import React, { useState, useEffect } from 'react';
import {
  View,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
} from 'react-native';
import FullWidthView from '../../../components/common/FullWidthView';
import { getCreditCardData, postWithAuthToken } from '../../../utils/service';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import { useNavigation } from '@react-navigation/native';
import { RootState, LocationInformation } from '../../../types';
import { useSelector } from 'react-redux';
import Loading from '../../../components/common/Loading';
import { useTranslation } from 'react-i18next';
import AddCreditCardForm from '../../../components/User/CreditCard/AddCreditCardForm';

type addCreditCardBody = {
  credit_card: number;
  expiry_year: number;
  expiry_month: number;
  cvd: string;
  ru_id: number;
  zipcode: string;
};

const NewCreditCardScreen = ({ route }: any) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const [ruID, setRuId] = useState(
    route.params !== undefined ? route.params.ruID : null
  );
  const { t } = useTranslation();

  const { countryId, provinceId, cityId } = useSelector<
    RootState,
    LocationInformation
  >((state) => state.goods.location);

  const handleSubmit = (
    cardNumber: string,
    expiry: string,
    cvc: string,
    postalCode: string
  ) => {
    const body: addCreditCardBody = {
      credit_card: parseInt(cardNumber.replace(/\s/g, '')),
      expiry_year: parseInt(
        expiry.replace('/', '').substring(2, 4).replace(/^0+/, '')
      ),
      expiry_month: parseInt(
        expiry.replace('/', '').substring(0, 2).replace(/^0+/, '')
      ),
      cvd: cvc,
      ru_id: ruID,
      zipcode: postalCode.replace(/\s/g, ''),
    };

    const loading = Toast.showLoading('');
    addCreditCard(body)
      .then((result) => {
        Toast.hide(loading);
        if (result) {
          if (result.goodStatus) {
            Toast.showSuccess(t('successfullyAdded'));
            Platform.OS === 'web'
              ? navigation.canGoBack()
                ? navigation.goBack()
                : /** @ts-ignore */
                  window.history.back()
              : navigation.goBack();
          } else {
            throw result.data;
          }
        } else {
          console.log(result);
        }
      })
      .catch((err) => {
        if (loading) Toast.hide(loading);
        Toast.show(err, {
          position: 0,
          duration: 2000,
        });
      });
  };

  useEffect(() => {
    if (ruID === undefined || ruID === null) {
      setLoading(true);
      getCreditCardData(countryId, provinceId, cityId)
        .then((result) => {
          if (result && result.goodStatus) {
            setRuId(result.data.ru_id);
          } else {
            throw result;
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          Toast.show(err, {
            position: 0,
            duration: 2000,
          });
        });
    }
  }, []);

  return (
    <TouchableWithoutFeedback
      onPress={Platform.OS === 'web' ? undefined : () => Keyboard.dismiss()}
      accessible={false}
    >
      <View style={{ flex: 1 }}>
        <FullWidthView>
          {loading ? (
            <Loading />
          ) : (
            <AddCreditCardForm handleSubmit={handleSubmit} />
          )}
        </FullWidthView>
      </View>
    </TouchableWithoutFeedback>
  );
};

const addCreditCard = async (body: addCreditCardBody) => {
  const result = await postWithAuthToken('creditcard/add', body);
  return result;
};

export default NewCreditCardScreen;
