import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import FullWidthView from '../../components/common/FullWidthView';
import ListOption from '../../components/common/list/ListOption';
import StyledText from '../../components/common/StyledText';
import { backGroundLight, tintColorLight } from '../../constants/Colors';
import { setAsyncStorageData } from '../../utils/asyncStorage';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../types';

const LanguageSetting = () => {
  const { i18n } = useTranslation();

  const dispatch = useDispatch();

  const preferredLanguage = useSelector<RootState, string | null | undefined>(
    (state) => state.user.preferredLanguage
  );

  const handleChangeLanguagePreference = (languageCode: string) => {
    setAsyncStorageData('@storage_LangrageReference', languageCode);
    dispatch({
      type: 'CHANGE_LANGUAGE_PREFERENCE',
      payload: languageCode,
    });
    i18n.changeLanguage(languageCode);
  };

  return (
    <FullWidthView style={styles.rootContainer}>
      <ListOption>
        <TouchableOpacity
          onPress={() => {
            handleChangeLanguagePreference('zh-CN');
          }}
          style={styles.itemContainer}
        >
          <StyledText>中文</StyledText>
          {preferredLanguage === 'zh-CN' && (
            <AntDesign name='checkcircle' size={16} color={tintColorLight} />
          )}
        </TouchableOpacity>
      </ListOption>
      <ListOption>
        <TouchableOpacity
          onPress={() => {
            handleChangeLanguagePreference('en');
          }}
          style={styles.itemContainer}
        >
          <StyledText>English</StyledText>
          {preferredLanguage === 'en' && (
            <AntDesign name='checkcircle' size={16} color={tintColorLight} />
          )}
        </TouchableOpacity>
      </ListOption>
    </FullWidthView>
  );
};

export default LanguageSetting;

const styles = StyleSheet.create({
  rootContainer: {
    backgroundColor: backGroundLight,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
});
