import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/common/iconButtons/BackButton';
import NavigateButton from '../../components/common/NavigateButton';
import ArticleScreen from '../../screens/User/Articles/ArticleScreen';
import ArticleListScreen from '../../screens/User/Articles/ArticleListScreen';
import { ArticleParamList } from '../../types';
import getAppStyle from '../../utils/getAppStyle';

const ArticleStack = createStackNavigator<ArticleParamList>();

const ArticleNavigator = () => {
  const { t } = useTranslation();

  return (
    <ArticleStack.Navigator
      initialRouteName="ArticleListScreen"
      screenOptions={{
        headerBackTitleVisible: false,
        headerLeft: () => (
          <NavigateButton goBack>
            <BackButton />
          </NavigateButton>
        ),
        headerTitleAlign: 'center',
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <ArticleStack.Screen
        name="ArticleListScreen"
        component={ArticleListScreen}
        options={{ title: t('userProfileScreen.otherMenu.articles') }}
      />
      <ArticleStack.Screen
        name="ArticleScreen"
        component={ArticleScreen}
        options={{ title: t('articleSection.articleDetail') }}
      />
    </ArticleStack.Navigator>
  );
};

export default ArticleNavigator;
