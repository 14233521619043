import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { AddressParamList } from '../../types';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/common/iconButtons/BackButton';
import AddressListScreen from '../../screens/User/Address/AddressListScreen';
import NewAddressScreen from '../../screens/User/Address/NewAddressScreen';
import EditAddressScreen from '../../screens/User/Address/EditAddressScreen';
import AddressMapScreen from '../../screens/User/Address/AddressMapScreen';
import AddAddressBtn from '../../components/User/Address/AddAddressBtn';
import NavigateButton from '../../components/common/NavigateButton';
import getAppStyle from '../../utils/getAppStyle';

const AddressStack = createStackNavigator<AddressParamList>();

const AddressNavigator = () => {
  const { t } = useTranslation();

  return (
    <AddressStack.Navigator
      mode="card"
      screenOptions={{
        headerLeft: () => (
          <NavigateButton goBack>
            <BackButton />
          </NavigateButton>
        ),
        headerBackTitleVisible: false,
        headerTitleAlign: 'center',
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
      initialRouteName="AddressList"
    >
      <AddressStack.Screen
        name="AddressList"
        component={AddressListScreen}
        options={{
          headerTitle: t('address.address'),
          headerRight: () => <AddAddressBtn />,
        }}
      />
      <AddressStack.Screen
        name="NewAddress"
        component={NewAddressScreen}
        options={{
          headerTitle: t('address.addAddress'),
        }}
      />
      <AddressStack.Screen
        name="EditAddress"
        component={EditAddressScreen}
        options={{
          headerTitle: t('address.editAddress'),
        }}
      />
      <AddressStack.Screen
        name="MapView"
        component={AddressMapScreen}
        options={{
          headerTitle: t('address.info.inputAddress'),
          gestureEnabled: false,
        }}
      />
    </AddressStack.Navigator>
  );
};

export default AddressNavigator;
