import React from 'react';
import { View, StyleSheet } from 'react-native';
import InputField from '../InputField';
import { Entypo, Feather } from '@expo/vector-icons';
import SendCodeButton from '../SendCodeButton';
import ErrorMessage from '../ErrorMessage';
import { Formik } from 'formik';
import { normalTextColor } from '../../../constants/Colors';
import { useTranslation } from 'react-i18next';
import StyledButton from '../../common/StyledButton';
import { VerifyPhoneSchema } from '../../../utils/validation';
import { forgotPassword } from '../../../utils/service';
import Toast from 'react-native-tiny-toast';

type VerifyUserFormProp = {
  navigation: any;
};

const VerifyUserForm = ({ navigation }: VerifyUserFormProp) => {
  const { t } = useTranslation();

  const verifyUser = (phone: string, code: string) => {
    const loading = Toast.showLoading('');
    forgotPassword(phone, code)
      .then((result) => {
        Toast.hide(loading);
        if (result && result.goodStatus) {
          navigation.navigate('ResetPasswordForm');
        } else {
          throw result?.data;
        }
      })
      .catch((err) => {
        if (loading) Toast.hide(loading);
        Toast.show(err, {
          position: 0,
          duration: 2000,
        });
      });
  };

  return (
    <View style={styles.VerifyUserForm}>
      <Formik
        initialValues={{ phone: '', code: '' }}
        onSubmit={(values) => verifyUser(values.phone, values.code)}
        validationSchema={VerifyPhoneSchema}
      >
        {({ handleChange, handleSubmit, values, errors, touched }) => (
          <View style={{ alignItems: 'center' }}>
            <InputField
              iconComponent={
                <Feather name="smartphone" size={24} color={normalTextColor} />
              }
              onChangeText={handleChange('phone')}
              placeholder={t('AuthModal.enterPhoneNumber')}
              keyboardType="phone-pad"
              value={values.phone}
            />
            <View style={styles.errorMsgContainer}>
              {errors.phone && touched.phone && (
                <ErrorMessage label={errors.phone} />
              )}
            </View>

            <InputField
              iconComponent={
                <Entypo
                  name="key"
                  size={20}
                  style={{ marginRight: 3 }}
                  color={normalTextColor}
                />
              }
              buttonComponent={
                <SendCodeButton value={values.phone} type={'phone'} />
              }
              onChangeText={handleChange('code')}
              placeholder={t('AuthModal.enterCode')}
              maxLength={6}
              keyboardType="number-pad"
              value={values.code}
            />
            <View style={styles.errorMsgContainer}>
              {errors.code && touched.code && (
                <ErrorMessage label={errors.code} />
              )}
            </View>

            <StyledButton
              label={t('AuthModal.nextStep')}
              onPress={handleSubmit}
            />
          </View>
        )}
      </Formik>
    </View>
  );
};

const styles = StyleSheet.create({
  errorMsgContainer: {
    height: 24,
  },
  VerifyUserForm: {
    backgroundColor: 'white',
    flex: 1,
  },
});

export default VerifyUserForm;
