import { useNavigation } from '@react-navigation/native';
import { useUpdateEffect } from 'ahooks';
import React, { useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  FlatList,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
} from 'react-native';
import SkeletonContent from 'react-native-skeleton-content';
import FullWidthView from '../../../components/common/FullWidthView';
import StyledText from '../../../components/common/StyledText';
import ArticleCategoryMenu from '../../../components/User/Article/ArticleCategoryMenu';
import ArticleList from '../../../components/User/Article/ArticleList';
import { ArticleCategory } from '../../../types';
import { errorAlertAndGoBack } from '../../../utils/alert';
import { getData } from '../../../utils/service';

const { width } = Dimensions.get('window');

const ArticleListScreen = () => {
  const navigation = useNavigation();

  const listRef = useRef<FlatList<ArticleCategory>>(null);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [categoryMenu, setCategoryMenu] = useState<ArticleCategory[]>([]);
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  const [webCatId, setWebCatId] = useState(-1);

  useEffect(() => {
    setWebCatId(-1);
    (async () => {
      //Get ArticleCategories
      const result = await getArticleCategories();
      if (result && result.goodStatus) {
        setCategoryMenu(result.data);
        setWebCatId(result.data[0].id);
        setLoadingComplete(true);
      } else {
        errorAlertAndGoBack(
          typeof result?.data === 'string'
            ? result?.data
            : 'Error about getting Article Categories',
          navigation
        );
      }
    })();
  }, []);

  useUpdateEffect(() => {
    if (Platform.OS !== 'web') {
      if (listRef && listRef.current) {
        listRef.current.scrollToIndex({
          index: selectedIndex,
          animated: false,
        });
      }
    } else {
      //For web to render different id
      setWebCatId(categoryMenu[selectedIndex].id);
    }
  }, [selectedIndex]);

  const renderItem = ({ item }: { item: ArticleCategory }) => (
    <ArticleList categoryId={item.id} />
  );

  const handleSwipePage = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    let contentOffset = e.nativeEvent.contentOffset;
    let viewSize = e.nativeEvent.layoutMeasurement;

    // Divide the horizontal offset by the width of the view to see which page is visible
    let pageNum = Math.floor((contentOffset.x + width / 2) / viewSize.width);
    setSelectedIndex(pageNum);
  };

  return (
    <FullWidthView>
      {isLoadingComplete ? (
        <ArticleCategoryMenu
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          data={categoryMenu}
        />
      ) : (
        <SkeletonContent
          containerStyle={{ width: width }}
          layout={[
            {
              width: width - 20,
              height: 30,
              marginHorizontal: 10,
              marginTop: 5,
              display: 'flex',
              flexDirection: 'row',
              children: [
                {
                  width: 50,
                  height: 25,
                  marginRight: 5,
                },
                {
                  width: 50,
                  height: 25,
                  marginRight: 5,
                },
                {
                  width: 50,
                  height: 25,
                  marginRight: 5,
                },
                {
                  width: 50,
                  height: 25,
                  marginRight: 5,
                },
              ],
            },
          ]}
          isLoading={true}
        />
      )}
      {Platform.OS !== 'web' ? (
        <FlatList
          ref={listRef}
          data={categoryMenu}
          renderItem={renderItem}
          keyExtractor={(item) => item.id.toString()}
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          onScroll={handleSwipePage}
          scrollEventThrottle={500}
        />
      ) : webCatId !== -1 ? (
        <ArticleList categoryId={webCatId} />
      ) : (
        <StyledText align="center">Loading</StyledText>
      )}
    </FullWidthView>
  );
};

export default ArticleListScreen;

const getArticleCategories = async (type = 1) => {
  const result = await getData(`article/cat?cat_type=${type}`);
  return result;
};
