import React from 'react';
import { View, StyleSheet } from 'react-native';
import InputField from '../InputField';
import { AntDesign } from '@expo/vector-icons';
import ErrorMessage from '../ErrorMessage';
import { Formik } from 'formik';
import { normalTextColor } from '../../../constants/Colors';
import { useTranslation } from 'react-i18next';
import StyledButton from '../../common/StyledButton';
import { resetPassword } from '../../../utils/service';
import Toast from 'react-native-tiny-toast';
import { ResetPasswordSchema } from '../../../utils/validation';
import getAppStyle from '../../../utils/getAppStyle';

type ResetPasswordFormProp = {
  navigation: any;
};

const ResetPasswordForm = ({ navigation }: ResetPasswordFormProp) => {
  const { t } = useTranslation();

  const changePassword = (newPassword: string, confirmPassword: string) => {
    const loading = Toast.showLoading('');
    resetPassword(newPassword, confirmPassword)
      .then((result) => {
        Toast.hide(loading);
        if (result && result.goodStatus) {
          Toast.showSuccess(t('AuthModal.passwordChanged'));
          navigation.navigate('AuthModal');
        } else {
          throw result?.data;
        }
      })
      .catch((err) => {
        if (loading) Toast.hide(loading);
        Toast.show(err, {
          position: 0,
          duration: 2000,
        });
      });
  };

  return (
    <View style={styles.ResetPasswordForm}>
      <Formik
        validationSchema={ResetPasswordSchema}
        initialValues={{ newPassword: '', confirmPassword: '' }}
        onSubmit={(values) =>
          changePassword(values.newPassword, values.confirmPassword)
        }
      >
        {({ handleChange, handleSubmit, values, errors, touched }) => (
          <View style={{ alignItems: 'center' }}>
            <InputField
              iconComponent={
                <AntDesign name="lock" size={24} color={normalTextColor} />
              }
              onChangeText={handleChange('newPassword')}
              placeholder={t('AuthModal.enterPassword')}
              passwordField
              value={values.newPassword}
            />
            <View style={styles.errorMsgContainer}>
              {errors.newPassword && touched.newPassword && (
                <ErrorMessage label={errors.newPassword} />
              )}
            </View>

            <InputField
              iconComponent={
                <AntDesign name="lock" size={24} color={normalTextColor} />
              }
              onChangeText={handleChange('confirmPassword')}
              placeholder={t('AuthModal.confirmPassword')}
              passwordField
              value={values.confirmPassword}
            />
            <View style={styles.errorMsgContainer}>
              {errors.confirmPassword && touched.confirmPassword && (
                <ErrorMessage label={errors.confirmPassword} />
              )}
            </View>

            <StyledButton
              color={getAppStyle(process.env.EXPO_APP_TYPE)?.mainColor}
              label={t('AuthModal.resetPassword')}
              onPress={handleSubmit}
            />
          </View>
        )}
      </Formik>
    </View>
  );
};

const styles = StyleSheet.create({
  errorMsgContainer: {
    height: 24,
  },
  ResetPasswordForm: {
    backgroundColor: 'white',
    flex: 1,
  },
});

export default ResetPasswordForm;
