import React, { useEffect, useState, useRef } from 'react';
import { FlatList } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { getDataWithAuthToken } from '../../../utils/service';
import ActivityIndicator from '../../../components/common/ActivityIndicator';
import ListFooterText from '../../../components/common/list/ListFooterText';
import { useTranslation } from 'react-i18next';
import { UncommentList, UncommentListItem } from '../../../types';
import UncommentedReviewListItem from './UncommentedReviewListItem';

type UncommentedReviewListScreenProps = {
  currentIndex: number;
};

const UncommentedReviewListScreen = ({
  currentIndex,
}: UncommentedReviewListScreenProps) => {
  const navigation = useNavigation();
  const [uncomments, setUncomments] = useState<Array<UncommentListItem>>([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState<UncommentList['total_page']>(1);
  const [allLoaded, setAllLoaded] = useState(false);
  const isMounted = useRef(true);
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getGoodsUncommented = () => {
    setIsFetching(true);
    getDataWithAuthToken(`user/uncomments?page=1&size=10`)
      .then((response) => {
        if (isMounted.current) {
          setAllLoaded(false);
          if (response && response.goodStatus) {
            setUncomments(response.data.goods);
            setTotalPage(response.data.total_page);
            page === response.data.total_page &&
              response.data.goods.length <= 10 &&
              setAllLoaded(true);
          } else {
            throw response;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    getGoodsUncommented();
  }, [currentIndex]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      //get uncomment list
      getGoodsUncommented();

      //reset comment screen state when leaving so that we can set it again on return
      navigation.addListener('blur', () => {
        setPage(1);
        setUncomments([]);
        setTotalPage(1);
      });
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    if (page > 1) {
      setIsFetching(true);
      //get uncomment list
      getDataWithAuthToken(`user/uncomments?page=${page}&size=${10}`)
        .then((response) => {
          if (isMounted.current) {
            if (response && response.goodStatus) {
              setUncomments((lastValue: Array<UncommentListItem>) => [
                ...lastValue,
                ...response.data.goods,
              ]);
              setTotalPage(response.data.total_page);
              page === response.data.total_page &&
                response.data.goods.length <= 10 &&
                setAllLoaded(true);
            } else {
              throw response;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
  }, [page]);

  const loadExtraData = async () => {
    if (isFetching) return;
    if (page < totalPage) {
      setPage((lastValue) => lastValue + 1);
    }
  };

  const handlePress = (item: UncommentListItem) => {
    navigation.navigate('SubmitReviewScreen', { ...item });
  };

  const _renderItem = ({ item }: { item: UncommentListItem }) => {
    return (
      <UncommentedReviewListItem
        order_id={item.order_id}
        goods_id={item.goods_id}
        goods_name={item.goods_name}
        goods_attr={item.goods_attr}
        goods_thumb={item.goods_thumb}
        ru_id={item.ru_id}
        rec_id={item.rec_id}
        get_points={item.get_points}
        t={t}
        handlePress={() => handlePress(item)}
      />
    );
  };

  return (
    <FlatList
      style={{ flex: 1 }}
      contentContainerStyle={{ backgroundColor: '#FFFF' }}
      data={uncomments}
      renderItem={_renderItem}
      keyExtractor={(item: UncommentListItem) => item.rec_id.toString()}
      onEndReached={() => loadExtraData()}
      onEndReachedThreshold={0.01}
      ListFooterComponent={allLoaded ? ListFooterText : ActivityIndicator}
    />
  );
};

export default UncommentedReviewListScreen;
