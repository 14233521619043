import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { HelpCenterParamList } from '../../types';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/common/iconButtons/BackButton';
import HelpCenterListScreen from '../../screens/User/HelpCenter/HelpCenterListScreen';
import HelpCenterItemScreen from '../../screens/User/HelpCenter/HelpCenterItemScreen';
import NavigateButton from '../../components/common/NavigateButton';
import getAppStyle from '../../utils/getAppStyle';

const HelpCenterStack = createStackNavigator<HelpCenterParamList>();

const HelpCenterNavigator = () => {
  const { t } = useTranslation();

  return (
    <HelpCenterStack.Navigator
      initialRouteName="HelpCenterListScreen"
      screenOptions={{
        headerBackTitleVisible: false,
        headerLeft: () => (
          <NavigateButton goBack>
            <BackButton />
          </NavigateButton>
        ),
        headerTitleAlign: 'center',
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <HelpCenterStack.Screen
        name="HelpCenterListScreen"
        component={HelpCenterListScreen}
        options={{ title: t('userProfileScreen.otherMenu.helpCenter') }}
      />
      <HelpCenterStack.Screen
        name="HelpCenterItemScreen"
        component={HelpCenterItemScreen}
        options={({ route }: { route: any }) => ({ title: route.params.title })}
      />
    </HelpCenterStack.Navigator>
  );
};

export default HelpCenterNavigator;
