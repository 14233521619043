import React from 'react';
import {
  StyleSheet,
  View,
  Dimensions,
  TouchableOpacity,
  GestureResponderEvent,
} from 'react-native';
import StyledText from './StyledText';
import { accentColor, successGreen } from '../../constants/Colors';
import getAppStyle from '../../utils/getAppStyle';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';

const winWidth = Dimensions.get('window').width;

type StyleButtonProp = {
  label: string;
  onPress: Function;
  accent?: boolean;
  disabled?: boolean;
  buttonSize?: 'big' | 'small' | number;
  outline?: boolean;
  icon?: JSX.Element;
  color?: string;
  labelColor?: string;
};

/**
 * Styled button with some options
 */
const StyledButton = ({
  label,
  onPress,
  accent = false,
  disabled = false,
  buttonSize = 'big',
  outline = false,
  icon,
  color,
  labelColor = 'white',
}: StyleButtonProp) => {
  const appMainColor = useSelector<RootState, string>(
    (state) => state.user.appMainColor
  );

  const backgroundColor = disabled
    ? 'lightgray'
    : color !== undefined
    ? color
    : accent
    ? getAppStyle(process.env.EXPO_APP_TYPE)?.secondaryColor
    : appMainColor;

  const sizeStyle =
    buttonSize === 'big' ? styles.buttonBig : styles.buttonSmall;

  return (
    <View style={styles.container}>
      {outline ? (
        <TouchableOpacity
          disabled={disabled}
          style={[
            sizeStyle,
            { borderWidth: 1, borderColor: color || 'lightgray' },
          ]}
          onPress={(event: GestureResponderEvent) => {
            event.preventDefault();
            onPress();
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {icon}
            <StyledText
              align="center"
              size={
                buttonSize === 'big'
                  ? 18
                  : typeof buttonSize === 'number'
                  ? buttonSize
                  : 14
              }
              color={color}
            >
              {label}
            </StyledText>
          </View>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          disabled={disabled}
          style={[sizeStyle, { backgroundColor: backgroundColor }]}
          onPress={(event: GestureResponderEvent) => {
            event.preventDefault();
            onPress();
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {icon}
            <StyledText
              align="center"
              color={labelColor}
              size={
                buttonSize === 'big'
                  ? 18
                  : typeof buttonSize === 'number'
                  ? buttonSize
                  : 14
              }
            >
              {label}
            </StyledText>
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default StyledButton;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonBig: {
    borderRadius: 25,
    marginHorizontal: 8,
    paddingVertical: 10,
    width: winWidth * 0.75,
  },
  buttonSmall: {
    borderRadius: 25,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
});
