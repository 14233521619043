import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { backGroundLight, subTextColor } from '../../constants/Colors';
import alert from '../../utils/alert';
import {
  getAsyncStorageData,
  removeAsyncStorageData,
  setAsyncStorageData,
} from '../../utils/asyncStorage';
import StyledText from '../common/StyledText';

type RecentSearchSectionProps = {
  onItemClick: (value: string) => void;
};

const RecentSearchSection = ({ onItemClick }: RecentSearchSectionProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const [searchTextList, setSearchTextList] = useState<string[]>([]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      (async () => {
        setSearchTextList(await getRecentSearchList());
      })();
    });

    return unsubscribe;
  }, [navigation]);

  const handleClearHistoryPress = () => {
    alert(
      t('searchScreen.recentSearchSection.deletePrompt.title'),
      t('searchScreen.recentSearchSection.deletePrompt.msg'),
      [
        {
          text: t('cancel'),
          style: 'cancel',
        },
        {
          text: t('confirm'),
          onPress: () => {
            removeRecentSearchList();
            setSearchTextList([]);
          },
        },
      ],
      { cancelable: false }
    );
  };

  const renderTextItem = searchTextList.map((keyword, index) => {
    return (
      <TouchableOpacity key={index} onPress={() => onItemClick(keyword)}>
        <View style={styles.keywordItem}>
          <StyledText>{keyword}</StyledText>
        </View>
      </TouchableOpacity>
    );
  });

  return searchTextList.length > 0 ? (
    <View style={styles.root}>
      <View style={styles.headerSection}>
        <StyledText bold>
          {t('searchScreen.recentSearchSection.title')}
        </StyledText>
        <AntDesign
          name="delete"
          size={20}
          color={subTextColor}
          onPress={handleClearHistoryPress}
        />
      </View>

      <View style={styles.listContainer}>{renderTextItem}</View>
    </View>
  ) : (
    <></>
  );
};

const styles = StyleSheet.create({
  root: {
    margin: 10,
  },
  headerSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  listContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  keywordItem: {
    backgroundColor: backGroundLight,
    paddingVertical: 5,
    paddingHorizontal: 8,
    marginRight: 5,
    marginBottom: 5,
  },
});

export default RecentSearchSection;

/**
 * Get recent search list text from AsyncStorage
 **/
const getRecentSearchList = async (): Promise<string[]> => {
  const recentSearch = await getAsyncStorageData('@storage_recentSearch');

  if (typeof recentSearch === 'string') {
    return JSON.parse(recentSearch);
  }
  return [];
};

/**
 * Add search text to AsyncStorage recent search list
 * @param newSearchText search text that user input
 */
export const updateRecentSearchList = async (newSearchText: string) => {
  newSearchText = newSearchText.trim();
  if (newSearchText.length > 0) {
    const recentSearch = await getRecentSearchList();
    if (recentSearch?.length > 0) {
      let currentList = recentSearch.filter((text) => text !== newSearchText);
      //Only store the recent 15 search text history
      if (recentSearch.length >= 15) {
        currentList.pop();
      }
      setAsyncStorageData(
        '@storage_recentSearch',
        JSON.stringify([newSearchText, ...currentList])
      );
    } else {
      setAsyncStorageData(
        '@storage_recentSearch',
        JSON.stringify([newSearchText])
      );
    }
  }
};

/**
 * Remove search history from AsyncStorage
 */
export const removeRecentSearchList = () => {
  removeAsyncStorageData('@storage_recentSearch');
};
