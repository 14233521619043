import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import {
  accentColor,
  backGroundLight,
  normalTextColor,
} from '../../constants/Colors';
import { getData } from '../../utils/service';
import StyledText from '../common/StyledText';

type TopSearchSectionProps = {
  onItemClick: (value: string) => void;
};

const TopSearchSection = ({ onItemClick }: TopSearchSectionProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const [trendingList, setTrendingList] = useState<string[]>([]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      (async () => {
        const result = await getTrendingKeywords();
        if (result && result.goodStatus) {
          setTrendingList(result.data.keywords);
        } else setTrendingList([]);
      })();
    });

    return unsubscribe;
  }, [navigation]);

  const renderTextItem = trendingList.map((keyword, index) => {
    const itemStyle = index <= 2 ? styles.topItem : styles.keywordItem;

    return (
      <TouchableOpacity key={index} onPress={() => onItemClick(keyword)}>
        <View style={itemStyle}>
          <StyledText color={index <= 2 ? accentColor : normalTextColor}>
            {keyword}
          </StyledText>
        </View>
      </TouchableOpacity>
    );
  });

  return trendingList.length === 0 ? (
    <></>
  ) : (
    <View style={styles.root}>
      <StyledText bold style={{ marginBottom: 10 }}>
        {t('searchScreen.topSearch')}
      </StyledText>
      <View style={styles.listContainer}>{renderTextItem}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: { margin: 10 },
  listContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  keywordItem: {
    backgroundColor: backGroundLight,
    paddingVertical: 5,
    paddingHorizontal: 8,
    marginRight: 5,
    marginBottom: 5,
  },
  topItem: {
    backgroundColor: accentColor + '40',
    paddingVertical: 5,
    paddingHorizontal: 8,
    marginRight: 5,
    marginBottom: 5,
  },
});

const getTrendingKeywords = async () => {
  return await getData('search/keywords');
};

export default TopSearchSection;
