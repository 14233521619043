import { useTranslation } from 'react-i18next';
import { Claim } from '../../../../types';
import StyledText from '../../../common/StyledText';
import WhiteCard from '../../../common/WhiteCard';
import { GREY2 } from '../../../../constants/Colors';
import { StyleSheet, View } from 'react-native';

type ReviewInfoProps = {
  claim: Claim;
};

const ReviewInfo = ({ claim }: ReviewInfoProps) => {
  const { t } = useTranslation();

  return (
    <WhiteCard title={t('order.claim.reviewInfo')}>
      <View style={styles.itemContainer}>
        {claim.review_status && (
          <View style={styles.textContainer}>
            <StyledText color={GREY2}>
              {t('order.claim.reviewStatus.title')}:{' '}
            </StyledText>
            <StyledText style={{ color: claim.review_status.color }}>
              {claim.review_status.desc}
            </StyledText>
          </View>
        )}
        {claim.claim_type && (
          <View style={styles.textContainer}>
            <StyledText color={GREY2}>
              {t('order.claim.claimType')}:{' '}
            </StyledText>
            <StyledText>{claim.claim_type.desc}</StyledText>
          </View>
        )}
        {claim.add_time !== '' && (
          <View style={styles.textContainer}>
            <StyledText color={GREY2}>{t('order.claim.addTime')}: </StyledText>
            <StyledText>{claim.add_time}</StyledText>
          </View>
        )}
        {claim.review_time !== '' && (
          <View style={styles.textContainer}>
            <StyledText color={GREY2}>
              {t('order.claim.reviewTime')}:{' '}
            </StyledText>
            <StyledText>{claim.review_time}</StyledText>
          </View>
        )}
      </View>
    </WhiteCard>
  );
};

export default ReviewInfo;

const styles = StyleSheet.create({
  itemContainer: {
    padding: 5,
  },
  textContainer: {
    flexDirection: 'row',
  },
});
