import axios from 'axios';
import { validateResponse } from './validation';
import { getAsyncStorageData } from './asyncStorage';
import { Platform } from 'react-native';
import { store } from '../App';
import alert from './alert';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import * as Localization from 'expo-localization';
import i18n from 'i18next';
import * as RootNavigation from './navigator';
import * as Network from 'expo-network';
import { webCurrentVersion } from '../constants/WebConstants';
import * as Application from 'expo-application';
import { DevicePushToken } from 'expo-notifications';
import getAppStyle from './getAppStyle';

/**
 * NOTES: Web platform use env file to get the backend URL, while app just use this variable not env.
 */
const BASE_URL = getAppStyle(process.env.EXPO_APP_TYPE)?.apiUrl;

const appPlatform = Platform.OS;

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

// Add a request interceptor
axios.interceptors.request.use(
  async function (config) {
    const { isConnected } = await Network.getNetworkStateAsync();
    if (!isConnected) {
      RootNavigation.navigate('NoInternet');
      return Promise.reject('no internet');
    }
    const location = await getAsyncStorageData('@storage_location');
    if (location) {
      const locationObj = JSON.parse(location);
      config.headers = {
        ...config.headers,
        'postal-code': locationObj.postalCodePrefix,
      };
    }
    const language = await getAsyncStorageData('@storage_LangrageReference');
    if (language === 'en') {
      config.headers = { ...config.headers, 'accept-language': 'en-US' };
    }

    if (appPlatform !== 'web') {
      config.headers = { ...config.headers, 'User-Agent': appPlatform };
    }

    config.headers = {
      ...config.headers,
      'app-version':
        appPlatform === 'web'
          ? webCurrentVersion
          : Application.nativeApplicationVersion,
      timezone: Localization.timezone,
      'app-type': getAppStyle(process.env.EXPO_APP_TYPE)?.title,
    };

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/**
 * Get Data without Auth token
 * @param endPoint end point uri
 * @param options axios GET options
 */
export const getData = async (endPoint: string, options?: {}) => {
  const authToken = await getAsyncStorageData('@storage_AuthToken');
  try {
    const response = await axios.get(endPoint, {
      // timeout: 10000,
      ...options,
      headers: {
        Authorization: getAppStyle(process.env.EXPO_APP_TYPE)
          .loginRequiredAccessAllPages
          ? `Bearer ${authToken}`
          : '',
      },
    });
    if (response.status === 200) {
      return validateResponse(response.data);
    } else console.error(response);
  } catch (err) {
    if (axios.isCancel(err)) {
      return err.message;
    } else {
      // alert('getData' + err + ' at endpoint: ' + endPoint);
      console.log('getData' + err + ' at endpoint: ' + endPoint);
    }
  }
};

/**
 * Post data without Auth Token
 * @param endPoint end point uri
 * @param body post body content
 * @param options axios POST options
 */
export const postData = async (
  endPoint: string,
  body: object,
  options?: {}
) => {
  try {
    const response = await axios.post(endPoint, body, options);
    if (response.status === 200) {
      return validateResponse(response.data);
    } else console.error(response);
  } catch (err) {
    //   alert('postData' + err + ' at endpoint: ' + endPoint);
    console.log('postData' + err + ' at endpoint: ' + endPoint);
  }
};

/**
 * Get Product list base on category id
 * @param catId
 * @param page
 * @param countryId
 * @param provinceId
 * @param cityId
 * @param size
 * @param goodsType
 * @param options
 * @param sort_key
 * @param sort_value
 */
export async function getGoodsListWithId(
  catId: number,
  page = 1,
  countryId: number,
  provinceId: number,
  cityId: number,
  size = 10,
  sort_key?: string,
  sort_value?: string,
  goodsType = 'all',
  options = {}
) {
  const result = await getData(
    `goods/list?id=${catId}&page=${page}&size=${size}&goods_type=${goodsType}${
      sort_key ? `&sort_key=${sort_key}` : ''
    }${sort_key ? `&sort_value=${sort_value}` : ''}`,
    options
  );
  return result;
}

export async function getGroupSaleGoodsListWithId(
  catId: number,
  page = 1,
  size = 10,
  goodsType = 'all',
  options = {}
) {
  const result = await getDataWithAuthToken(
    `groupsale/goods_list?id=${catId}&page=${page}&size=${size}&goods_type=${goodsType}`,
    options
  );
  return result;
}

export async function getGoodsUnderBrand(
  catId: number,
  page = 1,
  countryId: number,
  provinceId: number,
  cityId: number,
  size = 10,
  goodsType = 'all',
  brandId: number
) {
  const result = await getData(
    `goods/list?id=${catId}&page=${page}&country_id=${countryId}&province_id=${provinceId}&city_id=${cityId}&size=${size}&goods_type=${goodsType}&brand_id=${brandId}`
  );
  return result;
}

/**
 * Get Product using product id
 * @param productId product ID
 */
export async function getGoodsWithId(productId: number) {
  const authToken = await getAsyncStorageData('@storage_AuthToken');
  if (authToken) {
    try {
      const response = await axios.get(`goods?id=${productId}`, {
        timeout: 15000,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) {
        return validateResponse(response.data);
      } else console.error(response);
    } catch (err) {
      console.error(err);
    }
  } else {
    const result = await getData(`goods?id=${productId}`);
    return result;
  }
}

/**
 * Search products base on keyword
 * @param keyword
 * @param page
 * @param countryId
 * @param provinceId
 * @param cityId
 */
export async function searchGoodsWithKeyword(
  keyword: string,
  page = 1,
  countryId: number,
  provinceId: number,
  cityId: number
) {
  const result = await getData(
    `goods/list?keyword=${keyword}&page=${page}&country_id=${countryId}&province_id=${provinceId}&city_id=${cityId}&is_search=1`
  );
  return result;
}

export async function loginWithPhoneNumber(
  phoneNumber: string,
  password: string,
  options?: any
) {
  const response = await axios.post(
    'user/login',
    {
      username: phoneNumber,
      password: password,
    },
    options
  );

  return validateResponse(response.data);
}

/**
 * Get data with Authorization header JWT token
 * @param endPoint end point uri
 * @param options axios GET options
 */
export async function getDataWithAuthToken(endPoint: string, options?: {}) {
  const authToken = await getAsyncStorageData('@storage_AuthToken');
  if (authToken) {
    try {
      const response = await axios.get(endPoint, {
        // timeout: 10000,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        ...options,
      });
      if (response.status === 200) {
        return validateResponse(response.data);
      } else console.error(response);
    } catch (err) {
      console.error(err);
    }
  } else {
    // For AuthModal to check if the goBack should be single or double.
    if (appPlatform === 'web') {
      store.dispatch({
        type: 'CHECK_URL_IS_DANGEROUS',
        //@ts-ignore
        payload: window.location.href,
      });
    }

    RootNavigation.navigate('Modal', {
      screen: 'AuthNavigator',
      params: { screen: 'AuthModal' },
    });
    return {
      goodStatus: false,
      return_code: 401,
      data: i18n.t('AuthModal.noTokenTip'),
    };
  }
}

/**
 * Send post request with Authorization header JWT token
 * @param endPoint end point uri
 * @param data post data
 * @param options axios POST options
 */
export async function postWithAuthToken(
  endPoint: string,
  data: object,
  options?: { [key: string]: any; headers?: { [key: string]: any } }
) {
  const authToken = await getAsyncStorageData('@storage_AuthToken');
  if (authToken) {
    try {
      const optionsHeaders = options?.headers;
      const response = await axios.post(endPoint, data, {
        // timeout: 10000,
        ...options,
        headers: {
          Authorization: `Bearer ${authToken}`,
          ...optionsHeaders,
        },
      });
      if (response.status === 200) {
        return validateResponse(response.data);
      } else console.error(response);
    } catch (err) {
      if (axios.isCancel(err)) {
        return {
          goodStatus: false,
          return_code: 499,
          data: 'Request cancelled due to new request',
        };
      } else {
        console.error(err);
      }
    }
  } else {
    // For AuthModal to check if the goBack should be single or double.
    if (appPlatform === 'web') {
      store.dispatch({
        type: 'CHECK_URL_IS_DANGEROUS',
        //@ts-ignore
        payload: window.location.href,
      });
    }

    RootNavigation.navigate('Modal', {
      screen: 'AuthNavigator',
      params: { screen: 'AuthModal' },
    });
    return {
      goodStatus: false,
      return_code: 401,
      data: i18n.t('AuthModal.noTokenTip'),
    };
  }
}

/**
 * Get customer service contact info(wechat/phone etc.)
 * @param countryId
 * @param provinceId
 * @param cityId
 * @param ruId
 */
export async function getCustomerService(
  countryId: number,
  provinceId: number,
  cityId: number,
  ruId?: number | undefined
) {
  let endpoint = `customerservice?country_id=${countryId}&province_id=${provinceId}&city_id=${cityId}`;

  if (ruId) {
    endpoint += `&ru_id=${ruId}`;
  }

  const result = await getDataWithAuthToken(endpoint);

  return result;
}

/**
 * Send request to retrieve code for authentication
 * @param receiver phone number or email address
 * @param type phone or email
 */
export async function sendCode(receiver: string, type: string) {
  const response = await axios.post(
    'send_code',
    {
      receiver,
      type,
    },
    {
      withCredentials: true,
    }
  );
  return validateResponse(response.data);
}

/**
 * New user register
 * @param username
 * @param password
 * @param confirmPassword
 * @param code The code get from send code
 */
export async function registerUser(
  username: string,
  password: string,
  confirmPassword: string,
  code: string,
  redeem: string,
  companyName?: string,
  companyAddress?: string,
  businessLicense?: string,
  options?: any
) {
  const response = await axios.post(
    'user/register',
    {
      username,
      password,
      confirm_password: confirmPassword,
      code,
      promotion_code: redeem,
      business_name: companyName,
      business_address: companyAddress,
      business_license: businessLicense,
    },
    options
  );
  return validateResponse(response.data);
}

/**
 * Verify phone number and code, and get the permission to reset password.
 * @param phone
 * @param code
 */
export async function forgotPassword(phone: string, code: string) {
  const response = await axios.post('user/forgot', {
    username: phone,
    code,
  });

  return validateResponse(response.data);
}

/**
 * Update user new password
 * @param password
 * @param confirmPassword
 * @param oldPassword
 */
export async function resetPassword(
  password: string,
  confirmPassword: string,
  oldPassword?: string
) {
  let body = {};
  let headers: any = {};
  if (oldPassword) {
    body = {
      password,
      confirm_password: confirmPassword,
      old_password: oldPassword,
    };
    try {
      const authToken = await getAsyncStorageData('@storage_AuthToken');
      headers = { Authorization: `Bearer ${authToken}` };
    } catch (error) {
      return false;
    }
  } else {
    body = { password, confirm_password: confirmPassword };
  }
  const response = await axios.post('user/password/update', body, {
    headers: headers,
  });
  return validateResponse(response.data);
}

export async function getCartInformation() {
  const result = await getDataWithAuthToken('cart');
  return result;
}

/**
 * Get Comments using product id
 * @param productId
 * @param pageNumber
 * @param size
 */
export async function getProductCommentList(
  productId: number,
  pageNumber: number,
  size: number
) {
  const result = await getData(
    `goods/comments?id=${productId}&page=${pageNumber}&size=${size}`
  );
  return result;
}

/**
 * Get Credit Card Data with Auth token
 * @param countryId
 * @param provinceId
 * @param cityId
 */
export async function getCreditCardData(
  countryId: number,
  provinceId: number,
  cityId: number
) {
  const result = await getDataWithAuthToken(
    `creditcard?country_id=${countryId}&province_id=${provinceId}&city_id=${cityId}`
  );
  return result;
}

export async function processWeChatCode(weChatCode: string, options?: any) {
  const response = await axios.post(
    'oauth/wechat',
    { code: weChatCode },
    options
  );
  return validateResponse(response.data);
}

/**
 * Get App basic configuration information from backend (e.g API keys)
 */
export async function getAppConfig() {
  const result = await getData('app_config');
  return result;
}

/**
 * Request Expo push token for sending push notification
 */
export async function registerForPushNotificationsAsync() {
  let token;

  if (appPlatform === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      console.log('Failed to get push token for push notification!');
      return;
    }

    const options: { experienceId: string; devicePushToken?: DevicePushToken } =
      { experienceId: '@luniu/Luniu-mall' };
    // Uncomment below if it fails to get push token on ios
    // if (appPlatform === 'ios' && Constants.appOwnership !== 'expo') {
    //   try {
    //     const apnToken = await Notifications.getDevicePushTokenAsync()
    //     options.devicePushToken = apnToken;
    //   } catch (err) {
    //     console.log('Failed to get APN token.', err)
    //   }
    // }

    try {
      const res = await Notifications.getExpoPushTokenAsync(options);
      token = res.data;
    } catch (err) {
      console.log('Failed to get push token.', err);
    }
  } else {
    alert('Must use physical device for Push Notifications');
  }

  return token;
}

/**
 * Get ads for different screens
 * @param type Different type of ads
 */
export const getAds = async (type: string) => {
  const result = await getData(`/ad?type=${type}`);
  return result;
};

/**
 * Save Expo push notification to backend
 * @param pushToken Expo Push notification token
 * @param info User id and Expire time if exist
 */
export const savePushToken = async (
  pushToken: string,
  info?: { id: number; exp: number }
) => {
  return await postData('device/set', {
    device_code: pushToken,
    user_id: info ? info.id : '',
    login_expire_time: info ? info.exp : '',
  })
    .then((result) => {
      if (result && result.goodStatus) {
        return true;
      } else {
        return false;
      }
    })
    .catch((err) => {
      console.log('error with setting push token', err);
      return false;
    });
};

/**
 * Redeem coupon by entering promo code
 * @param code Coupon Promo code
 */
export const addCoupon = async (code: string) => {
  const result = await postWithAuthToken('coupon/add', { code });
  return result;
};

export async function getRegionWithPostalCode(code: string) {
  const result = await getData(`postal_code?postal_code=${code}`);
  return result;
}

/**
 * Get user notification config
 */
export const getNotificationConfig = async () => {
  const result = await getDataWithAuthToken('user/notify');
  return result;
};

/**
 * Edit user notification config
 * @param data Notification config
 */
export const editNotificationConfig = async (data: any) => {
  const result = await postWithAuthToken('user/notify/edit', data);
  return result;
};

/*
 * Unlink WeChat account
 * @param password Account password
 */
export const unlinkWeChat = async (password: string) => {
  const result = await postWithAuthToken('user/wechat/unbind', {
    password: password,
  });
  return result;
};

/**
 * Get self service ticket steps
 */
export const getTicketSteps = async () => {
  const result = await getDataWithAuthToken('udesk/steps');
  return result;
};

/**
 * Create self service ticket
 * @param data ticket content
 */
export const createTicket = async (data: any) => {
  const result = await postWithAuthToken('udesk/ticket/create', data);
  return result;
};

/**
 * Upload files of self service ticket
 */
export const uploadTicketFiles = async (data: any) => {
  const result = await postWithAuthToken('udesk/ticket/upload_file', data);
  return result;
};

/**
 * Pay order
 * @param data payment details
 */
export const payOrder = async (data: {
  orderId: number;
  payId: number;
  cardId?: number;
  cvd?: string;
  applePayData?: any;
}) => {
  const result = postWithAuthToken('order/pay', {
    order_id: data.orderId,
    pay_id: data.payId,
    card_id: data.cardId,
    cvd: data.cvd,
    applepay_data: data.applePayData,
  });
  return result;
};
