import React, { useState } from 'react';
import { View, TouchableOpacity, StyleSheet, Platform } from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import ListOption from '../../common/list/ListOption';
import StyledText from '../../common/StyledText';
import EditButton from '../../common/iconButtons/EditButton';
import { AntDesign } from '@expo/vector-icons';
import { normalTextColor, tintColorLight } from '../../../constants/Colors';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import Toast from 'react-native-tiny-toast';
import { postWithAuthToken } from '../../../utils/service';
import BottomAutoHeightModal from '../../common/BottomAutoHeightModal';

type BirthdayEditFormProps = {
  title: string;
  value: string;
};

const BirthdayEditForm = ({ title, value }: BirthdayEditFormProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const [modalVisible, setModalVisible] = useState(false);
  const [date, setDate] = useState(new Date());

  const onChange = (event: any, selectedDate: any) => {
    if (selectedDate) {
      const currentDate = selectedDate || date;
      setModalVisible(Platform.OS === 'ios');
      setDate(currentDate);
      if (Platform.OS === 'android') {
        setModalVisible(false);
        const newDate = moment(currentDate).toObject();
        postWithAuthToken('user/birthday/edit', {
          year: newDate.years,
          month: newDate.months + 1,
          day: newDate.date,
        }).then((result) => {
          if (result && result.goodStatus) {
            setModalVisible(Platform.OS === 'ios');
            //Successfully update, go back to info screen
            Toast.showSuccess(t('userInfoEditScreen.updateSuccessfully'));
            navigation.navigate('UserInfo', {
              newBirthday: moment(currentDate).format('YYYY-MM-DD'),
            });
          } else {
            Toast.show(result?.data ? result.data : 'Error');
          }
        });
      }
    }
  };

  const handleSubmit = () => {
    setModalVisible(false);

    const newDate = moment(date).toObject();
    postWithAuthToken('user/birthday/edit', {
      year: newDate.years,
      month: newDate.months + 1,
      day: newDate.date,
    }).then((result) => {
      if (result && result.goodStatus) {
        //Successfully update, go back to info screen
        Toast.showSuccess(t('userInfoEditScreen.updateSuccessfully'));
        navigation.navigate('UserInfo', {
          newBirthday: moment(date).format('YYYY-MM-DD'),
        });
      } else {
        Toast.show(result?.data ? result.data : 'Error');
      }
    });
  };

  return (
    <View>
      <ListOption>
        <TouchableOpacity onPress={() => setModalVisible(true)}>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <StyledText>{title}</StyledText>
            <View style={styles.valueContainer}>
              <StyledText>{value}</StyledText>
              <EditButton onPress={() => setModalVisible(true)} />
            </View>
          </View>
        </TouchableOpacity>
      </ListOption>
      {Platform.OS === 'android' && (
        <>
          {/* TODO - OnSubmit is not working for android */}
          {modalVisible && (
            <DateTimePicker
              textColor={normalTextColor}
              testID='dateTimePicker'
              value={date}
              mode='date'
              display='spinner'
              onChange={onChange}
              maximumDate={new Date()}
            />
          )}
        </>
      )}
      {Platform.OS === 'ios' && (
        <BottomAutoHeightModal
          visible={modalVisible}
          hideModal={() => setModalVisible(false)}
        >
          <View style={styles.headerMenuContainer}>
            <AntDesign
              name='close'
              size={24}
              color={normalTextColor}
              onPress={() => setModalVisible(false)}
            />
            <AntDesign
              name='check'
              size={24}
              color={tintColorLight}
              onPress={handleSubmit}
            />
          </View>

          <View style={styles.pickerContainer}>
            <DateTimePicker
              textColor={normalTextColor}
              testID='dateTimePicker'
              value={date}
              mode='date'
              display='default'
              onChange={onChange}
              maximumDate={new Date()}
            />
          </View>
        </BottomAutoHeightModal>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  headerMenuContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  valueContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  pickerContainer: {},
});

export default BirthdayEditForm;
