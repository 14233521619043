import React, { useState } from 'react';
import { View, TextInput, StyleSheet } from 'react-native';
import StyledText from '../../common/StyledText';
import ListOption from '../../common/list/ListOption';
import { GREY3 } from '../../../constants/Colors';
import StyledButton from '../../common/StyledButton';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-tiny-toast';
import { useNavigation } from '@react-navigation/native';
import { Formik } from 'formik';
import ErrorMessage from '../../Auth/ErrorMessage';
import { postWithAuthToken } from '../../../utils/service';
import { useDispatch } from 'react-redux';
import SendCodeButton from '../../Auth/SendCodeButton';
import { CheckBox } from 'react-native-elements';
import { ChangeEmailSchema } from '../../../utils/validation';

type EmailEditFormParams = {
  value: string;
};

const EmailEditForm = ({ value }: EmailEditFormParams) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const changeEmail = (email: string, code: string, subscribe: boolean) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      const loading = Toast.showLoading('');
      let subscribeValue = subscribe ? 1 : 0;
      postWithAuthToken('user/email/edit', {
        email,
        code,
        subscribe: subscribeValue,
      })
        .then((result) => {
          Toast.hide(loading);
          if (result && result.goodStatus) {
            setIsSubmitting(false);
            Toast.showSuccess(t('userInfoEditScreen.updateSuccessfully'));
            navigation.navigate('UserInfo', { newEmail: email });
          } else {
            throw result ? result.data : 'Error';
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
          if (loading) Toast.hide(loading);
          Toast.show(err, {
            position: 0,
            duration: 2000,
          });
        });
    }
  };

  return (
    <Formik
      validationSchema={ChangeEmailSchema}
      initialValues={{
        email: '',
        code: '',
        subscribe: true,
      }}
      onSubmit={(values) =>
        changeEmail(values.email, values.code, values.subscribe)
      }
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <View style={styles.rootContainer}>
          <View>
            <ListOption>
              <View style={styles.formContainer}>
                <StyledText style={styles.fieldTitle}>
                  {t('userInfoScreen.changeEmailOption.emailAddress')}
                </StyledText>
                <View style={styles.inputContainer}>
                  <TextInput
                    style={[styles.inputField, { outline: 'none' }]}
                    value={values.email}
                    onChangeText={handleChange('email')}
                  />
                </View>
              </View>
              <View style={styles.errorMsgContainer}>
                {errors.email && touched.email && (
                  <ErrorMessage label={errors.email} />
                )}
              </View>
            </ListOption>

            <ListOption>
              <View style={styles.formContainer}>
                <StyledText style={styles.fieldTitle}>
                  {t('userInfoScreen.changeEmailOption.code')}
                </StyledText>
                <View style={styles.sendCodeInputContainer}>
                  <TextInput
                    keyboardType={'number-pad'}
                    placeholder={t(
                      'userInfoScreen.changeEmailOption.codePlaceholder'
                    )}
                    style={[styles.inputField, { outline: 'none' }]}
                    value={values.code}
                    onChangeText={handleChange('code')}
                    maxLength={6}
                  />
                  <SendCodeButton value={values.email} type={'email'} />
                </View>
              </View>
              <View style={styles.errorMsgContainer}>
                {errors.code && touched.code && (
                  <ErrorMessage label={errors.code} />
                )}
              </View>
            </ListOption>

            <CheckBox
              size={18}
              textStyle={{ fontSize: 14, color: GREY3, paddingBottom: 2 }}
              fontFamily={'noto-sans'}
              title={t('userInfoScreen.changeEmailOption.subscribeCheckbox')}
              checked={values.subscribe}
              checkedIcon="check-square"
              checkedColor={'gray'}
              containerStyle={{ backgroundColor: 'white', borderWidth: 0 }}
              onPress={() => {
                setFieldValue('subscribe', !values.subscribe);
              }}
            />
          </View>
          <View style={styles.buttonContainer}>
            <StyledButton
              disabled={isSubmitting}
              label={t('userInfoScreen.changeEmailOption.changeEmailButton')}
              onPress={handleSubmit}
            />
          </View>
        </View>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  formContainer: {
    flexDirection: 'column',
  },
  fieldTitle: {
    height: 30,
  },
  inputContainer: {
    height: 40,
  },
  sendCodeInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputField: {
    color: GREY3,
    flex: 1,
    fontSize: 14,
  },
  buttonContainer: {
    marginBottom: 30,
  },
  errorMsgContainer: {
    height: 20,
  },
  checkboxContainer: {
    flexDirection: 'row',
  },
});

export default EmailEditForm;
