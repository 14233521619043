import React from 'react';
import { View, StyleSheet } from 'react-native';
import StyledText from './StyledText';
import StyledButton from './StyledButton';
import { useTranslation } from 'react-i18next';
import { AntDesign } from '@expo/vector-icons';

type EmptyDisplayBlockProps = {
  onPress?: Function;
  buttonLabel?: string;
  icon?: JSX.Element;
  description?: string;
  withoutFlex?: boolean;
};

/**
 * Display empty style when there is no data after fetching
 */
const EmptyDisplayBlock = ({
  icon,
  onPress,
  buttonLabel,
  description,
  withoutFlex = false,
}: EmptyDisplayBlockProps) => {
  const { t } = useTranslation();

  return (
    <View
      style={[
        styles.emptyDisplayBlockContainer,
        !withoutFlex ? { flex: 1 } : { height: '100%' },
      ]}
    >
      {icon ? icon : <AntDesign name="meh" size={80} color="lightgray" />}
      <StyledText
        size={18}
        color="lightgray"
        style={{ marginBottom: 30, marginHorizontal: 10 }}
        align="center"
      >
        {description ? description : t('noContent')}
      </StyledText>

      {onPress && buttonLabel && (
        <StyledButton label={buttonLabel} onPress={onPress} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  emptyDisplayBlockContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default EmptyDisplayBlock;
