import React, { useRef, useState } from 'react';
import { Dimensions, View, StyleSheet, TouchableOpacity } from 'react-native';
import ResponsiveImg from '../common/ResponsiveImg';
import { GoodsInfo } from '../../types';
import CarouselPagination from './widgets/CarouselPagination';
import Swiper from 'react-native-web-swiper';
import Watermark from './widgets/Watermark';
import { AntDesign } from '@expo/vector-icons';

type ProductMediaObject = {
  uri: string;
  type: string;
};

type ProductMediaCarouselProps = {
  data: Array<ProductMediaObject>;
  watermark_img: GoodsInfo['watermark_img'];
};

const { width } = Dimensions.get('window');

const ProductMediaCarousel = (props: ProductMediaCarouselProps) => {
  const { data, watermark_img } = props;
  const swiperRef = useRef<Swiper>(null);
  const [activeIndex, setActiveIndex] = useState(1);

  const goToNext = () => {
    swiperRef?.current?.goToNext();
  }

  const goToPrev = () => {
    swiperRef?.current?.goToPrev();
  }

  return data.length > 0 ? (
    <View style={styles.CarouselWrapper}>
      <Swiper
        ref={swiperRef}
        onIndexChanged={(index) => setActiveIndex(index + 1)}
        controlsProps={{
          DotComponent: EmptyControlComponent,
          PrevComponent: EmptyControlComponent,
          NextComponent: EmptyControlComponent,
          cellsContent: {
            'left': activeIndex > 1 ? (
              <TouchableOpacity onPress={goToPrev}>
                <View style={{backgroundColor: 'rgba(0,0,0,.3)', paddingHorizontal: 5, paddingVertical: 4, borderRadius: 50}}>
                  <AntDesign name="left" size={18} color="white" />
                </View>
              </TouchableOpacity>
            ) : (<></>),
            'right': activeIndex < data.length ? (
              <TouchableOpacity onPress={goToNext}>
                <View style={{backgroundColor: 'rgba(0,0,0,.3)', paddingHorizontal: 5, paddingVertical: 4, borderRadius: 50}}>
                  <AntDesign name="right" size={18} color="white" />
                </View>
              </TouchableOpacity>
            ) : (<></>)
          }
        }}
      >
        {data.map((item, index) => {
          return (
            <ResponsiveImg
              key={index}
              uri={item.uri}
              width={width}
              type={item.type}
            />
          );
        })}
      </Swiper>
      <CarouselPagination
        activeIndex={activeIndex}
        totalPage={data.length}
        style={styles.pagination}
      />
      <Watermark watermarkType={watermark_img} width={80} />
    </View>
  ) : (
    <></>
  );
};

class EmptyControlComponent extends React.Component {
  render() {
    return (
      <></>
    )
  }
}

const styles = StyleSheet.create({
  CarouselWrapper: {
    position: 'relative',
    width: width,
    height: width,
  },
  pagination: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
});

export default ProductMediaCarousel;
