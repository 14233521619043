import React, { useEffect, useRef } from 'react';
import { FlatList, View, StyleSheet, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { SubCategoryData, RootState } from '../../../types';
import {
  accentColor,
  tintColorLight,
  warningRed,
} from '../../../constants/Colors';
import StyledText from '../../common/StyledText';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome5, MaterialIcons, AntDesign } from '@expo/vector-icons';
import { useUpdateEffect } from 'ahooks';
import { screenHit } from '../../../utils/analytic';

type SubCategoriesListProp = {
  data: Array<SubCategoryData>;
  resetHorizontalIndex: Function;
  isHomeProductList?: boolean;
  disabled?: boolean;
  updateSubCategoryChange: Function;
};

const SubCategoriesList = ({
  data,
  resetHorizontalIndex,
  isHomeProductList = false,
  disabled = false,
  updateSubCategoryChange,
}: SubCategoriesListProp) => {
  const selectedSubCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedSubCategoryIndex
  );

  const menuRef = useRef<FlatList>(null);

  const scrollToIndex = (index: number) => {
    if (menuRef && menuRef.current) {
      menuRef?.current?.scrollToIndex({
        animated: true,
        index: index,
        viewPosition: 0.5,
      });
    }
  };

  useEffect(() => {
    if (menuRef && menuRef.current) {
      menuRef?.current?.scrollToIndex({
        animated: false,
        index: selectedSubCategoryIndex,
      });
    }
  }, []);

  useUpdateEffect(() => {
    scrollToIndex(selectedSubCategoryIndex);
  }, [selectedSubCategoryIndex]);

  const renderItem = ({ item, index }: any) => (
    <SubCategoriesListItem
      item={item}
      index={index}
      resetHorizontalIndex={resetHorizontalIndex}
      isHomeProductList={isHomeProductList}
      scrollToIndex={scrollToIndex}
      disabled={disabled}
      updateSubCategoryChange={updateSubCategoryChange}
    />
  );

  return (
    <FlatList
      ref={menuRef}
      data={data}
      renderItem={renderItem}
      keyExtractor={(item) =>
        `${item.id.toString()}${Math.random().toString()}`
      }
      showsVerticalScrollIndicator={false}
      scrollEnabled={!disabled}
      getItemLayout={(_, index) => ({
        length: 49.66,
        offset: 49.66 * index,
        index,
      })}
    />
  );
};

export default SubCategoriesList;

const SubCategoriesListItem = ({
  item,
  index,
  resetHorizontalIndex,
  isHomeProductList,
  scrollToIndex,
  disabled = false,
  updateSubCategoryChange,
}: any) => {
  const selectedSubCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedSubCategoryIndex
  );

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const handleChangeSubCategory = (id: number, index: number) => {
    screenHit(`category/${id}`);
    scrollToIndex(index);
    resetHorizontalIndex();
    updateSubCategoryChange();
    dispatch({ type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX', payload: index });
    dispatch({ type: 'SET_RESET_GOODS_LIST', payload: true });
  };

  const renderMenuIcon = (type: string) => {
    switch (type) {
      case 'new':
        return <MaterialIcons name="fiber-new" size={14} color={accentColor} />;
      case 'hot':
        return <FontAwesome5 name="hotjar" size={14} color={warningRed} />;
      case 'best':
        return <AntDesign name="star" size={14} color="#fdba52" />;
      default:
        return <></>;
    }
  };

  if (item.show_in_nav) {
    if (index === selectedSubCategoryIndex) {
      //Show header title if it is home product list
      if (isHomeProductList) {
        navigation.setOptions({
          headerTitle: item.name,
        });
      }

      return (
        <View
          style={[
            styles.listItemContainer,
            styles.selectedSubCategoryIndex,
            { borderRightColor: tintColorLight },
          ]}
        >
          {item.goods_type !== undefined && renderMenuIcon(item.goods_type)}
          <StyledText variant bold align="center" style={styles.listItemText}>
            {item.name}
          </StyledText>
        </View>
      );
    }

    return (
      <TouchableOpacity
        disabled={disabled}
        onPress={() => {
          handleChangeSubCategory(item.id, index);
        }}
      >
        <View style={styles.listItemContainer}>
          {item.goods_type !== undefined && renderMenuIcon(item.goods_type)}
          <StyledText align="center" style={styles.listItemText}>
            {item.name}
          </StyledText>
        </View>
      </TouchableOpacity>
    );
  }

  return <></>;
};

const styles = StyleSheet.create({
  selectedSubCategoryIndex: {
    backgroundColor: 'white',
    borderRightWidth: 3,
  },
  listItemContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingVertical: 15,
  },
  listItemText: {
    // paddingVertical: 15,
  },
});
