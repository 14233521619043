import i18next from 'i18next';

/**
 * Order Delivery Status
 */
export enum OrderStatusType {
  Preparing = 0,
  OutForDelivery = 1,
  ArrivingSoon = 2,
  Failed = 3,
  Delivered = 4,
}

/**
 * Output order delivery status text value base on status number value
 * @param status Order Delivery Status
 */
export const orderStatusToString = (status: OrderStatusType) => {
  switch (status) {
    case OrderStatusType.Preparing:
      return i18next.t('order.orderTracking.orderStatusTypes.preparing');

    case OrderStatusType.OutForDelivery:
      return i18next.t('order.orderTracking.orderStatusTypes.outForDelivery');

    case OrderStatusType.ArrivingSoon:
      return i18next.t('order.orderTracking.orderStatusTypes.arrivingSoon');

    case OrderStatusType.Failed:
      return i18next.t('order.orderTracking.orderStatusTypes.failed');

    case OrderStatusType.Delivered:
      return i18next.t('order.orderTracking.orderStatusTypes.delivered');

    case -1:
      return 'Unknown';

    default:
      return status;
  }
};

/**
 * Output the color representing the delivery status of the order
 * @param status Order Delivery Status
 */
export const orderStatusColor = (status: OrderStatusType) => {
  switch (status) {
    case OrderStatusType.ArrivingSoon:
      return '#f57a4c';

    case OrderStatusType.Delivered:
      return '#2cae35';

    case OrderStatusType.Failed:
      return '#eb6565';

    case OrderStatusType.OutForDelivery:
      return '#4285F4';

    default:
      return '#878484';
  }
};
