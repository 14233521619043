import { StyleSheet, ScrollView } from 'react-native';
import FullWidthView from '../../components/common/FullWidthView';
import ProfileHeader from '../../components/User/ProfileHeader';
import {
  backGroundLight,
  subTextColor,
  tintColorLight,
} from '../../constants/Colors';
import ProfileOrderMenu from '../../components/User/container/ProfileOrderMenu';
import ProfileWalletMenu from '../../components/User/container/ProfileWalletMenu';
import ProfileOtherMenu from '../../components/User/container/ProfileOtherMenu';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { getDataWithAuthToken } from '../../utils/service';
import { useDispatch } from 'react-redux';
import Loading from '../../components/common/Loading';
import {
  getAsyncStorageData,
  setAsyncStorageData,
} from '../../utils/asyncStorage';
import alert from '../../utils/alert';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import FriendsBanner from '../../components/User/Friends/FriendsBanner';
import { UserParamList } from '../../types';
import getAppStyle from '../../utils/getAppStyle';

export default function UserProfileScreen() {
  const navigation =
    useNavigation<NavigationProp<UserParamList, 'UserProfile'>>();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState<any>({});
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setIsLoadingComplete(false);
      getUserInfo();
    });

    return unsubscribe;

    async function getUserInfo() {
      const authToken = await getAsyncStorageData('@storage_AuthToken');
      if (authToken) {
        const result = await getDataWithAuthToken('user');
        if (result && result.goodStatus) {
          // console.log(result.data);
          dispatch({ type: 'CHANGE_LOGIN_STATUS', payload: true });

          setUserInfo(result.data);
          const { is_validated } = result.data;
          if (!is_validated) {
            handleValidatePopup(result.data);
          }
        } else {
          console.log('err, user is not logged in yet', result);
          setUserInfo({});
        }
      } else {
        console.log('err, user is not logged in yet');
        setUserInfo({});
      }

      setIsLoadingComplete(true);
    }
  }, []);

  const handleValidatePopup = async (userInfo: any) => {
    const popupExpireTime = await getAsyncStorageData(
      '@storage_EmailValidateExpireTime'
    );

    if (
      userInfo.activate_email &&
      (!popupExpireTime ||
        (popupExpireTime && moment().valueOf() - parseInt(popupExpireTime) > 0))
    ) {
      //if no email expiry time or has result but is expired then show alert
      alert(t('userProfileScreen.addEmailAlert'), '', [
        {
          text: t('cancel'),
          style: 'cancel',
          onPress: () => {
            setPopupExpireTime();
          },
        },
        {
          text: t('userProfileScreen.addEmail'),
          onPress: () => {
            navigation.navigate('UserInfo', { data: userInfo });
            navigation.navigate('UserInfoEdit', {
              type: 'email',
              data: userInfo,
            });
          },
        },
      ]);
    }
  };

  const setPopupExpireTime = async () => {
    //Set Popup expire time after close the popup
    const expireTime = moment().add(1, 'd');
    await setAsyncStorageData(
      '@storage_EmailValidateExpireTime',
      expireTime.valueOf().toString()
    );
  };

  return (
    <FullWidthView
      edges={['left', 'right', 'top']}
      safeAreaBackgroundColor={tintColorLight}
    >
      <ProfileHeader
        nickName={userInfo.nick_name}
        profilePicture={userInfo.user_picture}
        userInfo={userInfo}
      />
      {isLoadingComplete ? (
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={styles.mainContentContainer}
        >
          {/* Orders Menu */}
          <ProfileOrderMenu
            unpayOrder={userInfo.unpay_order}
            awaitShipOrder={userInfo.await_ship_order}
            awaitCommentOrder={userInfo.await_comment_order}
          />

          {/* Wallet Menu */}
          <ProfileWalletMenu
            balance={userInfo.user_money}
            points={userInfo.pay_points}
            coupons={userInfo.coupon_num}
          />

          {/* Invite Friends Banner */}
          {userInfo.register_coupon &&
            !getAppStyle(process.env.EXPO_APP_TYPE)?.toHideFeature && (
              <FriendsBanner />
            )}

          {/* Other Menu Options */}
          <ProfileOtherMenu
            showInviteMenu={
              userInfo.register_coupon ? userInfo.register_coupon : false
            }
            showGroupSaleMenu={
              userInfo.show_groupSale ? userInfo.show_groupSale : false
            }
          />
        </ScrollView>
      ) : (
        <Loading />
      )}
    </FullWidthView>
  );
}

const styles = StyleSheet.create({
  rootContainer: {},
  mainContentContainer: {
    backgroundColor: backGroundLight,
  },
});
