import React, { useState } from 'react';
import {
  View,
  StyleSheet,
  TouchableWithoutFeedback,
  GestureResponderEvent,
  Dimensions,
} from 'react-native';
import { CartGoodsType, CartItem, RootState } from '../../types';
import StyledText from '../common/StyledText';
import ResponsiveImg from '../common/ResponsiveImg';

import { AntDesign } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import NavigateButton from '../common/NavigateButton';
import { postWithAuthToken, getCartInformation } from '../../utils/service';
import Toast from 'react-native-tiny-toast';
import AmountCounter from '../Product/widgets/AmountCounter';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../../i18n';
import axios, { CancelTokenSource, CancelToken } from 'axios';
import alert from '../../utils/alert';
import { useMount, useUpdateEffect } from 'ahooks';
import { CheckBox } from 'react-native-elements';
import {
  BLUE3,
  GREEN5,
  GREY2,
  GREY3,
  ORANGE1,
  RED3,
  WHITE5,
} from '../../constants/Colors';

type ProductItemProps = {
  item: CartItem;
  index: number;
  showAllItems: boolean;
  isCalculating: boolean;
  setIsCalculating: React.Dispatch<React.SetStateAction<boolean>>;
  toggleItemSelect: Function;
  allSelected: boolean;
  allDeselected: boolean;
  deselectAllHandler: Function;
};

const { width } = Dimensions.get('window');

const ProductItem = ({
  item,
  index,
  showAllItems,
  isCalculating,
  setIsCalculating,
  toggleItemSelect,
  allSelected,
  allDeselected,
  deselectAllHandler,
}: ProductItemProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [itemAmount, setItemAmount] = useState(item.goods_number);
  const [isSelected, setIsSelected] = useState(item.is_checked);
  const appMainColor = useSelector<RootState, string>(
    (state) => state.user.appMainColor
  );

  useMount(() => {
    setItemAmount(item.goods_number);
  });

  useUpdateEffect(() => {
    let cancelToken: CancelTokenSource;
    if (itemAmount !== item.goods_number) {
      cancelToken = axios.CancelToken.source();
      setIsCalculating(true);
      updateItemAmount(item.rec_id, itemAmount, cancelToken.token)
        .then((_) => {
          //Update cart when product be deleted
          getCartInformation()
            .then((result) => {
              if (result && result.goodStatus) {
                dispatch({
                  type: 'UPDATE_CART_LIST',
                  payload: result.data.cart_list,
                });
                dispatch({
                  type: 'UPDATE_CART_ITEM_COUNTER',
                  payload: result.data.cart_number,
                });
              } else {
                console.log(result);
              }
              setIsCalculating(false);
            })
            .catch((err) => {
              setIsCalculating(false);
              throw err;
            });
        })
        .catch((err) => {
          console.log(err);
          setIsCalculating(false);
        });
    }
    return () => {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('operation canceled due to new request');
      }
    };
  }, [itemAmount]);

  //Update displaying goods amount when user add amount in product detail screen
  useUpdateEffect(() => {
    setItemAmount(item.goods_number);
  }, [item.goods_number]);

  // Update isSelect when user presses all button
  useUpdateEffect(() => {
    setIsSelected(item.is_checked);
  }, [item.is_checked]);

  const handleDeleteItem = (e: GestureResponderEvent) => {
    e.preventDefault();
    alert(
      t('cartScreen.deletePrompt'),
      '',
      [
        {
          text: t('cancel'),
          style: 'cancel',
        },
        {
          text: t('confirm'),
          onPress: () =>
            deleteItemFromCart(item.rec_id)
              .then((_) => {
                //Update cart when product be deleted
                getCartInformation()
                  .then((result) => {
                    if (result && result.goodStatus) {
                      dispatch({
                        type: 'UPDATE_CART_LIST',
                        payload: result.data.cart_list,
                      });
                      dispatch({
                        type: 'UPDATE_CART_ITEM_COUNTER',
                        payload: result.data.cart_number,
                      });
                    } else {
                      console.log(result);
                    }
                  })
                  .catch((err) => {
                    throw err;
                  });
              })
              .catch((err) => console.log(err)),
        },
      ],
      { cancelable: false }
    );
  };

  // Sends request to toggle select once button is pressed
  useUpdateEffect(() => {
    if (isSelected != item.is_checked) {
      if (isSelected) {
        deselectAllHandler(item.ru_id, false);
      }
      setIsCalculating(true);
      toggleItemSelect(item, isSelected)
        .then(
          (
            _:
              | {
                  goodStatus: boolean;
                  return_code: number | undefined;
                  data: any;
                }
              | undefined
          ) => {
            //Update cart when product be deleted
            getCartInformation()
              .then((result) => {
                if (result && result.goodStatus) {
                  dispatch({
                    type: 'UPDATE_CART_LIST',
                    payload: result.data.cart_list,
                  });
                  dispatch({
                    type: 'UPDATE_CART_ITEM_COUNTER',
                    payload: result.data.cart_number,
                  });
                } else {
                  console.log(result);
                }
                setIsCalculating(false);
              })
              .catch((err) => {
                setIsCalculating(false);
                throw err;
              });
          }
        )
        .catch((err: any) => {
          console.log(err);
          setIsCalculating(false);
        });
    }
  }, [isSelected]);

  const visibleStyle = showAllItems
    ? styles.rootContainer
    : index <= 2
    ? styles.rootContainer
    : styles.invisibleItem;

  return (
    <View
      style={[
        visibleStyle,
        {
          backgroundColor: item.is_gift > 0 ? GREEN5 : 'white',
        },
      ]}
    >
      <View style={styles.itemContainer}>
        {item.is_gift === 0 && (
          <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
            <CheckBox
              size={18}
              textStyle={{
                fontSize: 14,
                fontWeight: 'normal',
                color: GREY3,
                paddingBottom: 2,
              }}
              fontFamily={'noto-sans'}
              checked={(isSelected === 1 && !allDeselected) || allSelected}
              onPress={() => {
                if (!isCalculating) {
                  setIsSelected((prevValue) => (prevValue === 0 ? 1 : 0));
                }
              }}
              checkedIcon={
                <AntDesign name="checkcircle" size={22} color={appMainColor} />
              }
              uncheckedIcon={
                <AntDesign name="checkcircle" size={22} color={'lightgray'} />
              }
              containerStyle={{
                backgroundColor: 'white',
                borderWidth: 0,
                marginLeft: 0,
                padding: 0,
                paddingTop: 5,
              }}
            />
          </View>
        )}
        <View style={styles.thumbnailContainer}>
          <DetailNavigator item={item}>
            <>
              <ResponsiveImg
                uri={item.goods_thumb}
                width={Math.min(width / 4, 120)}
              />
              {item.is_invalid && <InvalidProductCover />}
            </>
          </DetailNavigator>
        </View>
        <View style={styles.infoContainer}>
          <View style={styles.infoRow1}>
            <View style={{ flex: 10 }}>
              <DetailNavigator item={item}>
                <>
                  <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    {typeof item.tag === 'string' && item.tag !== '' && (
                      <View style={styles.tag}>
                        <StyledText size={10} color={RED3}>
                          {item.tag}
                        </StyledText>
                      </View>
                    )}
                    {typeof item.weight_tag === 'string' &&
                      item.weight_tag !== '' && (
                        <View style={[styles.tag, { borderColor: BLUE3 }]}>
                          <StyledText size={10} color={BLUE3}>
                            {item.weight_tag}
                          </StyledText>
                        </View>
                      )}
                  </View>
                  <StyledText>{item.goods_name}</StyledText>
                  {item.limit_number > 0 && (
                    <StyledText color={ORANGE1} size={10}>
                      {t('cartScreen.quantityLimit') + item.limit_number}
                    </StyledText>
                  )}
                  <StyledText color={GREY2} size={12}>
                    {item.goods_attr.replace(/\|/g, '\n')}
                  </StyledText>
                </>
              </DetailNavigator>
            </View>

            <TouchableWithoutFeedback
              style={{ flex: 1 }}
              onPress={handleDeleteItem}
            >
              <AntDesign name="delete" size={22} color="lightgray" />
            </TouchableWithoutFeedback>
          </View>

          <View style={styles.infoRow2}>
            <View style={styles.priceContainer}>
              <StyledText color={ORANGE1} bold size={16}>
                {item.formated_goods_price}
              </StyledText>
              <StyledText color={GREY2} size={12}>
                {item.has_tax && ' + Tax'}
              </StyledText>
            </View>
            {item.is_gift === 0 ? (
              <AmountCounter
                amount={itemAmount}
                setAmount={setItemAmount}
                maxQuantity={
                  item.limit_number === 0
                    ? item.stock
                    : item.limit_number >= item.stock
                    ? item.stock
                    : item.limit_number
                }
                minQuantity={
                  item &&
                  item.goods_number_rule &&
                  !Array.isArray(item.goods_number_rule) &&
                  item.goods_number_rule.number
                    ? Number(item.goods_number_rule.number) >= item.stock
                      ? item.stock
                      : Number(item.goods_number_rule.number)
                    : 1
                }
                sign={
                  item &&
                  item.goods_number_rule &&
                  !Array.isArray(item.goods_number_rule) &&
                  item.goods_number_rule.sign
                    ? item.goods_number_rule.sign
                    : undefined
                }
                invalid={item.is_invalid}
              />
            ) : (
              <StyledText size={20}>x {itemAmount}</StyledText>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

type DetailNavigatorProps = {
  children: JSX.Element;
  item: CartItem;
};

const DetailNavigator = ({ children, item }: DetailNavigatorProps) => {
  switch (item.rec_type) {
    default:
    case CartGoodsType.GENERAL_GOODS:
      return (
        <NavigateButton
          route="ProductNavigator"
          options={{
            params: { productId: item.goods_id },
            screen: 'ProductDetailScreen',
          }}
        >
          {children}
        </NavigateButton>
      );

    case CartGoodsType.CART_PACKAGE_GOODS:
      return (
        <NavigateButton
          route="BundleDetail"
          options={{
            id: item.goods_id,
            time: item.best_shipping_time,
          }}
        >
          {children}
        </NavigateButton>
      );
  }
};

const InvalidProductCover = () => {
  const { t } = useTranslation();

  return (
    <View style={styles.invalidProductCoverContainer}>
      <View style={styles.invalidProductCoverTextContainer}>
        <StyledText color="white" align="center" size={16}>
          {t('cartScreen.invalidProduct')}
        </StyledText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  rootContainer: {
    backgroundColor: 'white',
  },
  invisibleItem: {
    display: 'none',
  },
  itemContainer: {
    flexDirection: 'row',
    marginHorizontal: 10,
    paddingVertical: 5,
    borderBottomColor: WHITE5,
    borderBottomWidth: 1,
  },
  thumbnailContainer: {
    flex: 1,
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoContainer: {
    flex: 2,
    paddingVertical: 5,
    justifyContent: 'space-between',
  },
  infoRow1: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoRow2: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  priceContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  invalidProductCoverContainer: {
    backgroundColor: 'rgba(0,0,0,.5)',
    position: 'absolute',
    width: Math.min(width / 4, 100),
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  invalidProductCoverTextContainer: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: 'white',
    height: 80,
    width: 80,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tag: {
    borderWidth: 1,
    borderColor: RED3,
    padding: 2,
    borderRadius: 5,
    marginRight: 3,
    alignSelf: 'flex-start',
  },
});

export default ProductItem;

function deleteItemFromCart(recordId: number) {
  return new Promise((resolve, reject) => {
    postWithAuthToken('cart/delete', { id: recordId })
      .then((result) => {
        if (result && result.goodStatus) {
          //Successfully deleted
          Toast.showSuccess(i18n.t('productInformation.deleteSuccessfully'));
          resolve(result);
        } else {
          Toast.show(result ? result.data : 'Error');
          reject(result);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function updateItemAmount(
  recordId: number,
  amount: number,
  cancelToken: CancelToken
) {
  return new Promise((resolve, reject) => {
    postWithAuthToken(
      'cart/edit',
      { id: recordId, number: amount },
      { cancelToken: cancelToken }
    )
      .then((result) => {
        if (result && result.goodStatus) {
          //Successfully update
          resolve(result);
        } else {
          if (!(result && result.return_code === 499)) {
            Toast.show(result ? result.data : 'Error');
            reject(result);
          }
        }
      })
      .catch((err) => reject(err));
  });
}
