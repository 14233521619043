import { AntDesign } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import * as WechatShare from 'react-native-wechat-lib';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import alert from '../../../utils/alert';
import WechatShareModal from './WechatShareModal';

type WechatShareBtnProps = {
  title: string;
  url: string;
  imageUrl?: string;
  description?: string;
};

export const WechatShareBtn = ({
  title,
  url,
  imageUrl,
  description = '',
}: WechatShareBtnProps) => {
  const [showButton, setShowButton] = useState(false);
  const [visible, setVisible] = useState(false);
  const otherAppId = useSelector<RootState, string>(
    (state) => state.user.otherAppId
  );
  const universalLink = useSelector<RootState, string>(
    (state) => state.user.universalLink
  );

  useEffect(() => {
    (async () => {
      const isRegistered = await WechatShare.registerApp(
        otherAppId,
        universalLink
      );

      setTimeout(async () => {
        const isInstalled = await WechatShare.isWXAppInstalled();
        setShowButton(isInstalled ? true : false);
      }, 800);
    })();
  }, []);

  const hideModal = () => {
    setVisible(false);
  };

  const handleShare = async (type: 'wechat' | 'moments') => {
    const isInstalled = await WechatShare.isWXAppInstalled();
    if (isInstalled) {
      WechatShare.shareWebpage({
        title: title,
        description: description,
        webpageUrl: url,
        thumbImageUrl: imageUrl
          ? imageUrl
          : 'https://image.luniumall.com/themes/ecmoban_dsc2017/images/logo.gif',
        scene: type === 'wechat' ? 0 : 1,
      })
        .then((result) => {
          console.log(result);
          hideModal();
        })
        .catch((err) => {
          console.log(err);
          hideModal();
        });
    } else {
      alert('请先安装微信');
    }
  };

  return showButton ? (
    <>
      <TouchableOpacity onPress={() => setVisible(true)}>
        <View style={{ marginRight: 10 }}>
          <AntDesign name="sharealt" size={24} color="black" />
        </View>
      </TouchableOpacity>
      <WechatShareModal
        visible={visible}
        hideModal={hideModal}
        handleShare={handleShare}
      />
    </>
  ) : (
    <></>
  );
};
