import React from 'react';
import { StyleSheet, View, TouchableWithoutFeedback } from 'react-native';
import {
  normalTextColor,
  backGroundLight,
  tintColorLight,
  subTextColor,
  subColorLight,
} from '../../../constants/Colors';
import StyledText from '../../common/StyledText';
import CachedImage from '../../common/cache-img/CachedImage';
import AdminReply from '../../common/comment/AdminReply';
import ProductItemInList from '../../../components/Product/ProductItemInList';
import PhotoGrid from './PhotoGrid';
import { Comment, ProductInformation, CommentImage } from '../../../types';
import { NavigationProp, ParamListBase } from '@react-navigation/core';
import { FontAwesome } from '@expo/vector-icons';
import ViewMoreText from 'react-native-view-more-text';

type PostCardProps = Comment & {
  goods: ProductInformation;
  tag: string;
  user_id: number;
  disabled?: boolean;
  detailFunction?: Function;
  navigation: NavigationProp<ParamListBase>;
  highlightPostId?: number;
  isDetailScreen: boolean;
  adminReply: {comment_id: number, content: string} | null;
};

class PostCard extends React.Component<PostCardProps, any> {
  constructor(props: PostCardProps) {
    super(props);
  }

  // If 'highlightPostId' change, update the ListHeaderComponent.
  shouldComponentUpdate(nextProps: PostCardProps) {
    if (this.props.isDetailScreen === false) return false;

    if (
      this.props.highlightPostId &&
      nextProps.highlightPostId !== this.props.highlightPostId
    ) {
      return true;
    }
    return false;
  }

  // Fill up image array that is not able to 'length % 3 = 0'.
  addEmptyPicture = (imageArray: Array<CommentImage>) => {
    const number = imageArray.length % 3;
    const emptyPicture = { id: -1, comment_id: -1, comment_img: '' };
    switch (number) {
      case 1:
        return [...imageArray, emptyPicture, emptyPicture];
      case 2:
        return [...imageArray, emptyPicture];
      default:
        return imageArray;
    }
  };

  render() {
    const navigation = this.props.navigation;

    const handlePostPress = (postId: number, user_id: number) => {
      if (this.props.detailFunction) {
        this.props.detailFunction();
      } else {
        navigation.navigate('PostDetail', { postId, user_id });
      }
    };

    return (
      <>
        <View style={styles.cardContainer}>
          <TouchableWithoutFeedback
            disabled={this.props.disabled}
            onPress={() =>
              handlePostPress(this.props.comment_id, this.props.user_id)
            }
          >
            <View style={styles.cardTopWrapper}>
              <View style={styles.headerInfoWrapper}>
                <View style={styles.infoWrapper}>
                  <CachedImage
                    style={{
                      width: 35,
                      height: 35,
                      borderRadius: 100,
                      marginRight: 10,
                    }}
                    source={{ uri: this.props.user_picture }}
                  />
                  <StyledText color={normalTextColor} size={15}>
                    {this.props.nick_name}
                  </StyledText>
                </View>
                <View style={{ flex: 1, alignItems: 'flex-end' }}>
                  <StyledText color={subTextColor} size={12}>
                    {this.props.add_time}
                  </StyledText>
                </View>
              </View>
              <View style={styles.headerTextWrapper}>
                {this.props.tag ? (
                  <View style={styles.withHotTagContainer}>
                    <View style={styles.hotTag}>
                      <FontAwesome name="star" size={14} color="white" />
                      <StyledText style={styles.hotText}>
                        {this.props.tag}
                      </StyledText>
                    </View>
                    <ViewMoreText
                      numberOfLines={3}
                      renderViewMore={this.renderViewMore}
                      renderViewLess={this.renderViewLess}
                    >
                      <StyledText color={normalTextColor} size={15}>
                        {`            ${this.props.content}`}
                      </StyledText>
                    </ViewMoreText>
                  </View>
                ) : (
                  <ViewMoreText
                    numberOfLines={3}
                    renderViewMore={this.renderViewMore}
                    renderViewLess={this.renderViewLess}
                  >
                    <StyledText color={normalTextColor} size={15}>
                      {this.props.content}
                    </StyledText>
                  </ViewMoreText>
                )}
              </View>

              <PhotoGrid
                pictures={this.addEmptyPicture(this.props.images)}
                style={{ marginBottom: 5 }}
              />
              <AdminReply admin_reply={this.props.adminReply} showFullContent={false} post/>
            </View>
          </TouchableWithoutFeedback>
          <View style={styles.cardBottomWrapper}>
            <ProductItemInList data={this.props.goods} bottomDivider={false} />
          </View>
        </View>
      </>
    );
  }

  renderViewMore = (handlePress: any) => {
    return (
      <StyledText style={styles.expandText} onPress={handlePress}>
        Read more
      </StyledText>
    );
  };

  renderViewLess = (handlePress: any) => {
    return (
      <StyledText style={{ display: 'none' }} onPress={handlePress}>
        Show less
      </StyledText>
    );
  };
}

export default PostCard;

const styles = StyleSheet.create({
  cardContainer: {
    width: '100%',
    backgroundColor: 'white',
    marginVertical: 4,
    borderRadius: 10,
    paddingHorizontal: 8,
  },
  cardTopWrapper: {
    borderBottomWidth: 1,
    borderColor: backGroundLight,
    padding: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  headerInfoWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTextWrapper: {
    position: 'relative',
    marginVertical: 10,
  },
  withHotTagContainer: {
    flex: 1,
    position: 'relative',
  },
  hotTag: {
    position: 'absolute',
    flexDirection: 'row',
    backgroundColor: subColorLight,
    alignItems: 'center',
    justifyContent: 'center',
    top: 2,
    paddingHorizontal: 4,
    borderRadius: 4,
  },
  hotText: {
    textTransform: 'capitalize',
    marginLeft: 2,
    fontSize: 12,
    color: 'white',
  },
  expandText: {
    textAlign: 'right',
    left: 0,
    color: tintColorLight,
  },

  cardBottomWrapper: {
    backgroundColor: 'white',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    marginTop: 0,
  },
});
