import React from 'react';
import { View, StyleSheet, FlatList, TouchableOpacity } from 'react-native';
import StyledText from '../common/StyledText';
import { FontAwesome, AntDesign } from '@expo/vector-icons';
import Comment from '../../components/common/Comment';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { normalTextColor, backGroundLight } from '../../constants/Colors';
import { GoodsInfo, Comment as CommentType } from '../../types';

type ProductComments = {
  comments: Pick<GoodsInfo, 'comments'>;
};

type renderItemParam = {
  item: CommentType;
};

const ProductComments = ({
  comments,
  goods_id: productId,
}: Pick<GoodsInfo, 'goods_id' | 'comments'>) => {
  const { comments: commentArray, avg_rank, total_item, total_page } = comments;
  const { t } = useTranslation();
  const navigation = useNavigation();

  const handleSeeMorePress = () => {
    navigation.navigate('CommentScreen', {
      productId: productId,
    });
  };

  const _renderItem = ({ item }: renderItemParam) => {
    return (
      <View style={styles.commentContainer}>
        <TouchableOpacity onPress={handleSeeMorePress}>
          <Comment
            comment_id={item.comment_id}
            nick_name={item.nick_name}
            comment_rank={item.comment_rank}
            content={item.content}
            user_picture={item.user_picture}
            add_time={item.add_time}
            images={item.images}
            admin_reply={item.admin_reply}
            productId={productId}
            showFullContent={false}
          />
        </TouchableOpacity>
      </View>
    );
  };

  const renderRating = (rank: string) => {
    let rating = parseFloat(rank);
    let totalIcons = 0;

    return (
      <View style={styles.rating}>
        {Array.from(Array(Math.floor(rating))).map((item, index) => {
          totalIcons = totalIcons + 1;
          return <FontAwesome key={index} name="star" size={12} color="red" />;
        })}
        {Array.from(Array(Math.round(rating - Math.floor(rating)))).map(
          (item, index) => {
            totalIcons = totalIcons + 1;
            return (
              <FontAwesome
                key={index}
                name="star-half-o"
                size={12}
                color="red"
              />
            );
          }
        )}
        {Array.from(Array(5 - totalIcons)).map((item, index) => (
          <FontAwesome key={index} name="star" size={12} color="gray" />
        ))}
        <StyledText style={{ paddingBottom: 1, paddingLeft: 2 }} size={12}>
          {rank}
        </StyledText>
      </View>
    );
  };

  if (commentArray.length > 0) {
    return (
      <View style={styles.contentContainer}>
        <View style={styles.SectionHeader}>
          <StyledText size={16} bold style={styles.headerTitle}>
            {t('productDetailScreen.productReviews')}({total_item})
          </StyledText>
          {renderRating(avg_rank)}
        </View>
        <FlatList
          showsHorizontalScrollIndicator={false}
          data={commentArray}
          renderItem={_renderItem}
          keyExtractor={(item: CommentType) => item.comment_id.toString()}
          horizontal
          style={styles.horizontalList}
          ListFooterComponent={<ListFooter productId={productId} />}
        />
      </View>
    );
  } else {
    return (
      <View style={{ opacity: 0 }}>
        <StyledText size={1}>No Content, Placeholder</StyledText>
      </View>
    );
  }
};

type ListFooterProps = {
  productId: number;
};

const ListFooter = ({ productId }: ListFooterProps) => {
  const { t } = useTranslation();

  const navigation = useNavigation();

  const handleSeeMorePress = () => {
    navigation.navigate('CommentScreen', {
      productId: productId,
    });
  };

  return (
    <View style={styles.seeMoreBtnContainer}>
      <View style={styles.seeMoreBtn}>
        <TouchableOpacity onPress={handleSeeMorePress}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <StyledText>{t('seeMore')}</StyledText>
            <AntDesign
              name="rightcircleo"
              size={18}
              color={normalTextColor}
              style={{ marginLeft: 5 }}
            />
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    width: '100%',
    paddingVertical: 15,
    backgroundColor: 'white',
    marginBottom: 15,
  },
  SectionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingHorizontal: 10,
  },
  horizontalList: {
    paddingLeft: 10,
  },
  headerTitle: {
    paddingBottom: 15,
  },
  rating: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  seeMoreBtnContainer: {
    flex: 1,
    justifyContent: 'center',
    marginRight: 10,
  },
  seeMoreBtn: {
    borderColor: backGroundLight,
    padding: 10,
    borderRadius: 10,
    borderWidth: 1,
  },
  commentContainer: {
    width: 250,
    borderWidth: 1,
    borderColor: 'lightgray',
    borderRadius: 5,
    padding: 10,
    marginRight: 10,
  },
});

export default ProductComments;
