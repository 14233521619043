import React, { useEffect, useState, useRef } from 'react';
import { FlatList } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { getDataWithAuthToken } from '../../../utils/service';
import ActivityIndicator from '../../../components/common/ActivityIndicator';
import ListFooterText from '../../../components/common/list/ListFooterText';
import { UncommentList, CommentListItem } from '../../../types';
import CommentedReviewListItem from './CommentedReviewListItem';
import { useHeaderHeight } from '@react-navigation/stack';

type CommentedReviewListScreenProps = {
  currentIndex: number;
};

const CommentedReviewListScreen = ({
  currentIndex,
}: CommentedReviewListScreenProps) => {
  const navigation = useNavigation();
  const [comments, setComments] = useState<Array<CommentListItem>>([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState<UncommentList['total_page']>(1);
  const [allLoaded, setAllLoaded] = useState(false);
  const isMounted = useRef(true);
  const [isFetching, setIsFetching] = useState(false);

  const getGoodsCommented = () => {
    setIsFetching(true);
    getDataWithAuthToken(`user/comments?type=0&page=1&size=10`)
      .then((response) => {
        if (isMounted.current) {
          setAllLoaded(false);
          if (response && response.goodStatus) {
            setComments(response.data.comments);
            setTotalPage(response.data.total_page);
            page === response.data.total_page &&
              response.data.comments.length <= 10 &&
              setAllLoaded(true);
          } else {
            throw response;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getGoodsCommented();
  }, [currentIndex]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      //get comment list
      getGoodsCommented();

      //reset comment screen state when leaving so that we can set it again on return
      navigation.addListener('blur', () => {
        setPage(1);
        setComments([]);
        setTotalPage(1);
      });
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    if (page > 1) {
      setIsFetching(true);
      //get comment list
      getDataWithAuthToken(`user/comments?type=0&page=${page}&size=10`)
        .then((response) => {
          if (isMounted.current) {
            if (response && response.goodStatus) {
              setComments((lastValue: Array<CommentListItem>) => [
                ...lastValue,
                ...response.data.comments,
              ]);
              setTotalPage(response.data.total_page);
              page === response.data.total_page &&
                response.data.comments.length <= 10 &&
                setAllLoaded(true);
            } else {
              throw response;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
  }, [page]);

  const loadExtraData = async () => {
    if (isFetching) return;
    if (page < totalPage) {
      setPage((lastValue) => lastValue + 1);
    }
  };

  const _renderItem = ({ item }: { item: CommentListItem }) => {
    return (
      <CommentedReviewListItem
        goods_id={item.goods_id}
        goods_name={item.goods_name}
        nick_name={item.nick_name}
        comment_rank={item.comment_rank}
        user_picture={item.user_picture}
        add_time={item.add_time}
        content={item.content}
        images={item.images}
        comment_id={item.comment_id}
        goods_thumb={item.goods_thumb}
        setComments={setComments}
        comments={comments}
      />
    );
  };

  return (
    <FlatList
      style={{ flex: 1 }}
      contentContainerStyle={{ backgroundColor: '#FFFF' }}
      data={comments}
      renderItem={_renderItem}
      keyExtractor={(item: CommentListItem) => item.comment_id.toString()}
      onEndReached={() => loadExtraData()}
      onEndReachedThreshold={0.01}
      ListFooterComponent={allLoaded ? ListFooterText : ActivityIndicator}
    />
  );
};

export default CommentedReviewListScreen;
