import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * Set Async Storage Data data
 * @param key key prefix: `@storage_xxx`
 * @param value
 */
export async function setAsyncStorageData(key: string, value: string) {
  try {
    // @storage_LangrageReference
    await AsyncStorage.setItem(key, value);
  } catch (e) {
    // saving error
    console.log(e);
  }
}

/**
 * Get Async Storage Data
 * @param key
 */
export async function getAsyncStorageData(key: string) {
  try {
    const value = await AsyncStorage.getItem(key);
    return value;
  } catch (e) {
    // error reading value
    console.log(e);
  }
}

/**
 * Remove single async Storage Data
 * @param key
 */
export async function removeAsyncStorageData(key: string) {
  try {
    await AsyncStorage.removeItem(key, (err) => {
      if (err) console.log('Error when delete async storage', err);
      else console.log(`Done. ${key} deleted`);
    });
  } catch (e) {
    // remove error
    console.log(e);
  }
}

/**
 * Clear all Storage Data
 */
export async function clearAllAsyncStorage() {
  try {
    await AsyncStorage.clear();
  } catch (e) {
    // clear error
    console.log(e);
  }

  console.log('Done. All AsyncStorage deleted');
}

/**
 * For caching home screen data
 * @param data
 */
export function saveHomeDataToLocal(data: object) {
  try {
    AsyncStorage.setItem('@storage_HomeData', JSON.stringify(data));
  } catch (e) {
    // saving error
    console.log(e);
  }
}

/**
 * For caching category screen data
 * @param data
 */
export function saveCategoryDataToLocal(data: object) {
  try {
    AsyncStorage.setItem('@storage_CategoryData', JSON.stringify(data));
  } catch (e) {
    // saving error
    console.log(e);
  }
}

/**
 *Get cache home data from AsyncStorage
 */
export async function getLocalHomeData() {
  try {
    const value = await AsyncStorage.getItem('@storage_HomeData');
    return value;
  } catch (e) {
    // error reading value
    console.log(e);
  }
}

/**
 *Get cache category data from AsyncStorage
 */
export async function getLocalCategoryData() {
  try {
    const value = await AsyncStorage.getItem('@storage_CategoryData');
    return value;
  } catch (e) {
    // error reading value
    console.log(e);
  }
}
