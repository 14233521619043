import { useNavigation } from '@react-navigation/native';
import { useCounter } from 'ahooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlatList,
  Image,
  View,
  TouchableWithoutFeedback,
  Platform,
} from 'react-native';
import { StyleSheet } from 'react-native';
import Toast from 'react-native-tiny-toast';
import { useDispatch } from 'react-redux';
import { accentColor, backGroundLight } from '../../constants/Colors';
import { BundleObject, BundleProduct } from '../../types';
import { postWithAuthToken } from '../../utils/service';
import ActivityIndicator from '../common/ActivityIndicator';
import ListFooterText from '../common/list/ListFooterText';
import StyledButton from '../common/StyledButton';
import StyledText from '../common/StyledText';
import WhiteCard from '../common/WhiteCard';
import BundleNotice from './BundleNotice';

type BundleListProps = {
  data: Array<BundleObject>;
  allDataLoaded: boolean;
  currentPage: number;
  totalPage: number;
  bundleNotice: { title: string; content: string };
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  isFetching: boolean;
};

const BundleList = ({
  data,
  allDataLoaded,
  currentPage,
  totalPage,
  setCurrentPage,
  bundleNotice,
  isFetching,
}: BundleListProps) => {
  const renderItem = ({ item }: { item: BundleObject }) => (
    <BundleListItem bundleItem={item} />
  );
  const loadMore = () => {
    if (isFetching) return;
    if (currentPage < totalPage) {
      setCurrentPage((oldValue) => oldValue + 1);
    }
  };

  return (
    <View style={styles.container}>
      {Platform.OS === 'web' ? (
        <>
          {data.length > 0 ? (
            <>
              {data.map((item, index) => {
                return <BundleListItem key={index} bundleItem={item} />;
              })}
              <ListFooterText />
              <BundleNotice
                title={bundleNotice.title}
                content={bundleNotice.content}
              />
            </>
          ) : (
            <ActivityIndicator />
          )}
        </>
      ) : (
        <FlatList
          data={data}
          renderItem={renderItem}
          keyExtractor={(item) => item.act_id.toString()}
          showsVerticalScrollIndicator={false}
          onEndReached={loadMore}
          onEndReachedThreshold={0.01}
          ListFooterComponent={
            allDataLoaded ? (
              <>
                <ListFooterText />
                <BundleNotice
                  title={bundleNotice.title}
                  content={bundleNotice.content}
                />
              </>
            ) : (
              <ActivityIndicator />
            )
          }
        />
      )}
    </View>
  );
};

const BundleListItem = ({ bundleItem }: { bundleItem: BundleObject }) => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [current, { set, inc }] = useCounter(0, {
    min: 0,
  });

  const handleNavigateToDetail = () => {
    navigation.navigate('BundleDetail', {
      id: bundleItem.act_id,
      time: bundleItem.shipping_time,
    });
  };

  const handleAddToCart = () => {
    (async () => {
      const result = await addBundleToCart(
        bundleItem.act_id,
        bundleItem.shipping_time,
        1
        // current !== 0 ? current : 1
      );
      if (result && result.goodStatus) {
        inc(1);
        Toast.showSuccess(t('productInformation.addSuccessfully'));
        dispatch({
          type: 'UPDATE_CART_ITEM_COUNTER',
          payload: result.data.total_number,
        });
      } else if (result && result.data) {
        Toast.show(t('productInformation.addFailed') + `, ${result.data}`, {
          position: 0,
          duration: 2000,
        });
      } else {
        Toast.show(t('productInformation.addFailed'), {
          position: 0,
          duration: 2000,
        });
      }
    })();
  };

  return (
    <View style={styles.bundleItemContainer}>
      <WhiteCard>
        <TouchableWithoutFeedback onPress={handleNavigateToDetail}>
          <View>
            <StyledText bold align="center" size={20}>
              {bundleItem.act_name}
            </StyledText>
            <StyledText align="center" style={styles.description}>
              {bundleItem.act_desc}
            </StyledText>
          </View>
        </TouchableWithoutFeedback>
        <ItemList
          products={bundleItem.package_goods.goods}
          navigateFun={handleNavigateToDetail}
        />

        <TouchableWithoutFeedback onPress={handleNavigateToDetail}>
          <View style={styles.packageInfoContainer}>
            <View
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                borderRightWidth: 1,
                borderRightColor: backGroundLight,
              }}
            >
              <StyledText bold size={20} color={accentColor}>
                {bundleItem.package_goods.item_number}
              </StyledText>
              <StyledText>{t('bundleScreen.bundleList.items')}</StyledText>
            </View>

            <View
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <StyledText bold size={20} color={accentColor}>
                {bundleItem.formated_package_price}
              </StyledText>
              <StyledText bold size={12} style={styles.originalPrice}>
                {bundleItem.package_goods.formated_total_amount}
              </StyledText>
            </View>
            {bundleItem.day_sales_volume >= 10 && (
              <View style={styles.saleIndicator}>
                <StyledText align="center" size={20}>
                  {bundleItem.day_sales_volume}
                </StyledText>
                <StyledText align="center">
                  {t('bundleScreen.bundleList.sold')}
                </StyledText>
              </View>
            )}
          </View>
        </TouchableWithoutFeedback>

        <View>
          {current === 0 ? (
            <StyledButton
              accent
              label={
                bundleItem.stock <= 0
                  ? t('bundleScreen.bundleList.soldOut')
                  : t('bundleScreen.bundleList.buy')
              }
              onPress={handleAddToCart}
              disabled={bundleItem.stock <= 0}
            />
          ) : (
            <StyledButton
              accent
              label={
                current < bundleItem.stock
                  ? t('bundleScreen.bundleList.addAnother')
                  : t('bundleScreen.bundleList.soldOut')
              }
              onPress={handleAddToCart}
              disabled={current >= bundleItem.stock}
            />
          )}
        </View>
      </WhiteCard>
    </View>
  );
};

type ItemListProps = {
  products: Array<BundleProduct>;
  navigateFun: () => void;
};

const ItemList = ({ products, navigateFun }: ItemListProps) => {
  const renderItem = ({ item }: { item: BundleProduct }) => (
    <TouchableWithoutFeedback onPress={navigateFun}>
      <View style={{ flex: 1 }}>
        <ItemThumbnail product={item} />
      </View>
    </TouchableWithoutFeedback>
  );

  return (
    <View>
      <FlatList
        data={products}
        renderItem={renderItem}
        keyExtractor={(item, index) =>
          `${item.goods_id.toString()}@${item.product_id}@${index}`
        }
        horizontal
        showsHorizontalScrollIndicator={false}
      />
    </View>
  );
};

type ItemThumbnailProps = {
  product: BundleProduct;
};

const ItemThumbnail = ({ product }: ItemThumbnailProps) => {
  const { t } = useTranslation();
  return (
    <View style={styles.itemInfo}>
      <View style={{ alignItems: 'center' }}>
        <Image
          style={{ width: 80, height: 80 }}
          source={{ uri: product.goods_thumb }}
        />
      </View>
      <StyledText>{product.formated_shop_price}</StyledText>
      <StyledText numberOfLines={2}>{product.goods_name}</StyledText>
      <View
        style={{
          flex: 1,
          justifyContent: 'flex-end',
          paddingTop: 5,
        }}
      >
        <StyledText align="right">
          {t('bundleScreen.bundleList.each', { amount: product.goods_number })}
        </StyledText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 10,
  },
  bundleItemContainer: {
    marginTop: 10,
  },
  saleIndicator: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderLeftWidth: 1,
    borderLeftColor: backGroundLight,
  },
  packageInfoContainer: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  originalPrice: {
    color: 'lightgray',
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  itemInfo: {
    borderColor: backGroundLight,
    borderRadius: 15,
    borderWidth: 1,
    width: 100,
    marginVertical: 10,
    marginHorizontal: 2,
    padding: 5,
    flex: 1,
  },
  description: {
    marginTop: 10,
  },
});

const addBundleToCart = async (
  packageId: number,
  time: number,
  amount: number
) => {
  const result = await postWithAuthToken('cart/package/add', {
    package_id: packageId,
    shipping_time: time,
    number: amount,
  });
  return result;
};

export default BundleList;
