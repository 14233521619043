import React, { useEffect, useRef, useState } from 'react';
import {
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  Platform,
  BackHandler,
} from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { WebView, WebViewMessageEvent } from 'react-native-webview';
import FullWidthView from '../../components/common/FullWidthView';
import StyledText from '../../components/common/StyledText';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { parse } from 'url';
import queryString from 'query-string';
import BackButton from '../../components/common/iconButtons/BackButton';

type HomeWebviewScreenProps = {
  route: any;
};

const { width, height } = Dimensions.get('window');

const HomeWebviewScreen = ({ route }: HomeWebviewScreenProps) => {
  const linkTo = useLinkTo();
  const navigation = useNavigation();

  const [isError, setIsError] = useState(false);
  const { uri, title } = route.params;
  const webViewRef = useRef<WebView>(null);

  const handlePressLink = (url: string) => {
    try {
      const link = parse(url);
      const query = link.query || '';
      const queryObject = queryString.parse(query);
      const openInApp =
        queryObject.openInApp && queryObject.openInApp === 'true';
      if (query && openInApp && link.path) {
        linkTo(`/${link.path}`);
        return false;
      } else {
        return true;
      }
    } catch (error) {
      // => TypeError, "Failed to construct URL: Invalid URL"
      console.log(error);
      return false;
    }
  };

  const getTitle = `
    window.ReactNativeWebView.postMessage(document.title);
    document.addEventListener("click", () => {
      window.ReactNativeWebView.postMessage(document.title);
    });
    true;
  `;

  const onWebViewMessage = ({ nativeEvent }: WebViewMessageEvent) => {
    navigation.setOptions({
      title: nativeEvent.data,
      headerLeft: () =>
        nativeEvent.canGoBack ? (
          <TouchableOpacity onPress={onBackPress}>
            <BackButton />
          </TouchableOpacity>
        ) : (
          <></>
        ),
    });
  };

  const onBackPress = () => {
    if (webViewRef.current) {
      webViewRef.current.goBack();
      webViewRef.current.injectJavaScript(getTitle);
      return true; // prevent default behavior (exit app)
    }
    return false;
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      navigation.setOptions({ title: title });
    } else {
      if (Platform.OS === 'android') {
        BackHandler.addEventListener('hardwareBackPress', onBackPress);
        return () => {
          BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        };
      }
    }
  }, []);

  return isError ? (
    <FullWidthView style={styles.errorContainer}>
      <MaterialIcons name="error" size={24} color="lightgray" />
      <StyledText color={'lightgray'}>Failed to retrieve page</StyledText>
    </FullWidthView>
  ) : (
    <FullWidthView>
      <WebView
        ref={webViewRef}
        source={{ uri: uri }}
        onError={(err) => {
          setIsError(true);
          console.log(err);
        }}
        style={[styles.webviewContainer]}
        onShouldStartLoadWithRequest={(request) => {
          if (request.url === uri) {
            return true;
          } else {
            return handlePressLink(request.url);
          }
        }}
        onMessage={Platform.OS === 'web' ? undefined : onWebViewMessage}
        onLoad={() => {
          if (Platform.OS !== 'web' && webViewRef.current) {
            webViewRef.current.injectJavaScript(getTitle);
          }
        }}
      />
    </FullWidthView>
  );
};

const styles = StyleSheet.create({
  errorContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  webviewContainer: {
    width: width,
    height: height,
    flex: 1,
  },
});

export default HomeWebviewScreen;
