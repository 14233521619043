import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  backGroundLight,
  normalTextColor,
  subTextColor,
  warningRed,
} from '../../constants/Colors';
import { CouponType } from '../../types';
import StyledText from '../common/StyledText';

type CouponItemProps = {
  item: CouponType;
};

const CouponItem = ({ item }: CouponItemProps) => {
  const isInvalid = item.is_invalid === 1 ? true : false;

  return (
    <View style={styles.container}>
      <View style={styles.leftContainer}>
        <View style={styles.couponMainContent}>
          <StyledText
            size={16}
            bold
            color={isInvalid ? subTextColor : normalTextColor}
          >
            {item.cou_title}
          </StyledText>

          <StyledText>{item.coupon_time_desc}</StyledText>
        </View>
        <View style={styles.couponFooter}>
          <StyledText size={12}>{item.coupon_desc}</StyledText>
        </View>
      </View>
      <View
        style={[
          styles.rightContainer,
          isInvalid ? styles.invalidStyle : styles.validStyle,
        ]}
      >
        <StyledText size={28} bold color="white">
          {item.formated_coupon_amount}
        </StyledText>
        <StyledText color="white" align="center">
          {item.coupon_amount_desc}
        </StyledText>
      </View>
      <View style={styles.dotTop}></View>
      <View style={styles.dotBottom}></View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 10,
    marginTop: 10,
    overflow: 'hidden',
    borderRadius: 8,
    flexDirection: 'row',
    position: 'relative',
  },
  leftContainer: {
    width: '68%',
  },
  rightContainer: {
    width: '32%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  validStyle: {
    backgroundColor: '#2cae66',
  },
  invalidStyle: {
    backgroundColor: 'lightgray',
  },
  couponMainContent: {
    backgroundColor: 'white',
    padding: 10,
    paddingBottom: 5,
  },
  couponFooter: {
    borderTopColor: backGroundLight,
    borderTopWidth: 1,
    backgroundColor: 'white',
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  dotTop: {
    width: 16,
    height: 16,
    backgroundColor: backGroundLight,
    borderRadius: 15,
    position: 'absolute',
    top: -8,
    right: '30%',
  },
  dotBottom: {
    width: 16,
    height: 16,
    backgroundColor: backGroundLight,
    borderRadius: 15,
    position: 'absolute',
    bottom: -8,
    right: '30%',
  },
});

export default CouponItem;
