import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { backGroundLight, subTextColor } from '../../constants/Colors';
import { CartGoodsType } from '../../types';
import NavigateButton from '../common/NavigateButton';
import ResponsiveImg from '../common/ResponsiveImg';
import StyledText from '../common/StyledText';

type ItemDataType = {
  rec_id: number;
  ru_id: number;
  tid: number;
  shipping_fee: string;
  user_id: number;
  goods_id: number;
  goods_name: string;
  goods_price: string;
  main_goods_price: string;
  goods_number: number;
  goods_attr: string;
  is_real: number;
  is_gift: number;
  extension_code: string;
  is_invalid: false;
  goods_thumb: string;
  stock: number;
  limit_number: number;
  subtotal: number;
  main_subtotal: number;
  formated_goods_price: string;
  has_tax: boolean;
  rec_type: CartGoodsType;
  package_goods?: {
    goods: PackageGoods[];
    item_number: number;
    total_amount: number;
    formated_total_amount: string;
  };
};

type PackageGoods = {
  package_id: number;
  goods_id: number;
  product_id: number;
  goods_number: number;
  goods_name: string;
  shop_price: number;
  goods_brief: string;
  goods_thumb: string;
  stock: number;
  goods_attr_id: string;
  goods_attr: string;
  invalid_stock: false;
  formated_shop_price: string;
};

type BundleItemListProps = {
  bundle: ItemDataType;
  pageType?: 'preview' | 'detail';
};

const BundleItemList = ({
  bundle,
  pageType = 'detail',
}: BundleItemListProps) => {
  const { t } = useTranslation();

  const renderItems = bundle.package_goods?.goods.map((item, index) => {
    return (
      <View style={styles.listItem} key={index}>
        <NavigateButton
          route="ProductNavigator"
          options={{
            params: { productId: item.goods_id },
            screen: 'ProductDetailScreen',
          }}
          style={styles.listItemLeftContainer}
          disabled={pageType === 'preview'}
        >
          <ResponsiveImg width={30} uri={item.goods_thumb} />
          <View style={{ flexShrink: 1, marginLeft: 3 }}>
            <StyledText size={12} numberOfLines={3}>
              {item.goods_name}
            </StyledText>
            <StyledText color={subTextColor} size={12}>
              {item.goods_attr.replace(/\|/g, '\n')}
            </StyledText>
          </View>
        </NavigateButton>

        <View style={styles.listItemRightContainer}>
          <StyledText>x{item.goods_number}</StyledText>
        </View>
      </View>
    );
  });

  return (
    <View style={styles.bundleItem}>
      <View style={styles.bundleHeader}>
        <View style={styles.leftContainer}>
          <ResponsiveImg width={50} uri={bundle.goods_thumb} />
          <View style={{ flexShrink: 1, marginLeft: 3 }}>
            <StyledText>{bundle.goods_name}</StyledText>
          </View>
        </View>
        <View style={styles.rightContainer}>
          <StyledText>x{bundle.goods_number}</StyledText>
          <View>
            <StyledText>{bundle.formated_goods_price}</StyledText>
            {bundle.has_tax && (
              <StyledText align="center" color={subTextColor} size={10}>
                + Tax
              </StyledText>
            )}
          </View>
        </View>
      </View>
      <View style={styles.listContainer}>{renderItems}</View>
      <View style={styles.bundleFooter}>
        <StyledText align="right" size={12}>
          {t('order.itemCounter', {
            amount: bundle.package_goods?.item_number,
          })}
        </StyledText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  bundleItem: {
    marginVertical: 5,
    borderRadius: 10,
    borderColor: backGroundLight,
    borderWidth: 1,
    padding: 3,
  },
  bundleHeader: {
    flexDirection: 'row',
    paddingVertical: 5,
  },
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 6,
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 2,
    justifyContent: 'space-between',
  },
  listContainer: {
    borderTopWidth: 1,
    borderTopColor: backGroundLight,
    paddingTop: 5,
    overflow: 'hidden',
    marginHorizontal: 6,
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 2,
    marginBottom: 3,
    borderBottomColor: backGroundLight,
    borderBottomWidth: 1,
  },
  listItemLeftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  listItemRightContainer: {
    alignItems: 'flex-end',
  },
  bundleFooter: {},
});

export default BundleItemList;
