import React, { useEffect, useState } from 'react';
import { WalletParamList } from '../../../../types';
import { getAsyncStorageData } from '../../../../utils/asyncStorage';
import {
  View,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Platform,
  Modal,
  ScrollView,
} from 'react-native';
import { getDataWithAuthToken } from '../../../../utils/service';
import { useNavigation, RouteProp } from '@react-navigation/native';
import {
  tintColorLight,
  backGroundLight,
  accentColor,
  subBlueColor,
} from '../../../../constants/Colors';

import FullWidthView from '../../../../components/common/FullWidthView';
import StyledText from '../../../../components/common/StyledText';
import Loading from '../../../../components/common/Loading';
import NavigateButton from '../../../../components/common/NavigateButton';
import { MaterialIcons, FontAwesome5, AntDesign } from '@expo/vector-icons';
import { Tooltip } from 'react-native-elements';
import { useTranslation } from 'react-i18next';
import alert from '../../../../utils/alert';
import getAppStyle from '../../../../utils/getAppStyle';

// Types for EarnPointsScreen
type EarnPointsScreenRouteProp = RouteProp<WalletParamList, 'EarnPointsScreen'>;
type EarnPointsScreenProps = {
  route: EarnPointsScreenRouteProp;
};

type WayDataType = {
  type: string;
  points: number;
  title: string;
  desc: string;
  group?: string;
};

//For render WayItem
type WayItemProps = {
  item: WayDataType;
};

const EarnPointsScreen = ({ route }: EarnPointsScreenProps) => {
  const navigation = useNavigation();
  const { points } = route.params;

  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [wayList, setWayList] = useState<Array<WayDataType>>([]);

  //Get earn points ways when page focus
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setWayList([]);
      setIsLoadingComplete(false);
      getEarnPointsWays();
    });
    return unsubscribe;
  }, []);

  const renderItem = ({ item }: WayItemProps) => <WayItem item={item} />;

  async function getEarnPointsWays() {
    try {
      // Get location from localStorage for API call.
      const locationFromStorage = await getAsyncStorageData(
        '@storage_location'
      );
      let location;
      if (locationFromStorage) location = JSON.parse(locationFromStorage);
      const { countryId, provinceId, cityId } = location;
      const result = await getDataWithAuthToken(
        `/user/get_point_way?country_id=${countryId}&province_id=${provinceId}&city_id=${cityId}`
      );
      if (result && result.goodStatus) {
        setWayList((prevList) => [...prevList, ...result.data.ways]);
      } else {
        console.log('err', result);
        setWayList([]);
      }
    } catch (err) {
      console.log(err);
    }

    setIsLoadingComplete(true);
  }

  return (
    <FullWidthView>
      {!isLoadingComplete ? (
        <Loading />
      ) : (
        <FlatList
          style={{ backgroundColor: backGroundLight, flex: 1 }}
          data={wayList}
          renderItem={renderItem}
          showsVerticalScrollIndicator={false}
          ListHeaderComponent={<EarnPointsView points={points} />}
          onEndReachedThreshold={0.01}
          ListEmptyComponent={EmptyLogComponent}
        />
      )}
    </FullWidthView>
  );
};

type PointViewProps = {
  points: number;
};

const EarnPointsView = ({ points = 0 }: PointViewProps) => {
  const { t } = useTranslation();

  return (
    <>
      <View style={styles.earnPointsViewContainer}>
        <StyledText color="white" size={18}>
          {t('points.availablePoints')}
        </StyledText>
        <StyledText color="white" size={40}>
          {points}
        </StyledText>
      </View>
      <View style={styles.sectionTitleContainer}>
        <StyledText color={tintColorLight} size={18}>
          {t('points.earnPointsScreen.howToEarn')}
        </StyledText>
        <StyledText color={subBlueColor} size={12}>
          {t('points.earnPointsScreen.asteriskNote')}
        </StyledText>
      </View>
    </>
  );
};

const WayItem = ({ item }: WayItemProps) => {
  const { t } = useTranslation();

  const generateNavigateProps = (type: string) => {
    switch (type) {
      case 'order_more':
      case 'order':
        return {
          route: 'Category',
          loginRequired: true,
        };
      case 'comment_img':
      case 'comment':
        return {
          route: 'Review',
          loginRequired: true,
        };
      default:
        return { disabled: true };
    }
  };

  return (
    <View style={styles.wayItemContainer}>
      <View style={styles.logItemLeft}>
        <FontAwesome5 name="coins" size={20} color={accentColor} />
        <StyledText
          size={18}
          style={{ marginLeft: 6 }}
          bold
          color={accentColor}
        >
          {item.points}
        </StyledText>
      </View>

      <View style={styles.logItemCenter}>
        {item.group && (
          <StyledText
            size={18}
            bold
            color={subBlueColor}
            style={styles.asterisk}
          >
            *
          </StyledText>
        )}
        <StyledText style={{ marginRight: 5 }}>{item.title}</StyledText>
        {Platform.OS === 'web' ? (
          <TouchableOpacity onPress={() => alert(item.desc)}>
            <AntDesign name="questioncircleo" size={14} color="lightgray" />
          </TouchableOpacity>
        ) : (
          <Tooltip
            ModalComponent={Modal}
            popover={
              <View style={{ backgroundColor: 'black' }}>
                <StyledText color="white">{item.desc}</StyledText>
              </View>
            }
            backgroundColor="black"
            width={250}
            height={60}
          >
            <AntDesign name="questioncircleo" size={14} color="lightgray" />
          </Tooltip>
        )}
      </View>

      <View style={styles.logItemRight}>
        <NavigateButton
          {...generateNavigateProps(item.type)}
          style={{ flexDirection: 'row', alignItems: 'center' }}
        >
          <StyledText color={tintColorLight}>
            {t('points.earnPointsScreen.getPoints')}
          </StyledText>
          <AntDesign name="arrowright" size={18} color={tintColorLight} />
        </NavigateButton>
      </View>
    </View>
  );
};

const EmptyLogComponent = () => {
  const { t } = useTranslation();
  return (
    <View style={styles.emptyLogComponent}>
      <View>
        <MaterialIcons name="emoji-events" size={60} color="lightgray" />
      </View>

      <StyledText align="center">
        {t('points.earnPointsScreen.noWaysToEarnPoints')}
      </StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  earnPointsViewContainer: {
    backgroundColor: getAppStyle(process.env.EXPO_APP_TYPE)?.mainColor,
    alignItems: 'center',
    paddingVertical: 15,
  },
  wayItemContainer: {
    borderTopColor: backGroundLight,
    borderTopWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 25,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  logItemLeft: {
    width: 50,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 20,
  },
  logItemCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  asterisk: {
    position: 'absolute',
    top: 0,
    left: -12,
  },
  logItemRight: {
    marginLeft: 'auto',
    alignItems: 'flex-end',
  },
  earnPointsBtn: {
    borderColor: 'white',
    borderWidth: 1,
    borderRadius: 5,
    paddingVertical: 5,
    paddingHorizontal: 15,
    marginTop: 8,
  },

  sectionTitleContainer: {
    backgroundColor: 'white',
    // flexDirection: 'row',
    // alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingHorizontal: 10,
  },
  //   sectionTitle: {
  //     backgroundColor: 'white',
  //     // textAlign: 'center',
  //     paddingTop: 10,
  //     paddingBottom: 10,
  //     paddingHorizontal: 10,
  //   },
  emptyLogComponent: {
    alignItems: 'center',
    paddingVertical: 30,
  },
});

export default EarnPointsScreen;
