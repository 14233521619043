import React from 'react'
import { StyleProp, StyleSheet, View } from 'react-native'
import StyledText from '../../common/StyledText'

type CarouselPaginationProps = {
    totalPage: number;
    activeIndex: number;
    style?: StyleProp<object>;
}

const CarouselPagination = ({
    totalPage,
    activeIndex,
    style = {}
}: CarouselPaginationProps) => {

    return(
        <View style={[styles.container, style]}>
            <StyledText size={12} bold color={'white'}>{activeIndex.toString() + '/' + totalPage.toString()}</StyledText>
        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 10,
        paddingVertical: 2,
        borderRadius: 15,
        backgroundColor: 'rgba(0,0,0,.3)'
    }
})

export default CarouselPagination