import React from 'react';
import { normalTextColor } from '../../../constants/Colors';
import { AntDesign } from '@expo/vector-icons';
import NavigateButton from '../../common/NavigateButton';

function CustomerServiceBtn() {
  return (
    <NavigateButton
      loginRequired
      route="Modal"
      options={{ screen: 'CustomerServiceModal', params: { ruId: undefined } }}
    >
      <AntDesign
        name="customerservice"
        size={26}
        color={normalTextColor}
        style={{ marginRight: 5 }}
      />
    </NavigateButton>
  );
}

export default CustomerServiceBtn;
