import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ClaimParamList } from '../../types';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/common/iconButtons/BackButton';
import NavigateButton from '../../components/common/NavigateButton';
import ClaimListScreen from '../../screens/User/Claim/ClaimListScreen';
import ClaimDetailScreen from '../../screens/User/Claim/ClaimDetailScreen';
import OrderDetailScreen from '../../screens/User/Order/OrderDetailScreen';
import getAppStyle from '../../utils/getAppStyle';

const ClaimStack = createStackNavigator<ClaimParamList>();

const ClaimNavigator = () => {
  const { t } = useTranslation();

  return (
    <ClaimStack.Navigator
      initialRouteName="ClaimList"
      screenOptions={{
        headerBackTitleVisible: false,
        headerLeft: () => (
          <NavigateButton goBack>
            <BackButton />
          </NavigateButton>
        ),
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <ClaimStack.Screen
        name="ClaimList"
        component={ClaimListScreen}
        options={{
          headerTitleAlign: 'center',
          headerTitle: t('userProfileScreen.myOrders.options.return'),
        }}
      />
      <ClaimStack.Screen
        name="ClaimDetail"
        component={ClaimDetailScreen}
        options={{
          headerTitleAlign: 'center',
          headerTitle: t('order.claim.claimDetail'),
        }}
      />
      <ClaimStack.Screen
        name="ClaimOrderDetail"
        component={OrderDetailScreen}
        options={{
          headerTitleAlign: 'center',
          headerTitle: t('order.orderDetail'),
        }}
      />
    </ClaimStack.Navigator>
  );
};

export default ClaimNavigator;
