import React, { useState, useEffect } from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  ActivityIndicator as ActivityIndicatorBase,
} from 'react-native';
import { useTranslation } from 'react-i18next';
import { sendCode } from '../../utils/service';
import { tintColorLight } from '../../constants/Colors';
//@ts-ignore
import Toast from 'react-native-tiny-toast';

type SendCodeButtonProp = {
  value: string;
  type: string;
};

const BUTTON_COOLDOWN = 60; //in seconds

const SendCodeButton = ({ value, type }: SendCodeButtonProp) => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countdown, setCountdown] = useState(BUTTON_COOLDOWN);
  const [timeoutIsOn, setTimeoutIsOn] = useState(false);

  let isSubscribe: boolean;

  useEffect(() => {
    isSubscribe = true;
    let interval: any = null;
    if (isDisabled && isSubscribe) {
      interval = setInterval(() => {
        setCountdown((seconds) => seconds - 1);
      }, 1000);
    } else if (!isDisabled && countdown === 0) {
      clearInterval(interval);
    }
    return () => {
      isSubscribe = false;
      clearInterval(interval);
    };
  }, [isDisabled, countdown]);

  useEffect(() => {
    let timer: any;
    if (timeoutIsOn) {
      timer = setTimeout(() => {
        setIsDisabled(false);
        setCountdown(BUTTON_COOLDOWN);
        setTimeoutIsOn(false);
      }, BUTTON_COOLDOWN * 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [timeoutIsOn]);

  const handleSendCode = async (value: string) => {
    setIsSubmitting(true);
    let receiver = value;
    try {
      let response = await sendCode(receiver, type);
      if (response && response.goodStatus) {
        if (type === 'email')
          Toast.show(t('AuthModal.checkEmail'), { position: 0 });
        if (type === 'phone')
          Toast.show(t('AuthModal.checkPhone'), { position: 0 });
        setIsSubmitting(false);
        setIsDisabled(true);
        setTimeoutIsOn(true);
      } else {
        throw response.data;
      }
    } catch (error) {
      setIsSubmitting(false);
      Toast.show(error, {
        position: 0,
        duration: 2000,
      });
    }
  };

  return (
    <TouchableOpacity
      disabled={isDisabled || isSubmitting}
      style={[
        styles.sendCodeButton,
        { borderColor: isDisabled ? 'lightgray' : tintColorLight },
      ]}
      onPress={() => handleSendCode(value)}
    >
      {isSubmitting ? (
        <ActivityIndicatorBase size="small" color={tintColorLight} />
      ) : (
        <Text style={{ color: isDisabled ? 'lightgray' : tintColorLight }}>
          {isDisabled ? `${countdown}s` : t('AuthModal.send')}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  sendCodeButton: {
    borderWidth: 1,
    borderRadius: 25,
    paddingVertical: 3,
    paddingHorizontal: 12,
    marginRight: 6,
  },
});

export default SendCodeButton;
