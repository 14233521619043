import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet } from 'react-native';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native';
import BottomAutoHeightModal from '../../../common/BottomAutoHeightModal';
import StyledText from '../../../common/StyledText';
//@ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { backGroundLight, tintColorLight } from '../../../../constants/Colors';

const { width } = Dimensions.get('window');

type InviteModalProps = {
  visible: boolean;
  setVisible: Function;
  handleShare: (type: string) => void;
  inviteUrl: string;
};

const InviteModal = ({ visible, setVisible, inviteUrl }: InviteModalProps) => {
  const { t } = useTranslation();

  const [copied, setCopied] = useState(false);

  const hideModal = () => {
    setVisible(false);
    setCopied(false);
  };

  const copyToClipboard = () => {
    setCopied(true);
  };

  return (
    <BottomAutoHeightModal visible={visible} hideModal={hideModal}>
      <View>
        <View style={styles.header}>
          <StyledText size={16} bold>
            {t('inviteFriendScreen.copyAndSend')}
          </StyledText>
          <TouchableOpacity onPress={hideModal}>
            <StyledText align="right">{t('cancel')}</StyledText>
          </TouchableOpacity>
        </View>
        <View style={styles.content}>
          <StyledText align="center">{inviteUrl}</StyledText>
        </View>
        {copied && (
          <StyledText color={tintColorLight} align="center">
            {t('inviteFriendScreen.copySuccessfully')}
          </StyledText>
        )}
        <CopyToClipboard text={inviteUrl} onCopy={copyToClipboard}>
          <View style={styles.buttonBig}>
            <StyledText align="center" size={18} color="white">
              {t('productDetailScreen.sharePrompt.link')}
            </StyledText>
          </View>
        </CopyToClipboard>
      </View>
    </BottomAutoHeightModal>
  );
};

export default InviteModal;

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10,
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 10,
    backgroundColor: backGroundLight,
    paddingVertical: 20,
  },
  buttonBig: {
    borderRadius: 25,
    marginHorizontal: 8,
    paddingVertical: 10,
    width: width * 0.75,
    backgroundColor: tintColorLight,
    alignSelf: 'center',
  },
});
