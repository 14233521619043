import React, { useState } from 'react';
import { AddressInformation } from '../../../types';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  GestureResponderEvent,
} from 'react-native';
import StyledText from '../../common/StyledText';
import { AntDesign } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { postWithAuthToken } from '../../../utils/service';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import { subTextColor } from '../../../constants/Colors';
import { useNavigation } from '@react-navigation/native';
import alert from '../../../utils/alert';

const AddressItem = ({
  item,
  type,
  handleRegionChange,
}: {
  item: AddressInformation;
  type: 'edit' | 'view' | 'region';
  handleRegionChange?: Function;
}) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const [isDeleted, setIsDeleted] = useState(false);

  const handlePress = () => {
    switch (type) {
      case 'edit':
        handleEditAddress();
        break;
      case 'view':
        handleSelectAddress();
        break;
      case 'region':
        if (handleRegionChange) {
          handleRegionChange(item.postal_code.substring(0, 3));
        }
        break;
      default:
        console.log('Error: type not edit, view, or region');
    }
  };

  const handleEditAddress = () => {
    navigation.navigate('EditAddress', { addressObject: JSON.stringify(item) });
  };

  const handleSelectAddress = () => {
    //Pass the user selected address back to order preview screen
    navigation.navigate('OrderPreview', { selectedAddress: item });
  };

  const handleDeleteAddress = (event: GestureResponderEvent) => {
    event.preventDefault();
    alert(
      t('address.deleteAlert'),
      '',
      [
        {
          text: t('cancel'),
          style: 'cancel',
        },
        {
          text: t('confirm'),
          onPress: () =>
            deleteAddress(item.address_id)
              .then((result) => {
                //Update cart when product be deleted

                if (result && result.goodStatus) {
                  Toast.showSuccess(t('successfullyDeleted'));
                  setIsDeleted(true);
                } else {
                  console.log(result);
                }
              })
              .catch((err) => console.error(err)),
        },
      ],
      { cancelable: false }
    );
  };

  const styles = StyleSheet.create({
    addressItemContainer: {
      backgroundColor: 'white',
      // marginBottom: 10,
      padding: 10,
      marginHorizontal: type === 'region' ? 15 : 0,
      borderRadius: type === 'region' ? 10 : 0,
    },
    hide: {
      display: 'none',
    },
    contactInfoContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    footerContainer: {
      // alignSelf: 'flex-end',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  });

  const displayAddress = isDeleted ? styles.hide : styles.addressItemContainer;

  return (
    <TouchableOpacity onPress={() => handlePress()}>
      <View style={displayAddress}>
        <View style={styles.contactInfoContainer}>
          <StyledText bold size={16} style={{ flex: 2 }}>
            {item.consignee}
          </StyledText>
          <StyledText align="right" style={{ flex: 1 }}>
            {item.mobile}
          </StyledText>
        </View>
        <View>
          <StyledText>
            {item.sign_building.length > 0
              ? `[${item.district_name}] ${item.sign_building}-${item.address}`
              : `[${item.district_name}] ${item.address}`}
          </StyledText>
          <StyledText>{item.postal_code}</StyledText>
        </View>
        <View style={styles.footerContainer}>
          {type !== 'region' && (
            <TouchableWithoutFeedback onPress={handleDeleteAddress}>
              <AntDesign name="delete" size={22} color="lightgray" />
            </TouchableWithoutFeedback>
          )}
          {item.is_default && item.is_default === 1 && (
            <StyledText color={subTextColor} size={12}>
              {t('address.defaultAddress')}
            </StyledText>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default AddressItem;

const deleteAddress = async (id: number) => {
  const result = await postWithAuthToken('user/address/delete', { id: id });
  return result;
};
