import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { backGroundLight } from '../../../constants/Colors';
import ImageViewerModal from '../../common/ImageViewerModal';
import StyledText from '../../common/StyledText';

type DriverRemarkCardProps = {
  content: string | null;
  images: string[];
};

const DriverRemarkCard = ({ content, images }: DriverRemarkCardProps) => {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const handleImages = (images: string[]) => {
    let imageUrls: Array<any> = [];
    images.forEach((element: any) => {
      imageUrls.push({
        url: element.comment_img,
      });
    });

    return images.map((url) => {
      return { url: url };
    });
  };

  const renderImagesWithModal = () => {
    if (images.length > 0) {
      return (
        <>
          <View style={styles.imageThumbnailContainer}>
            {images.map((url, index) => (
              <TouchableOpacity
                key={index}
                onPress={() => {
                  setImageIndex(index);
                  setModalVisible(true);
                }}
              >
                <Image
                  source={{ uri: url }}
                  style={{ width: 70, height: 70, marginBottom: 6 }}
                />
              </TouchableOpacity>
            ))}
          </View>
          <ImageViewerModal
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            imgUrls={handleImages(images)}
            imageIndex={imageIndex}
          />
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <View style={styles.root}>
      <StyledText align="center" bold size={16}>
        {t('order.orderTracking.driverRemark.title')}
      </StyledText>
      {content !== null && <StyledText align="center">{content}</StyledText>}
      {renderImagesWithModal()}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: backGroundLight,
    padding: 10,
    marginBottom: 10,
  },
  imageThumbnailContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: 6,
    flexWrap: 'wrap',
  },
});

export default DriverRemarkCard;
