import { useNavigation } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import { Dimensions, StyleSheet, View, TouchableOpacity, FlatList } from 'react-native';
import { backGroundLight } from '../../../constants/Colors';
import { AdItem } from '../../../types';
import { getData } from '../../../utils/service';
import ResponsiveImg from '../../common/ResponsiveImg';

const { width } = Dimensions.get('window');

interface BrandItem extends AdItem {
  recommend_brand: 0 | 1;
}

type FixedBrandListProps = {
  data: BrandItem;
  marginBottom?: number;
  marginHorizontal?: number;
  backgroundColor?: string;
};

const FixedBrandList = ({
  data,
  marginBottom,
  marginHorizontal,
  backgroundColor,
}: FixedBrandListProps) => {
  const [brandListData, setBrandListData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let isSubscribe = true;
    getBrandListData(
      data.brand_cat_id,
      data.brand_item_number,
      data.recommend_brand
    )
      .then((result) => {
        if (isSubscribe) {
          if (result && result.goodStatus) {
            setBrandListData(result.data.brand);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    return () => {
      isSubscribe = false;
    };
  }, [data]);

  const _renderItem = ({item}: any) => {
    return (
      <OneFourthCard
        data={item}
        hasBackgroundColor={
          backgroundColor !== '' && backgroundColor !== 'white'
        }
      />
    )
  }

  return (
    <View
      style={{
        paddingTop: 10,
        width: width,
        marginBottom,
        backgroundColor,
        paddingHorizontal: marginHorizontal,
      }}
    >
      {loading ? (
        <></>
      ) : brandListData.length > 0 ? (
          <FlatList
            data={brandListData}
            renderItem={_renderItem}
            keyExtractor={(item, index)=> index.toString()}
            numColumns={4}
          />
      ) : (
        <></>
      )}
    </View>
  );
};

type OneFourthCardProps = {
  data: any;
  hasBackgroundColor: boolean;
};

const OneFourthCard = ({ data, hasBackgroundColor }: OneFourthCardProps) => {
  const navigation = useNavigation();

  const handleBrandPress = (brandId: any) => {
    navigation.navigate('Brand', {
      screen: 'BrandDetail',
      params: { brandId },
    });
  };

  return (
    <TouchableOpacity onPress={() => handleBrandPress(data.brand_id)}>
      <View
        style={[
          styles.brandContainer,
          hasBackgroundColor
            ? { borderColor: backGroundLight }
            : { borderColor: 'lightgray' },
        ]}
      >
        <ResponsiveImg width={width * 0.18} uri={data.brand_logo} />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  brandsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  brandContainer: {
    width: width * 0.22,
    height: width * 0.22,
    marginHorizontal: 3,
    borderRadius: 10,
    borderWidth: 1,
    marginBottom: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const getBrandListData = async (
  brandCatId: number,
  size = 10,
  isRecommend = 0
) => {
  const result = await getData(
    `brand/list?cat_id=${brandCatId}&size=${size}&is_recommend=${isRecommend}`
  );
  return result;
};

export default FixedBrandList;
