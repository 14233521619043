import { StyleSheet, View } from 'react-native';
import { CommentImage, TaskGood } from '../../types';
import StyledText from '../common/StyledText';
import { subTextColor, warningRed } from '../../constants/Colors';
import { useTranslation } from 'react-i18next';
import ImageList from '../common/comment/ImageList';
import { useState } from 'react';
import ImageViewerModal from '../common/ImageViewerModal';

type TaskGoodsListItemProps = {
  item: TaskGood;
};

const TaskGoodsListItem = ({ item }: TaskGoodsListItemProps) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [imageIndex, setImageIndex] = useState<number>(0);

  const handleImages = (images: any) => {
    let imageUrls: Array<{ url: string }> = [];
    images.forEach((element: any) => {
      imageUrls.push({
        url: element.img_url,
      });
    });
    return imageUrls;
  };

  return (
    <View style={styles.itemContainer}>
      <View style={{ flexDirection: 'row' }}>
        <View style={styles.leftContainer}>
          <View style={{ flexDirection: 'row' }}>
            {item.status && (
              <View style={[styles.tag, { borderColor: item.status.color }]}>
                <StyledText size={10} color={item.status.color}>
                  {item.status.desc}
                </StyledText>
              </View>
            )}
            {item.task_review_status && (
              <View
                style={[
                  styles.tag,
                  { borderColor: item.task_review_status.color },
                ]}
              >
                <StyledText size={10} color={item.task_review_status.color}>
                  {item.task_review_status.desc}
                </StyledText>
              </View>
            )}
          </View>
          <View>
            <StyledText numberOfLines={3}>{item.full_goods_name}</StyledText>
            <StyledText color={subTextColor} size={12}>
              {item.goods_attr_name}
            </StyledText>
          </View>
        </View>
        <View style={styles.rightContainer}>
          <View style={styles.column}>
            {item.is_weight_goods > 0 && (
              <>
                <StyledText style={{ color: subTextColor }}>
                  {t('order.taskGoods.goodsWeight')}
                </StyledText>
                <StyledText
                  style={
                    item.goods_weight === item.actual_weight
                      ? undefined
                      : {
                          textDecorationLine: 'line-through',
                          textDecorationColor: subTextColor,
                          color: subTextColor,
                        }
                  }
                >
                  {item.goods_weight}
                </StyledText>
                {item.goods_weight !== item.actual_weight && (
                  <StyledText color={warningRed}>
                    {item.actual_weight}
                  </StyledText>
                )}
              </>
            )}
          </View>
          <View style={styles.column}>
            <StyledText style={{ color: subTextColor }}>
              {t('order.taskGoods.goodsNumber')}
            </StyledText>
            <StyledText
              style={
                item.goods_number === item.actual_number
                  ? undefined
                  : {
                      textDecorationLine: 'line-through',
                      textDecorationColor: subTextColor,
                      color: subTextColor,
                    }
              }
            >
              x{item.goods_number}
            </StyledText>
            {item.goods_number !== item.actual_number && (
              <StyledText color={warningRed}>x{item.actual_number}</StyledText>
            )}
          </View>
          <View style={styles.column}>
            <StyledText style={{ color: subTextColor }}>
              {t('order.taskGoods.goodsPrice')}
            </StyledText>
            <StyledText
              style={
                item.formated_goods_price === item.formated_actual_price
                  ? undefined
                  : {
                      textDecorationLine: 'line-through',
                      textDecorationColor: subTextColor,
                      color: subTextColor,
                    }
              }
            >
              {item.formated_goods_price}
            </StyledText>
            {item.formated_goods_price !== item.formated_actual_price && (
              <StyledText color={warningRed}>
                {item.formated_actual_price}
              </StyledText>
            )}
          </View>
        </View>
      </View>
      {item.content !== '' && (
        <StyledText color={warningRed}>
          {t('order.remark')}: {item.content}
        </StyledText>
      )}
      {item.task_goods_images && item.task_goods_images.length > 0 && (
        <>
          <ImageList
            images={item.task_goods_images.map((image) => ({
              id: image.img_id,
              comment_id: image.tg_id,
              comment_img: image.img_url,
            }))}
            setModalVisible={setModalVisible}
            setImageIndex={setImageIndex}
          />
          <ImageViewerModal
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            imageIndex={imageIndex}
            imgUrls={handleImages(item.task_goods_images)}
          />
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    padding: 10,
  },
  leftContainer: {
    flexWrap: 'wrap',
    width: '50%',
  },
  rightContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '50%',
  },
  column: {
    width: '30%',
  },
  tag: {
    borderWidth: 1,
    padding: 2,
    borderRadius: 5,
    marginRight: 3,
  },
});

export default TaskGoodsListItem;
