import React from 'react';
import { View, StyleSheet, Dimensions, TouchableOpacity } from 'react-native';
//@ts-ignore
import Modal from 'modal-react-native-web';
import { useDispatch, useSelector } from 'react-redux';
import { AlertButton, AlertButtons, RootState } from '../../../types';
import StyledText from '../StyledText';
import { tintColorLight } from '../../../constants/Colors';

const { width, height } = Dimensions.get('window');

const AlertModal = React.memo(() => {
  const dispatch = useDispatch();

  const visible = useSelector<RootState, boolean>(
    (state) => state.goods.alertModal.visible
  );

  const title = useSelector<RootState, string>(
    (state) => state.goods.alertModal.title
  );

  const message = useSelector<RootState, string | undefined>(
    (state) => state.goods.alertModal.message
  );

  const buttons = useSelector<RootState, AlertButtons | undefined>(
    (state) => state.goods.alertModal.buttons
  );

  const options = useSelector<RootState, any>(
    (state) => state.goods.alertModal.options
  );

  const closeAlert = () => {
    dispatch({
      type: 'SET_IS_ALERT_VISIBLE',
      payload: false,
    });
  };

  const renderDefaultButtonText = (index: number) => {
    switch (index) {
      case 0:
        return 'OK';
      case 1:
        return 'Cancel';
      default:
        return '';
    }
  };

  return (
    <Modal
      animationType={'fade'}
      transparent={true}
      visible={visible}
      ariaHideApp={false}
    >
      <View style={styles.modalContainer}>
        <View style={[styles.mainContentContainer]}>
          <View style={{ padding: 20 }}>
            <StyledText bold align={'center'}>
              {title}
            </StyledText>
            {message ? (
              <StyledText align={'center'}>{message}</StyledText>
            ) : (
              <></>
            )}
          </View>
          <View style={styles.buttonContainer}>
            {buttons && buttons.length > 0 ? (
              buttons.map((button: AlertButton, index) => (
                <View key={index} style={styles.button}>
                  <TouchableOpacity
                    onPress={() => {
                      closeAlert();
                      if (button.onPress) button.onPress();
                    }}
                  >
                    <StyledText
                      color={tintColorLight}
                      bold={
                        button.style && button.style === 'cancel' ? true : false
                      }
                      align={'center'}
                    >
                      {button.text || renderDefaultButtonText(index)}
                    </StyledText>
                  </TouchableOpacity>
                </View>
              ))
            ) : (
              <View style={styles.button}>
                <TouchableOpacity onPress={closeAlert}>
                  <StyledText color={tintColorLight} align={'center'}>
                    {'OK'}
                  </StyledText>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
});

const styles = StyleSheet.create({
  modalContainer: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    justifyContent: 'center',
    alignItems: 'center',
    width: width,
    height: height,
    zIndex: 0,
    flex: 1,
  },
  mainContentContainer: {
    backgroundColor: 'white',
    width: '70%',
    zIndex: 10,
    borderRadius: 10,
    maxWidth: 375,
    justifyContent: 'space-between',
  },
  buttonContainer: {},
  button: {
    borderTopColor: 'lightgray',
    borderTopWidth: 1,
    paddingVertical: 10,
  },
});

export default AlertModal;
