import React from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native';
import GeneralModal from './GeneralModal';

type BottomAutoHeightModalProps = {
  visible: boolean;
  children: React.ReactNode;
  hideModal: Function;
};

const { width, height } = Dimensions.get('window');

/** Showing auto height modal from bottom */
const BottomAutoHeightModal = ({
  children,
  visible,
  hideModal,
}: BottomAutoHeightModalProps) => {
  const setVisible = () => {
    hideModal();
  };

  return (
    <GeneralModal
      animationType="fade"
      transparent={true}
      visible={visible}
      statusBarTranslucent={true}
      onRequestClose={setVisible}
    >
      <View style={styles.modalContainer}>
        <View style={{ flex: 1 }}>
          <TouchableWithoutFeedback onPress={setVisible}>
            <View
              style={{
                width: '100%',
                height: '100%',
              }}
            ></View>
          </TouchableWithoutFeedback>
        </View>

        <View style={[styles.mainContentContainer]}>{children}</View>
      </View>
    </GeneralModal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    justifyContent: 'flex-end',
    width: width,
    height: height,
    zIndex: 0,
    flex: 1,
  },
  mainContentContainer: {
    backgroundColor: 'white',
    padding: 10,
    zIndex: 10,
  },
  hideModalTrigger: {
    backgroundColor: 'pink',
    height: 30,
    flex: 1,
  },
});

export default BottomAutoHeightModal;
