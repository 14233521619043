import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import StyledText from '../../../components/common/StyledText';
import { subTextColor, accentColor } from '../../../constants/Colors';
import { AntDesign } from '@expo/vector-icons';
import { CommentListItem } from '../../../types';
import { Comment as CommentType, CommentImage } from '../../../types';
import NavigateButton from '../../../components/common/NavigateButton';
import UserAndStars from '../../../components/common/comment/UserAndStars';
import ImageList from '../../../components/common/comment/ImageList';
import ImageViewerModal from '../../../components/common/ImageViewerModal';
import { __handlePersistedRegistrationInfoAsync } from 'expo-notifications/build/DevicePushTokenAutoRegistration.fx';
import alert from '../../../utils/alert';
import { postWithAuthToken } from '../../../utils/service';
import { useTranslation } from 'react-i18next';
//@ts-ignore
import Toast from 'react-native-tiny-toast';

type CommentedReviewListItemProps = CommentListItem & {
  setComments: React.Dispatch<Array<CommentListItem>>;
  comments: Array<CommentListItem>;
};

const CommentedReviewListItem = ({
  comment_id,
  goods_id,
  goods_name,
  nick_name,
  comment_rank,
  user_picture,
  add_time,
  content,
  images,
  setComments,
  comments,
}: CommentedReviewListItemProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const { t } = useTranslation();

  const handleImages = (images: Array<CommentImage>) => {
    let imageUrls: Array<{ url: string }> = [];
    images.forEach((element: any) => {
      imageUrls.push({
        url: element.comment_img,
      });
    });
    return imageUrls;
  };

  const deleteReview = () => {
    const loading = Toast.showLoading('');
    postWithAuthToken('comments/delete?', {
      id: comment_id
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        Toast.hide(loading);
        if (response && response.goodStatus) {
          setComments(comments.filter((x) => x.comment_id != comment_id));
          Toast.showSuccess(t('review.reviewDeleted'));
        } else {
          if (response) {
            throw response.data;
          } else {
            throw 'no token found';
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (loading) Toast.hide(loading);
        Toast.show(err, {
          position: 0,
          duration: 2000,
        });
      });
  };

  const handlePress = () => {
    alert(`${t('review.deleteReview')}?`, '', [
      {
        text: t('cancel'),
        onPress: () => {},
      },
      {
        text: t('confirm'),
        onPress: () => {
          deleteReview();
        },
      },
    ]);
  };

  return (
    <View style={styles.goodContainer}>
      <NavigateButton
        route="ProductNavigator"
        options={{
          params: { productId: goods_id },
          screen: 'ProductDetailScreen',
        }}
      ></NavigateButton>
      <View style={styles.goodContainerInfo}>
        <NavigateButton
          route="ProductNavigator"
          options={{
            params: { productId: goods_id },
            screen: 'ProductDetailScreen',
          }}
        >
          <View style={styles.titleContainer}>
            <StyledText
              color={subTextColor}
              style={{ flex: 1, flexWrap: 'wrap' }}
            >
              {goods_name}
            </StyledText>
            <AntDesign
              name="rightcircleo"
              size={18}
              color={subTextColor}
              style={{ marginLeft: 10 }}
            />
          </View>

          <View style={styles.commentContainer}>
            <UserAndStars
              nick_name={nick_name}
              comment_rank={comment_rank}
              user_picture={user_picture}
              add_time={add_time}
              large
            />
            <View style={styles.content}>
              <StyledText>{content}</StyledText>
              <ImageList
                images={images}
                setModalVisible={setModalVisible}
                setImageIndex={setImageIndex}
              />
            </View>
          </View>
        </NavigateButton>
        <ImageViewerModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          imageIndex={imageIndex}
          imgUrls={handleImages(images)}
        />
        <View style={styles.buttonContainer}>
          <TouchableOpacity style={styles.button} onPress={handlePress}>
            <StyledText color={accentColor}>
              {t('review.deleteReview')}
            </StyledText>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  goodContainer: {
    width: '100%',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgray',
    flexDirection: 'row',
  },
  goodContainerInfo: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: 'lightgrey',
    borderBottomWidth: 1,
    padding: 10,
  },
  commentContainer: {
    padding: 10,
  },
  content: {
    padding: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  button: {
    flexDirection: 'row',
  },
});

export default CommentedReviewListItem;
