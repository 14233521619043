import React from 'react';
import FullWidthView from '../../components/common/FullWidthView';
import {WebView} from 'react-native-webview';
import { useRoute, RouteProp, useNavigation } from '@react-navigation/native';
import { SettingsParamList } from '../../types';

const SettingWebViewScreen = () => {
  const route = useRoute<RouteProp<SettingsParamList, 'SettingWebView'>>();
  const navigation = useNavigation();

  const { url, title } = route.params;

  navigation.setOptions({
    headerTitle: title,
  });

  return (
    <FullWidthView>
      <WebView source={{ uri: url }} />
    </FullWidthView>
  );
};

export default SettingWebViewScreen;
