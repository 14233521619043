import React from 'react';
import { View, StyleSheet, Dimensions, TouchableOpacity } from 'react-native';
import StyledText from '../../common/StyledText';
import { tintColorLight, normalTextColor } from '../../../constants/Colors';
import { useTranslation } from 'react-i18next';

const { width } = Dimensions.get('window');

const MethodSwitch = ({
  activeType = 'shipping',
  changeType,
}: {
  activeType: 'shipping' | 'pickup';
  changeType: React.Dispatch<React.SetStateAction<'shipping' | 'pickup'>>;
}) => {
  const { t } = useTranslation();
  const handleSwitchPress = () => {
    activeType === 'shipping' ? changeType('pickup') : changeType('shipping');
  };

  return (
    <View style={styles.switchContainer}>
      <TouchableOpacity
        style={[
          styles.switchBtn,
          activeType === 'shipping' ? styles.active : {},
        ]}
        onPress={handleSwitchPress}
      >
        <View>
          <StyledText
            align='center'
            bold={activeType === 'shipping'}
            color={activeType === 'shipping' ? 'white' : normalTextColor}
          >
            {t('order.switchDelivery')}
          </StyledText>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.switchBtn, activeType === 'pickup' ? styles.active : {}]}
        onPress={handleSwitchPress}
      >
        <View>
          <StyledText
            align='center'
            bold={activeType === 'pickup'}
            color={activeType === 'pickup' ? 'white' : normalTextColor}
          >
            {t('order.switchPickup')}
          </StyledText>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  switchContainer: {
    flexDirection: 'row',
    backgroundColor: 'white',
    borderRadius: 50,
    marginBottom: 10,
    overflow: 'hidden',
  },
  switchBtn: {
    padding: 10,
    flex: 1,
    width: (width - 20) / 2,
  },
  active: {
    backgroundColor: tintColorLight,
  },
});

export default MethodSwitch;
