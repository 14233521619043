import React, { useState, useEffect } from 'react';
import {
  Modal,
  StyleSheet,
  Pressable,
  View,
  ScrollView,
  NativeScrollEvent,
} from 'react-native';
import StyledText from '../../common/StyledText';
import { backGroundLight } from '../../../constants/Colors';
import { AntDesign } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';

type SubmitOrderModalProps = {
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  submitAlert: {
    title: string;
    desc: Array<{ text: string; color?: string; size?: number }>;
  };
  handleOrderSubmit: Function;
};

const SubmitOrderModal = ({
  modalVisible,
  setModalVisible,
  submitAlert,
  handleOrderSubmit,
}: SubmitOrderModalProps) => {
  const { t } = useTranslation();
  const [startedScrolling, setStartedScrolling] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const charCount = submitAlert.desc.map((x) => x.text.length);
    const totalChar = charCount.reduce((a, b) => a + b);
    if (totalChar < 1200) {
      setStartedScrolling(true);
      setDisabled(false);
    }
  }, []);

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: NativeScrollEvent) => {
    const paddingToBottom = 20;
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  };

  return (
    <View style={styles.centeredView}>
      <Modal animationType="slide" transparent={true} visible={modalVisible}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <StyledText align="center" size={24} bold style={{marginBottom: 10}}>
              {submitAlert.title}
            </StyledText>
            <View style={{ maxHeight: 450 }}>
              <ScrollView
                style={{ flexGrow: 0}}
                onScroll={({ nativeEvent }) => {
                  setStartedScrolling(true);
                  if (isCloseToBottom(nativeEvent)) {
                    setDisabled(false);
                  }
                }}
                scrollEventThrottle={0}
              >
                {submitAlert.desc.map((paragraph, index) => (
                  <StyledText
                    key={index}
                    color={paragraph.color}
                    style={{ marginTop: 10 }}
                    size={paragraph.size}
                  >
                    {paragraph.text}
                  </StyledText>
                ))}
              </ScrollView>
            </View>
            {!startedScrolling && (
              <View style={styles.scrollDownNotification}>
                <AntDesign name="downcircleo" size={13} color="black" />
                <StyledText style={{ marginLeft: 5 }}>{t('order.scrollDownConfirm')}</StyledText>
              </View>
            )}
            <View
              style={{
                marginVertical: 20,
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}
            >
              <Pressable
                style={[styles.button, styles.buttonCancel]}
                onPress={() => {
                  setModalVisible(false);
                }}
              >
                <StyledText size={16}>{t('cancel')}</StyledText>
              </Pressable>
              <Pressable
                style={[styles.button, styles.buttonConfirm, {opacity: disabled ? 0.5 : 1}]}
                onPress={() => {
                  setModalVisible(false);
                  handleOrderSubmit();
                }}
                disabled={disabled}
              >
                <StyledText color="white" size={16}>
                  {t('confirm')}
                </StyledText>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 5,
    padding: 25,
    paddingTop: 10,
    paddingBottom: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 30,
    elevation: 2,
    marginHorizontal: 5,
  },
  buttonCancel: {
    backgroundColor: backGroundLight,
  },
  buttonConfirm: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  scrollDownNotification: {
    position: 'absolute',
    height: 30,
    width: 200,
    borderRadius: 10,
    padding: 5,
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 100,
    right: 60,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});

export default SubmitOrderModal;
