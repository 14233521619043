/**
 * Web hook only, for checking if the browser is in wechat or not.
 */
const useIsWeChat = () => {
  try {
    if (navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
      //Overwrite alert default style
      window.alert = function (message: string) {
        var iframe = document.createElement('IFRAME');
        iframe.style.display = 'none';
        iframe.setAttribute('src', 'data:text/plain');
        document.documentElement.appendChild(iframe);
        window.frames[0].window.alert(message);
        iframe.parentNode.removeChild(iframe);
      };
      //Overwrite confirm default style
      // window.confirm = function (message: string) {
      //   var iframe = document.createElement('IFRAME');
      //   iframe.style.display = 'none';
      //   iframe.setAttribute('src', 'data:text/plain,');
      //   document.documentElement.appendChild(iframe);
      //   var alertFrame = window.frames[0];
      //   var result = alertFrame.window.confirm(message);
      //   iframe.parentNode.removeChild(iframe);
      //   return result;
      // };

      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export default useIsWeChat;
