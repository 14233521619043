import React from 'react';
import { FlatList, Image, TouchableOpacity } from 'react-native';
import { Comment as CommentType, CommentImage } from '../../../types';

type renderItemParams = {
  item: CommentImage;
  index: number;
};

type ImageListProps = {
  images: Array<CommentImage>;
  setModalVisible: Function;
  setImageIndex: Function;
};

const ImageList = ({
  images,
  setModalVisible,
  setImageIndex,
}: ImageListProps) => {
  const _renderImage = ({ item, index }: renderItemParams) => {
    return (
      <TouchableOpacity
        onPress={() => {
          setImageIndex(index);
          setModalVisible(true);
        }}
      >
        <Image
          source={{ uri: item.comment_img }}
          style={{ width: 100, height: 100, marginRight: 10 }}
        />
      </TouchableOpacity>
    );
  };

  return (
    <FlatList
      style={{
        marginTop: 10,
        flexDirection: 'row',
      }}
      data={images}
      renderItem={_renderImage}
      keyExtractor={(item: CommentImage) => item.id.toString()}
      horizontal
    />
  );
};

export default ImageList;
