import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import FullWidthView from '../../../components/common/FullWidthView';
import StyledText from '../../../components/common/StyledText';
import StyledButton from '../../../components/common/StyledButton';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/common/Loading';
import CreditCardList from '../../../components/User/CreditCard/CreditCardList';
import {
  RootState,
  LocationInformation,
  CreditCardInList,
} from '../../../types';
import { useSelector } from 'react-redux';
import { getCreditCardData, postWithAuthToken } from '../../../utils/service';
import Toast from 'react-native-tiny-toast';
import alert from '../../../utils/alert';

const CreditCardListScreen = () => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  const [creditCardList, setCreditCardList] = useState<Array<CreditCardInList>>(
    []
  );
  const [ruID, setRuID] = useState();
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { countryId, provinceId, cityId } = useSelector<
    RootState,
    LocationInformation
  >((state) => state.goods.location);

  const deleteCard = (id: number) => {
    if (!isSubmitting) {
      alert(
        t('creditCard.deleteCreditCardPrompt'),
        '',
        [
          {
            text: t('cancel'),
            style: 'cancel',
          },
          {
            text: t('confirm'),
            onPress: () => creditCardPostRequest('delete', id),
          },
        ],
        { cancelable: false }
      );
    }
  };

  const setDefault = (id: number) => {
    if (!isSubmitting) {
      creditCardPostRequest('set_default', id);
    }
  };

  const creditCardPostRequest = async (type: string, id: number) => {
    const loading = Toast.showLoading('');
    setIsSubmitting(true);
    postWithAuthToken(`creditcard/${type}`, { id })
      .then((result) => {
        if (result) {
          Toast.hide(loading);
          setIsSubmitting(false);
          if (result.goodStatus) {
            fetchData();
          } else {
            throw result.data;
          }
        } else {
          console.log(result);
        }
      })
      .catch((err) => {
        if (loading) Toast.hide(loading);
        setIsSubmitting(false);
        Toast.show(err, {
          position: 0,
          duration: 2000,
        });
      });
  };

  useEffect(() => {
    const unsubsribe = navigation.addListener('focus', () => {
      fetchData();
    });
    return unsubsribe;
  }, []);

  const fetchData = async () => {
    getCreditCardData(countryId, provinceId, cityId)
      .then((result) => {
        if (result && result.goodStatus) {
          setCreditCardList(result.data.card_list);
          setRuID(result.data.ru_id);
        } else {
          throw result;
        }
        setLoadingComplete(true);
      })
      .catch((err) => {
        setLoadingComplete(true);
        Toast.show(err, {
          position: 0,
          duration: 2000,
        });
      });
  };

  return (
    <FullWidthView edges={['bottom', 'left', 'right']}>
      {!loadingComplete ? (
        <View style={styles.loadingContainer}>
          <Loading />
        </View>
      ) : creditCardList.length > 0 ? (
        <CreditCardList
          creditCards={creditCardList}
          setDefault={setDefault}
          deleteCard={deleteCard}
        />
      ) : (
        <EmptyCreditCardList ruID={ruID} />
      )}
    </FullWidthView>
  );
};

const EmptyCreditCardList = ({ ruID }: any) => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  return (
    <View style={styles.emptyCreditCardListContainer}>
      <FontAwesome name="credit-card-alt" size={80} color="lightgray" />
      <StyledText size={18} color="lightgray" style={{ marginBottom: 30 }}>
        {t('creditCard.emptyCreditCardList')}
      </StyledText>
      <StyledButton
        label={t('creditCard.addCreditCard')}
        onPress={() => {
          navigation.navigate('NewCreditCardScreen', { ruID: ruID });
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  emptyCreditCardListContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CreditCardListScreen;
