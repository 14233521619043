import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ModalParamList } from '../types';

import AuthNavigator from './AuthNavigator';
import UserLocationModal from '../components/Modal/UserLocationModal';
import CustomerServiceModal from '../components/Modal/CustomerServiceModal';
import getAppStyle from '../utils/getAppStyle';

const ModalStack = createStackNavigator<ModalParamList>();

const ModalNavigator = () => {
  return (
    <ModalStack.Navigator
      mode="modal"
      screenOptions={{
        headerShown: false,
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <ModalStack.Screen name="AuthNavigator" component={AuthNavigator} />
      <ModalStack.Screen
        name="UserLocationModal"
        component={UserLocationModal}
      />
      <ModalStack.Screen
        name="CustomerServiceModal"
        component={CustomerServiceModal}
      />
    </ModalStack.Navigator>
  );
};

export default ModalNavigator;
