import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useHeaderHeight } from '@react-navigation/stack';
import React, { useState } from 'react';
import { FlatList } from 'react-native';
import FullWidthView from '../../components/common/FullWidthView';
import ProductItemInList from '../../components/Product/ProductItemInList';
import { CartParamList, ProductInformation, RootState } from '../../types';
import {
  getData,
  getDataWithAuthToken,
  postWithAuthToken,
} from '../../utils/service';
import Toast from 'react-native-tiny-toast';
import { useTranslation } from 'react-i18next';
import { useMount } from 'ahooks';
import Loading from '../../components/common/Loading';
import { errorAlertAndGoBack } from '../../utils/alert';
import OfferHeader from '../../components/Cart/SpecialOffer/OfferHeader';
import { useSelector } from 'react-redux';

const SpecialOfferScreen = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const headerHeight = useHeaderHeight();
  const route = useRoute<RouteProp<CartParamList, 'SpecialOffer'>>();
  const { id, type = 'next' } = route.params;

  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.user.isLoggedIn
  );

  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [giftList, setGiftList] = useState<ProductInformation[]>([]);
  const [activityId, setActivityId] = useState(0);
  const [activityInfo, setActivityInfo] = useState<{
    title: string;
    actType: string;
    maximumGifts: number;
    currentAmount: number;
  }>({
    title: t('specialOfferScreen.title'),
    actType: t('specialOfferScreen.regularAction'),
    maximumGifts: 1,
    currentAmount: 0,
  });
  const [offerStatus, setOfferStatus] = useState(0);

  useMount(() => refreshGiftProductList());

  /**
   * Get the gift product list when screen mount or when user add/remove item
   */
  const refreshGiftProductList = async () => {
    const result = await getGiftProductList(id, isLoggedIn);
    if (result && result.goodStatus) {
      setGiftList(result.data.gift);
      setActivityId(result.data.act_id);
      setActivityInfo({
        title: result.data.act_name,
        actType: result.data.act_type_name,
        maximumGifts: result.data.act_type_ext,
        currentAmount: result.data.current_gift,
      });
      setOfferStatus(result.data.status);
    } else {
      errorAlertAndGoBack(
        result && result?.data && typeof result.data === 'string'
          ? result.data
          : 'error with getting gift list',
        navigation
      );
    }

    setIsLoadingComplete(true);
  };

  const renderGiftList = ({ item }: any) => {
    const handleSpecialOfferProductToCart = (action: string) => {
      if (action === 'add' && activityId !== 0) {
        (async () => {
          const loading = Toast.showLoading('');

          const result = await addGiftToCart(activityId, item.goods_id);

          Toast.hide(loading);

          if (result && result.goodStatus) {
            Toast.showSuccess(t('specialOfferScreen.chooseSuccessfully'));
            refreshGiftProductList();
          } else if (result && typeof result.data === 'string') {
            Toast.show(result.data, { position: 0 });
          } else {
            Toast.show(t('specialOfferScreen.chooseFailed'));
          }
        })();
      } else if (action === 'remove' && activityId !== 0) {
        (async () => {
          const loading = Toast.showLoading('');

          const result = await removeGiftToCart(activityId, item.goods_id);

          Toast.hide(loading);

          if (result && result.goodStatus) {
            Toast.showSuccess(t('specialOfferScreen.removeSuccessfully'));
            refreshGiftProductList();
          } else if (result && typeof result.data === 'string') {
            Toast.show(result.data, { position: 0 });
          } else {
            Toast.show(t('specialOfferScreen.removeFailed'));
          }
        })();
      }
    };

    return (
      <ProductItemInList
        data={item}
        type="gift"
        action={handleSpecialOfferProductToCart}
      />
    );
  };

  return (
    <FullWidthView edges={['bottom', 'left', 'right']}>
      {isLoadingComplete ? (
        <FlatList
          style={{ flex: 1 }}
          data={giftList}
          renderItem={renderGiftList}
          keyExtractor={(item) => item.goods_id.toString()}
          ListHeaderComponent={
            <OfferHeader
              info={activityInfo}
              metCondition={type === 'current' && offerStatus > 0}
            />
          }
        />
      ) : (
        <Loading />
      )}
    </FullWidthView>
  );
};

const getGiftProductList = async (activityId: number, isLoggedIn: boolean) => {
  const result = isLoggedIn
    ? await getDataWithAuthToken(`activity/gift?act_id=${activityId}`)
    : await getData(`activity/gift?act_id=${activityId}`);
  return result;
};

const addGiftToCart = async (actId: number, goodsId: number) => {
  return await postWithAuthToken('cart/gift/add', {
    act_id: actId,
    goods_id: goodsId,
  });
};

const removeGiftToCart = async (actId: number, goodsId: number) => {
  return await postWithAuthToken('cart/gift/delete', {
    act_id: actId,
    goods_id: goodsId,
  });
};

export default SpecialOfferScreen;
