import React, {useRef} from 'react'
import { FlatList, StyleSheet, View, TouchableOpacity } from 'react-native'
import StyledText from '../common/StyledText'
import { useUpdateEffect } from 'ahooks'

type ThemeHomeScreenMenuProps = {
    data: Array<{
        title: string,
        color: string
    }>
    horizontalIndex: number;
    updateHorizontalPage: Function;
}

const ThemeHomeScreenMenu = (
    {data, horizontalIndex, updateHorizontalPage}: ThemeHomeScreenMenuProps
) => {
    const scrollMenuRef = useRef<any>(null)

    const handleChangeIndex = (index: number) => {
        updateHorizontalPage(index)
    }

    useUpdateEffect(()=>{
        try {
            if(scrollMenuRef && scrollMenuRef.current){
                scrollMenuRef.current.scrollToIndex({
                    animated: true,
                    index: horizontalIndex
                })
            }
        } catch (error) {
            console.log(error)
        }
    }, [horizontalIndex])

    const _renderItem = ({item, index}: {item: {title: string, color: string}, index: number}) => {
        return(
        <View>
            <TouchableOpacity style={styles.button} onPress={()=>handleChangeIndex(index)}>
                <StyledText bold={index === horizontalIndex ? true : false} color={item.color} size={16}>{item.title}</StyledText>
                {index === horizontalIndex &&
                (
                    <View style={{height: 2, backgroundColor: item.color}}></View>
                )}
            </TouchableOpacity>
        </View>
        )
    }

    return(
        <View style={styles.container}>
            <FlatList
            ref={scrollMenuRef} 
            data={data}
            renderItem={_renderItem}
            keyExtractor={(item)=>item.title}
            horizontal
            showsHorizontalScrollIndicator
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        marginVertical: 5,
        marginLeft: 5
      },
    button: {
        paddingHorizontal: 8,
        paddingVertical: 4,
        marginRight: 5,
        borderRadius: 10
      },
})

export default ThemeHomeScreenMenu