import React, { useState, useEffect } from 'react';
import {
  View,
  StyleSheet,
  Dimensions,
  ActivityIndicator as ActivityIndicatorBase,
  FlatList,
  Platform,
} from 'react-native';
import { useSelector } from 'react-redux';
import {
  RootState,
  LocationInformation,
  ProductInformation,
} from '../../../types';
import { getGoodsListWithId, getGoodsUnderBrand } from '../../../utils/service';
import ProductCard from '../../Product/ProductCard';
import { tintColorLight, backGroundLight } from '../../../constants/Colors';
import ListFooterText from '../../common/list/ListFooterText';
import ActivityIndicator from '../../common/ActivityIndicator';
import { useHeaderHeight } from '@react-navigation/stack';
import FullWidthView from '../../common/FullWidthView';

type VerticalGoodsListProps = {
  data: VerticalGoodsListItem;
};

type VerticalGoodsListItem = {
  ad_id: number;
  media_type: number;
  media_type_name: string;
  cat_id: number;
  cat_name: string;
  goods_list_style: number;
  goods_type: string;
  title_color: string;
  brand_id: number;
  brand_name?: string;
  brand_item_number?: number;
};

const { width, height } = Dimensions.get('window');

const VerticalGoodsList = ({ data }: VerticalGoodsListProps) => {
  const [goodsList, setGoodsList] = useState<Array<ProductInformation>>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { countryId, provinceId, cityId } = useSelector<
    RootState,
    LocationInformation
  >((state) => state.goods.location);
  const [isFetching, setIsFetching] = useState(false);

  const { cat_id } = data;

  useEffect(() => {
    let isSubscribe = true;
    setLoading(true);
    setIsFetching(true);
    if (data.brand_id !== 0) {
      getGoodsUnderBrand(
        cat_id,
        1,
        countryId,
        provinceId,
        cityId,
        10,
        data.goods_type,
        data.brand_id
      )
        .then((result) => {
          if (isSubscribe) {
            if (result && result.goodStatus) {
              setGoodsList(result.data.goods);
              setTotalPage(result.data.total_page);
              setPage(1);
            } else {
              throw result?.data;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
          setIsFetching(false);
        });
    } else {
      getGoodsListWithId(
        cat_id,
        1,
        countryId,
        provinceId,
        cityId,
        10,
        data.goods_type
      )
        .then((result) => {
          if (isSubscribe) {
            if (result && result.goodStatus) {
              setGoodsList(result.data.goods);
              setTotalPage(result.data.total_page);
              setPage(1);
            } else {
              throw result?.data;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
          setIsFetching(false);
        });
    }

    return () => {
      isSubscribe = false;
    };
  }, [cat_id]);

  const loadExtraData = async () => {
    if (isFetching) return;
    //Load more pages if less than total page
    setIsFetching(true);
    if (page < totalPage) {
      if (data.brand_id !== 0) {
        getGoodsUnderBrand(
          cat_id,
          page + 1,
          countryId,
          provinceId,
          cityId,
          10,
          data.goods_type,
          data.brand_id
        )
          .then((result) => {
            if (result && result.goodStatus) {
              setGoodsList((oldValues) => [...oldValues, ...result.data.goods]);
              setPage((oldValue) => oldValue + 1);
            } else {
              throw result?.data;
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsFetching(false);
          });
      } else {
        getGoodsListWithId(
          cat_id,
          page + 1,
          countryId,
          provinceId,
          cityId,
          10,
          data.goods_type
        )
          .then((result) => {
            if (result && result.goodStatus) {
              setGoodsList((oldValues) => [...oldValues, ...result.data.goods]);
              setPage((oldValue) => oldValue + 1);
            } else {
              throw result?.data;
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsFetching(false);
          });
      }
    }
  };

  const _renderItem = ({ item }: any) => <ProductCard data={item} />;

  const styles = StyleSheet.create({
    rootContainer: {
      backgroundColor: backGroundLight,
    },
    listContainer: {
      width: width - 20,
      marginHorizontal: 10,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    loadingContainer: {
      width: width,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: backGroundLight,
    },
    list: {
      width: width,
    },
  });

  return (
    <View style={styles.rootContainer}>
      {loading ? (
        <View style={[styles.loadingContainer]}>
          <ActivityIndicatorBase size="small" color={tintColorLight} />
        </View>
      ) : (
        <FlatList
          ListFooterComponentStyle={{ backgroundColor: backGroundLight }}
          disableVirtualization
          data={goodsList}
          style={styles.list}
          renderItem={_renderItem}
          keyExtractor={(item: ProductInformation, index) =>
            item.goods_id.toString() + '@' + index.toString()
          }
          numColumns={2}
          showsVerticalScrollIndicator={false}
          columnWrapperStyle={{
            marginHorizontal: 10,
            justifyContent: 'space-between',
          }}
          onEndReachedThreshold={0.01}
          onEndReached={() => loadExtraData()}
          ListFooterComponent={
            page >= totalPage ? ListFooterText : ActivityIndicator
          }
        />
      )}
    </View>
  );
};

export default VerticalGoodsList;
