import React from 'react';
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native';
import NavigateButton from '../../../components/common/NavigateButton';
import StyledText from '../../../components/common/StyledText';
import { accentColor, subTextColor } from '../../../constants/Colors';
import { AntDesign } from '@expo/vector-icons';
import { UncommentListItem } from '../../../types';
import { TFunction } from 'i18next';

type UncommentedReviewListItemProps = UncommentListItem & {
  t: TFunction;
  handlePress: Function;
};

class UncommentedReviewListItem extends React.Component<
  UncommentedReviewListItemProps,
  any
> {
  constructor(props: UncommentedReviewListItemProps) {
    super(props);
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const t = this.props.t;

    return (
      <View style={styles.goodContainer}>
        <NavigateButton
          route="ProductNavigator"
          options={{
            params: { productId: this.props.goods_id },
            screen: 'ProductDetailScreen',
          }}
        >
          <Image
            source={{ uri: this.props.goods_thumb }}
            style={{ width: 100, height: 100, marginRight: 10 }}
          />
        </NavigateButton>
        <View style={styles.goodContainerInfo}>
          <NavigateButton
            route="ProductNavigator"
            options={{
              params: { productId: this.props.goods_id },
              screen: 'ProductDetailScreen',
            }}
          >
            <View>
              <StyledText>{this.props.goods_name}</StyledText>
              {this.props.goods_attr !== '' && (
                <StyledText color={subTextColor} size={12}>
                  {this.props.goods_attr.replace(/\|/g, '\n')}
                </StyledText>
              )}
            </View>
            {this.props.get_points !== undefined && this.props.get_points > 0 && (
              <View style={{ paddingTop: 5 }}>
                <StyledText size={12}>
                  <StyledText size={12} color={subTextColor}>
                    {t('review.canGetPoints1')}{' '}
                  </StyledText>
                  <StyledText size={12} color={accentColor}>
                    {this.props.get_points} {t('review.canGetPoints2')}
                  </StyledText>
                </StyledText>
              </View>
            )}
          </NavigateButton>
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              style={styles.button}
              onPress={() => this.props.handlePress()}
            >
              <StyledText color={accentColor}>
                {t('review.leaveReview')}
              </StyledText>
              <AntDesign
                name="rightcircleo"
                size={18}
                color={accentColor}
                style={{ marginLeft: 5 }}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }
}

export default UncommentedReviewListItem;

const styles = StyleSheet.create({
  goodContainer: {
    width: '100%',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgray',
    flexDirection: 'row',
  },
  goodContainerInfo: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  button: {
    flexDirection: 'row',
  },
});
