import React, { useState, useRef } from 'react';
import FullWidthView from '../../../components/common/FullWidthView';
import { View, StyleSheet, Dimensions } from 'react-native';
import GoogleMapView from '../../../components/common/map/GoogleMapView.web';
import MapView from 'react-native-maps';
import { GooglePlaceDetail } from 'react-native-google-places-autocomplete';
import StyledButton from '../../../components/common/StyledButton';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../types';
import { useHeaderHeight } from '@react-navigation/stack';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete';
import { useUpdateEffect } from 'ahooks';
import MapBoxGeocoder from '../../../components/common/map/MapBoxGeocoder';
import MapBoxView from '../../../components/common/map/MapBoxView.web';
import alert from '../../../utils/alert';

const { width } = Dimensions.get('window');

const AddressMapScreen = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const addressLocation = useSelector<
    RootState,
    { latitude: number; longitude: number }
  >((state) => state.user.editingAddress.location);

  const googleMapKey = useSelector<RootState, string>(
    (state) => state.user.googleMayWebKey
  );

  const mapViewRef = useRef<MapView>(null);
  const headerHeight = useHeaderHeight();
  const [addressFound, setAddressFound] = useState(false);
  const [value, setValue] = useState<any>(null);
  const [viewport, setViewport] = useState({
    latitude: addressLocation.latitude,
    longitude: addressLocation.longitude,
    zoom: 10,
  });

  const isLocatedInChina = useSelector<RootState, boolean>(
    (state) => state.user.isLocatedInChina
  );

  const [newAddressPayload, setNewAddressPayload] = useState<{
    name: string;
    location: { longitude: number; latitude: number };
  }>();

  useUpdateEffect(() => {
    if (value && value.value && value.value.place_id !== undefined) {
      geocodeByPlaceId(value.value.place_id)
        .then((results) => {
          const addressInfo = handleAddressSelection(results[0]);
          return addressInfo;
        })
        .then((addressInfo) => {
          if (addressInfo) {
            getLatLng(addressInfo.geo).then((result) => {
              setAddressFound(true);
              setNewAddressPayload({
                name: addressInfo.name,
                location: {
                  longitude: result.lng,
                  latitude: result.lat,
                },
              });
            });
          } else {
            setAddressFound(false);
            setNewAddressPayload(undefined);
            alert(t('address.error'), t('address.invalidAddressAlert'));
          }
        })
        .catch((error) => console.error(error));
    }
  }, [value]);

  const handleAddressSelection = (detail: GooglePlaceDetail) => {
    const requiredFields = [
      'street_number',
      'route',
      'locality',
      'administrative_area_level_1',
      'country',
    ];

    let hasStreetNumber = false;
    let hasStreetName = false;
    const addressInfo = detail.address_components.reduce((value, item) => {
      if (requiredFields.includes(item.types[0])) {
        switch (item.types[0]) {
          case 'street_number':
            hasStreetNumber = true;
          case 'route':
            hasStreetName = true;
          default:
            return value + ' ' + item.long_name;
          case 'locality':
            return value + ', ' + item.long_name;
          case 'administrative_area_level_1':
          case 'country':
            return value + ', ' + item.short_name;
        }
      }
      return value;
    }, '');

    if (hasStreetNumber && hasStreetName) {
      return { name: addressInfo, geo: detail };
    }
    return null;
  };

  const updateAddress = (payload?: {
    name: string;
    location: { longitude: number; latitude: number };
  }) => {
    dispatch({
      type: 'UPDATE_EDITING_ADDRESS',
      payload: payload,
    });
  };

  const handleConfirmAddress = () => {
    if (newAddressPayload) updateAddress(newAddressPayload);
    navigation.canGoBack() ? navigation.goBack() : window.history.back();
  };

  return (
    <FullWidthView>
      {isLocatedInChina ? (
        <MapBoxView
          longitude={
            (newAddressPayload && newAddressPayload.location.longitude) ||
            addressLocation.longitude
          }
          latitude={
            (newAddressPayload && newAddressPayload.location.latitude) ||
            addressLocation.latitude
          }
          viewport={viewport}
          setViewport={setViewport}
        />
      ) : (
        <GoogleMapView
          longitude={
            (newAddressPayload && newAddressPayload.location.longitude) ||
            addressLocation.longitude
          }
          latitude={
            (newAddressPayload && newAddressPayload.location.latitude) ||
            addressLocation.latitude
          }
          mapViewRef={mapViewRef}
        />
      )}
      <View style={styles.infoContainer}>
        {isLocatedInChina ||
        navigator.userAgent.toLocaleLowerCase().indexOf('alipay') > -1 ? (
          <MapBoxGeocoder
            setAddressFound={setAddressFound}
            setViewport={setViewport}
            setNewAddressPayload={setNewAddressPayload}
          />
        ) : (
          <GooglePlacesAutocomplete
            apiKey={googleMapKey}
            minLengthAutocomplete={2}
            autocompletionRequest={{
              componentRestrictions: {
                country: ['ca'],
              },
            }}
            selectProps={{
              value,
              onChange: setValue,
            }}
          />
        )}
      </View>
      {addressFound && (
        <View style={[styles.confirmBtn, { bottom: headerHeight + 25 }]}>
          <StyledButton
            label={t('address.info.confirmAddress')}
            onPress={handleConfirmAddress}
          />
        </View>
      )}
    </FullWidthView>
  );
};

const styles = StyleSheet.create({
  infoContainer: {
    backgroundColor: 'white',
    position: 'absolute',
    width: width * 0.95,
    borderRadius: 25,
    top: 10,
    alignSelf: 'center',
  },
  emptyTextButton: {
    alignSelf: 'center',
    justifyContent: 'center',
    paddingRight: 10,
  },
  searchIcon: {
    alignSelf: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
  },
  confirmBtn: {
    position: 'absolute',
    alignSelf: 'center',
  },
});

export default AddressMapScreen;
