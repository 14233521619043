import React, { useState } from 'react';
import {
  Platform,
  Modal,
  View,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import WhiteCard from '../../common/WhiteCard';
import { Tooltip } from 'react-native-elements';
import { AntDesign } from '@expo/vector-icons';
import alert from '../../../utils/alert';
import StyledText from '../../common/StyledText';
import { useTranslation } from 'react-i18next';
import NotifyMethodSelectorModal from './NotifyMethodSelectorModal';
import { normalTextColor, subTextColor } from '../../../constants/Colors';

type NotifyMethodProps = {
  notifyText: string;
  notifyMethod: string;
  setNotifyMethod: React.Dispatch<React.SetStateAction<string>>;
  notifyMethodList: Array<string>;
};

const NotifyMethod = ({
  notifyText = '',
  notifyMethod,
  setNotifyMethod,
  notifyMethodList,
}: NotifyMethodProps) => {
  const { t } = useTranslation();

  const [showNotifyMethodModal, setShowNotifyMethodModal] = useState(false);

  return (
    <WhiteCard
      title={t('order.notifyBy')}
      icon={
        Platform.OS === 'web'
          ? {
              iconComponent: (
                <AntDesign name="questioncircleo" size={14} color="lightgray" />
              ),
              handleActionPress: () => alert(notifyText),
            }
          : {
              iconComponent: (
                <Tooltip
                  ModalComponent={Modal}
                  popover={
                    <View style={{ backgroundColor: 'black' }}>
                      <StyledText color="white">{notifyText}</StyledText>
                    </View>
                  }
                  backgroundColor="black"
                  width={240}
                  height={180}
                >
                  <AntDesign
                    name="questioncircleo"
                    size={14}
                    color="lightgray"
                  />
                </Tooltip>
              ),
            }
      }
    >
      <TouchableOpacity
        onPress={() => {
          setShowNotifyMethodModal(true);
        }}
      >
        <View style={styles.inputContainer}>
          {notifyMethod !== '' ? (
            <StyledText style={styles.selectorText}>{notifyMethod}</StyledText>
          ) : (
            <StyledText color={subTextColor} style={styles.selectorText}>
              {t('order.selectMethod')}
            </StyledText>
          )}
        </View>
      </TouchableOpacity>
      <NotifyMethodSelectorModal
        visible={showNotifyMethodModal}
        hideModal={() => setShowNotifyMethodModal(false)}
        currentType={''}
        setNotifyMethod={setNotifyMethod}
        notifyMethodList={notifyMethodList}
      />
    </WhiteCard>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectorText: {
    padding: 5,
  },
});

export default NotifyMethod;
