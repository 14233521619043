import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
} from 'react';
import {
  View,
  StyleSheet,
  FlatList,
  Platform,
  RefreshControl,
} from 'react-native';
import axios, { CancelTokenSource } from 'axios';
import Constants from 'expo-constants';
import FullWidthView from '../../components/common/FullWidthView';
import { useSelector } from 'react-redux';
import { RootState, LocationInformation, PostCategoryItem } from '../../types';
import { backGroundLight, tintColorLight } from '../../constants/Colors';
import { getData } from '../../utils/service';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'ahooks';
import { resetWechatSharePage } from '../../utils/wechatWebMethods';

/*Import: Components */
import { useHeaderHeight } from '@react-navigation/stack';
import PostMenu from '../../components/Post/PostMenu';
import PostCard from '../../components/Post/card/PostCard';
import PostButton from '../../components/Post/PostButton';
import ListFooterText from '../../components/common/list/ListFooterText';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import EmptyDisplayBlock from '../../components/common/EmptyDisplayBlock';
import ProductListSkeletonLoad from '../../components/common/ProductListSkeletonLoad';
import NoContent from '../../components/Category/NoContent';

/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import getAppStyle from '../../utils/getAppStyle';

// WechatShareBtn should only be shown on standalone app
let WechatShareBtn: Function;
if (Constants.appOwnership === 'expo' || Platform.OS === 'web') {
  WechatShareBtn = () => <View></View>;
} else {
  WechatShareBtn =
    require('../../components/common/WechatShare/WechatShareBtn').WechatShareBtn;
}

const PostScreen = ({ navigation, route }: any) => {
  const [navList, setNavList] = useState<Array<PostCategoryItem>>([]);
  const [postList, setPostList] = useState<any>([]);
  const [horizontalIndex, setHorizontalIndex] = useState(0);
  const [selectedCatId, setSelectedCatId] = useState(0);
  const [postAllLoaded, setPostAllLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstLoadComplete, setFirstLoadComplete] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [shouldShowPosts, setShouldShowPosts] = useState(true);
  const { t } = useTranslation();

  const isSubscribed = useRef(true);
  const menuRef = useRef<FlatList<any>>(null);
  const cancelToken = useRef<CancelTokenSource>();

  const location = useSelector<RootState, LocationInformation>(
    (state) => state.goods.location
  );

  const [isFetching, setIsFetching] = useState(false);

  const handleCategoryPress = (index: number, cat_id: number) => {
    setHorizontalIndex(index);
    setSelectedCatId(cat_id);
  };

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getNav = () => {
    getData(`post/nav`)
      .then((result) => {
        if (result && result.goodStatus) {
          if (isSubscribed.current) {
            setNavList(result.data);
            setShouldShowPosts(true);
          }
        } else {
          setShouldShowPosts(false);
          Toast.show(result?.data || 'err', {
            position: 0,
            duration: 2000,
          });
        }
      })
      .catch((err) => {
        setShouldShowPosts(false);
        Toast.show('err', {
          position: 0,
          duration: 2000,
        });
      });
  };

  // Re-fetch category when screen is focused. Mainly for the time that user changes language.
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      //get post nav list
      if (location.countryId !== 0) {
        getNav();
      } else {
        setShouldShowPosts(false);
      }
    });

    return unsubscribe;
  }, [navigation]);

  // For auto-middle category when is selected.
  useUpdateEffect(() => {
    try {
      if (menuRef && menuRef.current) {
        menuRef.current.scrollToIndex({
          animated: true,
          index: horizontalIndex,
          viewPosition: 0.5,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [horizontalIndex]);

  const getFirstPage = () => {
    cancelToken.current = axios.CancelToken.source();
    setFirstLoadComplete(false);
    setPostAllLoaded(false);
    setPostList([]);
    setCurrentPage(1);
    setIsFetching(true);
    getData(`post/list?cat_id=${selectedCatId}&page=1&size=10`, {
      cancelToken: cancelToken.current.token,
    })
      .then((result) => {
        if (result && result.goodStatus) {
          if (isSubscribed.current) {
            setPostList(result.data.posts);
            setTotalPage(result.data.total_page);
            result.data.posts.length < 10 && setPostAllLoaded(true);
          }
        } else {
          Toast.show(result?.data || 'err', {
            position: 0,
            duration: 2000,
          });
        }
      })
      .catch((err) => {
        Toast.show('err', {
          position: 0,
          duration: 2000,
        });
      })
      .finally(() => {
        setFirstLoadComplete(true);
        setIsRefreshing(false);
        setIsFetching(false);
      });
  };

  useEffect(() => {
    if (location.countryId !== 0) {
      getFirstPage();
    }
    return () => {
      if (typeof cancelToken.current != typeof undefined) {
        cancelToken.current?.cancel('operation canceled due to new request');
      }
    };
  }, [selectedCatId, location]);

  useUpdateEffect(() => {
    try {
      if (menuRef && menuRef.current) {
        menuRef.current.scrollToIndex({
          animated: true,
          index: horizontalIndex,
          viewPosition: 0.5,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [horizontalIndex]);

  useEffect(() => {
    if (currentPage !== 1) {
      if (!postAllLoaded) {
        setIsFetching(true);
        getData(`post/list?cat_id=${selectedCatId}&page=${currentPage}&size=10`)
          .then((result) => {
            if (result) {
              if (result.goodStatus) {
                if (isSubscribed.current) {
                  setPostList([...postList, ...result.data.posts]);
                  currentPage == totalPage && setPostAllLoaded(true);
                }
              }
            } else {
              console.log('error');
            }
          })
          .catch((err) => {
            Toast.show('err', {
              position: 0,
              duration: 2000,
            });
          })
          .finally(() => {
            setIsFetching(false);
          });
      }
    }
  }, [currentPage]);

  const handleReachBottomUpdate = () => {
    if (isFetching) return;
    !postAllLoaded &&
      currentPage < totalPage &&
      setCurrentPage(currentPage + 1);
  };

  /* Wechat section -------- Start */
  // Setting up Wechat share information
  useEffect(() => {
    const currentCategory = navList.find((nav) => nav.cat_id === selectedCatId);
    const title = currentCategory
      ? `${t('postScreen.title')} | ${currentCategory.cat_name} ${t(
          'postScreen.wechatShare'
        )}`
      : `${t('postScreen.title')} ${t('postScreen.wechatShare')}`;

    const link =
      Platform.OS === 'web'
        ? `${
            window.location.href.split('?')[0]
          }?postCategoryIndex=${horizontalIndex}&postSelectedId=${selectedCatId}`
        : `${
            getAppStyle(process.env.EXPO_APP_TYPE)?.appWebsite
          }/post?postCategoryIndex=${horizontalIndex}&postSelectedId=${selectedCatId}`;

    const imgUrl = 'https://image.luniumall.com/default/postIcon.png';

    // Give a timeOut to make it goes after default setup.
    if (Platform.OS === 'web')
      resetWechatSharePage(title, undefined, imgUrl, link, 1000);

    // Also set the shareBtn for Mobile platform.
    navigation.setOptions({
      headerRight: () => <WechatShareBtn title={title} url={link} />,
    });

    // Set up Wechat share information "again" when user come back from the other page.
    const unsubscribe = navigation.addListener('focus', () => {
      if (Platform.OS === 'web')
        resetWechatSharePage(title, undefined, imgUrl, link);
    });
    return unsubscribe;
  }, [navList, horizontalIndex, navigation]);

  // Extract query params from url and set to State in the end of component mounting cycle. The query params are set when update the share link for WechatShare.
  useLayoutEffect(() => {
    if (Platform.OS === 'web') {
      const url: URL = new URL(window.location.href);
      const params: URLSearchParams = url.searchParams;
      const postCategoryIndex = params.get('postCategoryIndex') ?? 0;
      const postSelectedId = params.get('postSelectedId') ?? 0;
      handleCategoryPress(+postCategoryIndex, +postSelectedId);
    }
  }, []);

  /* Wechat section -------- End */

  const _renderItem = useCallback(
    ({ item }: { item: any; index: number }) => (
      <PostCard
        comment_id={item.comment_id}
        nick_name={item.nick_name}
        user_picture={item.user_picture}
        add_time={item.add_time}
        tag={item.tag}
        content={item.content}
        images={item.images}
        goods={item.goods}
        user_id={item.user_id}
        navigation={navigation}
        comment_rank={item.comment_rank}
        admin_reply={null}
        isDetailScreen={false}
        adminReply={item.admin_reply}
      />
    ),
    []
  );

  const _keyExtractor = useCallback(
    (item, index) => item.comment_id.toString() + '@' + index.toString(),
    []
  );

  const onRefresh = () => {
    setIsRefreshing(true);
    getFirstPage();
  };

  const styles = StyleSheet.create({
    postMenuView: {
      flexDirection: 'row',
      borderBottomWidth: 2,
      borderBottomColor: backGroundLight,
    },
    rootContainer: {
      backgroundColor: backGroundLight,
      flexDirection: 'column',
      position: 'relative',
    },
    cardContainer: {
      flex: 1,
    },
  });

  return (
    <FullWidthView
      edges={['left', 'right']}
      style={{ backgroundColor: backGroundLight }}
    >
      {shouldShowPosts ? (
        <>
          {navList.length > 0 && (
            <View style={styles.postMenuView}>
              <PostMenu
                ref={menuRef}
                categoryNames={navList}
                selectedCatId={selectedCatId}
                horizontalIndex={horizontalIndex}
                handleCategoryPress={handleCategoryPress}
              />
            </View>
          )}
          {firstLoadComplete ? (
            postList.length > 0 ? (
              <FlatList
                data={postList}
                keyExtractor={_keyExtractor}
                showsVerticalScrollIndicator={false}
                renderItem={_renderItem}
                onEndReachedThreshold={0.01}
                onEndReached={() => handleReachBottomUpdate()}
                ListFooterComponent={
                  postAllLoaded ? ListFooterText : ActivityIndicator
                }
                /* bump these up so when user does fast scrolling, items can render properly. */
                maxToRenderPerBatch={200}
                windowSize={41}
                refreshControl={
                  <RefreshControl
                    colors={[tintColorLight]}
                    tintColor={tintColorLight}
                    onRefresh={onRefresh}
                    refreshing={isRefreshing}
                  />
                }
              />
            ) : (
              <EmptyDisplayBlock />
            )
          ) : (
            <ProductListSkeletonLoad />
          )}
          <PostButton />
        </>
      ) : (
        <NoContent />
      )}
    </FullWidthView>
  );
};

export default PostScreen;
