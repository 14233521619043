import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ProductParamList } from '../../types';

import CommentScreen from '../../screens/Product/CommentScreen';
import ProductDetailScreen from '../../screens/Product/ProductDetailScreen';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/common/iconButtons/BackButton';
import NavigateButton from '../../components/common/NavigateButton';
import ProductOptionSelector from '../../components/Product/ProductOptionSelector';
import AlertModal from '../../components/common/AlertModal/AlertModal';
import getAppStyle from '../../utils/getAppStyle';
import CartNavigator from '../CartNavigator';

const ProductStack = createStackNavigator<ProductParamList>();

const ProductNavigator = () => {
  const { t } = useTranslation();

  return (
    <>
      <ProductStack.Navigator
        initialRouteName="ProductDetailScreen"
        screenOptions={{
          headerShown: false,
          headerBackTitleVisible: false,
          headerLeft: () => (
            <NavigateButton goBack>
              <BackButton />
            </NavigateButton>
          ),
          headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)
            ?.screenHeaderStyle,
        }}
      >
        <ProductStack.Screen
          name="ProductDetailScreen"
          component={ProductDetailScreen}
        />
        <ProductStack.Screen
          name="CommentScreen"
          component={CommentScreen}
          options={{
            headerShown: true,
            title: t('productDetailScreen.productReviews'),
            headerTitleAlign: 'center',
          }}
        />
        <ProductStack.Screen
          name="Cart"
          component={CartNavigator}
          options={{
            headerBackTitleVisible: false,
            headerBackImage: BackButton,
            headerTitle: t('tabsLabel.tabCart'),
          }}
        />
      </ProductStack.Navigator>
      <ProductOptionSelector />
      <AlertModal />
    </>
  );
};

export default ProductNavigator;
