import React, { useState, useEffect } from 'react';
import * as Location from 'expo-location';
import { getData, postData } from '../../utils/service';
import Loading from '../../components/common/Loading';
import FullWidthView from '../../components/common/FullWidthView';
import { TopicPage, RootState, LocationInformation } from '../../types';
import Header from '../../components/Home/Header';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAsyncStorageData,
  setAsyncStorageData,
} from '../../utils/asyncStorage';
import alert from '../../utils/alert';
import { useTranslation } from 'react-i18next';
import NoLocation from './NoLocation';
import { Dimensions, Platform, View } from 'react-native';
import ThemeHomeScreenPaginator from '../../components/ThemeHomeScreen/ThemeHomeScreenPaginator';
import ThemeHomeScreenPage from '../../components/ThemeHomeScreen/ThemeHomeScreenPage';
import { MaterialIcons } from '@expo/vector-icons';
import StyledText from '../../components/common/StyledText';
import { screenHit } from '../../utils/analytic';
import { useHeaderHeight } from '@react-navigation/stack';
import useIsWeChat from '../../hooks/useIsWeChat';

const { width } = Dimensions.get('window');

export default function HomeScreen() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const location = useSelector<RootState, LocationInformation>(
    (state) => state.goods.location
  );

  const appMainColor = useSelector<RootState, string>(
    (state) => state.user.appMainColor
  );

  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [homeScreenChildren, setHomeScreenChildren] = useState<
    Array<TopicPage>
  >([]);
  const [hasLocationInfo, setHasLocationInfo] = useState(false);
  const [locationIssue, setLocationIssue] = useState<
    'pending' | 'granted' | 'range'
  >('pending');
  const [singleTab, setSingleTab] = useState(false);
  const [horizontalIndex, setHorizontalIndex] = useState(0);
  const [isError, setIsError] = useState(false);

  //Get home data when this component mount
  useEffect(() => {
    let isSubscribed = true;
    screenHit('home');

    (async () => {
      let { status } = await Location.requestForegroundPermissionsAsync();
      //Get location information if exist and set to redux
      const locationInformation = await getAsyncStorageData(
        '@storage_location'
      );

      //location information exist, update location redux
      if (locationInformation) {
        let userLocationPreference = JSON.parse(locationInformation);
        dispatch({
          type: 'UPDATE_LOCATION',
          payload: userLocationPreference,
        });
        setHasLocationInfo(true);

        if (status === 'granted') {
          let userLocation = await Location.getLastKnownPositionAsync();
          if (userLocation === null) {
            userLocation = await Location.getCurrentPositionAsync({
              accuracy: Location.Accuracy.Lowest,
            });
          }
          getUserCurrentRegion(
            userLocation.coords.latitude,
            userLocation.coords.longitude
          )
            .then((result) => {
              if (result && result.goodStatus) {
                const newLocation = {
                  countryId: result.data.country_id,
                  provinceId: result.data.province_id,
                  cityId: result.data.city_id,
                  areaName: result.data.full_region_name,
                  provinceName: result.data.province_name,
                  postalCodePrefix: result.data.postal_code_prefix,
                };
                if (
                  userLocationPreference.countryId !== newLocation.countryId ||
                  userLocationPreference.provinceId !==
                    newLocation.provinceId ||
                  userLocationPreference.cityId !== newLocation.cityId
                ) {
                  alert(
                    t('homeScreen.regionPrompt.title'),
                    t('homeScreen.regionPrompt.content', {
                      areaName: newLocation.areaName,
                    }),
                    [
                      {
                        text: t('cancel'),
                        style: 'cancel',
                      },
                      {
                        text: t('confirm'),
                        onPress: () => {
                          //Save location info to storage when user change location
                          postPostalCode(result.data.postal_code_prefix)
                            .then(() => {
                              setAsyncStorageData(
                                '@storage_location',
                                JSON.stringify(newLocation)
                              );
                              dispatch({
                                type: 'UPDATE_LOCATION',
                                payload: newLocation,
                              });
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        },
                      },
                    ],
                    { cancelable: false }
                  );
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } //No async storage location info,
      else {
        if (status !== 'granted') {
          setLocationIssue('granted');
          setHasLocationInfo(false);
        } else {
          let location = await Location.getLastKnownPositionAsync();
          if (location === null) {
            location = await Location.getCurrentPositionAsync({
              accuracy: Location.Accuracy.Lowest,
            });
          }
          //Get current use region
          getUserCurrentRegion(
            location.coords.latitude,
            location.coords.longitude
          )
            .then((result) => {
              if (result && result.goodStatus) {
                const newLocation = {
                  countryId: result.data.country_id,
                  provinceId: result.data.province_id,
                  cityId: result.data.city_id,
                  areaName: result.data.full_region_name,
                  provinceName: result.data.province_name,
                  postalCodePrefix: result.data.postal_code_prefix,
                };
                postPostalCode(result.data.postal_code_prefix)
                  .then(() => {
                    setAsyncStorageData(
                      '@storage_location',
                      JSON.stringify(newLocation)
                    );
                    dispatch({
                      type: 'UPDATE_LOCATION',
                      payload: newLocation,
                    });
                    if (isSubscribed) {
                      setHasLocationInfo(true);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                if (isSubscribed) {
                  setLocationIssue('range');
                  setHasLocationInfo(false);
                }
              }
            })
            .catch((err) => {
              console.log(err);
              if (isSubscribed) {
                setLocationIssue('range');
                setHasLocationInfo(false);
              }
            });
        }
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    loadHomePage();
  }, [location]);

  const loadHomePage = () => {
    setIsError(false);
    if (location && location.countryId !== 0) {
      setIsLoadingComplete(false);
      setHasLocationInfo(true);
      getHomeScreenData(
        location.countryId,
        location.provinceId,
        location.cityId
      )
        .then((result) => {
          if (result && result.goodStatus && result.data) {
            //Has single tabs
            const children = result.data.ad_page_child;
            if (children == 0) {
              setSingleTab(true);
            } //Has multiple tabs
            else {
              setSingleTab(false);
              setHomeScreenChildren(children);
            }
          } else {
            setIsError(true);
            console.error(result);
          }
          setIsLoadingComplete(true);
        })
        .catch((err) => {
          setIsError(true);
          alert(JSON.stringify(err));
          console.log(err);
          setIsLoadingComplete(true);
        });
    } else {
      setIsLoadingComplete(true);
    }
  };

  return (
    <FullWidthView
      edges={['left', 'right', 'top']}
      safeAreaBackgroundColor={isLoadingComplete ? appMainColor : 'white'}
    >
      {hasLocationInfo ? (
        !isLoadingComplete ? (
          <Loading />
        ) : (
          <>
            <Header />
            {isError ? (
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <MaterialIcons name="error" size={24} color="lightgray" />
                <StyledText color={'lightgray'}>
                  {t('failedToRetrieveItem')}
                </StyledText>
              </View>
            ) : singleTab ? (
              <ThemeHomeScreenPage />
            ) : (
              <ThemeHomeScreenPaginator
                data={homeScreenChildren}
                horizontalIndex={horizontalIndex}
                setHorizontalIndex={setHorizontalIndex}
                width={width}
              />
            )}
          </>
        )
      ) : (
        <NoLocation type={locationIssue} />
      )}
    </FullWidthView>
  );
}

async function getHomeScreenData(
  countryId: number,
  provinceId: number,
  cityId: number
) {
  const result = await getData(
    `home?country_id=${countryId}&province_id=${provinceId}&city_id=${cityId}`
  );
  return result;
}

async function getUserCurrentRegion(lat: number, lng: number) {
  const result = await getData(`current_region?lat=${lat}&lng=${lng}`);
  return result;
}

const postPostalCode = async (postalCode: string) => {
  const result = await postData('store_region', { postal_code: postalCode });
  return result;
};
