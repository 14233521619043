import React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle, Platform } from 'react-native';
import StyledText from '../StyledText';

type NotificationDotProps = {
  label?: string;
  otherStyles?: StyleProp<ViewStyle>;
};

const NotificationDot = ({ label, otherStyles }: NotificationDotProps) => {
  return (
    <View style={[styles.container, otherStyles]}>
      {label ? (
        <StyledText
          size={9}
          bold
          color='white'
          style={styles.text}
          align='center'
          numberOfLines={1}
        >
          {label}
        </StyledText>
      ) : (
        <View style={styles.dot}></View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    position: 'absolute',
    alignSelf: 'flex-end',
    zIndex: 10,
    overflow: 'hidden',
    right: 0,
    top: 2,
  },
  text: {
    backgroundColor: '#fa1b13',
    paddingHorizontal: 2,
    width: 22,
  },
  dot: {
    width: 10,
    height: 10,
    backgroundColor: '#fa1b13',
    borderRadius: 50,
  },
});

export default NotificationDot;
