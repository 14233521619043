import { AntDesign } from '@expo/vector-icons';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyleSheet,
  View,
  Platform,
  TextInput,
  FlatList,
  Pressable,
} from 'react-native';
import FullWidthView from '../../components/common/FullWidthView';
import ImagePickerModal from '../../components/common/ImagePickerModal';
import ResponsiveImg from '../../components/common/ResponsiveImg';
import StyledButton from '../../components/common/StyledButton';
import StyledText from '../../components/common/StyledText';
import { payOrder, postWithAuthToken } from '../../utils/service';
import alert from '../../utils/alert';
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import {
  CartParamList,
  OrderFormInfo,
  OrderFormInfoLight,
  OrderParamList,
} from '../../types';
import WhiteCard from '../../components/common/WhiteCard';
import { accentColor, backGroundLight } from '../../constants/Colors';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import { useHeaderHeight } from '@react-navigation/stack';

const UploadFileScreen = () => {
  const { t } = useTranslation();
  const route =
    useRoute<RouteProp<CartParamList | OrderParamList, 'UploadFileScreen'>>();
  const { creditFormInfo, data, from } = route.params;
  const navigation =
    useNavigation<
      NavigationProp<CartParamList | OrderParamList, 'UploadFileScreen'>
    >();
  const [formValue, setFormValue] = useState<{
    [key: string]: any;
  }>({});
  const [imageValue, setImageValue] = useState<{
    [key: string]: string;
  }>({});
  const [imageModalVisible, setImageModalVisible] = useState<boolean>(false);
  const [height, setHeight] = useState<{ [key: string]: number }>({});
  const flatListRef = useRef<FlatList>(null);
  const [currentFieldValue, setCurrentFieldValue] = useState<{
    parent_id: number;
    field_code: string;
  }>();
  const headerHeight = useHeaderHeight();

  useEffect(() => {
    if (
      creditFormInfo &&
      creditFormInfo.form &&
      creditFormInfo.form.length > 0
    ) {
      let groupValueStart: { [key: string]: OrderFormInfoLight } = {};
      creditFormInfo.form.map((group: OrderFormInfo) => {
        groupValueStart[group.field_id] = {
          field_id: group.field_id,
          field_code: group.field_code,
          field_name: group.field_name,
          child: {},
        };
        const groupFieldNames = group.child?.map((x) => {
          return { field_code: x.field_code, field_name: x.field_name };
        });
        let fieldGroupObj: {
          [key: string]: { field_name: string; value: string };
        } = {};
        groupFieldNames?.map((names) => {
          fieldGroupObj[names.field_code] = {
            field_name: names.field_name,
            value: '',
          };
        });
        groupValueStart[group.field_id].child = fieldGroupObj;
      });
      setFormValue(groupValueStart);
    }
  }, [creditFormInfo]);

  const updateImages = (img: ImageInfo) => {
    if (currentFieldValue) {
      setImageValue((prev) => ({
        ...prev,
        [`${currentFieldValue.field_code}@${currentFieldValue.parent_id}`]:
          img.uri,
      }));
      handleFieldChange(
        Platform.OS === 'web' ? img.uri : `data:image/png;base64,${img.base64}`,
        currentFieldValue.parent_id,
        currentFieldValue.field_code,
        false
      );
    }
  };

  const handleShowImgPicker = (parent_id: number, field_code: string) => {
    setCurrentFieldValue({ parent_id: parent_id, field_code: field_code });
    setImageModalVisible(true);
  };

  const handleFieldChange = (
    text: string,
    field_id: number,
    field_code: string,
    isNumeric: boolean
  ) => {
    if (isNumeric) {
      text = text.replace(/[^0-9]/g, '');
    }

    setFormValue((prev) => ({
      ...prev,
      [`${field_id}`]: {
        ...prev[`${field_id}`],
        child: {
          ...prev[`${field_id}`].child,
          [field_code]: {
            ...prev[`${field_id}`].child[field_code],
            value: text,
          },
        },
      },
    }));
  };

  const renderField = (field: OrderFormInfo) => {
    switch (field.field_type) {
      case 'group':
        return (
          <WhiteCard title={field.field_name} key={field.field_id}>
            {field.child &&
              Object.values(field.child).map((value) => renderField(value))}
          </WhiteCard>
        );
      case 'p_file':
      case 'file':
        return (
          <View key={field.field_id} style={{ paddingVertical: 5 }}>
            <StyledText>{field.field_name}</StyledText>
            {field.is_required === 1 && (
              <StyledText size={11} color={accentColor}>
                *{t('validation.required')}
              </StyledText>
            )}
            <View style={styles.idPhotoPlaceHolder}>
              {imageValue[`${field.field_code}@${field.parent_id}`] ? (
                <View>
                  <ResponsiveImg
                    uri={
                      imageValue[`${field.field_code}@${field.parent_id}`] ?? ''
                    }
                    width={200}
                  />
                  <Pressable
                    style={{ position: 'absolute', right: -12, top: -12 }}
                    onPress={() => {
                      setImageValue((prev) => ({
                        ...prev,
                        [`${field.field_code}@${field.parent_id}`]: '',
                      }));
                      handleFieldChange(
                        '',
                        field.parent_id ?? 0,
                        field.field_code,
                        false
                      );
                    }}
                  >
                    <AntDesign name="closecircle" size={20} color="red" />
                  </Pressable>
                </View>
              ) : (
                <AntDesign name="picture" size={100} color="lightgray" />
              )}
            </View>
            <StyledButton
              buttonSize={14}
              label={t('idVerification.select')}
              onPress={() =>
                handleShowImgPicker(field.parent_id ?? 0, field.field_code)
              }
              outline={
                field.is_required === 0 ||
                (formValue[`${field.parent_id}`] &&
                  formValue[`${field.parent_id}`].child &&
                  formValue[`${field.parent_id}`].child[field.field_code] &&
                  formValue[`${field.parent_id}`].child[field.field_code].value)
                  ? true
                  : false
              }
            />
          </View>
        );
      case 'text':
      case 'string':
      case 'textarea':
      case 'number':
        return (
          <View key={field.field_id}>
            <TextInput
              style={[
                styles.formInput,
                {
                  height: Math.max(28, height[field.field_code] ?? 0),
                  marginBottom: field.is_required === 1 ? 0 : 6,
                  fontSize: 16,
                },
              ]}
              placeholder={field.field_name}
              onChangeText={(text) =>
                handleFieldChange(
                  text,
                  field.parent_id ?? 0,
                  field.field_code,
                  field.field_type === 'number'
                )
              }
              keyboardType={
                field.field_type === 'number' ? 'numeric' : 'default'
              }
              multiline={field.field_type === 'textarea'}
              maxLength={field.field_type === 'string' ? 50 : undefined}
              onContentSizeChange={(event) => {
                setHeight({
                  ...height,
                  [field.field_code]: event.nativeEvent.contentSize.height,
                });
              }}
            />
            {field.is_required === 1 && (
              <StyledText size={11} color={accentColor}>
                *{t('validation.required')}
              </StyledText>
            )}
          </View>
        );
      default:
        return <></>;
    }
  };

  const scrollInvalidSubmit = (index: number) => {
    flatListRef.current !== null &&
      flatListRef.current.scrollToIndex({
        animated: true,
        index: index,
      });
  };

  const submitValidation = () => {
    let missingFields = false;
    creditFormInfo.form.map((formGroup: OrderFormInfo, index) => {
      formGroup.child?.map((field: OrderFormInfo) => {
        if (field.is_required === 1) {
          if (
            formValue[formGroup.field_id].child[field.field_code].value === ''
          ) {
            missingFields = true;
            scrollInvalidSubmit(index);
            return;
          }
        }
      });
    });

    if (missingFields) {
      return {
        status: false,
        msg: t('order.fillInRequired'),
      };
    }

    return { status: true };
  };

  const handleSubmitButtonPress = async () => {
    const isValidSubmit = submitValidation();
    if (isValidSubmit.status) {
      const loading = Toast.showLoading('');
      if (from === 'AddOrderGoodsScreen') {
        const result = await postWithAuthToken('order/add_order_goods', {
          ...data,
          credit_form_value: JSON.stringify(Object.values(formValue)),
        })
          .then((response) => {
            if (
              response &&
              response.goodStatus &&
              response.data &&
              response.data.process_order_pay
            ) {
              return true;
            } else {
              console.log('Wrong', response);
              alert(response?.data);
              Toast.hide(loading);
              return false;
            }
          })
          .catch((err) => {
            console.log(err);
            Toast.hide(loading);
            return false;
          });

        if (!result) return;
      }

      (from === 'OrderPreviewScreen'
        ? submitOrder({
            ...data,
            credit_form_value: JSON.stringify(Object.values(formValue)),
          })
        : payOrder(
            from === 'AddOrderGoodsScreen'
              ? {
                  orderId: data.order_id,
                  payId: data.pay_id,
                  cardId: data.card_id,
                  cvd: data.cvd,
                }
              : data
          )
      )
        .then((result) => {
          Toast.hide(loading);
          if (result && result.goodStatus) {
            //@ts-ignore
            navigation.navigate('User', {
              screen: 'Order',
              params: {
                screen: 'OrderSubmit',
                params: { data: result.data },
              },
            });
          } //Jump to payment
          else if (result && result.return_code === 303) {
            //@ts-ignore
            navigation.navigate('User', {
              screen: 'Order',
              params: {
                screen: 'OrderSubmit',
                params: { data: result.data },
              },
            });
          } //Credit card pay failed, go to await pay list
          else if (result && result.return_code === 304) {
            alert(
              result.data,
              '',
              [
                {
                  text: t('confirm'),
                  onPress: () =>
                    //@ts-ignore
                    navigation.navigate('User', {
                      screen: 'Order',
                      params: {
                        screen: 'OrderResult',
                        params: {
                          orderId:
                            from === 'AddOrderGoodsScreen'
                              ? data.order_id
                              : -100,
                          status: 'APP_PAY_FAIL',
                        },
                      },
                    }),
                },
              ],
              { cancelable: false }
            );
          }
          //Cart empty or have some invalid products
          else if (result && result.return_code === 300) {
            alert(
              result.data,
              '',
              [
                {
                  text: t('confirm'),
                  onPress: () => {
                    if (from === 'AddOrderGoodsScreen') {
                      //@ts-ignore
                      navigation.navigate('User', {
                        screen: 'Order',
                        params: {
                          screen: 'OrderResult',
                          params: {
                            orderId: data.order_id,
                            status: 'APP_PAY_FAIL',
                          },
                        },
                      });
                    } else {
                      //@ts-ignore
                      navigation.navigate('Cart');
                    }
                  },
                },
              ],
              { cancelable: false }
            );
          } else {
            console.log('Wrong', result);
            from === 'AddOrderGoodsScreen'
              ? alert(
                  result?.data,
                  '',
                  [
                    {
                      text: t('confirm'),
                      onPress: () =>
                        //@ts-ignore
                        navigation.navigate('User', {
                          screen: 'Order',
                          params: {
                            screen: 'OrderResult',
                            params: {
                              orderId: data.order_id,
                              status: 'APP_PAY_FAIL',
                            },
                          },
                        }),
                    },
                  ],
                  { cancelable: false }
                )
              : alert(result?.data);
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.hide(loading);
        });
    } else {
      isValidSubmit.msg !== undefined && alert(isValidSubmit.msg);
    }
  };

  return (
    <FullWidthView
      style={{
        backgroundColor: backGroundLight,
        paddingTop: 10,
        paddingHorizontal: 10,
      }}
    >
      {creditFormInfo && creditFormInfo.remark && (
        <StyledText align="center">{creditFormInfo.remark}</StyledText>
      )}
      <FlatList
        style={{ flex: 1, marginTop: 10 }}
        ref={flatListRef}
        data={(creditFormInfo && creditFormInfo.form) || []}
        keyExtractor={(item) => item.field_id}
        renderItem={({ item }) => renderField(item)}
        contentContainerStyle={{ paddingBottom: headerHeight }}
      />
      <View
        style={{
          position: 'absolute',
          alignSelf: 'center',
          bottom: 10,
        }}
      >
        <StyledButton
          label={t('idVerification.upload')}
          onPress={handleSubmitButtonPress}
          accent
        />
      </View>
      <ImagePickerModal
        modalVisible={imageModalVisible}
        setModalVisible={setImageModalVisible}
        setImages={setFormValue}
        customSetImages={(img: ImageInfo) => updateImages(img)}
      />
    </FullWidthView>
  );
};

const styles = StyleSheet.create({
  root: {
    padding: 10,
  },
  idPhotoPlaceHolder: {
    paddingVertical: 5,
    alignItems: 'center',
  },
  formInput: {
    marginTop: 6,
    paddingLeft: 4,
    borderWidth: 1,
    borderColor: backGroundLight,
  },
});

const submitOrder = async (data: any) => {
  const result = await postWithAuthToken('order', data);
  return result;
};

export default UploadFileScreen;
