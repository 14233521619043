import React, { useEffect, useState, useRef } from 'react';
import FullWidthView from '../../components/common/FullWidthView';
import {
  useRoute,
  useNavigation,
  RouteProp,
  NavigationProp,
} from '@react-navigation/native';
import {
  CartParamList,
  CreditCardInList,
  RootState,
  OrderFormInfo,
  OrderFormInfoLight,
  ShippingTime,
} from '../../types';
import { postWithAuthToken } from '../../utils/service';
import Loading from '../../components/common/Loading';
import {
  View,
  StyleSheet,
  ScrollView,
  Platform,
  KeyboardAvoidingView,
  Dimensions,
  TouchableWithoutFeedback,
  Keyboard,
} from 'react-native';
import AddressInfo from '../../components/Cart/orderPreviewSections/AddressInfo';
import { backGroundLight } from '../../constants/Colors';
import EmptyDisplayBlock from '../../components/common/EmptyDisplayBlock';
import { useTranslation } from 'react-i18next';
import GoodsInfo from '../../components/Cart/orderPreviewSections/GoodsInfo';
import TotalInfo from '../../components/Cart/orderPreviewSections/TotalInfo';
import MethodSwitch from '../../components/Cart/orderPreviewSections/MethodSwitch';
import PaymentInfo from '../../components/Cart/orderPreviewSections/PaymentInfo';
import RemarkInfo from '../../components/Cart/orderPreviewSections/RemarkInfo';
import BalanceInfo from '../../components/Cart/orderPreviewSections/BalanceInfo';
import PointsInfo from '../../components/Cart/orderPreviewSections/PointsInfo';
import OrderPreviewForm from '../../components/Cart/orderPreviewSections/OrderPreviewForm';
import ShippingTimeInfo from '../../components/Cart/orderPreviewSections/ShippingTimeInfo';
import PickupAddressInfo from '../../components/Cart/orderPreviewSections/PickupAddressInfo';
import CombineOrderInfo from '../../components/Cart/orderPreviewSections/CombineOrderInfo';
import CreditCardSelector from '../../components/Cart/orderPreviewSections/CreditCardSelector';
import NotifyMethod from '../../components/Cart/orderPreviewSections/NotifyMethod';
import ApartmentDelivery from '../../components/Cart/orderPreviewSections/ApartmentDelivery';
import SubmitOrderWidget from '../../components/Cart/orderPreviewSections/SubmitOrderWidget';
import CouponSelector from '../../components/Cart/orderPreviewSections/CouponSelector';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import i18n from 'i18next';
import alert from '../../utils/alert';
import { useHeaderHeight } from '@react-navigation/stack';
import { useSelector } from 'react-redux';
import valid from 'card-validator';
import { useUpdateEffect } from 'ahooks';
import SubmitOrderModal from '../../components/Cart/orderPreviewSections/SubmitOrderModal';

type PreviewOrderParamsType = {
  ru_id: number;
  tid: number;
  order_type: number;
  is_create?: 1 | 0;
  pay_id: number;
  remark?: string;
  address_id?: number;
  use_surplus?: 0 | 1;
  use_integral?: 0 | 1;
  shipping_time?: number;
  start_order_time?: number;
  best_time?: string;
  shipping_type?: 0 | 1;
  pickup_id?: number;
  combine_order_id?: number;
  card_id?: number;
  cvd?: string;
  tips?: number;
  coupon_id?: number;
  form_value?: string;
  notify_method?: string;
};

const OrderPreviewScreen = () => {
  const navigation =
    useNavigation<NavigationProp<CartParamList, 'OrderPreview'>>();
  const route = useRoute<RouteProp<CartParamList, 'OrderPreview'>>();
  const { t } = useTranslation();
  const headerHeight = useHeaderHeight();
  const { height } = Dimensions.get('window');

  const {
    ruId,
    tId,
    selectedAddress,
    newPickupAddressIndex,
    orderType = 0,
    shippingType = 1,
    bundleShippingTime,
    selectedCoupon,
  } = route.params;
  const scrollViewRef = useRef<ScrollView>(null);

  const shopStatus = useSelector<RootState, { code: number; message: string }>(
    (state) => state.goods.shopStatus
  );

  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const [orderData, setOrderData] = useState<any>({});
  const [method, setMethod] = useState<'shipping' | 'pickup'>('shipping');
  const [errorMsg, setErrorMsg] = useState('');

  const [paymentInfoOffsetY, setPaymentInfoOffsetY] = useState(0);
  const [addressInfoOffsetY, setAddressInfoOffsetY] = useState(0);
  const [notifyMethodInfoOffsetY, setNotifyMethodInfoOffsetY] = useState(0);
  const [formOffsetY, setFormOffsetY] = useState(0);

  //Shipping Time
  const [shippingTime, setShippingTime] = useState<ShippingTime>({
    name: '',
    time: 0,
    startTime: undefined,
    fast_delivery: undefined,
    fast_delivery_notice: undefined,
  });
  const [hasSelectedDate, setHasSelectedDate] = useState(false);

  //Pickup Info
  const [pickupAddressIndex, setPickupAddressIndex] = useState(0);
  const [pickupInfo, setPickupInfo] = useState({
    name: '',
    mobile: '',
    address: '',
  });
  const [notifyMethod, setNotifyMethod] = useState('');

  const [combineOrder, setCombineOrder] = useState({
    requireCombine: false,
    orderId: -1,
  });

  //User Remark under Address Info
  const [remark, setRemark] = useState('');

  //Use user balance
  const [useBalance, setUseBalance] = useState(true);

  //Use points discount
  const [usePoints, setUsePoints] = useState(true);

  //Payment
  const [selectedPayment, setSelectedPayment] = useState({
    name: '',
    id: -1,
    description: '',
    code: '',
  });

  //Credit Card
  const [creditCard, setCreditCard] = useState<CreditCardInList>();
  const [CVC, setCVC] = useState('');

  //Apartment Delivery Checkbox
  const [deliveryTipAmount, setDeliveryTipAmount] = useState(0);
  const [addDelivery, setAddDelivery] = useState<boolean>(false);

  //Form
  const [formValue, setFormValue] = useState<{
    [key: string]: OrderFormInfoLight;
  }>({});

  //Submit Order Modal
  const [submitOrderModalVisible, setSubmitOrderModalVisible] = useState(false);

  const handleSubmitButtonPress = () => {
    //Only shopStatus equal to 0 can submit order
    if (shopStatus.code === 0) {
      const isValidSubmit = submitValidation();
      if (isValidSubmit.status) {
        if (
          orderData.other?.submit_alert &&
          orderData.other.submit_alert.desc.length > 0
        ) {
          setSubmitOrderModalVisible(true);
        } else {
          handleOrderSubmit();
        }
      } else {
        if (isValidSubmit.type) {
          scrollInvalidSubmit(isValidSubmit.type);
        }
        isValidSubmit.msg !== undefined && alert(isValidSubmit.msg);
      }
    } else {
      alert(shopStatus.message);
    }
  };

  const handleOrderSubmit = () => {
    //build request body
    const data: PreviewOrderParamsType = {
      ru_id: ruId,
      tid: tId,
      order_type: 0,
      is_create: 1,
      pay_id: selectedPayment.id,
      remark: remark,
      use_surplus: useBalance ? 1 : 0,
      use_integral: usePoints ? 1 : 0,
      shipping_type: method === 'pickup' ? 0 : 1,
    };

    //Pickup order
    if (orderData.shipping.shipping_type === 0) {
      data.pickup_id = orderData.shipping.pick_up[pickupAddressIndex].id;
      data.address_id = selectedAddress
        ? selectedAddress.address_id
        : orderData.address.address_id;
    } //Shipping order
    else {
      data.address_id = selectedAddress
        ? selectedAddress.address_id
        : orderData.address.address_id;
      data.shipping_time = shippingTime.time;
      data.best_time = shippingTime.name;
      if (shippingTime.startTime !== undefined) {
        data.start_order_time = shippingTime.startTime;
      }
    }

    //Combine order
    if (combineOrder.orderId !== -1 && combineOrder.requireCombine) {
      data.combine_order_id = combineOrder.orderId;
    }

    //For presale item
    if (orderType) {
      data.order_type = orderType;
    }

    //For bundle order
    if (bundleShippingTime) {
      data.shipping_time = bundleShippingTime;
    }

    //add credit card
    if (
      selectedPayment.code === 'moneriscredit' &&
      orderData.payment.card_info &&
      orderData.payment.card_list &&
      !Array.isArray(orderData.payment.card_info)
    ) {
      data.card_id = orderData.payment.card_info.id;
      data.cvd = CVC;
    }

    if (
      addDelivery &&
      orderData?.shipping?.delivery?.tips_info &&
      !Array.isArray(orderData.shipping.delivery.tips_info)
    ) {
      data.tips = orderData.shipping.delivery.tips_info.tips;
    } else {
      data.tips = 0;
    }

    if (selectedCoupon !== undefined) {
      data.coupon_id = selectedCoupon;
    }

    //add notify method
    if (notifyMethod !== '') {
      data.notify_method = notifyMethod;
    }

    if (formValue && JSON.stringify(formValue) !== '{}') {
      data.form_value = JSON.stringify(Object.values(formValue));
    }

    //check if credit card need to be verified
    if (
      orderData.credit_form &&
      typeof orderData.credit_form === 'object' &&
      orderData.credit_form.form &&
      orderData.credit_form.form.length > 0
    ) {
      navigation.navigate('UploadFileScreen', {
        creditFormInfo: orderData.credit_form,
        data: data,
        from: 'OrderPreviewScreen',
      });
      return;
    }

    const loading = Toast.showLoading('');
    submitOrder(data)
      .then((result) => {
        Toast.hide(loading);
        if (result && result.goodStatus) {
          //@ts-ignore
          navigation.navigate('User', {
            screen: 'Order',
            params: {
              screen: 'OrderSubmit',
              params: { data: result.data },
            },
          });
        } //Jump to payment
        else if (result && result.return_code === 303) {
          //@ts-ignore
          navigation.navigate('User', {
            screen: 'Order',
            params: {
              screen: 'OrderSubmit',
              params: { data: result.data },
            },
          });
        }
        //Credit card pay failed, go to await pay list
        else if (result && result.return_code === 304) {
          alert(
            result.data,
            '',
            [
              {
                text: t('confirm'),
                onPress: () =>
                  //@ts-ignore
                  navigation.navigate('User', {
                    screen: 'Order',
                    params: {
                      screen: 'OrderResult',
                      params: {
                        orderId: -100,
                        status: 'APP_PAY_FAIL',
                      },
                    },
                  }),
              },
            ],
            { cancelable: false }
          );
        }
        //Cart empty or have some invalid products
        else if (result && result.return_code === 300) {
          alert(
            result.data,
            '',
            [
              {
                text: t('confirm'),
                onPress: () => navigation.navigate('Cart'),
              },
            ],
            { cancelable: false }
          );
        } else {
          console.log('Wrong', result);
          alert(result?.data);
        }
      })
      .catch((err) => {
        console.log(err);
        Toast.hide(loading);
      });
  };

  const scrollInvalidSubmit = (type: string) => {
    switch (type) {
      case 'payment':
        scrollViewRef.current !== null &&
          scrollViewRef.current.scrollTo({
            x: 0,
            y: paymentInfoOffsetY,
            animated: true,
          });

        break;
      case 'shippingTime':
        scrollViewRef.current !== null &&
          scrollViewRef.current.scrollTo({
            x: 0,
            y: addressInfoOffsetY,
            animated: true,
          });
        break;
      case 'notify':
        scrollViewRef.current !== null &&
          scrollViewRef.current.scrollTo({
            x: 0,
            y: notifyMethodInfoOffsetY,
            animated: true,
          });
        break;
      case 'form':
        scrollViewRef.current !== null &&
          scrollViewRef.current.scrollTo({
            x: 0,
            y: formOffsetY,
            animated: true,
          });
      default:
    }
  };

  const submitValidation = () => {
    //Check shipping time selected or not
    if (
      shippingTime.time === 0 &&
      method === 'shipping' &&
      orderData.extension_code !== 'presale' &&
      orderData.shipping.delivery.shipping_time_list.length > 0
    ) {
      return {
        status: false,
        msg: t('order.selectDeliveryTime'),
        type: 'shippingTime',
      };
    }

    //Check if Notify Method is selected
    if (
      method == 'shipping' &&
      notifyMethod == '' &&
      !combineOrder.requireCombine &&
      orderData.shipping.delivery?.notify_method?.options?.length > 0
    ) {
      return {
        status: false,
        msg: t('order.chooseMethod'),
        type: 'notify',
      };
    }

    if (orderData.form) {
      let missingFields = false;
      orderData.form.map((formGroup: OrderFormInfo) => {
        formGroup.child?.map((field: OrderFormInfo) => {
          if (field.is_required === 1) {
            if (
              formValue[formGroup.field_id].child[field.field_code].value === ''
            ) {
              missingFields = true;
            }
          }
        });
      });

      if (missingFields) {
        return {
          status: false,
          msg: t('order.fillInRequired'),
          type: 'form',
        };
      }
    }

    //Check payment selected or not
    if (selectedPayment.id === -1) {
      return {
        status: false,
        msg: t('order.selectPaymentMethod'),
        type: 'payment',
      };
    }

    //Check if credit card selected if payment method is Moneris
    if (selectedPayment.code === 'moneriscredit') {
      if (
        !orderData.payment.card_info ||
        !orderData.payment.card_list ||
        Array.isArray(orderData.payment.card_info) ||
        orderData.payment.card_list.length === 0
      ) {
        return {
          status: false,
          msg: t('order.selectCreditCard'),
          type: 'payment',
        };
      } else {
        //if credit card is selected check if CVC is entered
        if (CVC === undefined || CVC === '') {
          return {
            status: false,
            msg: t('validation.CVCHint'),
            type: 'payment',
          };
        } else {
          //if CVC is entered check if it is a valid CVC
          if (!valid.cvv(CVC, 4).isPotentiallyValid || CVC.length < 3) {
            return {
              status: false,
              msg: t('validation.invalidCVC'),
              type: 'payment',
            };
          }
        }
      }
    }
    return { status: true };
  };

  const handleScrollToEnd = () => {
    scrollViewRef.current !== null &&
      scrollViewRef.current.scrollToEnd({
        animated: true,
      });
  };

  const handleOpenList = () => {
    navigation.navigate('PickupLocationList', {
      list: orderData.shipping.pick_up,
    });
  };

  //reset delivery date/time if address/method/combineOrder changes
  useEffect(() => {
    setHasSelectedDate(false);
    setShippingTime({ name: '', time: 0, startTime: undefined });
  }, [selectedAddress, method, combineOrder]);

  //reset CVC if payment method/credit card changes
  useUpdateEffect(() => {
    setCVC('');
  }, [selectedPayment, creditCard]);

  //reset payment method/credit card on change address
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setErrorMsg('');
      const data: PreviewOrderParamsType = {
        ru_id: ruId,
        tid: tId,
        order_type: orderType,
        pay_id: selectedPayment.id,
        use_surplus: useBalance ? 1 : 0,
        use_integral: usePoints ? 1 : 0,
        shipping_type: method === 'pickup' ? 0 : 1,
      };

      //User selected address
      if (selectedAddress) {
        data.address_id = selectedAddress.address_id;
      }

      //For presale item
      if (orderType) {
        data.order_type = orderType;
      }

      if (combineOrder.orderId !== -1 && combineOrder.requireCombine) {
        data.combine_order_id = combineOrder.orderId;
      }

      if (shippingTime) {
        data.shipping_time = shippingTime.time;
        data.best_time = shippingTime.name;
      }

      //For bundle order
      if (bundleShippingTime) {
        data.shipping_time = bundleShippingTime;
      }

      if (shippingType === 0) {
        data.shipping_type = shippingType;
        setMethod('pickup');
      }

      if (creditCard) {
        data.card_id = creditCard.id;
      }

      if (addDelivery) {
        data.tips = deliveryTipAmount;
      } else {
        data.tips = 0;
      }

      if (selectedCoupon !== undefined) {
        data.coupon_id = selectedCoupon;
      }
      updatePreviewOrder(data)
        .then((result) => {
          if (result && result.goodStatus) {
            setOrderData(result.data);
            if (
              result.data.payment.card_info &&
              result.data.payment.card_list &&
              !Array.isArray(result.data.payment.card_info)
            ) {
              setCreditCard(result.data.payment.card_info);
            }
            if (
              result.data.shipping?.delivery?.tips_info &&
              !Array.isArray(result.data.shipping.delivery.tips_info)
            ) {
              setDeliveryTipAmount(
                result.data.shipping.delivery.tips_info.tips
              );
            }
          } else {
            let hasErrorDetail = false;
            if (result && result.return_code) {
              hasErrorDetail = orderPreviewResponseValidation(
                result,
                navigation,
                ruId,
                setMethod
              );
            }

            if (!hasErrorDetail) {
              if (result) setErrorMsg(result?.data);
              setOrderData({});
            }
          }
          setLoadingComplete(true);
        })
        .catch((err) => console.log(err));
    });

    return unsubscribe;
  }, [
    selectedAddress,
    method,
    selectedPayment,
    selectedCoupon,
    shippingTime,
    addDelivery,
  ]);

  const updateOrder = () => {
    if (Object.keys(orderData).length > 0) {
      //Updated data for getting new preview order information
      const data: PreviewOrderParamsType = {
        ru_id: ruId,
        tid: tId,
        order_type: orderType,
        pay_id: selectedPayment.id,
        address_id: selectedAddress
          ? selectedAddress.address_id
          : orderData.address.address_id,
        use_surplus: useBalance ? 1 : 0,
        use_integral: usePoints ? 1 : 0,
        shipping_type: method === 'pickup' ? 0 : 1,
      };

      if (combineOrder.orderId !== -1 && combineOrder.requireCombine) {
        data.combine_order_id = combineOrder.orderId;
      }

      //For presale item
      if (orderType) {
        data.order_type = orderType;
      }

      if (shippingTime) {
        data.shipping_time = shippingTime.time;
        data.best_time = shippingTime.name;
      }

      //For bundle order
      if (bundleShippingTime) {
        data.shipping_time = bundleShippingTime;
      }

      if (shippingType === 0) {
        data.shipping_type = shippingType;
        setMethod('pickup');
      }

      if (creditCard) {
        data.card_id = creditCard?.id;
      }

      if (addDelivery) {
        data.tips = deliveryTipAmount;
      } else {
        data.tips = 0;
      }

      if (selectedCoupon !== undefined) {
        data.coupon_id = selectedCoupon;
      }

      if (formValue && JSON.stringify(formValue) !== '{}') {
        data.form_value = JSON.stringify(Object.values(formValue));
      }

      const loading = Toast.showLoading('');
      updatePreviewOrder(data)
        .then((result) => {
          if (result && result.goodStatus) {
            setOrderData(result.data);
            if (result.data.combine_order && result.data.combine_order.info) {
              setShippingTime({
                name: result.data.combine_order.info.best_time,
                time: result.data.combine_order.info.best_shipping_time,
                startTime: undefined,
              });
            }
            if (
              result.data.shipping?.delivery?.tips_info &&
              !Array.isArray(result.data.shipping.delivery.tips_info)
            ) {
              setDeliveryTipAmount(
                result.data.shipping.delivery.tips_info.tips
              );
            }
          } else {
            let hasErrorDetail = false;
            if (result && result.return_code) {
              console.log(result.return_code);

              hasErrorDetail = orderPreviewResponseValidation(
                result,
                navigation,
                ruId,
                setMethod
              );
            }

            if (!hasErrorDetail) {
              if (result) setErrorMsg(result?.data);
              setOrderData({});
            }
          }

          Toast.hide(loading);
        })
        .catch((err) => {
          Toast.hide(loading);
          console.log(err);
        });
    }
  };

  //update order form on field change and check if combine order
  useEffect(() => {
    Toast.hide();
    updateOrder();
  }, [
    useBalance,
    usePoints,
    selectedPayment,
    method,
    combineOrder,
    creditCard,
    addDelivery,
  ]);

  function updateOrderTime(): any {
    Toast.hide();
    updateOrder();
  }

  //For pickup information updated
  useEffect(() => {
    if (
      Object.keys(orderData).length > 0 &&
      orderData.shipping.shipping_type === 0
    ) {
      if (newPickupAddressIndex) {
        setPickupInfo({
          name: orderData.shipping.pick_up[newPickupAddressIndex].name,
          mobile: orderData.shipping.pick_up[newPickupAddressIndex].mobile,
          address: orderData.shipping.pick_up[newPickupAddressIndex].address,
        });
        setPickupAddressIndex(newPickupAddressIndex);
      } else {
        setPickupInfo({
          name: orderData.shipping.pick_up[0].name,
          mobile: orderData.shipping.pick_up[0].mobile,
          address: orderData.shipping.pick_up[0].address,
        });
        setPickupAddressIndex(0);
      }
    }
  }, [orderData, newPickupAddressIndex]);

  const styles = StyleSheet.create({
    orderDetailContainer: {
      padding: 10,
      paddingBottom: Platform.OS === 'web' ? 30 : 50,
    },
    loadingContainer: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  return (
    <FullWidthView style={{ backgroundColor: backGroundLight }}>
      {isLoadingComplete ? (
        Object.keys(orderData).length > 0 ? (
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            keyboardVerticalOffset={headerHeight}
            style={{ flex: 1 }}
          >
            <TouchableWithoutFeedback
              onPress={
                Platform.OS === 'web' ? undefined : () => Keyboard.dismiss()
              }
              accessible={false}
            >
              <ScrollView
                contentContainerStyle={styles.orderDetailContainer}
                ref={scrollViewRef}
              >
                {/* Show available method */}
                {orderData.enabled_shipping && orderData.enabled_pickup && (
                  <MethodSwitch activeType={method} changeType={setMethod} />
                )}
                <View
                  onLayout={(event) => {
                    const { y } = event.nativeEvent.layout;
                    setAddressInfoOffsetY(y);
                  }}
                >
                  {/* Pickup Option */}
                  {orderData.shipping.shipping_type === 0 && (
                    <PickupAddressInfo
                      data={pickupInfo}
                      editable={orderData.shipping.pick_up.length > 1}
                      openList={handleOpenList}
                      contact={{
                        name: orderData.address.consignee,
                        phone: orderData.address.mobile,
                      }}
                      changeContact={true}
                    />
                  )}

                  {/* Combine Order Option */}
                  {orderData?.combine_order &&
                    orderData.combine_order?.list?.length > 0 &&
                    method === 'shipping' && (
                      <CombineOrderInfo
                        list={orderData.combine_order.list}
                        setCombineOrder={setCombineOrder}
                        combineOrder={combineOrder}
                        orderData={{ ruId, orderType, tId }}
                      />
                    )}

                  {/* Shipping Option */}
                  {orderData.shipping.shipping_type === 1 && (
                    <AddressInfo
                      address={orderData.address}
                      shippingTimeComponent={
                        orderData.extension_code !== 'presale' &&
                        orderData.shipping.delivery.shipping_time_list.length >
                          0 ? (
                          <ShippingTimeInfo
                            info={orderData.shipping}
                            shippingTime={shippingTime}
                            setShippingTime={setShippingTime}
                            disableTimeChange={combineOrder.orderId !== -1}
                            hasSelectedDate={hasSelectedDate}
                            setHasSelectedDate={setHasSelectedDate}
                            updateOrderTime={updateOrderTime}
                          />
                        ) : (
                          <></>
                        )
                      }
                      disableAddressChange={combineOrder.orderId !== -1}
                    />
                  )}
                </View>

                <GoodsInfo
                  data={orderData.cart_goods_list}
                  orderType={orderType}
                  number={orderData.goods_amount.goods_number}
                  pageType="preview"
                />
                <RemarkInfo remark={remark} setRemark={setRemark} />

                {/* Notify Method */}
                {method == 'shipping' &&
                  orderData.shipping.delivery?.notify_method?.options?.length >
                    0 &&
                  !combineOrder.requireCombine && (
                    <View
                      onLayout={(event) => {
                        const { y } = event.nativeEvent.layout;
                        setNotifyMethodInfoOffsetY(y);
                      }}
                    >
                      <NotifyMethod
                        notifyText={
                          orderData.shipping.delivery?.notify_method?.message ??
                          ''
                        }
                        notifyMethod={notifyMethod}
                        setNotifyMethod={setNotifyMethod}
                        notifyMethodList={
                          orderData.shipping.delivery?.notify_method?.options ??
                          []
                        }
                      />
                    </View>
                  )}

                {/*Apartment Delivery Section */}
                {orderData.shipping.shipping_type === 1 &&
                orderData?.address?.address_name === 'apartment' &&
                !Array.isArray(orderData.shipping.delivery.tips_info) ? (
                  <ApartmentDelivery
                    deliveryText={
                      orderData.shipping.delivery.tips_info
                        .deliver_to_door_alert
                    }
                    addDelivery={addDelivery}
                    setAddDelivery={setAddDelivery}
                    deliveryFee={orderData.shipping.delivery.tips_info.tips}
                  />
                ) : (
                  <></>
                )}

                {orderData.user && (
                  <BalanceInfo
                    balance={orderData.user.surplus}
                    useBalance={useBalance}
                    triggerBalanceUsage={() => {
                      setUseBalance((prev) => !prev);
                    }}
                  />
                )}

                {/* Points */}
                {orderData.integral &&
                orderData.integral.integral &&
                JSON.stringify(orderData.integral?.integral_info) !== '{}' ? (
                  <PointsInfo
                    points={orderData.integral?.integral}
                    pointDescription={
                      orderData.integral?.integral_info?.integral_desc
                    }
                    valid={
                      orderData.integral?.integral_info?.valid_enable_integral
                    }
                    getPayPoints={orderData.integral?.get_pay_point}
                    getPayPointsDescription={
                      orderData.integral?.get_pay_point_desc
                    }
                    usePoints={usePoints}
                    triggerPointUsage={() => {
                      setUsePoints((prev) => !prev);
                    }}
                  />
                ) : (
                  <></>
                )}

                {/* Form */}
                {orderData.form && orderData.form.length > 0 && (
                  <View
                    onLayout={(event) => {
                      const { y } = event.nativeEvent.layout;
                      setFormOffsetY(y);
                    }}
                  >
                    <OrderPreviewForm
                      formFields={orderData.form}
                      formValue={formValue}
                      setFormValue={setFormValue}
                    />
                  </View>
                )}

                <CouponSelector coupon={orderData.coupon} ruId={ruId} />

                <View
                  onLayout={(event) => {
                    const { y } = event.nativeEvent.layout;
                    setPaymentInfoOffsetY(y);
                  }}
                >
                  <PaymentInfo
                    selectedPayment={selectedPayment}
                    setSelectedPayment={setSelectedPayment}
                    paymentMethods={orderData.payment.list}
                  >
                    <CreditCardSelector
                      setCreditCard={setCreditCard}
                      cardList={orderData.payment.card_list || null}
                      cardInfo={orderData.payment.card_info || null}
                      ruID={ruId}
                      CVC={CVC}
                      setCVC={setCVC}
                    />
                  </PaymentInfo>
                </View>
                <TotalInfo total={orderData.total} />
                {orderData.other?.submit_alert &&
                  orderData.other.submit_alert.desc.length > 0 && (
                    <SubmitOrderModal
                      modalVisible={submitOrderModalVisible}
                      setModalVisible={setSubmitOrderModalVisible}
                      submitAlert={orderData.other.submit_alert}
                      handleOrderSubmit={handleOrderSubmit}
                    />
                  )}
                {Platform.OS === 'web' && <View style={{ height: 40 }}></View>}
              </ScrollView>
            </TouchableWithoutFeedback>
            <SubmitOrderWidget
              totalPrice={orderData.total.formated_order_amount}
              handleSubmitButtonPress={handleSubmitButtonPress}
              handleScrollToEnd={handleScrollToEnd}
              freeShippingAmount={
                orderData.shipping.shipping_type === 1
                  ? orderData.shipping.delivery.shipping_diff
                  : 0
              }
              fastDeliveryNotice={shippingTime?.fast_delivery_notice ?? ''}
            />
          </KeyboardAvoidingView>
        ) : (
          <EmptyDisplayBlock
            description={t('order.orderPreviewFailed') + '-' + errorMsg}
            buttonLabel={t('goBack')}
            onPress={() => {
              Platform.OS === 'web'
                ? navigation.canGoBack()
                  ? navigation.goBack()
                  : /** @ts-ignore */
                    window.history.back()
                : navigation.goBack();
            }}
          />
        )
      ) : (
        <View style={styles.loadingContainer}>
          <Loading />
        </View>
      )}
    </FullWidthView>
  );
};

export default OrderPreviewScreen;

const updatePreviewOrder = async (data: any) => {
  const result = await postWithAuthToken('order', data);
  return result;
};

const submitOrder = async (data: any) => {
  const result = await postWithAuthToken('order', data);
  return result;
};

const orderPreviewResponseValidation = (
  result: any,
  navigation: any,
  ruId: number,
  setMethod: React.Dispatch<React.SetStateAction<'shipping' | 'pickup'>>
) => {
  if (result.return_code === 300) {
    alert(result.data);
    return true;
  }

  //Required add address first
  if (result.return_code === 301) {
    setTimeout(() => {
      alert(
        result.data,
        '',
        [
          {
            text: i18n.t('cancel'),
            onPress: () => navigation.goBack(),
            style: 'cancel',
          },
          {
            text: i18n.t('address.selectAddress'),
            onPress: () =>
              navigation.navigate('Address', {
                screen: 'AddressList',
                params: {
                  type: 'view',
                },
              }),
          },
        ],
        { cancelable: false }
      );
    }, 300);
    return true;
  }

  // Require add credit card
  if (result.return_code === 305) {
    setTimeout(() => {
      alert(
        i18n.t('creditCard.emptyCreditCardList'),
        i18n.t('creditCard.noCardPleaseAddCard'),
        [
          {
            text: i18n.t('cancel'),
            onPress: () => {},
            style: 'cancel',
          },
          {
            text: i18n.t('creditCard.addCreditCard'),
            onPress: () =>
              navigation.navigate('CreditCard', {
                screen: 'CreditCardListScreen',
              }),
          },
        ],
        { cancelable: false }
      );
    }, 300);

    return true;
  }

  // Coupon cannot be used for this order
  if (result.return_code === 307) {
    navigation.setParams({
      selectedCoupon: -1,
    });
    navigation.navigate('CouponPromo', { ruId: ruId });
    Toast.show(result?.data, { position: 0 });

    return true;
  }

  //Order support delivery only, no pickup allow
  if (result.return_code === 308) {
    setTimeout(() => {
      alert(
        i18n.t('address.sorry'),
        i18n.t('address.deliveryOnly'),
        [
          {
            text: i18n.t('cancel'),
            onPress: () => navigation.goBack(),
            style: 'cancel',
          },
          {
            text: i18n.t('address.chooseDelivery'),
            onPress: () => {
              setMethod('shipping');
            },
          },
        ],
        { cancelable: false }
      );
    }, 300);

    return true;
  }

  //User address is not support delivery, but user can change address or choose pickup
  if (result.return_code === 309) {
    setTimeout(() => {
      alert(
        i18n.t('address.sorry'),
        i18n.t('address.failAddressAndPickupOnly'),
        [
          {
            text: i18n.t('cancel'),
            onPress: () => navigation.goBack(),
            style: 'cancel',
          },
          {
            text: i18n.t('address.changeAddress'),
            onPress: () => {
              navigation.navigate('Address', {
                screen: 'AddressList',
                params: {
                  type: 'view',
                },
              });
            },
          },
          {
            text: i18n.t('address.pickup'),
            onPress: () => {
              setMethod('pickup');
              navigation.navigate('Address', {
                screen: 'AddressList',
                params: {
                  type: 'view',
                },
              });
              //For some reasons, web doesn't support instantly go back, so set timeout is here to make it work :)
              if (Platform.OS !== 'web') {
                navigation.goBack();
              } else {
                setTimeout(() => {
                  navigation.goBack();
                }, 1);
              }
            },
          },
        ],
        { cancelable: false }
      );
    }, 300);

    return true;
  }

  //Special Items, need to verify id first. 特殊订单-烟酒类产品需要身份验证
  if (result.return_code === 310) {
    setTimeout(() => {
      alert(
        i18n.t('idVerification.title'),
        result.data,
        [
          {
            text: i18n.t('cancel'),
            onPress: () => navigation.goBack(),
            style: 'cancel',
          },
          {
            text: i18n.t('idVerification.notVerified'),
            onPress: () => {
              navigation.popToTop();
              navigation.navigate('User');
              setTimeout(() => {
                navigation.navigate('User', { screen: 'IdVerification' });
              }, 10);
            },
          },
        ],
        { cancelable: false }
      );
    }, 300);

    return true;
  }

  return false;
};
