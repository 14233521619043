import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  GestureResponderEvent,
  Platform,
} from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { tintColorLight } from '../../../constants/Colors';
import StyledText from '../../common/StyledText';
import { useTranslation } from 'react-i18next';
import WhiteCard from '../../common/WhiteCard';
import NavigateButton from '../../common/NavigateButton';
import alert from '../../../utils/alert';
import getAppStyle from '../../../utils/getAppStyle';

type OrderMenuItemType = {
  label: string;
  iconName: string;
  route?: string;
  loginRequired?: boolean;
  options?: any;
};

type ProfileOtherMenuProps = {
  showInviteMenu: boolean;
  showGroupSaleMenu: boolean;
};

const ProfileOtherMenu = ({
  showInviteMenu,
  showGroupSaleMenu,
}: ProfileOtherMenuProps) => {
  const { t } = useTranslation();

  const orderMenuOptions: Array<OrderMenuItemType> = [
    {
      label: t('userProfileScreen.otherMenu.address'),
      iconName: 'enviromento',
      route: 'Address',
      options: { screen: 'AddressList', params: { type: 'edit' } },
      loginRequired: true,
    },
    {
      label: t('userProfileScreen.otherMenu.creditCard'),
      iconName: 'creditcard',
      loginRequired: true,
      route: 'CreditCard',
    },
    {
      label: t('userProfileScreen.otherMenu.groupSale'),
      iconName: 'staro',
      route: 'GroupSale',
    },
    {
      label: t('userProfileScreen.otherMenu.articles'),
      iconName: 'notification',
      route: 'Articles',
      options: { screen: 'ArticleListScreen' },
    },
    {
      label: t('userProfileScreen.otherMenu.myFavorites'),
      iconName: 'hearto',
      route: 'Favorites',
      loginRequired: true,
    },
    {
      label: t('userProfileScreen.otherMenu.customerService'),
      iconName: 'customerservice',
      route: 'Modal',
      options: { screen: 'CustomerServiceModal' },
      loginRequired: true,
    },
    {
      label: t('userProfileScreen.otherMenu.inviteFriends'),
      iconName: 'gift',
      route: 'InviteFriends',
      loginRequired: true,
    },
    {
      label: t('userProfileScreen.otherMenu.helpCenter'),
      iconName: 'questioncircleo',
      route: 'HelpCenter',
    },
    {
      label: t('userProfileScreen.otherMenu.vouchers'),
      iconName: 'tago',
      route: 'Vouchers',
      loginRequired: true,
    },
  ];

  const renderOrderItems = orderMenuOptions.map((item, index) => {
    switch (item.route) {
      case 'InviteFriends':
        return showInviteMenu &&
          !getAppStyle(process.env.EXPO_APP_TYPE)?.toHideFeature ? (
          <OtherMenuItem item={item} key={index} />
        ) : (
          <View key={index}></View>
        );
      case 'GroupSale':
        return showGroupSaleMenu && Platform.OS !== 'web' ? (
          <OtherMenuItem item={item} key={index} />
        ) : (
          <View key={index}></View>
        );
      case 'Vouchers':
        return (
          !getAppStyle(process.env.EXPO_APP_TYPE)?.toHideFeature && (
            <OtherMenuItem item={item} key={index} />
          )
        );
      default:
        return <OtherMenuItem item={item} key={index} />;
    }
  });

  return (
    <WhiteCard>
      <View style={styles.menuContainer}>{renderOrderItems}</View>
    </WhiteCard>
  );
};

type OrderMenuItemProps = {
  item: OrderMenuItemType;
};

const OtherMenuItem = ({ item }: OrderMenuItemProps) => {
  return (
    <View style={styles.menuItemContainer}>
      {item.route ? (
        <NavigateButton
          route={item.route}
          options={item.options ? item.options : {}}
          loginRequired={item.loginRequired ? true : false}
        >
          <View style={styles.menuItem}>
            <AntDesign name={item.iconName} size={24} color={tintColorLight} />
            <StyledText align="center">{item.label}</StyledText>
          </View>
        </NavigateButton>
      ) : (
        <TouchableOpacity
          onPress={(event: GestureResponderEvent) => {
            event.preventDefault();
            alert('Coming soon');
          }}
        >
          <View style={styles.menuItem}>
            <AntDesign name={item.iconName} size={24} color={tintColorLight} />
            <StyledText align="center">{item.label}</StyledText>
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default ProfileOtherMenu;

const styles = StyleSheet.create({
  menuContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  menuItemContainer: {
    marginVertical: 15,
    width: '33.33%',
  },
  menuItem: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
