import React, { useCallback, useEffect, useState } from 'react';
import { useCounter } from 'ahooks';
import StyledText from '../common/StyledText';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, ProductInformation, GoodsNumberRule } from '../../types';
import {
  View,
  StyleSheet,
  Dimensions,
  TouchableWithoutFeedback,
  Vibration,
} from 'react-native';
import ResponsiveImg from '../common/ResponsiveImg';
import ClearButton from '../common/iconButtons/ClearButton';
import {
  accentColor,
  backGroundLight,
  normalTextColor,
  subTextColor,
} from '../../constants/Colors';
import AmountCounter from './widgets/AmountCounter';
import StyledButton from '../common/StyledButton';
import { postWithAuthToken, getData } from '../../utils/service';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import { useTranslation } from 'react-i18next';
import BottomAutoHeightModal from '../common/BottomAutoHeightModal';
import { useNavigation } from '@react-navigation/native';

const { width } = Dimensions.get('window');

const ProductOptionSelector = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigation = useNavigation();

  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.user.isLoggedIn
  );

  const selectedProduct = useSelector<RootState, ProductInformation | null>(
    (state) => state.goods.selectedProduct
  );

  const showProductOptionWindow = useSelector<RootState, boolean>(
    (state) => state.goods.showProductOptionWindow
  );

  const isPresaleProduct = useSelector<RootState, boolean>(
    (state) => state.goods.isPresaleProduct
  );

  const [quantity, { set, reset }] = useCounter(1, {
    min: 1,
    max: 999,
  });
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [displayPrice, setDisplayPrice] = useState('');
  const [optionIds, setOptionIds] = useState<Array<number>>([]);
  const appMainColor = useSelector<RootState, string>(
    (state) => state.user.appMainColor
  );
  const [attributeImg, setAttributeImg] = useState('');
  const [maxQuantity, setMaxQuantity] = useState(10);
  const [canAddToCart, setCanAddToCart] = useState(false);
  const [addToCartButtonText, setAddToCartButtonText] = useState<string>(
    t('productInformation.checkingStock')
  );
  const [quantityRule, setQuantityRule] = useState<GoodsNumberRule | null>();

  //Each time selected product or quantity change, fetch price information from server
  const getProductPriceInfo = useCallback(() => {
    if (selectedProduct) {
      setIsFetching(true);
      const optionAttributes = optionIds.map((item, index) => {
        return selectedProduct.specification[index].values[item].id;
      });

      getProductPrice(selectedProduct.goods_id, quantity, optionAttributes)
        .then((result) => {
          if (result && result.goodStatus && result.data) {
            if (result.data.goods_number_rule) {
              if (Array.isArray(result.data.goods_number_rule)) {
                setQuantityRule(null);
              } else {
                setQuantityRule(result.data.goods_number_rule);
                if (
                  result.data.goods_number_rule.number >
                  result.data.final_goods_number
                ) {
                  set(result.data.final_goods_number);
                } else if (result.data.goods_number_rule.number > quantity) {
                  set(Number(result.data.goods_number_rule.number));
                }
              }
            }
            result.data.attr_img !== ''
              ? setAttributeImg(result.data.attr_img)
              : setAttributeImg('');
            setDisplayPrice(result.data.goods_price_formated);
            setMaxQuantity(result.data.final_goods_number);
            setCanAddToCart(true);
          } else {
            setMaxQuantity(0);
            disableAddToCart(
              result ? result.data : t('productInformation.addFailed')
            );
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setIsFetching(false);
        });
    }
  }, [selectedProduct, quantity, optionIds]);

  useEffect(() => {
    if (selectedProduct) {
      //If specification option available, init optionIds with index 0 value
      if (selectedProduct.specification.length > 0) {
        setOptionIds(selectedProduct.specification.map((_) => 0));
      }
      //If quantity rule is available, update initial quantity
      if (selectedProduct.goods_number_rule) {
        if (Array.isArray(selectedProduct.goods_number_rule)) {
          setQuantityRule(null);
        } else {
          setQuantityRule(selectedProduct.goods_number_rule);
        }
      }
    }

    return () => {
      //Close window,rest all property
      reset();
      setDisplayPrice('');
      setOptionIds([]);
      setAttributeImg('');
      setCanAddToCart(false);
      setAddToCartButtonText(t('productInformation.checkingStock'));
      setQuantityRule(undefined);
    };
  }, [selectedProduct]);

  useEffect(() => {
    if (quantityRule !== undefined) {
      getProductPriceInfo();
    }
  }, [getProductPriceInfo]);

  const closeModal = () => {
    dispatch({ type: 'TRIGGER_PRODUCT_OPTION_WINDOW' });
    dispatch({ type: 'UPDATE_SELECTED_PRODUCT', payload: null });
    dispatch({ type: 'SET_IS_PRESALE_PRODUCT', payload: false });
  };

  const renderOptions = () => {
    if (
      selectedProduct?.add_type !== 0 &&
      Array.isArray(selectedProduct?.specification)
    ) {
      const list = selectedProduct?.specification.map((item, index) => {
        return (
          <Option
            key={item.attr_id}
            name={item.name}
            values={item.values}
            optionIndex={index}
            optionIds={optionIds}
            setOptionIds={setOptionIds}
            setAmount={set}
          />
        );
      });

      return list;
    } else {
      return <></>;
    }
  };

  //Handle add item to cart
  const handleItemToCart = () => {
    if (isLoggedIn) {
      const loading = Toast.showLoading('', { mask: true });
      setIsFetching(true);
      //Not specification, send request with goods id & quantity
      if (selectedProduct) {
        const optionAttributes = optionIds.map((item, index) => {
          return selectedProduct.specification[index].values[item].id;
        });

        const data = {
          id: selectedProduct?.goods_id,
          number: quantity,
          attr_id: optionAttributes,
        };
        postWithAuthToken('cart/add', data)
          .then((result) => {
            Toast.hide(loading);
            setIsFetching(false);
            if (result && result.goodStatus && result.data) {
              dispatch({
                type: 'UPDATE_CART_ITEM_COUNTER',
                payload: result.data.total_number,
              });
              closeModal();
              Toast.showSuccess(t('productInformation.addSuccessfully'));
              Vibration.vibrate(55);
            } else {
              disableAddToCart(
                result ? result.data : t('productInformation.addFailed')
              );
            }
          })
          .catch((err) => {
            Toast.hide(loading);
            setIsFetching(false);
            Toast.show(t('productInformation.addFailed'), {
              position: Toast.position.TOP,
            });
            throw err;
          });
      }
    } else {
      dispatch({ type: 'TRIGGER_PRODUCT_OPTION_WINDOW' });
      Toast.show(t('AuthModal.noTokenTip'), {
        position: Toast.position.CENTER,
      });
      navigation.navigate('Modal', { screen: 'AuthModal' });
    }
  };

  //Handle presale item to checkout
  const handleProcessPresaleCheckout = () => {
    if (isLoggedIn) {
      const loading = Toast.showLoading('', { mask: true });
      //Not specification, send request with goods id & quantity
      if (selectedProduct) {
        const optionAttributes = optionIds.map((item, index) => {
          return selectedProduct.specification[index].values[item].id;
        });

        const data = {
          id: selectedProduct?.goods_id,
          number: quantity,
          attr_id: optionAttributes,
        };
        postWithAuthToken('cart/add', data)
          .then((result) => {
            Toast.hide(loading);
            setIsFetching(false);
            if (result && result.goodStatus && result.data) {
              // Toast.showSuccess(t('productInformation.addSuccessFully'));
              dispatch({ type: 'TRIGGER_PRODUCT_OPTION_WINDOW' });
              navigation.navigate('Cart', {
                screen: 'OrderPreview',
                params: {
                  ruId: selectedProduct?.shop?.ru_id,
                  tId: selectedProduct.tid,
                  orderType: 5,
                },
              });
            } else {
              disableAddToCart(
                result ? result.data : t('productInformation.addFailed')
              );
            }
          })
          .catch((err) => {
            Toast.hide(loading);
            setIsFetching(false);
            Toast.show(t('productInformation.addFailed'), {
              position: Toast.position.CENTER,
            });
            throw err;
          });
      }
    } else {
      dispatch({ type: 'TRIGGER_PRODUCT_OPTION_WINDOW' });
      Toast.show(t('AuthModal.noTokenTip'), {
        position: Toast.position.CENTER,
      });
      navigation.navigate('Modal', { screen: 'AuthModal' });
    }
  };

  const disableAddToCart = (text: string) => {
    setAddToCartButtonText(text);
    setCanAddToCart(false);
  };

  return (
    <BottomAutoHeightModal
      visible={showProductOptionWindow}
      hideModal={closeModal}
    >
      <View style={styles.container}>
        <View style={styles.header}>
          {selectedProduct?.goods_thumb && (
            <View>
              <ResponsiveImg
                uri={attributeImg ? attributeImg : selectedProduct?.goods_thumb}
                width={100}
              />
            </View>
          )}
          <View style={styles.infoContainer}>
            {/* remove highlighting characters if selectedProduct is from search */}
            {selectedProduct?.is_search === 1 ? (
              <StyledText size={16}>
                {selectedProduct?.goods_name.replace(/{{|}}/g, '')}
              </StyledText>
            ) : (
              <StyledText size={16}>{selectedProduct?.goods_name}</StyledText>
            )}
            <StyledText color={accentColor} size={16} bold>
              {displayPrice === ''
                ? selectedProduct?.promote_price === 0
                  ? selectedProduct?.formated_shop_price
                  : selectedProduct?.formated_promote_price
                : displayPrice}
            </StyledText>
          </View>
          <View style={styles.closeBtnContainer}>
            <ClearButton onPress={closeModal} size={26} />
          </View>
        </View>
        <View style={styles.optionsContainer}>
          {renderOptions()}
          <View style={styles.amountContainer}>
            <View>
              <StyledText size={16}>
                {t('productInformation.amount')}
              </StyledText>
              {quantityRule && (
                <StyledText size={12} color={subTextColor}>
                  {quantityRule.desc}
                </StyledText>
              )}
            </View>
            <AmountCounter
              amount={quantity}
              setAmount={set}
              maxQuantity={maxQuantity}
              minQuantity={
                quantityRule && quantityRule.number !== undefined
                  ? Number(quantityRule.number) >= maxQuantity
                    ? maxQuantity
                    : Number(quantityRule.number)
                  : undefined
              }
              sign={
                quantityRule && quantityRule.sign
                  ? quantityRule.sign
                  : undefined
              }
            />
          </View>
        </View>
        <View style={styles.footer}>
          {isPresaleProduct ? (
            <StyledButton
              disabled={isFetching || quantity <= 0 || !canAddToCart}
              accent
              label={
                canAddToCart
                  ? t('productInformation.preOrderButton')
                  : addToCartButtonText
              }
              onPress={() => handleProcessPresaleCheckout()}
            />
          ) : (
            <StyledButton
              disabled={isFetching || quantity <= 0 || !canAddToCart}
              accent
              label={
                canAddToCart
                  ? t('productInformation.addToCart')
                  : addToCartButtonText
              }
              onPress={() => handleItemToCart()}
            />
          )}
        </View>
      </View>
    </BottomAutoHeightModal>
  );
};

type OptionProps = {
  name: string;
  values: Array<any>;
  optionIndex: number;
  optionIds: Array<number>;
  setOptionIds: React.Dispatch<React.SetStateAction<number[]>>;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
};

const Option = ({
  name,
  values = [],
  optionIndex,
  setOptionIds,
  optionIds,
  setAmount,
}: OptionProps) => {
  const handleOptionChange = (index: number) => {
    const idArray = [...optionIds];
    idArray[optionIndex] = index;
    setOptionIds(idArray);
    //When options changes, reset quantity to 1
    setAmount(1);
  };

  const renderItems = values.map((item, index) => {
    return (
      <TouchableWithoutFeedback
        key={item.id}
        onPress={() => handleOptionChange(index)}
      >
        <View
          style={
            optionIds[optionIndex] === index
              ? styles.activeOptionItem
              : styles.optionItem
          }
        >
          <StyledText
            color={
              optionIds[optionIndex] === index ? accentColor : normalTextColor
            }
          >
            {item.label}
          </StyledText>
        </View>
      </TouchableWithoutFeedback>
    );
  });

  return (
    <>
      <StyledText size={16}>{name}</StyledText>
      <View style={styles.itemsContainer}>{renderItems}</View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
  },
  header: {
    flexDirection: 'row',
    borderBottomColor: backGroundLight,
    borderBottomWidth: 1,
    justifyContent: 'space-between',
  },
  infoContainer: {
    justifyContent: 'center',
    marginLeft: 5,
    width: width * 0.6,
  },
  closeBtnContainer: {
    alignItems: 'flex-end',
  },
  optionsContainer: {
    paddingTop: 10,
  },
  footer: {
    paddingVertical: 10,
  },
  itemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  optionItem: {
    borderColor: backGroundLight,
    borderWidth: 1,
    padding: 5,
    marginVertical: 5,
    marginRight: 10,
    borderRadius: 10,
  },
  activeOptionItem: {
    borderColor: accentColor,
    borderRadius: 10,
    backgroundColor: '#fde8e0',
    borderWidth: 1,
    padding: 5,
    marginVertical: 5,
    marginRight: 10,
  },

  amountContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default ProductOptionSelector;

async function getProductPrice(
  goodsId: number,
  quantity: number,
  productOption: Array<number>
) {
  let url = `price?id=${goodsId}&qty=${quantity}&attr_id=${productOption}`;
  const result = await getData(url);
  return result;
}
