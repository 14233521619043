import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';
import React from 'react';
import { ColorSchemeName, Platform } from 'react-native';
import { RootStackParamList } from '../types';
import BottomTabNavigator from './BottomTabNavigator';
import ModalNavigator from './ModalNavigator';
import ProductNavigator from './Product/ProductNavigator';
import useAppConfig from '../hooks/useAppConfig';
import { useMount } from 'ahooks';
import NoInternetScreen from '../screens/NoInternetScreen';

import { useDispatch } from 'react-redux';
import getAppStyle from '../utils/getAppStyle';

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  return <RootNavigator />;
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
  const dispatch = useDispatch();

  useMount(() => {
    // Extract query params from url and set to Redux. The query params are set when update the share link for WechatShare.
    if (Platform.OS === 'web') {
      //@ts-ignore
      const url: URL = new URL(window.location.href);
      const params: URLSearchParams = url.searchParams;
      const mainIndex = params.get('mainIndex') ?? 0;
      const subIndex = params.get('subIndex') ?? 0;
      dispatch({
        type: 'UPDATE_SELECTED_MAIN_CATEGORY_INDEX',
        payload: +mainIndex,
      });
      dispatch({
        type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX',
        payload: +subIndex,
      });
    }
  });

  //Get basic setting information from async storage like language,location, app version etc.
  useAppConfig();

  return (
    <>
      <Stack.Navigator
        mode="modal"
        screenOptions={{
          headerShown: false,
          ...TransitionPresets.ModalSlideFromBottomIOS,
        }}
        initialRouteName={
          getAppStyle(process.env.EXPO_APP_TYPE)?.loginRequiredAccessAllPages
            ? 'Modal'
            : 'Root'
        }
      >
        <Stack.Screen name="Root" component={BottomTabNavigator} />
        <Stack.Screen name="Modal" component={ModalNavigator} />
        <Stack.Screen name="ProductNavigator" component={ProductNavigator} />
        <Stack.Screen name="NoInternet" component={NoInternetScreen} />
      </Stack.Navigator>
    </>
  );
}
