import React from 'react';
import FullWidthView from '../../../components/common/FullWidthView';
import AddressForm from '../../../components/User/Address/AddressForm';
import { useRoute } from '@react-navigation/native';
import { useDispatch } from 'react-redux';

const EditAddressScreen = () => {
  const route = useRoute();
  const dispatch = useDispatch();

  const { addressObject } = route.params;
  const addressInfo = JSON.parse(addressObject);

  if (addressInfo) {
    dispatch({
      type: 'UPDATE_EDITING_ADDRESS',
      payload: {
        name: addressInfo.address,
        location: {
          latitude: parseFloat(addressInfo.latitude),
          longitude: parseFloat(addressInfo.longitude),
        },
      },
    });
  }

  return (
    <FullWidthView>
      <AddressForm type="edit" data={addressInfo} />
    </FullWidthView>
  );
};

export default EditAddressScreen;
