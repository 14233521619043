import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import StyledText from '../../common/StyledText';
import { FontAwesome } from '@expo/vector-icons';

type UserAndStarsProps = {
  nick_name: string;
  comment_rank: number;
  user_picture: string;
  add_time: string;
  large?: boolean;
};

const UserAndStars = ({
  nick_name,
  comment_rank,
  user_picture,
  add_time,
  large = false,
}: UserAndStarsProps) => {
  const [styleVariables, setStyleVariables] = useState({
      image_size: 35,
      font_size: 12,
      date_margin: 0
  })

  useEffect(() => {
    if (large){
        setStyleVariables({
          image_size: 45,
          font_size: 16,
          date_margin: 5
        });
    }
  }, []);

  const styles = StyleSheet.create({
    commentHeader: {
      flexDirection: 'row',
      marginBottom: 10,
      justifyContent: 'space-between',
    },
    commentHeaderInfo: {
      flexDirection: 'column',
      width: '50%',
    },
    userPicture: {
      width: styleVariables.image_size,
      height: styleVariables.image_size,
      borderRadius: styleVariables.image_size,
      marginRight: 10,
    },
    dateText: {
      fontSize: 12,
      marginTop: styleVariables.date_margin
    }
  });

  const renderCommentRank = (commentRank: number) => {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {Array.from(Array(commentRank)).map((item, index) => (
          <FontAwesome key={index} name="star" size={12} color="red" />
        ))}
        {Array.from(Array(5 - commentRank)).map((item, index) => (
          <FontAwesome key={index} name="star" size={12} color="gray" />
        ))}
        <StyledText style={{ paddingBottom: 1, paddingLeft: 2 }} size={12}>
          {commentRank}
        </StyledText>
      </View>
    );
  };

  return (
    <View style={styles.commentHeader}>
      <View style={{ flexDirection: 'row' }}>
        <Image style={styles.userPicture} source={{ uri: user_picture }} />
        <View style={styles.commentHeaderInfo}>
          <StyledText size={styleVariables.font_size}>{nick_name}</StyledText>
          {renderCommentRank(comment_rank)}
        </View>
      </View>
      <View>
        <Text style={styles.dateText}>{add_time.split(' ')[0]}</Text>
      </View>
    </View>
  );
};

export default UserAndStars;
