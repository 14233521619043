import React, { useEffect, useState } from 'react';
import {
  View,
  StyleSheet,
  Dimensions,
  ScrollView,
  Platform,
} from 'react-native';
import FullWidthView from '../../components/common/FullWidthView';
import { backGroundLight, normalTextColor } from '../../constants/Colors';
import ProductPageHeader from '../../components/Product/ProductPageHeader';
import ProductMediaCarousel from '../../components/Product/ProductMediaCarousel';
import { getGoodsWithId } from '../../utils/service';
import Loading from '../../components/common/Loading';
import ProductInfo from '../../components/Product/ProductInfo';
import Section from '../../components/common/Section';
import HTML from 'react-native-render-html';
/** @ts-ignore */
import { IGNORED_TAGS } from 'react-native-render-html/src/HTMLUtils';
import { Video } from 'expo-av';
import ShopInfo from '../../components/Product/ShopInfo';
import ProductComments from '../../components/Product/ProductComments';
import { GoodsInfo } from '../../types';
import ProductNavbar from '../../components/Product/ProductNavbar';
import { useNavigation } from '@react-navigation/native';
import { postWithAuthToken } from '../../utils/service';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';
import StyledText from '../../components/common/StyledText';
import { useTranslation } from 'react-i18next';
import ResponsiveImg from '../../components/common/ResponsiveImg';
import { MaterialIcons } from '@expo/vector-icons';
import {
  defaultWeChatShareConfig,
  resetWechatSharePage,
} from '../../utils/wechatWebMethods';
import alert from '../../utils/alert';
import { screenHit } from '../../utils/analytic';

type ProductDetailScreenProps = {
  route: any;
};

type ProductMediaObject = {
  uri: string;
  type: string;
};

const { width } = Dimensions.get('screen');

const ProductDetailScreen = ({ route }: ProductDetailScreenProps) => {
  const { productId } = route.params ?? { productId: -1 };

  const { t } = useTranslation();
  const navigation = useNavigation();
  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.user.isLoggedIn
  );
  const [product, setProduct] = useState<GoodsInfo>();
  const [isCollect, setIsCollect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [carouselMedia, setCarouselMedia] = useState<Array<ProductMediaObject>>(
    []
  );
  const [isError, setIsError] = useState(false);
  const [addToCartDisabled, setAddToCartDisabled] = useState(false);
  const classesStyles = {
    txt: {
      lineHeight: 20,
      fontSize: 14,
      fontFamily: 'noto-sans',
      color: normalTextColor,
      marginBottom: 15,
      backgroundColor: 'white',
    },
    img: {
      marginBottom: 15,
    },
  };
  const shopStatus = useSelector<
    RootState,
    {
      code: number;
      message: string;
      shopRegCompany: boolean;
      shopTitle: string;
      shopDesc: string;
    }
  >((state) => state.goods.shopStatus);

  const handleCarouselMedia = (productPics: []) => {
    let carouselData: Array<ProductMediaObject> = [];
    productPics.forEach((element: any) => {
      carouselData.push({
        uri: element.img_url,
        type: element.type,
      });
    });
    setCarouselMedia(carouselData);
  };

  const handleIsCollect = (product: GoodsInfo) => {
    if (product.is_collect) {
      setIsCollect(true);
    } else {
      setIsCollect(false);
    }
  };

  const handleAddToCartDisabled = (product: GoodsInfo) => {
    if (
      product.is_on_sale === 0 ||
      product.is_alone_sale === 0 ||
      product.review_status < 3
    ) {
      setAddToCartDisabled(true);
    } else {
      setAddToCartDisabled(false);
    }
  };

  const handleFavorite = () => {
    if (isLoggedIn) {
      const loading = Toast.showLoading('');
      const endpoint = isCollect ? 'collection/delete' : 'collection/add';
      const setValue = !isCollect;
      postWithAuthToken(endpoint, { goods_id: productId })
        .then((result) => {
          Toast.hide(loading);
          if (result && result.goodStatus) {
            setIsCollect(setValue);
          } else {
            console.log('error: ', result);
          }
        })
        .catch((err) => {
          if (loading) Toast.hide(loading);
          Toast.show(err, {
            position: 0,
            duration: 2000,
          });
        });
    } else {
      navigation.navigate('Modal', { screen: 'AuthModal' });
    }
  };

  useEffect(() => {
    if (product && Platform.OS === 'web')
      resetWechatSharePage(
        product.goods_name +
          product.formated_final_goods_price +
          ` /${product.goods_unit}`,
        product.goods_brief
          ? product.goods_brief
          : defaultWeChatShareConfig.desc,
        product.goods_thumb
      );
  }, [product]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      screenHit(`product/${productId}`);
      setIsError(false);
      setIsLoading(true);
      if (productId < 0) {
        setIsError(true);
        setIsLoading(false);
      } else {
        getGoodsWithId(productId)
          .then((response) => {
            setIsLoading(false);
            if (response && response.goodStatus) {
              navigation.setOptions({ title: response.data.goods_name });
              setProduct(response.data);
              handleCarouselMedia(response.data.pics);
              handleAddToCartDisabled(response.data);
              handleIsCollect(response.data);
            } else {
              // if (response) {
              //   alert(response?.data || 'Error', '', [
              //     {
              //       text: t('goBack'),
              //       onPress: () => {
              //         if (navigation.canGoBack()) {
              //           navigation.goBack();
              //         } else {
              //           if (Platform.OS === 'web') {
              //             /** @ts-ignore */
              //             window.history.back();
              //             navigation.reset({
              //               index: 0,
              //               routes: [{ name: 'Root' }],
              //             });
              //           } else {
              //             navigation.reset({
              //               index: 0,
              //               routes: [{ name: 'Root' }],
              //             });
              //           }
              //         }
              //       },
              //     },
              //   ]);
              // }
              alert('Error', response?.data || '', [{ text: t('confirm') }], {
                cancelable: false,
              });
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
      }
    });

    return unsubscribe;
  }, [navigation]);

  return (
    <FullWidthView edges={['left', 'right', 'top', 'bottom']}>
      {isLoading ? (
        <View style={styles.loadingContainer}>
          <Loading />
        </View>
      ) : isError ? (
        <View style={styles.errorContainer}>
          <MaterialIcons name="error" size={24} color="lightgray" />
          <StyledText color={'lightgray'}>
            {t('failedToRetrieveItem')}
          </StyledText>
        </View>
      ) : (
        <>
          <ScrollView style={{ flex: 1, backgroundColor: backGroundLight }}>
            <ProductMediaCarousel
              watermark_img={product?.watermark_img || ''}
              data={carouselMedia}
            />
            <Section>
              <ProductInfo
                goods_name={product?.goods_name || ''}
                goods_brief={product?.goods_brief || ''}
                formated_final_goods_price={
                  product?.formated_final_goods_price || ''
                }
                formated_shop_price={product?.formated_shop_price || ''}
                formated_estimate_shop_price={
                  product?.formated_estimate_shop_price || ''
                }
                limit_number={product?.limit_number || 0}
                goods_unit={product?.goods_unit || ''}
                text_tag={product?.text_tag}
                weight_tag={product?.weight_tag}
              />
            </Section>
            {product?.shop && (
              <Section title={t('productDetailScreen.store')}>
                <ShopInfo shop={product.shop} />
              </Section>
            )}
            {product?.comments && (
              <ProductComments
                comments={product.comments}
                goods_id={productId}
              />
            )}
            {product && product.desc_mobile ? (
              <View style={{ backgroundColor: 'white', paddingHorizontal: 10 }}>
                <StyledText size={16} bold style={styles.descriptionTitle}>
                  {t('productDetailScreen.productDescription')}
                </StyledText>
                {typeof product.desc_mobile === 'string' ? (
                  <HTML
                    ignoredTags={IGNORED_TAGS.filter(
                      (tag: any) => tag !== 'video'
                    )}
                    renderers={{
                      img: (htmlAttribs) => (
                        <ResponsiveImg
                          key={Math.random()}
                          width={width * 0.95}
                          uri={htmlAttribs.src.toString()}
                        />
                      ),
                      video: (htmlAttribs) => (
                        <Video
                          key={Math.random()}
                          source={{
                            uri: htmlAttribs.src.toString(),
                          }}
                          useNativeControls
                          resizeMode="contain"
                          style={{
                            marginBottom: 15,
                            width: width * 0.95,
                            height: 200,
                          }}
                        />
                      ),
                    }}
                    classesStyles={classesStyles}
                    imagesInitialDimensions={{
                      width: width,
                      height: 0,
                    }}
                    source={{ html: product?.desc_mobile || '<div></div>' }}
                  />
                ) : Array.isArray(product.desc_mobile) &&
                  product.desc_mobile.length > 0 ? (
                  product.desc_mobile.map((item, index) =>
                    item.type === 'TXT' ? (
                      <StyledText
                        key={index}
                        style={{
                          fontSize: item.style
                            ? parseInt(item.style.fontSize || '14')
                            : undefined,
                          color: item.style ? item.style.fontColor : undefined,
                          backgroundColor: item.style
                            ? item.style.backgroundColor
                            : undefined,
                          fontWeight: item.style
                            ? item.style.fontWeight
                            : undefined,
                          textAlign: item.style
                            ? item.style.textAlign
                            : undefined,
                          marginBottom: 15,
                        }}
                      >
                        {item.value}
                      </StyledText>
                    ) : item.type === 'IMG' ? (
                      <View style={{ marginBottom: 15 }} key={index}>
                        <ResponsiveImg
                          width={width * 0.95}
                          uri={item.originalMedia || ''}
                        />
                      </View>
                    ) : item.type === 'VIDEO' ? (
                      <Video
                        key={index}
                        source={{ uri: item.originalMedia || '' }}
                        useNativeControls
                        resizeMode="contain"
                        style={{
                          marginBottom: 15,
                          width: width * 0.95,
                          height: 200,
                        }}
                      />
                    ) : (
                      <></>
                    )
                  )
                ) : (
                  <></>
                )}
              </View>
            ) : (
              <></>
            )}
          </ScrollView>
          {product && (
            <View style={styles.bottomNavbar}>
              <ProductNavbar
                addToCartDisabled={addToCartDisabled}
                isCollect={isCollect}
                onPressFavorite={handleFavorite}
                goodsId={productId}
                goodsData={product}
                isPresaleProduct={product.is_presale === 1}
              />
            </View>
          )}
        </>
      )}
      <View style={styles.header}>
        <ProductPageHeader
          pName={
            product
              ? product.goods_name +
                product.formated_final_goods_price +
                ` /${product.goods_unit}`
              : ''
          }
          pDescription={
            product?.goods_brief
              ? product?.goods_brief
              : shopStatus
              ? shopStatus.shopDesc
              : ''
          }
          pId={productId}
          pThumbnail={product ? product.goods_thumb : ''}
          product={product}
        />
      </View>
    </FullWidthView>
  );
};

const styles = StyleSheet.create({
  header: {
    position: 'absolute',
    top: 0,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  bottomNavbar: {
    width: width,
    backgroundColor: 'white',
  },
  descriptionTitle: {
    paddingVertical: 15,
  },
});

export default ProductDetailScreen;
