import React, { useState } from 'react';
import {
  StyleSheet,
  View,
  TouchableWithoutFeedback,
  Platform,
  Image,
} from 'react-native';
import CachedImage from '../../common/cache-img/CachedImage';
import ImageViewerModal from '../../common/ImageViewerModal';
import { CommentImage } from '../../../types';

type PhotoGridProps = {
  pictures: Array<CommentImage>;
  style?: any;
};

const PhotoGrid = ({ pictures, style }: PhotoGridProps) => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const handleImageOnPress = (index: number) => {
    setImageIndex(index);
    setModalVisible(true);
  };

  return (
    <>
      <View style={[styles.imageWrapper, style]}>
        {pictures.map((pic, index) =>
          pic.id !== -1 ? (
            <TouchableWithoutFeedback
              key={index}
              onPress={() => handleImageOnPress(index)}
              style={{ flexGrow: 1 }}
            >
              {Platform.OS !== 'web' ? (
                <CachedImage
                  source={{ uri: pic.comment_img }}
                  style={styles.image}
                />
              ) : (
                <Image style={styles.image} source={{ uri: pic.comment_img }} />
              )}
            </TouchableWithoutFeedback>
          ) : (
            <View style={styles.image} key={index}></View>
          )
        )}
      </View>
      <ImageViewerModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        imageIndex={imageIndex}
        imgUrls={pictures
          .filter((item) => item.id !== -1)
          .map((item) => ({ url: item.comment_img }))}
      />
    </>
  );
};

const styles = StyleSheet.create({
  imageWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  image: { borderRadius: 5, marginVertical: 4, width: 100, height: 100 },
});

export default PhotoGrid;
