import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import StyledText from '../../common/StyledText';
import NavigateButton from '../NavigateButton';
import { useTranslation } from 'react-i18next';
import { backGroundLight, tintColorLight } from '../../../constants/Colors';
import ViewMoreText from 'react-native-view-more-text';

type AdminReplyProps = {
  admin_reply: {
    comment_id: number;
    content: string;
  } | null;
  showFullContent?: boolean;
  productId?: number;
  post?: boolean;
};

const AdminReply = ({
  admin_reply,
  showFullContent = true,
  productId,
  post = false,
}: AdminReplyProps) => {
  const [showMore, setShowMore] = useState(false);

  const { t } = useTranslation();

  const renderViewMore = (handlePress: any) => {
    return (
      <View style={styles.showMoreBtnContainer}>
        <View style={styles.showMoreBtn}>
          <StyledText onPress={handlePress}>Show</StyledText>
        </View>
      </View>
    );
  };

  const renderViewLess = (handlePress: any) => {
    return (
      <StyledText style={{ display: 'none' }} onPress={handlePress}>
        Show less
      </StyledText>
    );
  };

  if (admin_reply) {
    if (admin_reply.content.length <= 50 || showFullContent) {
      return (
        <View style={styles.replyContainer}>
          <StyledText bold>
            {t('productDetailScreen.commentSection.storeReply')}
          </StyledText>
          <StyledText>{admin_reply.content}</StyledText>
        </View>
      );
    } else {
      return (
        <View style={styles.replyContainer}>
          <StyledText bold>
            {t('productDetailScreen.commentSection.storeReply')}
          </StyledText>
          {post ? (<ViewMoreText
            numberOfLines={3}
            renderViewMore={renderViewMore}
            renderViewLess={renderViewLess}
          >
            <StyledText>{admin_reply.content}</StyledText>
          </ViewMoreText>) : (
              <StyledText>{`${admin_reply.content.slice(
                0,
                45
              )}......`}</StyledText>
          )}
          <View style={styles.showMoreBtnContainer}>
            {!post  && (
              <NavigateButton
                route="CommentScreen"
                options={{ productId: productId }}
              >
                <View style={styles.showMoreBtn}>
                  <StyledText>
                    {t('productDetailScreen.commentSection.show')}
                  </StyledText>
                </View>
              </NavigateButton>
            )}
          </View>
        </View>
      );
    }
  }
  return <></>;
};

const styles = StyleSheet.create({
  showMoreBtnContainer: {
    alignItems: 'flex-end',
  },
  showMoreBtn: {
    backgroundColor: backGroundLight,
    padding: 3,
  },
  replyContainer: {
    marginTop: 10,
    backgroundColor: backGroundLight,
    padding: 3,
  },
});

export default AdminReply;
