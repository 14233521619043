import React, { useEffect, useRef, useState } from 'react';
import {
  SectionList,
  StyleSheet,
  TouchableOpacity,
  View,
  SectionListData,
  Platform,
  TouchableWithoutFeedback,
  ScrollView,
} from 'react-native';
import FullWidthView from '../../components/common/FullWidthView';
import ResponsiveImg from '../../components/common/ResponsiveImg';
import StyledText from '../../components/common/StyledText';
import {
  backGroundLight,
  subTextColor,
  tintColorLight,
} from '../../constants/Colors';
import { getData } from '../../utils/service';
import sectionListGetItemLayout from 'react-native-section-list-get-item-layout';
import alert from '../../utils/alert';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { resetWechatSharePage } from '../../utils/wechatWebMethods';
import RecommendList from '../../components/Brand/RecommendList';
import Loading from '../../components/common/Loading';
import { useUpdateEffect } from 'ahooks';

type BrandItem = {
  brand_id: number;
  brand_name: string;
  brand_letter: string;
  brand_first_char: string;
  brand_logo: string;
  index_img: string;
  brand_bg: string;
  brand_desc: string;
  site_url: string;
  sort_order: number;
  is_show: 0 | 1;
  is_delete: 0 | 1;
  audit_status: 0 | 1;
  add_time: string;
  is_recommend: 0 | 1;
};

const BrandListScreen = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const [brandList, setBrandList] = useState<
    Array<{
      title: string;
      data: BrandItem[];
    }>
  >([]);
  const [activatedIndex, setActivatedIndex] = useState(0);
  const [alphabetArray, setAlphabetArray] = useState<string[]>([]);
  const [contentSizeData, setContentSizeData] = useState<number[]>([]);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  const [isIndexClick, setIsIndexClick] = useState(false);

  const brandListRef = useRef<SectionList>(null);

  useEffect(() => {
    setIsLoadingComplete(false);
    let isSubscribe = true;
    getBrandList()
      .then((result) => {
        if (result && result.goodStatus) {
          const list: Array<{
            title: string;
            data: BrandItem;
          }> = [];
          const alphabet: Array<string> = [];
          for (const [key, value] of Object.entries(result.data.brand)) {
            list.push({ title: key, data: value });
            alphabet.push(key);
          }
          if (isSubscribe) {
            setIsLoadingComplete(true);
            setBrandList(list);
            setAlphabetArray(alphabet);
          }
        } else {
          alert(
            typeof result?.data === 'string'
              ? result?.data
              : '系统繁忙，请稍微再试',
            '',
            [
              {
                text: t('confirm'),
                onPress: () => {
                  navigation.goBack();
                },
              },
            ],
            { cancelable: false }
          );
        }
      })
      .catch((err) => console.log('get brand list error', err));

    return () => {
      isSubscribe = false;
    };
  }, []);

  const renderIndexPicker = alphabetArray.map((item, index) => {
    return (
      <TouchableWithoutFeedback
        key={index}
        onPress={() => {
          brandListRef.current?.scrollToLocation({
            itemIndex: 0,
            sectionIndex: index,
          });
          setIsIndexClick(true);
          setActivatedIndex(index);
        }}
      >
        <View
          style={{
            paddingHorizontal: 5,
            width: '100%',
          }}
        >
          <StyledText
            align="center"
            size={Platform.OS === 'web' ? 12 : 17}
            color={activatedIndex === index ? tintColorLight : subTextColor}
            bold={activatedIndex === index}
          >
            {item}
          </StyledText>
        </View>
      </TouchableWithoutFeedback>
    );
  });

  const getItemLayout = sectionListGetItemLayout({
    // The height of the row with rowData at the given sectionIndex and rowIndex
    getItemHeight: () => 50,
    getSectionHeaderHeight: () => 29.5, // The height of your section headers
    listHeaderHeight: 112.5,
  });

  useUpdateEffect(() => {
    var _sizeArr: number[] = [];
    if (brandList.length > 0) {
      for (var _iter = 0; _iter < brandList.length; _iter++) {
        let cellsHeight = brandList[_iter].data.length * 50;
        let headerHeight = 29.5;
        var totalHeight = cellsHeight + headerHeight;
        if (_iter != 0) {
          _sizeArr.push(totalHeight + _sizeArr[_iter - 1]);
        } else {
          _sizeArr.push(totalHeight);
        }
      }
      setContentSizeData(_sizeArr);
    }
  }, [brandList]);

  // Setting Wechat share link
  React.useLayoutEffect(() => {
    // Set up Wechat share information when arguments change.
    if (Platform.OS === 'web') resetWechatSharePage('品牌列表');
    // Set up Wechat share information "again" when user come back from the other page.
    const unsubscribe = navigation.addListener('focus', () => {
      if (Platform.OS === 'web') resetWechatSharePage('品牌列表');
    });
    return unsubscribe;
  }, [navigation]);

  return (
    <FullWidthView>
      {isLoadingComplete ? (
        <>
          <ScrollView style={{ flex: 1 }}>
            <SectionList
              ref={brandListRef}
              sections={brandList as any[]}
              stickySectionHeadersEnabled={false}
              showsVerticalScrollIndicator={false}
              maxToRenderPerBatch={50}
              keyExtractor={(item) => item.brand_id.toString()}
              renderItem={({ item }) => (
                <Item
                  title={item.brand_name}
                  logo={item.brand_logo}
                  id={item.brand_id}
                />
              )}
              renderSectionHeader={({
                section,
              }: {
                section: SectionListData<
                  Array<{
                    title: string;
                    data: BrandItem;
                  }>
                >;
              }) => SectionHeader(section)}
              getItemLayout={getItemLayout}
              ListHeaderComponent={RecommendList}
              onScroll={(event) => {
                if (Platform.OS === 'web') {
                  if (!isIndexClick) {
                    for (var _i = 0; _i < contentSizeData.length; _i++) {
                      if (
                        event.nativeEvent.contentOffset.y < contentSizeData[_i]
                      ) {
                        setActivatedIndex(_i);
                        break;
                      }
                    }
                  } else {
                    setTimeout(() => {
                      setIsIndexClick(false);
                    }, 100);
                  }
                }
              }}
              onMomentumScrollEnd={(event) => {
                if (!isIndexClick) {
                  for (var _i = 0; _i < contentSizeData.length; _i++) {
                    if (
                      event.nativeEvent.contentOffset.y < contentSizeData[_i]
                    ) {
                      setActivatedIndex(_i);
                      break;
                    }
                  }
                } else {
                  setTimeout(() => {
                    setIsIndexClick(false);
                  }, 100);
                }
              }}
              scrollEventThrottle={Platform.OS === 'web' ? 500 : 100}
            />
          </ScrollView>
          <View style={styles.alphabetContainer}>{renderIndexPicker}</View>
        </>
      ) : (
        <View
          style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <Loading />
        </View>
      )}
    </FullWidthView>
  );
};

//Province Header
const SectionHeader = (section: SectionListData<any>) => {
  return (
    <View style={styles.sectionHeader}>
      <StyledText color={subTextColor}>{section.title}</StyledText>
    </View>
  );
};

//List item - Brand
const Item = ({ title, logo, id }: any) => {
  const navigation = useNavigation();

  const handleBrandPress = (brandId: number) => {
    navigation.navigate('Brand', {
      screen: 'BrandDetail',
      params: { brandId },
    });
  };

  return (
    <View style={{ width: '90%' }}>
      <TouchableOpacity onPress={() => handleBrandPress(id)}>
        <View style={styles.brandItemContainer}>
          <View style={styles.logoContainer}>
            {/* <CachedImage
              source={{ uri: logo }}
              style={{ width: 40, height: 40 }}
            /> */}
            <ResponsiveImg uri={logo} width={40} />
          </View>
          <StyledText numberOfLines={1}>{title}</StyledText>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  sectionHeader: {
    backgroundColor: backGroundLight,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  brandItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    borderBottomColor: backGroundLight,
    borderBottomWidth: 1,
    paddingHorizontal: 5,
  },
  logoContainer: {
    padding: 5,
    overflow: 'hidden',
    maxHeight: 50,
  },
  alphabetContainer: {
    borderRadius: 10,
    paddingVertical: 5,
    backgroundColor: 'rgba(0,0,0,0.1)',
    position: 'absolute',
    top: 15,
    right: 5,
  },
});

export default BrandListScreen;

const getBrandList = async () => {
  const result = await getData('brand/nav');
  return result;
};
