import React from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { accentColor } from '../../constants/Colors';
import StyledText from '../../components/common/StyledText';
import { useTranslation } from 'react-i18next';
import { AntDesign } from '@expo/vector-icons';
import NavigationButton from '../common/NavigateButton';

const PostButton = () => {
  const { t } = useTranslation();
  const { height } = Dimensions.get('window');

  const styles = StyleSheet.create({
    buttonView: {
      width: 100,
      position: 'absolute',
      bottom: height * 0.1,
      left: 24,
    },
    buttonColorShape: {
      backgroundColor: accentColor,
      borderRadius: 30,
      paddingVertical: 12,
      paddingHorizontal: 0,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,

      elevation: 5,
    },
    textView: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });

  return (
    <View style={styles.buttonView}>
      <NavigationButton
        style={styles.buttonColorShape}
        route={'SubmitPost'}
        loginRequired
      >
        <View style={styles.textView}>
          <AntDesign name="edit" size={16} color="white" />
          <StyledText
            align="center"
            color="white"
            size={16}
            style={{ marginLeft: 5 }}
          >
            {t('postScreen.title')}
          </StyledText>
        </View>
      </NavigationButton>
    </View>
  );
};

export default PostButton;
