import React from 'react';
import StyledText from '../common/StyledText';

type ErrorMessageProps = {
  label: string;
};

const ErrorMessage = ({ label }: ErrorMessageProps) => {
  return (
    <StyledText color="red" size={14}>
      {label}
    </StyledText>
  );
};

export default ErrorMessage;
