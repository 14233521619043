import { AntDesign } from '@expo/vector-icons'
import React from 'react'
import { TouchableOpacity, View, StyleSheet } from 'react-native'
import { accentColor } from '../../constants/Colors'
import StyledText from '../common/StyledText'

type PressNextTipProps = {
    onPress: Function | undefined,
    nextCategoryName: string;
}

const PressNextTip = ({
    onPress,
    nextCategoryName
    }: PressNextTipProps) => {

    return(
        <TouchableOpacity onPress={()=>onPress && onPress()}>
                <View style={styles.nextBtn}>
                    <StyledText style={{marginRight: 4}} color={accentColor}>
                        {nextCategoryName}
                    </StyledText>
                    <AntDesign name="rightcircleo" size={14} color={accentColor} />
                </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    nextBtn: {
        flexDirection: 'row',
        justifyContent: 'center', 
        alignItems: 'center',
        marginVertical: 10,
        alignSelf: 'center',
        padding: 5,
        borderRadius: 20,
        paddingHorizontal: 15
    }
})

export default PressNextTip