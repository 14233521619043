import React, { ReactNode } from 'react';
import { View, TextInput, StyleSheet, Dimensions } from 'react-native';
import { GREY3, GREY2, WHITE } from '../../constants/Colors';

const { width } = Dimensions.get('window');

type InputFieldProp = {
  keyboardType?:
    | 'default'
    | 'number-pad'
    | 'decimal-pad'
    | 'numeric'
    | 'email-address'
    | 'phone-pad';
  onChangeText: Function;
  iconComponent?: ReactNode;
  buttonComponent?: ReactNode;
  placeholder?: string;
  passwordField?: boolean;
  maxLength?: number;
  value: any;
};

const InputField = ({
  keyboardType = 'default',
  onChangeText,
  iconComponent,
  buttonComponent,
  placeholder = '',
  passwordField = false,
  maxLength,
  value,
}: InputFieldProp) => {
  return (
    <View style={styles.container}>
      {iconComponent && iconComponent}
      <TextInput
        style={styles.input}
        placeholder={placeholder}
        placeholderTextColor={GREY2}
        keyboardType={keyboardType}
        onChangeText={(text) => {
          onChangeText(text);
        }}
        maxLength={maxLength}
        clearButtonMode="while-editing"
        secureTextEntry={passwordField}
        value={value}
      />
      {buttonComponent && buttonComponent}
    </View>
  );
};

export default InputField;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: WHITE,
    borderWidth: 1,
    borderRadius: 25,
    borderColor: 'lightgray',
    paddingVertical: 5,
    paddingHorizontal: 10,
    width: width * 0.75,
  },
  input: {
    flex: 1,
    paddingTop: 10,
    paddingRight: 0,
    paddingBottom: 10,
    paddingLeft: 0,
    backgroundColor: WHITE,
    color: GREY3,
  },
});
