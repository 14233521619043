import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Switch, View, StyleSheet } from 'react-native';
import FullWidthView from '../../components/common/FullWidthView';
import StyledText from '../../components/common/StyledText';
import { backGroundLight, subTextColor } from '../../constants/Colors';
import {
  editNotificationConfig,
  getNotificationConfig,
} from '../../utils/service';
import alert from '../../utils/alert';
import { useTranslation } from 'react-i18next';

type SettingsList = {
  name: string;
  desc: string;
  data: any[];
};

const NotificationSettingScreen = () => {
  const { t } = useTranslation();
  const [settingGroups, setSettingGroups] = useState<SettingsList[]>([]);
  const [isEnabled, setIsEnabled] = useState<any>();
  const navigation = useNavigation();

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getNotificationConfig()
        .then((response) => {
          if (response && response.goodStatus) {
            let enable: any = {};
            for (let value of Object.values<any>(response.data)) {
              for (let e of value.data) {
                enable[e.key] = e.value;
              }
            }
            setIsEnabled(enable);
            setSettingGroups(Object.values(response.data));
          } else {
            alert('Error', response?.data || '', [{ text: t('confirm') }], {
              cancelable: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
    return unsubscribe;
  }, [navigation]);

  const onToggle = (type: string, value: boolean) => {
    editNotificationConfig({ ...isEnabled, [type]: value ? 1 : 0 }).then(
      (response) => {
        if (response && response.goodStatus) {
          setIsEnabled((prev: any) => ({ ...prev, [type]: value ? 1 : 0 }));
        } else {
          alert('Error', response?.data || '', [{ text: t('confirm') }], {
            cancelable: false,
          });
        }
      }
    );
  };

  return (
    <FullWidthView style={{ backgroundColor: backGroundLight }}>
      {!!settingGroups.length &&
        settingGroups.map((group, index) => (
          <View style={styles.container} key={index}>
            <StyledText size={16} bold>
              {group.name}
            </StyledText>
            <StyledText size={12} color={subTextColor}>
              {group.desc}
            </StyledText>
            {!!group.data.length &&
              group.data.map((item, index) => (
                <View style={styles.item} key={index}>
                  <View style={styles.itemLeft}>
                    <StyledText>{item.name}</StyledText>
                    <StyledText size={12} color={subTextColor}>
                      {item.desc}
                    </StyledText>
                  </View>
                  <Switch
                    value={isEnabled[item.key] ? true : false}
                    onValueChange={(value) => onToggle(item.key, value)}
                  />
                </View>
              ))}
          </View>
        ))}
    </FullWidthView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 20,
    backgroundColor: 'white',
    marginHorizontal: 20,
    marginTop: 20,
    borderRadius: 8,
  },
  item: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingTop: 15,
  },
  itemLeft: {
    flex: 1,
  },
});

export default NotificationSettingScreen;
