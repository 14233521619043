import React from 'react';
import FullWidthView from '../../../components/common/FullWidthView';
import StyledText from '../../../components/common/StyledText';

const MessageDetailScreen = () => {
  return (
    <FullWidthView>
      <StyledText>Detail Hello</StyledText>
    </FullWidthView>
  );
};

export default MessageDetailScreen;
