import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import LevelThreeCategoryMenu from './LevelThreeCategoryMenu';
import { View, FlatList } from 'react-native';
import LevelThreeCategoryList from './LevelThreeCategoryList';
import { CategoriesItem, RootState } from '../../../../types';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import GoodsActionToolbar from '../../GoodsActionToolbar';

type LevelThreeCategoryWithSwipeProps = {
  horizontalIndex: number;
  setHorizontalIndex: Function;
  levelThreeCategories: Array<CategoriesItem>;
  listHeader?: JSX.Element;
  isHomeProductList?: boolean;
  setHomeProductListCatInfo?: Function;
  width: number;
  nextCategoryName: string;
  updateSubCategoryChange: Function;
  categoryChangeState: boolean;
};

const LevelThreeCategoryWithSwipe = forwardRef(
  (
    {
      levelThreeCategories,
      listHeader,
      horizontalIndex,
      setHorizontalIndex,
      width,
      setHomeProductListCatInfo,
      isHomeProductList = false,
      nextCategoryName = '',
      updateSubCategoryChange,
      categoryChangeState,
    }: LevelThreeCategoryWithSwipeProps,
    ref
  ) => {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const horizontalPageList = useRef<FlatList<any>>(null);
    const [selectedSorter, setSelectedSorter] = useState<{
      sort_key?: string;
      sort_value?: string;
    }>({});

    const categoryNamesArray = levelThreeCategories.map(
      (item: CategoriesItem) => item.name
    );

    const subCategoryLength = useSelector<RootState, number>(
      (state) => state.goods.subCategoryLength
    );

    const selectedSubCategoryIndex = useSelector<RootState, number>(
      (state) => state.goods.selectedSubCategoryIndex
    );

    const categoriesArray = useSelector<RootState, Array<CategoriesItem>>(
      (state) => state.goods.categoriesArray
    );

    const selectedMainCategoryIndex = useSelector<RootState, number>(
      (state) => state.goods.selectedMainCategoryIndex
    );

    useImperativeHandle(ref, () => ({
      resetHorizontalPage() {
        try {
          if (horizontalPageList && horizontalPageList.current) {
            horizontalPageList?.current?.scrollToIndex({
              animated: false,
              index: 0,
            });
          }
        } catch (error) {
          console.log(error);
        }
      },
    }));

    const updateHorizontalPage = (index: number) => {
      setHorizontalIndex(index);
      if (isHomeProductList && categoryNamesArray.length > 0) {
        navigation.setOptions({
          headerTitle: categoryNamesArray[index],
        });
      }
    };

    const scrollToNextCategory = (currentIndex: number) => {
      if (currentIndex < categoryNamesArray.length - 1) {
        updateHorizontalPage(currentIndex + 1);
      } else {
        updateHorizontalPage(0);
        //Switch level 2 category
        if (selectedSubCategoryIndex + 1 <= subCategoryLength - 1) {
          dispatch({ type: 'SET_RESET_GOODS_LIST', payload: true });
          dispatch({
            type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX',
            payload: selectedSubCategoryIndex + 1,
          });
        }
        //Switch level 1 category
        else {
          if (
            selectedMainCategoryIndex !== -1 &&
            selectedMainCategoryIndex < categoriesArray.length - 1
          ) {
            navigation.setParams({
              catId: categoriesArray[selectedMainCategoryIndex + 1].id,
            });
            dispatch({
              type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX',
              payload: 0,
            });
            dispatch({
              type: 'UPDATE_SELECTED_MAIN_CATEGORY_NAME',
              payload: categoriesArray[selectedMainCategoryIndex + 1].name,
            });
            dispatch({
              type: 'UPDATE_SELECTED_MAIN_CATEGORY_INDEX',
              payload: selectedMainCategoryIndex + 1,
            });
          } else {
            navigation.setParams({
              catId: categoriesArray[0].id,
            });
            dispatch({
              type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX',
              payload: 0,
            });
            dispatch({
              type: 'UPDATE_SELECTED_MAIN_CATEGORY_NAME',
              payload: categoriesArray[0].name,
            });
            dispatch({
              type: 'UPDATE_SELECTED_MAIN_CATEGORY_INDEX',
              payload: 0,
            });
          }
        }
      }
    };

    return (
      <View style={{ flex: 1 }}>
        <LevelThreeCategoryMenu
          categoryNames={categoryNamesArray}
          horizontalIndex={horizontalIndex}
          setHorizontalIndex={setHorizontalIndex}
          updateHorizontalPage={updateHorizontalPage}
          updateSubCategoryChange={updateSubCategoryChange}
        />
        <View>
          <GoodsActionToolbar
            leftSideMenuChangeState={categoryChangeState}
            setSelectedSorter={setSelectedSorter}
          />
        </View>
        <LevelThreeCategoryList
          selectedSorter={selectedSorter}
          width={width}
          setHomeProductListCatInfo={setHomeProductListCatInfo}
          category={levelThreeCategories[horizontalIndex]}
          listHeader={horizontalIndex === 0 ? listHeader : <></>}
          scrollToNextCategory={() => scrollToNextCategory(horizontalIndex)}
          nextCategoryName={
            levelThreeCategories[horizontalIndex + 1]
              ? levelThreeCategories[horizontalIndex + 1].name
              : nextCategoryName
          }
          levelThreeCategories={levelThreeCategories}
          horizontalIndex={horizontalIndex}
          setHorizontalIndex={setHorizontalIndex}
        />
      </View>
    );
  }
);

export default LevelThreeCategoryWithSwipe;
