import React, { useEffect, useState } from 'react';
import { Entypo } from '@expo/vector-icons';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { getDataWithAuthToken } from '../../../utils/service';
import Loading from '../../../components/common/Loading';
import { AddressInformation, AddressParamList } from '../../../types';
import AddressList from '../../../components/User/Address/AddressList';
import EmptyDisplayBlock from '../../../components/common/EmptyDisplayBlock';
import FullWidthView from '../../../components/common/FullWidthView';
import { useHeaderHeight } from '@react-navigation/stack';

const AddressListScreen = () => {
  const navigation = useNavigation();
  const route = useRoute<RouteProp<AddressParamList, 'AddressList'>>();

  const type = route.params ? route.params.type : 'edit';
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [addressList, setAddressList] = useState<Array<AddressInformation>>([]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getAddressList()
        .then((result) => {
          if (result && result.goodStatus) {
            setAddressList(result.data);
          } else {
            console.log(result);
          }

          setLoadingComplete(true);
        })
        .catch((err) => {
          throw err;
        });
    });

    return unsubscribe;
  }, [navigation]);

  return (
    <FullWidthView edges={['bottom', 'left', 'right']}>
      {!loadingComplete ? (
        <Loading />
      ) : addressList.length > 0 ? (
        <AddressList data={addressList} type={type} />
      ) : (
        <EmptyAddressList />
      )}
    </FullWidthView>
  );
};

const EmptyAddressList = () => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  return (
    <EmptyDisplayBlock
      buttonLabel={t('address.addAddress')}
      onPress={() => {
        navigation.navigate('NewAddress');
      }}
      description={t('address.emptyAddressList')}
      icon={<Entypo name="address" size={80} color="lightgray" />}
    />
  );
};

export default AddressListScreen;

const getAddressList = async () => {
  const result = await getDataWithAuthToken('user/address');
  return result;
};
