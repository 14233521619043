import React, { useState, useEffect } from 'react';
import { processWeChatCode } from '../../utils/service';
import { MaterialIcons } from '@expo/vector-icons';
import {
  TouchableWithoutFeedback,
  View,
  StyleSheet,
  Keyboard,
  TouchableOpacity,
  Platform,
} from 'react-native';
import FullWidthView from '../../components/common/FullWidthView';
import { Ionicons } from '@expo/vector-icons';
import StyledText from '../../components/common/StyledText';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, TokenPayload } from '../../types';
import { setAsyncStorageData } from '../../utils/asyncStorage';
import WeChatRegisterForm from '../../components/Auth/WeChatRegisterForm';
import Loading from '../../components/common/Loading';
import { useNavigation } from '@react-navigation/native';
import JwtDecode from 'jwt-decode';
import * as Linking from 'expo-linking';
import { linkColor } from '../../constants/Colors';

type WeChatRegisterScreenProps = {
  route: any;
};

const WeChatRegisterScreen = ({ route }: WeChatRegisterScreenProps) => {
  const { code, err } = route.params;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [responseCode, setResponseCode] = useState(0);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation<any>();
  const preferredLanguage = useSelector<RootState, string | null | undefined>(
    (state) => state.user.preferredLanguage
  );

  useEffect(() => {
    let isSubscribe = true;
    (async () => {
      if (Platform.OS === 'web') {
        setLoading(true);
        let referer: any = '';
        let redirectUrl: any = '';
        const url = await Linking.getInitialURL();
        if (url) {
          const { queryParams } = Linking.parse(url);
          if (queryParams) {
            if (queryParams.referer) {
              referer = queryParams.referer;
            }
            if (queryParams.redirect) {
              redirectUrl = queryParams.redirect;
            }
          }
        }

        processWeChatCode(
          code,
          referer && { headers: { 'Referer-Platform': referer } }
        )
          .then((result) => {
            setLoading(false);
            if (result) {
              if (isSubscribe) {
                switch (result.return_code) {
                  case 200:
                    if (redirectUrl) {
                      let newUrl = redirectUrl;
                      if (
                        result.data.third_party &&
                        typeof result.data.third_party === 'object'
                      ) {
                        Object.keys(result.data.third_party).forEach(
                          (key, i) => {
                            if (i === 0) {
                              newUrl += `?${key}=${result.data.third_party[key]}`;
                            } else {
                              newUrl += `&${key}=${result.data.third_party[key]}`;
                            }
                          }
                        );
                      }
                      redirect(newUrl);
                    } else {
                      login(result);
                    }
                    break;
                  case 300:
                    setResponseCode(300);
                    break;
                  case 301:
                    setResponseCode(301);
                    break;
                  default:
                    throw result?.data;
                }
              } else {
                throw 'error';
              }
            }
          })
          .catch((err) => {
            setLoading(false);
            setError(err);
          });
      } else {
        if (isSubscribe) {
          setError(err);
          switch (code) {
            case 300:
              setResponseCode(300);
              break;
            case 301:
              setResponseCode(301);
              break;
            default:
              throw 'error';
          }
        }
      }
    })();

    return () => {
      isSubscribe = false;
    };
  }, []);

  const redirect = (url: string) => {
    Linking.canOpenURL(url)
      .then((supported) => {
        supported && Linking.openURL(url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const login = async (result: any) => {
    setAsyncStorageData('@storage_AuthToken', result.data);
    const tokenInfo: TokenPayload = JwtDecode(result.data);

    dispatch({
      type: 'CHANGE_USER_STATUS',
      payload: {
        userInfo: {
          name: tokenInfo?.user_name,
          phoneNumber: tokenInfo?.mobile_phone,
          userId: tokenInfo?.user_id,
        },
        isLoggedIn: true,
      },
    });

    /*---------Start---------*/
    //To navigate user back to profile page after login. If the Bottom one failed,use Top (original) one.

    // if (Platform.OS === 'web') {
    //   window.history.go(-3);
    // } else {
    //   navigation.goBack();
    // }

    navigation.reset({
      index: 2,
      routes: [
        {
          name: 'Root',
          params: {
            screen: 'User',
            params: {
              screen: 'UserProfile',
            },
          },
        },
      ],
    });
    /*---------End---------*/
  };

  const handleLanguagePreferenceChange = () => {
    if (typeof preferredLanguage === 'string') {
      //Change to English
      if (preferredLanguage === 'zh-CN') {
        setAsyncStorageData('@storage_LangrageReference', 'en');
        dispatch({
          type: 'CHANGE_LANGUAGE_PREFERENCE',
          payload: 'en',
        });
        i18n.changeLanguage('en');
      } else {
        setAsyncStorageData('@storage_LangrageReference', 'zh-CN');
        dispatch({
          type: 'CHANGE_LANGUAGE_PREFERENCE',
          payload: 'zh-CN',
        });
        i18n.changeLanguage('zh-CN');
      }
    }
  };

  return (
    <TouchableWithoutFeedback
      onPress={Platform.OS === 'web' ? undefined : () => Keyboard.dismiss()}
      accessible={false}
    >
      <View style={{ flex: 1 }}>
        {loading ? (
          <Loading />
        ) : (
          <FullWidthView>
            <View style={styles.header}>
              <TouchableWithoutFeedback
                onPress={() => navigation.replace('AuthModal')}
              >
                <Ionicons
                  name="ios-arrow-back"
                  size={24}
                  color="black"
                  style={{ paddingLeft: 10 }}
                />
              </TouchableWithoutFeedback>
              <View>
                <TouchableOpacity onPress={handleLanguagePreferenceChange}>
                  <StyledText size={18}>中文/EN</StyledText>
                </TouchableOpacity>
              </View>
            </View>
            {error ? (
              <View style={styles.errorContainer}>
                <MaterialIcons
                  style={{ paddingBottom: 10 }}
                  name="error"
                  size={24}
                  color="lightgray"
                />
                <StyledText color={'lightgray'} size={16}>
                  {error}
                </StyledText>
                {Platform.OS === 'ios' && (
                  <View>
                    <StyledText align="center">
                      若显示授权失败，请彻底关闭App后重新打开登录即可
                    </StyledText>
                    <TouchableWithoutFeedback
                      onPress={() => {
                        Linking.openURL(
                          `https://support.apple.com/zh-cn/HT201330`
                        );
                      }}
                    >
                      <StyledText
                        align="center"
                        color={linkColor}
                        style={{ textDecorationLine: 'underline' }}
                      >
                        如何彻底关闭App？
                      </StyledText>
                    </TouchableWithoutFeedback>
                  </View>
                )}
              </View>
            ) : (
              <View style={[styles.formContainer, { paddingBottom: 75 }]}>
                <View style={{ width: '70%', marginBottom: 50 }}>
                  <StyledText
                    style={{ marginBottom: 10 }}
                    size={20}
                    bold
                    align={'center'}
                  >
                    {t('AuthModal.activateAccount')}
                  </StyledText>
                  <StyledText align={'center'}>
                    {t('AuthModal.activatePrompt')}
                  </StyledText>
                </View>
                <WeChatRegisterForm responseCode={responseCode} />
              </View>
            )}
          </FullWidthView>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

export default WeChatRegisterScreen;

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 75,
    alignItems: 'center',
    paddingHorizontal: 8,
  },
  formContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  errorContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    paddingBottom: 75,
  },
});
