import React, { useEffect, useState, useCallback } from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { getData } from '../../utils/service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, LocationInformation, CategoriesItem } from '../../types';
import FullWidthView from '../../components/common/FullWidthView';
import Loading from '../../components/common/Loading';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { resetWechatSharePage } from '../../utils/wechatWebMethods';
import {
  getLocalCategoryData,
  saveCategoryDataToLocal,
} from '../../utils/asyncStorage';
import NoContent from '../../components/Category/NoContent';
import Level1Menu from '../../components/Category/main/Level1Menu';
import Level2Menu from '../../components/Category/main/Level2Menu';
import { screenHit } from '../../utils/analytic';

export default function CategoriesScreen() {
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [extraMenus, setExtraMenus] = useState<Array<CategoriesItem>>([]);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const [categoryChangeState, setCategoryChangeState] =
    useState<boolean>(false);

  const location = useSelector<RootState, LocationInformation>(
    (state) => state.goods.location
  );
  const categoriesArray = useSelector<RootState, Array<CategoriesItem>>(
    (state) => state.goods.categoriesArray
  );
  const selectedMainCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedMainCategoryIndex
  );

  const [shouldDisplayCategory, setShouldDisplayCategory] = useState(false);

  useEffect(() => {
    screenHit('category-home');

    //When user focus on screen, fetch Category and reset selectedSubCategoryIndex
    // const unsubscribe = navigation.addListener('focus', () => {
    if (location.countryId !== 0) {
      setShouldDisplayCategory(true);
      setIsLoadingComplete(false);

      //Get the home page data from local storage if exist
      (async () => {
        const result = await getLocalCategoryData();

        if (result) {
          dispatch({
            type: 'UPDATE_CATEGORY_ARRAY',
            payload: JSON.parse(result),
          });
          setIsLoadingComplete(true);
        }
      })();

      //Reset selectedSubCategoryIndex
      // dispatch({ type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX', payload: 0 });

      getCategories().then((response) => {
        if (response && response.goodStatus) {
          storeFilteredResponse(response.data);
          storeExtraMenus(response.data);

          if (Platform.OS !== 'web')
            dispatch({
              type: 'UPDATE_SELECTED_MAIN_CATEGORY_INDEX',
              payload: 0,
            });
          setIsLoadingComplete(true);
          /* @ts-ignore */
          // navigation.popToTop();
        } else {
          console.error(response);
        }
      });
    } else {
      //Unknown location, no content should be display
      setIsLoadingComplete(true);
      setShouldDisplayCategory(false);
      navigation.setOptions({
        headerTitle: '',
      });
    }
    // });
    // return unsubscribe;
  }, [location]);

  // Re-fetch category when screen is focused. Mainly for the time that user changes language.
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      if (isLoadingComplete) {
        getCategories().then((response) => {
          if (response && response.goodStatus) {
            storeFilteredResponse(response.data);
          }
        });
      }
    });

    return unsubscribe;
  }, [navigation]);

  //Refresh category to get new update
  const [refreshing, setRefreshing] = useState(false);
  const onRefresh = useCallback(() => {
    setRefreshing(true);
    getCategories().then((response) => {
      if (response && response.goodStatus) {
        storeFilteredResponse(response.data);
        storeExtraMenus(response.data);

        dispatch({
          type: 'UPDATE_SELECTED_MAIN_CATEGORY_INDEX',
          payload: 0,
        });
        setIsLoadingComplete(true);
        setRefreshing(false);
      } else {
        console.error(response);
        setRefreshing(false);
      }
    });
  }, []);

  const storeFilteredResponse = (categoryList: Array<CategoriesItem>) => {
    const filteredResult = categoryList.reduce(
      (result: Array<CategoriesItem>, list: CategoriesItem) => {
        if (list.show_in_nav === 1 && list.url === undefined) {
          result.push(list);
        }
        return result;
      },
      []
    );
    saveCategoryDataToLocal(filteredResult);
    dispatch({ type: 'UPDATE_CATEGORY_ARRAY', payload: filteredResult });
  };

  const storeExtraMenus = (categoryList: Array<CategoriesItem>) => {
    //Get menu items if it is not category like bundle
    const extraMenusFromResult = categoryList.reduce(
      (result: Array<CategoriesItem>, item: CategoriesItem) => {
        if (item.show_in_nav === 1 && item.url !== undefined) {
          result.push(item);
        }

        return result;
      },
      []
    );

    setExtraMenus(extraMenusFromResult);
  };

  // Setting up Wechat share information
  React.useLayoutEffect(() => {
    const imgUrl =
      categoriesArray[selectedMainCategoryIndex]?.cat_img ?? undefined;
    const link =
      Platform.OS === 'web'
        ? `${
            window.location.href.split('?')[0]
          }?mainIndex=${selectedMainCategoryIndex}`
        : '';
    /*Because we are subscripting states from redux. We need to check if screen is focused.
      Otherwise this will be called even we are not in this screen  */
    if (Platform.OS === 'web' && isFocused)
      resetWechatSharePage(
        `${categoriesArray[selectedMainCategoryIndex]?.name}`,
        undefined,
        imgUrl,
        link
      );
    // Set up Wechat share information "again" when user come back from the other page.
    const unsubscribe = navigation.addListener('focus', () => {
      if (Platform.OS === 'web')
        resetWechatSharePage(
          `${categoriesArray[selectedMainCategoryIndex]?.name}`,
          undefined,
          imgUrl,
          link
        );
    });
    return unsubscribe;
  }, [categoriesArray, selectedMainCategoryIndex, navigation]);

  return (
    <FullWidthView edges={['left', 'right']}>
      {isLoadingComplete ? (
        shouldDisplayCategory ? (
          <>
            <View style={styles.root}>
              <Level1Menu
                extraMenus={extraMenus}
                onRefresh={onRefresh}
                refreshing={refreshing}
                setLevel1MenuChangeState={setCategoryChangeState}
              />
              <Level2Menu level1MenuChangeState={categoryChangeState} />
            </View>
          </>
        ) : (
          <NoContent />
        )
      ) : (
        <View
          style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <Loading />
        </View>
      )}
    </FullWidthView>
  );
}

//Get Categories
async function getCategories(): Promise<{
  goodStatus: boolean;
  return_code: number;
  data: Array<CategoriesItem>;
}> {
  const result = await getData('category/detail?level=2');
  return result;
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  ToolBarAndLevel1Menu: {},
  //Only app platform need the flex 1, on Web it breaks the layout
  carouselContainer: {
    flex: 1,
  },
  FilterPopUpContainer: {
    height: '50%',
  },
  root: {
    flexDirection: 'row',
    flex: 1,
  },
});
