import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { WHITE5, tintColorLight, RED3, WHITE } from '../../../constants/Colors';
import StyledButton from '../../common/StyledButton';
import StyledText from '../../common/StyledText';

type OfferHeaderProps = {
  metCondition: boolean;
  info: {
    title: string;
    actType: string;
    maximumGifts: number;
    currentAmount: number;
  };
};

const OfferHeader = ({ info, metCondition = false }: OfferHeaderProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const handleGoShopping = () => {
    navigation.reset({
      index: 0,
      routes: [
        {
          name: 'Root',
          params: {
            screen: 'Category',
          },
        },
      ],
    });
  };

  return (
    <View style={styles.cardShadow}>
      <View style={styles.cardContainer}>
        <View
          style={[
            styles.cardHeader,
            {
              backgroundColor: metCondition ? tintColorLight : 'lightgray',
            },
          ]}
        >
          <StyledText bold size={18} color="white">
            {`${info.actType} - ${info.title}`}
          </StyledText>
        </View>
        {metCondition ? (
          <View style={styles.container}>
            <View style={styles.progressBarContainer}>
              <View
                style={[
                  styles.progressBar,
                  {
                    width: `${(info.currentAmount / info.maximumGifts) * 100}%`,
                  },
                ]}
              ></View>
            </View>
            <View>
              <StyledText>{`${info.currentAmount}/${info.maximumGifts}`}</StyledText>
            </View>
          </View>
        ) : (
          <View style={styles.container}>
            <StyledText color={RED3}>
              {t('specialOfferScreen.notMetCondition')}
            </StyledText>
            <StyledButton
              label={t('specialOfferScreen.goShopping')}
              buttonSize="small"
              onPress={handleGoShopping}
            />
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardShadow: {
    borderRadius: 5,
    backgroundColor: 'transparent',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  cardContainer: {
    backgroundColor: WHITE,
    borderRadius: 5,
    overflow: 'hidden',
    margin: 10,
  },
  cardHeader: {
    padding: 5,
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    padding: 5,
  },
  progressBarContainer: {
    backgroundColor: WHITE5,
    borderRadius: 5,
    width: '90%',
    height: 10,
    position: 'relative',
    overflow: 'hidden',
  },
  progressBar: {
    backgroundColor: tintColorLight,
    height: 10,
    width: 0,
  },
});

export default OfferHeader;
