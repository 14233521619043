import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { AuthParamList } from '../types';

import AuthModal from '../components/Modal/AuthModal';
import ForgotPasswordScreen from '../screens/Auth/ForgotPasswordScreen';
import WeChatRegisterScreen from '../screens/Auth/WeChatRegisterScreen';
import RegistrationApprovalScreen from '../screens/Auth/RegistrationApprovalScreen';

const AuthStack = createStackNavigator<AuthParamList>();

const AuthNavigator = () => {
  return (
    <AuthStack.Navigator
      initialRouteName="AuthModal"
      screenOptions={{
        headerShown: false,
      }}
    >
      <AuthStack.Screen name="AuthModal" component={AuthModal} />
      <AuthStack.Screen
        name="ForgotPasswordScreen"
        component={ForgotPasswordScreen}
      />
      <AuthStack.Screen
        name="WeChatRegisterScreen"
        component={WeChatRegisterScreen}
      />
      <AuthStack.Screen
        name="RegistrationApprovalScreen"
        component={RegistrationApprovalScreen}
      />
    </AuthStack.Navigator>
  );
};

export default AuthNavigator;
