import React from 'react';
import { TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import { ProductInformation } from '../../types';

type AddToCartBtnProp = {
  children: React.ReactNode;
  goodsId: number;
  goodsData?: ProductInformation;
  disabled?: boolean;
  isPresale?: boolean;
};

//This Component doesn't has style, use the children component as the UI.
const AddToCartBtn = ({
  children,
  goodsId,
  goodsData,
  disabled,
  isPresale = false,
}: AddToCartBtnProp) => {
  const dispatch = useDispatch();

  const handleAddToCartPress = () => {
    dispatch({ type: 'TRIGGER_PRODUCT_OPTION_WINDOW', payload: true });
    dispatch({ type: 'UPDATE_SELECTED_PRODUCT', payload: goodsData });
    dispatch({ type: 'SET_IS_PRESALE_PRODUCT', payload: isPresale });
  };

  return (
    <TouchableOpacity disabled={disabled} onPress={handleAddToCartPress}>
      {children}
    </TouchableOpacity>
  );
};

export default AddToCartBtn;
