import React, { useEffect, useRef } from 'react';
/** @ts-ignore */
import MapView, { PROVIDER_GOOGLE, LatLng } from 'react-native-web-maps';
import { View, StyleSheet, Dimensions } from 'react-native';
import { accentColor } from '../../../constants/Colors';
import { styleGreen } from '../../../constants/MapStyle';
import { useHeaderHeight } from '@react-navigation/stack';
import { useUpdateEffect } from 'ahooks';

type GoogleMapViewProps = {
  latitude: number | undefined;
  longitude: number | undefined;
  showDriver?: {
    lat: number;
    lng: number;
  };
};

const GoogleMapView = ({
  latitude,
  longitude,
  showDriver,
}: GoogleMapViewProps) => {
  const mapViewRef = useRef<any>();

  useEffect(() => {
    if (latitude && longitude) {
      const newLocation = {
        center: {
          lat: latitude,
          lng: longitude,
        },
        pitch: 0,
        heading: 0,
        //  // Only on iOS MapKit, in meters. The property is ignored by Google Maps.
        altitude: 1000,

        // Only when using Google Maps.
        zoom: 14,
      };

      setTimeout(() => {
        if (!(latitude === 49.2827291 && longitude === -123.1207375)) {
          if (mapViewRef.current) {
            mapViewRef.current.animateCamera(newLocation);
          }
        }
      }, 1000);
    }
  }, [latitude, longitude]);

  useUpdateEffect(() => {
    if (showDriver !== undefined) {
      if (mapViewRef.current) {
        setTimeout(() => {
          mapViewRef.current.animateCamera({
            latitude: showDriver.lat,
            longitude: showDriver.lng,
          });
        }, 500);
      }
    }
  }, [showDriver]);

  const markerLatLng: LatLng = {
    latitude: latitude,
    longitude: longitude,
  };

  const headerHeight = useHeaderHeight();

  return (
    <View style={styles.container}>
      <MapView
        initialRegion={{
          latitude: 49.2827291,
          longitude: -123.1207375,
          latitudeDelta: 0.1,
          longitudeDelta: 0.1,
        }}
        ref={mapViewRef}
        provider={PROVIDER_GOOGLE}
        style={[styles.mapStyle, { height: Dimensions.get('window').height }]}
        options={{
          zoomControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          // styles: styleGreen,
        }}
      >
        <MapView.Marker
          coordinate={markerLatLng}
          icon={
            'https://driversystem.s3-us-west-2.amazonaws.com/default/destination.png'
          }
        />

        {showDriver !== undefined && (
          <MapView.Marker
            coordinate={{ latitude: showDriver.lat, longitude: showDriver.lng }}
            icon={
              'https://driversystem.s3-us-west-2.amazonaws.com/default/driver.png'
            }
          />
        )}
      </MapView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  mapStyle: {
    width: Dimensions.get('window').width,
  },
  homeMarker: {
    backgroundColor: accentColor,
    padding: 10,
    borderRadius: 50,
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default GoogleMapView;
