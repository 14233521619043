import React from 'react';
import { View, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import StyledText from '../../common/StyledText';
import { Octicons, AntDesign } from '@expo/vector-icons';
import { accentColor } from '../../../constants/Colors';
import { CreditCardInList } from '../../../types';

type CreditCardProps = {
  card: CreditCardInList;
  deleteCard: Function;
  setDefault: Function;
};

const CreditCard = ({ card, deleteCard, setDefault }: CreditCardProps) => {
  const { credit_card, expiry_year, expiry_month, is_default, id } = card;

  const _renderNumber = () => (
    <View style={styles.numberContainer}>
      {Array.from(Array(3)).map((item, index) => (
        <View key={index} style={styles.numberDots}>
          {Array.from(Array(4)).map((item, index) => (
            <Octicons
              key={index}
              name="dot-fill"
              style={{ paddingHorizontal: 2 }}
              size={10}
              color="white"
            />
          ))}
        </View>
      ))}
      <StyledText bold color={'white'}>
        {credit_card.substring(credit_card.length - 4, credit_card.length)}
      </StyledText>
    </View>
  );

  const handleDelete = () => {
    deleteCard(id);
  };

  const handleSetDefault = () => {
    setDefault(id);
  };

  return (
    <View style={styles.cardContainer}>
      <View style={styles.cardInfo}>
        {_renderNumber()}
        <View style={styles.expiryContainer}>
          <StyledText color={'white'}>
            {expiry_month}/{expiry_year}
          </StyledText>
        </View>
      </View>
      <View style={styles.buttonContainer}>
        {is_default === 1 ? (
          <AntDesign name="star" size={22} color="yellow" />
        ) : (
          <TouchableOpacity onPress={() => handleSetDefault()}>
            <AntDesign name="staro" size={22} color="white" />
          </TouchableOpacity>
        )}
        <TouchableOpacity onPress={() => handleDelete()}>
          <AntDesign name="delete" size={22} color="white" />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: 'row',
    paddingHorizontal: 25,
    paddingVertical: 10,
    borderRadius: 10,
    backgroundColor: accentColor,
  },
  cardInfo: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    width: '20%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  brandContainer: {
    marginVertical: 5,
    flexDirection: 'row',
  },
  numberContainer: {
    marginVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  expiryContainer: {
    marginVertical: 5,
    flexDirection: 'row',
  },
  numberDots: {
    flexDirection: 'row',
    marginRight: 20,
  },
});

export default CreditCard;
