import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useRef } from 'react';
import {
  FlatList,
  RefreshControl,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { backGroundLight, tintColorLight } from '../../../constants/Colors';
import { CategoriesItem, RootState } from '../../../types';
import { screenHit } from '../../../utils/analytic';
import StyledText from '../../common/StyledText';

type Level1MenuProps = {
  extraMenus?: Array<CategoriesItem>;
  onRefresh: () => void;
  refreshing: boolean;
  menuType?: 'regular' | 'groupsale';
  setLevel1MenuChangeState:React.Dispatch<React.SetStateAction<boolean>>;
};

const Level1Menu = ({
  extraMenus = [],
  refreshing = false,
  onRefresh,
  menuType = 'regular',
  setLevel1MenuChangeState
}: Level1MenuProps) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const categoriesArray = useSelector<RootState, Array<CategoriesItem>>(
    (state) => state.goods.categoriesArray
  );
  const selectedMainCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedMainCategoryIndex
  );

  const groupSaleCategoriesArray = useSelector<
    RootState,
    Array<CategoriesItem>
  >((state) => state.goods.groupSaleCategoriesArray);
  
  const selectedGroupSaleCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedGroupSaleCategoryIndex
  );

  const menuRef = useRef<FlatList>(null);

  useEffect(() => {
    if (menuRef && menuRef.current) {
      menuRef.current.scrollToIndex({
        animated: true,
        index: selectedMainCategoryIndex,
        viewPosition: 0.5,
      });
    }
  }, [selectedMainCategoryIndex]);

  const handleLevel1MenuPress = (item: CategoriesItem, index: number) => {
    screenHit(`category/${item.id}`);
    if (menuType === 'regular') {
      dispatch({
        type: 'UPDATE_SELECTED_MAIN_CATEGORY_INDEX',
        payload: index,
      });

      if (item.haschild === 0 && item.cat_id === undefined) {
        dispatch({ type: 'UPDATE_SUBCATEGORY_LENGTH', payload: 0 });
      }
    } else if (menuType === 'groupsale') {
      dispatch({
        type: 'UPDATE_SELECTED_GROUPSALE_MAIN_CATEGORY_INDEX',
        payload: index,
      });
    }
  };

  const appMainColor = useSelector<RootState, string>(
    (state) => state.user.appMainColor
  );

  const renderItem = ({
    item,
    index,
  }: {
    item: CategoriesItem;
    index: number;
  }) => {
    const targetIndex =
      menuType === 'regular'
        ? selectedMainCategoryIndex
        : selectedGroupSaleCategoryIndex;

    return item.show_in_nav === 1 ? (
      <TouchableOpacity onPress={() => {setLevel1MenuChangeState(prev=>!prev); handleLevel1MenuPress(item, index);}}>
        {targetIndex === index ? (
          <View
            style={[
              styles.listItemContainer,
              styles.selectedSubCategoryIndex,
              { borderRightColor: appMainColor },
            ]}
          >
            <StyledText align="center" style={styles.listItemText}>
              {item.name}
            </StyledText>
          </View>
        ) : (
          <View style={styles.listItemContainer}>
            <StyledText align="center" style={styles.listItemText}>
              {item.name}
            </StyledText>
          </View>
        )}
      </TouchableOpacity>
    ) : (
      <></>
    );
  };

  const renderFooter = extraMenus.map((item, index) => {
    const handleItemPress = () => {
      if (item.url && item.url.url_type === 'inner') {
        switch (item.url.type) {
          case 'package':
            navigation.navigate('ProductBundle');
            break;
          case 'category':
            navigation.navigate('SubCategory', {
              catId: item.url.id,
            });
            break;
          default:
            break;
        }
      }
    };
    return (
      <TouchableOpacity key={index} onPress={handleItemPress}>
        <View style={styles.listItemContainer}>
          <View style={styles.extraMenuItem}>
            <AntDesign name="star" size={14} color="#fdba52" />
            <StyledText align="center" style={styles.listItemText}>
              {item.name}
            </StyledText>
          </View>
        </View>
      </TouchableOpacity>
    );
  });

  const dataSource =
    menuType === 'regular' ? categoriesArray : groupSaleCategoriesArray;
  const extraData =
    menuType === 'regular'
      ? selectedMainCategoryIndex
      : selectedGroupSaleCategoryIndex;

  return (
    <View style={styles.container}>
      <FlatList
        ref={menuRef}
        data={dataSource}
        renderItem={renderItem}
        keyExtractor={(item) => item.id.toString()}
        showsVerticalScrollIndicator={false}
        ListFooterComponent={<>{renderFooter}</>}
        extraData={extraData}
        refreshControl={
          <RefreshControl
            colors={[tintColorLight]}
            tintColor={tintColorLight}
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
        }
        onScrollToIndexFailed={() =>
          dispatch({
            type: 'UPDATE_SELECTED_MAIN_CATEGORY_INDEX',
            payload: 0,
          })
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '25%',
    backgroundColor: backGroundLight,
  },
  listItemContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingVertical: 15,
  },
  selectedSubCategoryIndex: {
    backgroundColor: 'white',
    borderRightWidth: 3,
  },
  listItemText: {
    // paddingVertical: 15,
  },
  extraMenuItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default Level1Menu;
