import React from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import BackButton from '../common/iconButtons/BackButton';
import { useNavigation } from '@react-navigation/native';

const ThemeHomeScreenBackBtn = () => {
  const navigation = useNavigation();

  const handlePress = () => {
    navigation.replace('Home');
    if(Platform.OS === 'web'){
      navigation.replace('Home');
    }else{
      if(navigation.canGoBack()){
        navigation.goBack()
      }else{
        navigation.replace('Home')
      }
    }
  };

  return (
    <TouchableOpacity onPress={handlePress}>
      <BackButton />
    </TouchableOpacity>
  );
};

export default ThemeHomeScreenBackBtn;
