//For web, main color need to be changed manually
export let tintColorLight = '#2cae35';
export const backGroundLight = '#f2f3f7';
export const normalTextColor = '#333333';
export const subTextColor = '#878484';
export const accentColor = '#f57a4c';
export const subColorLight = '#94c515';
export const warningRed = '#eb6565';
export const successGreen = '#2cae35';
export const linkColor = '#4285F4';
export const subBlueColor = '#4695f7';
export const subLightBlueColor = '#e3f0fd';

const tintColorDark = '#fff';

export const GREY1 = '#D3D3D3';
export const GREY2 = '#878484';
export const GREY3 = '#333333';
export const ORANGE1 = '#f57a4c';
export const RED1 = '#ff0000';
export const RED2 = '#FF231F7C';
export const RED3 = '#eb6565';
export const GREEN1 = '#2cae35';
export const GREEN2 = '#2cae66';
export const GREEN3 = '#07C160';
export const GREEN4 = '#94c515';
export const GREEN5 = '#2cae3510';
export const WHITE = '#FFFF';
export const WHITE2 = '#d3d3d3';
export const WHITE3 = '#f2f3f5';
export const WHITE4 = '#fde8e0';
export const WHITE5 = '#f2f3f7';
export const BLUE1 = '#2e78b7';
export const BLUE2 = '#1890ff';
export const BLUE3 = '#4285F4';
export const TURQUOISE1 = '#40E0D0';

// GrandIntl main color
export const GRAND_INTL_PRIMARY1 = '#2971a2';
export const GRAND_INTL_PRIMARY2 = '#e5832f';

export const changeMainColor = (color: string) => {
  tintColorLight = color;
};

export default {
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
  },
  //Fake dark mode -- delete later
  dark: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
  },
  // dark: {
  //   text: '#fff',
  //   background: '#000',
  //   tint: tintColorDark,
  //   tabIconDefault: '#ccc',
  //   tabIconSelected: tintColorDark,
  // },
};
