import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { SettingsParamList } from '../types';

import UserSettingScreen from '../screens/Settings/UserSettingScreen';
import { useTranslation } from 'react-i18next';
import LanguageSetting from '../screens/Settings/LanguageSetting';
import BackButton from '../components/common/iconButtons/BackButton';
import AboutLuniuScreen from '../screens/Settings/aboutUs/AboutLuniuScreen';
import SettingWebViewScreen from '../screens/Settings/SettingWebViewScreen';
import NavigateButton from '../components/common/NavigateButton';
import NotificationSettingScreen from '../screens/Settings/NotificationSettingScreen';
import getAppStyle from '../utils/getAppStyle';
import FontSetting from '../screens/Settings/FontSetting';

const SettingsStack = createStackNavigator<SettingsParamList>();

const SettingsNavigator = () => {
  const { t } = useTranslation();

  return (
    <SettingsStack.Navigator
      initialRouteName="SettingsMainScreen"
      screenOptions={{
        headerBackTitleVisible: false,
        headerTitleAlign: 'center',
        headerLeft: () => (
          <NavigateButton goBack>
            <BackButton />
          </NavigateButton>
        ),
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <SettingsStack.Screen
        name="SettingsMainScreen"
        component={UserSettingScreen}
        options={{
          headerTitle: t('userSettingScreen.headerTitle'),
        }}
      />
      <SettingsStack.Screen
        name="LanguageSettingScreen"
        component={LanguageSetting}
        options={{
          headerTitle: t('userSettingScreen.subScreens.languageSetting'),
        }}
      />
      <SettingsStack.Screen
        name="FontSettingScreen"
        component={FontSetting}
        options={{
          headerTitle: t('userSettingScreen.fontScreen.title'),
        }}
      />
      <SettingsStack.Screen
        name="NotificationSettingScreen"
        component={NotificationSettingScreen}
        options={{
          headerTitle: t('userSettingScreen.notificationScreen.title'),
        }}
      />
      <SettingsStack.Screen
        name="AboutLuniu"
        component={AboutLuniuScreen}
        options={{
          headerTitle: t(
            `userSettingScreen.${
              getAppStyle(process.env.EXPO_APP_TYPE)?.about
            }.title`
          ),
        }}
      />
      <SettingsStack.Screen
        name="SettingWebView"
        component={SettingWebViewScreen}
        options={{
          headerTitle: '',
        }}
      />
    </SettingsStack.Navigator>
  );
};

export default SettingsNavigator;
