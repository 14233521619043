import React, { useState } from 'react';
import { View, TextInput, StyleSheet, Platform } from 'react-native';
import StyledText from '../../common/StyledText';
import ListOption from '../../common/list/ListOption';
import { GREY3, GREY2 } from '../../../constants/Colors';
import StyledButton from '../../common/StyledButton';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-tiny-toast';
import { useNavigation } from '@react-navigation/native';
import { Formik } from 'formik';
import ErrorMessage from '../../Auth/ErrorMessage';
import { postWithAuthToken } from '../../../utils/service';
import { setAsyncStorageData } from '../../../utils/asyncStorage';
import { useDispatch } from 'react-redux';
import SendCodeButton from '../../Auth/SendCodeButton';
import { TokenPayload } from '../../../types';
import jwtDecode from 'jwt-decode';

type PhoneEditFormParams = {
  value: string;
};

const PhoneEditForm = ({ value }: PhoneEditFormParams) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const changePhone = (password: string, phone: string, code: string) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      const loading = Toast.showLoading('');
      postWithAuthToken('user/phone/edit', {
        old_password: password,
        mobile_phone: phone,
        code,
      })
        .then((result) => {
          Toast.hide(loading);
          if (result && result.goodStatus) {
            setIsSubmitting(false);
            setAsyncStorageData('@storage_AuthToken', result.data);
            const tokenInfo: TokenPayload = jwtDecode(result.data);
            dispatch({
              type: 'CHANGE_USER_STATUS',
              payload: {
                userInfo: {
                  name: tokenInfo.user_name,
                  phoneNumber: tokenInfo.mobile_phone,
                  userId: tokenInfo.user_id,
                },
                isLoggedIn: true,
              },
            });
            Toast.showSuccess(t('userInfoEditScreen.updateSuccessfully'));
            navigation.navigate('UserInfo', { newPhone: phone });
          } else {
            throw result ? result?.data : 'Error';
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
          if (loading) Toast.hide(loading);
          Toast.show(err, {
            position: 0,
            duration: 2000,
          });
        });
    }
  };

  return (
    <Formik
      initialValues={{
        password: '',
        phone: value,
        code: '',
      }}
      onSubmit={(values) =>
        changePhone(values.password, values.phone, values.code)
      }
    >
      {({ handleChange, handleSubmit, values, errors, touched }) => (
        <View style={styles.rootContainer}>
          <View>
            <ListOption>
              <View style={styles.formContainer}>
                <StyledText style={styles.fieldTitle}>
                  {t('userInfoScreen.changePhoneOption.password')}
                </StyledText>
                <View style={styles.inputContainer}>
                  <TextInput
                    placeholder={'******'}
                    placeholderTextColor={GREY2}
                    style={styles.inputField}
                    value={values.password}
                    secureTextEntry
                    onChangeText={handleChange('password')}
                  />
                </View>
              </View>
              <View style={styles.errorMsgContainer}>
                {errors.password && touched.password && (
                  <ErrorMessage label={errors.password} />
                )}
              </View>
            </ListOption>

            <ListOption>
              <View style={styles.formContainer}>
                <StyledText style={styles.fieldTitle}>
                  {t('userInfoScreen.changePhoneOption.code')}
                </StyledText>
                <View style={styles.sendCodeInputContainer}>
                  <TextInput
                    keyboardType={'number-pad'}
                    placeholder={t(
                      'userInfoScreen.changePhoneOption.codePlaceholder'
                    )}
                    style={styles.inputField}
                    value={values.code}
                    onChangeText={handleChange('code')}
                    maxLength={6}
                  />
                  <SendCodeButton value={values.phone} type={'phone'} />
                </View>
              </View>
              <View style={styles.errorMsgContainer}>
                {errors.code && touched.code && (
                  <ErrorMessage label={errors.code} />
                )}
              </View>
            </ListOption>
          </View>
          <View style={styles.buttonContainer}>
            <StyledButton
              disabled={isSubmitting}
              label={t('userInfoScreen.changePhoneOption.changePhoneButton')}
              onPress={handleSubmit}
            />
          </View>
        </View>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  formContainer: {
    flexDirection: 'column',
  },
  fieldTitle: {
    height: 30,
  },
  inputContainer: {
    height: Platform.OS === 'android' ? 40 : 20,
  },
  sendCodeInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputField: {
    color: GREY3,
    flex: 1,
    fontSize: 14,
  },
  buttonContainer: {
    marginBottom: 30,
  },
  errorMsgContainer: {
    height: 20,
  },
});

export default PhoneEditForm;
