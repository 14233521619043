import React from 'react';
import { View, StyleSheet, Dimensions, Platform } from 'react-native';
import StyledText from '../common/StyledText';
import FakeSearchBar from '../common/FakeSearchBar';
import NavigateButton from '../common/NavigateButton';
import { AntDesign } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import { RootState, LocationInformation } from '../../types';
import { useTranslation } from 'react-i18next';

const { width } = Dimensions.get('window');

/**
 * Home screen header
 */
const Header = () => {
  const { t } = useTranslation();

  const location = useSelector<RootState, LocationInformation>(
    (state) => state.goods.location
  );

  const appMainColor = useSelector<RootState, string>(
    (state) => state.user.appMainColor
  );

  return (
    <View style={[styles.header, { backgroundColor: appMainColor }]}>
      <View style={styles.areaSelector}>
        <NavigateButton
          route="Modal"
          options={{ screen: 'UserLocationModal' }}
          style={styles.regionButtonStyle}
        >
          <AntDesign name="enviromento" size={20} color="white" />
          <StyledText
            color="white"
            size={16}
            style={styles.buttonText}
            numberOfLines={1}
          >
            {location.areaName}
          </StyledText>
          <AntDesign
            name="caretdown"
            size={12}
            color="white"
            style={{ marginLeft: 0 }}
          />
        </NavigateButton>
        <NavigateButton
          route="LanguageSettingScreen"
          style={styles.languageButtonStyle}
        >
          <AntDesign
            style={{ marginTop: 5 }}
            name="earth"
            size={20}
            color="white"
          />
        </NavigateButton>
      </View>
      <View style={styles.searchBarContainer}>
        <FakeSearchBar width="100%" />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    width: width,
    paddingTop: Platform.OS === 'android' ? 30 : 0,
    padding: 10,
  },
  areaSelector: {
    width: '100%',
    paddingBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  regionButtonStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    flexBasis: 200,
  },
  languageButtonStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: 40,
    paddingLeft: 10,
  },
  buttonText: {
    marginHorizontal: 6,
    maxWidth: '80%',
  },
  searchBarContainer: {
    width: '100%',
  },
});

export default Header;
