import React from 'react';
import { normalTextColor } from '../../../constants/Colors';
import { AntDesign } from '@expo/vector-icons';
import NavigateButton from '../../common/NavigateButton';

const AddAddressBtn = () => {
  return (
    <NavigateButton loginRequired route='NewAddress'>
      <AntDesign
        name='plus'
        size={26}
        color={normalTextColor}
        style={{ marginRight: 5 }}
      />
    </NavigateButton>
  );
};

export default AddAddressBtn;
