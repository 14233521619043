import * as React from 'react';
import { StatusBar } from 'expo-status-bar';
import { useIsFocused } from '@react-navigation/native';

/**
 * For changing StatusBar style, use it in FullWidthView component
 * @param style "dark" | "light" | "auto" | "inverted" | undefined
 */
const FocusAwareStatusBar = ({
  style,
}: {
  style: 'dark' | 'light' | 'auto' | 'inverted' | undefined;
}) => {
  const isFocused = useIsFocused();

  return isFocused ? <StatusBar style={style} /> : null;
};

export default FocusAwareStatusBar;
