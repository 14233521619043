import React, { useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { HomeParamList, RootState } from '../types';

import HomeScreen from '../screens/Home/HomeScreen';
//Home Screen
import HomeProductListScreen from '../screens/Home/HomeProductListScreen';
import BackButton from '../components/common/iconButtons/BackButton';
import { useSelector } from 'react-redux';
import RegionScreen from '../screens/Home/RegionScreen';
import { useTranslation } from 'react-i18next';
import {
  useRoute,
  useNavigation,
  getFocusedRouteNameFromRoute,
} from '@react-navigation/native';
import HomeWebviewScreen from '../screens/Home/HomeWebviewScreen';
import { Linking } from 'react-native';
import { navigateBasedOnRoute } from '../utils/linkingRoute';
import NavigateButton from '../components/common/NavigateButton';
import ThemeHomeScreen from '../screens/Home/ThemeHomeScreen';
import FakeSearchBar from '../components/common/FakeSearchBar';
import ThemeHomeScreenBackBtn from '../components/ThemeHomeScreen/ThemeHomeScreenBackBtn';
import ProductBundleNavigator from './ProductBundleNavigator';
import SearchNavigator from './SearchNavigator';
import BrandNavigator from './BrandNavigator';
import ArticleNavigator from './User/ArticleNavigator';
import LanguageSetting from '../screens/Settings/LanguageSetting';
import { AntDesign } from '@expo/vector-icons';
import getAppStyle from '../utils/getAppStyle';

const HomeStack = createStackNavigator<HomeParamList>();

const HomeNavigator = () => {
  const { t } = useTranslation();

  const selectedHomeCategoryName = useSelector<RootState, string>(
    (state) => state.goods.selectedHomeCategoryName
  );
  const navigation = useNavigation();
  const route = useRoute();
  //Hide Tab bar when route is not Home
  useEffect(() => {
    const activeRouteName = getFocusedRouteNameFromRoute(route);

    //Show tab bar in Bundle screen / Topic home screen
    switch (activeRouteName) {
      case undefined:
      case 'ProductBundle':
      case 'Home':
      case 'ThemeHomeScreen':
        navigation.setOptions({
          tabBarVisible: true,
        });
        break;
      default:
        navigation.setOptions({
          tabBarVisible: process.env.EXPO_APP_TYPE === 'grandIntl',
        });
        break;
      // }
    }

    return () =>
      navigation.setOptions({
        tabBarVisible: true,
      });
  }, [route]);

  useEffect(() => {
    (async () => {
      const link = await Linking.getInitialURL();
      if (link) {
        const result = navigateBasedOnRoute(link);
        if (result && result.route) {
          navigation.navigate(result.route, result.options);
        }
      }
    })();

    Linking.addEventListener('url', handleGetShareLink);

    return () => Linking.removeEventListener('url', handleGetShareLink);
  }, []);

  const handleGetShareLink = (event: { url: string }) => {
    if (!event.url.includes('alipay')) {
      const result = navigateBasedOnRoute(event.url);
      if (result && result.route) {
        navigation.navigate(result.route, result.options);
      }
    }
  };

  return (
    <HomeStack.Navigator
      headerMode="screen"
      initialRouteName="Home"
      screenOptions={{
        headerBackTitleVisible: false,
        headerTitleAlign: 'center',
        headerLeft: () => (
          <NavigateButton goBack>
            <BackButton />
          </NavigateButton>
        ),
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <HomeStack.Screen
        name="Home"
        component={HomeScreen}
        options={{
          headerShown: false,
          animationTypeForReplace: 'pop',
        }}
      />
      <HomeStack.Screen
        name="ThemeHomeScreen"
        component={ThemeHomeScreen}
        options={{
          headerTitle: () => <FakeSearchBar />,
          headerLeft: () => <ThemeHomeScreenBackBtn />,
        }}
      />
      <HomeStack.Screen
        name="ProductList"
        component={HomeProductListScreen}
        options={{
          headerTitle: selectedHomeCategoryName || '',
        }}
      />
      <HomeStack.Screen
        name="Region"
        component={RegionScreen}
        options={{
          headerTitle: t('homeScreen.selectRegion'),
        }}
      />
      <HomeStack.Screen
        name="HomeWebviewScreen"
        component={HomeWebviewScreen}
        options={({ route }: { route: any }) => ({
          title: route.params.title || '',
          headerLeft: () => <></>,
          headerRight: () => (
            <NavigateButton goBack>
              <AntDesign name="close" size={30} />
            </NavigateButton>
          ),
        })}
      />
      <HomeStack.Screen
        name="ProductBundle"
        component={ProductBundleNavigator}
        options={{ headerShown: false }}
      />
      <HomeStack.Screen
        name="SearchNavigator"
        component={SearchNavigator}
        options={{ headerShown: false }}
      />
      <HomeStack.Screen
        name="Brand"
        component={BrandNavigator}
        options={{ headerShown: false }}
      />
      <HomeStack.Screen
        name="Articles"
        component={ArticleNavigator}
        options={{ headerShown: false }}
      />
      <HomeStack.Screen
        name="LanguageSettingScreen"
        component={LanguageSetting}
        options={{
          headerTitle: t('userSettingScreen.subScreens.languageSetting'),
        }}
      />
    </HomeStack.Navigator>
  );
};

export default HomeNavigator;
