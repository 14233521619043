import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import StyledText from '../common/StyledText';
import ResponsiveImg from '../common/ResponsiveImg';
import { backGroundLight, subTextColor } from '../../constants/Colors';
import { useTranslation } from 'react-i18next';
import { AntDesign } from '@expo/vector-icons';
import ActivityHeader from './ActivityHeader';
import { CartActivity } from '../../types';

type ShopHeader = {
  shopName: string;
  totalNumber: number;
  logo: string;
  shippingName: string;
  showAllItems: boolean;
  triggerCollapseView: React.Dispatch<React.SetStateAction<boolean[]>>;
  sectionIndex: number;
  itemCounter: number;
  bestTime?: string | null;
  activityList?: {
    current?: CartActivity;
    next_activity?: CartActivity;
  } | null;
};

const ShopHeader = ({
  shopName,
  totalNumber,
  logo,
  shippingName,
  showAllItems,
  triggerCollapseView,
  sectionIndex,
  itemCounter,
  bestTime = '',
  activityList = null,
}: ShopHeader) => {
  const { t } = useTranslation();

  const handleTriggerCollapseView = () => {
    triggerCollapseView((prev) =>
      prev.map((item, index) => (index === sectionIndex ? !item : item))
    );
  };

  return (
    <View style={styles.headerContainer}>
      {bestTime && (
        <View style={styles.deliveryTime}>
          <StyledText bold>
            <AntDesign name="calendar" size={16} color="lightgray" />
            {t('bundleScreen.bundleDetail.deliveryDate') + bestTime}
          </StyledText>
        </View>
      )}

      <View style={styles.leftContainer}>
        <ResponsiveImg uri={logo} width={40} />
        <View style={styles.shopName}>
          <StyledText bold>{shopName}</StyledText>
          <StyledText color={subTextColor}>{shippingName}</StyledText>
        </View>
      </View>
      <View style={styles.rightContainer}>
        <StyledText>
          {totalNumber}
          {t('cartScreen.header.items') + ' '}
        </StyledText>
        {itemCounter >= 4 && (
          <TouchableOpacity onPress={handleTriggerCollapseView}>
            <AntDesign
              name={!showAllItems ? 'downcircleo' : 'upcircleo'}
              size={16}
              color="lightgray"
            />
          </TouchableOpacity>
        )}
      </View>
      {activityList && <ActivityHeader activityList={activityList} />}
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    backgroundColor: 'white',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 10,
    alignItems: 'flex-start',
    borderBottomColor: backGroundLight,
    borderBottomWidth: 1,
  },
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  deliveryTime: {
    width: '100%',
    paddingBottom: 5,
  },
  shopName: {
    marginLeft: 5,
  },
});

export default ShopHeader;
