import React, { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity, TextInput } from 'react-native';
import StyledText from './StyledText';
import { AntDesign } from '@expo/vector-icons';
import { tintColorLight } from '../../constants/Colors';
import { getRegionWithPostalCode } from '../../utils/service';
import { useTranslation } from 'react-i18next';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';

type PostalCodeInformation = {
  country_id: number;
  province_id: number;
  city_id: number;
  district_id: number;
  street_id: number;
  country_name: string;
  province_name: string;
  city_name: string;
  district_name: string;
  postal_code_prefix: string;
  full_region_name: string;
};

type PostalCodeSearchProps = {
  handleRegionChange: Function;
};

const PostalCodeSearch = ({ handleRegionChange }: PostalCodeSearchProps) => {
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] =
    useState<PostalCodeInformation | null>(null);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    if (searchText.length === 3) {
      handleSubmit();
    } else {
      setSearchResult(null);
    }
  }, [searchText]);

  const handleSubmit = () => {
    Toast.hide();
    const loading = Toast.showLoading('');
    setLoading(true);
    getRegionWithPostalCode(searchText)
      .then((response) => {
        if (response && response.goodStatus) {
          setSearchResult(response.data);
        } else {
          setSearchResult(null);
        }
        Toast.hide(loading);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        Toast.hide(loading);
      });
  };

  return (
    <View>
      <View style={[styles.container]}>
        <AntDesign
          name="search1"
          size={20}
          color="#acacac"
          style={{ flexShrink: 1 }}
        />
        <TextInput
          style={{ padding: 5, flexGrow: 1 }}
          placeholder={t('creditCard.postalCode')}
          value={searchText}
          onChangeText={(text) => setSearchText(text)}
          autoCapitalize={'characters'}
          autoCompleteType={'postal-code'}
          maxLength={3}
          textAlign={'center'}
          textContentType={'postalCode'}
        />
        <View
          style={[
            styles.button,
            {
              backgroundColor:
                searchText.length === 3 ? tintColorLight : 'lightgrey',
            },
          ]}
        >
          <TouchableOpacity
            onPress={handleSubmit}
            disabled={searchText.length !== 3}
          >
            <StyledText color={'white'}>{t('regionScreen.confirm')}</StyledText>
          </TouchableOpacity>
        </View>
      </View>
      {searchText.length === 3 && !loading && (
        <TouchableOpacity
          style={styles.resultsContainer}
          disabled={searchResult ? false : true}
          onPress={() => handleRegionChange(searchResult?.postal_code_prefix)}
        >
          {searchResult ? (
            <StyledText align={'center'}>
              {searchResult.full_region_name}
            </StyledText>
          ) : (
            <StyledText align={'center'}>
              {t('regionScreen.postalCodeNotCovered')}
            </StyledText>
          )}
        </TouchableOpacity>
      )}
    </View>
  );
};

export default PostalCodeSearch;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f2f3f5',
    borderRadius: 25,
    padding: 5,
    paddingHorizontal: 15,
  },
  resultsContainer: {
    padding: 10,
    paddingVertical: 10,
    backgroundColor: '#f2f3f5',
    marginHorizontal: 15,
    marginTop: 10,
    borderRadius: 10,
  },
  button: {
    padding: 8,
    paddingVertical: 5,
    color: '#FFFFFF',
    borderRadius: 12,
    flexWrap: 'nowrap',
  },
  inputContainer: {
    borderRadius: 40,
    alignItems: 'center',
    height: 20,
    backgroundColor: '#f2f3f5',
    paddingHorizontal: 10,
  },
});
