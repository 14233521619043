import { Platform } from 'react-native';
import {
  accentColor,
  GRAND_INTL_PRIMARY1,
  GRAND_INTL_PRIMARY2,
  tintColorLight,
} from '../constants/Colors';

const getAppStyle = (type: string | undefined) => {
  switch (type) {
    case 'grandIntl':
      // GrandIntl - wholesale company
      return {
        title: 'grandIntl',
        appWebsite:
          process.env.EXPO_WEBAPP_URL || 'https://grandapp.luniumall.com',
        mainColor: GRAND_INTL_PRIMARY1,
        secondaryColor: GRAND_INTL_PRIMARY2,
        icon: require('../assets/images/grand_logo.png'),
        firstStartImage: require('../assets/images/grandIntlSplash.jpg'),
        loadingIcon: require('../assets/images/icons/GIntl_loading.gif'),
        loginLogo: require('../assets/images/grandIntlSplash.jpg'),
        toHideFeature: true,
        about: 'aboutGrandIntl',
        aboutURL: `https://grand-import-ca.com/privacypolicy`,
        slogan: 'Grand International Import & Export Inc',
        loginRequiredAccessAllPages: true,
        screenHeaderStyle:
          Platform.OS === 'web' ? { maxHeight: 48 } : undefined,
        apiUrl:
          process.env.EXPO_LIVE_URL || 'https://grandapi.luniumall.com/api/v1/',
      };
    default:
      // Default type is luniu
      return {
        title: 'luniu',
        appWebsite: process.env.EXPO_WEBAPP_URL || 'https://app.luniumall.com',
        mainColor: tintColorLight,
        secondaryColor: accentColor,
        icon: require('../assets/images/appicon.png'),
        firstStartImage: require('../assets/images/luniu-splash.jpeg'),
        loadingIcon: require('../assets/images/icons/loading.gif'),
        loginLogo: require('../assets/images/logo-black.png'),
        toHideFeature: false,
        about: 'aboutLuniu',
        aboutURL: `https://luniumall.com/privacy-policy/`,
        slogan: '陆牛商城, 让北美生活更美好',
        loginRequiredAccessAllPages: false,
        screenHeaderStyle:
          Platform.OS === 'web' ? { maxHeight: 48 } : undefined,
        apiUrl:
          process.env.EXPO_LIVE_URL || 'https://api.luniumall.com/api/v1/',
      };
  }
};
export default getAppStyle;
