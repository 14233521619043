import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { backGroundLight } from '../../../constants/Colors';

type ListOptionProp = {
  children: React.ReactNode;
};

const ListOption = ({ children }: ListOptionProp) => {
  return <View style={styles.optionItem}>{children}</View>;
};

export default ListOption;

const styles = StyleSheet.create({
  optionItem: {
    paddingHorizontal: 10,
    paddingVertical: 15,
    borderBottomColor: backGroundLight,
    borderBottomWidth: 1,
    backgroundColor: 'white',
  },
});
