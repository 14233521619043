import React, { useState } from 'react';
import { View, StyleSheet, Image, Text, TouchableOpacity } from 'react-native';
import StyledText from '../common/StyledText';
import Collapsible from 'react-native-collapsible';
import { normalTextColor } from '../../constants/Colors';
import { AntDesign, Entypo } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';
import { Shop } from '../../types';
import getAppStyle from '../../utils/getAppStyle';

type ShopInfoProps = {
  shop: Shop;
};

const ShopInfo = ({ shop }: ShopInfoProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { t } = useTranslation();

  const renderWechat = (kf_wechat: string | Array<string>) => {
    if (kf_wechat) {
      if (Array.isArray(kf_wechat)) {
        return (
          <View style={styles.wechatAccount}>
            {kf_wechat.map((item, index) => {
              const values = Object.entries(item);
              return (
                <StyledText key={index} color={normalTextColor} size={12}>
                  {values[0][0]}: {values[0][1]}
                </StyledText>
              );
            })}
          </View>
        );
      } else {
        return typeof kf_wechat === 'string' ? (
          <StyledText color={normalTextColor} size={12}>
            {t('customerService.wechatAccount')}: {kf_wechat}
          </StyledText>
        ) : (
          <></>
        );
      }
    } else {
      return (
        <StyledText color={normalTextColor} size={12}>
          {''}
        </StyledText>
      );
    }
  };

  return (
    <View style={styles.shopInfoWrapper}>
      <View style={styles.topSection}>
        <View style={styles.thumbContainer}>
          <Image
            source={{ uri: shop.logo_thumb }}
            style={styles.thumbImage}
          ></Image>
        </View>
        <View style={styles.infoContainer}>
          <StyledText color={normalTextColor} bold={true}>
            {shop.shop_name}
          </StyledText>
          <TouchableOpacity
            onPress={() => {
              Linking.openURL(`tel:${shop.kf_tel}`);
            }}
          >
            <StyledText color={'gray'}>{shop.kf_tel}</StyledText>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setIsCollapsed((lastValue) => !lastValue);
            }}
          >
            {((shop.shop_address !== undefined && shop.shop_address.length) ||
              (shop.kf_wechat !== undefined && shop.kf_wechat.length > 0)) && (
              <Text style={{ color: '#2e78b7' }}>
                {isCollapsed ? t('more') : t('hide')}
              </Text>
            )}
          </TouchableOpacity>
        </View>
      </View>
      <Collapsible collapsed={isCollapsed}>
        <View style={styles.bottomSection}>
          <StyledText style={{ marginBottom: 10 }} color={'gray'}>
            {shop.store_description}
          </StyledText>
          {shop.shop_address !== undefined && shop.shop_address.length > 0 && (
            <View style={styles.detailWithLogo}>
              <Entypo
                style={{ paddingRight: 8 }}
                name="location"
                size={20}
                color="black"
              />
              <StyledText color={normalTextColor} size={12}>
                {shop.shop_address}
              </StyledText>
            </View>
          )}
          {!getAppStyle(process.env.EXPO_APP_TYPE)?.toHideFeature &&
            shop.kf_wechat !== undefined &&
            shop.kf_wechat.length > 0 && (
              <View style={styles.detailWithLogo}>
                <AntDesign
                  style={{ paddingRight: 8 }}
                  name="wechat"
                  size={20}
                  color="black"
                />
                {renderWechat(shop.kf_wechat)}
              </View>
            )}
        </View>
      </Collapsible>
    </View>
  );
};

const styles = StyleSheet.create({
  shopInfoWrapper: {
    flexDirection: 'column',
  },
  topSection: {
    flexDirection: 'row',
  },
  thumbContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: 'lightgray',
    borderWidth: 1,
  },
  infoContainer: {
    flex: 3,
    flexDirection: 'column',
    paddingLeft: 15,
  },
  thumbImage: {
    width: 50,
    height: 50,
  },
  bottomSection: {
    marginTop: 15,
    paddingTop: 15,
    borderTopWidth: 1,
    borderTopColor: 'lightgray',
    flexDirection: 'column',
  },
  detailWithLogo: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingVertical: 5,
  },
  wechatAccount: {
    flexDirection: 'column',
  },
});

export default ShopInfo;
