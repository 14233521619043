import React, { useEffect } from 'react';
import { SectionList, Text, StyleSheet } from 'react-native';
import FullWidthView from '../../components/common/FullWidthView';
import { backGroundLight } from '../../constants/Colors';
import { useTranslation } from 'react-i18next';
import NavigateButton from '../../components/common/NavigateButton';
import ListOption from '../../components/common/list/ListOption';
import StyledText from '../../components/common/StyledText';
import { RootState } from '../../types';
import { useSelector } from 'react-redux';
import getAppStyle from '../../utils/getAppStyle';

const UserSettingScreen = () => {
  const { t } = useTranslation();

  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.user.isLoggedIn
  );

  const options = [
    {
      title: 'Language Setting',
      data: [
        {
          label: t('userSettingScreen.subScreens.languageSetting'),
          route: 'LanguageSettingScreen',
        },
      ],
    },
    {
      title: 'Font Setting',
      data: [
        {
          label: t('userSettingScreen.fontScreen.title'),
          route: 'FontSettingScreen',
        },
      ],
    },
    {
      title: 'Notification Setting',
      data: [
        {
          label: t('userSettingScreen.notificationScreen.title'),
          route: 'NotificationSettingScreen',
        },
      ],
    },
    {
      title: 'About Luniu Mall',
      data: [
        {
          label: t(
            `userSettingScreen.${
              getAppStyle(process.env.EXPO_APP_TYPE)?.about
            }.title`
          ),
          route: 'AboutLuniu',
        },
      ],
    },
  ];

  return (
    <FullWidthView style={styles.rootContainer}>
      <SectionList
        sections={
          isLoggedIn
            ? options
            : options.filter(
                (option) => option.title !== 'Notification Setting'
              )
        }
        keyExtractor={(item, index) => item.route + index}
        renderItem={({ item }) => (
          <MenuItem title={item.label} route={item.route} />
        )}
        renderSectionHeader={({ section: { title } }) => (
          <Text style={styles.header}>{title}</Text>
        )}
      />
    </FullWidthView>
  );
};

export default UserSettingScreen;

type MenuItemProp = {
  title: string;
  route: string;
};

const MenuItem = ({ title, route }: MenuItemProp) => (
  <ListOption>
    <NavigateButton route={route}>
      <StyledText>{title}</StyledText>
    </NavigateButton>
  </ListOption>
);

const styles = StyleSheet.create({
  rootContainer: {
    backgroundColor: backGroundLight,
  },
  header: {
    fontSize: 18,
    backgroundColor: '#fff',
    opacity: 0,
  },
});
