import { useHeaderHeight } from '@react-navigation/stack';
import React, { ReactNode } from 'react';
import {
  View,
  StyleSheet,
  Dimensions,
  StyleProp,
  TextStyle,
  // SafeAreaView,
  Platform,
} from 'react-native';
import { Edge, SafeAreaView } from 'react-native-safe-area-context';
import useIsWeChat from '../../hooks/useIsWeChat';
import FocusAwareStatusBar from './FocusAwareStatusBar';
const { width, height } = Dimensions.get('window');

type FullWidthViewProps = {
  edges?: readonly Edge[];
  children: ReactNode;
  style?: StyleProp<TextStyle>;
  safeAreaBackgroundColor?: string;
};

/**
 * Base safe area view layout component being used in screen component root
 */
const FullWidthView = ({
  edges,
  children,
  style,
  safeAreaBackgroundColor = 'white',
}: FullWidthViewProps) => {
  const isWeChat = useIsWeChat();

  return Platform.OS === 'web' ? (
    isWeChat ? (
      <View
        style={[
          {
            flex: 1,
            backgroundColor: 'white',
            width: '100vw',
            height: height - useHeaderHeight(),
          },
          style,
        ]}
      >
        {children}
      </View>
    ) : (
      <View
        style={[
          styles.normalBrowserContainer,
          { height: height - useHeaderHeight() },
          style,
        ]}
      >
        {children}
      </View>
    )
  ) : (
    <SafeAreaView
      edges={edges ? edges : ['left', 'right']}
      style={{ flex: 1, backgroundColor: safeAreaBackgroundColor }}
    >
      <View style={{ flex: 1 }}>
        <FocusAwareStatusBar
          style={safeAreaBackgroundColor === 'white' ? 'dark' : 'light'}
        />

        <View style={[styles.appContainer, style]}>{children}</View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  appContainer: {
    flex: 1,
    width: width,
    height: height,
    backgroundColor: 'white',
  },
  normalBrowserContainer: {
    // flex: 1,
    width: width,
    backgroundColor: 'white',
  },
});

export default FullWidthView;
