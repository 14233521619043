import { useMount } from 'ahooks';
import { getAppConfig, getDataWithAuthToken } from '../utils/service';
import * as Application from 'expo-application';
import { Platform, Linking } from 'react-native';
import * as Device from 'expo-device';
import compareVersions, { CompareOperator } from 'compare-versions';
import alert from '../utils/alert';
import { useTranslation } from 'react-i18next';

import * as RootNavigation from '../utils/navigator';
import {
  getAsyncStorageData,
  setAsyncStorageData,
} from '../utils/asyncStorage';
import { useDispatch } from 'react-redux';
import { changeMainColor } from '../constants/Colors';
import { setWechatWebConfig } from '../utils/wechatWebMethods';
import useIsWeChat from './useIsWeChat';
import getAppStyle from '../utils/getAppStyle';

/**
 *  Get shop status and app config(e.g API key etc) from server
 */
const useAppConfig = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isWeChatBrowser = useIsWeChat();

  //Get the basic information from async storage
  useMount(async () => {
    const languagePreference = await getAsyncStorageData(
      '@storage_LangrageReference'
    );
    //Set Language, Default would be zh-CN
    if (languagePreference) {
      dispatch({
        type: 'CHANGE_LANGUAGE_PREFERENCE',
        payload: languagePreference,
      });
      i18n.changeLanguage(languagePreference);
    }

    //Check if user's app is out of date and location
    getAppConfig()
      .then(async (result) => {
        if (result && result.goodStatus) {
          let isOutOfDate = false;
          let link = '';
          const version = Application.nativeApplicationVersion;
          const { ios, android, other, wechat } = result.data;
          await setAsyncStorageData(
            '@storage_displayFloatCS',
            other.need_float_cs.toString()
          );
          if (version) {
            if (Platform.OS === 'android') {
              if (
                version &&
                compareVersions.compare(version, android.version, '<')
              ) {
                isOutOfDate = true;
                link = android.googleplay_link;
              }
              if (android.force_update) {
                let needUpdate = android.force_update.some(
                  (versionCheck: {
                    version: string;
                    operator: CompareOperator;
                  }) =>
                    compareVersions.compare(
                      version,
                      versionCheck.version,
                      versionCheck.operator
                    )
                );
                dispatch({
                  type: 'CHANGE_APP_OUT_OF_DATE',
                  payload: needUpdate,
                });
                dispatch({
                  type: 'CHANGE_APP_STORE_LINK',
                  payload: android.googleplay_link,
                });
                dispatch({
                  type: 'CHANGE_APP_STORE_LINK',
                  payload: android.googleplay_link,
                });
                if (Device.brand === 'HUAWEI') {
                  dispatch({
                    type: 'CHANGE_APP_STORE_LINK',
                    payload: android.huawei_link,
                  });
                }
                if (android.universal_link) {
                  dispatch({
                    type: 'UPDATE_UNIVERSAL_LINK',
                    payload: android.universal_link,
                  });
                }
              }
            }
            if (Platform.OS === 'ios') {
              if (
                version &&
                compareVersions.compare(version, ios.version, '<')
              ) {
                isOutOfDate = true;
                link = ios.link;
              }
              if (ios.force_update) {
                let needUpdate = ios.force_update.some(
                  (versionCheck: {
                    version: string;
                    operator: CompareOperator;
                  }) =>
                    compareVersions.compare(
                      version,
                      versionCheck.version,
                      versionCheck.operator
                    )
                );
                dispatch({
                  type: 'CHANGE_APP_OUT_OF_DATE',
                  payload: needUpdate,
                });
                dispatch({
                  type: 'CHANGE_APP_STORE_LINK',
                  payload: ios.link,
                });
                if (ios.universal_link) {
                  dispatch({
                    type: 'UPDATE_UNIVERSAL_LINK',
                    payload: ios.universal_link,
                  });
                }
              }
            }
          }

          if (isOutOfDate) {
            alert(
              t('updatePrompt.title'),
              t('updatePrompt.message'),
              [
                {
                  text: t('cancel'),
                  style: 'cancel',
                },
                {
                  text: t('updatePrompt.update'),
                  onPress: () => {
                    link !== '' &&
                      Linking.canOpenURL(link)
                        .then(
                          (supported) => {
                            supported && Linking.openURL(link);
                          },
                          (err) => console.log(err)
                        )
                        .catch((err) => {
                          console.log(err);
                        });
                  },
                },
              ],
              { cancelable: false }
            );
          }

          if (wechat) {
            dispatch({
              type: 'UPDATE_APP_LOGIN_APP_ID',
              payload: wechat.app_login_appid,
            });

            dispatch({
              type: 'UPDATE_WEB_LOGIN_APP_ID',
              payload: wechat.web_login_appid,
            });

            dispatch({
              type: 'UPDATE_OTHER_APP_ID',
              payload: wechat.other_appid,
            });
          }

          //Set Google map API Key
          if (result.data.google_map_key.web_key) {
            dispatch({
              type: 'UPDATE_GOOGLE_MAP_KEY',
              payload: result.data.google_map_key.web_key,
            });
          }

          //Set Mapbox API key
          if (result.data.mapbox_key) {
            dispatch({
              type: 'UPDATE_MAPBOX_MAP_KEY',
              payload: result.data.mapbox_key,
            });
          }
          //Check shop status, only 0 is good status
          if (result.data.shop_status) {
            checkShopStatus(
              result.data.shop_status.shop_closed,
              result.data.shop_status.close_comment,
              result.data.shop_status.shop_reg_company,
              result.data.shop_status.shop_title,
              result.data.shop_status.shop_desc
            );
          }
          //Change App main color for special event
          if (result.data.app_color && result.data.app_color !== '') {
            dispatch({
              type: 'CHANGE_APP_MAIN_COLOR',
              payload: result.data.app_color,
            });
            changeMainColor(result.data.app_color);
          }
          //no special color, use default
          else {
            dispatch({
              type: 'CHANGE_APP_MAIN_COLOR',
              payload: getAppStyle(process.env.EXPO_APP_TYPE)?.mainColor,
            });
            changeMainColor(getAppStyle(process.env.EXPO_APP_TYPE)?.mainColor);
          }

          // set Wechat configs if wechat browser
          if (isWeChatBrowser) {
            setWechatWebConfig();
          }

          // Set if the user is located in China
          if (result.data.other) {
            dispatch({
              type: 'LOCATED_IN_CHINA',
              payload: result.data.other.is_located_at_china,
            });
          }
        }
      })
      .catch((err) => {
        console.log('error checking for updates', err);
      });

    //Check user is login or not
    const authToken = await getAsyncStorageData('@storage_AuthToken');
    if (authToken) {
      checkValidUser().then((result) => {
        if (result && result.goodStatus) {
          if (result.data.is_valid) {
            dispatch({
              type: 'CHANGE_LOGIN_STATUS',
              payload: true,
            });
            if (
              getAppStyle(process.env.EXPO_APP_TYPE)
                ?.loginRequiredAccessAllPages
            ) {
              RootNavigation.reset();
            }
          } else {
            dispatch({
              type: 'CHANGE_LOGIN_STATUS',
              payload: false,
            });
          }
        }
      });
    } else {
      if (getAppStyle(process.env.EXPO_APP_TYPE)?.loginRequiredAccessAllPages) {
        RootNavigation.navigate('Modal');
      }
    }
  });

  const checkShopStatus = (
    code: number,
    comment: string,
    shopRegCompany: boolean,
    shopTitle: string,
    shopDesc: string
  ) => {
    dispatch({
      type: 'SET_SHOP_STATUS',
      payload: {
        code: code,
        message: comment,
        shopRegCompany: shopRegCompany,
        shopTitle: shopTitle,
        shopDesc: shopDesc,
      },
    });
    switch (code) {
      case 2:
        alert(comment);
        break;
      case 0:
      default:
        break;
    }
  };
};

export default useAppConfig;

const checkValidUser = async () => {
  const result = await getDataWithAuthToken('user/check');
  return result;
};
