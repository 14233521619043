import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import React, { useEffect, useRef, useState } from 'react';
import {
  StyleSheet,
  View,
  ScrollView,
  Dimensions,
  Platform,
} from 'react-native';
import FullWidthView from '../../../components/common/FullWidthView';
import StyledText from '../../../components/common/StyledText';
import WhiteCard from '../../../components/common/WhiteCard';
import {
  accentColor,
  backGroundLight,
  subTextColor,
} from '../../../constants/Colors';
import { OrderParamList } from '../../../types';
import { getDataWithAuthToken } from '../../../utils/service';
import moment from 'moment';
import DriverInfo from '../../../components/Order/tracking/DriverInfo';
import { AntDesign } from '@expo/vector-icons';
import {
  orderStatusColor,
  orderStatusToString,
  OrderStatusType,
} from '../../../utils/toString';
import LogList from '../../../components/Order/tracking/LogList';
import Loading from '../../../components/common/Loading';
import DriverRemarkCard from '../../../components/Order/tracking/DriverRemarkCard';
import { useTranslation } from 'react-i18next';
import EmptyDisplayBlock from '../../../components/common/EmptyDisplayBlock';
import Constants from 'expo-constants';
import { useHeaderHeight } from '@react-navigation/stack';

const { height } = Dimensions.get('window');

let MapView: any = <></>;

if (Constants.appOwnership === 'expo' || Platform.OS === 'web') {
  MapView = require('../../../components/common/map/GoogleMapView').default;
} else {
  MapView = require('../../../components/common/map/MapBoxView').default;
}

const OrderTrackingScreen = () => {
  const { t } = useTranslation();

  const route = useRoute<RouteProp<OrderParamList, 'OrderTracking'>>();
  const navigation = useNavigation();
  const headerHeight = useHeaderHeight();

  const [isLoading, setIsLoading] = useState(false);
  const [getOrderSuccess, setGetOrderSuccess] = useState(false);

  const [errMsg, setErrMsg] = useState('');

  const [targetLocation, setTargetLocation] = useState({
    lat: 49.2827291,
    lng: -123.1207375,
  });

  const [showMap, setShowMap] = useState(false);
  const [currentSequence, setCurrentSequence] = useState(-1);
  const [currentStatus, setCurrentStatus] = useState<OrderStatusType>(-1);
  const [etcPeriod, setEtcPeriod] = useState<{
    start: null | string;
    end: null | string;
  }>({ start: null, end: null });
  const [driverInfo, setDriverInfo] = useState<{
    name: string | null;
    updateAt: string | null;
    lat: string | null;
    lng: string | null;
  }>({ name: null, updateAt: null, lat: null, lng: null });

  const [driverRemark, setDriverRemark] = useState<{
    content: string | null;
    images: string[];
  }>({
    content: null,
    images: [],
  });

  const [completeTime, setCompleteTime] = useState<string | null>(null);

  const [logs, setLogs] = useState<
    Array<{
      id: number;
      status: OrderStatusType;
      created_at: string;
    }>
  >([]);

  const getTrackingStatus = () => {
    setIsLoading(true);
    if (route.params.orderSn) {
      getDataWithAuthToken(`order/track?order_sn=${route.params.orderSn}`)
        .then((result) => {
          if (result && result.goodStatus && result.data) {
            setGetOrderSuccess(true);
            if (
              typeof result.data.longitude === 'string' &&
              typeof result.data.latitude === 'string'
            )
              setTargetLocation({
                lat: parseFloat(result.data.latitude),
                lng: parseFloat(result.data.longitude),
              });

            setCurrentSequence(result.data.countSequence);

            if (
              result.data.status === OrderStatusType.OutForDelivery ||
              result.data.status === OrderStatusType.ArrivingSoon
            ) {
              if (
                typeof result.data.ETC_start_time === 'string' &&
                typeof result.data.ETC_end_time === 'string'
              ) {
                setEtcPeriod({
                  start: moment(result.data.ETC_start_time).format(
                    'MM-DD k:mm'
                  ),
                  end: moment(result.data.ETC_end_time).format('MM-DD k:mm'),
                });
              }
            } else if (
              result.data.status === OrderStatusType.Delivered ||
              result.data.status === OrderStatusType.Failed
            ) {
              setCompleteTime(result.data.complete_at);
            }

            setCurrentStatus(result.data.status);

            if (result.data.route.driver !== null) {
              //Driver Info
              setDriverInfo({
                name: result.data.route.driver.driver_name,
                updateAt: result.data.route.driver.location_update_at,
                lat: result.data.route.driver.latitude,
                lng: result.data.route.driver.longitude,
              });

              setDriverRemark({
                content: result.data.driver_remark,
                images: result.data.order_images.map(
                  (item: any) => item.img_url
                ),
              });
            }

            setLogs(result.data.orders_logs);

            if (result.data.show_map) {
              setShowMap(true);
            } else {
              setShowMap(false);
            }
          } else if (result) {
            if (typeof result.data === 'string') {
              setErrMsg(result.data);
            }
            setGetOrderSuccess(false);
          } else {
            setGetOrderSuccess(false);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err), setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getTrackingStatus();
    });

    return unsubscribe;
  }, [navigation]);

  const flyToDriver = () => {
    if (driverInfo.lat !== null && driverInfo.lng !== null) {
      const savedLatLng = { lat: driverInfo.lat, lng: driverInfo.lng };

      setDriverInfo((prev) => {
        return { ...prev, lat: null, lng: null };
      });
      setDriverInfo((prev) => {
        return { ...prev, ...savedLatLng };
      });
    }
  };

  const renderTrackInfo = () => {
    return (
      <>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <StyledText>
            {t('order.orderNumber') + ': '}
            {route.params.orderSn ? route.params.orderSn : -1}
          </StyledText>
          <View style={styles.refreshBtn}>
            <AntDesign
              name="reload1"
              size={20}
              color={subTextColor}
              onPress={() => getTrackingStatus()}
            />
          </View>
        </View>

        <StyledText
          size={20}
          align="center"
          color={orderStatusColor(currentStatus)}
        >
          {orderStatusToString(currentStatus)}
        </StyledText>
        {currentStatus === OrderStatusType.Delivered ||
        currentStatus === OrderStatusType.Failed ? (
          completeTime !== null ? (
            <View style={styles.etcContainer}>
              <StyledText size={18}>
                {t('order.orderTracking.main.completeTime')}
              </StyledText>
              <View style={{ marginBottom: 3 }}>
                <StyledText>
                  {moment(completeTime).format('YYYY-MM-DD k:mm')}
                </StyledText>
              </View>
            </View>
          ) : (
            <></>
          )
        ) : (
          <>
            <View style={styles.sequenceCounter}>
              <StyledText size={18}>
                {t('order.orderTracking.main.queuePosition')}
              </StyledText>

              <StyledText>
                {t('order.orderTracking.main.di')}
                <StyledText
                  bold
                  size={26}
                  color={accentColor}
                  style={{ marginHorizontal: 10 }}
                >
                  {currentSequence}
                </StyledText>
                {t('order.orderTracking.main.wei')}
              </StyledText>
            </View>
            {etcPeriod.start !== null && etcPeriod.end !== null && (
              <View style={styles.etcContainer}>
                <StyledText size={18}>
                  {t('order.orderTracking.main.est')}
                </StyledText>
                <View>
                  <StyledText>{`${etcPeriod.start} ~ ${etcPeriod.end}`}</StyledText>
                </View>
              </View>
            )}
          </>
        )}

        {driverInfo.name !== null && showMap === true && (
          <DriverInfo
            name={driverInfo.name}
            updateAt={driverInfo.updateAt}
            flyToDriver={flyToDriver}
          />
        )}

        {(driverRemark.content !== null || driverRemark.images.length > 0) && (
          <DriverRemarkCard
            content={driverRemark.content}
            images={driverRemark.images}
          />
        )}

        <LogList logs={logs} />
      </>
    );
  };

  return (
    <FullWidthView>
      {isLoading ? (
        <View
          style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
        >
          <Loading />
        </View>
      ) : getOrderSuccess ? (
        <View style={styles.rootContainer}>
          <View style={styles.mapContainer}>
            {showMap ? (
              <MapView
                latitude={targetLocation.lat}
                longitude={targetLocation.lng}
                showDriver={
                  driverInfo.lat !== null && driverInfo.lng !== null
                    ? {
                        lat: parseFloat(driverInfo.lat),
                        lng: parseFloat(driverInfo.lng),
                      }
                    : undefined
                }
              />
            ) : (
              <View style={styles.noMapContainer}></View>
            )}
          </View>
          {showMap ? (
            <View style={styles.infoContainer}>
              <WhiteCard>
                <ScrollView
                  showsVerticalScrollIndicator={false}
                  style={{
                    height: height * 0.4,
                    paddingBottom: Platform.OS === 'web' ? headerHeight : 0,
                  }}
                >
                  {renderTrackInfo()}
                </ScrollView>
              </WhiteCard>
            </View>
          ) : (
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{
                height: height,
                padding: 10,
                paddingBottom: Platform.OS === 'web' ? headerHeight : 0,
              }}
            >
              {renderTrackInfo()}
            </ScrollView>
          )}
        </View>
      ) : (
        <EmptyDisplayBlock description={errMsg !== '' ? errMsg : undefined} />
      )}
    </FullWidthView>
  );
};

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    position: 'relative',
  },
  mapContainer: {
    flex: 1,
  },
  infoContainer: {
    position: 'absolute',
    bottom: 0,
    left: 10,
    right: 10,
  },
  noMapContainer: {
    flex: 1,
    backgroundColor: backGroundLight,
  },
  sequenceCounter: {
    alignItems: 'center',
  },
  etcContainer: {
    alignItems: 'center',
  },
  refreshBtn: {
    alignItems: 'flex-end',
  },
});

export default OrderTrackingScreen;
