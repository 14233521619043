import React from 'react';
import { StyleSheet, View } from 'react-native';
import StyledButton from '../../common/StyledButton';
import StyledText from '../../common/StyledText';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import {
  backGroundLight,
  linkColor,
  normalTextColor,
  subTextColor,
} from '../../../constants/Colors';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type DriverInfoProps = {
  name: string;
  updateAt: string | null;
  flyToDriver: () => void;
};

const DriverInfo = ({ name, updateAt, flyToDriver }: DriverInfoProps) => {
  const { t } = useTranslation();

  return (
    <View style={styles.root}>
      <View style={styles.row1}>
        <View style={styles.driverName}>
          <AntDesign
            name="car"
            size={20}
            color={normalTextColor}
            style={{ marginRight: 3 }}
          />
          <StyledText>
            {t('order.orderTracking.driverInfo.driver', { name: name })}
          </StyledText>
        </View>
        <View style={styles.buttonContainer}>
          <StyledButton
            buttonSize="small"
            label={t('order.orderTracking.driverInfo.locate')}
            color={linkColor}
            onPress={flyToDriver}
            icon={<Ionicons name="md-locate" size={16} color="white" />}
          />
        </View>
      </View>
      {updateAt !== null && (
        <StyledText align="center" color={subTextColor} size={12}>
          {t('order.orderTracking.driverInfo.updatedAt', {
            time: moment(updateAt).format('MM-DD k:mm'),
          })}
        </StyledText>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    marginVertical: 6,
    borderWidth: 1,
    borderColor: backGroundLight,
    borderRadius: 10,
    padding: 10,
    alignItems: 'center',
  },
  row1: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    marginVertical: 6,
  },
  driverName: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

export default DriverInfo;
