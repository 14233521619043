import React from 'react';
import FullWidthView from '../../../components/common/FullWidthView';
import { WebView } from 'react-native-webview';
import { MaterialIcons } from '@expo/vector-icons';
import StyledText from '../../../components/common/StyledText';
import { useTranslation } from 'react-i18next';
import { Dimensions, Platform, StyleSheet, View } from 'react-native';
import { useHeaderHeight } from '@react-navigation/stack';

type HelpCenterItemScreenProps = {
  route: any;
};

const HelpCenterItemScreen = ({ route }: HelpCenterItemScreenProps) => {
  const { uri } = route.params;
  const { t } = useTranslation();

  return uri ? (
    <FullWidthView edges={['bottom', 'left', 'right']}>
      <WebView source={{ uri: uri }} style={{ width: '100%' }} />
    </FullWidthView>
  ) : (
    <View style={styles.errorContainer}>
      <MaterialIcons name="error" size={24} color="lightgray" />
      <StyledText color={'lightgray'}>{t('failedToRetrievePage')}</StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  errorContainer: {
    height: Dimensions.get('window').height - 60,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default HelpCenterItemScreen;
