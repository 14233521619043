import React, { useState, useEffect } from 'react';
import FullWidthView from '../common/FullWidthView';

import ProductList from '../Category/container/ProductList';
import { searchGoodsWithKeyword } from '../../utils/service';
import {
  ProductInformation,
  RootState,
  LocationInformation,
} from '../../types';
import SearchBar from './SearchBar';
import HeaderView from '../common/HeaderView';
import { View, StyleSheet, Platform, Dimensions } from 'react-native';
import StyledText from '../common/StyledText';
import { useTranslation } from 'react-i18next';
import { normalTextColor } from '../../constants/Colors';
import NavigateButton from '../common/NavigateButton';
import { AntDesign } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import ProductOptionSelector from '../Product/ProductOptionSelector';
import ProductListSkeletonLoad from '../common/ProductListSkeletonLoad';
import AlertModal from '../common/AlertModal/AlertModal';
import { updateRecentSearchList } from './RecentSearchSection';
import { useHeaderHeight } from '@react-navigation/stack';

const SearchResult = ({ route }: any) => {
  const { t } = useTranslation();

  const location = useSelector<RootState, LocationInformation>(
    (state) => state.goods.location
  );

  const [keyword, setKeyword] = useState(route.params.keyword);

  const [currentPage, setCurrentPage] = useState(1);
  const [productAllLoaded, setProductAllLoaded] = useState(false);
  const [searchResultArray, setSearchResultArray] = useState<
    Array<ProductInformation>
  >([]);
  const [loading, setLoading] = useState(true);

  const shareFetchFunction = (page = 1, reset = true) => {
    searchGoodsWithKeyword(
      keyword.trim(),
      page,
      location.countryId,
      location.provinceId,
      location.cityId
    ).then((result) => {
      setLoading(false);
      if (result && result?.goodStatus) {
        setSearchResultArray(
          reset
            ? [...result.data.goods]
            : [...searchResultArray, ...result.data.goods]
        );
        //Product list less than 10, do not require bottom reach fetch
        result.data.goods.length < 10 && setProductAllLoaded(true);
      }
    });
  };

  // Only used for updating list when component first mount.
  useEffect(() => {
    shareFetchFunction();
  }, []);

  // Only used for updating list when current page change
  useEffect(() => {
    if (!productAllLoaded && currentPage !== 1) {
      shareFetchFunction(currentPage, false);
    }
  }, [currentPage]);

  //User Submit new search keyword, reset current list
  const searchOnSubmit = () => {
    setSearchResultArray([]);
    setCurrentPage(1);
    setLoading(true);
    setProductAllLoaded(false);
    updateRecentSearchList(keyword);

    shareFetchFunction();
  };

  const searchOnChange = (text: string) => {
    setKeyword(text);
  };

  //Function for list scroll update
  const updatePage = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <FullWidthView edges={['bottom', 'left', 'right', 'top']}>
      <HeaderView
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 10,
          backgroundColor: '#FFFF',
        }}
      >
        <NavigateButton goBack>
          <AntDesign name="left" size={24} color={normalTextColor} />
        </NavigateButton>
        <SearchBar
          onChange={searchOnChange}
          searchText={keyword}
          onSubmit={searchOnSubmit}
          showLoading={loading}
        />
      </HeaderView>
      {loading ? (
        <View style={styles.loadingContainer}>
          <ProductListSkeletonLoad />
        </View>
      ) : searchResultArray.length > 0 ? (
        <View style={{ flex: 1 }}>
          <ProductList
            productAllLoaded={productAllLoaded}
            updatePage={updatePage}
            data={searchResultArray}
            isHomeProductList
          />
        </View>
      ) : (
        <View style={styles.noResultContainer}>
          <StyledText align="center" size={20}>
            {t('searchScreen.noContentAbout')}
          </StyledText>
        </View>
      )}
      <ProductOptionSelector />
      <AlertModal />
    </FullWidthView>
  );
};

export default SearchResult;

const styles = StyleSheet.create({
  noResultContainer: {
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: 10,
  },
  loadingContainer: {
    flex: 1,
  },
});
