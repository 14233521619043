import { ReducerActionType, UrlState } from '../types';

const initState: UrlState = {
  isDangerousUrl: false,
};

const urlReducer = (state = initState, action: ReducerActionType) => {
  switch (action.type) {
    /*If the provided url contains one of these route. Set dangerous to true. So the Auth
        page's 'X' button can go to screens back.*/
    case 'CHECK_URL_IS_DANGEROUS':
      const routeList = [
        'cart',
        'wallet',
        'orders',
        'reviews',
        'address',
        'credit',
        'favorites',
        'invite',
        'verification',
      ];
      const url = action.payload;
      const hasMatch = routeList.some((route) => url.includes(route));
      return { ...state, isDangerousUrl: hasMatch };

    case 'RESET_URL_IS_DANGEROUS':
      return { ...state, isDangerousUrl: false };

    default:
      return state;
  }
};

export default urlReducer;
