import React, { useEffect, useState } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ProductBundleParamList } from '../types';
import BundleHomeScreen from '../screens/ProductBundle/BundleHomeScreen';
import BundleDetailScreen from '../screens/ProductBundle/BundleDetailScreen';
import NavigateButton from '../components/common/NavigateButton';
import BackButton from '../components/common/iconButtons/BackButton';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import getAppStyle from '../utils/getAppStyle';

const ProductBundleStack = createStackNavigator<ProductBundleParamList>();

const ProductBundleNavigator = () => {
  const { t } = useTranslation();

  const [goBackPath, setGoBackPath] = useState<'Home' | 'Categories'>('Home');

  useEffect(() => {
    //Web - Set go back route based on route from Home/ Categories
    if (Platform.OS === 'web') {
      if (window.location.href.includes('categories')) {
        setGoBackPath('Categories');
      }
    }
  }, []);

  return (
    <ProductBundleStack.Navigator
      initialRouteName="BundleHome"
      screenOptions={{
        headerTitleAlign: 'center',
        headerBackTitleVisible: false,
        headerLeft: () =>
          Platform.OS === 'web' ? (
            <NavigateButton route={goBackPath}>
              <BackButton />
            </NavigateButton>
          ) : (
            <NavigateButton goBack>
              <BackButton />
            </NavigateButton>
          ),
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <ProductBundleStack.Screen
        name="BundleHome"
        component={BundleHomeScreen}
        options={{ headerTitle: t('bundleScreen.bundleList.title') }}
      />
      <ProductBundleStack.Screen
        name="BundleDetail"
        component={BundleDetailScreen}
        options={{
          headerTitle: t('bundleScreen.bundleDetail.title'),
          headerLeft: () => (
            <NavigateButton route="BundleHome">
              <BackButton />
            </NavigateButton>
          ),
        }}
      />
    </ProductBundleStack.Navigator>
  );
};

export default ProductBundleNavigator;
