import React, { useEffect, useState } from 'react';
import FullWidthView from '../../components/common/FullWidthView';
import StyledText from '../../components/common/StyledText';
import { getData } from '../../utils/service';
import {
  View,
  SectionList,
  SectionListData,
  StyleSheet,
  TouchableOpacity,
  Platform,
  Dimensions
} from 'react-native';
import { backGroundLight, subTextColor } from '../../constants/Colors';
import Loading from '../../components/common/Loading';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { setAsyncStorageData } from '../../utils/asyncStorage';
import { StackNavigationProp } from '@react-navigation/stack';
import { HomeParamList } from '../../types';

type RegionsInformation = {
  code: string;
  parent_id: number;
  region?: RegionsInformation;
  region_id: number;
  region_name: string;
  region_type_name: string;
};

const RegionScreen = () => {
  const [regions, setRegions] = useState<Array<RegionsInformation>>([]);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  useEffect(() => {
    getData('region')
      .then((result) => {
        //TODO - Fetch USA Later
        if (result) {
          const targetObject = result.data[0].region.map(
            (item: RegionsInformation) => {
              return {
                id: item.region_id,
                name: item.region_name,
                data: item.region ? item.region : [],
                countryId: result.data[0].region_id,
              };
            }
          );
          setRegions(targetObject);
          setIsLoadingComplete(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <FullWidthView>
      {isLoadingComplete ? <AreaList data={regions} /> : <Loading />}
    </FullWidthView>
  );
};

const AreaList = ({ data }: any) => {
  const dispatch = useDispatch();
  const navigation = useNavigation<
    StackNavigationProp<HomeParamList, 'Region'>
  >();

  const { height } = Dimensions.get('window');

  //Province Header
  const SectionHeader = (section: SectionListData<any>) => {
    return (
      <View style={styles.sectionHeader}>
        <StyledText color={subTextColor}>{section.name}</StyledText>
      </View>
    );
  };

  //List item - Area
  const Item = ({ item }: any) => {
    //When user change location, update location information in redux
    const handleRegionChange = () => {
      const provinceInformation = data.find(
        (province: {
          countryId: number;
          data: Array<RegionsInformation>;
          id: number;
          name: string;
        }) => {
          return item.parent_id === province.id;
        }
      );

      const newLocation = {
        countryId: provinceInformation.countryId,
        provinceId: item.parent_id,
        cityId: item.region_id,
        areaName: item.region_name,
        provinceName: provinceInformation.name,
      };

      //Save location info to storage when user change location
      setAsyncStorageData('@storage_location', JSON.stringify(newLocation));
      dispatch({ type: 'UPDATE_LOCATION', payload: newLocation });
      navigation.popToTop();
    };

    return (
      <TouchableOpacity onPress={handleRegionChange}>
        <View style={styles.item}>
          <StyledText>{item.region_name}</StyledText>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <>
      <SectionList
        sections={data}
        keyExtractor={(item) => item.region_id.toString()}
        renderItem={({ item }) => <Item item={item} />}
        renderSectionHeader={({ section }) => SectionHeader(section)}
        style={{
          marginBottom: Platform.OS === "web" ? 60 : 0,
          height: Platform.OS == "web" ? height - 70 : 'auto'
        }}
      />
      {Platform.OS === "web" && 
        <View style={{ height: 30, width: '100%' }}></View>
      }
    </>
  );
};

const styles = StyleSheet.create({
  sectionHeader: {
    backgroundColor: backGroundLight,
    padding: 10,
  },
  item: {
    paddingHorizontal: 10,
    paddingVertical: 15,
    borderTopColor: backGroundLight,
    borderTopWidth: 1,
  },
});

export default RegionScreen;
