import React, { useState } from 'react';
import StyledText from '../../common/StyledText';
import {
  View,
  Image,
  StyleSheet,
  Dimensions,
  NativeSyntheticEvent,
  NativeScrollEvent,
  FlatList,
} from 'react-native';

import { tintColorLight } from '../../../constants/Colors';
import HomeLinkNavigator from '../HomeLinkNavigator';
import CachedImage from '../../common/cache-img/CachedImage';

type navData = {
  ad_id: number;
  nav_id?: number;
  name?: string;
  title: string;
  url: string;
  pic?: string;
  link: {
    url_type: string;
    type: string;
    id: number;
    cat_level: number;
  };
};

type HomeNavigationProps = {
  data: Array<navData>;
  marginBottom?: number;
  marginHorizontal?: number;
  backgroundColor?: string;
};

const { width } = Dimensions.get('window');

const HomeNavigation = ({
  data = [],
  marginHorizontal,
  marginBottom,
  backgroundColor,
}: HomeNavigationProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const pages: Array<Array<navData>> = [];

  //Check how many pages required based on data length
  for (let i = 0; i < Math.ceil(data.length / 10); i++) {
    pages.push([]);
  }

  //Separate items into page, 10 items per page
  data.forEach((item, index) => {
    if (item) {
      pages[Math.floor(index / 10)].push(item);
    }
  });

  //Pass pages to Flatlist, to render items
  const renderItem = ({ item }: any) => {
    const menuInPage = item.map((menu: navData) => {
      return (
        <MenuItem key={menu.ad_id ? menu.ad_id : menu.nav_id} item={menu} />
      );
    });
    return <View style={styles.menuContainer}>{menuInPage}</View>;
  };

  //Change index when scroll
  const handleBannerIndexChange = (
    event: NativeSyntheticEvent<NativeScrollEvent>
  ) => {
    const contentOffset = event.nativeEvent.contentOffset;
    const viewSize = event.nativeEvent.layoutMeasurement;

    // Divide the horizontal offset by the width of the view to see which page is visible
    setSelectedIndex(Math.floor(contentOffset.x / viewSize.width));
  };

  return (
    <View
      style={[
        styles.contentContainer,
        {
          marginHorizontal,
          marginBottom,
          backgroundColor,
        },
      ]}
    >
      <FlatList
        horizontal
        pagingEnabled
        data={pages}
        renderItem={renderItem}
        keyExtractor={(_, index) => index.toString()}
        onMomentumScrollEnd={handleBannerIndexChange}
        showsHorizontalScrollIndicator={false}
        // scrollEnabled={pages.length > 1}
      />
      {pages.length > 1 && (
        <View style={styles.circleDiv}>
          {pages.map((_: any, index: number) => (
            <View
              style={[
                styles.dot,
                { opacity: index === selectedIndex ? 1 : 0.3 },
              ]}
              key={index}
            />
          ))}
        </View>
      )}
    </View>
  );
};

export default HomeNavigation;

const MenuItem = ({ item }: any) => {
  return (
    <View style={styles.menuItemContainer}>
      <HomeLinkNavigator data={item} style={{ width: '100%' }}>
        <View style={styles.iconContainer}>
          {item.img !== undefined && item.title !== undefined ? (
            <>
              <CachedImage
                style={styles.thumbnail}
                source={{
                  uri: item.img,
                }}
              />
              <StyledText size={12} align="center">{item.title}</StyledText>
            </>
          ) : (
            <>
              <CachedImage
                style={styles.thumbnail}
                source={{
                  uri: item.pic,
                }}
              />
              <StyledText size={12} align="center">{item.name}</StyledText>
            </>
          )}
        </View>
      </HomeLinkNavigator>
    </View>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    width: width,
    marginBottom: 10,
  },
  menuContainer: {
    width: width,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  menuItemContainer: {
    width: '20%',
    alignItems: 'center',
    marginBottom: 10,
  },
  iconContainer: {
    alignItems: 'center',
  },
  thumbnail: {
    width: 50,
    height: 50,
  },
  circleDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  dot: {
    width: 16,
    height: 6,
    borderRadius: 3,
    margin: 1,
    backgroundColor: tintColorLight,
  },
});
