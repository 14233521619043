import React from 'react';
import {
  View,
  StyleSheet,
  Keyboard,
  TouchableWithoutFeedback,
} from 'react-native';
import WhiteCard from '../../common/WhiteCard';
import StyledText from '../../common/StyledText';
import { AntDesign } from '@expo/vector-icons';
import { subTextColor, warningRed } from '../../../constants/Colors';
import NavigateButton from '../../common/NavigateButton';
import { useTranslation } from 'react-i18next';

type AddressInfoProps = {
  address: {
    address_id: number;
    address_name: string;
    user_id: number;
    consignee: string;
    email: string;
    country: number;
    province: number;
    city: number;
    district: number;
    street: number;
    address: string;
    latitude: string;
    longitude: string;
    postal_code: string;
    zipcode: string;
    tel: string;
    mobile: string;
    sign_building: string;
    best_time: string;
    audit: number;
    update_time: string;
  };
  shippingTimeComponent: JSX.Element;
  disableAddressChange?: boolean;
};

const AddressInfo = ({
  address,
  shippingTimeComponent,
  disableAddressChange = false,
}: AddressInfoProps) => {
  const { t } = useTranslation();

  const handleKeyboardClose = () => {
    Keyboard.dismiss();
  };

  return (
    <TouchableWithoutFeedback onPress={handleKeyboardClose}>
      <WhiteCard
        title={t('order.deliveryInformation')}
        description={
          disableAddressChange
            ? {
                label: t('order.cannotModifyAddress'),
                color: warningRed,
              }
            : undefined
        }
      >
        <View style={styles.infoRow}>
          <View style={{ flex: 1 }}>
            <StyledText bold>
              <AntDesign name="enviromento" size={20} color={subTextColor} />{' '}
              {t('address.address')}
            </StyledText>
            <NavigateButton
              route="Address"
              options={{
                screen: 'AddressList',
                params: {
                  type: 'view',
                },
              }}
              disabled={disableAddressChange}
              style={{ justifyContent: 'space-between', flexDirection: 'row' }}
            >
              <View>
                <StyledText>
                  {address.sign_building !== ''
                    ? `${address.sign_building}-${address.address}`
                    : address.address}
                </StyledText>
                <StyledText>{address.postal_code}</StyledText>
              </View>
              {!disableAddressChange && (
                <AntDesign name="rightcircleo" size={20} color="lightgray" />
              )}
            </NavigateButton>
          </View>
        </View>
        <View>
          <StyledText bold>
            <AntDesign name="phone" size={20} color={subTextColor} />{' '}
            {t('order.contact')}
          </StyledText>
          <View style={styles.infoRow}>
            <StyledText style={styles.infoColumn}>
              {address.consignee}
            </StyledText>
            <StyledText style={styles.infoColumn} align="right">
              {address.mobile}
            </StyledText>
          </View>
        </View>
        {shippingTimeComponent}
      </WhiteCard>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  infoRow: {
    marginVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  infoColumn: {
    flex: 1,
  },
});

export default AddressInfo;
