import { ReducerActionType, UserState } from '../types';

const initState: UserState = {
  isLoggedIn: false,
  preferredLanguage: 'en',
  fontSizeIncrement: 0,
  userInfo: {
    name: '',
    phoneNumber: '',
    userId: -1,
  },
  editingAddress: {
    name: '',
    location: { latitude: 49.2827291, longitude: -123.1207375 },
  },
  googleMayWebKey: 'AIzaSyCkITylVziUA-UeyhgYD57qmKkL1t2Z_4I',
  appMainColor: '#2cae35',
  appOutOfDate: false,
  appStoreLink: '',
  displayFloatCS: false,
  isLocatedInChina: false,
  mapboxToken:
    'sk.eyJ1IjoibHVuaXUiLCJhIjoiY2tlYnp1OHhyMGR2MjJxcWphYjBodjdxcyJ9.eAR7QewvSx4Y6_zDpOoPqA',
  appLoginAppId: '',
  webLoginAppId: '',
  otherAppId: '',
  universalLink: 'https://luniumall.com/',
};

const userReducer = (state = initState, action: ReducerActionType) => {
  switch (action.type) {
    case 'CHANGE_LANGUAGE_PREFERENCE':
      return { ...state, preferredLanguage: action.payload };

    case 'CHANGE_FONT_SIZE':
      return { ...state, fontSizeIncrement: action.payload };

    case 'CHANGE_USER_STATUS':
      return {
        ...state,
        userInfo: action.payload.userInfo,
        isLoggedIn: action.payload.isLoggedIn,
      };

    case 'CHANGE_LOGIN_STATUS':
      return {
        ...state,
        isLoggedIn: action.payload,
      };

    case 'UPDATE_EDITING_ADDRESS':
      return {
        ...state,
        editingAddress: action.payload,
      };

    case 'UPDATE_GOOGLE_MAP_KEY':
      return {
        ...state,
        googleMayWebKey: action.payload,
      };

    case 'CHANGE_APP_MAIN_COLOR':
      return { ...state, appMainColor: action.payload };

    case 'CHANGE_APP_OUT_OF_DATE':
      return { ...state, appOutOfDate: action.payload };

    case 'CHANGE_APP_STORE_LINK':
      return { ...state, appStoreLink: action.payload };

    case 'DISPLAY_FLOAT_CS':
      return { ...state, displayFloatCS: action.payload };

    case 'LOCATED_IN_CHINA':
      return { ...state, isLocatedInChina: action.payload };

    case 'UPDATE_MAPBOX_MAP_KEY':
      return { ...state, mapboxToken: action.payload };

    case 'UPDATE_APP_LOGIN_APP_ID':
      return { ...state, appLoginAppId: action.payload };

    case 'UPDATE_WEB_LOGIN_APP_ID':
      return { ...state, webLoginAppId: action.payload };

    case 'UPDATE_OTHER_APP_ID':
      return { ...state, otherAppId: action.payload };

    case 'UPDATE_UNIVERSAL_LINK':
      return { ...state, universalLink: action.payload };

    default:
      return state;
  }
};

export default userReducer;
