import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
  TouchableWithoutFeedback,
  StyleProp,
  TextStyle,
  View,
} from 'react-native';
import { AdItem, OrderResultAd } from '../../types';
import { navigateBasedOnURL } from '../../utils/linkingRoute';

type HomeLinkNavigatorProps = {
  data: AdItem | OrderResultAd;
  children: React.ReactNode;
  style?: StyleProp<TextStyle>;
  /**
   * Extra Function for this component run
   */
  extraFn?: Function;
};

/**
 * Wrapper component specific for handling home navigation
 */
const HomeLinkNavigator = ({
  data,
  children,
  style = {},
  extraFn,
}: HomeLinkNavigatorProps) => {
  const dispatch = useDispatch();
  const navigation = useNavigation<any>();

  const handleItemPress = () => {
    navigateBasedOnURL({
      navigation: navigation,
      type: data.link.type,
      url_type: data.link.url_type,
      id: data.link.id,
      url: data.link.url,
      appId: data.link.appid,
      name: data.link.name,
      title: data.link.title,
    });

    if (data.link.url_type === 'inner' && data.link.type === 'category') {
      dispatch({
        type: 'UPDATE_SELECTED_HOME_CATEGORY_NAME',
        payload: data.title,
      });
    }

    if (extraFn) extraFn();
  };

  return (
    <TouchableWithoutFeedback onPress={handleItemPress}>
      <View style={style}>{children}</View>
    </TouchableWithoutFeedback>
  );
};

export default HomeLinkNavigator;
