import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import StyledText from '../../common/StyledText';
import { AntDesign } from '@expo/vector-icons';
import { tintColorLight, normalTextColor } from '../../../constants/Colors';
import { useTranslation } from 'react-i18next';
import ListOption from '../../common/list/ListOption';
import EditButton from '../../common/iconButtons/EditButton';
import { postWithAuthToken } from '../../../utils/service';
import Toast from 'react-native-tiny-toast';
import { useNavigation } from '@react-navigation/native';
import BottomAutoHeightModal from '../../common/BottomAutoHeightModal';

type GenderEditFormProps = {
  title: string;
  value: string;
};

const GenderEditForm = ({ title, value }: GenderEditFormProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  let currentGenderValue = 0;

  switch (value) {
    default:
    case t('userInfoScreen.listOptions.genderType.unknown'):
      break;
    case t('userInfoScreen.listOptions.genderType.male'):
      currentGenderValue = 1;
      break;
    case t('userInfoScreen.listOptions.genderType.female'):
      currentGenderValue = 2;
      break;
  }

  const [modalVisible, setModalVisible] = useState(false);
  const [genderValue, setGenderValue] = useState(currentGenderValue);
  const [oldGenderText, setOldGenderText] = useState(value);

  const handleSubmit = () => {
    setModalVisible(false);
    postWithAuthToken('user/sex/edit', { sex: genderValue })
      .then((result) => {
        if (result && result.goodStatus) {
          switch (genderValue) {
            default:
            case 0:
              setOldGenderText(
                t('userInfoScreen.listOptions.genderType.unknown')
              );
              break;
            case 1:
              setOldGenderText(t('userInfoScreen.listOptions.genderType.male'));
              break;
            case 2:
              setOldGenderText(
                t('userInfoScreen.listOptions.genderType.female')
              );
              break;
          }

          //Successfully update, go back to info screen
          Toast.showSuccess(t('userInfoEditScreen.updateSuccessfully'));

          let newGenderText = '';
          switch (genderValue) {
            default:
            case 0:
              newGenderText = t(
                'userInfoScreen.listOptions.genderType.unknown'
              );
              break;
            case 1:
              newGenderText = t('userInfoScreen.listOptions.genderType.male');
              break;
            case 2:
              newGenderText = t('userInfoScreen.listOptions.genderType.female');

              break;
          }

          navigation.navigate('UserInfo', { newGender: newGenderText });
        } else {
          Toast.show(result?.data ? result.data : 'Error');
        }
      })
      .catch((err) => Toast.show(err));
  };

  return (
    <View>
      <ListOption>
        <TouchableOpacity onPress={() => setModalVisible(true)}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <StyledText>{title}</StyledText>
            <View style={styles.valueContainer}>
              <StyledText>{oldGenderText}</StyledText>
              <EditButton onPress={() => setModalVisible(true)} />
            </View>
          </View>
        </TouchableOpacity>
      </ListOption>
      <BottomAutoHeightModal
        visible={modalVisible}
        hideModal={() => setModalVisible(false)}
      >
        <View style={styles.headerMenuContainer}>
          <AntDesign
            name="close"
            size={24}
            color={normalTextColor}
            onPress={() => setModalVisible(false)}
          />
          <AntDesign
            name="check"
            size={24}
            color={tintColorLight}
            onPress={handleSubmit}
          />
        </View>
        <View style={styles.pickerContainer}>
          <Picker
            selectedValue={genderValue}
            accessibilityLabel="gender"
            onValueChange={(value) => setGenderValue(value)}
          >
            <Picker.Item
              label={t('userInfoScreen.listOptions.genderType.unknown')}
              value={0}
            />
            <Picker.Item
              label={t('userInfoScreen.listOptions.genderType.male')}
              value={1}
            />
            <Picker.Item
              label={t('userInfoScreen.listOptions.genderType.female')}
              value={2}
            />
          </Picker>
        </View>
      </BottomAutoHeightModal>
    </View>
  );
};

const styles = StyleSheet.create({
  headerMenuContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  valueContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  pickerContainer: {},
});

export default GenderEditForm;
