import React, { useState } from 'react';
import { View, StyleSheet, Image, Platform } from 'react-native';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import InputField from './InputField';
import { Feather, AntDesign, Entypo } from '@expo/vector-icons';
import { GREY3 } from '../../constants/Colors';
import {
  loginWithPhoneNumber,
  registerForPushNotificationsAsync,
  savePushToken,
} from '../../utils/service';
import { LoginSchema } from '../../utils/validation';
import ErrorMessage from './ErrorMessage';
import { setAsyncStorageData } from '../../utils/asyncStorage';
import { useNavigation } from '@react-navigation/native';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { TokenPayload } from '../../types';
// @ts-ignore
import Toast from 'react-native-tiny-toast';
import getAppStyle from '../../utils/getAppStyle';
import StyledButton from '../common/StyledButton';
import * as Linking from 'expo-linking';

const LoginForm = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const dispatch = useDispatch();

  const handleLogin = async (phoneNumber: string, password: string) => {
    const loading = Toast.showLoading('');
    let referer: any = '';
    let redirectUrl: any = '';
    const url = await Linking.getInitialURL();
    if (url) {
      const { queryParams } = Linking.parse(url);
      if (queryParams) {
        if (queryParams.referer) {
          referer = queryParams.referer;
        }
        if (queryParams.redirect) {
          redirectUrl = queryParams.redirect;
        }
      }
    }

    loginWithPhoneNumber(
      phoneNumber,
      password,
      referer && { headers: { 'Referer-Platform': referer } }
    )
      .then((result) => {
        Toast.hide(loading);
        if (result && result.goodStatus) {
          if (redirectUrl) {
            let newUrl = redirectUrl;
            if (
              result.data.third_party &&
              typeof result.data.third_party === 'object'
            ) {
              Object.keys(result.data.third_party).forEach((key, i) => {
                if (i === 0) {
                  newUrl += `?${key}=${result.data.third_party[key]}`;
                } else {
                  newUrl += `&${key}=${result.data.third_party[key]}`;
                }
              });
            }
            Linking.canOpenURL(newUrl)
              .then((supported) => {
                supported && Linking.openURL(newUrl);
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            setAsyncStorageData('@storage_AuthToken', result.data.token);
            const tokenInfo: TokenPayload = jwtDecode(result.data.token);
            dispatch({
              type: 'CHANGE_USER_STATUS',
              payload: {
                userInfo: {
                  name: tokenInfo.user_name,
                  phoneNumber: tokenInfo.mobile_phone,
                  userId: tokenInfo.user_id,
                },
                isLoggedIn: true,
              },
            });

            //Save device expo push token if user grant
            if (Platform.OS === 'ios' || Platform.OS === 'android') {
              registerForPushNotificationsAsync().then(async (token) => {
                if (token && tokenInfo) {
                  savePushToken(token, {
                    id: tokenInfo.user_id,
                    exp: tokenInfo.exp,
                  });
                }
              });
            }

            Platform.OS === 'web'
              ? getAppStyle(process.env.EXPO_APP_TYPE)
                  ?.loginRequiredAccessAllPages
                ? navigation.reset({
                    index: 0,
                    routes: [{ name: 'Root' }],
                  })
                : navigation.canGoBack()
                ? navigation.goBack()
                : window.history.back()
              : getAppStyle(process.env.EXPO_APP_TYPE)
                  ?.loginRequiredAccessAllPages
              ? navigation.reset({
                  index: 0,
                  routes: [{ name: 'Root' }],
                })
              : navigation.goBack();
          }
        } else {
          throw result?.data;
        }
      })
      .catch((err) => {
        if (loading) Toast.hide(loading);
        Toast.show(err, {
          position: 0,
          duration: 2000,
        });
      });
  };

  return (
    <View style={styles.loginForm}>
      <View style={styles.brandContainer}>
        <Image
          source={getAppStyle(process.env.EXPO_APP_TYPE)?.loginLogo}
          style={
            Platform.OS === 'web'
              ? { width: 120, height: 120 }
              : {
                  width: 175,
                  height: 175,
                }
          }
          resizeMode="cover"
        />
      </View>
      <Formik
        initialValues={{ phone: '', password: '' }}
        validationSchema={LoginSchema}
        onSubmit={(values) => handleLogin(values.phone, values.password)}
      >
        {({ handleChange, handleSubmit, values, errors, touched }) => (
          <View
            style={{
              alignItems: 'center',
            }}
          >
            <InputField
              iconComponent={
                <Feather name="smartphone" size={24} color={GREY3} />
              }
              onChangeText={handleChange('phone')}
              placeholder={t('AuthModal.enterPhoneNumber')}
              keyboardType="phone-pad"
              value={values.phone}
            />
            <View style={styles.errorMsgContainer}>
              {errors.phone && touched.phone && (
                <ErrorMessage label={errors.phone} />
              )}
            </View>
            <InputField
              buttonComponent={
                <Entypo
                  name={hidePassword ? 'eye' : 'eye-with-line'}
                  size={24}
                  color="black"
                  onPress={() => setHidePassword((prev) => !prev)}
                />
              }
              passwordField={hidePassword}
              iconComponent={<AntDesign name="lock" size={24} color={GREY3} />}
              onChangeText={handleChange('password')}
              placeholder={t('AuthModal.enterPassword')}
              value={values.password}
            />
            <View style={styles.errorMsgContainer}>
              {errors.password && touched.password && (
                <ErrorMessage label={errors.password} />
              )}
            </View>
            <StyledButton
              label={t('AuthModal.login')}
              onPress={handleSubmit}
              color={getAppStyle(process.env.EXPO_APP_TYPE)?.mainColor}
            />
          </View>
        )}
      </Formik>
    </View>
  );
};

export default LoginForm;

const styles = StyleSheet.create({
  loginForm: {
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  brandContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorMsgContainer: {
    height: 24,
  },
});
