import { Alert, Platform } from 'react-native';
import { store } from '../App';
import i18 from 'i18next';

const alertPolyfill = (
  title: string,
  message?: string,
  buttons?: any,
  options?: any
) => {
  store.dispatch({
    type: 'SET_ALERT_MODAL',
    payload: {
      visible: true,
      title,
      message,
      buttons,
      options,
    },
  });
};

/**
 * Compatible alert method for all platform, use this instead of React Native Alert
 */
const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert;

export const errorAlertAndGoBack = (message: string, navigation: any) => {
  alert(message, '', [
    {
      text: i18.t('goBack'),
      onPress: () => {
        if (navigation.canGoBack()) {
          navigation.goBack();
        } else {
          if (Platform.OS === 'web') {
            //@ts-ignore
            window.history.back();
            navigation.reset({
              index: 0,
              routes: [{ name: 'Root' }],
            });
          } else {
            navigation.reset({
              index: 0,
              routes: [{ name: 'Root' }],
            });
          }
        }
      },
    },
  ]);
};

export default alert;
