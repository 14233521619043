import React from 'react';
import { ProductInformation, GoodsInfo } from '../../../types';
import StyledText from '../../common/StyledText';
import { StyleSheet, View } from 'react-native';
import Watermark from './Watermark';
import { useTranslation } from 'react-i18next';

type ProductImageTagProps = {
  data: ProductInformation | GoodsInfo;
};

const ProductImageTag = ({ data }: ProductImageTagProps) => {
  const soldOut = data.stock === 0 && data.specification.length === 0;

  return (
    <>
      {soldOut ? (
        <SoldOutCover />
      ) : data.text_tag ? (
        <View
          style={[styles.textTag, { backgroundColor: data.text_tag.color }]}
        >
          <StyledText size={12} color={'white'}>
            {data.text_tag.text}
          </StyledText>
        </View>
      ) : (
        <Watermark watermarkType={data.watermark_img}/>
      )}
    </>
  );
};

const SoldOutCover = () => {
  const { t } = useTranslation();

  return (
    <View style={styles.soldOutCoverContainer}>
      <View style={styles.soldOutCoverTextContainer}>
        <StyledText color="white" align="center" size={16}>
          {t('productInformation.soldOut')}
        </StyledText>
      </View>
    </View>
  );
};

export default ProductImageTag;

const styles = StyleSheet.create({
  soldOutCoverContainer: {
    backgroundColor: 'rgba(0,0,0,.5)',
    position: 'absolute',
    width: 100,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  soldOutCoverTextContainer: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: 'white',
    height: 80,
    width: 80,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textTag: {
    paddingHorizontal: 6,
    borderRadius: 8,
    paddingVertical: 2,
    position: 'absolute',
    left: 1,
    top: 1,
  },
  textTagShareImage: {
    paddingHorizontal: 16,
    borderRadius: 10,
    paddingVertical: 6,
    position: 'absolute',
    left: 20,
    top: 20,
  },
});
