import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import FullWidthView from '../../components/common/FullWidthView';
import NavigateButton from '../../components/common/NavigateButton';
import StyledText from '../../components/common/StyledText';
import { backGroundLight } from '../../constants/Colors';

const RegistrationApprovalScreen = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  return (
    <FullWidthView edges={['top']}>
      <NavigateButton goBack isAuthModal={true}>
        <AntDesign
          name="close"
          size={30}
          onPress={() => {
            navigation.navigate('AuthModal');
          }}
        />
      </NavigateButton>
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <AntDesign name="clockcircle" size={80} color={backGroundLight} />

        <StyledText size={16}>
          {t('AuthModal.registrationApproval.partOne')}
        </StyledText>
        <StyledText size={16}>
          {t('AuthModal.registrationApproval.partTwo')}
        </StyledText>
      </View>
    </FullWidthView>
  );
};

export default RegistrationApprovalScreen;
