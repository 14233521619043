import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import BottomAutoHeightModal from '../BottomAutoHeightModal';
import CachedImage from '../cache-img/CachedImage';
import StyledText from '../StyledText';

type WechatShareModalProps = {
  visible: boolean;
  hideModal: () => void;
  handleShare: Function;
  extraType?: JSX.Element;
};

const WechatShareModal = ({
  visible,
  hideModal,
  handleShare,
  extraType = <></>,
}: WechatShareModalProps) => {
  const { t } = useTranslation();

  return (
    <BottomAutoHeightModal visible={visible} hideModal={hideModal}>
      <View>
        <View style={styles.header}>
          <StyledText size={16} bold>
            {t('productDetailScreen.sharePrompt.title')}
          </StyledText>
          <TouchableOpacity onPress={() => hideModal()}>
            <StyledText align="right">{t('cancel')}</StyledText>
          </TouchableOpacity>
        </View>
        <View style={styles.content}>
          <TouchableOpacity onPress={() => handleShare('wechat')}>
            <View style={styles.buttonContainer}>
              <View style={[styles.button, { backgroundColor: '#07C160' }]}>
                <AntDesign name="wechat" size={30} color="white" />
              </View>
              <StyledText>
                {t('productDetailScreen.sharePrompt.wechat')}
              </StyledText>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handleShare('moments')}>
            <View style={styles.buttonContainer}>
              <View
                style={[
                  styles.button,
                  { borderColor: 'lightgray', borderWidth: 1 },
                ]}
              >
                <CachedImage
                  source={require('../../../assets/images/brands/wechat_moments.png')}
                  style={{ width: 40, height: 40 }}
                />
              </View>
              <StyledText>
                {t('productDetailScreen.sharePrompt.moments')}
              </StyledText>
            </View>
          </TouchableOpacity>
          {extraType}
        </View>
      </View>
    </BottomAutoHeightModal>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10,
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 10,
  },
  buttonContainer: {
    alignItems: 'center',
    marginHorizontal: 10,
  },
  button: {
    borderRadius: 100,

    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
  },
});

export default WechatShareModal;
