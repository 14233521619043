import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../types';
import React, { useEffect } from 'react';
import { getDataWithAuthToken } from '../../utils/service';
import NotificationDot from '../common/widgets/NotificationDot';
import { AntDesign } from '@expo/vector-icons';
import { Dimensions, Platform } from 'react-native';

const { width } = Dimensions.get('window');

const CartTabItem = ({ color }: { color: string }) => {
  const dispatch = useDispatch();

  const cartItemCounter = useSelector<RootState, number>(
    (state) => state.goods.shoppingCart.itemCounter
  );

  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.user.isLoggedIn
  );

  useEffect(() => {
    if (isLoggedIn) {
      getDataWithAuthToken('cart/number')
        .then((result) => {
          if (result && result.goodStatus) {
            dispatch({
              type: 'UPDATE_CART_ITEM_COUNTER',
              payload: result.data.cart_number,
            });
          }
        })
        .catch((err) => console.log('Err', err));
    } else {
      dispatch({ type: 'UPDATE_CART_ITEM_COUNTER', payload: 0 });
    }
  }, [isLoggedIn]);

  return (
    <>
      {cartItemCounter > 0 && (
        <NotificationDot
          label={cartItemCounter.toString()}
          otherStyles={{ right: width < 600 ? width * 0.06 : 0 }}
        />
      )}
      <AntDesign
        style={{
          marginBottom: -3,
          marginTop: Platform.OS === 'android' ? 10 : 0,
        }}
        name="shoppingcart"
        color={color}
        size={24}
      />
    </>
  );
};

export default CartTabItem;
