import React from 'react';
import { View, StyleSheet } from 'react-native';
import StyledButton from '../../components/common/StyledButton';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

const NoLocation = () => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  const handleNavigateRegionScreen = () => {
    navigation.navigate('Modal', { screen: 'UserLocationModal' });
  };

  return (
    <View style={styles.buttonContainer}>
      <StyledButton
        label={t('regionScreen.manuallySelect')}
        onPress={handleNavigateRegionScreen}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    marginTop: 10,
  },
});

export default NoLocation;
