import { AntDesign } from '@expo/vector-icons';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import QRCode from 'react-native-qrcode-generator';
import QRCodeWeb from 'react-qr-code';
import { successGreen } from '../../../constants/Colors';
import { qrCode, Voucher } from '../../../types';
import GeneralModal from '../../common/GeneralModal';
import StyledText from '../../common/StyledText';
import moment from 'moment';
import StyledButton from '../../common/StyledButton';

const { height, width } = Dimensions.get('window');

type VoucherModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  voucher?: Voucher;
  codeInfo?: qrCode;
  callBack?: (id: number) => void;
};

const VoucherModal = ({
  visible,
  setVisible,
  voucher,
  codeInfo,
  callBack,
}: VoucherModalProps) => {
  const { t } = useTranslation();
  const [seconds, setSeconds] = useState(0);
  const isSubscribed = useRef(true);

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (codeInfo) {
      setSeconds(codeInfo.expire_in_seconds);
    }
  }, [codeInfo]);

  useEffect(() => {
    const token = setTimeout(() => {
      if (isSubscribed.current && seconds > 0) {
        setSeconds((prev) => prev - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(token);
    };
  }, [seconds]);

  return (
    <GeneralModal
      animationType="fade"
      transparent={true}
      visible={visible}
      statusBarTranslucent={true}
      onRequestClose={() => setVisible(false)}
    >
      <View style={styles.modalContainer}>
        <View style={styles.mainContentContainer}>
          {!seconds && (
            <View
              style={{
                width: 250,
                height: 250,
                backgroundColor: 'rgba(0,0,0,0.9)',
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                top: 20,
                zIndex: 1,
              }}
            >
              <StyledButton
                label={t('vouchers.refresh')}
                buttonSize="small"
                onPress={() => {
                  if (voucher && callBack) callBack(voucher.bonus_id);
                }}
              />
            </View>
          )}
          {Platform.OS === 'web' ? (
            <QRCodeWeb
              value={codeInfo ? codeInfo.code : ''}
              size={250}
              bgColor={successGreen}
              fgColor="white"
            />
          ) : (
            <QRCode
              value={codeInfo ? codeInfo.code : ''}
              size={250}
              bgColor={successGreen}
              fgColor="white"
            />
          )}
          <StyledText style={styles.text}>
            {seconds
              ? `${t('vouchers.expireIn')} ${moment
                  .duration(seconds, 'seconds')
                  .minutes()}:${
                  moment.duration(seconds, 'seconds').seconds() < 10
                    ? `0${moment.duration(seconds, 'seconds').seconds()}`
                    : moment.duration(seconds, 'seconds').seconds()
                }`
              : t('vouchers.expiredTip')}
          </StyledText>
        </View>
        <TouchableWithoutFeedback onPress={() => setVisible(false)}>
          <View style={styles.closeBtn}>
            <AntDesign name="closecircle" size={40} color="white" />
          </View>
        </TouchableWithoutFeedback>
      </View>
    </GeneralModal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    backgroundColor: 'rgba(0,0,0,0.7)',
    justifyContent: 'center',
    alignItems: 'center',
    width: width,
    height: height,
    zIndex: 0,
    flex: 1,
  },
  mainContentContainer: {
    backgroundColor: 'white',
    padding: 20,
    zIndex: 10,
    borderRadius: 10,
    alignItems: 'center',
    position: 'relative',
  },
  closeBtn: {
    marginTop: 10,
  },
  text: {
    marginTop: 10,
  },
});

export default VoucherModal;
