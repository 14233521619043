import * as React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import * as Network from 'expo-network';
import { Feather } from '@expo/vector-icons';
import StyledText from '../components/common/StyledText';
import { backGroundLight, tintColorLight } from '../constants/Colors';
import { useTranslation } from 'react-i18next';

export default function NoInternetScreen({
  navigation,
}: any) {

  const { t } = useTranslation();

  const handlePressLink = async() => {
    const { isConnected } = await Network.getNetworkStateAsync()
    if(isConnected){
      navigation.replace('Root')
    }
  }

  return (
    <View style={styles.container}>
      <Feather name="wifi-off" size={100} color="lightgray" />
      <StyledText style={styles.title} bold size={18}>{t('noInternetScreen.noInternetFound')}</StyledText>
      <View style={styles.helpSection}>
  <StyledText bold>{t('noInternetScreen.tips')}{':'}</StyledText>
        <StyledText>
          {t('noInternetScreen.tipMessage')}
        </StyledText>
      </View>
      <TouchableOpacity onPress={handlePressLink} style={styles.reloadButton}>
        <StyledText color={'white'} >{t('noInternetScreen.tryAgain')}</StyledText>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    marginTop: 20
  },
  helpSection: {
    padding: 20,
    backgroundColor: backGroundLight,
    marginTop: 20
  },
  reloadButton: {
    padding: 10,
    borderRadius: 5,
    backgroundColor: tintColorLight,
    marginTop: 50
  }
});
