import React from 'react';
import { View, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import StyledText from '../common/StyledText';
import StyledButton from '../common/StyledButton';
import { GREY1, GREY3, ORANGE1 } from '../../constants/Colors';
import { useTranslation } from 'react-i18next';
import { AntDesign } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import { RootState, CartShop } from '../../types';
import { useNavigation } from '@react-navigation/native';
import alert from '../../utils/alert';
import { CheckBox } from 'react-native-elements';

type ShopFooterProps = {
  total: {
    amount: number;
    formated_amount: string;
    total_goods_number: number;
    checked_goods_number: number;
  };
  showAllItems: boolean;
  triggerCollapseView: React.Dispatch<React.SetStateAction<boolean[]>>;
  sectionIndex: number;
  itemCounter: number;
  isCalculating: boolean;
  ruId: number;
  tId: number;
  orderType: number;
  bestTime: number | null;
  allSelected: boolean;
  selectOrDeselectAllItems: Function;
};

const ShopFooter = ({
  total,
  showAllItems,
  triggerCollapseView,
  sectionIndex,
  itemCounter,
  isCalculating,
  tId,
  ruId,
  orderType,
  bestTime,
  allSelected,
  selectOrDeselectAllItems,
}: ShopFooterProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const cartList = useSelector<RootState, Array<CartShop>>(
    (state) => state.goods.shoppingCart.cartList
  );
  const appMainColor = useSelector<RootState, string>(
    (state) => state.user.appMainColor
  );

  const handleTriggerCollapseView = () => {
    triggerCollapseView((prev) =>
      prev.map((item, index) => (index === sectionIndex ? !item : item))
    );
  };

  const handleCheckout = () => {
    //Check if invalid product exist in cart
    const invalidProducts = cartList[sectionIndex].data.find(
      (item) => item.is_invalid === true && item.is_checked === 1
    );

    if (invalidProducts) {
      alert(
        t('cartScreen.footer.failToCheckout.title'),
        t('cartScreen.footer.failToCheckout.content')
      );
    } else {
      navigation.navigate('OrderPreview', {
        ruId: ruId,
        tId: tId,
        orderType: orderType,
        shippingType: tId === 0 ? 0 : 1,
        bundleShippingTime: bestTime || undefined,
      });
    }
  };

  return (
    <View style={styles.footerContainer}>
      {itemCounter >= 4 && !showAllItems && (
        <View style={styles.viewMoreContainer}>
          <TouchableOpacity
            style={styles.iconButton}
            onPress={handleTriggerCollapseView}
          >
            <StyledText align="center">{t('seeMore') + ' '}</StyledText>
            <AntDesign name="downcircleo" size={12} color="lightgray" />
          </TouchableOpacity>
        </View>
      )}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <View>
          <CheckBox
            size={18}
            textStyle={{
              fontSize: 14,
              fontWeight: 'normal',
              color: GREY3,
            }}
            fontFamily={'noto-sans'}
            title={t('postScreen.all')}
            checked={allSelected}
            onPress={() => {
              if (!isCalculating) {
                selectOrDeselectAllItems(ruId, allSelected ? 0 : 1);
              }
            }}
            checkedIcon={
              <AntDesign name="checkcircle" size={20} color={appMainColor} />
            }
            uncheckedIcon={
              <AntDesign name="checkcircle" size={20} color={GREY1} />
            }
            containerStyle={{
              backgroundColor: 'white',
              borderWidth: 0,
              marginLeft: 0,
              marginRight: 0,
              padding: 0,
              paddingTop: 5,
              paddingBottom: Platform.OS === 'ios' ? 4 : 0,
            }}
          />
        </View>

        <View style={styles.leftContainer}>
          <View style={styles.totalContainer}>
            <StyledText bold>{t('cartScreen.footer.total')} </StyledText>
            {
              <StyledText color={ORANGE1} bold size={14}>
                {isCalculating
                  ? t('cartScreen.footer.calculating')
                  : total?.formated_amount ?? '$0.00'}
              </StyledText>
            }
          </View>
        </View>
        <View style={styles.rightContainer}>
          <StyledButton
            buttonSize="small"
            label={`${t('cartScreen.footer.checkout')} (${
              total.checked_goods_number
            })`}
            onPress={handleCheckout}
            disabled={isCalculating || total.checked_goods_number === 0}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  footerContainer: {
    backgroundColor: 'white',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 10,
    alignItems: 'flex-end',
    marginBottom: 10,
    flexWrap: 'wrap',
  },
  viewMoreContainer: {
    width: '100%',
  },
  iconButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftContainer: {
    flexGrow: 1,
  },
  rightContainer: {
    justifyContent: 'flex-end',
  },
  totalContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
});

export default ShopFooter;
