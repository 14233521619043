import React, { useEffect, useState, ReactNode } from 'react';
import { WebView } from 'react-native-webview';
import FullWidthView from '../../common/FullWidthView';
import { useSelector } from 'react-redux';
import { RootState, LocationInformation } from '../../../types';
import { getCustomerService } from '../../../utils/service';
import Loading from '../../common/Loading';
import StyledText from '../../common/StyledText';
import {
  View,
  StyleSheet,
  Linking,
  Dimensions,
  ScrollView,
} from 'react-native';
import ResponsiveImg from '../../common/ResponsiveImg';
import { useTranslation } from 'react-i18next';
import { useHeaderHeight } from '@react-navigation/stack';

type serviceDataType = {
  name?: string;
  kf_link?: string;
  kf_logo?: string;
  kf_tel?: string;
  kf_wechat?: Array<object> | string;
};

const { width } = Dimensions.get('window');

const LiveChat = ({ ruId }: { ruId?: number }) => {
  const location = useSelector<RootState, LocationInformation>(
    (state) => state.goods.location
  );

  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const [serviceData, setServiceData] = useState<serviceDataType>({});
  const headerHeight = useHeaderHeight();

  useEffect(() => {
    //Send request to get customer service based on location information
    getCustomerServiceInfo(ruId)
      .then((result) => {
        if (result && result.goodStatus && result.data) {
          setServiceData(result.data);
          setLoadingComplete(true);
        } else {
          console.error(result);
        }
      })
      .catch((err) => console.log(err));

    async function getCustomerServiceInfo(ruId: number | undefined) {
      const result = await getCustomerService(
        location.countryId,
        location.provinceId,
        location.cityId,
        ruId
      );

      return result;
    }
  }, [location]);

  const renderServiceWindow = () => {
    if (serviceData) {
      //Live chat customer service available,render web view
      if (serviceData.kf_link) {
        return (
          <WebView
            source={{ uri: serviceData.kf_link }}
            startInLoadingState={true}
          />
        );
      }
      //Live chat customer service not available, show wechat customer service information
      else {
        return (
          <WechatCustomerServiceTextBlock
            kf_tel={serviceData.kf_tel}
            kf_wechat={serviceData.kf_wechat}
            kf_logo={serviceData.kf_logo}
            name={serviceData.name}
          />
        );
      }
    } else return <StyledText>Error</StyledText>;
  };

  return (
    <FullWidthView>
      {isLoadingComplete ? renderServiceWindow() : <Loading />}
    </FullWidthView>
  );
};

export default LiveChat;

const WechatCustomerServiceTextBlock = ({
  name,
  kf_tel,
  kf_logo,
  kf_wechat,
}: serviceDataType) => {
  const { t } = useTranslation();

  let renderWeChatName: ReactNode = <></>;

  if (Array.isArray(kf_wechat)) {
    renderWeChatName = kf_wechat.map((item, index) => {
      const values = Object.entries(item);
      return (
        <StyledText key={index} size={18}>
          {values[0][0]}: {values[0][1]}
        </StyledText>
      );
    });
  } else {
    renderWeChatName =
      typeof kf_wechat === 'string' ? (
        <StyledText size={18}>
          {t('customerService.wechatAccount')}: {kf_wechat}
        </StyledText>
      ) : (
        <></>
      );
  }

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={styles.container}
    >
      <StyledText align="center" bold size={20}>
        {name}
      </StyledText>
      <StyledText align="center" size={18}>
        {t('customerService.contactWay')}
      </StyledText>
      <ResponsiveImg width={width * 0.7} uri={kf_logo ? kf_logo : ''} />
      {renderWeChatName}
      {typeof kf_tel === 'string' && (
        <View style={styles.phoneContainer}>
          <StyledText size={18}>{t('customerService.phone')}: </StyledText>
          <StyledText
            size={18}
            onPress={() => {
              Linking.openURL(`tel:${kf_tel}`);
            }}
          >
            {kf_tel}
          </StyledText>
        </View>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  qrCodeContainer: {
    width: width * 0.7,
    height: width * 0.7,
  },
  phoneContainer: {
    flexDirection: 'row',
  },
});
