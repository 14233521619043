import React from 'react';
import FullWidthView from '../../../components/common/FullWidthView';
import AddressForm from '../../../components/User/Address/AddressForm';
import { useDispatch } from 'react-redux';

const NewAddressScreen = () => {
  const dispatch = useDispatch();

  dispatch({
    type: 'UPDATE_EDITING_ADDRESS',
    payload: {
      name: '',
      location: { latitude: 49.2827291, longitude: -123.1207375 },
    },
  });

  return (
    <FullWidthView>
      <AddressForm type='new' />
    </FullWidthView>
  );
};

export default NewAddressScreen;
