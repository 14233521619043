import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import {
  Dimensions,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
} from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import FullWidthView from '../../components/common/FullWidthView';
import StyledText from '../../components/common/StyledText';
import CouponList from '../../components/Coupon/CouponList';
import CouponListHeader from '../../components/Coupon/CouponListHeader';
import { backGroundLight } from '../../constants/Colors';

const DATA: Array<{
  id: number;
  type: 'unused' | 'used' | 'expired';
}> = [
  { id: 0, type: 'unused' },
  { id: 1, type: 'used' },
  { id: 2, type: 'expired' },
];

const { width } = Dimensions.get('window');

const CouponListScreen = () => {
  const navigation = useNavigation();
  const listRef = useRef<FlatList<{ id: number; type: string }>>(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [webCouponType, setWebCouponType] = useState<
    'unused' | 'used' | 'expired'
  >('unused');

  const [triggerReload, setTriggerReload] = useState(false);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      if (listRef && listRef.current) {
        listRef.current.scrollToIndex({ index: currentIndex, animated: false });
      }
    } else {
      //For web to render different type
      setWebCouponType(DATA[currentIndex].type);
    }
  }, [currentIndex]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setCurrentIndex(0);
      setTriggerReload(true);
      setTimeout(() => {
        setTriggerReload(false);
      }, 1);
    });

    return unsubscribe;
  }, [navigation]);

  const renderItem = ({ item }: any) => <CouponList couponType={item.type} />;

  const handleSwipePage = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    let contentOffset = e.nativeEvent.contentOffset;
    let viewSize = e.nativeEvent.layoutMeasurement;

    // Divide the horizontal offset by the width of the view to see which page is visible
    let pageNum = Math.floor((contentOffset.x + width / 2) / viewSize.width);
    setCurrentIndex(pageNum);
  };

  return (
    <FullWidthView style={{ backgroundColor: backGroundLight }}>
      <CouponListHeader
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
      {!triggerReload &&
        (Platform.OS !== 'web' ? (
          <FlatList
            ref={listRef}
            data={DATA}
            renderItem={renderItem}
            keyExtractor={(item) => item.id.toString()}
            horizontal
            pagingEnabled
            showsHorizontalScrollIndicator={false}
            onScroll={handleSwipePage}
            scrollEventThrottle={500}
          />
        ) : (
          <CouponList couponType={webCouponType} />
        ))}
    </FullWidthView>
  );
};

export default CouponListScreen;
