import React, { useEffect, useState } from 'react';
import { Dimensions, FlatList, Platform, View } from 'react-native';
import { ArticleListItemType } from '../../../types';
import { getData } from '../../../utils/service';
import ActivityIndicator from '../../common/ActivityIndicator';
import EmptyDisplayBlock from '../../common/EmptyDisplayBlock';
import ListFooterText from '../../common/list/ListFooterText';
import ListSeparator from '../../common/list/ListSeparator';
import ArticleListItem from './ArticleListItem';
import { FontAwesome } from '@expo/vector-icons';
import SkeletonContent from 'react-native-skeleton-content';
import { errorAlertAndGoBack } from '../../../utils/alert';
import { useNavigation } from '@react-navigation/native';
import axios, { CancelTokenSource } from 'axios';
import FullWidthView from '../../common/FullWidthView';
import { useHeaderHeight } from '@react-navigation/stack';

type ArticleListProps = {
  categoryId: number;
};

const { width } = Dimensions.get('window');

const ArticleList = ({ categoryId }: ArticleListProps) => {
  const navigation = useNavigation();
  const [articles, setArticles] = useState<ArticleListItemType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [firstLoadComplete, setFirstLoadComplete] = useState(false);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    let cancelToken: CancelTokenSource;
    cancelToken = axios.CancelToken.source();
    if (categoryId) {
      setIsFetching(true);
      (async () => {
        const result = await getArticleList(categoryId, currentPage);
        if (result && result.goodStatus) {
          if (isSubscribed) {
            if (currentPage === 1) {
              setFirstLoadComplete(true);
              setArticles(result.data.article);
              setTotalPage(result.data.total_page);
            } else {
              setArticles((prev) => [...prev, ...result.data.article]);
            }
            if (currentPage >= result.data.total_page) {
              setAllDataLoaded(true);
            }
            setIsFetching(false);
          }
        } else {
          setIsFetching(false);
          errorAlertAndGoBack(
            result && typeof result.data === 'string'
              ? result.data
              : 'Error about getting article list',
            navigation
          );
        }
      })();
    }

    return () => {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('operation canceled due to new request');
      }
      isSubscribed = false;
    };
  }, [currentPage, categoryId]);

  //For web to change different list
  useEffect(() => {
    setFirstLoadComplete(false);
    setCurrentPage(1);
  }, [categoryId]);

  const _renderItem = ({ item }: { item: ArticleListItemType }) => {
    return <ArticleListItem item={item} />;
  };

  const loadMore = () => {
    if (isFetching) return;
    if (currentPage < totalPage) {
      setCurrentPage((oldValue) => oldValue + 1);
    }
  };

  return !firstLoadComplete ? (
    <SkeletonContent
      containerStyle={{ width: width }}
      layout={[
        {
          width: width - 20,
          flexDirection: 'row',
          marginTop: 10,
          marginHorizontal: 10,
          children: [
            { width: 80, height: 80 },
            {
              children: [
                { width: 200, height: 20, marginLeft: 10 },
                {
                  width: width - 110,
                  height: 10,
                  marginLeft: 10,
                  marginTop: 5,
                },
                {
                  width: width - 110,
                  height: 10,
                  marginLeft: 10,
                  marginTop: 5,
                },
              ],
            },
          ],
        },
        {
          width: width - 20,
          flexDirection: 'row',
          marginTop: 10,
          marginHorizontal: 10,
          children: [
            { width: 80, height: 80 },
            {
              children: [
                { width: 200, height: 20, marginLeft: 10 },
                {
                  width: width - 110,
                  height: 10,
                  marginLeft: 10,
                  marginTop: 5,
                },
                {
                  width: width - 110,
                  height: 10,
                  marginLeft: 10,
                  marginTop: 5,
                },
              ],
            },
          ],
        },
      ]}
      isLoading={true}
    />
  ) : (
    <FullWidthView edges={['bottom', 'left', 'right']}>
      <FlatList
        data={articles}
        renderItem={_renderItem}
        keyExtractor={(item) => item.article_id.toString()}
        ItemSeparatorComponent={() => <ListSeparator />}
        onEndReached={loadMore}
        onEndReachedThreshold={0.01}
        ListFooterComponent={
          articles.length > 0 ? (
            allDataLoaded ? (
              ListFooterText
            ) : (
              ActivityIndicator
            )
          ) : (
            <></>
          )
        }
        ListEmptyComponent={
          <View style={{ height: 500 }}>
            <EmptyDisplayBlock
              icon={
                <FontAwesome name="newspaper-o" size={80} color="lightgray" />
              }
              // description={t('coupons.list.emptyMsg', {
              //   type: t('coupons.list.' + couponType),
              // })}
            />
          </View>
        }
      />
    </FullWidthView>
  );
};

const getArticleList = async (catId: number, page = 1) => {
  const result = await getData(
    `article/list?cat_id=${catId}&page=${page}&size=10`
  );
  return result;
};

export default ArticleList;
