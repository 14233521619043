import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/common/iconButtons/BackButton';
import NavigateButton from '../../components/common/NavigateButton';
import CategoriesScreen from '../../screens/User/GroupSale/CategoriesScreen';
import SubCategoryScreen from '../../screens/User/GroupSale/SubCategoryScreen';
import { GroupSaleParamList } from '../../types';
import getAppStyle from '../../utils/getAppStyle';

const GroupSaleStack = createStackNavigator<GroupSaleParamList>();

const GroupSaleNavigator = () => {
  const { t } = useTranslation();

  return (
    <GroupSaleStack.Navigator
      initialRouteName="Categories"
      screenOptions={{
        headerBackTitleVisible: false,
        headerLeft: () => (
          <NavigateButton goBack>
            <BackButton />
          </NavigateButton>
        ),
        headerTitleAlign: 'center',
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <GroupSaleStack.Screen
        name="Categories"
        component={CategoriesScreen}
        options={{ headerTitle: t('userProfileScreen.otherMenu.groupSale') }}
      />
      <GroupSaleStack.Screen
        name="SubCategory"
        component={SubCategoryScreen}
        options={{ headerShown: false }}
      />
    </GroupSaleStack.Navigator>
  );
};

export default GroupSaleNavigator;
