import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { CreditCardParamList } from '../../types';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/common/iconButtons/BackButton';
import NewCreditCardScreen from '../../screens/User/CreditCard/NewCreditCardScreen';
import CreditCardListScreen from '../../screens/User/CreditCard/CreditCardListScreen';
import AddCreditCardBtn from '../../components/User/CreditCard/AddCreditCardBtn';
import NavigateButton from '../../components/common/NavigateButton';
import getAppStyle from '../../utils/getAppStyle';

const CreditCardStack = createStackNavigator<CreditCardParamList>();

const CreditCardNavigator = () => {
  const { t } = useTranslation();

  return (
    <CreditCardStack.Navigator
      initialRouteName="CreditCardListScreen"
      screenOptions={{
        headerBackTitleVisible: false,
        headerLeft: () => (
          <NavigateButton goBack>
            <BackButton />
          </NavigateButton>
        ),
        headerTitleAlign: 'center',
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <CreditCardStack.Screen
        name="CreditCardListScreen"
        component={CreditCardListScreen}
        options={{
          title: t('creditCard.creditCards'),
          headerRight: () => <AddCreditCardBtn />,
        }}
      />
      <CreditCardStack.Screen
        name="NewCreditCardScreen"
        component={NewCreditCardScreen}
        options={{ title: t('creditCard.newCreditCard') }}
      />
    </CreditCardStack.Navigator>
  );
};

export default CreditCardNavigator;
