import React from 'react';
import { AddressInformation } from '../../../types';
import AddressItem from './AddressItem'

const AddressListRegion = ({
  data,
  type,
  handleRegionChange,
}: {
  data: Array<AddressInformation>;
  type: 'edit' | 'view' | 'region';
  handleRegionChange?: Function;
}) => {

  return (
    <>
        {data.map((item) => (
            <AddressItem key={item.address_id} item={item} type={type} handleRegionChange={handleRegionChange}/>
        ))}
    </>
  );
};

export default AddressListRegion;