import React from 'react';
import WhiteCard from '../../common/WhiteCard';
import { View, StyleSheet, Linking, TouchableOpacity } from 'react-native';
import StyledText from '../../common/StyledText';
import { subTextColor } from '../../../constants/Colors';
import { AntDesign } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import NavigateButton from '../../common/NavigateButton';

type PickupAddressInfoProps = {
  data: { name: string; mobile: string; address: string };
  openList?: Function;
  editable?: boolean;
  contact?: { name: string; phone: string };
  changeContact?: boolean;
};

const PickupAddressInfo = ({
  data,
  openList,
  editable = false,
  contact,
  changeContact = false,
}: PickupAddressInfoProps) => {
  const { t } = useTranslation();

  return (
    <WhiteCard title={t('address.pickupInfo')}>
      <View style={styles.container}>
        {Object.keys(data).length > 0 && (
          <>
            <View style={styles.row}>
              <View style={[styles.itemContainer, { flex: 2 }]}>
                <StyledText bold>
                  <AntDesign name="isv" size={20} color={subTextColor} />{' '}
                  {t('address.locationName')}
                </StyledText>
                <StyledText>{data.name}</StyledText>
              </View>
              <View style={styles.itemContainer}>
                <StyledText bold align="right">
                  <AntDesign name="phone" size={20} color={subTextColor} />{' '}
                  {t('address.info.phone')}
                </StyledText>
                <StyledText
                  align="right"
                  onPress={() => {
                    Linking.openURL(`tel:${data.mobile}}`);
                  }}
                >
                  {data.mobile}
                </StyledText>
              </View>
            </View>
            <View style={styles.row}>
              <View style={{ flex: 2 }}>
                <StyledText bold>
                  <AntDesign
                    name="enviromento"
                    size={20}
                    color={subTextColor}
                  />{' '}
                  {t('address.pickupAddress')}
                </StyledText>
                <StyledText>{data.address}</StyledText>
              </View>
              {editable && openList ? (
                <TouchableOpacity onPress={() => openList()}>
                  <StyledText color={subTextColor}>
                    {t('address.changeLocation')}
                    {' >'}
                  </StyledText>
                </TouchableOpacity>
              ) : (
                <></>
              )}
            </View>
          </>
        )}

        {contact && (
          <View style={styles.customerInfo}>
            <View style={styles.row}>
              <StyledText bold>
                <AntDesign name="user" size={20} color={subTextColor} />{' '}
                {t('address.customerForPickup')}
              </StyledText>
              {changeContact && (
                <NavigateButton
                  route="Address"
                  options={{
                    screen: 'AddressList',
                    params: {
                      type: 'view',
                    },
                  }}
                >
                  <StyledText color={subTextColor}>
                    {t('address.changeContact')}
                    {' >'}
                  </StyledText>
                </NavigateButton>
              )}
            </View>

            <View style={styles.row}>
              <StyledText style={styles.column}>{contact?.name}</StyledText>
              <StyledText align="right" style={styles.column}>
                {contact?.phone}
              </StyledText>
            </View>
          </View>
        )}
      </View>
    </WhiteCard>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  column: {
    flex: 1,
  },
  itemContainer: { marginBottom: 10, flex: 1 },
  customerInfo: {
    marginTop: 10,
  },
});

export default PickupAddressInfo;
