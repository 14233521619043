import React, { useState } from 'react';
import {
  View,
  StyleSheet,
  Dimensions,
  Linking,
  TouchableWithoutFeedback,
} from 'react-native';
import NavigateButton from '../common/NavigateButton';
import { AntDesign } from '@expo/vector-icons';
import StyledText from '../common/StyledText';
import { useTranslation } from 'react-i18next';
import { GoodsInfo } from '../../types';

const { width } = Dimensions.get('window');

type ProductPageHeaderProps = {
  pName: string;
  pId: number;
  pThumbnail: string;
  product?: GoodsInfo;
};

const ProductPageHeader = ({ pId }: ProductPageHeaderProps) => {
  const { t } = useTranslation();

  const handleOpenAppFromWeb = () => {
    Linking.openURL(
      `https://luniumall.com/files/share.html?id=${pId}&type=product`
    );
  };

  return (
    <View style={styles.header}>
      <View>
        <NavigateButton goBack>
          <View style={styles.btn}>
            <AntDesign
              name="left"
              size={25}
              color="white"
              style={{ height: 25 }}
            />
          </View>
        </NavigateButton>
      </View>
      {/* No native wechat sdk share function in web environment */}
      {navigator.userAgent.toLocaleLowerCase().indexOf('alipay') === -1 && (
        <View style={styles.openAppTipContainer}>
          <View style={styles.openAppTip}>
            <TouchableWithoutFeedback onPress={handleOpenAppFromWeb}>
              <StyledText size={12} color="white" align="center">
                {t('productDetailScreen.useApp')}
              </StyledText>
            </TouchableWithoutFeedback>
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    width: width,
    padding: 10,
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  shareBtn: {
    width: 32,
    height: 32,

    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  openAppTipContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  openAppTip: {
    backgroundColor: 'rgba(0,0,0,.3)',
    padding: 10,
    borderRadius: 30,
  },
  btn: {
    width: 35,
    height: 35,
    backgroundColor: 'rgba(0,0,0,0.3)',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    overflow: 'hidden',
  },
});

export default ProductPageHeader;
