import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ImageBackground, StyleSheet, View } from 'react-native';
import { getDataWithAuthToken } from '../../../utils/service';
import { qrCode, Voucher } from '../../../types';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import Loading from '../../../components/common/Loading';
import { AntDesign } from '@expo/vector-icons';
import StyledText from '../../../components/common/StyledText';
import StyledButton from '../../../components/common/StyledButton';
import VoucherModal from '../../../components/User/Vouchers/VouchersModal';
import ResponsiveImg from '../../../components/common/ResponsiveImg';
import FullWidthView from '../../../components/common/FullWidthView';
import ListFooterText from '../../../components/common/list/ListFooterText';
import ActivityIndicator from '../../../components/common/ActivityIndicator';
import { useHeaderHeight } from '@react-navigation/stack';
import { backGroundLight } from '../../../constants/Colors';

const VoucherListScreen = () => {
  const { t } = useTranslation();
  const [firstLoadCompleted, setIsFirstLoadCompleted] = useState(false);
  const [vouchers, setVouchers] = useState<Voucher[]>([]);
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState<Voucher>();
  const [code, setCode] = useState<qrCode>();
  const [allLoaded, setAllLoaded] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const isSubscribed = useRef(true);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getCode = (id: number) => {
    getDataWithAuthToken('user/bonus/code', {
      params: { bonus_id: id },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setCode(response.data);
              setShowModal(true);
            }
          } else {
            if (isSubscribed.current) setCode(undefined);
            throw response.data;
          }
        } else {
          if (isSubscribed.current) setCode(undefined);
          throw 'no qrCode found';
        }
      })
      .catch((err) => {
        if (isSubscribed.current) setCode(undefined);
        Toast.show(err, {
          position: 0,
          duration: 2000,
        });
      });
  };

  const fetchData = useCallback(() => {
    setIsFetching(true);
    getDataWithAuthToken('user/bonus/list', {
      params: {
        page: page,
        size: 10,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              if (page === response.data.total_page) setAllLoaded(true);
              setTotalPage(response.data.total_page);
              setVouchers((prev) => [...prev, ...response.data.bonus]);
            }
          } else {
            Toast.show(response.data, { position: 0, duration: 2000 });
          }
        } else {
          Toast.show('no voucher found', { position: 0, duration: 2000 });
        }
      })
      .catch((err) => {
        Toast.show('err', { position: 0, duration: 2000 });
      })
      .finally(() => {
        if (isSubscribed.current) {
          setIsFirstLoadCompleted(true);
          setIsFetching(false);
        }
      });
  }, [page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleReachBottomUpdate = () => {
    if (isFetching) return;
    if (isSubscribed.current && !allLoaded && page < totalPage) {
      setPage((prev) => prev + 1);
    }
  };

  const renderItem = ({ item }: { item: Voucher }) => (
    <View style={styles.item}>
      <View style={styles.itemLeft}>
        <StyledText size={26} color="red" bold>
          {item.formated_amount}
        </StyledText>
      </View>
      <ImageBackground
        source={{ uri: item.bonus.shop.logo_thumb }}
        blurRadius={10}
        borderRadius={8}
        style={styles.itemRight}
      >
        <View style={styles.itemRightHeader}>
          <ResponsiveImg
            uri={item.bonus.shop.logo_thumb}
            width={20}
            borderRadius={50}
          />
          <StyledText size={12} style={styles.itemRightHeaderText}>
            {item.bonus.shop.shop_name}
          </StyledText>
        </View>
        <View style={styles.itemRightContent}>
          <StyledText size={18} bold>
            {item.bonus.type_name}
          </StyledText>
          <StyledButton
            disabled={item.is_use || !item.is_valid ? true : false}
            label={
              !item.is_valid
                ? t('vouchersScreen.expired')
                : item.is_use
                ? t('vouchersScreen.used')
                : t('vouchersScreen.use')
            }
            buttonSize="small"
            onPress={() => {
              setSelectedVoucher(item);
              getCode(item.bonus_id);
            }}
          />
        </View>
        <StyledText size={12} color="gray">
          {`${t('vouchers.expireOn')} ${item.bonus.use_end_date}`}
        </StyledText>
      </ImageBackground>
      <View style={styles.dotTop}></View>
      <View style={styles.dotBottom}></View>
    </View>
  );

  return (
    <FullWidthView
      edges={['bottom', 'left', 'right']}
      style={{ backgroundColor: backGroundLight }}
    >
      {!firstLoadCompleted ? (
        <Loading />
      ) : vouchers.length > 0 ? (
        <FlatList
          style={{ flex: 1 }}
          data={vouchers}
          renderItem={renderItem}
          onEndReachedThreshold={0.01}
          onEndReached={() => handleReachBottomUpdate()}
          keyExtractor={(item) => item.bonus_id.toString()}
          ListFooterComponent={allLoaded ? ListFooterText : ActivityIndicator}
          /* bump these up so when user does fast scrolling, items can render properly. */
          maxToRenderPerBatch={200}
          windowSize={41}
        />
      ) : (
        <EmptyFavoritesBlock />
      )}
      <VoucherModal
        visible={showModal}
        setVisible={setShowModal}
        voucher={selectedVoucher}
        codeInfo={code}
        callBack={(id) => getCode(id)}
      />
    </FullWidthView>
  );
};

const EmptyFavoritesBlock = () => {
  const { t } = useTranslation();

  return (
    <View style={styles.emptyVoucherBlockContainer}>
      <AntDesign name="tago" size={80} color="lightgray" />
      <StyledText size={18} color="gray" style={{ marginBottom: 5 }}>
        {t('vouchers.noVouchers')}
      </StyledText>
      <StyledText
        size={16}
        color="lightgray"
        style={{ textAlign: 'center', width: 250 }}
      >
        {t('vouchers.emptyVouchersTip')}
      </StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: backGroundLight,
  },
  item: {
    flexDirection: 'row',
    marginTop: 16,
    marginHorizontal: 16,
    position: 'relative',
  },
  itemLeft: {
    width: '32%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    backgroundColor: 'rgba(244, 188, 188, 0.5)',
    borderRadius: 8,
  },
  itemRight: {
    width: '68%',
    padding: 10,
    borderRadius: 8,
    backgroundColor: 'white',
  },
  itemRightHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemRightHeaderText: {
    marginLeft: 5,
  },
  itemRightContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  emptyVoucherBlockContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    borderRadius: 8,
    backgroundColor: 'white',
    borderColor: 'red',
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 3,
  },
  dotTop: {
    width: 16,
    height: 16,
    backgroundColor: backGroundLight,
    borderRadius: 15,
    position: 'absolute',
    top: -8,
    left: '30%',
  },
  dotBottom: {
    width: 16,
    height: 16,
    backgroundColor: backGroundLight,
    borderRadius: 15,
    position: 'absolute',
    bottom: -8,
    left: '30%',
  },
});

export default VoucherListScreen;
