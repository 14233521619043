import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, TouchableOpacity, View } from 'react-native';
import SkeletonContent from 'react-native-skeleton-content';
import { useDispatch, useSelector } from 'react-redux';
import { tintColorLight } from '../../../constants/Colors';
import {
  LocationInformation,
  ProductInformation,
  RootState,
} from '../../../types';
import { getGoodsListWithId, getGoodsUnderBrand } from '../../../utils/service';
import StyledText from '../../common/StyledText';
import OneThirdCard from '../../Product/OneThirdCard';

type FixedGoodsListProps = {
  data: {
    ad_id: number;
    media_type: number;
    media_type_name: string;
    cat_id: number;
    cat_name: string;
    goods_list_style: number;
    goods_type: string;
    title_color: string;
    show_title?: 0 | 1;
    item_number: number;
    brand_id: number;
    brand_name?: string;
    brand_item_number?: number;
  };
  marginBottom?: number;
  marginHorizontal?: number;
  backgroundColor?: string;
};

const { width } = Dimensions.get('window');

const FixedGoodsList = ({
  data,
  marginBottom = 10,
  marginHorizontal = 10,
  backgroundColor = 'white',
}: FixedGoodsListProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const location = useSelector<RootState, LocationInformation>(
    (state) => state.goods.location
  );

  const [goodsArray, setGoodsArray] = useState<Array<ProductInformation>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //For avoid memory leak, check https://juliangaramendy.dev/use-promise-subscription/#:~:text=This%20is%20a%20no%2Dop,the%20cleanup%20function%20of%20useEffect%20.
    let isSubscribed = true;
    setLoading(true);
    if (data.brand_id !== 0) {
      getGoodsUnderBrand(
        data.cat_id,
        1,
        location.countryId,
        location.provinceId,
        location.cityId,
        data.item_number,
        data.goods_type,
        data.brand_id
      ).then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed) {
            setLoading(false);
            setGoodsArray(response.data.goods);
          }
        } else {
          console.error(response);
        }
      });
    } else {
      getGoodsListWithId(
        data.cat_id,
        1,
        location.countryId,
        location.provinceId,
        location.cityId,
        data.item_number,
        data.goods_type
      ).then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed) {
            setLoading(false);
            setGoodsArray(response.data.goods);
          }
        } else {
          console.error(response);
        }
      });
    }

    return () => {
      isSubscribed = false;
    };
  }, [location]);

  const _renderItem = goodsArray.map((item, index) => {
    return (
      <OneThirdCard
        data={item}
        key={index}
        hasMargin={index % 3 === 1}
        hasBackgroundColor={
          backgroundColor !== '' && backgroundColor !== 'white'
        }
      />
    );
  });

  const handleSeeMorePress = () => {
    navigation.navigate('ProductList', { catId: data.cat_id });
    dispatch({
      type: 'UPDATE_SELECTED_HOME_CATEGORY_NAME',
      payload: data.cat_name,
    });
  };

  return loading ? (
    <SkeletonContent
      containerStyle={styles.skeletonContentContainer}
      layout={[
        {
          flexDirection: 'row',
          justifyContent: 'space-between',
          children: [
            { width: 80, height: 20 },
            { width: 50, height: 20 },
          ],
        },
        {
          flexDirection: 'row',
          marginTop: 10,
          children: [
            {
              flexDirection: 'column',
              alignItems: 'center',
              marginHorizontal: 10,
              children: [{ width: 100, height: 120, marginTop: 4 }],
            },
            {
              flexDirection: 'column',
              alignItems: 'center',
              marginHorizontal: 10,
              children: [{ width: 100, height: 120, marginTop: 4 }],
            },
            {
              flexDirection: 'column',
              alignItems: 'center',
              marginHorizontal: 10,
              children: [{ width: 100, height: 120, marginTop: 4 }],
            },
          ],
        },
      ]}
      isLoading={true}
    />
  ) : (
    <View>
      {goodsArray.length > 0 ? (
        <View
          style={{
            width: width,
            paddingHorizontal: marginHorizontal,
            marginBottom,
            backgroundColor,
            paddingTop: 10,
          }}
        >
          {data.show_title === 1 && (
            <View
              style={[
                styles.headerTitle,
                {
                  borderLeftColor: data.title_color
                    ? data.title_color
                    : tintColorLight,
                },
              ]}
            >
              <StyledText size={18}>{data.cat_name}</StyledText>
              <TouchableOpacity onPress={handleSeeMorePress}>
                <StyledText>{t('more')}</StyledText>
              </TouchableOpacity>
            </View>
          )}
          <View style={styles.productsContainer}>{_renderItem}</View>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  headerTitle: {
    paddingLeft: 5,
    marginVertical: 5,
    borderLeftWidth: 3,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  productsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'flex-start',
  },
  skeletonContentContainer: {
    width: '100%',
    marginBottom: 10,
  },
});

export default FixedGoodsList;
