import React, { useEffect, useState } from 'react';
import { Dimensions, Image, Platform, View } from 'react-native';
import { Video } from 'expo-av';
import { OnLoadEvent } from 'react-native-fast-image';
import CachedImage from './cache-img/CachedImage';
import Constants from 'expo-constants';

const screenWidth = Dimensions.get('window').width;

type ResponsiveImgProps = {
  uri: string;
  resizeMode?: string;
  borderRadius?: number;
  width?: number;
  type?: string;
};

/**
 * Responsive auto height Image component (full width by default)
 */
const ResponsiveImg: React.FC<ResponsiveImgProps> = (props: any) => {
  const [outputSize, setOutputSize] = useState({ width: 0, height: 0 });
  const [isError, setIsError] = useState(false);

  const { uri, borderRadius, width, type } = props;

  const borderRadiusStyle = borderRadius ? { borderRadius: borderRadius } : {};

  useEffect(() => {
    let isSubscribed = true;

    if (Constants.appOwnership === 'expo' || Platform.OS !== 'ios') {
      Image.getSize(uri, (w, h) => {
        if (isSubscribed) {
          const targetSize = width
            ? {
                width: width,
                height: (width * h) / w,
              }
            : {
                width: screenWidth - 20,
                height: ((screenWidth - 20) * h) / w,
              };
          setOutputSize(targetSize);
        }
      });
    }

    return () => {
      isSubscribed = false;
    };
  }, [uri]);

  const getSize = (e: OnLoadEvent) => {
    const targetSize = width
      ? {
          width: width,
          height: (width * e.nativeEvent.height) / e.nativeEvent.width,
        }
      : {
          width: screenWidth - 20,
          height:
            ((screenWidth - 20) * e.nativeEvent.height) / e.nativeEvent.width,
        };
    setOutputSize(targetSize);
  };

  const renderVideoComponent = (
    <Video
      source={{ uri: uri }}
      posterSource={{ uri: 'https://shop.luniumall.com/images/errorImg.png' }}
      usePoster={true}
      rate={1.0}
      isMuted={true}
      resizeMode="cover"
      shouldPlay
      isLooping
      style={{ width: width, height: width }}
    />
  );

  const renderImageComponent =
    Constants.appOwnership !== 'expo' && Platform.OS === 'ios' ? (
      <CachedImage
        onError={() => setIsError(true)}
        style={[outputSize, borderRadiusStyle]}
        source={{
          uri: uri,
        }}
        onLoad={getSize}
        {...props}
      />
    ) : (
      <Image
        onError={() => setIsError(true)}
        style={[outputSize, borderRadiusStyle]}
        source={{
          uri: uri,
        }}
        {...props}
      />
    );

  return (
    <View>
      {type && type === 'video' ? (
        renderVideoComponent
      ) : isError ? (
        <></>
      ) : (
        renderImageComponent
      )}
    </View>
  );
};

export default ResponsiveImg;
