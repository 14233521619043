import React, { useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { UserParamList } from '../../types';

import UserProfileScreen from '../../screens/User/UserProfileScreen';

import SettingsNavigator from '../SettingsNavigator';
import BackButton from '../../components/common/iconButtons/BackButton';
import { useTranslation } from 'react-i18next';
import UserInfoScreen from '../../screens/User/UserInfoScreen';
import {
  useRoute,
  useNavigation,
  getFocusedRouteNameFromRoute,
} from '@react-navigation/native';
import WalletNavigator from './WalletNavigator';
import UserInfoEditScreen from '../../screens/User/UserInfoEditScreen';
import AddressNavigator from './AddressNavigator';
import ReviewNavigator from './ReviewNavigator';
import UserFavoriteScreen from '../../screens/User/UserFavoriteScreen';
import OrderNavigator from './OrderNavigator';
import HelpCenterNavigator from './HelpCenterNavigator';
import CreditCardNavigator from './CreditCardNavigator';
import NavigateButton from '../../components/common/NavigateButton';
import MessageNavigator from './MessageNavigator';
import InviteFriendsScreen from '../../screens/User/InviteFriendsScreen';
import ArticleNavigator from './ArticleNavigator';
import GroupSaleNavigator from './GroupSaleNavigator';
import IdVerificationScreen from '../../screens/User/IdVerificationScreen';
import VoucherListScreen from '../../screens/User/Vouchers/VoucherListScreen';
import ClaimNavigator from './ClaimNavigator';
import getAppStyle from '../../utils/getAppStyle';

const UserStack = createStackNavigator<UserParamList>();

const UserNavigator = () => {
  const { t } = useTranslation();

  const navigation = useNavigation();
  const route = useRoute();

  //Hide Tab bar when route is not UserProfile
  useEffect(() => {
    const activeRouteName = getFocusedRouteNameFromRoute(route);
    if (activeRouteName !== 'UserProfile' && activeRouteName !== undefined) {
      navigation.setOptions({
        tabBarVisible: false,
      });
    }

    return () =>
      navigation.setOptions({
        tabBarVisible: true,
      });
  }, [route]);

  return (
    <UserStack.Navigator
      headerMode="screen"
      initialRouteName="UserProfile"
      screenOptions={{
        headerLeft: () => (
          <NavigateButton goBack>
            <BackButton />
          </NavigateButton>
        ),
        headerBackTitleVisible: false,
        headerTitleAlign: 'center',
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <UserStack.Screen
        name="UserProfile"
        component={UserProfileScreen}
        options={{ headerShown: false }}
      />
      <UserStack.Screen
        name="UserSettings"
        component={SettingsNavigator}
        options={{
          headerShown: false,
        }}
      />
      <UserStack.Screen
        name="UserInfo"
        component={UserInfoScreen}
        options={{
          headerTitle: t('userInfoScreen.headerTitle'),
          headerLeft: () => (
            <NavigateButton route="User" options={{ screen: 'UserProfile' }}>
              <BackButton />
            </NavigateButton>
          ),
        }}
      />
      <UserStack.Screen
        name="UserInfoEdit"
        component={UserInfoEditScreen}
        options={{ headerTitle: t('userInfoScreen.editInformation') }}
      />
      <UserStack.Screen
        name="Wallet"
        component={WalletNavigator}
        options={{
          headerShown: false,
        }}
      />
      <UserStack.Screen
        name="Address"
        component={AddressNavigator}
        options={{
          headerShown: false,
        }}
      />
      <UserStack.Screen
        name="Review"
        component={ReviewNavigator}
        options={{
          headerShown: false,
        }}
      />
      <UserStack.Screen
        name="Claim"
        component={ClaimNavigator}
        options={{
          headerShown: false,
        }}
      />
      <UserStack.Screen
        name="Favorites"
        component={UserFavoriteScreen}
        options={{
          headerTitle: t('userProfileScreen.otherMenu.myFavorites'),
        }}
      />
      <UserStack.Screen
        name="Order"
        component={OrderNavigator}
        options={{
          headerShown: false,
        }}
      />
      <UserStack.Screen
        name="HelpCenter"
        component={HelpCenterNavigator}
        options={{
          headerShown: false,
        }}
      />
      <UserStack.Screen
        name="CreditCard"
        component={CreditCardNavigator}
        options={{
          headerShown: false,
        }}
      />
      <UserStack.Screen
        name="Messages"
        component={MessageNavigator}
        options={{
          headerShown: false,
        }}
      />
      <UserStack.Screen
        name="InviteFriends"
        component={InviteFriendsScreen}
        options={{
          headerTitle: t('userProfileScreen.otherMenu.inviteFriends'),
        }}
      />
      <UserStack.Screen
        name="Articles"
        component={ArticleNavigator}
        options={{
          headerShown: false,
        }}
      />
      <UserStack.Screen
        name="GroupSale"
        component={GroupSaleNavigator}
        options={{
          headerShown: false,
        }}
      />
      <UserStack.Screen
        name="IdVerification"
        component={IdVerificationScreen}
        options={{
          headerTitle: t('idVerification.title'),
        }}
      />
      <UserStack.Screen
        name="Vouchers"
        component={VoucherListScreen}
        options={{
          headerTitle: t('userProfileScreen.otherMenu.vouchers'),
        }}
      />
    </UserStack.Navigator>
  );
};

export default UserNavigator;
