import React, { useState, useEffect } from 'react';
import FullWidthView from '../../../components/common/FullWidthView';
import { useRoute, RouteProp, useNavigation } from '@react-navigation/native';
import { OrderParamList } from '../../../types';
import { View } from 'react-native';
import StyledText from '../../../components/common/StyledText';
import { AntDesign } from '@expo/vector-icons';
import { backGroundLight, subTextColor } from '../../../constants/Colors';
import { useTranslation } from 'react-i18next';
import NavigateButton from '../../../components/common/NavigateButton';
import * as Linking from 'expo-linking';
import getAppStyle from '../../../utils/getAppStyle';

/**
 * Web version OrderSubmitScreen, payment methods are different from App.
 */
const OrderSubmitScreen = () => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  const route = useRoute<RouteProp<OrderParamList, 'OrderSubmit'>>();

  const { data } = route.params;

  const [statusText, setStatusText] = useState<string>(
    t('order.orderProcessing')
  );

  useEffect(() => {
    setStatusText(t('order.orderProcessing'));
    if (data) {
      switch (data.payment_status) {
        default:
        case 'PAY_BY_CASH':
          navigation.navigate('OrderResult', {
            orderId: data.order_id,
            status: 'ORDER_SUCCESS',
            redPacket: data.share_red_packet,
          });
          break;
        case 'PAID':
          navigation.navigate('OrderResult', {
            orderId: data.order_id,
            status: 'PAY_SUCCESS',
            redPacket: data.share_red_packet,
          });
          break;
        case 'H5_PAY':
          let paramAlipayString = [];
          for (const [key, value] of Object.entries(data.params)) {
            paramAlipayString.push(`${key}=${value}`);
          }
          paramAlipayString.push(
            `redirect=${
              getAppStyle(process.env.EXPO_APP_TYPE)?.appWebsite
            }/user/orders/check?id=${data.payment.partner_order_id}`
          );
          navigation.navigate('WebPaymentScreen', {
            pay_url: `${data.payment.pay_url}?${paramAlipayString.join('&')}`,
            id: data.payment.partner_order_id,
          });
          break;
        case 'WEB_PAY':
        case 'JSAPI_PAY':
          let paramsString = [];
          for (const [key, value] of Object.entries(data.params)) {
            paramsString.push(`${key}=${value}`);
          }
          paramsString.push('directpay=true');
          paramsString.push(
            `redirect=${
              getAppStyle(process.env.EXPO_APP_TYPE)?.appWebsite
            }/user/orders/check?id=${data.payment.partner_order_id}`
          );
          Linking.openURL(`${data.payment.pay_url}?${paramsString.join('&')}`);
      }
    }
  }, [data]);

  return (
    <FullWidthView>
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <AntDesign name="clockcircle" size={80} color={backGroundLight} />
        <StyledText align="center" size={16}>
          {statusText}
        </StyledText>
        <NavigateButton
          route={'OrderResult'}
          options={{ orderId: data.order_id, status: 'APP_PAY_FAIL' }}
        >
          <StyledText
            color={subTextColor}
            style={{ marginTop: 30, textDecorationLine: 'underline' }}
          >
            {t('order.abandonPayment')}
          </StyledText>
        </NavigateButton>
      </View>
    </FullWidthView>
  );
};

export default OrderSubmitScreen;
