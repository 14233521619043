import React, { useRef, useState } from 'react';
import { CategoriesItem, RootState } from '../../../../types';
import {
  FlatList,
  StyleSheet,
  View,
  TouchableOpacity,
  Platform,
} from 'react-native';
import StyledText from '../../../common/StyledText';
import {
  normalTextColor,
  backGroundLight,
  tintColorLight,
} from '../../../../constants/Colors';
import { useUpdateEffect } from 'ahooks';
import { useNavigation } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';

type LevelThreeCategoryMenuProps = {
  categoryNames: Array<CategoriesItem['name']>;
  horizontalIndex: number;
  setHorizontalIndex: Function;
  updateHorizontalPage: Function;
  isHomeProductList?: boolean;
  updateSubCategoryChange?: Function;
};

const LevelThreeCategoryMenu = ({
  categoryNames,
  horizontalIndex,
  updateHorizontalPage,
  isHomeProductList = false,
  updateSubCategoryChange,
}: LevelThreeCategoryMenuProps) => {
  const scrollMenuRef = useRef<FlatList<any>>(null);
  const navigation = useNavigation();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const dispatch = useDispatch();
  const isFetching = useSelector<RootState, boolean>(
    (state) => state.goods.isFetchingGoods
  );

  useUpdateEffect(() => {
    setIsCollapsed(true);
  }, [categoryNames]);

  useUpdateEffect(() => {
    try {
      if (scrollMenuRef && scrollMenuRef.current) {
        scrollMenuRef.current.scrollToIndex({
          animated: true,
          index: horizontalIndex,
          viewPosition: 0.5,
        });
      }

      if (isHomeProductList && categoryNames.length > 0) {
        navigation.setOptions({
          headerTitle: categoryNames[horizontalIndex],
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [horizontalIndex]);

  const handleChangeIndex = (index: number) => {
    dispatch({ type: 'SET_RESET_GOODS_LIST', payload: true });
    updateHorizontalPage(index);
  };

  const handlePressDropdown = () => {
    setIsCollapsed((prev) => !prev);
  };

  const _renderItem = ({ item, index }: any) => {
    return (
      <TouchableOpacity
        style={[
          styles.button,
          {
            backgroundColor:
              index === horizontalIndex ? tintColorLight : backGroundLight,
          },
        ]}
        onPress={() => {
          // Platform.OS !== 'web' &&
          //   updateSubCategoryChange &&
          //   updateSubCategoryChange();
          handleChangeIndex(index);
        }}
        disabled={isFetching}
      >
        <StyledText
          color={index === horizontalIndex ? 'white' : normalTextColor}
          size={15}
        >
          {item}
        </StyledText>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.container}>
      <FlatList
        ref={scrollMenuRef}
        data={categoryNames}
        renderItem={_renderItem}
        horizontal
        keyExtractor={(item, index) => index.toString()}
        showsHorizontalScrollIndicator={false}
        style={
          isCollapsed
            ? undefined
            : Platform.OS === 'ios'
            ? { display: 'none' }
            : { height: 0 }
        }
      />
      {isCollapsed ? (
        <></>
      ) : (
        <View style={styles.dropdownMenu}>
          {categoryNames.map((item, index) => {
            return (
              <TouchableOpacity
                key={item}
                style={[
                  styles.button,
                  {
                    marginBottom: 5,
                    backgroundColor:
                      index === horizontalIndex
                        ? tintColorLight
                        : backGroundLight,
                  },
                ]}
                onPress={() => handleChangeIndex(index)}
              >
                <StyledText
                  color={index === horizontalIndex ? 'white' : normalTextColor}
                  size={15}
                >
                  {item}
                </StyledText>
              </TouchableOpacity>
            );
          })}
        </View>
      )}
      {categoryNames.length > 4 && (
        <TouchableOpacity onPress={handlePressDropdown}>
          <View style={styles.dropdownBtn}>
            <AntDesign
              name={isCollapsed ? 'down' : 'up'}
              size={20}
              color="black"
            />
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default LevelThreeCategoryMenu;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginVertical: 5,
    marginLeft: 5,
  },
  button: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginRight: 5,
    borderRadius: 5,
  },
  dropdownBtn: {
    paddingHorizontal: 15,
    marginLeft: 5,
    borderLeftWidth: 1,
    borderLeftColor: 'lightgray',
  },
  dropdownMenu: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '80%',
  },
});
