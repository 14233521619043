import React, { useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { CategoryParamList } from '../types';
import CategoriesScreen from '../screens/Category/CategoriesScreen';
import SubCategoryScreen from '../screens/Category/SubCategoryScreen';
import FakeSearchBar from '../components/common/FakeSearchBar';
import BackButton from '../components/common/iconButtons/BackButton';
import {
  useRoute,
  useNavigation,
  getFocusedRouteNameFromRoute,
} from '@react-navigation/native';
import NavigateButton from '../components/common/NavigateButton';
import ProductBundleNavigator from './ProductBundleNavigator';
import { Platform } from 'react-native';
import SearchNavigator from './SearchNavigator';
import getAppStyle from '../utils/getAppStyle';
// import CartNavigator from './CartNavigator';

const CategoryStack = createStackNavigator<CategoryParamList>();

const CategoryNavigator = () => {
  const navigation = useNavigation();
  const route = useRoute();

  //Hide Tab bar when route is not Categories home or SubCategory
  useEffect(() => {
    const activeRouteName = getFocusedRouteNameFromRoute(route);

    if (
      activeRouteName !== 'Categories' &&
      activeRouteName !== undefined &&
      activeRouteName !== 'SubCategory'
    ) {
      navigation.setOptions({
        tabBarVisible: false,
      });
    }

    return () =>
      navigation.setOptions({
        tabBarVisible: true,
      });
  }, [route]);

  return (
    <CategoryStack.Navigator
      headerMode="screen"
      initialRouteName="Categories"
      screenOptions={{
        headerBackTitleVisible: false,
        headerTitleAlign: 'center',
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <CategoryStack.Screen
        name="Categories"
        component={CategoriesScreen}
        options={{
          headerTitle: (props) => <FakeSearchBar />,
          headerLeft: () => <></>,
        }}
      />
      <CategoryStack.Screen
        name="SubCategory"
        component={SubCategoryScreen}
        options={{
          headerTitle: (props) => <FakeSearchBar />,
          headerStyle: { shadowColor: 'transparent' },
          headerLeft: () => (
            <NavigateButton goBack>
              <BackButton />
            </NavigateButton>
          ),
          headerShown: Platform.OS !== 'web',
        }}
      />
      {/* <CategoryStack.Screen
        name='Cart'
        component={CartNavigator}
        options={{
          headerShown: false,
        }}
      /> */}
      <CategoryStack.Screen
        name="ProductBundle"
        component={ProductBundleNavigator}
        options={{
          headerShown: false,
        }}
      />
      <CategoryStack.Screen
        name="SearchNavigator"
        component={SearchNavigator}
        options={{ headerShown: false }}
      />
    </CategoryStack.Navigator>
  );
};

export default CategoryNavigator;
