import React from 'react';
import WhiteCard from '../../common/WhiteCard';
import {
  View,
  TouchableOpacity,
  Platform,
  Modal,
  StyleSheet,
} from 'react-native';
import StyledText from '../../common/StyledText';
import { AntDesign } from '@expo/vector-icons';
import {
  tintColorLight,
  accentColor,
  backGroundLight,
} from '../../../constants/Colors';
import { useTranslation } from 'react-i18next';
import alert from '../../../utils/alert';
import { Tooltip } from 'react-native-elements';

type PointsInfoProps = {
  points: number;
  pointDescription: string;
  valid: 1 | 0;
  getPayPoints: number;
  getPayPointsDescription: string;
  usePoints: boolean;
  triggerPointUsage: Function;
};

const PointsInfo = ({
  points = 0,
  pointDescription,
  valid,
  getPayPoints,
  getPayPointsDescription,
  usePoints,
  triggerPointUsage,
}: PointsInfoProps) => {
  const { t } = useTranslation();

  return (
    <WhiteCard
      title={t('userProfileScreen.myWallet.options.point')}
      icon={
        Platform.OS === 'web'
          ? {
              iconComponent: (
                <AntDesign name="questioncircleo" size={14} color="lightgray" />
              ),
              handleActionPress: () => alert(pointDescription),
            }
          : {
              iconComponent: (
                <Tooltip
                  ModalComponent={Modal}
                  popover={
                    <View style={{ backgroundColor: 'black' }}>
                      <StyledText color="white">
                        {pointDescription}
                      </StyledText>
                    </View>
                  }
                  backgroundColor="black"
                  width={240}
                  height={100}
                >
                  <AntDesign
                    name="questioncircleo"
                    size={14}
                    color="lightgray"
                  />
                </Tooltip>
              ),
            }
      }
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingVertical: 10,
        }}
      >
        <View style={{ flexDirection: 'column' }}>
          <View style={{ flexDirection: 'row' }}>
            <StyledText>{t('order.points.currentPoints')}:</StyledText>
            <StyledText color={accentColor} bold style={{ marginLeft: 5 }}>
              {points}
            </StyledText>
          </View>
        </View>
        {valid == 1 && (
          <TouchableOpacity
            style={{ flexDirection: 'row' }}
            onPress={() => {
              triggerPointUsage();
            }}
          >
            <AntDesign
              name="checkcircle"
              size={20}
              color={usePoints ? tintColorLight : 'lightgray'}
              style={{ marginRight: 5 }}
            />
            <StyledText>{t('order.points.usePointDiscount')}</StyledText>
          </TouchableOpacity>
        )}
      </View>
      {getPayPoints > 0 && (
        <View style={styles.footer}>
          <StyledText color={accentColor} size={14}>
            {getPayPointsDescription}
          </StyledText>
        </View>
      )}
    </WhiteCard>
  );
};

const styles = StyleSheet.create({
  footer: {
    // flexDirection: 'row',
    // justifyContent: 'flex-end',
    alignItems: 'flex-end',
    borderTopColor: backGroundLight,
    borderTopWidth: 1,
    paddingTop: 5,
  },
  footerItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default PointsInfo;
