import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ReviewParamList } from '../../types';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/common/iconButtons/BackButton';
import ReviewListScreen from '../../screens/User/Review/ReviewListScreen';
import SubmitReviewScreen from '../../screens/User/Review/SubmitReviewScreen';
import NavigateButton from '../../components/common/NavigateButton';
import getAppStyle from '../../utils/getAppStyle';

const ReviewStack = createStackNavigator<ReviewParamList>();

const ReviewNavigator = () => {
  const { t } = useTranslation();

  return (
    <ReviewStack.Navigator
      initialRouteName="ReviewListScreen"
      screenOptions={{
        headerBackTitleVisible: false,
        headerLeft: () => (
          <NavigateButton goBack>
            <BackButton />
          </NavigateButton>
        ),
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <ReviewStack.Screen
        name="ReviewListScreen"
        component={ReviewListScreen}
        options={{
          headerTitleAlign: 'center',
          title: t('userProfileScreen.myOrders.options.uncommented'),
        }}
      />
      <ReviewStack.Screen
        name="SubmitReviewScreen"
        component={SubmitReviewScreen}
        options={{ headerTitleAlign: 'center', title: t('review.leaveReview') }}
      />
    </ReviewStack.Navigator>
  );
};

export default ReviewNavigator;
