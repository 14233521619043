import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import StyledText from '../../common/StyledText';
import { subTextColor } from '../../../constants/Colors';
import { AntDesign } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import RNPickerSelect from 'react-native-picker-select';
import { ShippingTime, ShippingTimeInfoTime } from '../../../types';

type ShippingTimeInfoProps = {
  info: any;
  shippingTime: ShippingTime;
  setShippingTime: React.Dispatch<React.SetStateAction<ShippingTime>>;
  disableTimeChange?: boolean;
  hasSelectedDate: boolean;
  setHasSelectedDate: Function;
  updateOrderTime: Function;
};

const ShippingTimeInfo = ({
  info,
  shippingTime,
  setShippingTime,
  disableTimeChange = false,
  hasSelectedDate,
  setHasSelectedDate,
  updateOrderTime,
}: ShippingTimeInfoProps) => {
  const { t } = useTranslation();

  const [shippingDate, setShippingDate] = useState<{
    name?: string;
    start_order_time?: number;
    child?: Array<ShippingTimeInfoTime>;
  }>({});
  const [time, setTime] = useState<ShippingTimeInfoTime | string>({
    label: t('order.selectDeliveryTime'),
    value: 'false',
  });
  const [fastDeliverySelected, setFastDeliverySelected] = useState(false);
  const [shouldFireRequest, setShouldFireRequest] = useState(false);

  const onDateChange = (value: any) => {
    setHasSelectedDate(true);
    if (value === 'false') {
      setShippingDate({});
      setShippingTime({ name: '', time: 0, startTime: undefined });
    } else {
      const selectedDate = JSON.parse(value);
      if (selectedDate.child && selectedDate.child.length > 0) {
        setShippingDate(selectedDate);
        setShippingTime({ name: '', time: 0, startTime: undefined });
      } else {
        setShippingTime({
          name: selectedDate.name,
          time: selectedDate.shipping_time,
          startTime: selectedDate.start_order_time,
        });
      }
    }
    setTime({ label: '', value: 'false' });
  };

  const onTimeChange = (value: any) => {
    if (value === 'false') {
      setShippingTime({ name: '', time: 0, startTime: undefined });
    } else {
      const selectedTime = JSON.parse(value);
      setShippingTime({
        name: `${shippingDate.name}` + ' ' + `${selectedTime.name}`,
        time: selectedTime.shipping_time,
        startTime: shippingDate.start_order_time
          ? shippingDate.start_order_time
          : undefined,
        fast_delivery: selectedTime.fast_delivery
          ? selectedTime.fast_delivery
          : undefined,
        fast_delivery_notice: selectedTime.fast_delivery_notice
          ? selectedTime.fast_delivery_notice
          : undefined,
      });
      setTime(value);
      setShouldFireRequest(true);
    }
  };

  // Save guard for API call. Only call the API when "shouldFireRequest" is true.
  useEffect(() => {
    if (shouldFireRequest) {
      let selectedTime;
      if (typeof time === 'string') {
        selectedTime = JSON.parse(time);
      } else {
        selectedTime = time;
      }
      if (selectedTime.fast_delivery == 1) {
        updateOrderTime();
        setFastDeliverySelected(true);
      } else {
        if (fastDeliverySelected) {
          updateOrderTime();
          setFastDeliverySelected(false);
        }
      }
      setShouldFireRequest(false);
    }
  }, [time]);

  return (
    <View>
      <StyledText bold>
        <AntDesign name="clockcircleo" size={20} color={subTextColor} />{' '}
        {t('order.deliveryTime')}
      </StyledText>
      {disableTimeChange && (
        <View style={styles.timeContainer}>
          <StyledText style={styles.picker}>{shippingTime.name}</StyledText>
        </View>
      )}
      <View style={disableTimeChange ? { display: 'none' } : undefined}>
        <View style={styles.timeContainer}>
          <RNPickerSelect
            value={
              hasSelectedDate
                ? undefined
                : { label: t('order.selectDeliveryDate'), value: 'false' }
            }
            disabled={disableTimeChange}
            placeholder={{
              label: t('order.selectDeliveryDate'),
              value: 'false',
            }}
            style={{ inputWeb: styles.picker }}
            onValueChange={onDateChange}
            items={info.delivery.shipping_time_list.map((date: any) => ({
              label: date.name,
              value: JSON.stringify(date),
            }))}
          />
        </View>
        {shippingDate &&
        JSON.stringify(shippingDate) !== '{}' &&
        shippingDate.child ? (
          <View style={styles.timeContainer}>
            <RNPickerSelect
              value={time}
              disabled={disableTimeChange}
              placeholder={{
                label: t('order.selectDeliveryTime'),
                value: 'false',
              }}
              style={{ inputWeb: styles.picker }}
              onValueChange={onTimeChange}
              items={shippingDate.child.map((time: any) => ({
                label: time.name,
                value: JSON.stringify(time),
              }))}
            />
          </View>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  timeContainer: {
    paddingVertical: 10,
  },
  picker: {
    borderWidth: 0,
    color: subTextColor,
    fontSize: 14,
    fontFamily: 'noto-sans',
  },
});

export default ShippingTimeInfo;
