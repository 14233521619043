import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import {
  GestureResponderEvent,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { backGroundLight, tintColorLight } from '../../../constants/Colors';
import BottomAutoHeightModal from '../../common/BottomAutoHeightModal';
import StyledText from '../../common/StyledText';

type AddressTypeSelectorModalProps = {
  visible: boolean;
  hideModal: Function;
  currentType: string;
  setAddressType: React.Dispatch<
    React.SetStateAction<{
      title: string;
      value: string;
    }>
  >;
};

const AddressTypeSelectorModal = ({
  visible,
  hideModal,
  currentType,
  setAddressType,
}: AddressTypeSelectorModalProps) => {
  const addressTypes = [
    {
      title: '别墅 House',
      value: 'house',
    },
    {
      title: '联排别墅 Townhouse',
      value: 'townhouse',
    },
    {
      title: '公寓 Apartment/Condo',
      value: 'apartment',
    },
    {
      title: '地下室 Basement',
      value: 'basement',
    },
  ];

  const updateOption = (index: number) => {
    setAddressType(addressTypes[index]);
    hideModal();
  };

  const renderOptions = addressTypes.map((item, index) => {
    return (
      <Option
        key={index}
        item={item}
        updateOption={() => updateOption(index)}
        isActive={currentType === item.value}
      />
    );
  });

  return (
    <BottomAutoHeightModal visible={visible} hideModal={hideModal}>
      <StyledText bold size={16}>
        地址类型
      </StyledText>
      <View style={styles.contentContainer}>{renderOptions}</View>
    </BottomAutoHeightModal>
  );
};

type OptionProps = {
  item: { title: string; value: string };
  isActive: boolean;
  updateOption: ((event: GestureResponderEvent) => void) | undefined;
};

const Option = ({ item, isActive, updateOption }: OptionProps) => {
  return (
    <TouchableOpacity onPress={updateOption}>
      <View style={styles.optionItem}>
        <StyledText size={16}>{item.title}</StyledText>
        {isActive && (
          <AntDesign name="checkcircle" size={16} color={tintColorLight} />
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    paddingVertical: 10,
  },
  optionItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 10,
    borderTopColor: backGroundLight,
    borderTopWidth: 1,
  },
});

export default AddressTypeSelectorModal;
