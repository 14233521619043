import React from 'react';
import { View, StyleSheet } from 'react-native';
import WhiteCard from '../../common/WhiteCard';
import StyledText from '../../common/StyledText';
import { AntDesign } from '@expo/vector-icons';
import { subTextColor } from '../../../constants/Colors';
import { useTranslation } from 'react-i18next';

type DeliveryInfoProps = {
  data: {
    address: string;
    postalCode: string;
    unit: string;
    contact: string;
    phone: string;
    bestTime: string;
  };
};

const DeliveryInfo = ({ data }: DeliveryInfoProps) => {
  const { t } = useTranslation();
  return (
    <WhiteCard title={t('order.deliveryInformation')}>
      <View style={styles.infoRow}>
        <View style={{ flex: 1 }}>
          <StyledText bold>
            <AntDesign name='enviromento' size={20} color={subTextColor} />{' '}
            {t('address.address')}
          </StyledText>

          <StyledText>
            {data.unit !== '' ? `${data.unit}-${data.address}` : data.address}
          </StyledText>
          <StyledText>{data.postalCode}</StyledText>
        </View>
      </View>
      {data.bestTime !== '' && (
        <View style={{ marginBottom: 10 }}>
          <StyledText bold>
            <AntDesign name='clockcircleo' size={20} color={subTextColor} />{' '}
            {t('order.deliveryTime')}
          </StyledText>
          <StyledText>{data.bestTime}</StyledText>
        </View>
      )}

      <View>
        <StyledText bold>
          <AntDesign name='phone' size={20} color={subTextColor} />{' '}
          {t('order.contact')}
        </StyledText>
        <View style={styles.infoRow}>
          <StyledText>{data.contact}</StyledText>
          <StyledText>{data.phone}</StyledText>
        </View>
      </View>
    </WhiteCard>
  );
};
const styles = StyleSheet.create({
  infoRow: {
    marginVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default DeliveryInfo;
