import React, { useState } from 'react';
import {
  Dimensions,
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import Modal from 'modal-react-native-web';
import StyledButton from '../common/StyledButton';
import StyledText from '../common/StyledText';
import { useTranslation } from 'react-i18next';

const { width, height } = Dimensions.get('window');

type BundleNoticeProps = {
  title: string;
  content: string;
};

const BundleNotice = ({ title, content }: BundleNoticeProps) => {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const renderContent = content.split('\\n').map((item, index) => {
    return (
      <StyledText key={index} style={{ marginBottom: 10 }}>
        {'\u2022 '}
        {item}
      </StyledText>
    );
  });

  return (
    <View style={styles.root}>
      <TouchableOpacity onPress={openModal}>
        <StyledText align="center" style={styles.linkText}>
          {title}
        </StyledText>
      </TouchableOpacity>
      <Modal visible={modalVisible} animationType="slide" transparent>
        <View style={styles.wrapper}>
          <View style={styles.container}>
            <ScrollView>
              <StyledText
                bold
                size={18}
                align="center"
                style={{ marginBottom: 10 }}
              >
                {title}
              </StyledText>
              {renderContent}
            </ScrollView>
            <StyledButton label={t('ok')} onPress={closeModal} />
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    marginVertical: 10,
  },
  linkText: {
    textDecorationLine: 'underline',
  },
  wrapper: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: 'white',
    width: width * 0.8,
    height: height * 0.7,
    borderRadius: 10,
    padding: 20,
  },
});

export default BundleNotice;
