import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import {
  FlatList,
  StyleSheet,
  TouchableOpacity,
  View,
  Dimensions,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { CategoriesItem, RootState, SubCategoryData } from '../../../types';
import CachedImage from '../../common/cache-img/CachedImage';
import EmptyDisplayBlock from '../../common/EmptyDisplayBlock';
import StyledText from '../../common/StyledText';
import ProductFilterPopup from '../../Product/ProductFilterPopup';
import ProductInCategory from '../container/ProductInCategory';
import GoodsActionToolbar from '../GoodsActionToolbar';

const { width } = Dimensions.get('window');

type Level2MenuProps = { 
  menuType?: 'regular' | 'groupsale';
  level1MenuChangeState: boolean;
};

const Level2Menu = ({
  menuType = 'regular',
  level1MenuChangeState,
}: Level2MenuProps) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [showFilterPopUp,setShowFilterPopUp] = useState<boolean>(false);
  const [selectedSorter,setSelectedSorter] = useState<{
    sort_key?: string;
    sort_value?: string;
  }>({});
  const [numOfSelectedFilter, setNumOfSelectedFilter] = useState<string>('0');

  const categoriesArray = useSelector<RootState, Array<CategoriesItem>>(
    (state) => state.goods.categoriesArray
  );
  const selectedMainCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedMainCategoryIndex
  );

  const groupSaleCategoriesArray = useSelector<
    RootState,
    Array<CategoriesItem>
  >((state) => state.goods.groupSaleCategoriesArray);

  const selectedGroupSaleCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedGroupSaleCategoryIndex
  );

  const handleLevel2MenuPress = (index: number) => {
    dispatch({ type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX', payload: index });
    navigation.navigate('SubCategory', {
      catId:
        menuType === 'regular'
          ? categoriesArray[selectedMainCategoryIndex].id
          : groupSaleCategoriesArray[selectedGroupSaleCategoryIndex].id,
    });
  };

  const renderItem = ({
    item,
    index,
  }: {
    item: SubCategoryData;
    index: number;
  }) => {
    return item.show_in_nav === 1 ? (
      <TouchableOpacity
        style={styles.item}
        onPress={() => handleLevel2MenuPress(index)}
      >
        <CachedImage
          style={styles.thumbnail}
          source={{
            uri: item.cat_img,
          }}
        />
        <StyledText align="center">{item.name}</StyledText>
      </TouchableOpacity>
    ) : (
      <></>
    );
  };

  const targetArray =
    menuType === 'regular' ? categoriesArray : groupSaleCategoriesArray;

  const targetIndex =
    menuType === 'regular'
      ? selectedMainCategoryIndex
      : selectedGroupSaleCategoryIndex;

  return (
    <>
    <View style={styles.container}>
      {targetArray.length > 0 &&
      targetArray[targetIndex] !== undefined &&
      targetArray[targetIndex].haschild === 0 ? (
        <>
          {targetArray[targetIndex].banner.length > 0 && (
            <View style={{ marginLeft: 5, marginBottom: 3, marginTop:3 }}>
              <TouchableOpacity
                {...(targetArray[targetIndex].banner[0].cat_banner_link !==
                  '' && {
                  onPress: () =>
                    navigation.navigate(
                      targetArray[targetIndex].banner[0].cat_banner_link
                    ),
                })}
              >
                <CachedImage
                  style={{
                    width: width * 0.78 - 22,
                    height: (width * 0.78 - 10) / 3,
                    borderRadius: 10,
                  }}
                  source={{
                    uri: targetArray[targetIndex].banner[0].cat_banner,
                  }}
                />
              </TouchableOpacity>
            </View>
          )}
          <View>
            <GoodsActionToolbar 
              leftSideMenuChangeState={level1MenuChangeState}
              numOfSelectedFilter = {numOfSelectedFilter} 
              setShowFilterPopUp={setShowFilterPopUp}
              setSelectedSorter={setSelectedSorter}
            />
          </View>
          <View style={{ flex: 1 }}>
            <ProductInCategory
              catId={targetArray[targetIndex].id || -1}
              nextCategoryName={
                targetArray[targetIndex + 1]
                  ? targetArray[targetIndex + 1].name
                  : targetArray[0].name
              }
              productType={menuType}
              selectedSorter={selectedSorter}
            />
          </View>
        </>
      ) : targetArray[targetIndex].cat_id !== undefined ? (
        <>
          {categoriesArray[selectedMainCategoryIndex].banner.length > 0 && (
            <View style={{ marginLeft: 5, marginBottom: 10 }}>
              <TouchableOpacity
                {...(categoriesArray[selectedMainCategoryIndex].banner[0]
                  .cat_banner_link !== '' && {
                  onPress: () =>
                    navigation.navigate(
                      categoriesArray[selectedMainCategoryIndex].banner[0]
                        .cat_banner_link
                    ),
                })}
              >
                <CachedImage
                  style={{
                    width: width * 0.78 - 22,
                    height: (width * 0.78 - 10) / 3,
                    borderRadius: 10,
                  }}
                  source={{
                    uri: categoriesArray[selectedMainCategoryIndex].banner[0]
                      .cat_banner,
                  }}
                />
              </TouchableOpacity>
            </View>
          )}
          <FlatList
            data={targetArray[targetIndex].cat_id}
            renderItem={renderItem}
            keyExtractor={(item, index) => item.id.toString() + '@' + index}
            showsVerticalScrollIndicator={false}
            numColumns={3}
            onScrollToIndexFailed={() =>
              dispatch({
                type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX',
                payload: 0,
              })
            }
          />
        </>
      ) : (
        <EmptyDisplayBlock />
      )}
    </View>

    {/* <ProductFilterPopup 
      setNumOfSelectedFilter={setNumOfSelectedFilter} 
      selectedFilter = {selectedSorter} 
      setSelectedFilter={setSelectedSorter} 
      showFilterPopUp={showFilterPopUp} 
      setShowFilterPopUp={setShowFilterPopUp}/> */}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '75%',
    // paddingTop: 3,
  },
  item: {
    marginHorizontal: '1.5%',
    width: '30.333%',
    alignItems: 'center',
    marginBottom: 3,
    borderColor: 'red',
  },
  thumbnail: {
    width: 80,
    height: 80,
  },
});

export default Level2Menu;
