import React from 'react';
import {
  ActivityIndicator as ActivityIndicatorBase,
  StyleSheet,
  View,
} from 'react-native';
import { tintColorLight } from '../../constants/Colors';
import ListLoadingText from './list/ListLoadingText';

/**
 * Pull down refresh styled component
 */
const ActivityIndicator = () => {
  return (
    <View style={[styles.container, styles.horizontal]}>
      <ActivityIndicatorBase size="small" color={tintColorLight} />
      <ListLoadingText />
    </View>
  );
};

export default ActivityIndicator;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 10,
  },
});
