import React, { useState } from 'react';
import { View, TextInput, StyleSheet, Platform } from 'react-native';
import StyledText from '../../common/StyledText';
import ListOption from '../../common/list/ListOption';
import { normalTextColor, subTextColor } from '../../../constants/Colors';
import StyledButton from '../../common/StyledButton';
import { useTranslation } from 'react-i18next';

import Toast from 'react-native-tiny-toast';
import { useNavigation } from '@react-navigation/native';
import { Formik } from 'formik';
import ErrorMessage from '../../Auth/ErrorMessage';
import { ChangePasswordSchema } from '../../../utils/validation';
import { resetPassword } from '../../../utils/service';
import { removeAsyncStorageData } from '../../../utils/asyncStorage';
import { useDispatch } from 'react-redux';

const PasswordEditForm = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const emptyToken = () => {
    removeAsyncStorageData('@storage_AuthToken');
    dispatch({
      type: 'CHANGE_USER_STATUS',
      payload: {
        userInfo: {
          name: '',
          phoneNumber: '',
          userId: -1,
        },
        isLoggedIn: false,
      },
    });
  };

  const changePassword = (
    oldPassword: string,
    newPassword: string,
    confirmNewPassword: string
  ) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      const loading = Toast.showLoading('');
      resetPassword(newPassword, confirmNewPassword, oldPassword)
        .then((result) => {
          setIsSubmitting(false);
          Toast.hide(loading);
          if (result && result.goodStatus) {
            Toast.showSuccess(t('AuthModal.passwordChanged'));
            emptyToken();
            navigation.navigate('UserProfile');
            navigation.navigate('Home');
            navigation.navigate('Modal', { screen: 'AuthModal' });
          } else {
            throw result ? result?.data : 'Error';
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
          if (loading) Toast.hide(loading);
          Toast.show(err, {
            position: 0,
            duration: 2000,
          });
        });
    }
  };

  return (
    <Formik
      validationSchema={ChangePasswordSchema}
      initialValues={{
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      }}
      onSubmit={(values) =>
        changePassword(
          values.oldPassword,
          values.newPassword,
          values.confirmNewPassword
        )
      }
    >
      {({ handleChange, handleSubmit, values, errors, touched }) => (
        <View style={styles.rootContainer}>
          <View>
            <ListOption>
              <View style={styles.formContainer}>
                <StyledText style={styles.fieldTitle}>
                  {t('userInfoScreen.changePasswordOption.oldPassword')}
                </StyledText>
                <View style={styles.inputContainer}>
                  <TextInput
                    placeholder={'******'}
                    placeholderTextColor={subTextColor}
                    style={[styles.inputField, { outline: 'none' }]}
                    value={values.oldPassword}
                    secureTextEntry
                    onChangeText={handleChange('oldPassword')}
                  />
                </View>
              </View>
              <View style={styles.errorMsgContainer}>
                {errors.oldPassword && touched.oldPassword && (
                  <ErrorMessage label={errors.oldPassword} />
                )}
              </View>
            </ListOption>

            <ListOption>
              <View style={styles.formContainer}>
                <StyledText style={styles.fieldTitle}>
                  {t('userInfoScreen.changePasswordOption.newPassword')}
                </StyledText>
                <View style={styles.inputContainer}>
                  <TextInput
                    placeholder={'******'}
                    placeholderTextColor={subTextColor}
                    style={[styles.inputField, { outline: 'none' }]}
                    value={values.newPassword}
                    secureTextEntry
                    onChangeText={handleChange('newPassword')}
                  />
                </View>
              </View>
              <View style={styles.errorMsgContainer}>
                {errors.newPassword && touched.newPassword && (
                  <ErrorMessage label={errors.newPassword} />
                )}
              </View>
            </ListOption>

            <ListOption>
              <View style={styles.formContainer}>
                <StyledText style={styles.fieldTitle}>
                  {t('userInfoScreen.changePasswordOption.confirmNewPassword')}
                </StyledText>
                <View style={styles.inputContainer}>
                  <TextInput
                    placeholder={'******'}
                    placeholderTextColor={subTextColor}
                    style={[styles.inputField, { outline: 'none' }]}
                    value={values.confirmNewPassword}
                    secureTextEntry
                    onChangeText={handleChange('confirmNewPassword')}
                  />
                </View>
              </View>
              <View style={styles.errorMsgContainer}>
                {errors.confirmNewPassword && touched.confirmNewPassword && (
                  <ErrorMessage label={errors.confirmNewPassword} />
                )}
              </View>
            </ListOption>
          </View>
          <View style={styles.buttonContainer}>
            <StyledButton
              disabled={isSubmitting}
              label={t('userInfoScreen.changePasswordOption.changePassword')}
              onPress={handleSubmit}
            />
          </View>
        </View>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  formContainer: {
    flexDirection: 'column',
  },
  fieldTitle: {
    height: 30,
  },
  inputContainer: {
    height: Platform.OS === 'android' ? 40 : 20,
  },
  inputField: {
    color: normalTextColor,
    flex: 1,
    fontSize: 14,
  },
  buttonContainer: {
    marginBottom: 30,
  },
  errorMsgContainer: {
    height: 20,
  },
});

export default PasswordEditForm;
