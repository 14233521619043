import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import StyledText from '../common/StyledText';
import {
  normalTextColor,
  backGroundLight,
  tintColorLight,
} from '../../constants/Colors';

type PostMenuItemProps = {
  index: number;
  selectedCatId: number;
  cat_id: number;
  cat_name: string;
  horizontalIndex: number;
  handleCategoryPress: Function;
};

class PostMenuItem extends React.Component<PostMenuItemProps, any> {
  constructor(props: PostMenuItemProps) {
    super(props);
  }

  shouldComponentUpdate(nextProps: any) {
    return nextProps.selectedCatId != this.props.selectedCatId;
  }

  render() {
    return (
      <TouchableOpacity
        style={[
          styles.button,
          this.props.cat_id === this.props.selectedCatId
            ? {backgroundColor: tintColorLight }
            : { backgroundColor: backGroundLight },
        ]}
        onPress={() =>
          this.props.handleCategoryPress(this.props.index, this.props.cat_id)
        }
      >
        <StyledText
          color={
            this.props.cat_id === this.props.selectedCatId
              ? 'white'
              : normalTextColor
          }
          size={14}
        >
          {this.props.cat_name}
        </StyledText>
      </TouchableOpacity>
    );
  }
}

export default PostMenuItem;

const styles = StyleSheet.create({
  button: {
    flex: 1,
    paddingHorizontal: 10,
    paddingVertical: 6,
    marginHorizontal: 5,
    borderRadius: 10,
  }
});
