import React from 'react';
import { Dimensions, View, StyleSheet, Platform } from 'react-native';
import Draggable from 'react-native-draggable';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';
import { AntDesign } from '@expo/vector-icons';
import * as Navigation from '../../utils/navigator';

const { width, height } = Dimensions.get('window');
// Check out the repo for more details: https://github.com/tongyy/react-native-draggable
const CustomerServiceButton = () => {
  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.user.isLoggedIn
  );

  const appMainColor = useSelector<RootState, string>(
    (state) => state.user.appMainColor
  );

  const display = useSelector<RootState, boolean>(
    (state) => state.user.displayFloatCS
  );

  return display ? (
    <View style={Platform.OS === 'android' ? {} : styles.container}>
      <Draggable
        x={width * 0.85}
        y={height * 0.75}
        minX={0} // min X value for left edge of component
        minY={0} // min Y value for top edge of component
        maxX={width} // max X value for right edge of component
        maxY={height} // max Y value for bottom edge of component
        renderColor="white"
        isCircle
        onShortPressRelease={() => {
          if (isLoggedIn) {
            Navigation.navigate('Modal', {
              screen: 'CustomerServiceModal',
              params: { ruId: undefined },
            });
          } else {
            Navigation.navigate('Modal', {
              screen: 'AuthNavigator',
              params: { screen: 'AuthModal' },
            });
          }
        }}
      >
        <AntDesign
          name="customerservice"
          size={Platform.OS === 'web' ? 42 : 44}
          color={appMainColor}
        />
      </Draggable>
    </View>
  ) : (
    <></>
  );
};

export default CustomerServiceButton;

const styles = StyleSheet.create({
  container: {
    zIndex: 1,
  },
});
