export type RootStackParamList = {
  Root: undefined;
  NoInternet: undefined;
  Modal: undefined;
  ProductNavigator: undefined;
};

export type BottomTabParamList = {
  Home: undefined;
  Category: undefined;
  Cart: undefined;
  User: undefined;
  TabOne: undefined;
  TabTwo: undefined;
};

export type TabOneParamList = {
  TabOneScreen: undefined;
};

export type HomeParamList = {
  Home: undefined;
  ThemeHomeScreen: undefined;
  ProductList: undefined;
  UserLocation: undefined;
  Region: undefined;
  HomeAllProductListScreen: undefined;
  HomeWebviewScreen: undefined;
  ProductBundle: undefined;
  SearchNavigator: undefined;
  Brand: undefined;
  Articles: undefined;
  LanguageSettingScreen: undefined;
};

export type CategoryParamList = {
  Categories: undefined;
  SubCategory: undefined;
  SubCategoryNoAnimation: undefined;
  Cart: undefined;
  ProductBundle: undefined;
  SearchNavigator: undefined;
  Brand: undefined;
};

export type PostParamList = {
  Post: undefined;
  PostDetail: undefined;
  SubmitPost: undefined;
};

export type CartParamList = {
  Cart: undefined;
  OrderPreview: {
    tId: number;
    ruId: number;
    selectedAddress?: AddressInformation;
    newPickupAddressIndex?: number;
    orderType: number;
    shippingType?: number;
    bundleShippingTime?: number;
    selectedCoupon?: number;
  };
  OrderSubmit: {
    data: any;
  };
  OrderResult: {
    orderId: number;
    status: string;
    redPacket?: RedPacket | [];
  };
  OrderDetail: { orderId: number };
  Address: AddressParamList;
  PickupLocationList: {
    list: Array<{
      id: number;
      shipping_area_id: number;
      name: string;
      user_name: string;
      mobile: string;
      address: string;
      img_url: string;
      anchor: string;
      line: string;
    }>;
  };
  NewCreditCardScreen: undefined;
  CouponPromo: undefined;
  WebPaymentScreen: { id: number };
  OrderCheck: { id: string };
  BundleDetail: { id: number; time: number };
  SpecialOffer: { id: number; type?: 'current' | 'next' };
  UploadFileScreen: {
    creditFormInfo: CreditForm;
    data: any;
    from: string;
  };
  AddOrderGoodsScreen: {
    orderType: number;
    tId: number;
    ruId: number;
    orderId: number;
  };
};

export type CreditForm = { form: OrderFormInfo[]; remark: string };

export type RedPacket = {
  link: string;
  img: string;
};

export type UserParamList = {
  UserProfile: { data: any };
  UserSettings: undefined;
  UserInfo: {
    data: any;
    newPicture?: string;
    newName?: string;
    newGender?: number;
    newBirthday?: string;
    newPhone?: string;
    newEmail?: string;
    code?: string;
  };
  UserInfoEdit: { type: string; data: any };
  Address: undefined;
  NewAddress: undefined;
  CustomerServiceModal: { ruId?: number };
  Wallet: undefined;
  Review: undefined;
  Favorites: undefined;
  HelpCenter: undefined;
  Order: undefined;
  CreditCard: undefined;
  Messages: undefined;
  InviteFriends: undefined;
  Articles: undefined;
  GroupSale: undefined;
  IdVerification: undefined;
  Vouchers: undefined;
  Claim: undefined;
};

export type GroupSaleParamList = {
  Categories: undefined;
  SubCategory: { catId: number };
};

export type SettingsParamList = {
  SettingsMainScreen: undefined;
  LanguageSettingScreen: undefined;
  AboutLuniu: undefined;
  SettingWebView: { url: string; title: string };
  NotificationSettingScreen: undefined;
  FontSettingScreen: undefined;
};

export type ModalParamList = {
  AuthNavigator: undefined;
  UserLocationModal: undefined;
  CustomerServiceModal: undefined;
};

export type SearchParamList = {
  SearchModal: undefined;
  SearchResult: undefined;
};

export type AuthParamList = {
  AuthModal: undefined;
  ForgotPasswordScreen: undefined;
  WeChatRegisterScreen: undefined;
  RegistrationApprovalScreen: undefined;
};

export type ForgotPasswordParamList = {
  VerifyUserForm: undefined;
  ResetPasswordForm: undefined;
};

export type AddressParamList = {
  AddressList: { type: 'edit' | 'view' };
  NewAddress: undefined;
  EditAddress: undefined;
  MapView: undefined;
};

export type WalletParamList = {
  AccountScreen: undefined;
  PointScreen: undefined;
  EarnPointsScreen: { points: number };
  CouponList: undefined;
  AddFundsScreen: undefined;
  CouponRedeem: undefined;
};

export type ProductParamList = {
  ProductDetailScreen: undefined;
  CommentScreen: undefined;
  CustomerServiceModal: { ruId?: number };
  Cart: undefined;
};

import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
export type ReviewParamList = {
  ReviewListScreen: undefined;
  SubmitReviewScreen: {
    imageData?: ImageInfo[];
    goods_id?: number;
    order_id?: number;
    rec_id?: number;
    ru_id?: number;
    goods_thumb?: string;
    goods_name?: string;
    goods_attr?: string;
  };
};

export type ClaimParamList = {
  ClaimList: { orderId?: number };
  ClaimDetail: { claimId: number };
  ClaimOrderDetail: { orderId: number };
};

export type OrderParamList = {
  OrderList: { type: 'all' | 'awaitPay' | 'awaitShip' };
  OrderDetail: { orderId: number; type?: 'all' | 'awaitPay' | 'awaitShip' };
  OrderSubmit: {
    data: any;
  };
  OrderCheck: {
    id: string;
  };
  OrderResult: {
    orderId: number;
    status: string;
    redPacket?: RedPacket | [];
  };
  NewCreditCardScreen: undefined;
  WebPaymentScreen: undefined;
  OrderTracking: { orderSn: string };
  UploadFileScreen: {
    creditFormInfo: CreditForm;
    data: any;
    from: string;
  };
  OrderClaimList: { orderId: number };
  OrderClaimDetail: { claimId: number };
  TaskGoodsList: { orderId: number };
};

export type HelpCenterParamList = {
  HelpCenterListScreen: undefined;
  HelpCenterItemScreen: undefined;
};

export type ArticleParamList = {
  ArticleListScreen: undefined;
  ArticleScreen: { id: number };
};

export type CreditCardParamList = {
  CreditCardListScreen: undefined;
  NewCreditCardScreen: undefined;
};

export type ProductBundleParamList = {
  BundleHome: undefined;
  BundleDetail: { id: number; time: number };
};

export type BrandParamList = {
  BrandList: undefined;
  BrandDetail: { brandId: number };
};

export type MessageParamList = {
  MessageCenter: undefined;
  MessageDetail: undefined;
};

//Reducer type --------- Start
export type ReducerActionType = {
  type: string;
  payload?: any;
};

export type GoodsState = {
  location: LocationInformation;
  categoriesArray: Array<CategoriesItem>;
  selectedMainCategoryIndex: number;
  selectedHomeCategoryName: string;
  selectedSubCategoryIndex: number;
  subCategoryLength: number;
  homeGoodsList: Array<{
    catId: number;
    data: Array<ProductInformation>;
  }>;
  homeGoodsListSelectedIndex: number;
  selectedProduct: ProductInformation | null;
  showProductOptionWindow: boolean;
  isPresaleProduct: boolean;
  isResetGoodsList: boolean;
  isFetchingGoods: boolean;
  alertModal: {
    visible: boolean;
    title: string;
    message?: string;
    buttons?: AlertButtons;
    options?: any;
  };
  shoppingCart: {
    itemCounter: number;
    cartList: Array<CartShop>;
  };
  shopStatus: {
    code: number;
    message: string;
    shopRegCompany: boolean;
    shopTitle: string;
    shopDesc: string;
  };

  //Group Sale
  selectedGroupSaleCategoryIndex: number;
  groupSaleCategoriesArray: Array<CategoriesItem>;
};

export type UserState = {
  isLoggedIn: boolean;
  preferredLanguage: string | null | undefined;
  fontSizeIncrement: number;
  userInfo: {
    name: string;
    phoneNumber: string;
    userId: number;
  };
  editingAddress: AddressDetail;
  googleMayWebKey: string;
  appMainColor: string;
  appOutOfDate: boolean;
  appStoreLink: string;
  displayFloatCS: boolean;
  isLocatedInChina: boolean;
  mapboxToken: string;
  appLoginAppId: string;
  webLoginAppId: string;
  otherAppId: string;
  universalLink: string;
};

export type UrlState = {
  isDangerousUrl: boolean;
};

export type RootState = {
  user: UserState;
  goods: GoodsState;
  url: UrlState;
};

export type LocationInformation = {
  countryId: number;
  provinceId: number;
  cityId: number;
  areaName: string;
  provinceName: string;
  postalCodePrefix: string;
};
//Reducer type --------- End

export type AddressDetail = {
  name: string;
  location: { latitude: number; longitude: number };
};

//HomeScreen --------- Start
export type HomeScreenDataType = {
  ad: Array<any>;
  ad_type: string;
  position_id: number;
  position_name: string;
  tc_type: string;
  background_color?: string;
  ad_height: number;
  bottom_margin?: number;
  lr_margin?: number;
  three_ads_method?: string;
};

//HomeScreen ---------- End

//CategoriesScreen -------- Start
export type CategoriesItem = {
  name: string;
  id: number;
  cat_img: string;
  banner: Array<Banner>;
  cat_id?: Array<SubCategoryData>;
  show_in_nav: 0 | 1;
  haschild: 0 | 1;
  goods_type: string;
  url?: {
    url_type: string;
    type: string;
    id?: number;
  };
};

export type CategoriesListProp = {
  list: Array<CategoriesItem>;
};

export type SubCategoryData = {
  id: number;
  name: string;
  banner: Array<Banner>;
  haschild: number;
  show_in_nav: number;
  cat_img: string;
  cat_id?: Array<CategoriesItem>;
  goods_type?: string;
};

export type ThirdLevelCategory = {
  banner: Array<Banner>;
  cat_img: string;
  haschild: number;
  id: number;
  name: string;
  show_in_nav: number;
};

export type Banner = {
  cat_banner: string;
  cat_banner_link: string;
};

export type GoodsNumberRule = { sign: string; number: number; desc: string };

//For Good Item inside List
export interface ProductInformation {
  add_type: number;
  cat_id: number;
  current_gift_number?: number;
  formated_promote_price: string;
  formated_shop_price: string;
  formated_final_goods_price: string;
  formated_estimate_shop_price: string;
  goods_brief: string;
  goods_id: number;
  goods_name: string;
  goods_unit: string;
  goods_thumb: string;
  goods_number_rule: GoodsNumberRule;
  is_presale: number;
  is_weight_goods: number;
  estimate_shop_price: number;
  promote_price: number;
  ru_id: number;
  tid?: number;
  shop_price: string;
  watermark_img: string;
  stock: number;
  limit_number?: number;
  specification: Array<{
    attr_id: number;
    attr_type: number;
    attr_type_name: string;
    name: string;
    values: Array<{
      attr_checked: number;
      attr_gallery_flie: string;
      attr_sort: number;
      formated_price: string;
      id: number;
      label: string;
      price: string;
    }>;
  }>;
  shop?: {
    shop_name: string;
    shop_logo: string;
    ru_id: number;
  };
  tag?: string;
  text_tag?: {
    text: string;
    color: string;
  };
  weight_tag?: string;
  is_search: 0 | 1;
  //For special offer redeem
  insert_stage?: number;
}

//CategoriesScreen -------- End

//ProductDetailScreen -------- Start
//For data from goods Id
export type GoodsInfo = {
  goods_id: number;
  goods_name: string;
  goods_unit: string;
  goods_thumb: string;
  goods_img: string;
  goods_sn: string;
  desc_mobile:
    | string
    | {
        type: string;
        value?: string;
        style?: {
          fontSize?: string;
          fontWeight?: any;
          fontColor?: string;
          backgroundColor?: string;
          textAlign?: any;
        };
        thumbMedia?: string;
        largeMedia?: string;
        originalMedia?: string;
      }[];
  goods_brief: string;
  shop: Shop;
  limit_number: number;
  comments: {
    comments: Array<Comment>;
    avg_rank: string;
    total_item: number;
    total_page: number;
  };
  pics: Array<ProductMedia>;
  watermark_img: string;
  formated_shop_price: string;
  formated_final_goods_price: string;
  formated_estimate_shop_price: string;
  estimate_shop_price: number;
  is_collect: boolean;
  is_presale: 0 | 1;
  is_on_sale: number;
  is_alone_sale: number;
  weight_tag?: string;
  review_status: number;
  tag?: string;
  text_tag?: {
    text: string;
    color: string;
  };
  stock: number;
  specification: Array<{
    attr_id: number;
    attr_type: number;
    attr_type_name: string;
    name: string;
    values: Array<{
      attr_checked: number;
      attr_gallery_flie: string;
      attr_sort: number;
      formated_price: string;
      id: number;
      label: string;
      price: string;
    }>;
  }>;
};

export type ProductMedia = {
  img_id: number;
  img_url: string;
  thumb_url: string;
  type: string;
};

export type Comment = {
  comment_id: number;
  content: string;
  comment_rank: number;
  add_time: string;
  nick_name: string;
  user_picture: string;
  images: Array<CommentImage>;
  admin_reply: {
    comment_id: number;
    content: string;
  } | null;
};

export type CommentImage = {
  id: number;
  comment_id: number;
  comment_img: string;
};

//ProductDetailScreen --------- end

//PostScreen -------- Start
export type PostCategoryItem = {
  cat_id: number;
  cat_name: string;
  cat_alias_name?: string;
};

//PostScreen -------- End

//CartScreen -------- Start
export type CartItem = {
  rec_id: number;
  ru_id: number;
  tid: number;
  user_id: number;
  goods_id: number;
  goods_name: string;
  goods_price: string;
  main_goods_price: string;
  goods_number: number;
  goods_number_rule: GoodsNumberRule;
  goods_attr: string;
  is_real: number;
  is_gift: number;
  extension_code: string;
  is_invalid: boolean;
  goods_thumb: string;
  limit_number: number;
  stock: number;
  subtotal: number;
  main_subtotal: number;
  formated_goods_price: string;
  has_tax: boolean;
  rec_type: CartGoodsType;
  best_shipping_time?: number;
  tag?: string;
  weight_tag?: string;
  is_checked: 0 | 1;
};

// 商品类型 Goods Type
export enum CartGoodsType {
  GENERAL_GOODS = 0,
  CART_GROUP_BUY_GOODS = 1,
  CART_AUCTION_GOODS = 2,
  CART_SNATCH_GOODS = 3,
  CART_EXCHANGE_GOODS = 4,
  CART_PRESALE_GOODS = 5,
  CART_TEAM_GOODS = 6,
  CART_SECKILL_GOODS = 7,
  CART_BARGAIN_GOODS = 8,
  CART_PACKAGE_GOODS = 9,
}

export type CartShop = {
  ru_id: number;
  shop_name: string;
  shop_logo: string;
  tid: number;
  shipping_fee: string;
  shipping_name: string;
  rec_type: CartGoodsType;
  data: Array<CartItem>;
  total: {
    amount: number;
    total_goods_number: number;
    checked_goods_number: number;
    formated_amount: string;
  };
  best_time?: string;
  activity_list?: {
    current?: CartActivity;
    next_activity?: CartActivity;
  };
  best_shipping_time?: number;
};

//CartScreen -------- End

//Uncomment Screen ------- Start

export type UncommentList = {
  goods: Array<UncommentListItem> | [];
  total_item: number;
  total_page: number;
};

export type UncommentListItem = {
  order_id: number;
  goods_id: number;
  goods_name: string;
  goods_attr: string;
  goods_thumb: string;
  ru_id: number;
  rec_id: number;
  get_points: number;
};

//Uncomment Screen ------- End

//Comment Screen ------- Start

export type CommentListItem = {
  comment_id: number;
  goods_id: number;
  goods_thumb: string;
  goods_name: string;
  nick_name: string;
  comment_rank: number;
  content: string;
  user_picture: string;
  add_time: string;
  images: Array<CommentImage>;
};

//Comment Scren ------- End

export type Shop = {
  id: number;
  ru_id: number;
  shop_name: string;
  country_id: number;
  province_id: number;
  city_id: number;
  shop_address: string;
  kf_tel: string;
  kf_wechat: string;
  shop_logo: string;
  logo_thumb: string;
  store_description: string;
};

export type TokenPayload = {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  mobile_phone: string;
  nbf: number;
  user_id: number;
  user_name: string;
} | null;

export type AddressInformation = {
  address_id: number;
  address_name: string;
  address: string;
  user_id: number;
  consignee: string;
  email: string;
  country: number;
  province: number;
  city: number;
  district: number;
  street: number;
  latitude: string;
  longitude: string;
  postal_code: string;
  zipcode: string;
  mobile: string;
  sign_building: string;
  best_time: string;
  audit: number;
  update_time: string | null;
  is_default?: 0 | 1;
  district_name: string;
};

//Order Form ------- Start
export type OrderDetailType = {
  order_id: number;
  order_sn: string;
  user_id: number;
  order_status: number;
  shipping_status: number;
  pay_status: number;
  consignee: string;
  address: string;
  sign_building: string;
  postal_code: string;
  zipcode: string;
  mobile: string;
  best_time: string;
  shipping_name: string;
  shipping_code: string;
  pay_id: number;
  pay_name: string;
  formated_order_status: string;
  total: OrderTotalType;
  add_time: string;
  confirm_time: number;
  pay_time: number;
  shipping_time: number;
  ru_id: number;
  rec_type: CartGoodsType;
  pay_code: string;
  pay_desc: string;
  item_number: number;
  cancel_button: boolean;
  pay_button: boolean;
  delete_button: boolean;
  receive_button: boolean;
  reorder_button: boolean;
  task_goods_button: boolean;
  show_payment_list: boolean;
  delivery_status: 0 | 1 | 2;
  buyer_remark?: string;
  form_value: Array<OrderFormInfoLight>;
  goods_list: Array<{
    goods_id: number;
    goods_name: string;
    goods_attr: string;
    goods_price: string;
    goods_number: number;
    gst: number;
    pst: number;
    ru_id: number;
    goods_thumb: string;
    formated_goods_price: string;
  }>;
  pickup_point?: {
    name: string;
    mobile: string;
    address: string;
  };
  total_item: number;
  payment: PaymentData;
  share_red_packet: [] | RedPacket;
};

export type PaymentMethod = {
  pay_id: number;
  pay_code: string;
  pay_name: string;
  pay_fee: string;
  pay_desc: string;
  icon_img: string;
};

export type PaymentConfig = {
  companyLabel: string;
  merchantIdentifier: string;
  supportedNetworks: string[];
  countryCode: string;
  currencyCode: string;
  displayItems?: any;
  options: any;
};

export type PaymentData = {
  list: PaymentMethod[];
  config: PaymentConfig;
  card_info?: CreditCardInList;
  card_list?: CreditCardInList[];
};

export type OrderTotalType = {
  goods_amount: number;
  shipping_fee: number;
  pay_fee: number;
  gst_fee: number;
  pst_fee: number;
  money_paid: number;
  surplus: number;
  discount: number;
  coupons: number;
  order_amount: number;
  return_amount: number;
  tax_fee: number;
  pack_fee: number;
  insure_fee: number;
  bonus: number;
  integral: number;
  integral_money: number;
  deposit_fee: number;
  recycle_fee: number;
  formated_deposit_fee: string;
  formated_recycle_fee: string;
  formated_bonus: string;
  formated_goods_amount: string;
  formated_shipping_fee: string;
  formated_insure_fee: string;
  formated_pack_fee: string;
  formated_tax_fee: string;
  formated_coupons: string;
  formated_discount: string;
  formated_pay_fee: string;
  formated_surplus: string;
  formated_money_paid: string;
  formated_order_amount: string;
  formated_return_amount: string;
  formated_gst_fee: string;
  formated_pst_fee: string;
  formated_total_amount: string;
  formated_integral_money: string;
  formated_unpaid_amount: string;
  unpaid_amount: number;
};

export type CreditCardInList = {
  credit_card: string;
  expiry_year: string;
  expiry_month: string;
  id: number;
  is_default: number;
};

export type OrderFormInfo = {
  field_id: number;
  ru_id?: number;
  parent_id?: number;
  field_code: string;
  field_type: string;
  field_name: string;
  child?: Array<OrderFormInfo>;
  is_required?: number;
};

export type OrderFormInfoLight = {
  field_id: number;
  field_code: string;
  field_name: string;
  child: {
    [key: string]: {
      field_name: string;
      value: string;
    };
  };
};

export type ShippingTime = {
  name: string;
  time: number;
  startTime?: number;
  fast_delivery?: number;
  fast_delivery_notice?: string;
};

export type ShippingTimeInfoTime = {
  label: string;
  value: string;
  fast_delivery?: number;
};

//Order Form ------- End

export type SelectedPaymentType = {
  id: number;
  name: string;
  description: string;
  code: string;
};

export type AdvertisementType = 'pay_success' | 'pay_fail';

//ThemeHomeScreen start

export type TopicPage = {
  id: number;
  page_name: string;
  parent_id: number;
  ru_id: number;
  type: string;
  title: string;
  title_color: string;
  keywords: string;
};

//ThemeHomeScreen end

export type AdItem = {
  ad_id: number;
  media_type: number;
  media_type_name: string;
  img: string;
  cat_id: number;
  brand_cat_id: number;
  cat_name: string;
  goods_list_style: number;
  goods_type: string;
  show_title: number;
  title: string;
  title_color: string;
  brand_id: number;
  brand_name?: string;
  brand_item_number?: number;
  link: {
    id: string;
    url_type: string;
    type: string;
    url: string;
    appid?: string;
    name?: string;
    title?: string;
  };
};

export type OrderResultAd = {
  ad_id: number;
  position_id: number;
  ad_name: string;
  banner: string;
  link: OrderResultAdLink;
  title: string;
};

type OrderResultAdLink = {
  id?: number;
  url_type: string;
  type: string;
  url: string;
  appid?: string;
  name?: string;
  title?: string;
};

//Product Bundle start
export type BundleDate = {
  day: number;
  best_time: string;
  shipping_time: number;
  package_number: number;
};

export type BundleProduct = {
  package_id: number;
  goods_id: number;
  product_id: number;
  goods_number: number;
  goods_name: string;
  goods_unit: string;
  shop_price: string | number;
  goods_brief: string;
  goods_thumb: string;
  goods_attr: string;
  formated_shop_price: string;
};

export type BundleObject = {
  act_id: number;
  act_name: string;
  ru_id: number;
  act_desc: string;
  activity_thumb: string;
  goods_number: number;
  day_sales_volume: number;
  day: number;
  best_time: string;
  shipping_time: number;
  package_number: number;
  gst: string | number;
  pst: string | number;
  tie: number;
  formated_package_price: string;
  stock: number;
  package_goods: {
    goods: Array<BundleProduct>;
    item_number: number;
    total_amount: number;
    formated_total_amount: string;
  };
  notice: {
    title: string;
    content: string;
  };
};

//Product Bundle end

export type AlertButton = {
  text?: string;
  onPress?: Function;
  style?: string;
};

export type AlertButtons = Array<AlertButton>;

//Coupon
export type CouponType = {
  is_invalid: 0 | 1;
  uc_id: number;
  cou_id: number;
  ru_id: number;
  cou_type: number;
  cou_title: string;
  coupon_amount: string;
  formated_coupon_amount: string;
  cou_man: string;
  cou_start_time: number;
  cou_end_time: number;
  is_use_time: string;
  cou_type_name: string;
  coupon_time_desc: string;
  coupon_amount_desc: string;
  coupon_desc: string;
};

//Article related Types
export type ArticleCategory = {
  cat_name: string;
  id: number;
  keywords: string;
};

export type ArticleListItemType = {
  article_id: number;
  title: string;
  description: '';
  image: string;
  add_time: string;
  link: string;
};

export type CartActivity = {
  act_id: number;
  act_name: string;
  act_type_name: string;
  formated_min_amount: string;
  max_amount: string;
  min_amount: string;
  note: string;
  formated_amount_diff?: string;
};

export type Voucher = {
  bonus_id: number;
  bonus_type_id: number;
  bonus_sn: number;
  amount: string;
  user_id: number;
  used_time: number;
  bonus: {
    type_id: number;
    type_name: string;
    type_code: string;
    send_start_date: string;
    send_end_date: string;
    use_start_date: string;
    use_end_date: string;
    shop: Shop;
  };
  formated_amount: string;
  is_valid: boolean;
  is_use: boolean;
};

export type qrCode = {
  code: string;
  expire_in_seconds: number;
  current_time: number;
};

export type ReorderItem = {
  goods_id: number;
  goods_name: string;
  goods_thumb: string;
};

export type SelfServiceTicket = {
  ticket_type: number;
  name: string;
  desc: string;
  tags: string;
  template_id: number;
  style: {
    backgroundColor: string;
    textColor: string;
  };
  step_progress_bar: any[];
  step: any;
};

export type SelfServiceStepSection = {
  code: string;
  type: string;
  name: string;
  desc: string;
  is_required: boolean;
  style: {
    backgroundColor: string;
    textColor: string;
  };
  range: string[];
  max_num: number;
  url: {
    id: number;
    type: string;
    url_type: string;
    url: string;
    appid: string;
  };
};

export type Claim = {
  id: number;
  claim_sn: string;
  order_id: number;
  user_id: number;
  ru_id: number;
  claim_amount: string;
  final_amount: string;
  claim_type: {
    code: string;
    desc: string;
  };
  refund_method: {
    code: string;
    desc: string;
  };
  coupon_id: number;
  claim_reason: string;
  admin_id: number;
  add_time: string;
  review_status: {
    code: number;
    desc: string;
    color: string;
  };
  review_content: string;
  review_admin_id: number;
  review_time: string;
  remark: string;
  formated_claim_amount: string;
  formated_final_amount: string;
  claim_goods: ClaimGood[];
  claim_attachments: any[];
  order: {
    order_id: string;
    order_sn: string;
  };
};

export type ClaimGood = {
  id: number;
  claim_id: number;
  rec_id: number;
  claim_number: number;
  claim_reason: string;
  claim_amount: string;
  deposit_fee: string;
  recycle_fee: string;
  gst_fee: string;
  pst_fee: string;
  is_sortation_mistake: number;
  sortation_reason: string;
  remark: string;
  formated_claim_amount: string;
  formated_deposit_fee: string;
  formated_recycle_fee: string;
  formated_gst_fee: string;
  formated_pst_fee: string;
  order_goods: {
    rec_id: number;
    goods_id: number;
    goods_name: string;
    goods_unit: string;
    goods_attr: string;
    goods_number: number;
    goods_weight: string;
    goods_price: string;
    full_goods_name: string;
    formated_goods_price: string;
  };
};

export type TaskGood = {
  tg_id: number;
  order_id: number;
  goods_id: number;
  goods_name: string;
  goods_unit: string;
  goods_attr_name: string;
  goods_price: string;
  actual_price: string;
  goods_number: number;
  actual_number: number;
  is_weight_goods: number;
  goods_weight: string;
  actual_weight: string;
  status: {
    code: number;
    desc: string;
    color: string;
  };
  content: '';
  task_review_status: {
    code: number;
    desc: string;
    color: string;
  };
  formated_goods_price: string;
  formated_actual_price: string;
  full_goods_name: string;
  task_goods_images: {
    img_id: number;
    tg_id: number;
    img_name: string;
    img_url: string;
    add_time: string;
  }[];
};
