import { Platform } from 'expo-modules-core';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, View } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { ORANGE1, GREY2 } from '../../constants/Colors';
import { RootState } from '../../types';
import StyledText from '../common/StyledText';
import NotificationDot from '../common/widgets/NotificationDot';
import { useDispatch, useSelector } from 'react-redux';

type GoodsActionToolbarProps = {
  leftSideMenuChangeState: boolean;
  numOfSelectedFilter?: string;
  setShowFilterPopUp?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedSorter?: React.Dispatch<
    React.SetStateAction<{ sort_key?: string; sort_value?: string }>
  >;
};

const GoodsActionToolbar = ({
  leftSideMenuChangeState,
  numOfSelectedFilter,
  setShowFilterPopUp,
  setSelectedSorter,
}: GoodsActionToolbarProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appMainColor = useSelector<RootState, string>(
    (state) => state.user.appMainColor
  );
  const [selectedAction, setSelectedAction] = useState<number>(0);
  const [priceASCState, setPriceASCState] = useState<boolean>();
  const filterSelectionToParams = [
    { sort_key: 'sort_order', sort_value: 'DESC' },
    { sort_key: 'sales_volume', sort_value: 'DESC' },
    { sort_key: 'shop_price', sort_value: 'ASC' },
    { sort_key: 'shop_price', sort_value: 'DESC' },
  ];

  //useEffect handling when the user changes category on the left side container
  useEffect(() => {
    setSelectedAction(0);
    setPriceASCState(undefined);
  }, [leftSideMenuChangeState]);

  //useEffect updating the product list updates based on the selected action from the Actions Toolbar
  useEffect(() => {
    dispatch({ type: 'SET_RESET_GOODS_LIST', payload: true });
    setSelectedSorter &&
      setSelectedSorter(filterSelectionToParams[selectedAction]);
  }, [selectedAction, setSelectedSorter]);

  /**
   * Main Space allocated for buttons such as Filter
   * or other action buttons
   * .container: is space for buttons
   * .cartIcon: is for circular shape holding the icon
   */
  return (
    <>
      {numOfSelectedFilter && numOfSelectedFilter !== '0' && (
        <NotificationDot
          label={numOfSelectedFilter}
          otherStyles={{
            right:
              Dimensions.get('window').width < 600
                ? Dimensions.get('window').width * 0.06
                : 0,
          }}
        />
      )}
      <View style={styles.container}>
        {/**Featured text button */}
        <View>
          <StyledText
            color={selectedAction === 0 ? appMainColor : GREY2}
            style={styles.textButton}
            size={14}
            onPress={() => {
              setPriceASCState(undefined);
              setSelectedAction(0);
            }}
          >
            {t('productInformation.sort.default')}
          </StyledText>
        </View>
        {/**Best Selling text button */}
        <View>
          <StyledText
            color={selectedAction === 1 ? appMainColor : GREY2}
            style={styles.textButton}
            size={14}
            onPress={() => {
              setPriceASCState(undefined);
              setSelectedAction(1);
            }}
          >
            {t('productInformation.sort.bestSelling')}
          </StyledText>
        </View>
        {/**Price text button */}
        <View>
          <StyledText
            color={
              selectedAction === 2 || selectedAction === 3
                ? appMainColor
                : GREY2
            }
            size={14}
            onPress={() => {
              priceASCState === undefined
                ? setPriceASCState(true)
                : setPriceASCState((prev) => !prev);
              setSelectedAction((prev) => (prev === 2 ? 3 : 2));
            }}
          >
            {`${t('productInformation.sort.price')} `}
          </StyledText>
        </View>
        {/**Used to cycle Price sorting icons */}
        <View style={styles.priceIconContainer}>
          <AntDesign
            style={styles.priceUpIcon}
            name="caretup"
            size={7}
            color={selectedAction === 2 ? appMainColor : GREY2}
          />
          <AntDesign
            style={styles.priceDownIcon}
            name="caretdown"
            size={7}
            color={selectedAction === 3 ? appMainColor : GREY2}
          />
        </View>
        {/**The code below is for filter icon button. Currently it is Disabled because there aren't any filtering needed.
         * If there are more filters needed, uncomment the body of code below and <ProductFilterPopup component in Level2Menu, then the
         * the icon and the modal will appear
         */}
        {/**Filter Icon Button button */}
        {/* <View style={styles.filterIconButton}>
        <TouchableOpacity onPress={()=>{setShowFilterPopUp((prev)=>!prev)}}>
          <View style={styles.cartIcon}>
            <MaterialCommunityIcons size={27} name="filter-outline" color={accentColor} />
          </View>
        </TouchableOpacity>
      </View> */}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 6,
    paddingBottom: 6,
    // paddingTop:5, //CHANGE THIS TO 10 IF WE WILL USE THE FILTER ICON BUTTON
  },
  priceIconContainer: {
    flexDirection: 'column',
  },
  priceUpIcon: {
    position: 'absolute',
    top: Platform.OS === 'web' ? 2 : Platform.OS === 'android' ? 2 : 3.5,
  },
  priceDownIcon: {
    position: 'absolute',
    bottom: Platform.OS === 'web' ? 2 : Platform.OS === 'android' ? 2 : 3.5,
  },
  cartIcon: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: ORANGE1,
    justifyContent: 'center',
  },
  textButton: {
    paddingRight: 20,
  },
  filterIconButton: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 10,
  },
});

export default GoodsActionToolbar;
