import React from 'react';
import WhiteCard from '../../common/WhiteCard';
import { View, TouchableOpacity } from 'react-native';
import StyledText from '../../common/StyledText';
import { AntDesign } from '@expo/vector-icons';
import { tintColorLight, accentColor } from '../../../constants/Colors';
import { useTranslation } from 'react-i18next';

type BalanceInfoProps = {
  balance: string;
  useBalance: boolean;
  triggerBalanceUsage: Function;
};

const BalanceInfo = ({
  balance = '0.00',
  useBalance,
  triggerBalanceUsage,
}: BalanceInfoProps) => {
  const { t } = useTranslation();

  return balance === '0.00' ? (
    <></>
  ) : (
    <WhiteCard title={t('order.accountBalance')}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingVertical: 10,
          flexWrap: 'wrap',
        }}
      >
        <StyledText style={{ marginTop: 5 }}>
          {t('order.currentBalance')}
          <StyledText color={accentColor} bold>
            {balance}
          </StyledText>
        </StyledText>

        <View style={{ marginTop: 5 }}>
          <TouchableOpacity
            style={{ flexDirection: 'row', alignItems: 'center' }}
            onPress={() => triggerBalanceUsage()}
          >
            <AntDesign
              name="checkcircle"
              size={20}
              color={useBalance ? tintColorLight : 'lightgray'}
              style={{ marginRight: 5 }}
            />
            <StyledText>{t('order.payWithBalance')}</StyledText>
          </TouchableOpacity>
        </View>
      </View>
    </WhiteCard>
  );
};

export default BalanceInfo;
