import React, { useEffect, useState } from 'react';
import {
  useNavigation,
  useRoute,
  RouteProp,
  useIsFocused,
} from '@react-navigation/native';

import OrderItem from '../../../components/Order/OrderItem';
import CustomerServiceBtn from '../../../components/Order/sections/CustomerServiceBtn';
import { getDataWithAuthToken } from '../../../utils/service';
import Loading from '../../../components/common/Loading';
import { FlatList } from 'react-native';
import ListFooterText from '../../../components/common/list/ListFooterText';
import ActivityIndicator from '../../../components/common/ActivityIndicator';
import EmptyDisplayBlock from '../../../components/common/EmptyDisplayBlock';
import { useTranslation } from 'react-i18next';
import { OrderDetailType, OrderParamList, ReorderItem } from '../../../types';
import ReorderModal from '../../../components/Order/ReorderModal';
import FullWidthView from '../../../components/common/FullWidthView';
import { WHITE5 } from '../../../constants/Colors';
import getAppStyle from '../../../utils/getAppStyle';

const OrderListScreen = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const route = useRoute<RouteProp<OrderParamList, 'OrderList'>>();
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [orderList, setOrderList] = useState<Array<any>>([]);
  //Indicator for reach bottom scroll update
  const [productAllLoaded, setProductAllLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(2);
  const [showReorderModal, setShowReorderModal] = useState(false);
  const [reorderErrors, setReorderErrors] = useState<ReorderItem[]>([]);
  const isFocused = useIsFocused();
  const [isFetching, setIsFetching] = useState(false);

  const type = route.params?.type ? route.params.type : 'all';

  useEffect(() => {
    // const unsubscribe = navigation.addListener('focus', () => {
    setIsLoadingComplete(false);
    setCurrentPage(1);
    setProductAllLoaded(false);
    setTotalPage(2);
    //Get type param from route and display orders based on different type

    switch (type) {
      default:
      case 'all':
        navigation.setOptions({
          headerTitle: t('order.allOrders'),
          headerRight: () =>
            !getAppStyle(process.env.EXPO_APP_TYPE)?.toHideFeature && (
              <CustomerServiceBtn />
            ),
        });
        break;
      case 'awaitPay':
        navigation.setOptions({
          headerTitle: t('order.unpaidOrder'),
          headerRight: () =>
            !getAppStyle(process.env.EXPO_APP_TYPE)?.toHideFeature && (
              <CustomerServiceBtn />
            ),
        });
        break;
      case 'awaitShip':
        navigation.setOptions({
          headerTitle: t('order.unreceivedOrder'),
          headerRight: () =>
            !getAppStyle(process.env.EXPO_APP_TYPE)?.toHideFeature && (
              <CustomerServiceBtn />
            ),
        });
        break;
    }
    setIsFetching(true);
    getOrderList(1, type)
      .then((result) => {
        if (result && result.goodStatus) {
          setTotalPage(result.data.total_page);
          setOrderList(result.data.orders);
        } else {
          console.log('err', result);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoadingComplete(true);
        setIsFetching(false);
      });
    // return unsubscribe;
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    if (currentPage !== 1 && currentPage <= totalPage && !productAllLoaded) {
      setIsFetching(true);
      getOrderList(currentPage, type)
        .then((result) => {
          // console.log('result is', result);
          if (result && result.goodStatus) {
            if (isSubscribed) {
              setOrderList((prev) => [...prev, ...result.data.orders]);
            }
          } else {
            console.log('err', result);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setIsFetching(false);
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [currentPage]);

  useEffect(() => {
    let isSubscribed = true;
    if (reorderErrors.length && isFocused && isSubscribed)
      setShowReorderModal(true);
    return () => {
      isSubscribed = false;
    };
  }, [isFocused]);

  const renderItem = ({ item }: { item: OrderDetailType }) => (
    <OrderItem
      item={item}
      setShowReorderModal={setShowReorderModal}
      setReorderErrors={setReorderErrors}
      type={type}
    />
  );

  const loadMoreOrder = () => {
    if (isFetching) return;
    if (currentPage < totalPage) {
      setCurrentPage((prev) => prev + 1);
    } else {
      setProductAllLoaded(true);
    }
  };

  return (
    <FullWidthView
      style={{ backgroundColor: WHITE5 }}
      edges={['bottom', 'left', 'right']}
    >
      {isLoadingComplete ? (
        orderList.length > 0 ? (
          <FlatList
            style={{ flex: 1 }}
            contentContainerStyle={{
              paddingHorizontal: 10,
              paddingTop: 10,
            }}
            data={orderList}
            renderItem={renderItem}
            keyExtractor={(item) => item.order_sn}
            onEndReached={loadMoreOrder}
            onEndReachedThreshold={0.01}
            ListFooterComponent={
              productAllLoaded ? ListFooterText : ActivityIndicator
            }
            maxToRenderPerBatch={200}
          />
        ) : (
          <EmptyDisplayBlock
            description={t('order.noOrder')}
            buttonLabel={t('cartScreen.goShopping')}
            onPress={() => {
              navigation.reset({ index: 0, routes: [{ name: 'Home' }] });
            }}
          />
        )
      ) : (
        <Loading />
      )}
      <ReorderModal
        title={t('order.prompt.reorderSuccessPartial')}
        visible={showReorderModal}
        setVisible={setShowReorderModal}
        setReorderErrors={setReorderErrors}
        items={reorderErrors}
      />
    </FullWidthView>
  );
};

export default OrderListScreen;

const getOrderList = async (
  page: number,
  type: 'awaitPay' | 'awaitShip' | 'all'
) => {
  let url = `order/list?page=${page}&size=10`;

  switch (type) {
    default:
    case 'all':
      break;

    case 'awaitPay':
      url += '&type=await_pay';
      break;
    case 'awaitShip':
      url += '&type=await_ship';
      break;
  }

  const result = await getDataWithAuthToken(url);
  return result;
};
