import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import * as Linking from 'expo-linking';
import StyledText from '../common/StyledText';
import useIsWeChat from '../../hooks/useIsWeChat';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';
import getAppStyle from '../../utils/getAppStyle';

//Placeholder for web because it doesn't support Wechat SDK
const WechatLogin = () => {
  const { t } = useTranslation();
  const showWeChatLoginButton = useIsWeChat();
  const webLoginAppId = useSelector<RootState, string>(
    (state) => state.user.webLoginAppId
  );

  const handleWeChatLoginPress = async () => {
    let referer: any = '';
    let redirect: any = '';
    const url = await Linking.getInitialURL();
    if (url) {
      const { queryParams } = Linking.parse(url);
      if (queryParams) {
        if (queryParams.referer) {
          referer = queryParams.referer;
        }
        if (queryParams.redirect) {
          redirect = queryParams.redirect;
        }
      }
    }

    const redirectUri = encodeURIComponent(
      `${
        getAppStyle(process.env.EXPO_APP_TYPE)?.appWebsite
      }/activate/wechat?refer=${referer}&redirect=${redirect}`
    );

    Linking.openURL(
      `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${webLoginAppId}&response_type=code&scope=snsapi_userinfo&redirect_uri=${redirectUri}`
    );
  };

  return (
    showWeChatLoginButton && (
      <>
        <StyledText align="center" style={{ marginTop: 10 }}>
          {t('AuthModal.otherMethods')}
        </StyledText>
        <View style={styles.container}>
          <TouchableOpacity
            onPress={handleWeChatLoginPress}
            style={{ alignItems: 'center' }}
          >
            <View style={styles.button}>
              <AntDesign name="wechat" size={30} color="white" />
            </View>
            <StyledText align="center">{t('AuthModal.wechatLogin')}</StyledText>
          </TouchableOpacity>
        </View>
      </>
    )
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginVertical: 10,
  },
  button: {
    borderRadius: 100,
    backgroundColor: '#07C160',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
  },
});

export default WechatLogin;
