import React from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import StyledText from './StyledText';
import { AntDesign } from '@expo/vector-icons';
import NavigateButton from './NavigateButton';
import { useTranslation } from 'react-i18next';

const { width } = Dimensions.get('window');

type FakeSearchBarProps = {
  width?: number | string;
};

/**
 * Fake styled Search Bar for home screen. Jump to search screen when being clicked
 */
const FakeSearchBar = ({ width }: FakeSearchBarProps) => {
  const { t } = useTranslation();

  const widthStyle = width ? { width: width } : {};

  return (
    <NavigateButton route="SearchNavigator">
      <View style={[styles.container, widthStyle]}>
        <AntDesign name="search1" size={24} color="#acacac" />
        <StyledText color="#acacac">
          {t('searchScreen.searchProduct')}
        </StyledText>
      </View>
    </NavigateButton>
  );
};

export default FakeSearchBar;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f2f3f5',
    borderRadius: 25,
    padding: 5,
    width: width * 0.8,
  },
});
