import React, { useState, useEffect } from 'react';
import FullWidthView from '../common/FullWidthView';
import StyledText from '../common/StyledText';
import Loading from '../common/Loading';
import PostalCodeSearch from '../common/PostalCodeSearch';
import AddressListRegion from '../User/Address/AddressListRegion';
import {
  View,
  StyleSheet,
  Platform,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard,
} from 'react-native';
import {
  tintColorLight,
  backGroundLight,
  subTextColor,
} from '../../constants/Colors';
import ListOption from '../common/list/ListOption';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  RootState,
  LocationInformation,
  AddressInformation,
} from '../../types';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import {
  setAsyncStorageData,
  getAsyncStorageData,
} from '../../utils/asyncStorage';
import alert from '../../utils/alert';
import { getDataWithAuthToken, postData } from '../../utils/service';
import * as Location from 'expo-location';
import { getData } from '../../utils/service';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import NavigateButton from '../common/NavigateButton';
import { SafeAreaView } from 'react-native-safe-area-context';

const UserLocationModal = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { height } = Dimensions.get('window');

  const location = useSelector<RootState, LocationInformation>(
    (state) => state.goods.location
  );

  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.user.isLoggedIn
  );

  let userCurrentLocation: any = false;

  const [addressList, setAddressList] = useState<Array<AddressInformation>>([]);
  const [loadingComplete, setLoadingComplete] = useState(false);

  // const [regionChangeLoading, setRegionChangeLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const locationInformation = await getAsyncStorageData(
        '@storage_location'
      );
      // Reset location state
      if (!locationInformation)
        dispatch({
          type: 'UPDATE_LOCATION',
          payload: {
            countryId: 0,
            provinceId: 0,
            cityId: 0,
            areaName: 'Unknown',
            provinceName: 'Unknown',
            postalCodePrefix: '',
          },
        });
    })();

    if (isLoggedIn) {
      getAddressList().then((result) => {
        if (result && result.goodStatus) {
          setAddressList(result.data);
        } else {
          console.log(result);
        }
        setLoadingComplete(true);
      });
    } else {
      setLoadingComplete(true);
    }
  }, []);

  const handleRelocate = () => {
    (async () => {
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        alert(
          t('regionScreen.requireLocationPermissionFailed'),
          t('regionScreen.setLocationPermissionTipIOS'),
          [
            {
              text: t('closeSeeMore'),
              onPress: () => {},
            },
          ]
        );
        userCurrentLocation = false;
      } else {
        let loading = Toast.showLoading('');
        let location = await Location.getLastKnownPositionAsync();
        if (location === null) {
          location = await Location.getCurrentPositionAsync({
            accuracy: Location.Accuracy.Lowest,
          });
        }

        //Get current use region
        getData(
          `current_region?lat=${location.coords.latitude}&lng=${location.coords.longitude}`
        )
          .then((result) => {
            if (result && result.goodStatus) {
              Toast.hide(loading);
              handleRegionChange(result.data.postal_code_prefix);
              Toast.hide();
              Toast.showSuccess(
                t('regionScreen.relocateSuccess', {
                  areaName: result.data.full_region_name,
                })
              );
            } else {
              Toast.hide(loading);
              Toast.show(t('regionScreen.notFound'), {
                position: Toast.position.CENTER,
              });
            }
          })
          .catch((err) => {
            console.log('Error with location check', err);
            Toast.hide(loading);
            Toast.show(t('regionScreen.notFound'), {
              position: Toast.position.CENTER,
            });
          });
      }
    })();
  };

  const handleRegionChange = (postalCode: string) => {
    Toast.hide();
    const loading = Toast.showLoading('');
    // setRegionChangeLoading(true);
    postPostalCode(postalCode)
      .then((result) => {
        // setRegionChangeLoading(false);
        if (result && result.goodStatus) {
          const newLocation = {
            countryId: result.data.country_id,
            provinceId: result.data.province_id,
            cityId: result.data.city_id,
            areaName: result.data.full_region_name,
            provinceName: result.data.province_name,
            postalCodePrefix: postalCode,
          };

          setAsyncStorageData('@storage_location', JSON.stringify(newLocation));
          dispatch({ type: 'UPDATE_LOCATION', payload: newLocation });
          Toast.hide(loading);
          Platform.OS === 'web'
            ? navigation.canGoBack()
              ? navigation.goBack()
              : window.history.back()
            : navigation.goBack();
        } else {
          Toast.hide(loading);
          Toast.show(t('regionScreen.notFound'), {
            position: Toast.position.CENTER,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        Toast.hide(loading);
      });
  };

  // if (userCurrentLocation === null || regionChangeLoading) {
  //   return (
  //     <FullWidthView>
  //       <Loading />
  //     </FullWidthView>
  //   );
  // }

  const appMainColor = useSelector<RootState, string>(
    (state) => state.user.appMainColor
  );

  return (
    <FullWidthView edges={['top', 'left', 'right']}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={{ flex: 1 }}
      >
        <TouchableWithoutFeedback
          onPress={Platform.OS === 'web' ? undefined : () => Keyboard.dismiss()}
          accessible={false}
        >
          <View style={{ flex: 1 }}>
            <View style={styles.header}>
              <NavigateButton goBack style={{ position: 'absolute', left: 8 }}>
                <AntDesign name="close" size={30} />
              </NavigateButton>
              <StyledText size={18}>{t('homeScreen.selectRegion')}</StyledText>
            </View>

            <ListOption>
              <PostalCodeSearch handleRegionChange={handleRegionChange} />
            </ListOption>
            <ListOption>
              <View style={styles.buttonsContainer}>
                <StyledText size={13} color={subTextColor}>
                  {t('address.info.locateCurrentLocation')}
                </StyledText>
                <TouchableOpacity onPress={handleRelocate}>
                  <View style={styles.relocateBtn}>
                    <Ionicons
                      name="md-locate"
                      size={16}
                      color={tintColorLight}
                    />
                    <StyledText color={appMainColor} size={13}>
                      {t('regionScreen.relocate')}
                    </StyledText>
                  </View>
                </TouchableOpacity>
              </View>
              <View>
                <StyledText
                  bold
                  size={14}
                  style={{ marginTop: 15, marginLeft: 12 }}
                >
                  {location.areaName}
                </StyledText>
              </View>
            </ListOption>

            <ScrollView
              style={{
                height: height,
                backgroundColor: backGroundLight,
              }}
            >
              {!loadingComplete ? (
                <View style={[Platform.OS !== 'web' ? { flex: 1 } : {}]}>
                  <Loading />
                </View>
              ) : (
                <View style={{ backgroundColor: backGroundLight }}>
                  {addressList.length > 0 && (
                    <View>
                      <View
                        style={{
                          paddingHorizontal: 15,
                          paddingBottom: 6,
                          paddingTop: 12,
                          borderBottomWidth: 1,
                          borderBottomColor: backGroundLight,
                        }}
                      >
                        <StyledText bold size={13}>
                          {t('regionScreen.selectFromAddress')}
                        </StyledText>
                      </View>
                      <AddressListRegion
                        data={addressList}
                        type={'region'}
                        handleRegionChange={handleRegionChange}
                      />
                    </View>
                  )}
                </View>
              )}
            </ScrollView>
          </View>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </FullWidthView>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 8,
  },
  locationContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  regionInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 3,
    justifyContent: 'flex-end',
  },
  relocateBtn: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  manualSelectContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'center',
  },
  manualSelectBtn: {
    marginVertical: 10,
  },
  confirmBtnContainer: {
    marginTop: 30,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: backGroundLight,
    paddingHorizontal: 6,
  },
  loadingContainer: {},
});

export default UserLocationModal;

const getAddressList = async () => {
  const result = await getDataWithAuthToken('user/address');
  return result;
};

const postPostalCode = async (postalCode: string) => {
  const result = await postData('store_region', { postal_code: postalCode });
  return result;
};
