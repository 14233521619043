import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, TouchableOpacity, View } from 'react-native';
import {
  accentColor,
  backGroundLight,
  warningRed,
} from '../../constants/Colors';
import { ProductInformation } from '../../types';
import AddToCartBtn from '../Cart/AddToCartBtn';
import ResponsiveImg from '../common/ResponsiveImg';
import StyledText from '../common/StyledText';
import ProductImageTag from './widgets/ProductImageTag';

type OneThirdCardProps = {
  data: ProductInformation;
  hasMargin: boolean;
  hasBackgroundColor: boolean;
};

const { width } = Dimensions.get('window');

const OneThirdCard = ({
  data,
  hasMargin,
  hasBackgroundColor,
}: OneThirdCardProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const soldOut = data.stock === 0 && data.specification.length === 0;

  const renderPriceText = () => {
    //Has Promote Price
    if (data.promote_price !== 0) {
      return (
        <View style={{ flex: 1 }}>
          <StyledText bold size={14} color={accentColor} numberOfLines={1}>
            {data.formated_promote_price}
            <StyledText size={12}>{' /' + data.goods_unit}</StyledText>
          </StyledText>
          <StyledText bold size={12} style={styles.originalPrice}>
            {data.formated_shop_price}
          </StyledText>
        </View>
      );
    } else {
      return (
        <View style={{ flex: 1 }}>
          <StyledText bold size={14} color={accentColor} numberOfLines={1}>
            {data.formated_shop_price}
            <StyledText size={12}>{' /' + data.goods_unit}</StyledText>
          </StyledText>
        </View>
      );
    }
  };

  const handleOpenProductDetail = () => {
    navigation.navigate('ProductNavigator', {
      screen: 'ProductDetailScreen',
      params: { productId: data.goods_id },
    });
  };

  return (
    <View
      style={[
        styles.cardContainer,
        hasMargin ? { marginHorizontal: '3%' } : {},
        hasBackgroundColor
          ? { borderColor: backGroundLight }
          : { borderColor: 'lightgray' },
      ]}
    >
      <TouchableOpacity onPress={handleOpenProductDetail}>
        <View>
          <View style={styles.thumbContainer}>
            <ResponsiveImg width={width / 3 - 30} uri={data.goods_thumb} />
            <ProductImageTag data={data} />
          </View>
          <View
            style={{
              justifyContent: 'space-between',
            }}
          >
            {typeof data.tag === 'string' && data.tag !== '' && (
              <View style={styles.tag}>
                <StyledText size={10} color={warningRed}>
                  {data.tag}
                </StyledText>
              </View>
            )}
            <View style={{minHeight: (typeof data.tag === 'string' && data.tag) ? 0 : width < 400 ? 45 : 60}}>
              <StyledText numberOfLines={3} size={11}>
                {data.goods_name}
              </StyledText>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              {renderPriceText()}
              <View style={styles.buttonContainer}>
                {soldOut ? (
                  <></>
                ) : data.is_presale === 1 ? (
                  <TouchableOpacity
                    style={styles.preOrderButton}
                    onPress={handleOpenProductDetail}
                  >
                    <StyledText size={12} color="white">
                      {t('productInformation.preOrderButton')}
                    </StyledText>
                  </TouchableOpacity>
                ) : (
                  <AddToCartBtn goodsId={data.goods_id} goodsData={data}>
                    <View style={styles.cartIcon}>
                      <MaterialCommunityIcons
                        name="cart-outline"
                        size={16}
                        color={accentColor}
                      />
                    </View>
                  </AddToCartBtn>
                )}
              </View>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    width: '31.333%',
    borderWidth: 1,
    borderColor: backGroundLight,
    borderRadius: 10,
    padding: 5,
    position: 'relative',
    marginBottom: 10,
    backgroundColor: 'white',
  },
  thumbContainer: {
    alignSelf: 'center',
    marginBottom: 5,
    position: 'relative',
  },
  originalPrice: {
    color: 'lightgray',
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  buttonContainer: {
    alignSelf: 'flex-end',
  },
  cartIcon: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: accentColor,
    padding: 5,
    justifyContent: 'center',
  },
  preOrderButton: {
    backgroundColor: accentColor,
    padding: 3,
  },
  watermark: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  tag: {
    borderWidth: 1,
    borderColor: warningRed,
    padding: 2,
    borderRadius: 5,
    marginRight: 3,
    alignSelf: 'flex-start',
  },
});

export default OneThirdCard;
