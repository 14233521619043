import { ReducerActionType, GoodsState } from '../types';

const initState: GoodsState = {
  location: {
    countryId: 0,
    provinceId: 0,
    cityId: 0,
    areaName: 'Unknown',
    provinceName: 'Unknown',
    postalCodePrefix: '',
  },
  categoriesArray: [],
  selectedSubCategoryIndex: 0,
  subCategoryLength: 0,
  selectedMainCategoryIndex: 0,
  selectedHomeCategoryName: '',
  homeGoodsList: [],
  homeGoodsListSelectedIndex: 0,
  selectedProduct: null,
  showProductOptionWindow: false,
  isPresaleProduct: false,
  alertModal: {
    visible: false,
    title: '',
  },
  isResetGoodsList: false,
  isFetchingGoods: false,

  //State for Cart
  shoppingCart: {
    itemCounter: 0,
    cartList: [],
  },

  shopStatus: {
    code: 0,
    message: '',
    shopRegCompany: true,
    shopTitle: '',
    shopDesc: '',
  },

  //Group Sale
  selectedGroupSaleCategoryIndex: 0,
  groupSaleCategoriesArray: [],
};

const goodsReducer = (state = initState, action: ReducerActionType) => {
  switch (action.type) {
    case 'UPDATE_CATEGORY_ARRAY':
      return { ...state, categoriesArray: action.payload };

    case 'UPDATE_SELECTED_SUBCATEGORY_INDEX': {
      return { ...state, selectedSubCategoryIndex: action.payload };
    }

    case 'UPDATE_SUBCATEGORY_LENGTH': {
      return { ...state, subCategoryLength: action.payload };
    }

    case 'UPDATE_SELECTED_MAIN_CATEGORY_INDEX': {
      return { ...state, selectedMainCategoryIndex: action.payload };
    }

    case 'UPDATE_SELECTED_HOME_CATEGORY_NAME': {
      return { ...state, selectedHomeCategoryName: action.payload };
    }

    case 'UPDATE_LOCATION': {
      return { ...state, location: action.payload };
    }

    //For selecting options like size, flavour etc
    case 'UPDATE_SELECTED_PRODUCT': {
      return { ...state, selectedProduct: action.payload };
    }
    case 'TRIGGER_PRODUCT_OPTION_WINDOW': {
      let newState = !state.showProductOptionWindow;

      if (action.payload) {
        newState = action.payload;
      }

      return {
        ...state,
        showProductOptionWindow: newState,
      };
    }

    case 'HIDE_PRODUCT_OPTION_WINDOW': {
      return { ...state, showProductOptionWindow: false };
    }

    case 'UPDATE_CART_ITEM_COUNTER': {
      return {
        ...state,
        shoppingCart: { ...state.shoppingCart, itemCounter: action.payload },
      };
    }

    case 'UPDATE_CART_LIST': {
      return {
        ...state,
        shoppingCart: { ...state.shoppingCart, cartList: action.payload },
      };
    }

    case 'SET_IS_PRESALE_PRODUCT': {
      return {
        ...state,
        isPresaleProduct: action.payload,
      };
    }

    case 'SET_IS_ALERT_VISIBLE': {
      return {
        ...state,
        alertModal: {
          ...state.alertModal,
          visible: action.payload,
        },
      };
    }

    case 'SET_ALERT_MODAL': {
      return {
        ...state,
        alertModal: action.payload,
      };
    }

    case 'SET_SHOP_STATUS': {
      return {
        ...state,
        shopStatus: {
          code: action.payload.code,
          message: action.payload.message,
          shopRegCompany: action.payload.shopRegCompany,
          shopTitle: action.payload.shopTitle,
          shopDesc: action.payload.shopDesc,
        },
      };
    }

    case 'SET_RESET_GOODS_LIST': {
      return { ...state, isResetGoodsList: action.payload };
    }

    case 'SET_FETCHING_GOODS': {
      return { ...state, isFetchingGoods: action.payload };
    }

    //For updating group sale states
    case 'UPDATE_GROUPSALE_CATEGORY_ARRAY':
      return { ...state, groupSaleCategoriesArray: action.payload };

    case 'UPDATE_SELECTED_GROUPSALE_MAIN_CATEGORY_INDEX': {
      return { ...state, selectedGroupSaleCategoryIndex: action.payload };
    }

    default:
      return state;
  }
};

export default goodsReducer;
