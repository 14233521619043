import React, { useEffect, useState } from 'react';
import {
  View,
  FlatList,
  StyleSheet,
  Dimensions,
  TouchableWithoutFeedback,
  TouchableOpacity,
} from 'react-native';
import {
  backGroundLight,
  accentColor,
  normalTextColor,
  tintColorLight,
  warningRed,
} from '../../../constants/Colors';
import { getGoodsListWithId, getGoodsUnderBrand } from '../../../utils/service';
import {
  ProductInformation,
  RootState,
  LocationInformation,
  AdItem,
} from '../../../types';
import StyledText from '../../common/StyledText';
import { useTranslation } from 'react-i18next';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonContent from 'react-native-skeleton-content';
import CachedImage from '../../common/cache-img/CachedImage';
import ProductImageTag from '../../Product/widgets/ProductImageTag';

type HorizontalGoodsListProps = {
  data: AdItem;
  marginBottom?: number;
  marginHorizontal?: number;
  backgroundColor?: string;
};

const { width } = Dimensions.get('window');

const HorizontalGoodsList = ({
  data,
  marginBottom,
  marginHorizontal,
  backgroundColor,
}: HorizontalGoodsListProps) => {
  const { t } = useTranslation();

  const location = useSelector<RootState, LocationInformation>(
    (state) => state.goods.location
  );

  const showTitle = data.show_title;
  const navigation = useNavigation();

  const dispatch = useDispatch();

  const [goodsArray, setGoodsArray] = useState<Array<ProductInformation>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //For avoid memory leak, check https://juliangaramendy.dev/use-promise-subscription/#:~:text=This%20is%20a%20no%2Dop,the%20cleanup%20function%20of%20useEffect%20.
    let isSubscribed = true;
    setLoading(true);
    if (data.brand_id !== 0) {
      getGoodsUnderBrand(
        data.cat_id,
        1,
        location.countryId,
        location.provinceId,
        location.cityId,
        10,
        data.goods_type,
        data.brand_id
      ).then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed) {
            setLoading(false);
            setGoodsArray(response.data.goods);
          }
        } else {
          console.error(response);
        }
      });
    } else {
      getGoodsListWithId(
        data.cat_id,
        1,
        location.countryId,
        location.provinceId,
        location.cityId,
        10,
        data.goods_type
      ).then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed) {
            setLoading(false);
            setGoodsArray(response.data.goods);
          }
        } else {
          console.error(response);
        }
      });
    }

    return () => {
      isSubscribed = false;
    };
  }, [location]);

  //Render Item in List
  const renderItem = ({ item }: any) => <ProductItem data={item} />;

  const handleSeeMorePress = () => {
    navigation.navigate('ProductList', {
      catId: data.cat_id,
      goodsType: data.goods_type,
      brandId: data.brand_id,
    });
    dispatch({
      type: 'UPDATE_SELECTED_HOME_CATEGORY_NAME',
      payload: data.cat_name,
    });
  };

  return loading ? (
    <SkeletonContent
      containerStyle={styles.contentContainer}
      layout={[
        {
          flexDirection: 'row',
          marginTop: 10,
          children: [
            {
              flexDirection: 'column',
              alignItems: 'center',
              marginHorizontal: 15,
              children: [
                { width: 100, height: 100, marginTop: 4 },
                { width: 90, height: 15, marginVertical: 4 },
                { width: 70, height: 15 },
              ],
            },
            {
              flexDirection: 'column',
              alignItems: 'center',
              marginHorizontal: 15,
              children: [
                { width: 100, height: 100, marginTop: 4 },
                { width: 90, height: 15, marginVertical: 4 },
                { width: 70, height: 15 },
              ],
            },
            {
              flexDirection: 'column',
              alignItems: 'center',
              marginHorizontal: 15,
              children: [
                { width: 100, height: 100, marginTop: 4 },
                { width: 90, height: 15, marginVertical: 4 },
                { width: 70, height: 15 },
              ],
            },
          ],
        },
      ]}
      isLoading={true}
    />
  ) : goodsArray.length > 0 ? (
    <View
      style={[
        styles.contentContainer,
        {
          paddingHorizontal: marginHorizontal,
          marginBottom,
          backgroundColor,
          paddingTop: 5,
        },
      ]}
    >
      {showTitle === 1 ? (
        <View
          style={[
            styles.headerTitle,
            {
              borderLeftColor: data.title_color
                ? data.title_color
                : tintColorLight,
            },
          ]}
        >
          <StyledText size={18}>{data.cat_name}</StyledText>
          <TouchableOpacity onPress={handleSeeMorePress}>
            <StyledText>{t('more')}</StyledText>
          </TouchableOpacity>
        </View>
      ) : (
        <></>
      )}

      <FlatList
        showsHorizontalScrollIndicator={false}
        data={goodsArray}
        renderItem={renderItem}
        keyExtractor={(item, index) =>
          item.goods_id.toString() + '@' + index.toString()
        }
        horizontal
        ListFooterComponent={
          <ListFooter
            catId={data.cat_id}
            catName={data.cat_name}
            goodsType={data.goods_type}
            brandId={data.brand_id}
          />
        }
        style={{
          paddingRight: 10,
        }}
      />
    </View>
  ) : (
    <View style={{ opacity: 0 }}>
      <StyledText size={1}>No Content, Placeholder</StyledText>
    </View>
  );
};

type ProductItemProps = {
  data: ProductInformation;
};

const ProductItem = ({ data }: ProductItemProps) => {
  const navigation = useNavigation();

  const soldOut = data.stock === 0 && data.specification.length === 0;

  const renderPriceText = () => {
    //Has Promote Price
    if (data.promote_price !== 0) {
      return (
        <>
          <StyledText bold size={12} style={styles.originalPrice}>
            {data.formated_shop_price}
          </StyledText>
          <StyledText bold size={16} color={accentColor} numberOfLines={1}>
            {data.formated_promote_price}
            <StyledText size={12}>{' /' + data.goods_unit}</StyledText>
          </StyledText>
        </>
      );
    } else {
      return (
        <>
          <StyledText bold size={12}>
            {' '}
          </StyledText>
          <StyledText bold size={16} color={accentColor} numberOfLines={1}>
            {data.formated_shop_price}
            <StyledText size={12}>{' /' + data.goods_unit}</StyledText>
          </StyledText>
        </>
      );
    }
  };

  const handleOpenProductDetail = () => {
    navigation.navigate('ProductNavigator', {
      screen: 'ProductDetailScreen',
      params: { productId: data.goods_id },
    });
  };

  return (
    <TouchableOpacity onPress={handleOpenProductDetail}>
      <View style={styles.productContainer}>
        <View>
          <CachedImage
            style={styles.productImg}
            source={{
              uri: data.goods_thumb,
            }}
          />
          <ProductImageTag data={data} />
        </View>
        {typeof data.tag === 'string' && data.tag !== '' && (
          <View style={styles.tag}>
            <StyledText size={10} color={warningRed}>
              {data.tag}
            </StyledText>
          </View>
        )}
        <StyledText
          align={'center'}
          style={styles.productName}
          numberOfLines={2}
        >
          {data.goods_name}
        </StyledText>
        {renderPriceText()}
      </View>
    </TouchableOpacity>
  );
};

type ListFooterProps = {
  catId: number;
  catName: string;
  goodsType?: string;
  brandId?: number;
};

const ListFooter = ({
  catId,
  catName,
  goodsType = 'all',
  brandId,
}: ListFooterProps) => {
  const { t } = useTranslation();

  const navigation = useNavigation();

  const dispatch = useDispatch();

  const handleSeeMorePress = () => {
    navigation.navigate('ProductList', {
      catId: catId,
      goodsType: goodsType,
      brandId: brandId,
    });
    dispatch({
      type: 'UPDATE_SELECTED_HOME_CATEGORY_NAME',
      payload: catName,
    });
  };

  return (
    <View style={styles.seeMoreBtnContainer}>
      <View style={styles.seeMoreBtn}>
        <TouchableWithoutFeedback onPress={handleSeeMorePress}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <StyledText>{t('seeMore')}</StyledText>
            <AntDesign
              name="rightcircleo"
              size={18}
              color={normalTextColor}
              style={{ marginLeft: 5 }}
            />
          </View>
        </TouchableWithoutFeedback>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerTitle: {
    paddingLeft: 5,
    marginVertical: 5,
    borderLeftWidth: 3,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: width - 20,
    alignSelf: 'center',
  },
  contentContainer: {
    width: width,
    marginBottom: 10,
  },
  productContainer: {
    marginRight: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'lightgray',
    borderRadius: 10,
    paddingHorizontal: 4,
    paddingVertical: 10,
    backgroundColor: 'white',
    maxWidth: 130,
    flex: 1,
  },
  originalPrice: {
    color: 'lightgray',
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  productName: {
    marginTop: 5,
    width: 120,
  },
  productImg: {
    width: 100,
    height: 80,
    backgroundColor: '#DEE7E7',
  },
  seeMoreBtnContainer: {
    flex: 1,
    justifyContent: 'center',
    marginRight: 10,
  },
  seeMoreBtn: {
    borderColor: backGroundLight,
    padding: 10,
    borderRadius: 10,
    borderWidth: 1,
    backgroundColor: 'white',
  },
  watermark: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  tag: {
    borderWidth: 1,
    borderColor: warningRed,
    padding: 2,
    borderRadius: 5,
    marginTop: 5,
  },
});

export default HorizontalGoodsList;
