import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { subTextColor } from '../../constants/Colors';
import StyledText from '../common/StyledText';

type KeepScrollTipProps = {
  shouldRelease?: boolean;
  nextCategoryName: string;
};

const KeepScrollTip = ({
  shouldRelease = false,
  nextCategoryName = '',
}: KeepScrollTipProps) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      {shouldRelease ? (
        <>
          <AntDesign name="downcircleo" size={12} color={subTextColor} />
          <StyledText align="center" size={12} color={subTextColor}>
            {' ' +
              t('categoriesScreen.scrollTip.release', {
                categoryName: nextCategoryName,
              })}
          </StyledText>
        </>
      ) : (
        <>
          <AntDesign name="upcircleo" size={12} color={subTextColor} />
          <StyledText align="center" size={12} color={subTextColor}>
            {' ' +
              t('categoriesScreen.scrollTip.pullUp', {
                categoryName: nextCategoryName,
              })}
          </StyledText>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default KeepScrollTip;
