import React, { useState, useRef, useEffect } from 'react';
import { View, StyleSheet, Dimensions, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Feather } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { CategoriesItem, RootState, SubCategoryData } from '../../../types';
import { screenHit } from '../../../utils/analytic';
import SubCategoriesList from '../../../components/Category/container/SubCategoriesList';
import LevelThreeCategoryWithSwipe from '../../../components/Category/container/level3/LevelThreeCategoryWithSwipe';
import CachedImage from '../../../components/common/cache-img/CachedImage';
import ProductInCategory from '../../../components/Category/container/ProductInCategory';
import FullWidthView from '../../../components/common/FullWidthView';
import ScrollableHeader from '../../../components/Category/ScrollableHeader';
import StyledText from '../../../components/common/StyledText';
import Loading from '../../../components/common/Loading';
import { backGroundLight } from '../../../constants/Colors';
import { getDataWithAuthToken } from '../../../utils/service';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import NavigateButton from '../../../components/common/NavigateButton';
import BackButton from '../../../components/common/iconButtons/BackButton';
import { useNavigation } from '@react-navigation/native';
import GoodsActionToolbar from '../../../components/Category/GoodsActionToolbar';
import { useHeaderHeight } from '@react-navigation/stack';

const { width } = Dimensions.get('window');

const SubCategoryScreen = ({ route }: any) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [categoryId, setCategoryId] = useState();
  const [data, setData] = useState<Array<SubCategoryData>>([]);
  const [levels, setLevels] = useState(0);
  const [loading, setLoading] = useState(false);
  const [horizontalIndex, setHorizontalIndex] = useState(0);
  const levelThreeCategoryRef = useRef<any>(null);
  const [categoryChangeState, setCategoryChangeState] =
    useState<boolean>(false);
  const [selectedSorter, setSelectedSorter] = useState<{
    sort_key?: string;
    sort_value?: string;
  }>({});
  const selectedSubCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedSubCategoryIndex
  );

  const groupSaleCategoriesArray = useSelector<
    RootState,
    Array<CategoriesItem>
  >((state) => state.goods.groupSaleCategoriesArray);

  const selectedGroupSaleCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedGroupSaleCategoryIndex
  );

  const resetHorizontalIndex = () => {
    setHorizontalIndex(0);
    if (levelThreeCategoryRef.current) {
      levelThreeCategoryRef.current.resetHorizontalPage();
    }
  };

  /**
   * If Platform is web, redirect to home, groupsale only available for App
   */
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      if (Platform.OS === 'web') {
        console.log('go back');
        navigation.reset({
          index: 0,
          routes: [{ name: 'Root' }],
        });
      }
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    const { catId } = route.params;
    screenHit(`category/${catId}`);
    setCategoryId(catId);
    setLoading(true);
    resetHorizontalIndex();
    getDataWithAuthToken(`groupsale/category?id=${catId}`)
      .then((result) => {
        if (result) {
          if (result.goodStatus) {
            setData(result.data);
            setLevels(calculateLevels(result.data));
            setLoading(false);
          } else {
            throw result.data;
          }
        } else {
          console.log('error');
        }
      })
      .catch((err) => {
        setLoading(false);
        Toast.show('err', {
          position: 0,
          duration: 2000,
        });
      });
  }, [route]);

  const calculateLevels = (data: Array<any>) => {
    if (data.length > 0) {
      let atleastOneHasChildren = false;
      data.forEach((item: SubCategoryData) => {
        if (item.haschild === 1) {
          atleastOneHasChildren = true;
        }
      });
      return atleastOneHasChildren ? 3 : 2;
    } else {
      return 1;
    }
  };
  const updateSubCategoryChange = () => {
    categoryChangeState
      ? setCategoryChangeState(false)
      : setCategoryChangeState(true);
  };

  const renderContent = () => {
    //Has sub category show left-right layout
    if (levels === 3) {
      dispatch({ type: 'UPDATE_SUBCATEGORY_LENGTH', payload: data.length });
      return (
        <>
          <View style={styles.leftContainer}>
            <SubCategoriesList
              resetHorizontalIndex={resetHorizontalIndex}
              data={data}
              updateSubCategoryChange={updateSubCategoryChange}
            />
          </View>
          <View style={styles.rightContainer}>
            {/* Check Level three Category if exist */}
            {data[selectedSubCategoryIndex].haschild === 1 &&
            data[selectedSubCategoryIndex].cat_id &&
            Array.isArray(data[selectedSubCategoryIndex].cat_id) ? (
              <LevelThreeCategoryWithSwipe
                updateSubCategoryChange={updateSubCategoryChange}
                categoryChangeState={categoryChangeState}
                ref={levelThreeCategoryRef}
                horizontalIndex={horizontalIndex}
                setHorizontalIndex={setHorizontalIndex}
                width={width * 0.78 - 10}
                levelThreeCategories={
                  data[selectedSubCategoryIndex].cat_id || []
                }
                listHeader={
                  data[selectedSubCategoryIndex].banner.length > 0 ? (
                    <View style={{ marginVertical: 5 }}>
                      <CachedImage
                        style={{
                          width: width * 0.78 - 10,
                          height: (width * 0.78 - 10) / 3,
                          borderRadius: 10,
                        }}
                        source={{
                          uri: data[selectedSubCategoryIndex].banner[0]
                            .cat_banner,
                        }}
                      />
                    </View>
                  ) : (
                    <></>
                  )
                }
                nextCategoryName={
                  data[selectedSubCategoryIndex + 1]
                    ? data[selectedSubCategoryIndex + 1].name
                    : groupSaleCategoriesArray[
                        selectedGroupSaleCategoryIndex + 1
                      ]
                    ? groupSaleCategoriesArray[
                        selectedGroupSaleCategoryIndex + 1
                      ].name
                    : groupSaleCategoriesArray[0].name
                }
                productType="groupsale"
              />
            ) : (
              <>
                <View style={styles.toolbarContainer}>
                  <GoodsActionToolbar
                    leftSideMenuChangeState={categoryChangeState}
                    setSelectedSorter={setSelectedSorter}
                  />
                </View>
                <ProductInCategory
                  catId={data[selectedSubCategoryIndex].id}
                  goodsType={
                    data[selectedSubCategoryIndex].goods_type !== undefined
                      ? data[selectedSubCategoryIndex].goods_type
                      : 'all'
                  }
                  listHeader={
                    data[selectedSubCategoryIndex].banner.length > 0 ? (
                      <View style={{ marginTop: 5 }}>
                        <CachedImage
                          style={{
                            width: width * 0.78 - 10,
                            height: (width * 0.78 - 10) / 3,
                            borderRadius: 10,
                          }}
                          source={{
                            uri: data[selectedSubCategoryIndex].banner[0]
                              .cat_banner,
                          }}
                        />
                      </View>
                    ) : (
                      <></>
                    )
                  }
                  nextCategoryName={
                    data[selectedSubCategoryIndex + 1]
                      ? data[selectedSubCategoryIndex + 1].name
                      : groupSaleCategoriesArray[
                          selectedGroupSaleCategoryIndex + 1
                        ]
                      ? groupSaleCategoriesArray[
                          selectedGroupSaleCategoryIndex + 1
                        ].name
                      : groupSaleCategoriesArray[0].name
                  }
                  productType="groupsale"
                  selectedSorter={{
                    sort_key: undefined,
                    sort_value: undefined,
                  }}
                />
              </>
            )}
          </View>
        </>
      );
    }
    //No sub category, show full width layout
    else if (levels === 2) {
      dispatch({ type: 'UPDATE_SUBCATEGORY_LENGTH', payload: data.length });
      return (
        <>
          <View style={styles.leftContainer}>
            <SubCategoriesList
              resetHorizontalIndex={resetHorizontalIndex}
              data={data}
              updateSubCategoryChange={updateSubCategoryChange}
            />
          </View>
          <View style={styles.rightContainer}>
            <View style={styles.toolbarContainer}>
              <GoodsActionToolbar
                leftSideMenuChangeState={categoryChangeState}
                setSelectedSorter={setSelectedSorter}
              />
            </View>
            <ProductInCategory
              selectedSorter={selectedSorter}
              catId={data[selectedSubCategoryIndex].id}
              goodsType={
                data[selectedSubCategoryIndex].goods_type !== undefined
                  ? data[selectedSubCategoryIndex].goods_type
                  : 'all'
              }
              listHeader={
                data[selectedSubCategoryIndex].banner.length > 0 ? (
                  <View style={{ marginTop: 5 }}>
                    <CachedImage
                      style={{
                        width: width * 0.78 - 10,
                        height: (width * 0.78 - 10) / 3,
                        borderRadius: 10,
                      }}
                      source={{
                        uri: data[selectedSubCategoryIndex].banner[0]
                          .cat_banner,
                      }}
                    />
                  </View>
                ) : (
                  <></>
                )
              }
              nextCategoryName={
                data[selectedSubCategoryIndex + 1]
                  ? data[selectedSubCategoryIndex + 1].name
                  : groupSaleCategoriesArray[selectedGroupSaleCategoryIndex + 1]
                  ? groupSaleCategoriesArray[selectedGroupSaleCategoryIndex + 1]
                      .name
                  : groupSaleCategoriesArray[0].name
              }
              productType="groupsale"
            />
          </View>
        </>
      );
    }
    //Empty data show plain Product List
    else {
      dispatch({ type: 'UPDATE_SUBCATEGORY_LENGTH', payload: 0 });
      return levels === 1 ? (
        <View style={{ flex: 1, width: '100%' }}>
          <View style={styles.toolbarContainer}>
            <GoodsActionToolbar
              leftSideMenuChangeState={categoryChangeState}
              setSelectedSorter={setSelectedSorter}
            />
          </View>
          <ProductInCategory
            selectedSorter={selectedSorter}
            catId={categoryId || -1}
            nextCategoryName={
              data[selectedSubCategoryIndex + 1]
                ? data[selectedSubCategoryIndex + 1].name
                : groupSaleCategoriesArray[selectedGroupSaleCategoryIndex + 1]
                ? groupSaleCategoriesArray[selectedGroupSaleCategoryIndex + 1]
                    .name
                : groupSaleCategoriesArray[0].name
            }
            productType="groupsale"
          />
        </View>
      ) : (
        <></>
      );
    }
  };
  const styles = StyleSheet.create({
    rootContainer: {
      paddingBottom: Platform.OS === 'web' ? useHeaderHeight() + 50 : 0,
      flexDirection: 'column',
      position: 'relative',
    },
    webHeader: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 5,
      borderBottomColor: 'lightgray',
      borderBottomWidth: 0.5,
      position: 'absolute',
      zIndex: 999,
      width: width,
      backgroundColor: 'white',
    },
    mobileHeader: {
      position: 'absolute',
      zIndex: 999,
      width: width,
      backgroundColor: 'white',
    },
    leftContainer: {
      backgroundColor: backGroundLight,
      width: width * 0.22,
    },
    rightContainer: {
      width: width * 0.78 - 10,
      marginHorizontal: 5,
    },
    toolbarContainer: {
      paddingBottom: 3,
    },
  });

  return (
    <>
      <FullWidthView edges={['left', 'right']} style={styles.rootContainer}>
        {Platform.OS === 'web' ? (
          <View style={styles.webHeader}>
            <NavigateButton route="Categories">
              <BackButton />
            </NavigateButton>
            <ScrollableHeader menuType="groupsale" />
          </View>
        ) : (
          <View style={styles.mobileHeader}>
            <ScrollableHeader menuType="groupsale" />
          </View>
        )}

        {/* dummy scrollableHeader to fill space under real scrollable header */}
        <View style={{ paddingVertical: 5, flexDirection: 'row' }}>
          <View style={{ flexDirection: 'column' }}>
            <View
              style={
                Platform.OS === 'web'
                  ? { width: 32, height: 32 }
                  : { width: 50, height: 50 }
              }
            />
            <StyledText style={{ paddingBottom: 2 }} bold size={10}>
              {' '}
            </StyledText>
          </View>
          <View style={{ flexDirection: 'column' }}>
            <StyledText size={14}>
              {t('categoriesScreen.dropdownButtonLine1')}
            </StyledText>
            <StyledText size={14}>
              {t('categoriesScreen.dropdownButtonLine2')}
            </StyledText>
            <Feather name="menu" size={14} color="black" />
          </View>
        </View>

        <View style={{ flexDirection: 'row', flex: 1 }}>
          {loading ? <Loading /> : <>{renderContent()}</>}
        </View>
      </FullWidthView>
    </>
  );
};

export default SubCategoryScreen;
