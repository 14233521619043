import { AntDesign } from '@expo/vector-icons';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useCounter, useMount } from 'ahooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  Image,
  Platform,
  StyleSheet,
  View,
  ScrollView,
} from 'react-native';
import Toast from 'react-native-tiny-toast';
import { useDispatch } from 'react-redux';
import EmptyDisplayBlock from '../../components/common/EmptyDisplayBlock';
import FullWidthView from '../../components/common/FullWidthView';
import Loading from '../../components/common/Loading';
import NavigateButton from '../../components/common/NavigateButton';
import StyledButton from '../../components/common/StyledButton';
import StyledText from '../../components/common/StyledText';
import AmountCounter from '../../components/Product/widgets/AmountCounter';
import BundleNotice from '../../components/ProductBundle/BundleNotice';
import {
  accentColor,
  backGroundLight,
  subTextColor,
} from '../../constants/Colors';
import {
  BundleObject,
  BundleProduct,
  ProductBundleParamList,
} from '../../types';
import { getData, postWithAuthToken } from '../../utils/service';
import { resetWechatSharePage } from '../../utils/wechatWebMethods';

const { width } = Dimensions.get('window');

const BundleDetailScreen = () => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const route = useRoute<RouteProp<ProductBundleParamList, 'BundleDetail'>>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [current, { set }] = useCounter(1, {
    min: 1,
  });

  const [bundleDetail, setBundleDetail] = useState<BundleObject>({});
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [errorHappened, setErrorHappened] = useState({ show: false, msg: '' });

  const { id = -1, time = -1 } = route.params;

  useMount(() => {
    if (id !== -1 && time !== -1) {
      (async () => {
        const result = await getBundleDetail(id, time);
        if (result && result.goodStatus) {
          setIsLoadingComplete(true);
          setBundleDetail(result.data);

          //Wechat share settings
          if (Platform.OS === 'web')
            resetWechatSharePage(
              `超值大礼包|${result.data.best_time}|${result.data.act_name}`
            );
        } else {
          setIsLoadingComplete(true);
          setErrorHappened({
            show: true,
            msg: result ? result.data : 'Network Error',
          });
          console.log('err for getting bundle detail', result);
        }
      })();
    } else {
      setIsLoadingComplete(true);
      setErrorHappened({ show: true, msg: '礼包参数错误' });
    }
  });

  const productList =
    Object.entries(bundleDetail).length > 0 &&
    bundleDetail.package_goods.goods.length > 0 ? (
      bundleDetail.package_goods.goods.map((item, index) => (
        <ItemThumbnail product={item} key={index} />
      ))
    ) : (
      <></>
    );

  const handleAddToCart = () => {
    (async () => {
      setIsFetching(true);
      const result = await addBundleToCart(id, time, current);
      if (result && result.goodStatus) {
        //Reset AmountSelector to 1
        set(1);
        Toast.showSuccess(t('productInformation.addSuccessfully'));
        dispatch({
          type: 'UPDATE_CART_ITEM_COUNTER',
          payload: result.data.total_number,
        });
      } else if (result && result.data) {
        Toast.show(t('productInformation.addFailed') + `, ${result.data}`, {
          position: 0,
          duration: 2000,
        });
      } else {
        Toast.show(t('productInformation.addFailed'), {
          position: 0,
          duration: 2000,
        });
      }
      setIsFetching(false);
      console.log(result);
    })();
  };

  return (
    <FullWidthView style={{ paddingBottom: 60 }}>
      {isLoadingComplete ? (
        errorHappened.show ? (
          <EmptyDisplayBlock
            buttonLabel={t('goBack')}
            icon={<AntDesign name="meh" size={80} color="lightgray" />}
            onPress={() => {
              Platform.OS === 'web'
                ? navigation.canGoBack()
                  ? navigation.goBack()
                  : window.history.back()
                : navigation.goBack();
            }}
            description={errorHappened.msg}
          />
        ) : (
          Object.entries(bundleDetail).length > 0 && (
            <>
              <ScrollView
                style={{ flex: 1 }}
                showsVerticalScrollIndicator={false}
              >
                {/* <View>
                  <ResponsiveImg
                    uri={bundleDetail.activity_thumb}
                    width={width}
                  />
                </View> */}
                <View style={styles.container}>
                  <View>
                    <StyledText>
                      <AntDesign name="calendar" size={16} color="lightgray" />
                      {t('bundleScreen.bundleDetail.deliveryDate')}
                      {bundleDetail.best_time}
                    </StyledText>
                    <StyledText size={20} bold>
                      {bundleDetail.act_name}
                    </StyledText>
                    <StyledText color={subTextColor} size={16}>
                      {bundleDetail.act_desc}
                    </StyledText>
                  </View>
                  <View style={styles.packageInfoContainer}>
                    <View
                      style={{
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRightWidth: 1,
                        borderRightColor: backGroundLight,
                      }}
                    >
                      <StyledText bold size={20} color={accentColor}>
                        {bundleDetail.package_goods.item_number}
                      </StyledText>
                      <StyledText>
                        {t('bundleScreen.bundleList.items')}
                      </StyledText>
                    </View>

                    <View
                      style={{
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <StyledText bold size={20} color={accentColor}>
                        {bundleDetail.formated_package_price}
                      </StyledText>
                      <StyledText bold size={12} style={styles.originalPrice}>
                        {bundleDetail.package_goods.formated_total_amount}
                      </StyledText>
                    </View>
                    {bundleDetail.day_sales_volume >= 10 && (
                      <View style={styles.saleIndicator}>
                        <StyledText align="center" size={20}>
                          {bundleDetail.day_sales_volume}
                        </StyledText>
                        <StyledText align="center">
                          {t('bundleScreen.bundleList.sold')}
                        </StyledText>
                      </View>
                    )}
                  </View>
                  <View style={styles.dividerContainer}>
                    <View style={styles.divider}></View>
                    <View style={styles.dividerText}>
                      <StyledText align="center" size={12} color={subTextColor}>
                        {t('bundleScreen.bundleDetail.bundleIncludes')}
                      </StyledText>
                    </View>
                  </View>
                  <View style={styles.productList}>{productList}</View>
                  <BundleNotice
                    title={bundleDetail.notice.title}
                    content={bundleDetail.notice.content}
                  />
                </View>
              </ScrollView>
              <View style={styles.actionContainer}>
                <AmountCounter
                  amount={current}
                  setAmount={set}
                  maxQuantity={bundleDetail.stock}
                />
                <StyledButton
                  accent
                  disabled={isFetching}
                  label={t('productNavbar.addToCart')}
                  buttonSize="small"
                  onPress={handleAddToCart}
                />
              </View>
            </>
          )
        )
      ) : (
        <View style={{ flex: 1 }}>
          <Loading />
        </View>
      )}
    </FullWidthView>
  );
};

type ItemThumbnailProps = {
  product: BundleProduct;
};

const ItemThumbnail = ({ product }: ItemThumbnailProps) => {
  const { t } = useTranslation();
  return (
    <View style={styles.itemInfo}>
      <NavigateButton
        route="ProductNavigator"
        options={{
          params: { productId: product.goods_id },
          screen: 'ProductDetailScreen',
        }}
      >
        <View style={{ alignItems: 'center' }}>
          <Image
            style={{ width: 80, height: 80 }}
            source={{ uri: product.goods_thumb }}
          />
        </View>
        <StyledText>{product.formated_shop_price}</StyledText>
        <StyledText numberOfLines={2}>{product.goods_name}</StyledText>
        <StyledText color={subTextColor} size={12}>
          {product.goods_attr.replace(/\|/g, '\n')}
        </StyledText>
        <View
          style={{
            justifyContent: 'flex-end',
            paddingTop: 5,
          }}
        >
          <StyledText align="right">
            {t('bundleScreen.bundleList.each', {
              amount: product.goods_number,
            })}
          </StyledText>
        </View>
      </NavigateButton>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 10,
    marginTop: 10,
  },
  bundleItemContainer: {
    marginTop: 10,
  },
  saleIndicator: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderLeftWidth: 1,
    borderLeftColor: backGroundLight,
  },
  packageInfoContainer: {
    paddingVertical: 10,
    flexDirection: 'row',
    marginBottom: 10,
    borderBottomColor: backGroundLight,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderTopColor: backGroundLight,
  },
  originalPrice: {
    color: 'lightgray',
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  dividerContainer: {
    marginBottom: 5,
  },
  divider: {
    width: width * 0.92,
    height: 1,
    backgroundColor: backGroundLight,
    alignSelf: 'center',
    position: 'absolute',
    top: 14,
    zIndex: 9,
  },
  dividerText: {
    zIndex: 10,
    backgroundColor: 'white',
    flexDirection: 'row',
    alignSelf: 'center',
    paddingVertical: 3,
    paddingHorizontal: 8,
    borderColor: backGroundLight,
    borderWidth: 1,
    borderRadius: 20,
  },
  productList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flex: 1,
  },
  itemInfo: {
    borderColor: backGroundLight,
    borderRadius: 15,
    borderWidth: 1,
    marginHorizontal: '1%',
    marginBottom: '2%',
    width: '48%',
    padding: 5,
  },
  actionContainer: {
    alignSelf: 'center',
    width: width - 30,
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 30,
    borderWidth: 1,
    borderColor: backGroundLight,
    position: 'absolute',
    bottom: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,

    elevation: 2,
  },
});

const getBundleDetail = async (packageId: number, time: number) => {
  const result = await getData(
    `package/info?package_id=${packageId}&shipping_time=${time}`
  );
  return result;
};

const addBundleToCart = async (
  packageId: number,
  time: number,
  amount: number
) => {
  const result = await postWithAuthToken('cart/package/add', {
    package_id: packageId,
    shipping_time: time,
    number: amount,
  });
  return result;
};

export default BundleDetailScreen;
