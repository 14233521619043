import React, { useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import FullWidthView from '../../../components/common/FullWidthView';
import { tintColorLight } from '../../../constants/Colors';
import { WebView } from 'react-native-webview';
// WebView has bug when we put it inside ScrollView with Android platform.
import WebviewAutoHeight from '../../../components/common/WebviewAutoHeight';
import { AntDesign } from '@expo/vector-icons';
import { FlatList } from 'react-native-gesture-handler';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { ArticleParamList, GoodsInfo } from '../../../types';
import { getData } from '../../../utils/service';
import ProductItemInList from '../../../components/Product/ProductItemInList';
import StyledText from '../../../components/common/StyledText';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/common/Loading';
import { errorAlertAndGoBack } from '../../../utils/alert';
import { useHeaderHeight } from '@react-navigation/stack';
import StyledButton from '../../../components/common/StyledButton';
import { resetWechatSharePage } from '../../../utils/wechatWebMethods';
import { screenHit } from '../../../utils/analytic';
import Constants from 'expo-constants';
import useIsWeChat from '../../../hooks/useIsWeChat';
import getAppStyle from '../../../utils/getAppStyle';

const { width, height } = Dimensions.get('screen');

let WechatShareBtn: Function;

//Display Wechat
if (Constants.appOwnership === 'expo' || Platform.OS === 'web') {
  WechatShareBtn = () => <View></View>;
} else {
  WechatShareBtn =
    require('../../../components/common/WechatShare/WechatShareBtn').WechatShareBtn;
}

const ArticleScreen = () => {
  const navigation = useNavigation();
  const headerHeight = useHeaderHeight();
  const route = useRoute<RouteProp<ArticleParamList, 'ArticleScreen'>>();
  const { t } = useTranslation();
  const [productsOffsetY, setProductsOffsetY] = useState(0);
  const [showScrollToProductBtn, setShowScrollToProductBtn] = useState(true);

  const scrollViewRef = useRef<any>();

  const [contentURI, setContentURI] = useState('');
  const [displaySkeleton, setDisplaySkeleton] = useState(true);
  const [productList, setProductList] = useState<GoodsInfo[]>([]);

  useEffect(() => {
    let isSubscribe = true;
    if (route.params.id) {
      screenHit(`article/${route.params.id}`);
      (async () => {
        const result = await getArticleDetail(route.params.id);
        if (result && result.goodStatus) {
          if (isSubscribe) {
            setContentURI(result.data.link);
            setProductList(result.data.goods);
            navigation.setOptions({
              title:
                result.data.title.length >= 14
                  ? result.data.title.substring(0, 14) + '...'
                  : result.data.title,
            });
            const shareLinkTitle = `${result.data.title}`;
            const webUrl = `${
              getAppStyle(process.env.EXPO_APP_TYPE)?.appWebsite
            }/user/articles/article?id=${route.params.id}`;
            navigation.setOptions({
              headerRight: () => (
                <WechatShareBtn
                  title={shareLinkTitle}
                  url={webUrl}
                  imageUrl={result.data.image}
                  description={result.data.description}
                />
              ),
            });

            if (Platform.OS === 'web')
              resetWechatSharePage(
                result.data.title,
                result.data.description,
                result.data.image
              );
          }
        } else {
          errorAlertAndGoBack(
            result && typeof result.data === 'string'
              ? result.data
              : 'Error about getting article detail',
            navigation
          );
        }
      })();

      setTimeout(() => {
        setDisplaySkeleton(false);
      }, 1000);
    } else {
      errorAlertAndGoBack('Missing Article id', navigation);
    }

    return () => {
      isSubscribe = false;
    };
  }, []);

  const scrollToProducts = () => {
    scrollViewRef.current !== null &&
      scrollViewRef.current.scrollTo({
        x: 0,
        y: productsOffsetY,
        animated: true,
      });
  };

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (event.nativeEvent.contentOffset.y < productsOffsetY - height * 0.7)
      setShowScrollToProductBtn(true);
    else setShowScrollToProductBtn(false);
  };

  return (
    <FullWidthView edges={['bottom', 'left', 'right']}>
      {productList.length > 0 && showScrollToProductBtn && !displaySkeleton && (
        <ScrollToProductsBtn onPress={scrollToProducts} />
      )}
      {displaySkeleton ? (
        <Loading />
      ) : (
        <ScrollView
          ref={scrollViewRef}
          onScroll={handleScroll}
          scrollEventThrottle={500}
          bounces={false}
        >
          <View
            onLayout={(event) => {
              const { height } = event.nativeEvent.layout;
              setProductsOffsetY(height);
            }}
          >
            {Platform.OS === 'android' ? (
              <WebviewAutoHeight source={{ uri: contentURI }} />
            ) : (
              <FullWidthView>
                <WebView source={{ uri: contentURI }} bounces={false} />
              </FullWidthView>
            )}
          </View>
          {productList.length > 0 && (
            <View style={{ flex: 1, alignItems: 'center', marginTop: 5 }}>
              <View style={styles.header}>
                <StyledText bold align="center" size={18}>
                  {t('articleSection.relatedProducts')}
                </StyledText>
              </View>
              <RelatedProducts list={productList} />
            </View>
          )}
        </ScrollView>
      )}
    </FullWidthView>
  );
};

const ScrollToProductsBtn = ({ onPress }: { onPress: any }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.floatingBtn}>
      <StyledButton
        icon={<AntDesign name="downcircleo" size={12} color="white" />}
        buttonSize="small"
        label={t('articleSection.viewRelatedProducts')}
        onPress={onPress}
      />
    </View>
  );
};

type RelatedProductsProps = {
  list: GoodsInfo[];
};

const RelatedProducts = ({ list }: RelatedProductsProps) => {
  //Render Item in List
  const renderItem = ({ item }: any) => <ProductItemInList data={item} />;

  return (
    <FlatList
      data={list}
      renderItem={renderItem}
      keyExtractor={(item) => item.goods_id.toString()}
      style={{ width: width }}
    />
  );
};

const styles = StyleSheet.create({
  floatingBtn: {
    position: 'absolute',
    bottom: Platform.OS === 'web' ? 80 : 30,
    right: 10,
    zIndex: 10,
  },
  header: {
    alignSelf: 'center',
    marginBottom: 10,
    borderBottomColor: tintColorLight,
    borderBottomWidth: 2,
  },
});

const getArticleDetail = async (id: number) => {
  const result = await getData(`article/detail?id=${id}`);
  return result;
};

export default ArticleScreen;
