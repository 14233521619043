import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import SkeletonContent from 'react-native-skeleton-content';
import { accentColor } from '../../constants/Colors';
import alert from '../../utils/alert';
import { getData } from '../../utils/service';
import ResponsiveImg from '../common/ResponsiveImg';
import StyledText from '../common/StyledText';

const { width } = Dimensions.get('window');

const RecommendList = () => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  const [brandListData, setBrandListData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isSubscribe = true;
    setLoading(true);

    getRecommendBrandListData()
      .then((result) => {
        if (result && result.goodStatus) {
          if (isSubscribe) {
            setBrandListData(result.data.brand);
          }
        } else {
          alert(
            typeof result?.data === 'string'
              ? result?.data
              : '获取品牌推荐列表失败',
            '',
            [
              {
                text: t('confirm'),
                onPress: () => {
                  // navigation.goBack();
                },
              },
            ],
            { cancelable: false }
          );
        }
        setLoading(false);
      })
      .catch((err) => console.log('get recommend brand list error', err));

    return () => {
      isSubscribe = false;
    };
  }, []);

  const handleBrandPress = (brandId: number) => {
    navigation.navigate('Brand', {
      screen: 'BrandDetail',
      params: { brandId },
    });
  };

  const _renderItem = ({ item, index }: any) => {
    return (
      <TouchableOpacity onPress={() => handleBrandPress(item.brand_id)}>
        <View
          style={[styles.brandContainer, index === 0 ? { marginLeft: 10 } : {}]}
        >
          <ResponsiveImg width={width * 0.15} uri={item.brand_logo} />
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <StyledText align="left" bold size={16}>
          {t('brand.recommendBrands')}
        </StyledText>
      </View>
      {loading ? (
        <SkeletonContent
          containerStyle={{ flexDirection: 'row', width: width }}
          layout={[
            {
              width: width * 0.2,
              height: width * 0.2,
              marginRight: 5,
              borderRadius: 10,
              marginLeft: 10,
            },
            {
              width: width * 0.2,
              height: width * 0.2,
              marginRight: 5,
              borderRadius: 10,
            },
            {
              width: width * 0.2,
              height: width * 0.2,
              marginRight: 5,
              borderRadius: 10,
            },
            {
              width: width * 0.2,
              height: width * 0.2,
              marginRight: 5,
              borderRadius: 10,
            },
            {
              width: width * 0.2,
              height: width * 0.2,
              marginRight: 5,
              borderRadius: 10,
            },
          ]}
          isLoading={true}
        />
      ) : (
        <FlatList
          data={brandListData}
          renderItem={_renderItem}
          keyExtractor={(item: any) => item.brand_id.toString()}
          horizontal
          showsHorizontalScrollIndicator={false}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
  titleContainer: {
    margin: 10,
    marginBottom: 5,
    alignSelf: 'flex-start',
    borderLeftWidth: 2,
    borderLeftColor: accentColor,
    paddingHorizontal: 5,
  },
  brandContainer: {
    borderColor: 'lightgray',
    width: width * 0.2,
    height: width * 0.2,
    marginRight: 5,
    borderRadius: 10,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const getRecommendBrandListData = async () => {
  const result = await getData(`brand/list?is_recommend=1`);
  return result;
};

export default RecommendList;
