import React from 'react'
import ThemeHomeScreenPage from './ThemeHomeScreenPage'
import ThemeHomeScreenMenu from './ThemeHomeScreenMenu'
import { TopicPage } from '../../types'
import { useNavigation } from '@react-navigation/native'

type ThemeHomeScreenPaginatorProps = {
    data: Array<TopicPage>;
    horizontalIndex: number;
    setHorizontalIndex: Function;
    width: number;
}

const ThemeHomeScreenPaginator = ({data, horizontalIndex, setHorizontalIndex, width}:ThemeHomeScreenPaginatorProps) => {

    const navigation = useNavigation();

    const tabTitles = data.map((item: TopicPage)=> ({
        title: item.title,
        color: item.title_color
    }))

    const updateHorizontalPage = (index: number) => {
        setHorizontalIndex(index)
        navigation.setParams({pageNumber: index + 1})
    }

    return(data.length < 1 ? (
            <></>
        ) : (
            <>
            <ThemeHomeScreenMenu data={tabTitles} horizontalIndex={horizontalIndex} updateHorizontalPage={updateHorizontalPage}/>
            <ThemeHomeScreenPage pageId={data[horizontalIndex].id}/>
            </>
        )
    )
}

export default ThemeHomeScreenPaginator