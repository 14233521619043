import React from 'react';
import { AntDesign } from '@expo/vector-icons';
import { GestureResponderEvent, TouchableOpacity } from 'react-native';

type ClearButtonProps = {
  size?: number;
  onPress: (event: GestureResponderEvent) => void;
};

const ClearButton = ({ size = 20, onPress }: ClearButtonProps) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <AntDesign name='closecircle' size={size} color='lightgray' />
    </TouchableOpacity>
  );
};

export default ClearButton;
