import React, { ReactNode } from 'react';
import { Text, StyleSheet, StyleProp, TextStyle, Platform } from 'react-native';
import { tintColorLight, normalTextColor } from '../../constants/Colors';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';

type StyledTextProps = {
  children: ReactNode;
  align?: 'left' | 'center' | 'right';
  style?: StyleProp<TextStyle>;
  bold?: boolean;
  variant?: boolean;
  size?: number;
  color?: string;
  numberOfLines?: number;
  onPress?: () => void;
  allowFontScaling?: boolean;
};

/**
 * Text Wrapper component, use it instead of React Native Text component
 */
const StyledText = ({
  children,
  style,
  align = 'left',
  bold = false,
  variant = false,
  size = 14,
  color = normalTextColor,
  allowFontScaling = false,
  ...props
}: StyledTextProps) => {
  const fontSizeIncrement = useSelector<RootState, number>(
    (state) => state.user.fontSizeIncrement
  );
  let alignmentStyle, boldText, accentText;

  //Text Alignment
  switch (align) {
    default:
    case 'left':
      alignmentStyle = styles.alignmentLeft;
      break;

    case 'center':
      alignmentStyle = styles.alignmentCenter;
      break;

    case 'right':
      alignmentStyle = styles.alignmentRight;
  }

  //Bold
  boldText = bold ? styles.boldText : styles.normalText;

  //AccentColor
  accentText = variant ? { color: tintColorLight } : {};

  return (
    <Text
      style={[
        styles.text,
        alignmentStyle,
        boldText,
        { color: color },
        accentText,
        { fontSize: size + fontSizeIncrement },
        style,
        Platform.OS === 'android' ? { includeFontPadding: false } : {},
      ]}
      allowFontScaling={allowFontScaling}
      {...props}
    >
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: 'noto-sans',
  },
  alignmentLeft: {
    textAlign: 'left',
  },
  alignmentRight: {
    textAlign: 'right',
  },
  alignmentCenter: {
    textAlign: 'center',
  },
  boldText: {
    fontFamily: 'noto-sans-bold',
  },
  normalText: {
    fontWeight: 'normal',
  },
  normalColor: {
    color: normalTextColor,
  },
});

export default StyledText;
