import React from 'react';
import { Dimensions, ImageBackground, StyleSheet, View } from 'react-native';
import { backGroundLight, subTextColor } from '../../constants/Colors';
import ResponsiveImg from '../common/ResponsiveImg';
import StyledText from '../common/StyledText';

type BrandHeaderProps = {
  info: {
    audit_status: number;
    brand_bg: string;
    brand_desc: string;
    brand_first_char: string;
    brand_id: number;
    brand_letter: string;
    brand_logo: string;
    brand_name: string;
    index_img: string;
    is_delete: 0 | 1;
    is_recommend: 0 | 1;
    is_show: 0 | 1;
    site_url: string;
  };
};

const { width } = Dimensions.get('window');

const BrandHeader = ({ info }: BrandHeaderProps) => {
  return (
    <View>
      <ImageBackground
        style={styles.root}
        source={{ uri: info.brand_logo }}
        blurRadius={10}
      >
        <View style={styles.left}>
          <ResponsiveImg uri={info.brand_logo} width={100} />
        </View>
        <View style={styles.right}>
          <StyledText bold size={20} color="white">
            {info.brand_name}
          </StyledText>
          <StyledText color={subTextColor}>{info.brand_desc}</StyledText>
        </View>
      </ImageBackground>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 120,
    backgroundColor: backGroundLight,
  },
  left: {
    width: '30%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
    zIndex: 10,
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  right: {
    width: '70%',
    height: '100%',
    justifyContent: 'center',
    paddingHorizontal: 10,
    zIndex: 10,
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  backgroundImage: {
    width: width,
    height: 100,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
});

export default BrandHeader;
