import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { useHeaderHeight } from '@react-navigation/stack';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TextInput, Platform, Keyboard } from 'react-native';
import { normalTextColor, subTextColor } from '../../../constants/Colors';
import { CreditCardSchema } from '../../../utils/validation';
import ErrorMessage from '../../Auth/ErrorMessage';
import ListOption from '../../common/list/ListOption';
import StyledButton from '../../common/StyledButton';
import StyledText from '../../common/StyledText';
import { AntDesign } from '@expo/vector-icons';

const AddCreditCardForm = ({ handleSubmit }: { handleSubmit: Function }) => {
  const headerHeight = useHeaderHeight();
  const { t } = useTranslation();

  return (
    <Formik
      validationSchema={CreditCardSchema}
      initialValues={{
        cardNumber: '',
        expiry: '',
        cvc: '',
        postalCode: '',
      }}
      onSubmit={(values) => {
        handleSubmit(
          values.cardNumber,
          values.expiry,
          values.cvc,
          values.postalCode
        );
      }}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <View
          style={[
            styles.rootContainer,
            { paddingBottom: Platform.OS === 'web' ? headerHeight : 0 },
          ]}
        >
          <View>
            <ListOption>
              <View style={styles.inputContainer}>
                <StyledText>{t('creditCard.cardNumber')}</StyledText>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: 5,
                  }}
                >
                  <FontAwesome
                    name={'credit-card-alt'}
                    size={20}
                    color={'lightgray'}
                  />
                  <TextInput
                    testID={'cardNumberInput'}
                    style={[
                      {
                        marginLeft: 5,
                        flex: 1,
                        color: normalTextColor,
                      },
                      /** @ts-ignore */
                      Platform.OS === 'web' ? { outline: 'none' } : {},
                    ]}
                    placeholder={'**** **** **** ****'}
                    placeholderTextColor={subTextColor}
                    value={values.cardNumber}
                    keyboardType={
                      Platform.OS === 'web' ? 'default' : 'number-pad'
                    }
                    returnKeyType={'done'}
                    maxLength={19}
                    onChangeText={(text) => {
                      //Add Space at index 3 position
                      if (
                        (values.cardNumber.length === 3 && text.length === 4) ||
                        (values.cardNumber.length === 8 && text.length === 9) ||
                        (values.cardNumber.length === 13 && text.length === 14)
                      ) {
                        text += ' ';
                      }

                      setFieldValue('cardNumber', text);
                    }}
                  />
                </View>
                <ErrorMessage
                  label={(touched.cardNumber && errors.cardNumber) || ''}
                />
              </View>
            </ListOption>

            <ListOption>
              <View style={styles.splitInputContainer}>
                <View
                  style={[
                    styles.splitInputChild,
                    {
                      borderRightColor: 'lightgray',
                      borderRightWidth: 1,
                    },
                  ]}
                >
                  <StyledText>{t('creditCard.expiryDate')}</StyledText>
                  <TextInput
                    testID={'expiryInput'}
                    style={[
                      styles.textInput,
                      /** @ts-ignore */
                      Platform.OS === 'web' ? { outline: 'none' } : {},
                    ]}
                    placeholder={'MM/YY'}
                    placeholderTextColor={subTextColor}
                    value={values.expiry}
                    maxLength={5}
                    keyboardType={
                      Platform.OS === 'web' ? 'default' : 'number-pad'
                    }
                    returnKeyType={'done'}
                    onChangeText={(text) => {
                      //Add Space at index 3 position
                      if (values.expiry.length === 1 && text.length === 2) {
                        text += '/';
                      }

                      setFieldValue('expiry', text);
                    }}
                  />
                  <ErrorMessage
                    label={(touched.expiry && errors.expiry) || ''}
                  />
                </View>
                <View style={[styles.splitInputChild, { paddingLeft: 25 }]}>
                  <StyledText>{t('creditCard.cvc')}</StyledText>
                  <TextInput
                    testID={'cvcInput'}
                    style={[
                      styles.textInput,
                      /** @ts-ignore */
                      Platform.OS === 'web' ? { outline: 'none' } : {},
                    ]}
                    placeholder={'***'}
                    placeholderTextColor={subTextColor}
                    value={values.cvc}
                    keyboardType={'number-pad'}
                    returnKeyType={'done'}
                    maxLength={4}
                    onChangeText={handleChange('cvc')}
                  />
                  <ErrorMessage label={(touched.cvc && errors.cvc) || ''} />
                </View>
              </View>
            </ListOption>

            <ListOption>
              <View style={styles.inputContainer}>
                <StyledText>{t('creditCard.postalCode')}</StyledText>
                <TextInput
                  testID={'postalCodeInput'}
                  style={[
                    styles.textInput,
                    /** @ts-ignore */
                    Platform.OS === 'web' ? { outline: 'none' } : {},
                  ]}
                  placeholder={t('creditCard.postalCodePlaceholder')}
                  placeholderTextColor={subTextColor}
                  value={values.postalCode}
                  onChangeText={handleChange('postalCode')}
                  maxLength={9}
                  autoCapitalize={'characters'}
                  returnKeyType={'done'}
                />
                <ErrorMessage
                  label={(touched.postalCode && errors.postalCode) || ''}
                />
              </View>
            </ListOption>
            <View style={styles.postalCodeTipRow}>
              <AntDesign
                name="questioncircleo"
                size={13}
                color={subTextColor}
                style={{ marginRight: 5 }}
              />
              <StyledText size={12} color={subTextColor}>
                {t('creditCard.postalCodeTip')}
              </StyledText>
            </View>
          </View>

          <View style={styles.buttonContainer}>
            <StyledButton
              // disabled={isSubmitting}
              label={t('creditCard.addCard')}
              onPress={handleSubmit}
            />
          </View>
        </View>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  inputContainer: {
    flexDirection: 'column',
  },
  splitInputContainer: {
    flexDirection: 'row',
  },
  splitInputChild: {
    flexDirection: 'column',
    flex: 1,
  },
  textInput: {
    marginTop: 5,
    color: normalTextColor,
  },
  postalCodeInput: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  buttonContainer: {
    marginBottom: 30,
  },
  postalCodeTipRow: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
});

export default AddCreditCardForm;
