import * as Notifications from 'expo-notifications';
import { NotificationResponse } from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';
import { useEffect } from 'react';
import { navigateBasedOnURL } from '../utils/linkingRoute';
// import { useSelector } from 'react-redux';
// import { RootState } from '../types';

//Handles notifications that come in while the app is foregrounded
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

/**
 * Hook for handle push notification event listener (ios/android/expo only)
 */
const useNotification = () => {
  const navigation = useNavigation();

  /**
   * can not used in closed app (only background/foreground)
   */
  // const isLoggedIn = useSelector<RootState, boolean>(
  //   (state) => state.user.isLoggedIn
  // );
  // useEffect(() => {
  //   //This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed). This listener is especially useful for routing users to a particular screen after they tap on a particular notification.
  //   const responseListener =
  //     Notifications.addNotificationResponseReceivedListener(
  //       _handleNotificationResponse
  //     );
  //   return () => {
  //     Notifications.removeNotificationSubscription(responseListener);
  //   };
  // }, [isLoggedIn]);

  /**
   * can be used for killed app
   */
  const lastNotificationResponse = Notifications.useLastNotificationResponse();
  useEffect(() => {
    if (lastNotificationResponse) {
      _handleNotificationResponse(lastNotificationResponse);
    }
  }, [lastNotificationResponse]);

  const _handleNotificationResponse = (response: NotificationResponse) => {
    const notificationContent: any = response.notification.request.content.data;
    if (notificationContent && notificationContent.type) {
      navigateBasedOnURL({
        navigation: navigation,
        url_type: 'inner',
        type: notificationContent.type,
        id: notificationContent.id,
        url: notificationContent.url,
        appId: notificationContent.appid
      });
    }
  };
};

export default useNotification;
