import React from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { useTranslation } from 'react-i18next';
import StyledText from '../common/StyledText';
import { AntDesign } from '@expo/vector-icons';
import { tintColorLight } from '../../constants/Colors';
import { RootState } from '../../types';
import { useSelector } from 'react-redux';

import NavigateButton from '../common/NavigateButton';
import CachedImage from '../common/cache-img/CachedImage';
import NotificationDot from '../common/widgets/NotificationDot';

type ProfileHeaderProps = {
  nickName: string;
  profilePicture: string;
  userInfo: { [key: string]: string | number | boolean };
};

const ProfileHeader = ({
  nickName,
  profilePicture,
  userInfo,
}: ProfileHeaderProps) => {
  const { t } = useTranslation();

  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.user.isLoggedIn
  );

  return (
    <View style={[styles.container, { backgroundColor: tintColorLight }]}>
      <View style={styles.leftContainer}>
        {isLoggedIn ? (
          <NavigateButton
            route="UserInfo"
            options={{ data: userInfo }}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <View style={styles.thumbnailContainer}>
              <CachedImage
                source={{ uri: profilePicture }}
                style={{ width: 50, height: 50 }}
              />
            </View>
            <View>
              <StyledText color="white" bold size={16}>
                {nickName}
              </StyledText>

              <StyledText color="white">
                {t('userInfoScreen.headerTitle') + ' >'}
              </StyledText>
            </View>
          </NavigateButton>
        ) : (
          <>
            <View style={styles.thumbnailContainer}>
              <Image
                source={require('../../assets/images/default-avatar.png')}
                style={{ width: 50, height: 50 }}
              />
            </View>
            <NavigateButton route="Modal" options={{ screen: 'AuthModal' }}>
              <StyledText color="white" size={16}>
                {t('userProfileScreen.labelNotLogin/Register')}
              </StyledText>
            </NavigateButton>
          </>
        )}
      </View>
      <View style={styles.rightContainer}>
        <NavigateButton
          route="Messages"
          options={{
            screen: 'MessageCenter',
          }}
          loginRequired
        >
          <View style={styles.messageBtnContainer}>
            {userInfo.unread_message === true && <NotificationDot />}
            <AntDesign name="message1" size={26} color="white" />
          </View>
        </NavigateButton>
        <NavigateButton
          route="UserSettings"
          options={{
            screen: 'SettingsMainScreen',
          }}
        >
          <AntDesign name="setting" size={26} color="white" />
        </NavigateButton>
      </View>
    </View>
  );
};

export default ProfileHeader;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderBottomEndRadius: 16,
    borderBottomStartRadius: 16,
  },
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 2,
  },
  rightContainer: {
    flex: 1,
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  messageBtnContainer: {
    marginRight: 10,
    paddingHorizontal: 5,
  },
  thumbnailContainer: {
    width: 50,
    height: 50,
    borderRadius: 100,
    backgroundColor: 'lightgray',
    marginRight: 10,
    overflow: 'hidden',
  },
});
