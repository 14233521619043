import React from 'react';
import { View, StyleSheet } from 'react-native';
import StyledText from './StyledText';

type Section = {
  children: React.ReactNode;
  title?: string;
};

const Section = ({ children, title }: Section) => {
  return (<View style={styles.optionItem}>
            {title && <StyledText style={styles.title} size={16} bold>{title}</StyledText>}
            {children}
          </View>)
};

export default Section;

const styles = StyleSheet.create({
  optionItem: {
    paddingHorizontal: 10,
    paddingVertical: 15,
    backgroundColor: 'white',
    marginBottom: 10
  },
  title: {
    paddingBottom: 15
  }
});