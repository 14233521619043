import React from 'react';
import { ProductInformation } from '../../types';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import StyledText from '../common/StyledText';
import { BLUE3, ORANGE1, RED3 } from '../../constants/Colors';
import AddToCartBtn from '../Cart/AddToCartBtn';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import CachedImage from '../common/cache-img/CachedImage';
import ChooseOfferBtn from '../Cart/SpecialOffer/ChooseOfferBtn';
import ProductImageTag from './widgets/ProductImageTag';
import getAppStyle from '../../utils/getAppStyle';

type ProductItemInListProp = {
  data: ProductInformation;
  type?: 'normal' | 'gift';
  action?: (action: string) => void;
  bottomDivider?: boolean;
};

const ProductItemInList = ({
  data,
  type = 'normal',
  action,
  bottomDivider = true,
}: ProductItemInListProp) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const soldOut = data.stock === 0 && data.specification.length === 0;

  const renderPriceText = () => {
    let finalPrice = data.formated_final_goods_price;
    let shopPrice = data.formated_shop_price;
    let estimatePrice = data.formated_estimate_shop_price;
    if (finalPrice !== shopPrice) {
      return (
        <View>
          <StyledText bold size={16} color={ORANGE1}>
            {finalPrice}
            <StyledText size={12}>{' /' + data.goods_unit}</StyledText>
          </StyledText>
          <StyledText bold size={12} style={styles.originalPrice}>
            {shopPrice}
          </StyledText>
          {data.is_weight_goods === 1 && estimatePrice && (
            <StyledText size={12}>
              <StyledText size={12}>
                {t('productInformation.estimatePrice')}:{' '}
              </StyledText>
              {estimatePrice}
            </StyledText>
          )}
        </View>
      );
    } else {
      return (
        <View>
          <StyledText bold size={16} color={ORANGE1}>
            {shopPrice}
            <StyledText size={12}>{' /' + data.goods_unit}</StyledText>
          </StyledText>
          {data.is_weight_goods === 1 && estimatePrice && (
            <StyledText size={12}>
              <StyledText size={12}>
                {t('productInformation.estimatePrice')}:{' '}
              </StyledText>
              {estimatePrice}
            </StyledText>
          )}
        </View>
      );
    }
  };

  const handleOpenProductDetail = () => {
    navigation.navigate('ProductNavigator', {
      screen: 'ProductDetailScreen',
      params: { productId: data.goods_id },
    });
  };

  const renderAction = () => {
    if (
      type === 'gift' &&
      data.insert_stage !== undefined &&
      action !== undefined
    ) {
      return (
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
          <ChooseOfferBtn
            status={data.insert_stage}
            onPress={action}
            gift={data}
          />
        </View>
      );
    }

    return (
      <View style={styles.buttonContainer}>
        <View style={{ flex: 1, marginRight: 3 }}>
          {data.shop ? (
            <>
              <StyledText color="lightgray" size={12}>
                {data.shop ? data.shop.shop_name : ''}
              </StyledText>
            </>
          ) : (
            <></>
          )}
        </View>
        {data.is_presale === 1 ? (
          <TouchableOpacity
            style={styles.preOrderButton}
            onPress={handleOpenProductDetail}
          >
            <StyledText size={12} color="white">
              {t('productInformation.preOrderButton')}
            </StyledText>
          </TouchableOpacity>
        ) : (
          !soldOut && (
            <AddToCartBtn goodsId={data.goods_id} goodsData={data}>
              <View style={styles.cartIcon}>
                <MaterialCommunityIcons
                  name="cart-outline"
                  size={20}
                  color={getAppStyle(process.env.EXPO_APP_TYPE)?.secondaryColor}
                />
              </View>
            </AddToCartBtn>
          )
        )}
      </View>
    );
  };

  const highlighter = (input: string): JSX.Element => {
    if (!input.includes('{{') || !input.includes('}}')) {
      return <StyledText numberOfLines={3}>{input}</StyledText>;
    }
    let inputArray = input.split(/{{|}}/);
    let innerComponents: Array<JSX.Element> = [];
    let colorIndicator = input[0] === '{{' || input[0] === '}}' ? 0 : 1;

    inputArray.forEach((value, index) => {
      if (index % 2 === colorIndicator) {
        innerComponents.push(
          <StyledText color={ORANGE1} key={index}>
            {value}
          </StyledText>
        );
      } else {
        innerComponents.push(<StyledText key={index}>{value}</StyledText>);
      }
    });

    return (
      <StyledText numberOfLines={3}>
        {innerComponents.map((component) => component)}
      </StyledText>
    );
  };

  return (
    <View
      style={[
        styles.rootContainer,
        { borderBottomWidth: bottomDivider ? 1 : 0 },
      ]}
    >
      <View style={styles.thumbImgContainer}>
        <TouchableOpacity onPress={handleOpenProductDetail}>
          <CachedImage
            style={styles.productImg}
            source={{
              uri: data.goods_thumb,
            }}
          />

          <ProductImageTag data={data} />
        </TouchableOpacity>
      </View>
      <View style={styles.infoContainer}>
        <View>
          <TouchableOpacity onPress={handleOpenProductDetail}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              {typeof data.tag === 'string' && data.tag !== '' && (
                <View style={styles.tag}>
                  <StyledText size={10} color={RED3}>
                    {data.tag}
                  </StyledText>
                </View>
              )}
              {typeof data.weight_tag === 'string' &&
                data.weight_tag !== '' && (
                  <View style={[styles.tag, { borderColor: BLUE3 }]}>
                    <StyledText size={10} color={BLUE3}>
                      {data.weight_tag}
                    </StyledText>
                  </View>
                )}
            </View>
            {data.is_search === 1 ? (
              highlighter(data.goods_name)
            ) : (
              //<WebView originWhitelist={['*']} source={{html: data.goods_name}}/>
              <StyledText numberOfLines={3}>{data.goods_name}</StyledText>
            )}

            {data.goods_brief ? (
              <StyledText color="gray" size={12}>
                {data.goods_brief}
              </StyledText>
            ) : (
              <></>
            )}
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              {data.limit_number !== undefined && data.limit_number !== 0 && (
                <StyledText color={RED3} size={12} style={{ marginRight: 6 }}>
                  {t('productDetailScreen.limitedPurchase', {
                    num: data.limit_number,
                  })}
                </StyledText>
              )}
              {!Array.isArray(data.goods_number_rule) && (
                <StyledText color={BLUE3} size={12}>
                  {data.goods_number_rule.desc}
                </StyledText>
              )}
            </View>

            {renderPriceText()}
          </TouchableOpacity>
        </View>
        {renderAction()}
      </View>
    </View>
  );
};

export default ProductItemInList;

const styles = StyleSheet.create({
  rootContainer: {
    flexDirection: 'row',
    borderBottomColor: 'lightgray',
    borderBottomWidth: 1,
    padding: 6,
    overflow: 'hidden',
  },
  thumbImgContainer: {
    width: '37.5%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: 2,
  },
  productImg: {
    width: 100,
    height: 100,
    backgroundColor: 'transparent',
  },
  infoContainer: {
    width: '62.5%',
    padding: 5,
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  originalPrice: {
    color: 'lightgray',
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  shopInfo: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  buttonContainer: {
    width: '100%',
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cartIcon: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: getAppStyle(process.env.EXPO_APP_TYPE)?.secondaryColor,
    padding: 5,
    justifyContent: 'center',
  },
  preOrderButton: {
    backgroundColor: ORANGE1,
    padding: 3,
  },
  watermark: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  tag: {
    borderWidth: 1,
    borderColor: RED3,
    padding: 2,
    borderRadius: 5,
    marginRight: 3,
    marginBottom: 3,
  },
});
