import React from 'react';
import { Modal as NativeModal, Platform } from 'react-native';
/** @ts-ignore */
import WebModal from 'modal-react-native-web';

type GeneralModalProps = NativeModal['props'];

/**
 * Compatible Modal for all platforms, use it instead of react-native built-in Modal component
 */
const GeneralModal = (props: GeneralModalProps) => {
  const { children, ...otherProps } = props;

  if (Platform.OS !== 'web')
    return <NativeModal {...otherProps}>{children}</NativeModal>;
  else
    return (
      <WebModal {...otherProps} ariaHideApp={false}>
        {children}
      </WebModal>
    );
};

export default GeneralModal;
