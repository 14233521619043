import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ForgotPasswordParamList } from '../types'

import VerifyUserForm from '../components/Auth/ResetPassword/VerifyUserForm';
import ResetPasswordForm from '../components/Auth/ResetPassword/ResetPasswordForm';

const ForgotPasswordStack = createStackNavigator<ForgotPasswordParamList>();

const ForgotPasswordNavigator = () =>{
    return(
    <ForgotPasswordStack.Navigator initialRouteName="VerifyUserForm"
                                screenOptions={{
                                headerShown: false,
                                }}>
        <ForgotPasswordStack.Screen name="VerifyUserForm" component={VerifyUserForm} />
        <ForgotPasswordStack.Screen name="ResetPasswordForm" component={ResetPasswordForm} />
    </ForgotPasswordStack.Navigator>
    )
}

export default ForgotPasswordNavigator;