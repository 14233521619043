import React, { useState } from 'react';
import WhiteCard from '../../common/WhiteCard';
import StyledText from '../../common/StyledText';
import {
  tintColorLight,
  warningRed,
  backGroundLight,
} from '../../../constants/Colors';
import { AntDesign } from '@expo/vector-icons';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useTranslation } from 'react-i18next';
import BottomAutoHeightModal from '../../common/BottomAutoHeightModal';
import { useNavigation } from '@react-navigation/native';

type CombineOrderInfoProps = {
  list: Array<{
    order_id: number;
    order_sn: string;
    total_amount: string;
  }>;
  combineOrder: {
    requireCombine: boolean;
    orderId: number;
  };
  setCombineOrder: React.Dispatch<
    React.SetStateAction<{
      requireCombine: boolean;
      orderId: number;
    }>
  >;
  orderData?: any;
};

const CombineOrderInfo = ({
  list,
  setCombineOrder,
  combineOrder,
  orderData,
}: CombineOrderInfoProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const [visible, setVisible] = useState(false);

  const renderOptionList = list.map((option) => {
    return (
      <TouchableOpacity
        key={option.order_id}
        onPress={
          orderData
            ? () => {
                hideModal();
                navigation.navigate('AddOrderGoodsScreen', {
                  ...orderData,
                  orderId: option.order_id,
                });
              }
            : () => updateCombineOrder(true, option.order_id)
        }
        disabled={combineOrder.orderId === option.order_id}
      >
        <View style={styles.optionItem}>
          <StyledText size={16}>{`${t('order.order')} ${option.order_sn} - ${
            option.total_amount
          }`}</StyledText>
          {combineOrder.orderId === option.order_id && (
            <AntDesign name="checkcircle" size={16} color={tintColorLight} />
          )}
        </View>
      </TouchableOpacity>
    );
  });

  const updateCombineOrder = (requireCombine: boolean, id: number) => {
    setCombineOrder({ requireCombine: requireCombine, orderId: id });
    hideModal();
  };

  const hideModal = () => {
    setVisible(false);
  };

  return (
    <WhiteCard
      title={t('order.combineOrder')}
      titleColor={warningRed}
      action={{
        label: t('order.choseOrderToCombine'),
        handleActionPress: () => setVisible(true),
      }}
    >
      <StyledText>{t('order.combineOrderDescription')}</StyledText>
      <BottomAutoHeightModal visible={visible} hideModal={hideModal}>
        <StyledText bold size={16}>
          {t('order.selectToCombine')}
        </StyledText>
        <View style={styles.contentContainer}>
          {/* <TouchableOpacity onPress={() => updateCombineOrder(false, -1)}>
            <View style={styles.optionItem}>
              <StyledText size={16}>{t('order.doNotCombine')}</StyledText>
              {combineOrder.orderId === -1 && (
                <AntDesign
                  name="checkcircle"
                  size={16}
                  color={tintColorLight}
                />
              )}
            </View>
          </TouchableOpacity> */}
          {renderOptionList}
        </View>
      </BottomAutoHeightModal>
    </WhiteCard>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    paddingVertical: 10,
  },
  optionItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 10,
    borderTopColor: backGroundLight,
    borderTopWidth: 1,
  },
});

export default CombineOrderInfo;
