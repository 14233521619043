import { PaymentConfig, RedPacket } from '../../../types';
import { payOrder } from '../../../utils/service';
import * as Navigation from '../../../utils/navigator';

let PaymentRequest: any;
try {
  PaymentRequest = require('react-native-payments').PaymentRequest;
} catch {}

/**
 * Apple Pay
 * @param orderId        Order ID
 * @param payId          Payment method ID
 * @param total          Total amount
 * @param config         Payment configuration
 * @param shareRedPacket Share Red Packet Object
 */
export const applePay = (
  orderId: number,
  payId: number,
  total: number,
  config: PaymentConfig,
  shareRedPacket?: RedPacket | []
) => {
  let methodData = [
    {
      supportedMethods: ['apple-pay'],
      data: {
        merchantIdentifier: config.merchantIdentifier,
        supportedNetworks: config.supportedNetworks,
        countryCode: config.countryCode,
        currencyCode: config.currencyCode,
      },
    },
  ];
  let details: any = {
    id: 'basic',
    total: {
      label: config.companyLabel,
      amount: {
        currency: config.currencyCode,
        value: total,
      },
    },
  };

  if (config.displayItems && config.displayItems.length > 0) {
    details.displayItems = config.displayItems;
  }
  let options = config.options;
  const paymentRequest = new PaymentRequest(methodData, details, options);

  paymentRequest.canMakePayments().then((canMakePayment: any) => {
    if (canMakePayment) {
      paymentRequest
        .show()
        .then((paymentResponse: any) => {
          payOrder({
            orderId: orderId,
            payId: payId,
            applePayData: paymentResponse.details,
          })
            .then((result) => {
              if (result && result.goodStatus) {
                paymentResponse.complete('success');
                Navigation.navigate('OrderResult', {
                  orderId: orderId,
                  status: 'PAY_SUCCESS',
                  redPacket: shareRedPacket,
                });
              } else {
                paymentResponse.complete('fail');
                Navigation.navigate('OrderResult', {
                  orderId: orderId,
                  status: 'APP_PAY_FAIL',
                });
              }
            })
            .catch((err) => {
              console.log(err);
              paymentResponse.complete('fail');
              Navigation.navigate('OrderResult', {
                orderId: orderId,
                status: 'APP_PAY_FAIL',
              });
            });
        })
        .catch((err: any) => {
          console.log(err);
          Navigation.navigate('OrderResult', {
            orderId: orderId,
            status: 'APP_PAY_FAIL',
          });
        });
    } else {
      console.log('Cant Make Payment');
      Navigation.navigate('OrderResult', {
        orderId: orderId,
        status: 'APP_PAY_FAIL',
      });
    }
  });
};
