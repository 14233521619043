import React from 'react';
import { View, StyleSheet, Dimensions, Platform } from 'react-native';
import StyledButton from '../../common/StyledButton';
import { useTranslation } from 'react-i18next';
import {
  backGroundLight,
  accentColor,
  linkColor,
} from '../../../constants/Colors';
import { payOrder, postWithAuthToken } from '../../../utils/service';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import { useNavigation } from '@react-navigation/native';
import StyledText from '../../common/StyledText';
import {
  CreditCardInList,
  ReorderItem,
  PaymentData,
  RedPacket,
  CreditForm,
} from '../../../types';
import alert from '../../../utils/alert';
import valid from 'card-validator';
import { applePay } from '../payment/payment';

const { width } = Dimensions.get('window');

type BottomActionsProps = {
  orderId: number;
  canCancel: boolean;
  canDelete: boolean;
  canPay: boolean;
  canReceive: boolean;
  canReorder: boolean;
  totalPrice: string;
  payId: number;
  payCode: string;
  creditCard: CreditCardInList | Array<any>;
  CVC: string | undefined;
  orderSn?: string;
  trackType?: 0 | 1 | 2;
  paymentData?: PaymentData;
  shareRedPacket?: RedPacket;
  setShowReorderModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setReorderErrors?: React.Dispatch<React.SetStateAction<ReorderItem[]>>;
  totalAmount: number;
  creditForm: Array<any> | CreditForm;
};

const BottomActions = ({
  orderId,
  canCancel,
  canDelete,
  canPay,
  canReceive,
  canReorder,
  totalPrice = '$0',
  payId = -1,
  payCode = '',
  creditCard,
  CVC,
  orderSn,
  trackType = 0,
  paymentData,
  shareRedPacket,
  creditForm,
  setShowReorderModal,
  setReorderErrors,
  totalAmount = 0,
}: BottomActionsProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const handleButtonClick = (
    type: 'pay' | 'delete' | 'cancel' | 'receive' | 'track' | 'reorder'
  ) => {
    switch (type) {
      default:
      //Cancel order
      case 'cancel':
        alert(
          t('order.prompt.cancelAlert'),
          '',
          [
            {
              text: t('cancel'),
              style: 'cancel',
            },
            {
              text: t('confirm'),
              onPress: () => {
                let loading = Toast.showLoading('');
                postWithAuthToken('order/cancel', { order_id: orderId })
                  .then((result) => {
                    Toast.hide(loading);
                    if (result && result.goodStatus) {
                      Toast.showSuccess(t('order.prompt.cancelSuccess'));
                      Platform.OS === 'web'
                        ? navigation.canGoBack()
                          ? navigation.goBack()
                          : window.history.back()
                        : navigation.goBack();
                    }
                    console.log(result);
                  })
                  .catch((err) => {
                    if (loading) Toast.hide(loading);
                    console.log(err);
                  });
              },
            },
          ],
          { cancelable: false }
        );
        break;
      case 'delete':
        alert(
          t('order.prompt.deleteAlert'),
          '',
          [
            {
              text: t('cancel'),
              style: 'cancel',
            },
            {
              text: t('confirm'),
              onPress: () => {
                let loading = Toast.showLoading('');
                postWithAuthToken('order/delete', { order_id: orderId })
                  .then((result) => {
                    Toast.hide(loading);
                    if (result && result.goodStatus) {
                      Toast.showSuccess(t('order.prompt.deleteSuccess'));
                      navigation.goBack();
                      Platform.OS === 'web'
                        ? navigation.canGoBack()
                          ? navigation.goBack()
                          : /** @ts-ignore */
                            window.history.back()
                        : navigation.goBack();
                    }
                    console.log(result);
                  })
                  .catch((err) => {
                    if (loading) Toast.hide(loading);
                    console.log(err);
                  });
              },
            },
          ],
          { cancelable: false }
        );
        break;

      case 'receive':
        alert(
          t('order.prompt.receiveAlert'),
          '',
          [
            {
              text: t('cancel'),
              style: 'cancel',
            },
            {
              text: t('confirm'),
              onPress: () => {
                let loading = Toast.showLoading('');
                postWithAuthToken('order/confirm_receive', {
                  order_id: orderId,
                })
                  .then((result) => {
                    Toast.hide(loading);
                    if (result && result.goodStatus) {
                      Toast.showSuccess(t('order.prompt.receiveSuccess'));
                      navigation.goBack();
                      Platform.OS === 'web'
                        ? navigation.canGoBack()
                          ? navigation.goBack()
                          : window.history.back()
                        : navigation.goBack();
                    }
                    console.log(result);
                  })
                  .catch((err) => {
                    if (loading) Toast.hide(loading);
                    console.log(err);
                  });
              },
            },
          ],
          { cancelable: false }
        );
        break;

      case 'pay':
        {
          const loading = Toast.showLoading('');
          let cardId;
          let cvd;
          if (!Array.isArray(creditCard) && payCode === 'moneriscredit') {
            cardId = creditCard.id;
            cvd = CVC;
            if (cvd === undefined || cvd === '') {
              alert(t('validation.CVCHint'));
              Toast.hide(loading);
              return;
            } else {
              if (!valid.cvv(cvd, 4).isPotentiallyValid || cvd.length < 3) {
                alert(t('validation.invalidCVC'));
                Toast.hide(loading);
                return;
              }
            }

            if (!Array.isArray(creditForm)) {
              Toast.hide(loading);
              navigation.navigate('UploadFileScreen', {
                creditFormInfo: creditForm,
                data: {
                  orderId: orderId,
                  payId: payId,
                  cardId: cardId,
                  cvd: Number(cvd),
                },
                from: 'OrderDetailScreen',
              });
              return;
            }
          }

          if (payCode === 'applepay') {
            if (Platform.OS !== 'ios') {
              Toast.hide(loading);
              Toast.show(t('order.applePayWarning'), {
                position: 0,
              });
              return;
            }

            if (paymentData) {
              applePay(
                orderId,
                payId,
                totalAmount,
                paymentData.config,
                shareRedPacket
              );
            }
            Toast.hide(loading);
            return;
          }

          payOrder({
            orderId: orderId,
            payId: payId,
            cardId: cardId,
            cvd: cvd,
          })
            .then((result) => {
              Toast.hide(loading);
              if (result && result.goodStatus) {
                navigation.navigate('OrderSubmit', { data: result.data });
              }
              //Jump to payment
              else if (result && result.return_code === 303) {
                navigation.navigate('OrderSubmit', { data: result.data });
              } else {
                console.log('Wrong', result);
                result && Toast.show(result.data, { position: 0 });
              }
            })
            .catch((err) => {
              console.log(err);
              Toast.hide(loading);
            });
        }
        break;

      case 'track':
        navigation.navigate('OrderTracking', { orderSn: orderSn });
        break;

      case 'reorder':
        alert(
          t('order.prompt.reorderAlert'),
          '',
          [
            { text: t('cancel'), style: 'cancel' },
            {
              text: t('confirm'),
              onPress: () => {
                let loading = Toast.showLoading('');
                postWithAuthToken('order/reorder', { order_id: orderId })
                  .then((result) => {
                    Toast.hide(loading);
                    if (result && result.goodStatus && result.data) {
                      if (result.data.error && result.data.error.length) {
                        if (setReorderErrors)
                          setReorderErrors(result.data.error);
                        if (setShowReorderModal) setShowReorderModal(true);
                      } else {
                        Toast.showSuccess(t('order.prompt.reorderSuccess'));
                        navigation.reset({
                          index: 0,
                          routes: [
                            { name: 'Root', params: { screen: 'Home' } },
                          ],
                        });
                        setTimeout(
                          () => navigation.navigate('Root', { screen: 'Cart' }),
                          100
                        );
                      }
                    } else if (result && result.data) {
                      Toast.show(result.data);
                    } else {
                      console.log(result);
                    }
                  })
                  .catch((err) => {
                    if (loading) Toast.hide(loading);
                    console.log(err);
                  });
              },
            },
          ],
          { cancelable: false }
        );
        break;
    }
  };

  const renderFooter = () => {
    let buttons: JSX.Element[] = [];

    canCancel &&
      buttons.push(
        <View style={styles.buttonContainer} key={1}>
          <StyledButton
            buttonSize="small"
            outline
            label={t('order.cancel')}
            onPress={() => handleButtonClick('cancel')}
          />
        </View>
      );

    canDelete &&
      buttons.push(
        <View style={styles.buttonContainer} key={2}>
          <StyledButton
            buttonSize="small"
            outline
            label={t('order.delete')}
            onPress={() => handleButtonClick('delete')}
          />
        </View>
      );

    canReceive &&
      buttons.push(
        <View style={styles.buttonContainer} key={3}>
          <StyledButton
            buttonSize="small"
            accent
            label={t('order.receive')}
            onPress={() => handleButtonClick('receive')}
          />
        </View>
      );

    canPay &&
      buttons.push(
        <View style={styles.buttonContainer} key={4}>
          <StyledButton
            buttonSize="small"
            accent
            label={t('order.pay')}
            onPress={() => handleButtonClick('pay')}
          />
        </View>
      );

    canReorder &&
      buttons.push(
        <View style={styles.buttonContainer} key={6}>
          <StyledButton
            buttonSize="small"
            label={t('order.reorder')}
            onPress={() => handleButtonClick('reorder')}
          />
        </View>
      );

    (trackType === 1 || trackType === 2) &&
      buttons.push(
        <View style={styles.buttonContainer} key={5}>
          <StyledButton
            buttonSize="small"
            color={linkColor}
            label={
              trackType === 1
                ? t('order.orderTracking.trackOrder')
                : t('order.orderTracking.checkOrderLog')
            }
            onPress={() => handleButtonClick('track')}
          />
        </View>
      );

    return buttons.length > 0 && <>{buttons}</>;
  };

  return (
    <View style={styles.barPlacement}>
      <View style={[styles.barShape, {}]}>
        <View style={styles.buttonsContainer}>{renderFooter()}</View>
        {!canReceive && (
          <StyledText>
            {t('order.total')}
            <StyledText bold color={accentColor} size={16}>
              {totalPrice}
            </StyledText>
          </StyledText>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  barPlacement: {
    alignSelf: 'center',
    justifyContent: 'flex-end',
  },
  barShape: {
    backgroundColor: 'white',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: width,
    padding: 10,
    borderTopColor: backGroundLight,
    borderTopWidth: 1,
  },
  buttonsContainer: {
    flexDirection: 'row',
  },
  buttonContainer: {
    marginLeft: 5,
  },
});

export default BottomActions;
