import React, { useEffect } from 'react';
import {
  View,
  StyleSheet,
  Dimensions,
  TouchableWithoutFeedback,
} from 'react-native';
import moment from 'moment';
import { AntDesign } from '@expo/vector-icons';

import {
  getAsyncStorageData,
  setAsyncStorageData,
} from '../../../utils/asyncStorage';
import ResponsiveImg from '../../common/ResponsiveImg';
import HomeLinkNavigator from '../HomeLinkNavigator';
import GeneralModal from '../../common/GeneralModal';

type AdPopupProps = {
  modalVisible: boolean;
  setModalVisible: Function;
  data: any;
};
// clearAllAsyncStorage();
const { width, height } = Dimensions.get('window');

const AdPopup = ({ modalVisible, setModalVisible, data }: AdPopupProps) => {
  useEffect(() => {
    let isSubscribed = true;
    getPopupInfo();
    async function getPopupInfo() {
      const popupExpireTime = await getAsyncStorageData(
        '@storage_PopupExpireTime'
      );

      if (popupExpireTime) {
        const timeGap = moment().valueOf() - parseInt(popupExpireTime);
        //Show popup again if expire
        if (timeGap > 0) {
          if (isSubscribed) {
            setModalVisible(true);
          }
        }
      }
      //No expire time found, show popup
      else {
        if (isSubscribed) {
          setModalVisible(true);
        }
      }
    }
  }, []);

  const setPopupExpireTime = async () => {
    //Set Popup expire time after close the popup
    const expireTime = moment().add(1, 'd');
    await setAsyncStorageData(
      '@storage_PopupExpireTime',
      expireTime.valueOf().toString()
    );
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setPopupExpireTime();
  };

  return (
    <View style={{ zIndex: 999 }}>
      <GeneralModal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={handleModalClose}
        statusBarTranslucent={true}
      >
        <View style={styles.modalView}>
          <View style={styles.imgContainer}>
            <HomeLinkNavigator data={data} extraFn={handleModalClose}>
              <ResponsiveImg uri={data.img} />
            </HomeLinkNavigator>
          </View>
          <View style={styles.closeBtn}>
            <TouchableWithoutFeedback onPress={handleModalClose}>
              <AntDesign name="closecircle" size={36} color="white" />
            </TouchableWithoutFeedback>
          </View>
        </View>
      </GeneralModal>
    </View>
  );
};

export default AdPopup;

const styles = StyleSheet.create({
  modalView: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: width,
    height: height,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  closeBtn: {
    position: 'relative',
    marginTop: 5,
    zIndex: 100000,
  },
  imgContainer: {
    borderRadius: 15,
    backgroundColor: 'transparent',
    overflow: 'hidden',
  },
});
