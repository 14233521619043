import { combineReducers } from 'redux';

import goodsReducer from './goodsReducer';
import userReducer from './userReducer';
import urlReducer from './urlReducer';

const rootReducer = combineReducers({
  goods: goodsReducer,
  user: userReducer,
  url: urlReducer,
});

export default rootReducer;
