import React from 'react';
import { AddressInformation } from '../../../types';
import { FlatList } from 'react-native';
import AddressItem from './AddressItem';

const AddressList = ({
  data,
  type,
  handleRegionChange,
}: {
  data: Array<AddressInformation>;
  type: 'edit' | 'view' | 'region';
  handleRegionChange?: Function;
}) => {
  const renderItem = ({ item }: { item: AddressInformation }) => (
    <AddressItem
      item={item}
      type={type}
      handleRegionChange={handleRegionChange}
    />
  );

  return (
    <FlatList
      style={{ flex: 1 }}
      data={data}
      renderItem={renderItem}
      keyExtractor={(item) => item.address_id.toString()}
    />
  );
};

export default AddressList;
