import React, { useState, useEffect } from 'react';
import FullWidthView from '../../../components/common/FullWidthView';
import {
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
  View,
} from 'react-native';
import Loading from '../../../components/common/Loading';
import { backGroundLight } from '../../../constants/Colors';
import {
  useRoute,
  RouteProp,
  useNavigation,
  useIsFocused,
  CommonActions,
} from '@react-navigation/native';
import {
  getDataWithAuthToken,
  postWithAuthToken,
} from '../../../utils/service';
import {
  CreditCardInList,
  CreditForm,
  OrderDetailType,
  OrderParamList,
  PaymentData,
  PaymentMethod,
  RedPacket,
  ReorderItem,
} from '../../../types';
import OrderDetail from '../../../components/Order/OrderDetail';
import BottomActions from '../../../components/Order/sections/BottomActions';
import BackButton from '../../../components/common/iconButtons/BackButton';
import CustomerServiceBtn from '../../../components/Order/sections/CustomerServiceBtn';
import RedPacketBtn from '../../../components/Order/sections/RedPacketBtn';
import { MaterialIcons } from '@expo/vector-icons';
import StyledText from '../../../components/common/StyledText';
import { useHeaderHeight } from '@react-navigation/stack';
import alert from '../../../utils/alert';
import { useTranslation } from 'react-i18next';
import ReorderModal from '../../../components/Order/ReorderModal';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import getAppStyle from '../../../utils/getAppStyle';
import NavigateButton from '../../../components/common/NavigateButton';

const OrderDetailScreen = () => {
  const route = useRoute<RouteProp<OrderParamList, 'OrderDetail'>>();
  const navigation = useNavigation<any>();

  const { orderId, type } = route.params;

  /** @ts-ignore */
  const [orderDetail, setOrderDetail] = useState<OrderDetailType>({});
  const [selectedPayment, setSelectedPayment] = useState({
    name: '',
    id: -1,
    description: '',
    code: '',
  });
  const [paymentDetail, setPaymentDetail] = useState<PaymentData>();
  const [creditCard, setCreditCard] = useState<Array<any> | CreditCardInList>(
    []
  );
  const [CVC, setCVC] = useState('');
  const [error, setError] = useState('');
  const [paymentMethods, setPaymentMethods] = useState<Array<PaymentMethod>>(
    []
  );

  const [redPacket, setRedPacket] = useState<RedPacket>({ link: '', img: '' });
  const [creditForm, setCreditForm] = useState<Array<any> | CreditForm>([]);

  const headerHeight = useHeaderHeight();
  const { t } = useTranslation();
  const [showReorderModal, setShowReorderModal] = useState(false);
  const [reorderErrors, setReorderErrors] = useState<ReorderItem[]>([]);
  const isFocused = useIsFocused();
  //Init loading order detail
  /** @ts-ignore */
  useEffect(() => {
    let isSubscribed = true;
    const unsubscribe = navigation.addListener('focus', () => {
      if (orderId) {
        getOrderDetail(orderId)
          .then((result) => {
            if (result && result.goodStatus) {
              if (isSubscribed) {
                setOrderDetail(result.data.order);
                setSelectedPayment({
                  name: result.data.order.pay_name,
                  id: result.data.order.pay_id,
                  description: result.data.order.pay_desc,
                  code: result.data.order.pay_code,
                });
                if (
                  result.data.share_red_packet &&
                  isRedPacketAvailable(result.data.share_red_packet)
                ) {
                  setRedPacket(result.data.share_red_packet);
                }
                if (result.data.payment) {
                  setPaymentMethods(result.data.payment.list);
                  setPaymentDetail(result.data.payment);
                  if (
                    result.data.payment.card_info &&
                    result.data.payment.card_list &&
                    !Array.isArray(
                      result.data.payment.card_info && Array.isArray(creditCard)
                    )
                  ) {
                    setCreditCard(result.data.payment.card_info);
                  }
                }
                if (result.data.credit_form) {
                  setCreditForm(result.data.credit_form);
                }
              }
            } else {
              /** @ts-ignore */
              setError(result.data);
              console.log('err', result);
            }
            setIsLoadingComplete(true);
          })
          .catch((err) => console.log(err));
      }
    });
    return () => {
      isSubscribed = false;
      return unsubscribe;
    };
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    if (reorderErrors.length && isFocused && isSubscribed)
      setShowReorderModal(true);
    return () => {
      isSubscribed = false;
    };
  }, [isFocused]);

  const isRedPacketAvailable = (
    redPacket: RedPacket | []
  ): redPacket is RedPacket => {
    if (Array.isArray(redPacket)) {
      return false;
    } else {
      if ('link' in redPacket && 'img' in redPacket) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () =>
        route?.name === 'OrderDetail' ? (
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('OrderList', { type: type || 'all' });
              navigation.dispatch((state: any) => {
                // Remove the order detail route from the stack
                const routes = state.routes.filter(
                  (r: any) => r.name !== 'OrderDetail'
                );

                return CommonActions.reset({
                  ...state,
                  routes,
                  index: routes.length - 1,
                });
              });
            }}
          >
            <BackButton />
          </TouchableOpacity>
        ) : (
          <NavigateButton goBack>
            <BackButton />
          </NavigateButton>
        ),
      headerRight: () => (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <RedPacketBtn redPacket={redPacket} />
          {!getAppStyle(process.env.EXPO_APP_TYPE).toHideFeature && (
            <CustomerServiceBtn />
          )}
        </View>
      ),
    });
  }, [redPacket]);

  //Update information when user change payment method
  useEffect(() => {
    let isSubscribed = true;
    if (orderDetail && selectedPayment.id !== -1) {
      if (selectedPayment.id !== orderDetail.pay_id) {
        const loading = Toast.showLoading('');
        updateOrderPaymentMethod(
          orderId,
          selectedPayment.id,
          creditCard && !Array.isArray(creditCard) ? creditCard.id : undefined
        )
          .then((result) => {
            if (result && result.goodStatus) {
              if (isSubscribed) {
                setOrderDetail(result.data.order);
                if (
                  result.data.payment.card_info &&
                  result.data.payment.card_list &&
                  !Array.isArray(
                    result.data.payment.card_info && Array.isArray(creditCard)
                  )
                ) {
                  setCreditCard(result.data.payment.card_info);
                }
                setPaymentDetail(result.data.payment);
                setCreditForm(result.data.credit_form);
              }
            } else {
              if (isSubscribed) {
                setSelectedPayment({
                  name: orderDetail.pay_name,
                  id: orderDetail.pay_id,
                  description: orderDetail.pay_desc,
                  code: orderDetail.pay_code,
                });
              }
              alert(t('order.failedUpdatePaymentMethod'), '', [
                {
                  text: t('closeSeeMore'),
                  onPress: () => {},
                },
              ]);
              console.log('err', result);
            }
            Toast.hide(loading);
          })
          .catch((err) => {
            if (isSubscribed) {
              setSelectedPayment({
                name: orderDetail.pay_name,
                id: orderDetail.pay_id,
                description: orderDetail.pay_desc,
                code: orderDetail.pay_code,
              });
            }
            Toast.hide(loading);
            console.log(err);
          });
      } else {
        if (
          paymentDetail &&
          paymentDetail.card_info &&
          paymentDetail.card_info.id &&
          creditCard &&
          !Array.isArray(creditCard) &&
          paymentDetail.card_info.id !== creditCard.id
        ) {
          const loading = Toast.showLoading('');
          updateOrderPaymentMethod(orderId, selectedPayment.id, creditCard.id)
            .then((result) => {
              if (result && result.goodStatus) {
                if (isSubscribed) {
                  setOrderDetail(result.data.order);
                  setPaymentDetail(result.data.payment);
                  setCreditForm(result.data.credit_form);
                }
              } else {
                if (isSubscribed) {
                  setCreditCard(paymentDetail.card_info || []);
                }
                alert(t('order.failedUpdatePaymentMethod'), '', [
                  {
                    text: t('closeSeeMore'),
                    onPress: () => {},
                  },
                ]);
                console.log('err', result);
              }
              Toast.hide(loading);
            })
            .catch((err) => {
              if (isSubscribed) {
                setCreditCard(paymentDetail.card_info || []);
              }
              Toast.hide(loading);
              console.log(err);
            });
        }
      }
    }
    return () => {
      isSubscribed = false;
    };
  }, [selectedPayment, creditCard]);

  //reset CVC when payment method or credit card is changed
  useEffect(() => {
    setCVC('');
  }, [selectedPayment, creditCard]);

  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  const renderBottomActions = () => {
    if (Object.keys(orderDetail).length === 0) return <></>;

    if (
      orderDetail.cancel_button ||
      orderDetail.pay_button ||
      orderDetail.delete_button ||
      orderDetail.receive_button ||
      orderDetail.reorder_button ||
      orderDetail.delivery_status === 1 ||
      orderDetail.delivery_status === 2
    ) {
      return (
        <BottomActions
          orderId={orderDetail.order_id}
          canCancel={orderDetail.cancel_button}
          canDelete={orderDetail.delete_button}
          canPay={orderDetail.pay_button}
          canReceive={orderDetail.receive_button}
          canReorder={orderDetail.reorder_button}
          totalPrice={orderDetail.total.formated_order_amount}
          payId={selectedPayment.id}
          payCode={selectedPayment.code}
          creditCard={creditCard}
          CVC={CVC}
          orderSn={orderDetail.order_sn}
          trackType={orderDetail.delivery_status}
          paymentData={paymentDetail}
          shareRedPacket={redPacket}
          setShowReorderModal={setShowReorderModal}
          setReorderErrors={setReorderErrors}
          totalAmount={orderDetail.total.order_amount}
          creditForm={creditForm}
        />
      );
    }
  };

  return (
    <FullWidthView style={{ backgroundColor: backGroundLight }}>
      {isLoadingComplete ? (
        error !== '' && !(Object.keys(orderDetail).length > 0) ? (
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MaterialIcons name="error" size={24} color="lightgray" />
            <StyledText color={'lightgray'}>{error}</StyledText>
          </View>
        ) : (
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            keyboardVerticalOffset={headerHeight}
            style={{ flex: 1 }}
          >
            <OrderDetail
              data={orderDetail}
              paymentData={paymentDetail}
              paymentMethods={paymentMethods}
              selectedPayment={selectedPayment}
              setSelectedPayment={setSelectedPayment}
              setCreditCard={setCreditCard}
              creditCard={creditCard || []}
              CVC={CVC}
              setCVC={setCVC}
            />
            {route?.name === 'OrderDetail' && renderBottomActions()}
          </KeyboardAvoidingView>
        )
      ) : (
        <View
          style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}
        >
          <Loading />
        </View>
      )}
      <ReorderModal
        title={t('order.prompt.reorderSuccessPartial')}
        visible={showReorderModal}
        setVisible={setShowReorderModal}
        setReorderErrors={setReorderErrors}
        items={reorderErrors}
      />
    </FullWidthView>
  );
};

export default OrderDetailScreen;

const getOrderDetail = async (id: number) => {
  const result = await getDataWithAuthToken(`order/detail?order_id=${id}`);
  return result;
};

const updateOrderPaymentMethod = async (
  id: number,
  payId: number,
  cardId?: number
) => {
  const result = await postWithAuthToken(`order/update`, {
    order_id: id,
    pay_id: payId,
    card_id: cardId || undefined,
  });
  return result;
};
