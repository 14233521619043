import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import InputField from './InputField';
import { Entypo, Feather, MaterialIcons } from '@expo/vector-icons';
import SendCodeButton from './SendCodeButton';
import ErrorMessage from './ErrorMessage';
import { Formik } from 'formik';
import { normalTextColor } from '../../constants/Colors';
import { useTranslation } from 'react-i18next';
import StyledButton from '../common/StyledButton';
import { VerifyPhoneSchema } from '../../utils/validation';
import Toast from 'react-native-tiny-toast';
import {
  postData,
  registerForPushNotificationsAsync,
  savePushToken,
} from '../../utils/service';
import { setAsyncStorageData } from '../../utils/asyncStorage';
import { TokenPayload } from '../../types';
import JwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import * as Linking from 'expo-linking';

type WeChatRegisterFormProps = {
  responseCode: number;
};

const WeChatRegisterForm = ({ responseCode }: WeChatRegisterFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const verifyUser = async (phone: string, code: string, redeem: string) => {
    try {
      let result: any = null;
      let referer: any = '';
      let redirectUrl: any = '';
      const url = await Linking.getInitialURL();
      if (url) {
        const { queryParams } = Linking.parse(url);
        if (queryParams) {
          if (queryParams.referer) {
            referer = queryParams.referer;
          }
          if (queryParams.redirect) {
            redirectUrl = queryParams.redirect;
          }
        }
      }

      switch (responseCode) {
        case 300:
          result = await OAuthRegister(
            phone,
            code,
            redeem,
            referer && { headers: { 'Referer-Platform': referer } }
          );
          break;
        case 301:
          result = await activatePhone(
            phone,
            code,
            referer && { headers: { 'Referer-Platform': referer } }
          );
          break;
        default:
          break;
      }
      if (result) {
        if (result.goodStatus) {
          if (redirectUrl) {
            let newUrl = redirectUrl;
            if (
              result.data.third_party &&
              typeof result.data.third_party === 'object'
            ) {
              Object.keys(result.data.third_party).forEach((key, i) => {
                if (i === 0) {
                  newUrl += `?${key}=${result.data.third_party[key]}`;
                } else {
                  newUrl += `&${key}=${result.data.third_party[key]}`;
                }
              });
            }
            redirect(newUrl);
          } else {
            login(result);
          }
        } else {
          throw result?.data;
        }
      } else {
        console.log('request error');
      }
    } catch (error) {
      Toast.show(error, {
        position: 0,
        duration: 2000,
      });
    }
  };

  const redirect = (url: string) => {
    Linking.canOpenURL(url)
      .then((supported) => {
        supported && Linking.openURL(url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const login = (result: any) => {
    setAsyncStorageData('@storage_AuthToken', result.data.token);
    const tokenInfo: TokenPayload = JwtDecode(result.data.token);

    dispatch({
      type: 'CHANGE_USER_STATUS',
      payload: {
        userInfo: {
          name: tokenInfo?.user_name,
          phoneNumber: tokenInfo?.mobile_phone,
          userId: tokenInfo?.user_id,
        },
        isLoggedIn: true,
      },
    });

    //Save device expo push token if user grant
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      registerForPushNotificationsAsync().then(async (token) => {
        if (token && tokenInfo) {
          savePushToken(token, {
            id: tokenInfo.user_id,
            exp: tokenInfo.exp,
          });
        }
      });
    }

    /*---------Start---------*/
    //To navigate user back to profile page after login. If the Bottom one failed,use Top (original) one.

    // Platform.OS === 'web'
    //   ? navigation.canGoBack()
    //     ? navigation.goBack()
    //     : window.history.go(-3)
    //   : navigation.goBack();

    if (Platform.OS === 'web') {
      navigation.reset({
        index: 2,
        routes: [
          {
            name: 'Root',
            params: {
              screen: 'User',
              params: {
                screen: 'UserProfile',
              },
            },
          },
        ],
      });
    } else {
      navigation.goBack();
    }
    /*---------End---------*/
  };

  return (
    <View style={styles.VerifyUserForm}>
      <Formik
        initialValues={{ phone: '', code: '', redeem: '' }}
        onSubmit={(values) =>
          verifyUser(values.phone, values.code, values.redeem)
        }
        validationSchema={VerifyPhoneSchema}
      >
        {({ handleChange, handleSubmit, values, errors, touched }) => (
          <View style={{ alignItems: 'center' }}>
            <InputField
              iconComponent={
                <Feather name="smartphone" size={24} color={normalTextColor} />
              }
              onChangeText={handleChange('phone')}
              placeholder={t('AuthModal.enterPhoneNumber')}
              keyboardType="phone-pad"
              value={values.phone}
            />
            <View style={styles.errorMsgContainer}>
              {errors.phone && touched.phone && (
                <ErrorMessage label={errors.phone} />
              )}
            </View>

            <InputField
              iconComponent={
                <Entypo
                  name="key"
                  size={20}
                  style={{ marginRight: 3 }}
                  color={normalTextColor}
                />
              }
              buttonComponent={
                <SendCodeButton value={values.phone} type={'phone'} />
              }
              onChangeText={handleChange('code')}
              maxLength={6}
              placeholder={t('AuthModal.enterCode')}
              keyboardType="number-pad"
              value={values.code}
            />
            <View style={styles.errorMsgContainer}>
              {errors.code && touched.code && (
                <ErrorMessage label={errors.code} />
              )}
            </View>

            <View>
              <InputField
                iconComponent={
                  <MaterialIcons
                    name="redeem"
                    size={24}
                    color={normalTextColor}
                  />
                }
                onChangeText={handleChange('redeem')}
                placeholder={`${t('AuthModal.enterRedeemCode')} (${t(
                  'AuthModal.optional'
                )})`}
                value={values.redeem}
              />
            </View>
            <View style={styles.errorMsgContainer}>
              {errors.redeem && touched.redeem && (
                <ErrorMessage label={errors.redeem} />
              )}
            </View>

            <StyledButton label={'Activate'} onPress={handleSubmit} />
          </View>
        )}
      </Formik>
    </View>
  );
};

const styles = StyleSheet.create({
  errorMsgContainer: {
    height: 24,
  },
  VerifyUserForm: {
    backgroundColor: 'white',
  },
});

const activatePhone = async (phone: string, code: string, options?: any) => {
  let body = {
    phone,
    code,
  };
  const result = await postData('user/phone/activate', body, options);
  return result;
};

const OAuthRegister = async (
  phone: string,
  code: string,
  redeem: string,
  options?: any
) => {
  let body = {
    username: phone,
    code,
    oauth_type: 'wx',
    promotion_code: redeem,
  };
  const result = await postData('oauth/register', body, options);
  return result;
};

export default WeChatRegisterForm;
