import { AntDesign } from '@expo/vector-icons';
import { useHeaderHeight } from '@react-navigation/stack';
import React, { useState } from 'react';
import {
  Dimensions,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { accentColor, backGroundLight } from '../../constants/Colors';
import BackButton from '../common/iconButtons/BackButton';
import StyledText from '../common/StyledText';
import SubCategoriesList from './container/SubCategoriesList';
import ScrollableHeader from './ScrollableHeader';
import { setAsyncStorageData } from '../../utils/asyncStorage';
import { useTranslation } from 'react-i18next';
import GeneralModal from '../common/GeneralModal';

const { width, height } = Dimensions.get('window');

type TutorialPopupProps = {
  modalVisible: boolean;
  setModalVisible: Function;
  data: any;
  levels: number;
};

const TutorialPopup = ({
  modalVisible,
  setModalVisible,
  data,
  levels,
}: TutorialPopupProps) => {
  const { t } = useTranslation();
  const headerHeight = useHeaderHeight();
  const [showPartTwo, setShowPartTwo] = useState(false);

  const setPopupExpireTime = () => {
    setAsyncStorageData('@storage_FinishedCategoryTutorial', 'true');
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setPopupExpireTime();
  };

  const handleFinishPartOne = () => {
    if (levels > 1) {
      setShowPartTwo(true);
    } else {
      handleModalClose();
    }
  };

  const renderPartOne = (
    <>
      {Platform.OS === 'web' ? (
        <View style={styles.webHeader}>
          <BackButton />
          <ScrollableHeader disabled={true} />
        </View>
      ) : (
        <View
          style={{
            backgroundColor: 'white',
            marginTop: headerHeight,
            width: width,
          }}
        >
          <ScrollableHeader disabled={true} />
        </View>
      )}
      <View style={styles.mainContentContainer}>
        <AntDesign
          name="caretup"
          style={{ transform: [{ translateY: 10 }, { translateX: 10 }] }}
          size={18}
          color={accentColor}
        />
        <View style={styles.tutorialMessageWrapper}>
          <StyledText color={'white'}>
            {t('categoriesScreen.tutorial.partOneMessage')}
          </StyledText>
        </View>
      </View>
      <TouchableOpacity
        style={styles.nextStepBtnWrapper}
        onPress={handleFinishPartOne}
      >
        <View style={styles.nextStepBtn}>
          <StyledText color={'white'}>
            {levels > 1
              ? t('categoriesScreen.tutorial.next')
              : t('categoriesScreen.tutorial.done')}
          </StyledText>
        </View>
      </TouchableOpacity>
    </>
  );

  const renderPartTwo = (
    <>
      {Platform.OS === 'web' ? (
        <View style={[styles.webHeader, { opacity: 0 }]}>
          <BackButton />
          <ScrollableHeader disabled={true} />
        </View>
      ) : (
        <View style={{ opacity: 0, marginTop: headerHeight, width: width }}>
          <ScrollableHeader disabled={true} />
        </View>
      )}
      <View style={[styles.mainContentContainer, { flexDirection: 'row' }]}>
        <View style={styles.leftContainer}>
          <SubCategoriesList
            resetHorizontalIndex={() => {}}
            data={data}
            disabled={true}
          />
        </View>
        <View style={styles.rightContainer}>
          <View
            style={{ backgroundColor: 'transparent', alignItems: 'flex-start' }}
          >
            <View
              style={{
                backgroundColor: 'transparent',
                marginTop: 20,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <AntDesign
                style={{ transform: [{ translateX: 10 }] }}
                name="caretleft"
                size={18}
                color={accentColor}
              />
              <View style={styles.tutorialMessageWrapper}>
                <StyledText color={'white'}>
                  {t('categoriesScreen.tutorial.partTwoMessage')}
                </StyledText>
              </View>
            </View>
          </View>
        </View>
      </View>
      <TouchableOpacity
        style={styles.nextStepBtnWrapper}
        onPress={handleModalClose}
      >
        <View style={styles.nextStepBtn}>
          <StyledText color={'white'}>
            {t('categoriesScreen.tutorial.done')}
          </StyledText>
        </View>
      </TouchableOpacity>
    </>
  );

  return (
    <View style={{ zIndex: 999 }}>
      <GeneralModal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        statusBarTranslucent={true}
      >
        <View style={styles.modalView}>
          {showPartTwo ? <>{renderPartTwo}</> : <>{renderPartOne}</>}
        </View>
      </GeneralModal>
    </View>
  );
};

const styles = StyleSheet.create({
  modalView: {
    backgroundColor: 'rgba(0,0,0,0.7)',
    width: width,
    height: height,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    position: 'relative',
  },
  webHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
    borderBottomColor: 'lightgray',
    borderBottomWidth: 0.5,
    width: width,
  },
  leftContainer: {
    backgroundColor: backGroundLight,
    width: width * 0.22 + 2,
  },
  rightContainer: {
    width: (width - 10) * 0.78,
    marginHorizontal: 5,
    backgroundColor: 'transparent',
    justifyContent: 'flex-start',
  },
  nextStepBtnWrapper: {
    position: 'absolute',
    bottom: '25%',
  },
  nextStepBtn: {
    backgroundColor: 'transparent',
    padding: 10,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  tutorialMessageWrapper: {
    flexDirection: 'row',
    backgroundColor: accentColor,
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
  },
  mainContentContainer: {
    flex: 1,
    backgroundColor: 'transparent',
  },
});

export default TutorialPopup;
