import { postData } from './service';

export let defaultWeChatShareConfig = {
  title: '',
  desc: '',
  logo: '',
};

/**
 * For Web, set the basic config for wechat available menus
 */
export function setWechatWebConfig() {
  return new Promise((resolve, reject) => {
    if (wx !== undefined) {
      postData('wechat/jssdk', {
        url: location.href,
      })
        .then((result) => {
          if (result && result.goodStatus) {
            const configs = result.data;
  
            wx.config({
              debug: configs.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: configs.appId, // 必填，公众号的唯一标识
              timestamp: configs.timestamp, // 必填，生成签名的时间戳
              nonceStr: configs.nonceStr, // 必填，生成签名的随机串
              signature: configs.signature, // 必填，签名
              jsApiList: [
                'updateAppMessageShareData',
                'updateTimelineShareData',
                'showMenuItems',
                'hideMenuItems',
              ], // 必填，需要使用的JS接口列表
            });
            setWechatMenu();
  
            defaultWeChatShareConfig = {
              title: configs.shop.title,
              desc: configs.shop.desc,
              logo: configs.shop.logo,
            };
  
            if (location.href.includes('home')){
              setWechatSharePage(
                configs.shop.title,
                configs.shop.desc,
                configs.shop.logo
              );
            }
            
            wx.error((res: any) => {
              console.log('catch config error :: =============== ::', res);
            });
          }
          resolve('finished');
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    } else {
      console.log('Cannot find Wechat JS Api');
      reject('Cannot find Wechat JS Api');
    }
  });
}

function setWechatMenu() {
  if (wx !== undefined) {
    wx.ready(() => {
      wx.showMenuItems({
        menuList: [
          'menuItem:share:appMessage',
          'menuItem:share:timeline',
          'menuItem:favorite',
          'menuItem:share:facebook',
          'menuItem:openWithSafari',
          'menuItem:openWithQQBrowser',
        ],
      });
      wx.hideMenuItems({
        menuList: [
          // 'menuItem:copyUrl',
          'menuItem:share:email',
          'menuItem:share:qq',
        ],
      });
    });
  } else {
    console.log('Cannot find Wechat JS Api');
  }
}

/**
 * Wechat share config
 * @param title
 * @param desc
 * @param imgUrl
 */
export function setWechatSharePage(
  title: string,
  desc: string,
  imgUrl: string,
  link: string = window.location.href
) {
  if (wx !== undefined) {
    wx.ready(function () {
      //需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({
        title: title, // 分享标题
        desc: desc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: imgUrl, // 分享图标
        success: function () {
          // 设置成功
          // console.log('setting share config success');
        },
      });

      wx.updateTimelineShareData({
        title: title, // 分享标题
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: imgUrl, // 分享图标
        success: function () {
          // 设置成功
          // console.log('setting share config success');
        },
      });
    });
  } else {
    console.log('Cannot find Wechat JS Api');
  }
}

export const resetWechatSharePage = (
  title: string,
  desc = defaultWeChatShareConfig.desc,
  logo = defaultWeChatShareConfig.logo,
  link?: string,
  timeOut?: number
) => {
  setTimeout(() => {
    setWechatSharePage(
      `${title} - ${defaultWeChatShareConfig.title}`,
      desc,
      logo,
      link
    );
  }, timeOut ?? 10);
};
