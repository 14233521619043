import React from 'react';
import { ProductInformation } from '../../types';
import WhiteCard from '../common/WhiteCard';
import StyledText from '../common/StyledText';
import { View, StyleSheet, Dimensions, TouchableOpacity } from 'react-native';
import ResponsiveImg from '../common/ResponsiveImg';
import { accentColor, warningRed } from '../../constants/Colors';
import { useNavigation } from '@react-navigation/native';
import AddToCartBtn from '../Cart/AddToCartBtn';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import ProductImageTag from './widgets/ProductImageTag';

type ProductCardProps = {
  data: ProductInformation;
};

const { width } = Dimensions.get('window');

const ProductCard = ({ data }: ProductCardProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const soldOut = data.stock === 0 && data.specification.length === 0;

  const renderPriceText = () => {
    //Has Promote Price
    if (data.promote_price !== 0) {
      return (
        <View>
          <StyledText bold size={16} color={accentColor}>
            {data.formated_promote_price}
          </StyledText>
          <StyledText bold size={12} style={styles.originalPrice}>
            {data.formated_shop_price}
            <StyledText size={12}>{' /' + data.goods_unit}</StyledText>
          </StyledText>
        </View>
      );
    } else {
      return (
        <View style={{ flex: 1 }}>
          <StyledText bold size={12} style={styles.originalPrice}>
            {' '}
          </StyledText>
          <StyledText bold size={16} color={accentColor} numberOfLines={1}>
            {data.formated_shop_price}
            <StyledText size={12}>{' /' + data.goods_unit}</StyledText>
          </StyledText>
        </View>
      );
    }
  };

  const handleOpenProductDetail = () => {
    navigation.navigate('ProductNavigator', {
      screen: 'ProductDetailScreen',
      params: { productId: data.goods_id },
    });
  };

  return (
    <View style={styles.cardContainer}>
      <TouchableOpacity onPress={handleOpenProductDetail}>
        <WhiteCard>
          <View style={styles.thumbContainer}>
            <ResponsiveImg width={width / 2 - 60} uri={data.goods_thumb} />
            <ProductImageTag data={data} />
          </View>

          <View
            style={{
              height: 70,
              flexDirection: 'row',
            }}
          >
            {typeof data.tag === 'string' && data.tag !== '' && (
              <View style={styles.tag}>
                <StyledText size={10} color={warningRed}>
                  {data.tag}
                </StyledText>
              </View>
            )}

            <View style={{ flex: 1 }}>
              <StyledText numberOfLines={4} size={12}>
                {data.goods_name}
              </StyledText>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {renderPriceText()}
            <View style={styles.buttonContainer}>
              {soldOut ? (
                <></>
              ) : data.is_presale === 1 ? (
                <TouchableOpacity
                  style={styles.preOrderButton}
                  onPress={handleOpenProductDetail}
                >
                  <StyledText size={12} color="white">
                    {t('productInformation.preOrderButton')}
                  </StyledText>
                </TouchableOpacity>
              ) : (
                <AddToCartBtn goodsId={data.goods_id} goodsData={data}>
                  <View style={styles.cartIcon}>
                    <MaterialCommunityIcons
                      name="cart-outline"
                      size={20}
                      color={accentColor}
                    />
                  </View>
                </AddToCartBtn>
              )}
            </View>
          </View>
        </WhiteCard>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    width: width / 2 - 15,
  },
  thumbContainer: {
    alignSelf: 'center',
    marginBottom: 5,
    position: 'relative',
  },
  originalPrice: {
    color: 'lightgray',
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  buttonContainer: {
    alignSelf: 'flex-end',
  },
  cartIcon: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: accentColor,
    padding: 5,
    justifyContent: 'center',
  },
  preOrderButton: {
    backgroundColor: accentColor,
    padding: 3,
  },
  watermark: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  tag: {
    borderWidth: 1,
    borderColor: warningRed,
    padding: 2,
    borderRadius: 5,
    marginRight: 3,
    alignSelf: 'flex-start',
  },
});

export default ProductCard;
