import * as React from 'react';
import { StyleSheet, View, Platform } from 'react-native';

import FullWidthView from '../../components/common/FullWidthView';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, CartShop } from '../../types';
import { useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import StyledText from '../../components/common/StyledText';
import { backGroundLight, tintColorLight } from '../../constants/Colors';
import { AntDesign } from '@expo/vector-icons';
import NavigateButton from '../../components/common/NavigateButton';
import { useTranslation } from 'react-i18next';
import { getCartInformation } from '../../utils/service';
import CartItemList from '../../components/Cart/CartItemList';
import Loading from '../../components/common/Loading';
import EmptyDisplayBlock from '../../components/common/EmptyDisplayBlock';
import { useUpdateEffect } from 'ahooks';
import alert from '../../utils/alert';

export default function CartScreen() {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.user.isLoggedIn
  );

  const cartList = useSelector<RootState, Array<CartShop>>(
    (state) => state.goods.shoppingCart.cartList
  );

  const [isLoadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    //When screen focus, fetch user cart data if logged in.
    const unsubscribe = navigation.addListener('focus', () => {
      if (isLoggedIn) {
        getCartInformation()
          .then((result) => {
            if (result && result.goodStatus) {
              dispatch({
                type: 'UPDATE_CART_LIST',
                payload: result.data.cart_list,
              });
              dispatch({
                type: 'UPDATE_CART_ITEM_COUNTER',
                payload: result.data.cart_number,
              });
              setLoadingComplete(true);
            } else {
              alert(`Error:${result?.return_code}`, result?.data || '');
            }

            setLoadingComplete(true);
          })
          .catch((err) => {
            console.log('ERROR!', err);
            throw err;
          });
      }
    });

    return unsubscribe;
  }, [navigation, cartList]);

  useUpdateEffect(() => {
    if (isLoggedIn) {
      getCartInformation()
        .then((result) => {
          if (result && result.goodStatus) {
            dispatch({
              type: 'UPDATE_CART_LIST',
              payload: result.data.cart_list,
            });
            dispatch({
              type: 'UPDATE_CART_ITEM_COUNTER',
              payload: result.data.cart_number,
            });
            setLoadingComplete(true);
          } else {
            alert(`Error:${result?.return_code}`, result?.data || '');
          }

          setLoadingComplete(true);
        })
        .catch((err) => {
          throw err;
        });
    }
  }, [isLoggedIn]);

  return (
    <FullWidthView style={styles.container}>
      {!isLoggedIn ? (
        <>
          <LoginTip />
          <EmptyCartBlock />
        </>
      ) : isLoadingComplete ? (
        cartList.length > 0 ? (
          <CartItemList list={cartList} />
        ) : (
          <EmptyCartBlock />
        )
      ) : (
        <View style={styles.loadingContainer}>
          <Loading />
        </View>
      )}
    </FullWidthView>
  );
}

const LoginTip = () => {
  const { t } = useTranslation();

  return (
    <View style={styles.loginTipContainer}>
      <StyledText color={tintColorLight}>{t('cartScreen.loginTip')}</StyledText>
      <NavigateButton route="Modal" options={{ screen: 'Auth' }}>
        <View style={[styles.loginTipBtn, { borderColor: tintColorLight }]}>
          <StyledText color={tintColorLight}>
            {t('cartScreen.loginBtn')}
          </StyledText>
        </View>
      </NavigateButton>
    </View>
  );
};

const EmptyCartBlock = () => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  return (
    <EmptyDisplayBlock
      buttonLabel={t('cartScreen.goShopping')}
      onPress={() => {
        navigation.navigate('Category');
      }}
      description={t('cartScreen.emptyCart')}
      icon={<AntDesign name="shoppingcart" size={80} color="lightgray" />}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: backGroundLight,
  },
  loginTipContainer: {
    position: 'absolute',
    width: '100%',
    top: 0,
    backgroundColor: tintColorLight + '1A',
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 9,
  },
  loginTipBtn: {
    borderRadius: 20,
    borderColor: tintColorLight,
    borderWidth: 1,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },

  emptyCartBlockContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
