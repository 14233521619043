import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { subTextColor } from '../../../constants/Colors';
import { ArticleListItemType } from '../../../types';
import NavigateButton from '../../common/NavigateButton';
import StyledText from '../../common/StyledText';

type ArticleListItemProps = {
  item: ArticleListItemType;
};

const ArticleListItem = ({ item }: ArticleListItemProps) => {
  return (
    <NavigateButton route="ArticleScreen" options={{ id: item.article_id }}>
      <View style={styles.container}>
        <View style={styles.leftContainer}>
          <Image style={styles.articleThumbnail} source={{ uri: item.image }} />
        </View>
        <View style={styles.rightContainer}>
          <View>
            <StyledText size={16} bold numberOfLines={2}>
              {item.title}
            </StyledText>
            <StyledText numberOfLines={3}>{item.description}</StyledText>
          </View>
          <View>
            <StyledText color={subTextColor} align="right">
              {item.add_time}
            </StyledText>
          </View>
        </View>
      </View>
    </NavigateButton>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 10,
    flexDirection: 'row',
  },
  leftContainer: {},
  rightContainer: {
    paddingHorizontal: 5,
    justifyContent: 'space-between',
    flex: 1,
  },
  articleThumbnail: {
    width: 80,
    height: 80,
    borderRadius: 5,
  },
});

export default ArticleListItem;
