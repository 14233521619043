import React from 'react';
import {
  View,
  StyleSheet,
  Dimensions,
  TouchableWithoutFeedback,
  Pressable,
  Linking,
} from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import GeneralModal from '../common/GeneralModal';
import { RedPacket } from '../../types';
import ResponsiveImg from '../common/ResponsiveImg';

type RedPacketPopupProps = {
  modalVisible: boolean;
  setModalVisible: Function;
  redPacket: RedPacket;
  handleCheckOrderDetail: () => void;
};
// clearAllAsyncStorage();
const { width, height } = Dimensions.get('window');

const RedPacketPopup = ({
  modalVisible,
  setModalVisible,
  redPacket,
  handleCheckOrderDetail,
}: RedPacketPopupProps) => {
  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleWebShare = () => {
    handleCheckOrderDetail();
    Linking.openURL(redPacket.link);
  };

  return (
    <View style={{ zIndex: 999 }}>
      <GeneralModal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={handleModalClose}
        statusBarTranslucent={true}
      >
        <View style={styles.modalView}>
          <View style={styles.imgContainer}>
            <Pressable onPress={() => handleWebShare()}>
              <ResponsiveImg uri={redPacket.img} />
            </Pressable>
          </View>
          <View style={styles.closeBtn}>
            <TouchableWithoutFeedback onPress={handleModalClose}>
              <AntDesign name="closecircle" size={36} color="white" />
            </TouchableWithoutFeedback>
          </View>
        </View>
      </GeneralModal>
    </View>
  );
};

export default RedPacketPopup;

const styles = StyleSheet.create({
  modalView: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: width,
    height: height,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  closeBtn: {
    position: 'relative',
    marginTop: 15,
    zIndex: 100000,
  },
  imgContainer: {
    borderRadius: 15,
    backgroundColor: 'transparent',
    overflow: 'hidden',
  },
});
