import React from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { useTranslation } from 'react-i18next';
import StyledText from './StyledText';
import getAppStyle from '../../utils/getAppStyle';
import { WHITE } from '../../constants/Colors';

/**
  Styled loading component
 */
const Loading = () => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Image
        source={getAppStyle(process.env.EXPO_APP_TYPE)?.loadingIcon}
        style={{ width: 150, height: 150 }}
      />
      <StyledText align="center" bold>
        {t('loading')}
      </StyledText>
    </View>
  );
};

export default Loading;

const styles = StyleSheet.create({
  container: {
    color: WHITE,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
