import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  Image,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import QRCode from 'react-native-qrcode-generator';
import QRCodeWeb from 'react-qr-code';
import { accentColor, successGreen } from '../../../constants/Colors';
import GeneralModal from '../../common/GeneralModal';
import StyledText from '../../common/StyledText';

const { height, width } = Dimensions.get('window');

type QrCodeModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  inviteUrl: string;
};

const QrCodeModal = ({ visible, setVisible, inviteUrl }: QrCodeModalProps) => {
  const { t } = useTranslation();

  return (
    <GeneralModal
      animationType="fade"
      transparent={true}
      visible={visible}
      statusBarTranslucent={true}
      onRequestClose={() => setVisible(false)}
    >
      <View style={styles.modalContainer}>
        <View style={styles.mainContentContainer}>
          <Image
            style={{ width: 50, height: 50 }}
            source={require('../../../assets/images/appicon.png')}
          />
          <StyledText
            align="center"
            color={accentColor}
            bold
            size={24}
            style={{ marginVertical: 10 }}
          >
            {t('inviteFriendScreen.qrCode.askFriendScan')}
          </StyledText>
          {Platform.OS === 'web' ? (
            <QRCodeWeb
              value={inviteUrl}
              size={250}
              bgColor={successGreen}
              fgColor="white"
            />
          ) : (
            <QRCode
              value={inviteUrl}
              size={250}
              bgColor={successGreen}
              fgColor="white"
            />
          )}
        </View>
        <TouchableWithoutFeedback onPress={() => setVisible(false)}>
          <View style={styles.closeBtn}>
            <AntDesign name="closecircle" size={40} color="white" />
          </View>
        </TouchableWithoutFeedback>
      </View>
    </GeneralModal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    backgroundColor: 'rgba(0,0,0,0.7)',
    justifyContent: 'center',
    alignItems: 'center',
    width: width,
    height: height,
    zIndex: 0,
    flex: 1,
  },
  mainContentContainer: {
    backgroundColor: 'white',
    padding: 20,
    zIndex: 10,
    borderRadius: 10,
    alignItems: 'center',
  },
  closeBtn: {
    marginTop: 10,
  },
});

export default QrCodeModal;
