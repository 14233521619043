import React from 'react';
import {
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  ScrollView,
} from 'react-native';

import {
  backGroundLight,
  normalTextColor,
  tintColorLight,
} from '../../../constants/Colors';
import { ArticleCategory } from '../../../types';
import StyledText from '../../common/StyledText';

type ArticleCategoryMenuProps = {
  selectedIndex: number;
  setSelectedIndex: Function;
  data: ArticleCategory[];
};

const ArticleCategoryMenu = ({
  selectedIndex,
  setSelectedIndex,
  data = [],
}: ArticleCategoryMenuProps) => {
  const handleSelectCategory = (index: number) => {
    setSelectedIndex(index);
  };

  const menus = data.map((menu, index) => (
    <TouchableWithoutFeedback
      key={index}
      onPress={() => handleSelectCategory(index)}
    >
      <View
        style={[
          styles.button,
          {
            backgroundColor:
              index === selectedIndex ? tintColorLight : backGroundLight,
          },
        ]}
      >
        <StyledText
          color={index === selectedIndex ? 'white' : normalTextColor}
          size={15}
        >
          {menu.cat_name}
        </StyledText>
      </View>
    </TouchableWithoutFeedback>
  ));

  return (
    <View style={styles.container}>
      <ScrollView horizontal>{menus}</ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 10,
    marginTop: 5,
  },
  button: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginRight: 5,
    borderRadius: 5,
  },
});

export default ArticleCategoryMenu;
