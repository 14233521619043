import { AntDesign, Entypo } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import { subTextColor } from '../../../constants/Colors';
import StyledText from '../../common/StyledText';
import WhiteCard from '../../common/WhiteCard';

type CouponSelectorProps = {
  coupon: {
    info: any;
  };
  ruId: number;
};

const CouponSelector = ({ coupon, ruId }: CouponSelectorProps) => {
  const { t } = useTranslation();
  const { info } = coupon;
  const navigation = useNavigation();

  const handlePress = () => {
    navigation.navigate('CouponPromo', { ruId: ruId });
  };

  return (
    <WhiteCard title={t('order.coupon.title')}>
      <TouchableOpacity
        onPress={handlePress}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingVertical: 10,
        }}
      >
        <View style={{ flex: 10, justifyContent: 'center' }}>
          {info && !Array.isArray(info) ? (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Entypo name="ticket" size={16} color={subTextColor} />
              <StyledText color={subTextColor}>{info.cou_title}</StyledText>
            </View>
          ) : (
            <StyledText color={subTextColor}>
              {t('order.coupon.applyCouponPromoCode')}
            </StyledText>
          )}
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {info && !Array.isArray(info) && (
            <View
              style={{
                paddingVertical: 5,
                paddingHorizontal: 10,
                backgroundColor: 'rgba(255, 99, 71, 0.2)',
                marginRight: 10,
                borderRadius: 5,
              }}
            >
              <StyledText color={'red'}>
                {'-' + info.formated_coupon_amount}
              </StyledText>
            </View>
          )}
          <AntDesign name="rightcircleo" size={20} color="lightgray" />
        </View>
      </TouchableOpacity>
    </WhiteCard>
  );
};

export default CouponSelector;
