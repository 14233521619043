import * as Application from 'expo-application';
import { Dimensions, Platform } from 'react-native';
import axios from 'axios';
import { webCurrentVersion } from '../constants/WebConstants';

const { width, height } = Dimensions.get('window');

const tid = 'UA-164523400-2';

const linkParameters = {
  an: 'Luniu Mall',
  aid: 'Luniu-mall',
  av: Application.nativeApplicationVersion || webCurrentVersion,
  ua: Platform.OS,
  sr: `${width}x${height}`,
};

/**
 * Send screen name to Google Analytic for tracking purpose
 * @param screenName
 */
export const screenHit = (screenName: string) => {
  const params = Object.entries(linkParameters)
    .map(
      (param) =>
        `&${encodeURIComponent(param[0])}=${encodeURIComponent(param[1])}`
    )
    .join('');
  const url = `https://www.google-analytics.com/collect?tid=${tid}&v=1&cid=${
    Application.applicationId
  }t=pageview&dp=${screenName}${params}&z=${Math.round(Math.random() * 1e8)}`;
  axios.get(url, {
    responseType: 'arraybuffer',
  });
};
