import React from 'react';
import { View, StyleSheet } from 'react-native';
import WhiteCard from '../../common/WhiteCard';
import StyledText from '../../common/StyledText';
import { accentColor } from '../../../constants/Colors';
import { OrderFormInfoLight } from '../../../types';
import { useTranslation } from 'react-i18next';

type OrderFormInfoProps = {
  formValues: Array<OrderFormInfoLight>;
};

const OrderFormInfo = ({ formValues }: OrderFormInfoProps) => {
  const { t } = useTranslation();

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <WhiteCard title={t('order.form')}>
      <View style={styles.container}>
        {formValues.map((formGroup: OrderFormInfoLight) => (
          <View key={formGroup.field_id}>
            <StyledText size={14} style={{marginVertical: 5}} color={accentColor}>{formGroup.field_name}</StyledText>
            {Object.values(formGroup.child).map((value) => (
              <StyledText style={styles.text} key={value.field_name + '@' + value.value}>
                <StyledText bold>{`${capitalizeFirstLetter(value.field_name)}: `}</StyledText>
                {`${value.value}`}
              </StyledText>
            ))}
          </View>
        ))}
      </View>
    </WhiteCard>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 5
  },
  text: {
    marginVertical: 2
  }
});

export default OrderFormInfo;
