import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { removeAsyncStorageData } from '../../../utils/asyncStorage';
import {
  postWithAuthToken,
  registerForPushNotificationsAsync,
  savePushToken,
} from '../../../utils/service';
import StyledButton from '../../common/StyledButton';
import StyledText from '../../common/StyledText';
//@ts-ignore
import Toast from 'react-native-tiny-toast';
import { warningRed } from '../../../constants/Colors';

const AccountDeleteForm = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dispatch = useDispatch();

  //Confirm logout, clean up user information
  const emptyToken = () => {
    //Remove device expo push token when logout
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      registerForPushNotificationsAsync().then(async (token) => {
        if (token) {
          savePushToken(token, {
            id: 0,
            exp: 0,
          });
        }
      });
    }

    removeAsyncStorageData('@storage_AuthToken');
    dispatch({
      type: 'CHANGE_USER_STATUS',
      payload: {
        userInfo: {
          name: '',
          phoneNumber: '',
          userId: -1,
        },
        isLoggedIn: false,
      },
    });

    navigation.navigate('UserProfile');
  };

  const deleteAccount = () => {
    let loading = Toast.showLoading('');
    postWithAuthToken('user/destroy', {})
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            Toast.hide(loading);
            emptyToken();
          } else {
            Toast.hide(loading);
            Toast.show(response.data, {
              position: 0,
            });
          }
        } else {
          Toast.hide(loading);
          console.log('no response');
        }
      })
      .catch((err) => {
        Toast.hide(loading);
        console.log(err);
      });
  };

  return (
    <View style={styles.rootContainer}>
      <View>
        <StyledText style={styles.text}>
          {t('userInfoScreen.deleteAccountOption.termOne')}
        </StyledText>
        <StyledText style={styles.text}>
          {t('userInfoScreen.deleteAccountOption.termTwo')}
        </StyledText>
      </View>
      <View>
        <StyledButton
          label={t('userInfoScreen.deleteAccountOption.confirm')}
          onPress={deleteAccount}
          color={warningRed}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 15,
  },
  text: {
    paddingHorizontal: 10,
    paddingVertical: 15,
  },
});
export default AccountDeleteForm;
