import React from 'react';
import {
  View,
  StyleSheet,
  Alert,
  TouchableOpacity,
  GestureResponderEvent,
} from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { backGroundLight, tintColorLight } from '../../../constants/Colors';
import StyledText from '../../common/StyledText';
import { useTranslation } from 'react-i18next';
import WhiteCard from '../../common/WhiteCard';
import NotificationDot from '../../common/widgets/NotificationDot';
import { useNavigation } from '@react-navigation/native';
import NavigateButton from '../../common/NavigateButton';
import alert from '../../../utils/alert';
import { event } from 'react-native-reanimated';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';

type ProfileOrderMenuProps = {
  unpayOrder: number;
  awaitShipOrder: number;
  awaitCommentOrder: number;
};

type OrderMenuItemType = {
  label: string;
  iconName: string;
  amount?: number;
  route?: string;
  orderType?: string;
};

const ProfileOrderMenu = ({
  unpayOrder = 0,
  awaitShipOrder = 0,
  awaitCommentOrder = 0,
}: ProfileOrderMenuProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.user.isLoggedIn
  );

  const orderMenuOptions: Array<OrderMenuItemType> = [
    {
      label: t('userProfileScreen.myOrders.options.unpaid'),
      iconName: 'wallet',
      amount: unpayOrder,
      route: 'Order',
      orderType: 'awaitPay',
    },
    {
      label: t('userProfileScreen.myOrders.options.unreceived'),
      iconName: 'car',
      amount: awaitShipOrder,
      route: 'Order',
      orderType: 'awaitShip',
    },
    {
      label: t('userProfileScreen.myOrders.options.uncommented'),
      iconName: 'form',
      amount: awaitCommentOrder,
      route: 'Review',
    },
    {
      label: t('userProfileScreen.myOrders.options.return'),
      iconName: 'swap',
      route: 'Claim',
    },
  ];

  const renderOrderItems = orderMenuOptions.map((item, index) => {
    return <OrderMenuItem item={item} key={index} />;
  });

  const handleGoToAllOrder = () => {
    if (isLoggedIn) {
      navigation.navigate('Order', {
        screen: 'OrderList',
        params: {
          type: 'all',
        },
      });
    } else {
      navigation.navigate('Modal', { screen: 'Auth' });
    }
  };

  return (
    <WhiteCard
      title={t('userProfileScreen.myOrders.title')}
      action={{
        label: t('userProfileScreen.myOrders.options.all'),
        handleActionPress: handleGoToAllOrder,
      }}
    >
      <View style={styles.menuContainer}>{renderOrderItems}</View>
    </WhiteCard>
  );
};

type OrderMenuItemProp = {
  item: OrderMenuItemType;
};

const OrderMenuItem = ({ item }: OrderMenuItemProp) => {
  const { t } = useTranslation();
  return item.route ? (
    <NavigateButton
      loginRequired={true}
      route={item.route}
      options={{
        screen: 'OrderList',
        params: {
          type: item.orderType,
        },
      }}
    >
      <View style={styles.menuItem}>
        {item.amount ? (
          <NotificationDot label={item.amount?.toString()} />
        ) : (
          <></>
        )}

        <AntDesign name={item.iconName} size={24} color={tintColorLight} />
        <StyledText>{item.label}</StyledText>
      </View>
    </NavigateButton>
  ) : (
    <TouchableOpacity
      onPress={(event: GestureResponderEvent) => {
        event.preventDefault();
        alert(t('messageCenter.returnText').toString());
      }}
    >
      <View style={styles.menuItem}>
        {item.amount ? (
          <NotificationDot label={item.amount?.toString()} />
        ) : (
          <></>
        )}

        <AntDesign name={item.iconName} size={24} color={tintColorLight} />
        <StyledText>{item.label}</StyledText>
      </View>
    </TouchableOpacity>
  );
};

export default ProfileOrderMenu;

const styles = StyleSheet.create({
  menuContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
  },
  menuItem: {
    paddingTop: 10,
    alignItems: 'center',
  },
});
