import { FlatList } from 'react-native';
import FullWidthView from '../../../components/common/FullWidthView';
import { useCallback, useEffect, useState } from 'react';
import { getDataWithAuthToken } from '../../../utils/service';
import { Claim, ClaimParamList } from '../../../types';
import alert from '../../../utils/alert';
import ListFooterText from '../../../components/common/list/ListFooterText';
import ActivityIndicator from '../../../components/common/ActivityIndicator';
import ListSeparator from '../../../components/common/list/ListSeparator';
import ClaimListHeader from '../../../components/User/Claim/ClaimListHeader';
import ClaimListItem from '../../../components/User/Claim/ClaimListItem';
import { RouteProp, useRoute } from '@react-navigation/native';
import Loading from '../../../components/common/Loading';
import EmptyDisplayBlock from '../../../components/common/EmptyDisplayBlock';

const ClaimListScreen = () => {
  const route = useRoute<RouteProp<ClaimParamList, 'ClaimList'>>();
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [claims, setClaims] = useState<Claim[]>([]);
  const [allLoaded, setAllLoaded] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [firstLoadComplete, setFirstLoadComplete] = useState<boolean>(false);
  const reviewStatuses = [undefined, 'unreviewed', 'pass', 'fail'];

  const getClaimList = useCallback(() => {
    setIsFetching(true);
    setAllLoaded(false);
    getDataWithAuthToken('order/claim/list', {
      params: {
        page: page,
        size: 10,
        review_status: reviewStatuses[currentIndex],
        order_id: route.params && route.params.orderId,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setTotalPage(response.data.total_page);
          setAllLoaded(page === response.data.total_page);
          setClaims((prev) => [...prev, ...response.data.claim]);
        } else {
          alert(`Error:${response?.return_code}`, response?.data || '');
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setFirstLoadComplete(true);
        setIsFetching(false);
      });
  }, [page, currentIndex]);

  const loadExtraData = () => {
    if (isFetching) return;
    if (page < totalPage) {
      setPage((lastValue) => lastValue + 1);
    }
  };

  useEffect(() => {
    let isSubscribed = true;
    isSubscribed && getClaimList();

    return () => {
      isSubscribed = false;
    };
  }, [getClaimList]);

  return (
    <FullWidthView edges={['bottom', 'left', 'right']}>
      {firstLoadComplete ? (
        <>
          {route && route.params && route.params.orderId ? (
            <></>
          ) : (
            <ClaimListHeader
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              callback={() => {
                setClaims([]);
                setPage(1);
              }}
            />
          )}
          {claims && claims.length > 0 ? (
            <FlatList
              style={{ flex: 1 }}
              data={claims}
              renderItem={({ item }) => <ClaimListItem item={item} />}
              keyExtractor={(item: Claim) => item.id.toString()}
              onEndReached={() => loadExtraData()}
              onEndReachedThreshold={0.01}
              ListFooterComponent={
                allLoaded ? ListFooterText : ActivityIndicator
              }
              ItemSeparatorComponent={() => <ListSeparator />}
            />
          ) : isFetching ? (
            <Loading />
          ) : (
            <EmptyDisplayBlock />
          )}
        </>
      ) : (
        <Loading />
      )}
    </FullWidthView>
  );
};

export default ClaimListScreen;
