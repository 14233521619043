import React from 'react';
import FullWidthView from '../../../components/common/FullWidthView';
import StyledText from '../../../components/common/StyledText';
import { useRoute, RouteProp } from '@react-navigation/native';
import { CartParamList } from '../../../types';
import { View, StyleSheet, ScrollView } from 'react-native';
import ListOption from '../../../components/common/list/ListOption';
import NavigateButton from '../../../components/common/NavigateButton';
import { backGroundLight } from '../../../constants/Colors';

const PickupLocationListScreen = () => {
  const route = useRoute<RouteProp<CartParamList, 'PickupLocationList'>>();

  const { list } = route.params;

  if (!list) return <StyledText>Error List</StyledText>;

  const addressList = list.map((address, index) => {
    return (
      <View key={address.id} style={{ marginBottom: 10 }}>
        <ListOption>
          <NavigateButton
            route='OrderPreview'
            options={{ newPickupAddressIndex: index }}
          >
            <View style={styles.row}>
              <StyledText bold>{address.name}</StyledText>
              <StyledText>{address.mobile}</StyledText>
            </View>
            <StyledText>{address.address}</StyledText>
          </NavigateButton>
        </ListOption>
      </View>
    );
  });

  return (
    <FullWidthView style={{ backgroundColor: backGroundLight }}>
      <ScrollView contentContainerStyle={{ paddingTop: 10 }}>
        {addressList}
      </ScrollView>
    </FullWidthView>
  );
};

const styles = StyleSheet.create({
  container: {},
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default PickupLocationListScreen;
