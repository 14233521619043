import React, { useState, useEffect, useRef } from 'react';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import SearchBar from './SearchBar';
import FullWidthView from '../common/FullWidthView';
import HeaderView from '../common/HeaderView';
import NavigateButton from '../common/NavigateButton';
import { normalTextColor } from '../../constants/Colors';
import { Platform } from 'react-native';
import RecentSearchSection, {
  updateRecentSearchList,
} from './RecentSearchSection';
import TopSearchSection from './TopSearchSection';

const SearchForm = () => {
  const [value, setText] = useState('');

  const navigation = useNavigation();
  const searchBarRef = useRef<any>(null);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      if (searchBarRef.current) {
        searchBarRef.current.focus();
      }
    });
    return unsubscribe;
  }, [navigation]);

  //When enter this screen, clean up search text
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setText('');
    });

    return unsubscribe;
  }, [navigation]);

  const handleSearchSubmit = (value: string) => {
    updateRecentSearchList(value);
    navigation.navigate('SearchResult', { keyword: value });
  };

  const searchOnChange = (text: string) => {
    setText(text);
  };

  return (
    <FullWidthView
      edges={['left', 'right', 'top']}
      style={{ paddingTop: Platform.OS === 'android' ? 15 : 0 }}
    >
      <HeaderView
        style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}
      >
        <NavigateButton goBack>
          <AntDesign name="left" size={24} color={normalTextColor} />
        </NavigateButton>
        <SearchBar
          ref={searchBarRef}
          onChange={searchOnChange}
          onSubmit={handleSearchSubmit}
          searchText={value}
        />
      </HeaderView>
      <TopSearchSection onItemClick={handleSearchSubmit} />
      <RecentSearchSection onItemClick={handleSearchSubmit} />
    </FullWidthView>
  );
};

export default SearchForm;
