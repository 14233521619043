import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Dimensions, FlatList } from 'react-native';
import FullWidthView from '../../components/common/FullWidthView';
import { getProductCommentList } from '../../utils/service';
import ListFooterText from '../../components/common/list/ListFooterText';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import Comment from '../../components/common/Comment';
import { useHeaderHeight } from '@react-navigation/stack';
import { Comment as CommentType } from '../../types';

type CommentScreenProps = {
  route: any;
};

const { height } = Dimensions.get('window');

const CommentScreen = ({ route }: CommentScreenProps) => {
  const [comments, setComments] = useState<Array<CommentType>>([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { productId } = route.params;
  const headerHeight = useHeaderHeight();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsFetching(true);
    getProductCommentList(productId, page, 10)
      .then((response) => {
        if (response && response.goodStatus) {
          setComments((lastValue: any) => [
            ...lastValue,
            ...response.data.comments,
          ]);
          setTotalPage(response.data.total_page);
        } else {
          throw response?.data;
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
        setIsFetching(false);
      });
  }, [page]);

  const _renderItem = ({ item }: { item: CommentType }) => (
    <View style={styles.commentContainer}>
      <Comment
        comment_id={item.comment_id}
        nick_name={item.nick_name}
        comment_rank={item.comment_rank}
        content={item.content}
        user_picture={item.user_picture}
        add_time={item.add_time}
        images={item.images}
        admin_reply={item.admin_reply}
        showFullContent={true}
      />
    </View>
  );

  const loadExtraData = async () => {
    if (isFetching) return;
    if (page < totalPage) {
      setPage((lastValue) => lastValue + 1);
    }
  };

  return (
    <FullWidthView edges={['bottom', 'left', 'right']}>
      <FlatList
        style={{ flex: 1 }}
        data={comments}
        renderItem={_renderItem}
        keyExtractor={(item: CommentType) => item.comment_id.toString()}
        onEndReached={() => loadExtraData()}
        onEndReachedThreshold={0.01}
        ListFooterComponent={isLoading ? ActivityIndicator : ListFooterText}
      />
    </FullWidthView>
  );
};

const styles = StyleSheet.create({
  commentContainer: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgray',
  },
});

export default CommentScreen;
