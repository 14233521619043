import React from 'react';
import { useHeaderHeight } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { accentColor, tintColorLight } from '../../../constants/Colors';
import StyledButton from '../../common/StyledButton';
import StyledText from '../../common/StyledText';

const { width } = Dimensions.get('window');

type SubmitOrderWidgetProps = {
  totalPrice: string;
  freeShippingAmount: number;
  handleSubmitButtonPress: Function;
  handleScrollToEnd: () => void;
  fastDeliveryNotice: string;
  buttonLabel?: string;
};

const SubmitOrderWidget = ({
  totalPrice,
  freeShippingAmount = 0,
  handleSubmitButtonPress,
  handleScrollToEnd,
  fastDeliveryNotice = '',
  buttonLabel,
}: SubmitOrderWidgetProps) => {
  const { t } = useTranslation();
  const headerHeight = useHeaderHeight();

  return (
    <View style={styles.bottomWidget}>
      {freeShippingAmount !== 0 && (
        <View style={{ backgroundColor: '#ffedd1' }}>
          <StyledText align="center" color={accentColor}>
            {t('order.freeDeliveryTip', { amount: freeShippingAmount })}
          </StyledText>
        </View>
      )}

      {fastDeliveryNotice !== '' && (
        <View style={[styles.fastDeliveryNotice]}>
          <StyledText align="center" color={tintColorLight}>
            {fastDeliveryNotice}
          </StyledText>
        </View>
      )}

      <View style={styles.summary}>
        <View>
          <TouchableOpacity onPress={handleScrollToEnd}>
            <StyledText>
              {t('order.total')}
              <StyledText bold color={accentColor} size={16}>
                {totalPrice}
              </StyledText>
            </StyledText>
          </TouchableOpacity>
        </View>
        <View>
          <StyledButton
            buttonSize="small"
            label={buttonLabel || t('order.submit')}
            onPress={() => {
              handleSubmitButtonPress();
            }}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  bottomWidget: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: 0,
  },
  summary: {
    backgroundColor: 'white',
    width: width,
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fastDeliveryNotice: {
    backgroundColor: '#e6ffde',
    paddingLeft: 5,
    paddingRight: 5,
  },
});

export default SubmitOrderWidget;
