import React from 'react';
import { AntDesign } from '@expo/vector-icons';
import { GestureResponderEvent, TouchableOpacity } from 'react-native';

type ClearButtonProps = {
  onPress?: (event: GestureResponderEvent) => void;
};

const EditButton = ({ onPress }: ClearButtonProps) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <AntDesign name="edit" size={20} color="lightgray" />
    </TouchableOpacity>
  );
};

export default EditButton;
