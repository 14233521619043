import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { ProductInformation, RootState } from '../../../types';
import getAppStyle from '../../../utils/getAppStyle';
import StyledText from '../../common/StyledText';
import AmountCounter from '../../Product/widgets/AmountCounter';

type ChooseOfferBtnProps = {
  status: number;
  onPress: (action: string) => void;
  gift: ProductInformation;
};

const ChooseOfferBtn = ({ status = 0, onPress, gift }: ChooseOfferBtnProps) => {
  const { t } = useTranslation();
  const appMainColor = useSelector<RootState, string>(
    (state) => state.user.appMainColor
  );

  switch (status) {
    default:
    case 0:
      return gift.current_gift_number ? (
        <AmountCounter
          amount={gift.current_gift_number || 0}
          maxQuantity={gift.limit_number || 0}
          updateAmount={onPress}
        />
      ) : (
        <TouchableOpacity onPress={() => onPress('add')} disabled={true}>
          <View style={[styles.buttonContainer, styles.unclickableBtn]}>
            <StyledText color="white">
              {t('specialOfferScreen.actions.unclickable')}
            </StyledText>
          </View>
        </TouchableOpacity>
      );
    case 1:
      return gift.current_gift_number ? (
        <AmountCounter
          amount={gift.current_gift_number || 0}
          maxQuantity={gift.limit_number || 0}
          updateAmount={onPress}
        />
      ) : (
        <TouchableOpacity onPress={() => onPress('add')}>
          <View
            style={[
              styles.buttonContainer,
              styles.clickableBtn,
              {
                borderColor: appMainColor,
              },
            ]}
          >
            <StyledText color={appMainColor}>
              {t('specialOfferScreen.actions.choose')}
            </StyledText>
          </View>
        </TouchableOpacity>
      );
    case 2:
      return (
        <AmountCounter
          amount={gift.current_gift_number || 0}
          maxQuantity={gift.limit_number || 0}
          updateAmount={onPress}
        />
      );
  }
};

const styles = StyleSheet.create({
  buttonContainer: {
    borderRadius: 25,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  unclickableBtn: {
    backgroundColor: 'lightgray',
  },
  clickableBtn: {
    borderWidth: 1,
  },
  selectedBtn: {
    backgroundColor: getAppStyle(process.env.EXPO_APP_TYPE)?.secondaryColor,
  },
});

export default ChooseOfferBtn;
