import React, { useEffect, useState, useCallback } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { CategoriesItem } from '../../../types';
import FullWidthView from '../../../components/common/FullWidthView';
import Level1Menu from '../../../components/Category/main/Level1Menu';
import NoContent from '../../../components/Category/NoContent';
import Level2Menu from '../../../components/Category/main/Level2Menu';
import Loading from '../../../components/common/Loading';
import { getDataWithAuthToken } from '../../../utils/service';
import { useNavigation } from '@react-navigation/native';
import { useHeaderHeight } from '@react-navigation/stack';

export default function CategoriesScreen() {
  const navigation = useNavigation();

  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [categoryChangeState, setCategoryChangeState] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  const [shouldDisplayCategory, setShouldDisplayCategory] = useState(false);

  /**
   * If Platform is web, redirect to home, groupsale only available for App
   */
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      if (Platform.OS === 'web') {
        navigation.reset({
          index: 0,
          routes: [{ name: 'Root' }],
        });
      }
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    //When user focus on screen, fetch Category and reset selectedSubCategoryIndex
    // const unsubscribe = navigation.addListener('focus', () => {
    setShouldDisplayCategory(true);
    setIsLoadingComplete(false);

    getCategories().then((response) => {
      if (response && response.goodStatus) {
        const filteredResult = response.data.reduce(
          (result: Array<CategoriesItem>, list: CategoriesItem) => {
            if (list.show_in_nav === 1 && list.url === undefined) {
              result.push(list);
            }
            return result;
          },
          []
        );

        dispatch({
          type: 'UPDATE_GROUPSALE_CATEGORY_ARRAY',
          payload: filteredResult,
        });
        dispatch({
          type: 'UPDATE_SELECTED_GROUPSALE_MAIN_CATEGORY_INDEX',
          payload: 0,
        });
        setIsLoadingComplete(true);
      } else {
        console.error(response);
      }
    });
    // });

    // return unsubscribe;
  }, []);

  //Refresh category to get new update
  const [refreshing, setRefreshing] = useState(false);
  const onRefresh = useCallback(() => {
    setRefreshing(true);
    getCategories().then((response) => {
      if (response && response.goodStatus) {
        const filteredResult = response.data.reduce(
          (result: Array<CategoriesItem>, list: CategoriesItem) => {
            if (list.show_in_nav === 1 && list.url === undefined) {
              result.push(list);
            }
            return result;
          },
          []
        );

        dispatch({
          type: 'UPDATE_GROUPSALE_CATEGORY_ARRAY',
          payload: filteredResult,
        });
        dispatch({
          type: 'UPDATE_SELECTED_GROUPSALE_MAIN_CATEGORY_INDEX',
          payload: 0,
        });
        setIsLoadingComplete(true);
        setRefreshing(false);
      } else {
        console.error(response);
        setRefreshing(false);
      }
    });
  }, []);

  return (
    <FullWidthView edges={['left', 'right']}>
      {isLoadingComplete ? (
        shouldDisplayCategory ? (
          <View style={styles.root}>
            <Level1Menu
              onRefresh={onRefresh}
              refreshing={refreshing}
              menuType="groupsale"
              setLevel1MenuChangeState={setCategoryChangeState}
            />
            <Level2Menu
              menuType="groupsale"
              level1MenuChangeState={categoryChangeState}
            />
          </View>
        ) : (
          <NoContent />
        )
      ) : (
        <View
          style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <Loading />
        </View>
      )}
    </FullWidthView>
  );
}

//Get Categories
async function getCategories() {
  const result = await getDataWithAuthToken('groupsale/category?level=2');
  return result;
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  //Only app platform need the flex 1, on Web it breaks the layout
  carouselContainer: {
    flex: 1,
  },

  root: {
    flexDirection: 'row',
    flex: 1,
  },
});
