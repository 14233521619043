import React, { useEffect, useRef, useState } from 'react';
import { RootState, CategoriesItem } from '../../types';
import { useSelector, useDispatch } from 'react-redux';
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  FlatList,
  Platform,
  Dimensions,
} from 'react-native';
import StyledText from '../common/StyledText';
import { getData } from '../../utils/service';
import { saveCategoryDataToLocal } from '../../utils/asyncStorage';
import { useNavigation } from '@react-navigation/native';
import { useUpdateEffect } from 'ahooks';
import { accentColor } from '../../constants/Colors';
import { Feather } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import NavigateButton from '../common/NavigateButton';
import BackButton from '../common/iconButtons/BackButton';

const { width } = Dimensions.get('window');

type ScrollableHeaderProps = {
  disabled?: boolean;
  menuType?: 'regular' | 'groupsale';
};

const ScrollableHeader = ({
  disabled = false,
  menuType = 'regular',
}: ScrollableHeaderProps) => {
  const { t } = useTranslation();

  const categoriesArray = useSelector<RootState, Array<CategoriesItem>>(
    (state) => state.goods.categoriesArray
  );

  const selectedMainCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedMainCategoryIndex
  );

  const groupSaleCategoriesArray = useSelector<
    RootState,
    Array<CategoriesItem>
  >((state) => state.goods.groupSaleCategoriesArray);

  const selectedGroupSaleCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedGroupSaleCategoryIndex
  );

  const horizontalCategoryList = useRef<FlatList>(null);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [filteredList, setFilteredList] = useState<Array<CategoriesItem>>([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const handlePress = (item: CategoriesItem, index: number) => {
    if (menuType === 'regular') {
      dispatch({
        type: 'UPDATE_SELECTED_MAIN_CATEGORY_NAME',
        payload: item.name,
      });
      dispatch({
        type: 'UPDATE_SELECTED_MAIN_CATEGORY_INDEX',
        payload: index,
      });
      dispatch({ type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX', payload: 0 });
      navigation.setParams({ catId: item.id });
      setShowDropdown(false);
    } else if (menuType === 'groupsale') {
      dispatch({
        type: 'UPDATE_SELECTED_GROUPSALE_MAIN_CATEGORY_INDEX',
        payload: index,
      });
      navigation.setParams({ catId: item.id });
      setShowDropdown(false);
    }
  };

  const targetCategoryIndex =
    menuType === 'regular'
      ? selectedMainCategoryIndex
      : selectedGroupSaleCategoryIndex;
  const targetArray =
    menuType === 'regular' ? categoriesArray : groupSaleCategoriesArray;

  useUpdateEffect(() => {
    setTimeout(() => {
      if (horizontalCategoryList && horizontalCategoryList.current) {
        horizontalCategoryList?.current?.scrollToIndex({
          animated: true,
          index: targetCategoryIndex,
          viewPosition: 0.3,
        });
      }
    }, 500);
  }, [filteredList]);

  useUpdateEffect(() => {
    if (horizontalCategoryList && horizontalCategoryList.current) {
      horizontalCategoryList?.current?.scrollToIndex({
        animated: true,
        index: targetCategoryIndex,
        viewPosition: 0.3,
      });
    }
  }, [targetCategoryIndex, filteredList]);

  useEffect(() => {
    //When user focus on screen, fetch Category and reset selectedSubCategoryIndex
    let isSubscribe = true;
    if (targetArray.length === 0) {
      if (menuType === 'regular') {
        getCategories().then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribe) {
              const filteredResult = response.data.reduce(
                (result: Array<CategoriesItem>, list: CategoriesItem) => {
                  if (list.show_in_nav === 1) {
                    result.push(list);
                  }
                  return result;
                },
                []
              );
              saveCategoryDataToLocal(filteredResult);
              dispatch({
                type: 'UPDATE_CATEGORY_ARRAY',
                payload: filteredResult,
              });
              //Update main categories list
              setFilteredList(filteredResult);
            }
          } else {
            console.error(response);
          }
        });
      }
    } else {
      //Only show show_in_nav === 1 item
      const filteredResult = targetArray.reduce(
        (result: Array<CategoriesItem>, list) => {
          if (list.show_in_nav === 1) {
            result.push(list);
          }
          return result;
        },
        []
      );
      //Update main categories list
      setFilteredList(filteredResult);
    }
    return () => {
      isSubscribe = false;
    };
  }, []);

  const _renderItem = ({ item, index }: any) => {
    const isCurrentCategory = targetCategoryIndex === index;
    return (
      <TouchableOpacity
        disabled={disabled}
        key={item.id}
        onPress={() => handlePress(item, index)}
      >
        <View style={styles.item}>
          <Image
            style={
              Platform.OS === 'web'
                ? styles.thumbnailWeb
                : styles.thumbnailMobile
            }
            source={{
              uri: item.cat_img,
            }}
          />
          <View
            style={
              isCurrentCategory
                ? {
                    backgroundColor: accentColor,
                    paddingHorizontal: 5,
                    paddingBottom: 2,
                    borderRadius: 50,
                  }
                : {}
            }
          >
            <StyledText
              align="center"
              size={10}
              color={isCurrentCategory ? 'white' : undefined}
              bold={isCurrentCategory}
            >
              {item.name}
            </StyledText>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View
      style={Platform.OS === 'web' ? styles.wrapperWeb : styles.wrapperMobile}
    >
      {menuType === 'groupsale' && Platform.OS !== 'web' && (
        <NavigateButton route="Categories" style={{ alignSelf: 'center' }}>
          <BackButton />
        </NavigateButton>
      )}

      <FlatList
        scrollEnabled={!disabled}
        ref={horizontalCategoryList}
        data={filteredList}
        keyExtractor={(item) => item.id.toString()}
        renderItem={_renderItem}
        horizontal
        contentContainerStyle={[
          { alignItems: 'center' },
          Platform.OS === 'web' ? { width: width - 20 } : {},
        ]}
        showsHorizontalScrollIndicator={false}
        style={
          showDropdown
            ? Platform.OS === 'ios'
              ? { display: 'none' }
              : { height: 0 }
            : undefined
        }
        getItemLayout={(data, index) => ({
          length: 50,
          offset: 50 * index,
          index,
        })}
      />
      {showDropdown ? (
        <View
          style={[
            {
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
            },
            Platform.OS !== 'ios' ? { width: '85%' } : { flex: 1 },
          ]}
        >
          {filteredList.map((item, index) => {
            const isCurrentCategory = targetCategoryIndex === index;
            return (
              <TouchableOpacity
                key={item.id}
                onPress={() => handlePress(item, index)}
              >
                <View style={styles.item}>
                  <Image
                    style={
                      Platform.OS === 'web'
                        ? styles.thumbnailWebDropdown
                        : styles.thumbnailMobileDropdown
                    }
                    source={{
                      uri: item.cat_img,
                    }}
                  />
                  <View
                    style={
                      isCurrentCategory
                        ? {
                            backgroundColor: accentColor,
                            paddingHorizontal: 5,
                            paddingBottom: 2,
                            borderRadius: 50,
                          }
                        : {}
                    }
                  >
                    <StyledText
                      align="center"
                      size={12}
                      color={isCurrentCategory ? 'white' : undefined}
                      bold={isCurrentCategory}
                    >
                      {item.name}
                    </StyledText>
                  </View>
                </View>
              </TouchableOpacity>
            );
          })}
        </View>
      ) : (
        <></>
      )}
      <TouchableOpacity
        disabled={disabled}
        onPress={() => setShowDropdown((prev) => !prev)}
      >
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            paddingHorizontal: 15,
            borderLeftColor: 'lightgray',
            borderLeftWidth: 1,
          }}
        >
          <StyledText size={14}>
            {t('categoriesScreen.dropdownButtonLine1')}
          </StyledText>
          <StyledText size={14}>
            {t('categoriesScreen.dropdownButtonLine2')}
          </StyledText>
          <Feather name="menu" size={14} color="black" />
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapperWeb: {
    flexDirection: 'row',
    flex: 1,
  },
  wrapperMobile: {
    paddingVertical: 5,
    borderBottomColor: 'lightgray',
    borderBottomWidth: 0.5,
    flexDirection: 'row',
  },
  item: {
    marginHorizontal: 7,
    alignItems: 'center',
  },
  thumbnailWeb: {
    width: 32,
    height: 32,
  },
  thumbnailWebDropdown: {
    width: 40,
    height: 40,
  },
  thumbnailMobile: {
    width: 50,
    height: 50,
  },
  thumbnailMobileDropdown: {
    width: 55,
    height: 55,
  },
});

export default ScrollableHeader;

//Get Categories
async function getCategories() {
  const result = await getData('category/detail?level=1');
  return result;
}
