import React, { useState, useEffect } from 'react';
import {
  View,
  ScrollView,
  TextInput,
  StyleSheet,
  Keyboard,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Platform,
} from 'react-native';
import ListOption from '../../common/list/ListOption';
import StyledText from '../../common/StyledText';
import ClearButton from '../../common/iconButtons/ClearButton';
import {
  normalTextColor,
  subTextColor,
  tintColorLight,
} from '../../../constants/Colors';
import NavigateButton from '../../common/NavigateButton';
import { AntDesign } from '@expo/vector-icons';
import StyledButton from '../../common/StyledButton';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AddressDetail, AddressInformation } from '../../../types';
import { Formik } from 'formik';
import { AddressSchema } from '../../../utils/validation';
import ErrorMessage from '../../Auth/ErrorMessage';
import { postWithAuthToken } from '../../../utils/service';
import Toast from 'react-native-tiny-toast';
import { useNavigation } from '@react-navigation/native';
import alert from '../../../utils/alert';
import { useHeaderHeight } from '@react-navigation/stack';
import AddressTypeSelectorModal from './AddressTypeSelectorModal';

type AddressFormProps = {
  type: 'edit' | 'new';
  data?: AddressInformation;
};

const AddressForm = ({ type = 'new', data }: AddressFormProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const headerHeight = useHeaderHeight();

  const addressDetail = useSelector<RootState, AddressDetail>(
    (state) => state.user.editingAddress
  );

  const [isDefaultAddress, setIsDefaultAddress] = useState(
    data?.is_default === 1 ? true : false
  );

  //Address Type related states
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [addressType, setAddressType] = useState<{
    title: string;
    value: string;
  }>({
    title: '',
    value: '',
  });

  useEffect(() => {
    //set new address as default when init
    type === 'new' && setIsDefaultAddress(true);

    //Match address type
    const addressTypes = [
      {
        title: '别墅 House',
        value: 'house',
      },
      {
        title: '联排别墅 Townhouse',
        value: 'townhouse',
      },
      {
        title: '公寓 Apartment/ Condo',
        value: 'apartment',
      },
      {
        title: '地下室 Basement',
        value: 'basement',
      },
    ];

    const currentTypeValue = addressTypes.find(
      (item) => item.value === data?.address_name
    );
    if (currentTypeValue) {
      setAddressType(currentTypeValue);
    }
  }, [data]);

  const formInitialValue =
    type == 'edit' && data
      ? {
          name: data.consignee,
          phone: data.mobile,
          postal: data.postal_code,
          unit: data.sign_building,
          buzz: data.zipcode,
        }
      : { name: '', phone: '', postal: '', unit: '', buzz: '' };

  const handleFormSubmit = (values: {
    name: string;
    phone: string;
    postal: string;
    unit: string;
    buzz: string;
  }) => {
    if (addressDetail.name !== '') {
      if (addressType.value !== '') {
        let addressData = {
          consignee: values.name,
          address: addressDetail.name.trim(),
          latitude: addressDetail.location.latitude.toString(),
          longitude: addressDetail.location.longitude.toString(),
          postal_code: values.postal,
          mobile: values.phone,
          sign_building: values.unit,
          zipcode: values.buzz,
          is_default: isDefaultAddress ? 1 : 0,
          id: data?.address_id ? data.address_id : '',
          address_name: addressType.value,
        };

        const loading = Toast.showLoading('');

        uploadAddress(addressData)
          .then((result) => {
            Toast.hide(loading);
            if (result && result.goodStatus && result.data) {
              Toast.showSuccess(t('successfullyAdded'));

              //Reset empty location
              dispatch({
                type: 'UPDATE_EDITING_ADDRESS',
                payload: {
                  name: '',
                  location: { latitude: 49.2827291, longitude: -123.1207375 },
                },
              });

              Platform.OS === 'web'
                ? navigation.canGoBack()
                  ? navigation.goBack()
                  : window.history.back()
                : navigation.goBack();
            } else {
              if (result && result.data) {
                Toast.show(result.data, { position: Toast.position.CENTER });
              }

              console.log(result);
            }
          })
          .catch((err) => {
            Toast.hide(loading);
            Toast.show(t('address.failedToAdd'), {
              position: Toast.position.CENTER,
            });

            console.log(err);
          });
      } else {
        alert('请选择地址类型');
      }
    } else {
      alert(t('address.chooseValidAddress'));
    }
  };

  const handleSelectCountry = () => {};

  const handleSetDefaultAddress = () => {
    setIsDefaultAddress((prev) => !prev);
  };

  return (
    <ScrollView
      style={[
        styles.rootContainer,
        // { paddingBottom: Platform.OS === 'web' ? headerHeight + 50 : 0 },
      ]}
    >
      <Formik
        initialValues={formInitialValue}
        validationSchema={AddressSchema}
        onSubmit={handleFormSubmit}
        validateOnChange={true}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          errors,
          setFieldValue,
          touched,
        }) => (
          <>
            <TouchableWithoutFeedback
              onPress={
                Platform.OS === 'web' ? undefined : () => Keyboard.dismiss()
              }
              accessible={false}
            >
              <>
                <ListOption>
                  <View style={styles.formContainer}>
                    <StyledText style={styles.fieldTitle}>
                      {t('address.info.consignee')}
                    </StyledText>
                    <View style={styles.fieldInputArea}>
                      <View style={styles.inputContainer}>
                        <TextInput
                          placeholder={t('address.info.required')}
                          placeholderTextColor={subTextColor}
                          style={[styles.inputField, { outline: 'none' }]}
                          value={values.name}
                          onChangeText={handleChange('name')}
                        />
                        {values.name.length > 0 && (
                          <ClearButton
                            onPress={() => setFieldValue('name', '')}
                          />
                        )}
                      </View>

                      {errors.name && touched.name && (
                        <View style={styles.errorMsgContainer}>
                          <ErrorMessage label={errors.name} />
                        </View>
                      )}
                    </View>
                  </View>
                </ListOption>
                <ListOption>
                  <View style={styles.formContainer}>
                    <StyledText style={styles.fieldTitle}>
                      {t('address.info.phone')}
                    </StyledText>

                    <View style={styles.fieldInputArea}>
                      <View style={styles.inputContainer}>
                        <TextInput
                          placeholder={t('address.info.required')}
                          placeholderTextColor={subTextColor}
                          keyboardType="phone-pad"
                          style={[styles.inputField, { outline: 'none' }]}
                          value={values.phone}
                          onChangeText={handleChange('phone')}
                        />
                        {values.phone.length > 0 && (
                          <ClearButton
                            onPress={() => setFieldValue('phone', '')}
                          />
                        )}
                      </View>

                      {errors.phone && touched.phone && (
                        <View style={styles.errorMsgContainer}>
                          <ErrorMessage label={errors.phone} />
                        </View>
                      )}
                    </View>
                  </View>
                </ListOption>
                <ListOption>
                  <View style={styles.formContainer}>
                    <StyledText style={styles.fieldTitle}>
                      {t('address.info.country')}
                    </StyledText>

                    <View style={styles.fieldInputArea}>
                      <TouchableOpacity onPress={handleSelectCountry} disabled>
                        <View style={styles.inputContainer}>
                          <StyledText>Canada</StyledText>

                          {/* <AntDesign
                            name='rightcircleo'
                            size={20}
                            color='lightgray'
                            style={{ marginLeft: 5 }}
                          /> */}
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </ListOption>
                <ListOption>
                  <View style={styles.formContainer}>
                    <StyledText style={styles.fieldTitle}>
                      {t('address.info.address')}
                    </StyledText>
                    <View style={styles.fieldInputArea}>
                      <View style={styles.inputContainer}>
                        <NavigateButton
                          route="MapView"
                          style={{
                            width: 250,
                          }}
                        >
                          <StyledText>{addressDetail.name + ' '}</StyledText>
                        </NavigateButton>
                        <NavigateButton route="MapView">
                          <AntDesign
                            name="rightcircleo"
                            size={20}
                            color="lightgray"
                            style={{ marginLeft: 5 }}
                          />
                        </NavigateButton>
                      </View>
                    </View>
                  </View>
                </ListOption>
                <ListOption>
                  <View style={styles.formContainer}>
                    <StyledText style={styles.fieldTitle}>
                      {t('address.info.postal')}
                    </StyledText>
                    <View style={styles.fieldInputArea}>
                      <View style={styles.inputContainer}>
                        <TextInput
                          placeholder={
                            t('address.info.required') +
                            ' ' +
                            t('address.info.format') +
                            'A1A 1A1'
                          }
                          placeholderTextColor={subTextColor}
                          style={[styles.inputField, { outline: 'none' }]}
                          value={values.postal}
                          onChangeText={(text) => {
                            let upperText = text.toUpperCase();
                            //Add Space at index 3 position
                            if (
                              values.postal.length === 2 &&
                              upperText.length === 3
                            ) {
                              upperText = upperText + ' ';
                            }
                            setFieldValue('postal', upperText);
                          }}
                          maxLength={7}
                        />
                        {values.postal.length > 0 && (
                          <ClearButton
                            onPress={() => setFieldValue('postal', '')}
                          />
                        )}
                      </View>
                      {errors.postal && touched.postal && (
                        <View style={styles.errorMsgContainer}>
                          <ErrorMessage label={errors.postal} />
                        </View>
                      )}
                    </View>
                  </View>
                </ListOption>
                <ListOption>
                  <View style={styles.formContainer}>
                    <StyledText style={styles.fieldTitle}>
                      {t('address.info.addressType')}
                    </StyledText>
                    <View style={styles.fieldInputArea}>
                      <TouchableOpacity
                        onPress={() => {
                          setShowAddressModal(true);
                        }}
                      >
                        <View style={styles.inputContainer}>
                          {addressType.value !== '' ? (
                            <StyledText>{addressType.title}</StyledText>
                          ) : (
                            <StyledText color={subTextColor}>
                              {t('address.info.required')}
                            </StyledText>
                          )}
                        </View>
                      </TouchableOpacity>
                      <AddressTypeSelectorModal
                        visible={showAddressModal}
                        hideModal={() => setShowAddressModal(false)}
                        currentType={addressType.value}
                        setAddressType={setAddressType}
                      />
                    </View>
                  </View>
                </ListOption>
                <ListOption>
                  <View style={styles.formContainer}>
                    <StyledText style={styles.fieldTitle}>
                      {t('address.info.unit')}
                    </StyledText>
                    <View style={styles.fieldInputArea}>
                      <View style={styles.inputContainer}>
                        <TextInput
                          placeholder={t('address.info.optional')}
                          placeholderTextColor={subTextColor}
                          style={[styles.inputField, { outline: 'none' }]}
                          value={values.unit}
                          onChangeText={handleChange('unit')}
                        />
                        {values.unit.length > 0 && (
                          <ClearButton
                            onPress={() => setFieldValue('unit', '')}
                          />
                        )}
                      </View>
                      {errors.unit && touched.unit && (
                        <View style={styles.errorMsgContainer}>
                          <ErrorMessage label={errors.unit} />
                        </View>
                      )}
                    </View>
                  </View>
                </ListOption>
                <ListOption>
                  <View style={styles.formContainer}>
                    <StyledText style={styles.fieldTitle}>
                      {t('address.info.buzz')}
                    </StyledText>
                    <View style={styles.fieldInputArea}>
                      <View style={styles.inputContainer}>
                        <TextInput
                          placeholder={t('address.info.optional')}
                          placeholderTextColor={subTextColor}
                          style={[styles.inputField, { outline: 'none' }]}
                          value={values.buzz}
                          onChangeText={handleChange('buzz')}
                        />
                        {values.unit.length > 0 && (
                          <ClearButton
                            onPress={() => setFieldValue('buzz', '')}
                          />
                        )}
                      </View>
                      {errors.buzz && touched.buzz && (
                        <View style={styles.errorMsgContainer}>
                          <ErrorMessage label={errors.buzz} />
                        </View>
                      )}
                    </View>
                  </View>
                </ListOption>
                <TouchableOpacity
                  onPress={handleSetDefaultAddress}
                  style={styles.setDefaultAddressContainer}
                >
                  <AntDesign
                    name="checkcircle"
                    size={20}
                    color={isDefaultAddress ? tintColorLight : 'lightgray'}
                    style={{ marginRight: 5 }}
                  />
                  <StyledText>{t('address.info.setDefaultAddress')}</StyledText>
                </TouchableOpacity>
                <View style={styles.buttonContainer}>
                  <StyledButton label={t('confirm')} onPress={handleSubmit} />
                </View>
              </>
            </TouchableWithoutFeedback>
          </>
        )}
      </Formik>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
  },
  formContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  fieldTitle: {
    flex: 1,
  },
  inputContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fieldInputArea: {
    flex: 4,
  },

  inputField: {
    color: normalTextColor,
    flex: 1,
    fontSize: 14,
    paddingVertical: 0,
  },
  buttonContainer: {
    marginVertical: 30,
  },
  setDefaultAddressContainer: {
    margin: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  errorMsgContainer: {
    height: 24,
  },
});

export default AddressForm;

const uploadAddress = async (data: object) => {
  const result = await postWithAuthToken('user/address/edit', data);
  return result;
};
