import React from 'react';
import { View, StyleSheet, Platform, TouchableOpacity } from 'react-native';
import WhiteCard from '../../common/WhiteCard';
import { useTranslation } from 'react-i18next';
import StyledText from '../../common/StyledText';
import { subTextColor, accentColor } from '../../../constants/Colors';
import Toast from 'react-native-tiny-toast';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as Clipboard from 'expo-clipboard';

type BasicInfoProps = {
  data: {
    orderNum: string;
    orderStatus: string;
    addTime: string;
  };
};

const BasicInfo = ({ data }: BasicInfoProps) => {
  const { t } = useTranslation();

  const handleOrderNumberCopy = () => {
    Toast.show(t('order.copySuccessfully'), { position: 0 });

    if (Platform.OS !== 'web') {
      Clipboard.setString(data.orderNum);
    }
  };

  return (
    <WhiteCard title={t('order.orderInfo')}>
      <View style={{ padding: 5 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <StyledText>
            <StyledText color={subTextColor}>
              {t('order.orderNumber') + ': '}
            </StyledText>
            {data.orderNum}
          </StyledText>
          {Platform.OS !== 'web' ? (
            <TouchableOpacity
              onPress={handleOrderNumberCopy}
              style={styles.clipboardBtn}
            >
              <StyledText size={12} color={accentColor}>
                {t('order.copy')}
              </StyledText>
            </TouchableOpacity>
          ) : (
            <CopyToClipboard
              text={data.orderNum}
              onCopy={handleOrderNumberCopy}
            >
              <StyledText size={12} color={accentColor}>
                {' ' + t('order.copy')}
              </StyledText>
            </CopyToClipboard>
          )}
        </View>
        <StyledText color={accentColor}>
          <StyledText color={subTextColor}>
            {t('order.orderStatus') + ': '}
          </StyledText>
          {data.orderStatus}
        </StyledText>

        <StyledText color={subTextColor}>
          {t('order.orderAddTime') + ': '}
          {data.addTime}
        </StyledText>
      </View>
    </WhiteCard>
  );
};

const styles = StyleSheet.create({
  header: {},
  clipboardBtn: {
    marginLeft: 3,
  },
});

export default BasicInfo;
