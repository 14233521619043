import { useNavigation } from '@react-navigation/native';
import { useHeaderHeight } from '@react-navigation/stack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  StyleSheet,
  TextInput,
  View,
  TouchableWithoutFeedback,
  Keyboard,
} from 'react-native';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import FullWidthView from '../../components/common/FullWidthView';
import StyledButton from '../../components/common/StyledButton';
import { WHITE5 } from '../../constants/Colors';
import { addCoupon } from '../../utils/service';

const CouponRedeemScreen = () => {
  const navigation = useNavigation();
  const headerHeight = useHeaderHeight();
  const [couponCode, setCouponCode] = useState('');
  const { t } = useTranslation();

  const handlePressRedeem = () => {
    const loading = Toast.showLoading('');
    addCoupon(couponCode)
      .then((result) => {
        Toast.hide(loading);
        setCouponCode('');
        if (result && result.goodStatus) {
          Toast.showSuccess(t('order.coupon.createCouponSuccess'));
          navigation.navigate('CouponList');
        } else {
          Toast.show(result && result.data, {
            position: 0,
          });
        }
      })
      .catch((err) => {
        Toast.hide(loading);
        setCouponCode('');
        console.log('caught error', err);
      });
  };

  return (
    <FullWidthView
      style={{
        paddingBottom: Platform.OS === 'web' ? headerHeight : 0,
        backgroundColor: WHITE5,
      }}
    >
      <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
        <View style={styles.container}>
          <View
            style={[
              styles.input,
              Platform.OS === 'android' ? { padding: 5 } : { padding: 10 },
            ]}
          >
            <TextInput
              placeholder={t('order.coupon.enterPromoCode')}
              value={couponCode}
              onChangeText={(text) => setCouponCode(text)}
              placeholderTextColor={'lightgray'}
              style={{ fontSize: 16 }}
            />
          </View>
          <StyledButton
            onPress={handlePressRedeem}
            label={t('order.coupon.redeemBtn')}
          />
        </View>
      </TouchableWithoutFeedback>
    </FullWidthView>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
    flex: 1,
  },
  input: {
    backgroundColor: 'white',
    height: 50,
    marginBottom: 20,
  },
});

export default CouponRedeemScreen;
