import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { SearchParamList } from '../types';

import SearchForm from '../components/Search/SearchForm';
import SearchResult from '../components/Search/SearchResult';
import getAppStyle from '../utils/getAppStyle';

const SearchStack = createStackNavigator<SearchParamList>();

const SearchNavigator = () => {
  return (
    <SearchStack.Navigator
      initialRouteName="SearchModal"
      screenOptions={{
        // headerBackTitleVisible: false,
        // headerTitleAlign: 'center',
        headerShown: false,
        animationEnabled: false,
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <SearchStack.Screen name="SearchModal" component={SearchForm} />
      <SearchStack.Screen name="SearchResult" component={SearchResult} />
    </SearchStack.Navigator>
  );
};

export default SearchNavigator;
