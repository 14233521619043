import React, { useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import CartScreen from '../screens/Cart/CartScreen';
import { CartParamList } from '../types';
import { useTranslation } from 'react-i18next';
import OrderPreviewScreen from '../screens/Cart/OrderPreviewScreen';
import BackButton from '../components/common/iconButtons/BackButton';
import AddressNavigator from './User/AddressNavigator';
import {
  useRoute,
  useNavigation,
  getFocusedRouteNameFromRoute,
} from '@react-navigation/native';
import PickupLocationListScreen from '../screens/User/Order/PickupLocationListScreen';
import OrderSubmitScreen from '../screens/User/Order/OrderSubmitScreen';
import OrderResultScreen from '../screens/User/Order/OrderResultScreen';
import NewCreditCardScreen from '../screens/User/CreditCard/NewCreditCardScreen';
import OrderDetailScreen from '../screens/User/Order/OrderDetailScreen';
import WebPaymentScreen from '../screens/User/Order/WebPaymentScreen';
import OrderCheckScreen from '../screens/User/Order/OrderCheckScreen';
import BundleDetailScreen from '../screens/ProductBundle/BundleDetailScreen';
import CouponPromoScreen from '../screens/Coupon/CouponPromoScreen';
import SpecialOfferScreen from '../screens/Cart/SpecialOfferScreen';
import UploadFileScreen from '../screens/Cart/UploadFileScreen';
import getAppStyle from '../utils/getAppStyle';
import AddOrderGoodsScreen from '../screens/Cart/AddOrderGoodsScreen';
import NavigateButton from '../components/common/NavigateButton';

const CartStack = createStackNavigator<CartParamList>();

const CartNavigator = () => {
  const { t } = useTranslation();
  const route = useRoute();
  const navigation = useNavigation();

  //Hide Tab bar when route is not Cart home
  useEffect(() => {
    const activeRouteName = getFocusedRouteNameFromRoute(route);

    if (activeRouteName !== 'Cart' && activeRouteName !== undefined) {
      navigation.setOptions({
        tabBarVisible: false,
      });
    }

    return () =>
      navigation.setOptions({
        tabBarVisible: true,
      });
  }, [route]);

  return (
    <CartStack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
        headerBackImage: BackButton,
        headerTitleAlign: 'center',
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <CartStack.Screen
        name="Cart"
        component={CartScreen}
        options={{
          headerTitle: t('cartScreen.title'),
          headerLeft: () => <></>,
        }}
      />
      <CartStack.Screen
        name="OrderPreview"
        component={OrderPreviewScreen}
        options={{
          headerTitle: t('order.orderPreview'),
        }}
      />
      <CartStack.Screen
        name="CouponPromo"
        component={CouponPromoScreen}
        options={{
          headerTitle: t('order.coupon.title'),
        }}
      />
      <CartStack.Screen
        name="OrderSubmit"
        component={OrderSubmitScreen}
        options={{
          headerTitle: t('order.orderProcessing'),
          headerLeft: () => <></>,
          gestureEnabled: false,
        }}
      />
      <CartStack.Screen
        name="OrderResult"
        component={OrderResultScreen}
        options={{
          headerTitle: t('order.orderProcessResult'),
          headerLeft: () => <></>,
          gestureEnabled: false,
        }}
      />
      <CartStack.Screen
        name="OrderDetail"
        component={OrderDetailScreen}
        options={{
          headerTitle: t('order.orderDetail'),
          gestureEnabled: false,
        }}
      />
      <CartStack.Screen
        name="Address"
        component={AddressNavigator}
        options={{
          headerShown: false,
        }}
      />
      <CartStack.Screen
        name="PickupLocationList"
        component={PickupLocationListScreen}
        options={{
          headerTitle: t('address.pickupAddress'),
        }}
      />
      <CartStack.Screen
        name="NewCreditCardScreen"
        component={NewCreditCardScreen}
        options={{ title: t('creditCard.newCreditCard') }}
      />
      <CartStack.Screen
        name="WebPaymentScreen"
        component={WebPaymentScreen}
        options={{
          headerShown: false,
        }}
      />
      <CartStack.Screen
        name="OrderCheck"
        component={OrderCheckScreen}
        options={{
          headerTitle: t('order.orderProcessing'),
          headerLeft: () => <></>,
          gestureEnabled: false,
        }}
      />
      <CartStack.Screen
        name="BundleDetail"
        component={BundleDetailScreen}
        options={{ headerTitle: t('bundleScreen.bundleDetail.title') }}
      />
      <CartStack.Screen
        name="SpecialOffer"
        component={SpecialOfferScreen}
        options={{
          headerTitle: t('specialOfferScreen.title'),
        }}
      />
      <CartStack.Screen
        name="UploadFileScreen"
        component={UploadFileScreen}
        options={{
          headerTitle: t('uploadFileScreen.title'),
        }}
      />
      <CartStack.Screen
        name="AddOrderGoodsScreen"
        component={AddOrderGoodsScreen}
        options={{
          headerTitle: t('addOrderGoodsScreen.title'),
          headerLeft: () => (
            <NavigateButton doubleBack>
              <BackButton />
            </NavigateButton>
          ),
          gestureEnabled: false,
        }}
      />
    </CartStack.Navigator>
  );
};

export default CartNavigator;
