import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import {
  GestureResponderEvent,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { backGroundLight, tintColorLight } from '../../../constants/Colors';
import BottomAutoHeightModal from '../../common/BottomAutoHeightModal';
import StyledText from '../../common/StyledText';
import { useTranslation } from 'react-i18next';

type NotifyMethodSelectorModalProps = {
  visible: boolean;
  hideModal: Function;
  currentType: string;
  setNotifyMethod: React.Dispatch<React.SetStateAction<string>>;
  notifyMethodList: Array<string>;
};

const NotifyMethodSelectorModal = ({
  visible,
  hideModal,
  currentType,
  setNotifyMethod,
  notifyMethodList,
}: NotifyMethodSelectorModalProps) => {
  const { t } = useTranslation();

  const updateOption = (index: number) => {
    setNotifyMethod(notifyMethodList[index]);
    hideModal();
  };

  const renderOptions = notifyMethodList.map((item, index) => {
    return (
      <Option
        key={index}
        item={{ title: item, value: item }}
        updateOption={() => updateOption(index)}
        isActive={currentType === item}
      />
    );
  });

  return (
    <BottomAutoHeightModal visible={visible} hideModal={hideModal}>
      <StyledText bold size={16}>
        {t('order.notifyBy')}
      </StyledText>
      <View style={styles.contentContainer}>{renderOptions}</View>
    </BottomAutoHeightModal>
  );
};

type OptionProps = {
  item: { title: string; value: string };
  isActive: boolean;
  updateOption: ((event: GestureResponderEvent) => void) | undefined;
};

const Option = ({ item, isActive, updateOption }: OptionProps) => {
  return (
    <TouchableOpacity onPress={updateOption}>
      <View style={styles.optionItem}>
        <StyledText size={16}>{item.title}</StyledText>
        {isActive && (
          <AntDesign name="checkcircle" size={16} color={tintColorLight} />
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    paddingVertical: 10,
  },
  optionItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 10,
    borderTopColor: backGroundLight,
    borderTopWidth: 1,
  },
});

export default NotifyMethodSelectorModal;
