import getAppStyle from '../utils/getAppStyle';

const AddressRoutes = {
  path: 'address',
  initialRouteName: '',
  screens: {
    AddressList: '',
    NewAddress: 'new',
    EditAddress: 'edit/:addressObject',
    MapView: 'map-view',
  },
};

const ReviewRoutes = {
  path: 'reviews',
  initialRouteName: '',
  screens: {
    ReviewListScreen: '',
    SubmitReviewScreen: 'submit',
  },
};

const ClaimRoutes = {
  path: 'claims',
  initialRouteName: 'list',
  screens: {
    ClaimList: 'list',
    ClaimDetail: 'detail',
    ClaimOrderDetail: 'order-detail',
  },
};

const OrderRoutes = {
  path: 'orders',
  initialRouteName: 'list',
  screens: {
    OrderList: 'list/:type?',
    OrderDetail: 'detail',
    OrderSubmit: 'submit',
    OrderCheck: 'check',
    OrderResult: 'result',
    OrderTracking: 'track',
    NewCreditCardScreen: '',
    WebPaymentScreen: 'webpayment',
    OrderClaimList: 'claims/list',
    OrderClaimDetail: 'claims/detail',
    TaskGoodsList: 'task-goods/detail',
    UploadFileScreen: 'upload-files',
  },
};

const WalletRoutes = {
  path: 'wallet',
  initialRouteName: '',
  screens: {
    AccountScreen: '',
    PointScreen: 'points',
    EarnPointsScreen: 'earn-points',
    CouponList: 'coupons',
    CouponRedeem: 'coupon-redeem',
    AddFundsScreen: 'add-funds',
  },
};

const CreditCardRoutes = {
  path: 'credit-cards',
  initialRouteName: '',
  screens: {
    CreditCardListScreen: '',
    NewCreditCardScreen: 'new',
  },
};

const HelpCenterRoutes = {
  path: 'help-center',
  initialRouteName: '',
  screens: {
    HelpCenterListScreen: '',
    HelpCenterItemScreen: 'item',
  },
};

const GroupSaleRoutes = {
  path: 'groupsale',
  screens: {
    Categories: '',
    SubCategory: 'list',
  },
};

const CartRoutes = {
  path: 'cart',
  initialRouteName: '',
  screens: {
    Cart: '',
    OrderPreview: 'order-preview',
    OrderSubmit: 'order-submit',
    OrderResult: 'order-result',
    OrderDetail: 'order-detail',
    OrderCheck: 'order-check',
    Address: AddressRoutes,
    PickupLocationList: 'pickup-locations',
    NewCreditCardScreen: 'credit-cards/new',
    WebPaymentScreen: 'webpayment',
    BundleDetail: 'bundle-detail',
    SpecialOffer: 'offer',
    UploadFileScreen: 'upload-files',
    AddOrderGoodsScreen: 'add-order-goods',
  },
};

const BundleRoutes = {
  path: 'bundle',
  initialRouteName: '',
  screens: {
    BundleHome: '',
    BundleDetail: 'detail',
  },
};

const SettingsRoutes = {
  path: 'settings',
  screens: {
    SettingsMainScreen: '',
    LanguageSettingScreen: 'language',
    AboutLuniu: 'about',
    SettingWebView: 'info',
    NotificationSettingScreen: 'notification',
    FontSettingScreen: 'font',
  },
};

const SearchRoutes = {
  path: 'search',
  screens: {
    SearchModal: '',
    SearchResult: 'result',
  },
};

const BrandRoutes = {
  path: 'brands',
  screens: {
    BrandList: '',
    BrandDetail: 'detail',
  },
};

const ArticleRoutes = {
  path: 'articles',
  screens: {
    ArticleListScreen: 'list',
    ArticleScreen: 'article',
  },
};

const RootRoutes = {
  path: '/',
  screens: {
    Home: {
      path: 'home',
      initialRouteName: '',
      screens: {
        Home: '',
        ThemeHomeScreen: 'topic/:topicId/:pageNumber?',
        ProductList: {
          path: 'product-list/:catId',
        },
        Region: 'region',
        HomeWebviewScreen: 'webview',
        ProductBundle: BundleRoutes,
        SearchNavigator: SearchRoutes,
        Brand: BrandRoutes,
        Articles: ArticleRoutes,
      },
    },
    Category: {
      path: 'categories',
      initialRouteName: '',
      screens: {
        Categories: '',
        SubCategory: 'subcategory/:catId',
        ProductBundle: BundleRoutes,
        SearchNavigator: SearchRoutes,
        Brand: BrandRoutes,
      },
    },
    Post: {
      path: 'post',
      initialRouteName: '',
      screens: {
        Post: '',
        PostDetail: 'detail/:postId',
      },
    },
    Cart: CartRoutes,
    User: {
      path: 'user',
      initialRouteName: '',
      screens: {
        UserProfile: '',
        UserSettings: SettingsRoutes,
        UserInfo: 'info',
        UserInfoEdit: 'info/edit',
        Wallet: WalletRoutes,
        Address: AddressRoutes,
        Review: ReviewRoutes,
        Favorites: 'favorites',
        Order: OrderRoutes,
        HelpCenter: HelpCenterRoutes,
        CreditCard: CreditCardRoutes,
        InviteFriends: 'invite',
        Articles: ArticleRoutes,
        GroupSale: GroupSaleRoutes,
        IdVerification: 'verification',
        Vouchers: 'vouchers',
        Claim: ClaimRoutes,
      },
    },
  },
};

const ForgotPasswordRoutes = {
  path: 'forgot-password',
  screens: {
    VerifyUserForm: 'verify',
    ResetPasswordForm: 'reset',
  },
};

const AuthRoutes = {
  screens: {
    AuthModal: 'auth',
    ForgotPasswordScreen: ForgotPasswordRoutes,
    WeChatRegisterScreen: 'activate/wechat',
  },
};

const ModalRoutes = {
  screens: {
    AuthNavigator: AuthRoutes,
    UserLocationModal: 'user-location',
    CustomerServiceModal: 'customer-service',
  },
};

const ProductNavigatorRoutes = {
  path: 'product',
  screens: {
    ProductDetailScreen: 'id/:productId',
    CommentScreen: 'id/:productId/comments',
    Cart: CartRoutes,
  },
};

export default {
  prefixes: [getAppStyle(process.env.EXPO_APP_TYPE)?.appWebsite],

  config: {
    screens: {
      Root: RootRoutes,
      Modal: ModalRoutes,
      ProductNavigator: ProductNavigatorRoutes,
      NoInternet: 'no-internet-connection',
    },
  },
};
