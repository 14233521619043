import React from 'react';
import { View, StyleSheet } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import StyledText from '../common/StyledText';
import ManuallyChooseRegionButton from '../common/ManuallyChooseRegionButton'

const NoContent = () => {
  const { t } = useTranslation();
  return (
    <View style={styles.root}>
      <AntDesign name='meh' size={80} color='lightgray' />
      <StyledText align='center' size={20}>
        {t('noContent')}
      </StyledText>
      <StyledText align='center'>
        {t('categoriesScreen.selectRegion')}
      </StyledText>
      <ManuallyChooseRegionButton />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default NoContent;
