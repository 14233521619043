import React, { useEffect } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import {
  OrderDetailType,
  CreditCardInList,
  SelectedPaymentType,
  PaymentMethod,
  PaymentData,
} from '../../types';
import GoodsInfo from '../Cart/orderPreviewSections/GoodsInfo';
import DeliveryInfo from './sections/DeliveryInfo';
import BasicInfo from './sections/BasicInfo';
import TotalInfo from '../Cart/orderPreviewSections/TotalInfo';
import PaymentInfo from '../Cart/orderPreviewSections/PaymentInfo';
import PickupAddressInfo from '../Cart/orderPreviewSections/PickupAddressInfo';
import CreditCardSelector from '../Cart/orderPreviewSections/CreditCardSelector';
import RemarkInfo from '../Cart/orderPreviewSections/RemarkInfo';
import OrderFormInfo from '../Cart/orderPreviewSections/OrderFormInfo';
import { backGroundLight } from '../../constants/Colors';

type OrderDetailProps = {
  data: OrderDetailType;
  paymentMethods?: Array<PaymentMethod>;
  selectedPayment: SelectedPaymentType;
  setSelectedPayment: React.Dispatch<React.SetStateAction<SelectedPaymentType>>;
  paymentData?: PaymentData;
  setCreditCard: Function;
  creditCard: CreditCardInList | Array<any>;
  CVC: string | undefined;
  setCVC: Function;
};

const OrderDetail = ({
  data,
  paymentData,
  paymentMethods = [],
  selectedPayment,
  setSelectedPayment,
  setCreditCard,
  creditCard,
  CVC,
  setCVC,
}: OrderDetailProps) => {
  if (Object.keys(data).length === 0) return <></>;

  useEffect(() => {
    if (data.pay_name && data.pay_id) {
      setSelectedPayment({
        name: data.pay_name,
        id: data.pay_id,
        description: data.pay_desc,
        code: data.pay_code,
      });
    }
  }, [data]);

  return (
    <ScrollView contentContainerStyle={[styles.container]}>
      <BasicInfo
        data={{
          orderNum: data.order_sn,
          orderStatus: data.formated_order_status,
          addTime: data.add_time,
        }}
      />
      {/* cac for pickup order */}
      {data.shipping_code === 'cac' ? (
        <PickupAddressInfo
          /** @ts-ignore */
          data={{ ...data.pickup_point }}
          contact={{ name: data.consignee, phone: data.mobile }}
        />
      ) : (
        <DeliveryInfo
          data={{
            address: data.address,
            postalCode: data.postal_code,
            unit: data.sign_building,
            contact: data.consignee,
            phone: data.mobile,
            bestTime: data.best_time,
          }}
        />
      )}
      <GoodsInfo
        orderType={data.rec_type}
        /** @ts-ignore */
        data={data.goods_list}
        number={data.total_item}
        pageType="detail"
        orderId={data.order_id}
        taskGoodsButtonEnabled={data.task_goods_button}
      />
      {data.buyer_remark ? <RemarkInfo remark={data.buyer_remark} /> : null}
      {data.form_value && data.form_value.length > 0 ? (
        <OrderFormInfo formValues={data.form_value} />
      ) : null}
      <TotalInfo total={data.total} />
      <PaymentInfo
        selectedPayment={selectedPayment}
        setSelectedPayment={setSelectedPayment}
        paymentMethods={paymentMethods.length > 0 ? paymentMethods : []}
        disabledEdit={!data.show_payment_list}
      >
        {selectedPayment.code === 'moneriscredit' && data.show_payment_list && (
          <CreditCardSelector
            cardList={paymentData?.card_list}
            cardInfo={creditCard}
            setCreditCard={setCreditCard}
            ruID={data.ru_id}
            CVC={CVC}
            setCVC={setCVC}
          />
        )}
      </PaymentInfo>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 10,
    paddingTop: 10,
    backgroundColor: backGroundLight,
  },
});

export default OrderDetail;
