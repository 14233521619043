import React from 'react';
import { Dimensions, Image, StyleSheet, Text, View } from 'react-native';
import { tintColorLight } from '../../../constants/Colors';
import { AdItem } from '../../../types';
import HomeLinkNavigator from '../HomeLinkNavigator';
import Swiper from 'react-native-web-swiper';

const { width } = Dimensions.get('window');

type HomeBannerProps = {
  data: Array<AdItem>;
  backgroundColor?: string;
  height: number;
};

const HomeBanner = ({
  data,
  backgroundColor = 'white',
  height = 0,
}: HomeBannerProps) => {
  const adjustedWidth = width - 20;

  const viewStyle =
    height === 0
      ? { width: adjustedWidth }
      : { width: adjustedWidth, height: height * 0.376 };

  return (
    <View
      style={{
        backgroundColor,
        width,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View style={[styles.container, viewStyle]}>
        <Swiper
          loop
          timeout={3.5}
          minDistanceForAction={0.1}
          controlsEnabled={data.length > 1}
          controlsProps={{
            prevPos: false,
            nextPos: false,
            dotsWrapperStyle: { transform: [{ translateY: 12 }] },
            DotComponent: ({ isActive }: any) => (
              <View style={[styles.dot, { opacity: isActive ? 1 : 0.3 }]} />
            ),
          }}
        >
          {data.map((item, index) => (
            <Slide key={index} item={item} />
          ))}
        </Swiper>
      </View>
    </View>
  );
};

const Slide = (props: any) => {
  const { item } = props;

  return (
    <View>
      <HomeLinkNavigator data={item}>
        <Image
          style={styles.imgStyle}
          source={{ uri: item.img }}
          resizeMode="cover"
        />
      </HomeLinkNavigator>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    marginVertical: 10,
    overflow: 'hidden',
    position: 'relative',
    height: (width - 20) * 0.376,
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 3,
    margin: 5,
    backgroundColor: tintColorLight,
  },
  imgStyle: {
    width: width - 20,
    height: (width - 20) * 0.376,
    backgroundColor: 'lightgray',
  },
});

export default HomeBanner;
