import queryString from 'query-string';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { getDataWithAuthToken } from './service';
import alert from '../utils/alert';
//@ts-ignore
import Toast from 'react-native-tiny-toast';
import { Platform } from 'react-native';
import * as Linking from 'expo-linking';

let WeChat: any;
try {
  WeChat = require('react-native-wechat-lib');
} catch {}

type navigateBaseOnUrlProps = {
  navigation: NavigationProp<ParamListBase>;
  type?: string;
  url_type: string;
  url?: string;
  id?: string | number;
  appId?: string;
  name?: string;
  title?: string;
};

/**
 *  Screen under User page, should setTimeout
 *  Otherwise the page cannot go to user profile page
 */
export const navigateBasedOnURL = ({
  navigation,
  type,
  url_type = 'inner',
  id,
  url = '',
  appId,
  name,
  title,
}: navigateBaseOnUrlProps) => {
  if (url_type === 'inner') {
    switch (type) {
      case 'category':
        navigation.navigate('Root', {
          screen: 'Home',
          params: { screen: 'ProductList', params: { catId: id } },
        });
        break;
      //For going to product detail
      case 'goods':
        navigation.navigate('ProductNavigator', {
          screen: 'ProductDetailScreen',
          params: { productId: id },
        });
        break;
      case 'webview':
        navigation.navigate('Root', {
          screen: 'Home',
          params: {
            screen: 'HomeWebviewScreen',
            params: { uri: url },
          },
        });
        break;
      case 'topic':
        navigation.navigate('Root', {
          screen: 'Home',
          params: { screen: 'ThemeHomeScreen', params: { topicId: id } },
        });
        break;
      case 'package':
        navigation.navigate('ProductBundle');
        break;
      case 'brand':
        if (id) {
          navigation.navigate('Brand', {
            screen: 'BrandDetail',
            params: { brandId: id },
          });
        } else {
          navigation.navigate('Brand');
        }
        break;
      //For going to article page
      case 'article':
        navigation.navigate('Root', {
          screen:
            navigation.dangerouslyGetState().routes[0].name === 'UserProfile'
              ? 'User'
              : 'Home',
          params: {
            screen: 'Articles',
            params: id
              ? { screen: 'ArticleScreen', params: { id: id } }
              : { screen: 'ArticleListScreen' },
          },
        });
        break;
      //For going to order detail
      case 'order':
        navigation.navigate('Root', {
          screen: 'User',
          params: { screen: 'UserProfile' },
        });
        // setTimeout is for navigate to the specific page instead of staying at Home page
        setTimeout(() => {
          navigation.navigate('Root', {
            screen: 'User',
            params: {
              screen: 'Order',
              params: {
                screen: 'OrderDetail',
                params: {
                  orderId: id,
                },
              },
            },
          });
        }, 100);
        break;
      //For balance screen
      case 'balance':
        navigation.navigate('Root', {
          screen: 'User',
          params: { screen: 'UserProfile' },
        });
        // setTimeout is for navigate to the specific page instead of staying at Home page
        setTimeout(() => {
          navigation.navigate('Root', {
            screen: 'User',
            params: {
              screen: 'Wallet',
              params: {
                screen: 'AccountScreen',
              },
            },
          });
        }, 100);
        break;
      //For going to coupon page
      case 'coupon':
        navigation.navigate('Root', {
          screen: 'User',
          params: { screen: 'UserProfile' },
        });
        // setTimeout is for navigate to the specific page instead of staying at Home page
        setTimeout(() => {
          navigation.navigate('Root', {
            screen: 'User',
            params: {
              screen: 'Wallet',
              params: {
                screen: 'CouponList',
              },
            },
          });
        }, 100);
        break;
      case 'pay_point':
        navigation.navigate('Root', {
          screen: 'User',
          params: { screen: 'UserProfile' },
        });
        // setTimeout is for navigate to the specific page instead of staying at Home page
        setTimeout(() => {
          navigation.navigate('Root', {
            screen: 'User',
            params: {
              screen: 'Wallet',
              params: {
                screen: 'PointScreen',
              },
            },
          });
        }, 100);
        break;
      case 'customer_service':
        navigation.navigate('Modal', {
          screen: 'CustomerServiceModal',
          params: { ruId: undefined },
        });
        break;
      case 'help-center':
        navigation.navigate('Root', {
          screen: 'User',
          params: { screen: 'UserProfile' },
        });
        setTimeout(() => {
          navigation.navigate('Root', {
            screen: 'User',
            params: {
              screen: 'HelpCenter',
              params: { screen: 'HelpCenterListScreen' },
            },
          });
        }, 100);
        break;
      // Only work for Eject App not web or expo client
      case 'miniprogram':
        if (WeChat) {
          WeChat.registerApp(
            'wx89bcdc631538d804',
            'https://luniumall.com/'
          ).then(() => {
            WeChat.launchMiniProgram({
              userName: appId,
              path: url,
            }).catch((error: Error) => console.log(error));
          });
        }
        break;
    }
  } else if (url_type === 'outer') {
    switch (type) {
      // For Youpi
      case 'authBrowser':
        const loading = Toast.showLoading('', { position: 0 });
        getDataWithAuthToken('home_link', { params: { name } })
          .then((response) => {
            Toast.hide(loading);
            if (response && response.goodStatus) {
              if (Platform.OS === 'web') {
                Linking.canOpenURL(response.data.url)
                  .then((supported) => {
                    supported && Linking.openURL(response.data.url);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                WebBrowser.openBrowserAsync(response.data.url);
              }
            } else {
              alert(`Error:${response?.return_code}`, response?.data || '');
            }
          })
          .catch((err) => {
            console.log(err);
            Toast.hide(loading);
          });
        break;
      default:
        WebBrowser.openBrowserAsync(url);
        break;
    }
  }
};

/**
 * Linking information from website
 * @param linkURL schemeURL luniumall://
 */
export const navigateBasedOnRoute = (linkURL: string) => {
  const query = linkURL.split('?')[1];
  if (query) {
    const info = queryString.parse(query);

    switch (info.type) {
      case 'product':
        return {
          route: 'ProductNavigator',
          options: {
            params: { productId: info.id },
            screen: 'ProductDetailScreen',
          },
        };

      default:
        return false;
    }
  }
  return false;
};
