import { useTranslation } from 'react-i18next';
import { Claim } from '../../../../types';
import StyledText from '../../../common/StyledText';
import WhiteCard from '../../../common/WhiteCard';
import { GREY2, accentColor } from '../../../../constants/Colors';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';

type BasicInfoProps = {
  claim: Claim;
};

const BasicInfo = ({ claim }: BasicInfoProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const route = useRoute();

  return (
    <WhiteCard title={t('order.orderInfo')}>
      <View style={styles.itemContainer}>
        <View style={styles.textContainer}>
          <StyledText color={GREY2}>{t('order.orderNumber')}: </StyledText>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('Claim', {
                screen: 'ClaimOrderDetail',
                params: { orderId: claim.order.order_id },
              });
            }}
            disabled={route && route.name === 'OrderClaimDetail'}
          >
            <StyledText
              style={{
                color:
                  route && route.name === 'OrderClaimDetail'
                    ? undefined
                    : accentColor,
              }}
            >
              {claim.order.order_sn}
            </StyledText>
          </TouchableOpacity>
        </View>
      </View>
    </WhiteCard>
  );
};

export default BasicInfo;

const styles = StyleSheet.create({
  itemContainer: {
    padding: 5,
  },
  textContainer: {
    flexDirection: 'row',
  },
});
