import { useHeaderHeight } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import EmptyDisplayBlock from '../../../components/common/EmptyDisplayBlock';
import FullWidthView from '../../../components/common/FullWidthView';
import Loading from '../../../components/common/Loading';
import StyledText from '../../../components/common/StyledText';
import MessageListItem from '../../../components/User/Messages/MessageListItem';
import { subTextColor } from '../../../constants/Colors';
import alert from '../../../utils/alert';
import { getDataWithAuthToken } from '../../../utils/service';

type MessageItem = {
  id: number;
  user_id: number;
  title: string;
  content: string;
  data: {
    type: string;
    id: string;
  };
  msg_type: string;
  send_time: string;
  read_time: string;
};

const MessageCenterScreen = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [messageList, setMessageList] = useState<MessageItem[]>([]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const result = await getMessages();
        if (result && result.goodStatus) {
          setMessageList(result.data);
        } else {
          console.log('Error when getting message list', result);
          alert('Error', result?.data);
        }
        setLoading(false);
      } catch (err) {
        console.log('Error when getting message list', err);
        setLoading(false);
      }
    })();
  }, []);

  const _renderItem = ({ item }: any) => {
    return <MessageListItem item={item} />;
  };

  return (
    <FullWidthView edges={['left', 'right', 'bottom']}>
      {loading ? (
        <Loading />
      ) : messageList.length > 0 ? (
        <ScrollView style={{ flex: 1 }}>
          <FlatList
            data={messageList}
            contentContainerStyle={{ backgroundColor: '#FFFF' }}
            renderItem={_renderItem}
            keyExtractor={(item: MessageItem) => item.id.toString()}
            ListFooterComponent={
              <StyledText
                align="center"
                size={12}
                color={subTextColor}
                style={{ marginVertical: 6 }}
              >
                {t('messageCenter.listFooterText')}
              </StyledText>
            }
          />
        </ScrollView>
      ) : (
        <EmptyDisplayBlock description={t('messageCenter.noMessage')} />
      )}
    </FullWidthView>
  );
};

export default MessageCenterScreen;

const getMessages = async () => {
  const result = await getDataWithAuthToken('message');
  return result;
};
