import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { MessageParamList } from '../../types';
import NavigateButton from '../../components/common/NavigateButton';
import BackButton from '../../components/common/iconButtons/BackButton';
import MessageCenterScreen from '../../screens/User/Messages/MessageCenterScreen';
import MessageDetailScreen from '../../screens/User/Messages/MessageDetailScreen';
import getAppStyle from '../../utils/getAppStyle';

const MessageStack = createStackNavigator<MessageParamList>();

const MessageNavigator = () => {
  const { t } = useTranslation();

  return (
    <MessageStack.Navigator
      initialRouteName="MessageCenter"
      screenOptions={{
        headerBackTitleVisible: false,
        headerLeft: () => (
          <NavigateButton goBack>
            <BackButton />
          </NavigateButton>
        ),
        headerTitleAlign: 'center',
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <MessageStack.Screen
        name="MessageCenter"
        component={MessageCenterScreen}
        options={{ title: t('messageCenter.title') }}
      />
      <MessageStack.Screen
        name="MessageDetail"
        component={MessageDetailScreen}
        options={{ title: t('messageCenter.detailTitle') }}
      />
    </MessageStack.Navigator>
  );
};

export default MessageNavigator;
