import { AntDesign } from '@expo/vector-icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlatList,
  Keyboard,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { useSelector } from 'react-redux';
import { normalTextColor } from '../../../constants/Colors';
import { RootState } from '../../../types';
import ListSeparator from '../list/ListSeparator';
import StyledText from '../StyledText';
import alert from '../../../utils/alert';

type MapBoxGeocoderProps = {
  country?: string;
  type?:
    | 'country'
    | 'region'
    | 'postcode'
    | 'district'
    | 'place'
    | 'locality'
    | 'neighborhood'
    | 'address'
    | 'poi';
  value?: string;
  language?: string;
  setAddressFound?: React.Dispatch<React.SetStateAction<boolean>>;
  setViewport?: React.Dispatch<
    React.SetStateAction<{
      latitude: number;
      longitude: number;
      zoom: number;
    }>
  >;
  setNewAddressPayload?: React.Dispatch<
    React.SetStateAction<
      | {
          name: string;
          location: { longitude: number; latitude: number };
        }
      | undefined
    >
  >;
};

const MapBoxGeocoder = ({
  country = 'ca',
  type = 'address',
  language = 'en',
  setAddressFound,
  setViewport,
  value,
  setNewAddressPayload,
}: MapBoxGeocoderProps) => {
  const [address, setAddress] = useState('');
  const [data, setData] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const mapboxToken = useSelector<RootState, string>(
    (state) => state.user.mapboxToken
  );

  const searchAddress = (address: string) => {
    const instance = axios.create();
    instance.defaults.withCredentials = false;
    instance
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json`,
        {
          params: {
            country: country || undefined,
            language: language || undefined,
            types: type || undefined,
            access_token: mapboxToken,
          },
        }
      )
      .then((response) => {
        if (response && response.data) {
          setData(response.data.features);
          setVisible(true);
        } else {
          console.log('No response');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChange = (address: string) => {
    if (typingTimeout) clearTimeout(typingTimeout);
    setAddress(address);
    if (address.length > 4) {
      setTypingTimeout(setTimeout(() => searchAddress(address), 500));
    } else if (address.length === 0) {
      setNewAddressPayload ? setNewAddressPayload(undefined) : undefined;
    }
  };

  const parseAddress = (item: any) => {
    let city,
      region,
      country = '';
    for (let e of item.context) {
      if (e.id.indexOf('place') !== -1) {
        city = e.text;
      } else if (e.id.indexOf('region') !== -1) {
        region = e.short_code
          .substring(e.short_code.lastIndexOf('-') + 1)
          .toUpperCase();
      } else if (e.id.indexOf('country') !== -1) {
        country = e.short_code.toUpperCase();
      }
    }
    return `${item.address ? item.address + ' ' : ''}${
      item.text ? item.text + ', ' : ''
    }${city ? city + ', ' : ''}${region ? region + ', ' : ''}${country || ''}`;
  };

  const renderItem = ({ item }: { item: any }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          setAddress(parseAddress(item));
          if (item.address && item.text) {
            if (setAddressFound) setAddressFound(true);
            if (setNewAddressPayload) {
              setNewAddressPayload({
                name: parseAddress(item),
                location: {
                  longitude: item.center[0],
                  latitude: item.center[1],
                },
              });
            }
          } else {
            if (setAddressFound) setAddressFound(false);
            if (setNewAddressPayload) setNewAddressPayload(undefined);
            alert(t('address.error'), t('address.invalidAddressAlert'));
          }
          setVisible(false);
          if (setViewport)
            setViewport({
              longitude: item.center[0],
              latitude: item.center[1],
              zoom: 13,
            });
          Keyboard.dismiss();
        }}
      >
        <StyledText style={styles.item}>{item.place_name}</StyledText>
      </TouchableOpacity>
    );
  };

  useEffect(() => {
    if (value) {
      setAddress(value);
    }
  }, [setAddress]);

  return (
    <View>
      <View style={styles.container}>
        <AntDesign
          name="search1"
          size={20}
          color="#acacac"
          style={styles.searchIcon}
        />
        <TextInput
          onChangeText={onChange}
          value={address}
          placeholder={t('address.info.inputAddress')}
          clearButtonMode="while-editing"
          style={styles.textInput}
        />
      </View>
      {visible && !!data.length && (
        <FlatList
          data={data}
          keyExtractor={(item) => item.id}
          renderItem={renderItem}
          ItemSeparatorComponent={() => <ListSeparator />}
          style={{ display: visible ? 'flex' : 'none' }}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 25,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  textInput: {
    flex: 1,
    paddingTop: 10,
    paddingRight: 0,
    paddingBottom: 10,
    paddingLeft: 0,
    backgroundColor: '#fff',
    color: normalTextColor,
  },
  item: {
    paddingVertical: 5,
    paddingHorizontal: 20,
  },
  searchIcon: {
    alignSelf: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10,
  },
});

export default MapBoxGeocoder;
