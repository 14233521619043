import React, { useEffect, useState } from 'react';
import FullWidthView from '../../../components/common/FullWidthView';
import StyledButton from '../../../components/common/StyledButton';
import { View, StyleSheet } from 'react-native';
import StyledText from '../../../components/common/StyledText';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { successGreen, warningRed } from '../../../constants/Colors';
import {
  OrderParamList,
  AdvertisementType,
  RedPacket,
  OrderResultAd,
} from '../../../types';
import { useTranslation } from 'react-i18next';
import ResponsiveImg from '../../../components/common/ResponsiveImg';
import { getAds } from '../../../utils/service';
import RedPacketPopup from '../../../components/Order/RedPacketPopup';
import HomeLinkNavigator from '../../../components/Home/HomeLinkNavigator';

type AdsItemProps = {
  item: OrderResultAd;
};

const OrderResultScreen = () => {
  const navigation = useNavigation<any>();
  const { t } = useTranslation();

  const route = useRoute<RouteProp<OrderParamList, 'OrderResult'>>();

  const { orderId, status, redPacket } = route.params;

  const [resultTitle, setResultTitle] = useState('');
  const [msg, setMsg] = useState('');
  const [goodStatus, setGoodStatus] = useState(false);
  const [ads, setAds] = useState<Array<OrderResultAd>>([]);
  const [redPacketPopupVisible, setRedPacketPopupVisible] = useState(false);

  useEffect(() => {
    let adsType: AdvertisementType = 'pay_success';
    switch (status) {
      case 'ORDER_SUCCESS':
        setResultTitle(t('order.orderResult.orderSuccess'));
        setGoodStatus(true);
        break;
      case 'PAY_SUCCESS':
        setResultTitle(t('order.orderResult.paySuccess'));
        setGoodStatus(true);
        break;
      default:
      case 'APP_PAY_FAIL':
        setResultTitle(t('order.orderResult.payFail'));
        setGoodStatus(false);
        const orderPreviewInRoute = navigation
          .dangerouslyGetState()
          .routes.findIndex((item: any) => {
            return item.name === 'OrderPreview';
          });
        if (orderPreviewInRoute !== -1) {
          setMsg(t('order.orderResult.checkOrderDetailToPay'));
        } else {
          setMsg('');
        }

        adsType = 'pay_fail';
        break;
    }

    //Get Ads based on result status
    getAds(adsType).then((result) => {
      if (result && result.goodStatus) {
        if (Array.isArray(result.data.ad)) {
          setAds(result.data.ad);
        }
      }
    });

    if (isRedPacketAvailable(redPacket)) {
      setRedPacketPopupVisible(true);
    }
  }, [orderId, status]);

  const isRedPacketAvailable = (
    redPacket: RedPacket | [] | undefined
  ): redPacket is RedPacket => {
    if (Array.isArray(redPacket)) {
      return false;
    } else {
      if (
        redPacket &&
        typeof redPacket['img'] == 'string' &&
        typeof redPacket['link'] == 'string'
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleCheckOrderDetail = () => {
    if (orderId !== -100) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: 'User',
            params: {
              screen: 'Order',
              params: {
                screen: 'OrderDetail',
                params: {
                  orderId: orderId,
                  type:
                    status === 'ORDER_SUCCESS' || status === 'PAY_SUCCESS'
                      ? 'awaitShip'
                      : 'awaitPay',
                },
              },
            },
          },
        ],
      });
    } else {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: 'User',
            params: {
              screen: 'Order',
              params: {
                screen: 'OrderList',
                params: { type: 'awaitPay' },
              },
            },
          },
        ],
      });
    }
  };

  const renderAdvertisement = ads.map((item, index) => {
    return item.banner ? (
      <AdsItem key={index} item={item} />
    ) : (
      <View key={index}></View>
    );
  });

  return (
    <FullWidthView>
      <View style={styles.container}>
        {goodStatus ? (
          <AntDesign name="checkcircle" size={80} color={successGreen} />
        ) : (
          <AntDesign name="closecircle" size={80} color={warningRed} />
        )}

        <StyledText align="center" size={30} style={{ marginBottom: 20 }}>
          {resultTitle}
        </StyledText>
        <StyledText align="center" style={{ marginBottom: 20 }}>
          {msg}
        </StyledText>
        <StyledButton
          onPress={handleCheckOrderDetail}
          label={t('order.orderResult.checkOrderDetail')}
        />
        {renderAdvertisement}
        {isRedPacketAvailable(redPacket) && (
          <RedPacketPopup
            modalVisible={redPacketPopupVisible}
            setModalVisible={setRedPacketPopupVisible}
            redPacket={redPacket}
            handleCheckOrderDetail={handleCheckOrderDetail}
          />
        )}
      </View>
    </FullWidthView>
  );
};

const AdsItem = ({ item }: AdsItemProps) => {
  return (
    <View style={styles.adItem}>
      <HomeLinkNavigator data={item}>
        <ResponsiveImg uri={item.banner} />
      </HomeLinkNavigator>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    // paddingVertical: 30,
  },
  adItem: {
    marginTop: 10,
  },
});

export default OrderResultScreen;
