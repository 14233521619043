import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { WalletParamList } from '../../types';
import { useTranslation } from 'react-i18next';
import { tintColorLight } from '../../constants/Colors';
import StyledText from '../../components/common/StyledText';
import NavigateButton from '../../components/common/NavigateButton';
import BackButton from '../../components/common/iconButtons/BackButton';
import AccountScreen from '../../screens/User/Wallet/AccountScreen';
import PointScreen from '../../screens/User/Wallet/Point/PointScreen';
import EarnPointsScreen from '../../screens/User/Wallet/Point/EarnPointsScreen';
import CouponListScreen from '../../screens/Coupon/CouponListScreen';
import CouponRedeemScreen from '../../screens/Coupon/CouponRedeemScreen';
import AddFundsScreen from '../../screens/User/Wallet/AddFundsScreen';
import getAppStyle from '../../utils/getAppStyle';

const WalletStack = createStackNavigator<WalletParamList>();

const WalletNavigator = () => {
  const { t } = useTranslation();

  return (
    <WalletStack.Navigator
      mode="card"
      screenOptions={{
        headerLeft: () => (
          <NavigateButton goBack>
            <BackButton />
          </NavigateButton>
        ),
        headerBackTitleVisible: false,
        headerTitleAlign: 'center',
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <WalletStack.Screen
        name="AccountScreen"
        component={AccountScreen}
        options={{
          headerTitle: t('userProfileScreen.myWallet.options.manage'),
        }}
      />
      <WalletStack.Screen
        name="PointScreen"
        component={PointScreen}
        options={{
          headerTitle: t('points.title'),
        }}
      />
      <WalletStack.Screen
        name="EarnPointsScreen"
        component={EarnPointsScreen}
        options={{
          headerTitle: t('points.earnPointsScreen.title'),
        }}
      />
      <WalletStack.Screen
        name="CouponList"
        component={CouponListScreen}
        options={{
          headerTitle: t('coupons.list.title'),
          gestureEnabled: false,
          headerRight: () => (
            <NavigateButton route="CouponRedeem" style={{ marginRight: 10 }}>
              <StyledText size={16} color={tintColorLight}>
                {t('order.coupon.redeemBtn')}
              </StyledText>
            </NavigateButton>
          ),
        }}
      />
      <WalletStack.Screen
        name="CouponRedeem"
        component={CouponRedeemScreen}
        options={{
          headerTitle: t('order.coupon.enterPromoCode'),
        }}
      />
      <WalletStack.Screen
        name="AddFundsScreen"
        component={AddFundsScreen}
        options={{
          headerTitle: '充值',
        }}
      />
    </WalletStack.Navigator>
  );
};

export default WalletNavigator;
