import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import FullWidthView from '../../components/common/FullWidthView';
import { Slider } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import { setAsyncStorageData } from '../../utils/asyncStorage';
import { backGroundLight } from '../../constants/Colors';
import StyledText from '../../components/common/StyledText';
import { RootState } from '../../types';

const FontSetting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fontSizeIncrement = useSelector<RootState, number>(
    (state) => state.user.fontSizeIncrement
  );

  const handleFontSizeChange = (size: number) => {
    setAsyncStorageData('@storage_FontSizeIncrement', size.toString());
    dispatch({
      type: 'CHANGE_FONT_SIZE',
      payload: size,
    });
  };

  return (
    <FullWidthView style={styles.rootContainer}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <StyledText>{t('userSettingScreen.fontScreen.sample')}</StyledText>
      </View>
      <Slider
        animateTransitions
        animationType="spring"
        maximumValue={6}
        minimumValue={0}
        step={2}
        value={fontSizeIncrement}
        onValueChange={handleFontSizeChange}
      />
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <StyledText size={14 - fontSizeIncrement}>
          {t('userSettingScreen.fontScreen.normal')}
        </StyledText>
        <StyledText size={20 - fontSizeIncrement}>
          {t('userSettingScreen.fontScreen.largest')}
        </StyledText>
      </View>
    </FullWidthView>
  );
};

export default FontSetting;

const styles = StyleSheet.create({
  rootContainer: {
    backgroundColor: backGroundLight,
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
});
