import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, TouchableWithoutFeedback } from 'react-native';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import StyledText from '../../common/StyledText';

type ClaimListHeaderProps = {
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  callback?: () => void;
};

const ClaimListHeader = ({
  currentIndex,
  setCurrentIndex,
  callback,
}: ClaimListHeaderProps) => {
  const { t } = useTranslation();
  const reviewStatuses = [
    t('order.claim.reviewStatus.all'),
    t('order.claim.reviewStatus.unreviewed'),
    t('order.claim.reviewStatus.pass'),
    t('order.claim.reviewStatus.fail'),
  ];

  const renderMenu = reviewStatuses.map((item, index) => {
    return (
      <MenuItem
        key={index}
        type={item}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        index={index}
        callback={callback}
      />
    );
  });

  return <View style={styles.menuContainer}>{renderMenu}</View>;
};

type MenuItemProps = {
  type: string;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  callback?: () => void;
};

const MenuItem = ({
  type,
  currentIndex,
  setCurrentIndex,
  index,
  callback,
}: MenuItemProps) => {
  const appMainColor = useSelector<RootState, string>(
    (state) => state.user.appMainColor
  );
  const handleIndexChange = () => {
    if (currentIndex !== index) {
      callback && callback();
      setCurrentIndex(index);
    }
  };

  const activeStyle =
    currentIndex === index
      ? [
          styles.activeMenu,
          {
            borderBottomColor: appMainColor,
          },
        ]
      : styles.inactiveMenu;

  return (
    <TouchableWithoutFeedback onPress={handleIndexChange}>
      <View style={[styles.menuItem, activeStyle]}>
        <StyledText bold={currentIndex === index}>{type}</StyledText>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  menuContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    justifyContent: 'center',
  },
  menuItem: {
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 25,
  },
  activeMenu: {
    borderBottomWidth: 3,
  },
  inactiveMenu: {
    borderBottomWidth: 3,
    borderBottomColor: 'white',
  },
});

export default ClaimListHeader;
