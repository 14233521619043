import React from 'react';
import { StyleSheet } from 'react-native';
import { Image } from 'react-native';

type WatermarkProps = {
  watermarkType: string;
  width?: number;
};

const Watermark = ({ watermarkType, width }: WatermarkProps) => {
  switch (watermarkType) {
    case 'watermark_hot':
      return (
        <Image
          source={require('../../../assets/images/watermark/hot.png')}
          style={[
            styles.watermark,
            width ? { width: width, height: width } : {},
          ]}
        />
      );
    case 'watermark_new':
      return (
        <Image
          source={require('../../../assets/images/watermark/new.png')}
          style={[
            styles.watermark,
            width ? { width: width, height: width } : {},
          ]}
        />
      );
    case 'watermark_sale':
    case 'watermark_promote':
      return (
        <Image
          source={require('../../../assets/images/watermark/sale.png')}
          style={[
            styles.watermark,
            width ? { width: width, height: width } : {},
          ]}
        />
      );
    case 'watermark_best':
      return (
        <Image
          source={require('../../../assets/images/watermark/best.png')}
          style={[
            styles.watermark,
            width ? { width: width, height: width } : {},
          ]}
        />
      );
    case 'watermark_farm':
      return (
        <Image
          source={require('../../../assets/images/watermark/farm.png')}
          style={[
            styles.watermark,
            width ? { width: width, height: width } : {},
          ]}
        />
      );
    default:
      return <></>;
  }
};

const styles = StyleSheet.create({
  watermark: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 40,
    height: 40,
  },
});

export default Watermark;
