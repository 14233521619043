import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Platform } from 'react-native';
import {
  HomeScreenDataType,
  RootState,
  LocationInformation,
} from '../../types';
import { resetWechatSharePage } from '../../utils/wechatWebMethods';
import { useSelector } from 'react-redux';
import { getData } from '../../utils/service';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import HomeBanner from '../Home/sections/HomeBanner';
import HomeNavigation from '../Home/sections/HomeNavigation';
import HorizontalGoodsList from '../Home/sections/HorizontalGoodsList';
import {
  StyleSheet,
  ActivityIndicator,
  View,
  Dimensions,
  RefreshControl,
  FlatList,
} from 'react-native';
import { normalTextColor, tintColorLight } from '../../constants/Colors';
import { useHeaderHeight } from '@react-navigation/stack';
import AdLayout from '../Home/sections/AdLayout';
import FixedGoodsList from '../Home/sections/FixedGoodsList';
import VerticalGoodsListMenu from '../Home/sections/VerticalGoodsListMenu';
import AdPopup from '../Home/sections/AdPopup';
import VerticalGoodsList from '../Home/sections/VerticalGoodsList';
import { useScrollToTop, useNavigation } from '@react-navigation/native';
import HorizontalBrandList from '../Home/sections/HorizontalBrandList';
import FixedBrandList from '../Home/sections/FixedBrandList';

type ThemeHomeScreenPageProps = {
  pageId?: any;
};

const { height, width } = Dimensions.get('window');

const ThemeHomeScreenPage = ({ pageId }: ThemeHomeScreenPageProps) => {
  const location = useSelector<RootState, LocationInformation>(
    (state) => state.goods.location
  );
  const navigation = useNavigation();
  const headerHeight = useHeaderHeight();

  const [pageTitle, setPageTitle] = useState('');
  const [pageData, setPageData] = useState<Array<HomeScreenDataType>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [verticalListCategories, setVerticalListCategories] = useState<
    Array<any>
  >([]);
  const [verticalListCategory, setVerticalListCategory] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);

  const homeScrollViewRef = useRef(null);
  const isSubscribed = useRef(true);

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  //When the tab press, scroll to top
  useScrollToTop(homeScrollViewRef);

  //Get data when this component mount
  useEffect(() => {
    (async () => {
      let userLocationPreference = location;
      setIsLoading(true);
      setVerticalListCategories([]);
      setVerticalListCategory(0);
      setModalVisible(false);
      getPageData(
        userLocationPreference.countryId,
        userLocationPreference.provinceId,
        userLocationPreference.cityId,
        pageId
      )
        .then((result) => {
          if (isSubscribed.current) {
            if (result && result.goodStatus && result.data) {
              setPageTitle(result.data.current_ad.title);
              setPageData(result.data.ad_detail);
              handleSetVerticalListCategories(result.data.ad_detail);
            } else {
              Toast.show(result.data);
            }
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (isSubscribed.current) {
            setIsLoading(false);
          }
          console.log(err);
        });
    })();
  }, [pageId]);

  // Setting Wechat share link
  React.useLayoutEffect(() => {
    // Set up Wechat share information when arguments change.
    if (Platform.OS === 'web') resetWechatSharePage(`${pageTitle}`);

    // Set up Wechat share information "again" when user come back from the other page.
    const unsubscribe = navigation.addListener('focus', () => {
      if (Platform.OS === 'web') resetWechatSharePage(`${pageTitle}`);
    });
    return unsubscribe;
  }, [pageTitle, navigation]);

  //Refresh when pull down
  const onRefresh = useCallback(() => {
    let userLocationPreference = location;
    setRefreshing(true);

    getPageData(
      userLocationPreference.countryId,
      userLocationPreference.provinceId,
      userLocationPreference.cityId,
      pageId
    )
      .then((result) => {
        if (isSubscribed.current) {
          if (result && result.goodStatus && result.data) {
            setPageData([]);
            setPageData(result.data.ad_detail);
            handleSetVerticalListCategories(result.data.ad_detail);
          } else {
            Toast.show(result.data);
          }
          setRefreshing(false);
        }
      })
      .catch((err) => {
        if (isSubscribed) {
          setRefreshing(false);
        }
        console.log(err);
      });
  }, []);

  const handleSetVerticalListCategories = (data: Array<HomeScreenDataType>) => {
    const verticalListCategories = data.find(
      (item) => item.tc_type === 'vertical_goods_list'
    );
    if (verticalListCategories) {
      setVerticalListCategories(verticalListCategories.ad);
    }
  };

  const renderPageElements = ({ item }: { item: HomeScreenDataType }) => {
    switch (item.tc_type) {
      case 'home_banner':
        return (
          <HomeBanner
            data={item.ad}
            backgroundColor={item.background_color}
            height={item.ad_height}
          />
        );
      case 'nav':
        return (
          <HomeNavigation
            data={item.ad}
            marginBottom={item.bottom_margin}
            marginHorizontal={item.lr_margin}
            backgroundColor={item.background_color}
          />
        );
      case 'three_ads':
      case 'two_ads':
      case 'one_ads':
        return (
          <AdLayout
            data={item.ad}
            marginBottom={item.bottom_margin}
            marginHorizontal={item.lr_margin}
            backgroundColor={item.background_color}
            tcType={item.tc_type}
            method={item.three_ads_method}
          />
        );
      case 'horizontal_goods_list':
        return (
          <HorizontalGoodsList
            data={item.ad[0]}
            marginBottom={item.bottom_margin}
            marginHorizontal={item.lr_margin}
            backgroundColor={item.background_color}
          />
        );
      case 'fixed_goods_list':
        return (
          <FixedGoodsList
            data={item.ad[0]}
            marginBottom={item.bottom_margin}
            marginHorizontal={item.lr_margin}
            backgroundColor={item.background_color}
          />
        );
      case 'vertical_goods_list':
        const categoryNamesArray = item.ad.map((item: any) => item.cat_name);
        return (
          <VerticalGoodsListMenu
            catNames={categoryNamesArray}
            verticalListCategory={verticalListCategory}
            setVerticalListCategory={setVerticalListCategory}
          />
        );
      case 'horizontal_brand_list':
        return (
          <HorizontalBrandList
            data={item.ad[0]}
            marginBottom={item.bottom_margin}
            marginHorizontal={item.lr_margin}
            backgroundColor={item.background_color}
          />
        );
      case 'fixed_brand_list':
        return (
          <FixedBrandList
            data={item.ad[0]}
            marginBottom={item.bottom_margin}
            marginHorizontal={item.lr_margin}
            backgroundColor={item.background_color}
          />
        );
      case 'popup':
        /* Ads Popup Modal */
        return (
          <AdPopup
            data={item.ad[0]}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
          />
        );
      default:
        return <></>;
    }
  };

  //Find verticalList Position in home, for sticky header when scroll to the bottom
  const verticalListPosition = pageData.findIndex(
    (item) => item.tc_type === 'vertical_goods_list'
  );

  return (
    <>
      {isLoading ? (
        <View
          style={{
            width,
            height: height - headerHeight - 200,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ActivityIndicator color={normalTextColor} />
        </View>
      ) : (
        <FlatList
          ref={homeScrollViewRef}
          data={pageData}
          renderItem={renderPageElements}
          keyExtractor={(item) => item.position_id.toString()}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.container}
          removeClippedSubviews={false}
          stickyHeaderIndices={[verticalListPosition]}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              colors={[tintColorLight]}
              tintColor={tintColorLight}
            />
          }
          ListFooterComponent={
            verticalListCategories.length > 0 ? (
              <VerticalGoodsList
                data={verticalListCategories[verticalListCategory]}
              />
            ) : (
              <></>
            )
          }
        />
      )}
    </>
  );
};

async function getPageData(
  countryId: number,
  provinceId: number,
  cityId: number,
  topicId?: number
) {
  let endpoint = `home?country_id=${countryId}&province_id=${provinceId}&city_id=${cityId}`;
  if (topicId) {
    endpoint = endpoint + `&topic_id=${topicId}`;
  }
  const result = await getData(endpoint);
  return result;
}

export default ThemeHomeScreenPage;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    position: 'relative',
  },
});
