import { useNavigation, useIsFocused } from '@react-navigation/native';
import React, { useState, useRef, useEffect } from 'react';
import {
  View,
  StyleSheet,
  Dimensions,
  Platform,
  TouchableOpacity,
} from 'react-native';
import { resetWechatSharePage } from '../../utils/wechatWebMethods';
import { useDispatch, useSelector } from 'react-redux';
import {
  CategoriesItem,
  LocationInformation,
  RootState,
  SubCategoryData,
} from '../../types';
import SubCategoriesList from '../../components/Category/container/SubCategoriesList';
import { backGroundLight } from '../../constants/Colors';
import ProductInCategory from '../../components/Category/container/ProductInCategory';
import LevelThreeCategoryWithSwipe from '../../components/Category/container/level3/LevelThreeCategoryWithSwipe';
import { getData } from '../../utils/service';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import FullWidthView from '../../components/common/FullWidthView';
import Loading from '../../components/common/Loading';
import ScrollableHeader from '../../components/Category/ScrollableHeader';
import NavigateButton from '../../components/common/NavigateButton';
import BackButton from '../../components/common/iconButtons/BackButton';
import TutorialPopup from '../../components/Category/TutorialPopup';
import { getAsyncStorageData } from '../../utils/asyncStorage';
import CachedImage from '../../components/common/cache-img/CachedImage';
import StyledText from '../../components/common/StyledText';
import { Feather } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { screenHit } from '../../utils/analytic';
import GoodsActionToolbar from '../../components/Category/GoodsActionToolbar';
import { useHeaderHeight } from '@react-navigation/stack';

const { width } = Dimensions.get('window');

const SubCategoryScreen = ({ route }: any) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const isFocused = useIsFocused();

  const { t } = useTranslation();
  const [categoryId, setCategoryId] = useState();
  const [data, setData] = useState<Array<SubCategoryData>>([]);
  const [levels, setLevels] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [horizontalIndex, setHorizontalIndex] = useState(0);
  const levelThreeCategoryRef = useRef<any>(null);
  const locationRef = useRef<any>(null);
  const [numOfSelectedFilter, setNumOfSelectedFilter] = useState<string>('0');
  const [showFilterPopUp, setShowFilterPopUp] = useState<boolean>(false);
  const [categoryChangeState, setCategoryChangeState] =
    useState<boolean>(false);
  const [selectedSorter, setSelectedSorter] = useState<{
    sort_key?: string;
    sort_value?: string;
  }>({});
  const selectedSubCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedSubCategoryIndex
  );

  const categoriesArray = useSelector<RootState, Array<CategoriesItem>>(
    (state) => state.goods.categoriesArray
  );

  const selectedMainCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedMainCategoryIndex
  );

  const location = useSelector<RootState, LocationInformation>(
    (state) => state.goods.location
  );

  const resetHorizontalIndex = () => {
    setHorizontalIndex(0);
    if (levelThreeCategoryRef.current) {
      levelThreeCategoryRef.current.resetHorizontalPage();
    }
  };

  const updateSubCategoryChange = () => {
    categoryChangeState
      ? setCategoryChangeState(false)
      : setCategoryChangeState(true);
  };

  // Setting Wechat share link
  React.useLayoutEffect(() => {
    let shareLinkTitle: string;
    const level1Title = categoriesArray[selectedMainCategoryIndex]?.name;
    const level2Title = data[selectedSubCategoryIndex]?.name;
    const level3Title =
      data[selectedSubCategoryIndex]?.cat_id?.[horizontalIndex]?.name;

    if (!level3Title) {
      shareLinkTitle = level2Title
        ? `${level1Title} | ${level2Title}`
        : `${level1Title}`;
    } else {
      shareLinkTitle = `${level2Title} | ${level3Title}`;
    }
    const imgUrl =
      categoriesArray[selectedMainCategoryIndex]?.cat_img ?? undefined;
    const link =
      Platform.OS === 'web'
        ? `${
            window.location.href.split('?')[0]
          }?mainIndex=${selectedMainCategoryIndex}&subIndex=${selectedSubCategoryIndex}`
        : '';

    /*Because we are subscripting states from redux. We need to check if screen is focused.
      Otherwise this will be called even we are not in this screen  */
    if (Platform.OS === 'web' && isFocused)
      resetWechatSharePage(shareLinkTitle, undefined, imgUrl, link);

    // Set up Wechat share information "again" when user come back from the other page.
    const unsubscribe = navigation.addListener('focus', () => {
      if (Platform.OS === 'web')
        resetWechatSharePage(shareLinkTitle, undefined, imgUrl, link);
    });
    return unsubscribe;
  }, [
    data,
    levels,
    selectedMainCategoryIndex,
    selectedSubCategoryIndex,
    horizontalIndex,
    navigation,
  ]);

  useEffect(() => {
    if (
      locationRef.current &&
      locationRef.current !== location.postalCodePrefix
    ) {
      locationRef.current = location.postalCodePrefix;
      navigation.navigate('Category', { screen: 'Categories' });
    } else {
      locationRef.current = location.postalCodePrefix;
      const { catId } = route.params;
      screenHit(`category/${catId}`);
      setCategoryId(catId);
      setLoading(true);
      resetHorizontalIndex();
      getData(`category/detail?id=${catId}`)
        .then((result) => {
          if (result) {
            if (result.goodStatus) {
              setData(result.data);
              setLevels(calculateLevels(result.data));
              setLoading(false);
              handleDisplayTutorial();
            } else {
              throw result.data;
            }
          } else {
            console.log('error');
          }
        })
        .catch((err) => {
          setLoading(false);
          Toast.show('err', {
            position: 0,
            duration: 2000,
          });
        });
    }
  }, [route, location]);

  const handleDisplayTutorial = async () => {
    const result = await getAsyncStorageData(
      '@storage_FinishedCategoryTutorial'
    );
    if (!result) {
      setModalVisible(true);
    }
  };

  const calculateLevels = (data: Array<any>) => {
    if (data.length > 0) {
      let atleastOneHasChildren = false;
      data.forEach((item: SubCategoryData) => {
        if (item.haschild === 1) {
          atleastOneHasChildren = true;
        }
      });
      return atleastOneHasChildren ? 3 : 2;
    } else {
      return 1;
    }
  };

  const renderContent = () => {
    //Has sub category show left-right layout
    if (levels === 3) {
      dispatch({ type: 'UPDATE_SUBCATEGORY_LENGTH', payload: data.length });
      return (
        <>
          <View style={styles.leftContainer}>
            <SubCategoriesList
              resetHorizontalIndex={resetHorizontalIndex}
              data={data}
              updateSubCategoryChange={updateSubCategoryChange}
            />
          </View>
          <View style={styles.rightContainer}>
            {/* Check Level three Category if exist */}
            {data[selectedSubCategoryIndex].haschild === 1 &&
            data[selectedSubCategoryIndex].cat_id &&
            Array.isArray(data[selectedSubCategoryIndex].cat_id) ? (
              <LevelThreeCategoryWithSwipe
                ref={levelThreeCategoryRef}
                horizontalIndex={horizontalIndex}
                setHorizontalIndex={setHorizontalIndex}
                width={width * 0.78 - 10}
                categoryChangeState={categoryChangeState}
                updateSubCategoryChange={updateSubCategoryChange}
                levelThreeCategories={
                  data[selectedSubCategoryIndex].cat_id || []
                }
                listHeader={
                  data[selectedSubCategoryIndex].banner.length > 0 ? (
                    <View style={{ marginVertical: 5 }}>
                      <TouchableOpacity
                        {...(data[selectedSubCategoryIndex].banner[0]
                          .cat_banner_link !== '' && {
                          onPress: () =>
                            navigation.navigate(
                              data[selectedSubCategoryIndex].banner[0]
                                .cat_banner_link
                            ),
                        })}
                      >
                        <CachedImage
                          style={{
                            width: width * 0.78 - 10,
                            height: (width * 0.78 - 10) / 3,
                            borderRadius: 10,
                          }}
                          source={{
                            uri: data[selectedSubCategoryIndex].banner[0]
                              .cat_banner,
                          }}
                        />
                      </TouchableOpacity>
                    </View>
                  ) : (
                    <></>
                  )
                }
                nextCategoryName={
                  data[selectedSubCategoryIndex + 1]
                    ? data[selectedSubCategoryIndex + 1].name
                    : categoriesArray[selectedMainCategoryIndex + 1]
                    ? categoriesArray[selectedMainCategoryIndex + 1].name
                    : categoriesArray[0].name
                }
              />
            ) : (
              <>
                <View style={styles.toolbarContainer}>
                  <GoodsActionToolbar
                    numOfSelectedFilter={numOfSelectedFilter}
                    setShowFilterPopUp={setShowFilterPopUp}
                    leftSideMenuChangeState={categoryChangeState}
                    setSelectedSorter={setSelectedSorter}
                  />
                </View>
                <ProductInCategory
                  selectedSorter={selectedSorter}
                  catId={data[selectedSubCategoryIndex].id}
                  goodsType={
                    data[selectedSubCategoryIndex].goods_type !== undefined
                      ? data[selectedSubCategoryIndex].goods_type
                      : 'all'
                  }
                  listHeader={
                    data[selectedSubCategoryIndex].banner.length > 0 ? (
                      <View style={{ marginTop: 5 }}>
                        <TouchableOpacity
                          {...(data[selectedSubCategoryIndex].banner[0]
                            .cat_banner_link !== '' && {
                            onPress: () =>
                              navigation.navigate(
                                data[selectedSubCategoryIndex].banner[0]
                                  .cat_banner_link
                              ),
                          })}
                        >
                          <CachedImage
                            style={{
                              width: width * 0.78 - 10,
                              height: (width * 0.78 - 10) / 3,
                              borderRadius: 10,
                            }}
                            source={{
                              uri: data[selectedSubCategoryIndex].banner[0]
                                .cat_banner,
                            }}
                          />
                        </TouchableOpacity>
                      </View>
                    ) : (
                      <></>
                    )
                  }
                  nextCategoryName={
                    data[selectedSubCategoryIndex + 1]
                      ? data[selectedSubCategoryIndex + 1].name
                      : categoriesArray[selectedMainCategoryIndex + 1]
                      ? categoriesArray[selectedMainCategoryIndex + 1].name
                      : categoriesArray[0].name
                  }
                />
              </>
            )}
          </View>
        </>
      );
    }
    //No sub category, show full width layout
    else if (levels === 2) {
      dispatch({ type: 'UPDATE_SUBCATEGORY_LENGTH', payload: data.length });
      return (
        <>
          <View style={styles.leftContainer}>
            <SubCategoriesList
              resetHorizontalIndex={resetHorizontalIndex}
              data={data}
              updateSubCategoryChange={updateSubCategoryChange}
            />
          </View>
          <View style={styles.rightContainer}>
            <View style={styles.toolbarContainer}>
              <GoodsActionToolbar
                leftSideMenuChangeState={categoryChangeState}
                numOfSelectedFilter={numOfSelectedFilter}
                setShowFilterPopUp={setShowFilterPopUp}
                setSelectedSorter={setSelectedSorter}
              />
            </View>
            <ProductInCategory
              selectedSorter={selectedSorter}
              catId={data[selectedSubCategoryIndex].id}
              goodsType={
                data[selectedSubCategoryIndex].goods_type !== undefined
                  ? data[selectedSubCategoryIndex].goods_type
                  : 'all'
              }
              listHeader={
                data[selectedSubCategoryIndex].banner.length > 0 ? (
                  <View style={{ marginTop: 5 }}>
                    <TouchableOpacity
                      {...(data[selectedSubCategoryIndex].banner[0]
                        .cat_banner_link !== '' && {
                        onPress: () =>
                          navigation.navigate(
                            data[selectedSubCategoryIndex].banner[0]
                              .cat_banner_link
                          ),
                      })}
                    >
                      <CachedImage
                        style={{
                          width: width * 0.78 - 10,
                          height: (width * 0.78 - 10) / 3,
                          borderRadius: 10,
                        }}
                        source={{
                          uri: data[selectedSubCategoryIndex].banner[0]
                            .cat_banner,
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                ) : (
                  <></>
                )
              }
              nextCategoryName={
                data[selectedSubCategoryIndex + 1]
                  ? data[selectedSubCategoryIndex + 1].name
                  : categoriesArray[selectedMainCategoryIndex + 1]
                  ? categoriesArray[selectedMainCategoryIndex + 1].name
                  : categoriesArray[0].name
              }
            />
          </View>
        </>
      );
    }
    //Empty data show plain Product List
    else {
      dispatch({ type: 'UPDATE_SUBCATEGORY_LENGTH', payload: 0 });

      return levels === 1 ? (
        <View style={{ width: '100%' }}>
          <View style={styles.toolbarContainer}>
            <GoodsActionToolbar
              leftSideMenuChangeState={categoryChangeState}
              numOfSelectedFilter={numOfSelectedFilter}
              setShowFilterPopUp={setShowFilterPopUp}
              setSelectedSorter={setSelectedSorter}
            />
          </View>
          <ProductInCategory
            selectedSorter={selectedSorter}
            catId={categoryId || -1}
            nextCategoryName={
              data[selectedSubCategoryIndex + 1]
                ? data[selectedSubCategoryIndex + 1].name
                : categoriesArray[selectedMainCategoryIndex + 1]
                ? categoriesArray[selectedMainCategoryIndex + 1].name
                : categoriesArray[0].name
            }
            listHeader={
              categoriesArray[selectedMainCategoryIndex]?.banner.length > 0 ? (
                <View
                  style={{
                    marginTop: 5,
                    alignItems: 'center',
                  }}
                >
                  <TouchableOpacity
                    {...(categoriesArray[selectedMainCategoryIndex].banner[0]
                      .cat_banner_link !== '' && {
                      onPress: () =>
                        navigation.navigate(
                          categoriesArray[selectedMainCategoryIndex].banner[0]
                            .cat_banner_link
                        ),
                    })}
                  >
                    <CachedImage
                      style={{
                        width: width - 10,
                        height: (width - 10) / 3,
                        borderRadius: 10,
                      }}
                      source={{
                        uri: categoriesArray[selectedMainCategoryIndex]
                          .banner[0].cat_banner,
                      }}
                    />
                  </TouchableOpacity>
                </View>
              ) : (
                <></>
              )
            }
          />
        </View>
      ) : (
        <></>
      );
    }
  };

  const styles = StyleSheet.create({
    rootContainer: {
      flexDirection: 'column',
      position: 'relative',
      paddingBottom: Platform.OS === 'web' ? useHeaderHeight() + 50 : 0,
    },
    webHeader: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 5,
      borderBottomColor: 'lightgray',
      borderBottomWidth: 0.5,
      position: 'absolute',
      zIndex: 999,
      width: width,
      backgroundColor: 'white',
    },
    mobileHeader: {
      position: 'absolute',
      zIndex: 999,
      width: width,
      backgroundColor: 'white',
    },
    leftContainer: {
      backgroundColor: backGroundLight,
      width: width * 0.22,
    },
    rightContainer: {
      width: width * 0.78 - 10,
      marginHorizontal: 5,
    },
    toolbarContainer: {
      paddingBottom: 3,
    },
  });

  return (
    <>
      <FullWidthView edges={['left', 'right']}>
        {Platform.OS === 'web' ? (
          <View style={styles.webHeader}>
            <NavigateButton route="Categories">
              <BackButton />
            </NavigateButton>
            <ScrollableHeader />
          </View>
        ) : (
          <View style={styles.mobileHeader}>
            <ScrollableHeader />
          </View>
        )}

        {/* dummy scrollableHeader to fill space under real scrollable header */}
        <View style={{ paddingVertical: 5, flexDirection: 'row' }}>
          <View style={{ flexDirection: 'column' }}>
            <View
              style={
                Platform.OS === 'web'
                  ? { width: 32, height: 32 }
                  : { width: 50, height: 50 }
              }
            />
            <StyledText style={{ paddingBottom: 2 }} bold size={10}>
              {' '}
            </StyledText>
          </View>
          <View style={{ flexDirection: 'column' }}>
            <StyledText size={14}>
              {t('categoriesScreen.dropdownButtonLine1')}
            </StyledText>
            <StyledText size={14}>
              {t('categoriesScreen.dropdownButtonLine2')}
            </StyledText>
            <Feather name="menu" size={14} color="black" />
          </View>
        </View>

        <View style={{ flexDirection: 'row', flex: 1 }}>
          {loading ? <Loading /> : <>{renderContent()}</>}
        </View>
        {levels > 0 && (
          <TutorialPopup
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            data={data}
            levels={levels}
          />
        )}
      </FullWidthView>
      {/* <ProductFilterPopup 
        setNumOfSelectedFilter={setNumOfSelectedFilter} 
        selectedFilter = {selectedFilter} 
        setSelectedFilter={setSelectedFilter} 
        showFilterPopUp={showFilterPopUp} 
        setShowFilterPopUp={setShowFilterPopUp}
      /> */}
    </>
  );
};

export default SubCategoryScreen;
