import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, TouchableWithoutFeedback } from 'react-native';
import { tintColorLight } from '../../constants/Colors';
import getAppStyle from '../../utils/getAppStyle';
import StyledText from '../common/StyledText';

type CouponListHeaderProps = {
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
};

const couponTypes = ['unused', 'used', 'expired'];

const CouponListHeader = ({
  currentIndex,
  setCurrentIndex,
}: CouponListHeaderProps) => {
  const renderMenu = couponTypes.map((item, index) => {
    return (
      <MenuItem
        key={index}
        type={item}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        index={index}
      />
    );
  });

  return <View style={styles.menuContainer}>{renderMenu}</View>;
};

type MenuItemProps = {
  type: string;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
};

const MenuItem = ({
  type,
  currentIndex,
  setCurrentIndex,
  index,
}: MenuItemProps) => {
  const { t } = useTranslation();

  const handleIndexChange = () => {
    setCurrentIndex(index);
  };

  const activeStyle =
    currentIndex === index ? styles.activeMenu : styles.inactiveMenu;

  return (
    <TouchableWithoutFeedback onPress={handleIndexChange}>
      <View style={[styles.menuItem, activeStyle]}>
        <StyledText bold={currentIndex === index}>
          {t('coupons.list.' + type)}
        </StyledText>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  menuContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    justifyContent: 'center',
  },
  menuItem: {
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 25,
  },
  activeMenu: {
    borderBottomWidth: 3,
    borderBottomColor: getAppStyle(process.env.EXPO_APP_TYPE)?.mainColor,
  },
  inactiveMenu: {
    borderBottomWidth: 3,
    borderBottomColor: 'white',
  },
});

export default CouponListHeader;
