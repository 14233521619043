import React from 'react';
import { Platform, Modal, View } from 'react-native';
import WhiteCard from '../../common/WhiteCard';
import { CheckBox, Tooltip } from 'react-native-elements';
import { normalTextColor } from '../../../constants/Colors';
import { AntDesign } from '@expo/vector-icons';
import alert from '../../../utils/alert';
import StyledText from '../../common/StyledText';
import { useTranslation } from 'react-i18next';
import { useMount } from 'ahooks';

type ApartmentDeliveryProps = {
  addDelivery: boolean;
  setAddDelivery: Function;
  deliveryText: string;
  deliveryFee: number;
};

const ApartmentDelivery = ({
  addDelivery,
  setAddDelivery,
  deliveryText = '',
  deliveryFee = 0,
}: ApartmentDeliveryProps) => {
  const { t } = useTranslation();

  useMount(() => {
    if (!addDelivery) {
      alert(
        Platform.OS === 'android' ? '' : deliveryText,
        Platform.OS === 'android' ? deliveryText : '',
        [
          { text: t('cancel'), style: 'cancel' },
          {
            text: t('order.apartmentDelivery.addForDeliveryFee', {
              deliveryFee,
            }),
            onPress: () => {
              setAddDelivery(true);
            },
          },
        ]
      );
    }
  });

  const handleAddApartmentDelivery = () => {
    if (!addDelivery) {
      alert(deliveryText, '', [
        { text: t('cancel'), style: 'cancel' },
        {
          text: t('order.apartmentDelivery.addForDeliveryFee', { deliveryFee }),
          onPress: () => {
            setAddDelivery(true);
          },
        },
      ]);
    } else {
      setAddDelivery(false);
    }
  };

  return (
    <WhiteCard
      title={t('order.apartmentDelivery.apartmentDelivery')}
      icon={
        Platform.OS === 'web'
          ? {
              iconComponent: (
                <AntDesign name="questioncircleo" size={14} color="lightgray" />
              ),
              handleActionPress: () => alert(deliveryText),
            }
          : {
              iconComponent: (
                <Tooltip
                  ModalComponent={Modal}
                  popover={
                    <View style={{ backgroundColor: 'black' }}>
                      <StyledText color="white">{deliveryText}</StyledText>
                    </View>
                  }
                  backgroundColor="black"
                  width={240}
                  height={100}
                >
                  <AntDesign
                    name="questioncircleo"
                    size={14}
                    color="lightgray"
                  />
                </Tooltip>
              ),
            }
      }
    >
      <CheckBox
        size={18}
        textStyle={{
          fontSize: 14,
          fontWeight: 'normal',
          color: normalTextColor,
          paddingBottom: 2,
        }}
        fontFamily={'noto-sans'}
        title={t('order.apartmentDelivery.checkboxLabel')}
        checked={addDelivery}
        checkedIcon={<AntDesign name="checkcircle" size={20} color="#2cae35" />}
        uncheckedIcon={
          <AntDesign name="checkcircle" size={20} color={'lightgray'} />
        }
        containerStyle={{
          backgroundColor: 'white',
          borderWidth: 0,
          marginLeft: 0,
          padding: 0,
          paddingTop: 5,
        }}
        onPress={handleAddApartmentDelivery}
      />
    </WhiteCard>
  );
};

export default ApartmentDelivery;
