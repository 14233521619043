import React from 'react';
import { View } from 'react-native';

type ListSeparatorProps = {
  direction?: 'horizontal' | 'vertical';
};

const ListSeparator = ({ direction = 'horizontal' }: ListSeparatorProps) => {
  if (direction === 'vertical') {
    return (
      <View
        style={{ width: 1, height: '100%', backgroundColor: 'lightgray' }}
      />
    );
  }

  return (
    <View style={{ height: 1, width: '100%', backgroundColor: 'lightgray' }} />
  );
};

export default ListSeparator;
