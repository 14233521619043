import React from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import Swiper from 'react-native-web-swiper';
import { tintColorLight } from '../../constants/Colors';
import ResponsiveImg from '../common/ResponsiveImg';

const { width } = Dimensions.get('window');

type BundleCarouselProps = {
  data: Array<{ banner: string; link: string }>;
};

const BundleCarousel = ({ data }: BundleCarouselProps) => {
  return data.length > 0 ? (
    <View style={styles.container}>
      <Swiper
        loop
        timeout={4}
        minDistanceForAction={0.1}
        controlsEnabled={data.length > 1}
        controlsProps={{
          prevPos: false,
          nextPos: false,
          dotsWrapperStyle: { transform: [{ translateY: 5 }] },
          DotComponent: ({ isActive }: any) => (
            <View style={[styles.dot, { opacity: isActive ? 1 : 0.3 }]} />
          ),
        }}
      >
        {data.map((item, index) => (
          <Slide key={index} item={item} />
        ))}
      </Swiper>
    </View>
  ) : (
    <></>
  );
};

const Slide = ({ item }: any) => {
  return (
    <View>
      <ResponsiveImg uri={item.banner} width={width} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: width,
    height: width * 0.32,
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 3,
    margin: 5,
    backgroundColor: tintColorLight,
  },
});

export default BundleCarousel;
