import { useNavigation } from '@react-navigation/native';
import { useHeaderHeight } from '@react-navigation/stack';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  Platform,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import FullWidthView from '../../components/common/FullWidthView';
import StyledText from '../../components/common/StyledText';
import CouponList from '../../components/Coupon/CouponList';
import { backGroundLight, warningRed } from '../../constants/Colors';
import { addCoupon } from '../../utils/service';

const { width } = Dimensions.get('window');

const CouponPromoScreen = ({ route }: any) => {
  const { ruId } = route.params ?? { ruId: undefined };
  const couponListRef = useRef<any>();
  const navigation = useNavigation();
  const [couponCode, setCouponCode] = useState('');
  const { t } = useTranslation();

  const handlePressRedeem = () => {
    const loading = Toast.showLoading('');
    addCoupon(couponCode)
      .then((result) => {
        Toast.hide(loading);
        setCouponCode('');
        if (result && result.goodStatus) {
          Toast.showSuccess(t('order.coupon.createCouponSuccess'));
          couponListRef?.current?.refreshList();
        } else {
          Toast.show(result && result.data, {
            position: 0,
          });
        }
      })
      .catch((err) => {
        Toast.hide(loading);
        setCouponCode('');
        console.log('caught error', err);
      });
  };

  const handlePressNoCoupon = () => {
    navigation.navigate('OrderPreview', { selectedCoupon: -1 });
  };

  return (
    <FullWidthView>
      <View style={styles.main}>
        <CouponList
          ref={couponListRef}
          couponType={'unused'}
          onSelectCouponRoute={'OrderPreview'}
          ruId={ruId}
          listHeader={
            <View style={styles.addCoupon}>
              <View
                style={[
                  styles.input,
                  Platform.OS === 'android' ? { padding: 5 } : { padding: 10 },
                ]}
              >
                <TextInput
                  placeholder={t('order.coupon.enterPromoCode')}
                  value={couponCode}
                  onChangeText={(text) => setCouponCode(text)}
                  placeholderTextColor={'lightgray'}
                />
              </View>
              <TouchableOpacity
                onPress={handlePressRedeem}
                style={styles.redeemBtn}
              >
                <StyledText align={'center'} size={12} bold color={'white'}>
                  {t('order.coupon.redeemBtn')}
                </StyledText>
              </TouchableOpacity>
            </View>
          }
        />
      </View>
      <TouchableOpacity onPress={handlePressNoCoupon}>
        <View style={styles.footer}>
          <StyledText color={warningRed} size={16} align={'center'}>
            {t('order.coupon.doNotUseCouponBtn')}
          </StyledText>
        </View>
      </TouchableOpacity>
    </FullWidthView>
  );
};

const styles = StyleSheet.create({
  header: {
    padding: 15,
  },
  addCoupon: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: width * 0.8,
    marginTop: 15,
    marginBottom: 5,
    alignSelf: 'center',
  },
  input: {
    backgroundColor: 'white',
    flex: 3,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  redeemBtn: {
    backgroundColor: '#2cae66',
    padding: 10,
    flex: 1,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  main: {
    flex: 1,
    backgroundColor: backGroundLight,
    alignItems: 'center',
  },
  footer: {
    padding: 15,
  },
});

export default CouponPromoScreen;
