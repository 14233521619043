import React, { useEffect, useState } from 'react';
import { View, FlatList, StyleSheet, TouchableOpacity } from 'react-native';
import { getData } from '../../../utils/service';
import FullWidthView from '../../../components/common/FullWidthView';
import Loading from '../../../components/common/Loading';
import Accordion from 'react-native-collapsible/Accordion';
import StyledText from '../../../components/common/StyledText';
import { backGroundLight } from '../../../constants/Colors';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';

type ArticleLinkItem = {
  title: string;
  link: string;
};

type HelpCenterItem = {
  id: number;
  cat_name: string;
  keywords: string;
  article_link: Array<ArticleLinkItem>;
  child?: Array<HelpCenterItem>;
};

const HelpCenterListScreen = () => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  const [helpCenterList, setHelpCenterList] = useState<any>([]);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [isError, setIsError] = useState(false);
  const [activeSections, setActiveSections] = useState<Array<number>>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getHelpCenterData()
      .then((result) => {
        if (result && result.goodStatus) {
          setHelpCenterList(result.data[0].child);
        } else {
          console.log(result);
        }

        setIsLoadingComplete(true);
      })
      .catch((err) => {
        setIsError(true);
      });
  };

  const handleChildPress = (item: ArticleLinkItem) => {
    navigation.navigate('HelpCenterItemScreen', {
      title: item.title,
      uri: item.link,
    });
  };

  const _renderHeader = (
    content: HelpCenterItem,
    index: number,
    isActive: boolean,
    sections: HelpCenterItem[]
  ) => {
    return (
      <View style={styles.header}>
        <StyledText size={15}>{content.cat_name}</StyledText>
        {isActive ? (
          <AntDesign name="up" size={18} color="gray" />
        ) : (
          <AntDesign name="down" size={18} color="gray" />
        )}
      </View>
    );
  };

  const _renderSectionChildren = ({ item }: { item: ArticleLinkItem }) => {
    return (
      <View style={styles.childContainer}>
        <TouchableOpacity onPress={() => handleChildPress(item)}>
          <StyledText>{item.title}</StyledText>
        </TouchableOpacity>
      </View>
    );
  };

  const _renderContent = (
    content: HelpCenterItem,
    index: number,
    isActive: boolean,
    sections: HelpCenterItem[]
  ) => {
    return (
      <View>
        <FlatList
          data={content.article_link}
          keyExtractor={(item, index) => index.toString()}
          renderItem={_renderSectionChildren}
        />
      </View>
    );
  };

  return isError ? (
    <FullWidthView style={styles.errorContainer}>
      <MaterialIcons name="error" size={24} color="lightgray" />
      <StyledText color={'lightgray'}>Failed to retrieve page</StyledText>
    </FullWidthView>
  ) : (
    <FullWidthView style={{ backgroundColor: backGroundLight }}>
      {!isLoadingComplete ? (
        <View style={styles.loadingContainer}>
          <Loading />
        </View>
      ) : (
        <Accordion
          sections={helpCenterList}
          renderHeader={_renderHeader}
          renderContent={_renderContent}
          onChange={(indexes: number[]) => {
            setActiveSections(indexes);
          }}
          activeSections={activeSections}
        />
      )}
    </FullWidthView>
  );
};

const styles = StyleSheet.create({
  header: {
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderBottomColor: 'lightgray',
    borderBottomWidth: 1,
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  childContainer: {
    paddingHorizontal: 25,
    paddingVertical: 15,
    borderBottomColor: 'lightgray',
    borderBottomWidth: 1,
    backgroundColor: backGroundLight,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const getHelpCenterData = async () => {
  const result = await getData('article', { params: { cat_type: 4 } });
  return result;
};

export default HelpCenterListScreen;
