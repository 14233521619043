import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useHeaderHeight } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View, FlatList } from 'react-native';
import { useSelector } from 'react-redux';
import { resetWechatSharePage } from '../../utils/wechatWebMethods';
import BrandCategoryList from '../../components/Brand/BrandCategoryList';
import BrandHeader from '../../components/Brand/BrandHeader';
import BrandProductList from '../../components/Brand/BrandProductList';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import FullWidthView from '../../components/common/FullWidthView';
import ListFooterText from '../../components/common/list/ListFooterText';
import Loading from '../../components/common/Loading';
import ProductCard from '../../components/Product/ProductCard';
import { backGroundLight } from '../../constants/Colors';
import {
  BrandParamList,
  LocationInformation,
  ProductInformation,
  RootState,
} from '../../types';
import alert from '../../utils/alert';
import { getData } from '../../utils/service';

type BrandInfoType = {
  audit_status: number;
  brand_bg: string;
  brand_desc: string;
  brand_first_char: string;
  brand_id: number;
  brand_letter: string;
  brand_logo: string;
  brand_name: string;
  index_img: string;
  is_delete: 0 | 1;
  is_recommend: 0 | 1;
  is_show: 0 | 1;
  site_url: string;
};

type CategoryType = {
  cat_id: number;
  cat_name: string;
  goods_count?: number;
};

const BrandDetailScreen = () => {
  const route = useRoute<RouteProp<BrandParamList, 'BrandDetail'>>();
  const { t } = useTranslation();
  const navigation = useNavigation();
  const headerHeight = useHeaderHeight();

  const location = useSelector<RootState, LocationInformation>(
    (state) => state.goods.location
  );

  const { brandId } = route.params;

  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [brandInfo, setBrandInfo] = useState<BrandInfoType | {}>({});
  const [productCategory, setProductCategory] = useState<Array<CategoryType>>(
    []
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [productList, setProductList] = useState<Array<ProductInformation>>([]);

  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    let isSubscribe = true;
    setIsLoadingComplete(false);
    if (brandId) {
      getBrandDetail(brandId)
        .then((result) => {
          if (result && result.goodStatus) {
            if (isSubscribe) {
              setIsLoadingComplete(true);
              setBrandInfo(result.data.info);
              setProductCategory(result.data.category);

              navigation.setOptions({
                headerTitle: result.data.info.brand_name,
              });
            }
          } else {
            alert(
              typeof result?.data === 'string'
                ? result?.data
                : '系统繁忙，请稍微再试',
              '',
              [
                {
                  text: t('confirm'),
                  onPress: () => {
                    if (navigation.canGoBack()) {
                      navigation.goBack();
                    } else {
                      navigation.reset({
                        index: 0,
                        routes: [
                          {
                            name: 'Root',
                            params: {
                              screen: 'Home',
                            },
                          },
                        ],
                      });
                    }
                  },
                },
              ],
              { cancelable: false }
            );
          }
        })
        .catch((err) => console.log('get brand detail error', err));
    } else {
      alert(
        '缺少品牌id参数，无法获取商品',
        '',
        [
          {
            text: t('goBack'),
            onPress: () => {
              if (navigation.canGoBack()) {
                navigation.goBack();
              } else {
                if (Platform.OS === 'web') {
                  window.history.back();
                  navigation.reset({
                    index: 0,
                    routes: [{ name: 'Root' }],
                  });
                } else {
                  navigation.reset({
                    index: 0,
                    routes: [{ name: 'Root' }],
                  });
                }
              }
            },
          },
        ],
        { cancelable: false }
      );
    }

    return () => {
      isSubscribe = false;
    };
  }, []);

  useEffect(() => {
    setProductList([]);
    setPage(1);
    setTotalPage(0);
    setIsFetching(true);
    getProductList(
      brandId,
      productCategory.length > 0 &&
        typeof productCategory[selectedIndex].cat_id === 'number'
        ? productCategory[selectedIndex].cat_id
        : 0,
      location.countryId,
      location.provinceId,
      location.cityId,
      1
    )
      .then((result) => {
        if (result && result.goodStatus) {
          setProductList(result.data.goods);
          setTotalPage(result.data.total_page);
        }
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [selectedIndex]);

  // Setting Wechat share link
  React.useLayoutEffect(() => {
    // Pre-formate the title
    let shareLinkTitle: string;
    if ('brand_name' in brandInfo) {
      shareLinkTitle = `人气品牌 | ${brandInfo.brand_name}`;
    } else {
      shareLinkTitle = `人气品牌`;
    }
    let imgUrl: string | undefined = undefined;
    if ('brand_logo' in brandInfo) imgUrl = brandInfo.brand_logo ?? undefined;

    // Set up Wechat share information when arguments change.
    if (Platform.OS === 'web')
      resetWechatSharePage(shareLinkTitle, undefined, imgUrl);
    // Set up Wechat share information "again" when user come back from the other page.
    const unsubscribe = navigation.addListener('focus', () => {
      if (Platform.OS === 'web')
        resetWechatSharePage(shareLinkTitle, undefined, imgUrl);
    });
    return unsubscribe;
  }, [brandInfo, navigation]);

  const loadExtraData = async () => {
    if (isFetching) return;
    //Load more pages if less than total page
    if (page < totalPage) {
      setIsFetching(true);
      getProductList(
        brandId,
        productCategory.length > 0 &&
          typeof productCategory[selectedIndex].cat_id === 'number'
          ? productCategory[selectedIndex].cat_id
          : 0,
        location.countryId,
        location.provinceId,
        location.cityId,
        page + 1
      )
        .then((result) => {
          if (result && result.goodStatus) {
            setProductList((oldValues) => [...oldValues, ...result.data.goods]);
            setPage((oldValue) => oldValue + 1);
          } else {
            throw result?.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
  };

  const renderItem = ({ item }: { item: ProductInformation }) => {
    return <ProductCard data={item} />;
  };

  return (
    <FullWidthView style={{ backgroundColor: backGroundLight }}>
      {isLoadingComplete ? (
        <FlatList
          style={{ flex: 1 }}
          data={productList}
          renderItem={renderItem}
          keyExtractor={(item, index) =>
            item.goods_id.toString() + '@' + index.toString()
          }
          numColumns={2}
          columnWrapperStyle={{
            marginHorizontal: 10,
            justifyContent: 'space-between',
          }}
          showsVerticalScrollIndicator={false}
          onEndReachedThreshold={0.01}
          onEndReached={loadExtraData}
          ListFooterComponent={
            page >= totalPage ? ListFooterText : ActivityIndicator
          }
          ListHeaderComponent={
            <View style={{ marginBottom: 10 }}>
              {Object.entries(brandInfo).length > 0 ? (
                <BrandHeader info={brandInfo} />
              ) : (
                <></>
              )}
              <BrandCategoryList
                menus={productCategory}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
              />
            </View>
          }
        />
      ) : (
        <View
          style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <Loading />
        </View>
      )}
    </FullWidthView>
  );
};

const getBrandDetail = async (id: number) => {
  const result = await getData(`brand/detail?id=${id}`);
  return result;
};

const getProductList = async (
  brandId: number,
  catId: number,
  countryId: number,
  provinceId: number,
  cityId: number,
  page = 1
) => {
  const result = await getData(
    `goods/list?brand_id=${brandId}&id=${catId}&page=${page}&country_id=${countryId}&province_id=${provinceId}&city_id=${cityId}&size=10`
  );
  return result;
};

export default BrandDetailScreen;
