import React from 'react'
import SkeletonContent from 'react-native-skeleton-content';
import { StyleProp, ViewStyle } from 'react-native';

type ProductListSkeletonLoadProps = {
    containerStyle?: StyleProp<ViewStyle>
}

const ProductListSkeletonLoad = ({containerStyle = {flex: 1, alignItems: 'center'}
}: ProductListSkeletonLoadProps) => {

    return(
        <SkeletonContent
        containerStyle={containerStyle}
        layout={[
            {width: '100%', flexDirection: 'row', marginTop: 20, children: [
                {flex: 1.5, alignItems: 'center', children: [
                    {width: 100, height: 100}
                ]},
                {flex: 2, children: [
                    {width: '90%', height: 20, marginBottom: 5},
                    {width: '80%', height: 20, marginBottom: 5},
                    {width: '40%', height: 20}
                ]}
            ]},
            {width: '100%', flexDirection: 'row', marginTop: 20, children: [
                {flex: 1.5, alignItems: 'center', children: [
                    {width: 100, height: 100}
                ]},
                {flex: 2, children: [
                    {width: '90%', height: 20, marginBottom: 5},
                    {width: '80%', height: 20, marginBottom: 5},
                    {width: '40%', height: 20}
                ]}
            ]},
            {width: '100%', flexDirection: 'row', marginTop: 20, children: [
                {flex: 1.5, alignItems: 'center', children: [
                    {width: 100, height: 100}
                ]},
                {flex: 2, children: [
                    {width: '90%', height: 20, marginBottom: 5},
                    {width: '80%', height: 20, marginBottom: 5},
                    {width: '40%', height: 20}
                ]}
            ]},
            {width: '100%', flexDirection: 'row', marginTop: 20, children: [
                {flex: 1.5, alignItems: 'center', children: [
                    {width: 100, height: 100}
                ]},
                {flex: 2, children: [
                    {width: '90%', height: 20, marginBottom: 5},
                    {width: '80%', height: 20, marginBottom: 5},
                    {width: '40%', height: 20}
                ]}
            ]}
        ]}
        isLoading={true}
        />
    )
}

export default ProductListSkeletonLoad;