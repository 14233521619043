import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import InputField from '../../Auth/InputField';
import GeneralModal from '../../common/GeneralModal';
import StyledButton from '../../common/StyledButton';

type UnbindWeChatModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: (password: string) => void;
};

const UnlinkWeChatModal = ({
  visible,
  setVisible,
  onConfirm,
}: UnbindWeChatModalProps) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const onClose = () => {
    setPassword('');
    setVisible(false);
  };

  return (
    <GeneralModal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={onClose}
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={{ flex: 1 }}
      >
        <TouchableWithoutFeedback
          onPress={Platform.OS === 'web' ? undefined : () => Keyboard.dismiss()}
          accessible={false}
        >
          <View style={styles.container}>
            <View style={styles.modal}>
              <InputField
                onChangeText={setPassword}
                value={password}
                passwordField
                placeholder={t('AuthModal.enterPassword')}
              />
              <View style={styles.buttonGroup}>
                <View style={{ marginRight: 10 }}>
                  <StyledButton
                    outline
                    buttonSize={18}
                    label={t('cancel')}
                    onPress={onClose}
                    color="lightgray"
                  />
                </View>
                <StyledButton
                  buttonSize={18}
                  label={t('confirm')}
                  onPress={() => {
                    onConfirm(password);
                    onClose();
                  }}
                  disabled={password.length < 6}
                />
              </View>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </GeneralModal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderRadius: 8,
  },
  buttonGroup: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 20,
  },
});

export default UnlinkWeChatModal;
