import React, { useEffect } from 'react';
import { Platform, StyleSheet, TouchableOpacity } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import alert from '../../utils/alert';
import { useTranslation } from 'react-i18next';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import BottomAutoHeightModal from './BottomAutoHeightModal';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import { View } from '../Themed';
import StyledText from './StyledText';
import { backGroundLight, normalTextColor } from '../../constants/Colors';

type ImagePickerModalProps = {
  setImages: Function;
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  customSetImages?: (img: ImageInfo) => void;
};

/**
 * Reuseable ImagePicker for getting photos from devices
 */
const ImagePickerModal = ({
  setImages,
  modalVisible,
  setModalVisible,
  customSetImages,
}: ImagePickerModalProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
  }, []);

  const takePhoto = async () => {
    const { granted } = await ImagePicker.requestCameraPermissionsAsync();
    if (!granted) {
      alert(t('review.enableCameraPermissions'));
    } else {
      let result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 0.5,
        allowsMultipleSelection: false,
        base64: true,
      });

      if (!result.cancelled) {
        const newImage = result;
        if (customSetImages) {
          customSetImages(newImage);
        } else {
          setImages((prevImages: ImageInfo[]) => [...prevImages, newImage]);
        }
        setModalVisible(false);
      }
    }
  };

  const pickImage = async () => {
    const { granted } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (!granted) {
      alert(t('review.enableCameraPermissions'));
    } else {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 0.5,
        allowsMultipleSelection: false,
        base64: true,
      });

      if (!result.cancelled) {
        const newImage = result;
        if (customSetImages) {
          customSetImages(newImage);
        } else {
          setImages((prevImages: ImageInfo[]) => [...prevImages, newImage]);
        }
        setModalVisible(false);
      }
    }
  };

  return (
    <BottomAutoHeightModal
      visible={modalVisible}
      hideModal={() => setModalVisible(false)}
    >
      <StyledText style={styles.title}>
        {t('imagePicker.pictureFrom')}
      </StyledText>
      <View style={styles.modalContentContainer}>
        <TouchableOpacity onPress={() => pickImage()}>
          <View style={styles.btnContainer}>
            <AntDesign name="picture" size={24} color={normalTextColor} />
            <StyledText>{t('imagePicker.album')}</StyledText>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => takePhoto()}>
          <View style={styles.btnContainer}>
            <AntDesign name="camerao" size={24} color={normalTextColor} />
            <StyledText>{t('imagePicker.camera')}</StyledText>
          </View>
        </TouchableOpacity>
      </View>
    </BottomAutoHeightModal>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: 20,
  },
  btnContainer: {
    backgroundColor: backGroundLight,
    width: 100,
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    marginHorizontal: 10,
    marginBottom: 10,
  },
  modalContentContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 20,
  },
});

export default ImagePickerModal;
