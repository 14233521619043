import React, { useState } from 'react';
import {
  View,
  Image,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
  Dimensions,
  ScrollView,
} from 'react-native';
import StyledText from '../../../components/common/StyledText';
import { GREY2 } from '../../../constants/Colors';
import { FontAwesome } from '@expo/vector-icons';
import { postWithAuthToken } from '../../../utils/service';
import { ReviewParamList } from '../../../types';
import { RouteProp } from '@react-navigation/native';
//@ts-ignore
import Toast from 'react-native-tiny-toast';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import ImagePicker from '../../../components/common/ImagePicker/ImagePicker';
import { useHeaderHeight } from '@react-navigation/stack';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import getAppStyle from '../../../utils/getAppStyle';

const { width, height } = Dimensions.get('window');

type SubmitReviewScreenRouteProp = RouteProp<
  ReviewParamList,
  'SubmitReviewScreen'
>;

type SubmitReviewScreenProps = {
  route: SubmitReviewScreenRouteProp;
};

const SubmitReviewScreen = ({ route }: SubmitReviewScreenProps) => {
  var {
    goods_id,
    order_id,
    rec_id,
    ru_id,
    goods_thumb,
    goods_name,
    goods_attr,
  } = route.params;
  const { t } = useTranslation();
  const navigation = useNavigation();
  const [rank, setRank] = useState(0);
  const [comment, setComment] = useState('');
  const [images, setImages] = useState<ImageInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const headerHeight = useHeaderHeight();

  const handleSubmit = () => {
    if (!isLoading) {
      if (submitValidation()) {
        let formData = new FormData();
        formData.append('comment_type', 0);
        formData.append('id', goods_id);
        formData.append('order_id', order_id);
        formData.append('rec_id', rec_id);
        formData.append('ru_id', ru_id);
        formData.append('content', comment);
        formData.append('rank', rank);
        images.forEach((image: ImageInfo) => {
          if (Platform.OS === 'web') {
            formData.append('image[]', image.uri);
          } else {
            formData.append(
              'image[]',
              'data:image/jpeg;base64,' + image.base64
            );
          }
        });

        const loading = Toast.showLoading('');
        setIsLoading(true);
        postWithAuthToken('comments/add', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {
            setIsLoading(false);
            Toast.hide(loading);
            if (response && response.goodStatus) {
              Toast.showSuccess(t('review.submittedReview'));
              Platform.OS === 'web'
                ? navigation.canGoBack()
                  ? navigation.goBack()
                  : //@ts-ignore
                    window.history.back()
                : navigation.goBack();
            } else {
              if (response) {
                throw response.data;
              } else {
                throw 'no token found';
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
            if (loading) Toast.hide(loading);
            Toast.show(err, {
              position: 0,
              duration: 2000,
            });
          });
      }
    }
  };

  const submitValidation = () => {
    let error = '';
    if (images.length > 9)
      error = t('review.error.tooManyPhotos', { maxNumber: 9 });
    // if (comment === '') error = t('review.error.noComment');
    if (rank < 1) error = t('review.error.noRating');

    if (error !== '') {
      Toast.show(error, { position: 0 });
      return false;
    } else {
      return true;
    }
  };

  return (
    <TouchableWithoutFeedback
      onPress={Platform.OS === 'web' ? undefined : () => Keyboard.dismiss()}
      accessible={false}
    >
      <ScrollView style={{ width, height: height - headerHeight }}>
        <View
          style={[styles.cardContainer, { paddingBottom: headerHeight + 10 }]}
        >
          <View style={styles.goodContainer}>
            <Image
              source={{ uri: goods_thumb }}
              style={{ width: 100, height: 100, marginRight: 10 }}
            />
            <View style={styles.goodContainerInfo}>
              <StyledText>{goods_name}</StyledText>
              <StyledText color={GREY2} size={12}>
                {goods_attr?.replace(/\|/g, '\n')}
              </StyledText>
            </View>
          </View>
          <View style={styles.formContainer}>
            <View style={styles.starContainer}>
              {Array.from(Array(5)).map((item, index) => (
                <TouchableWithoutFeedback
                  key={index}
                  onPress={() => {
                    setRank(index + 1);
                  }}
                >
                  <FontAwesome
                    style={{ paddingRight: 3 }}
                    name={rank >= index + 1 ? 'star' : 'star-o'}
                    size={24}
                    color={rank >= index + 1 ? 'red' : 'gray'}
                  />
                </TouchableWithoutFeedback>
              ))}
              <StyledText style={{ marginLeft: 5 }} size={17}>
                {rank > 0 && rank}
              </StyledText>
            </View>
            <TextInput
              onChangeText={(text) => {
                setComment(text);
              }}
              multiline
              placeholder={t('review.leaveComment')}
              style={styles.textInput}
            ></TextInput>
            <ImagePicker images={images} setImages={setImages} route={route} />
          </View>
          <View style={styles.buttonContainer}>
            <TouchableOpacity onPress={handleSubmit} style={styles.button}>
              <StyledText color={'white'}>{t('review.submit')}</StyledText>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    margin: 10,
    flex: 1,
    padding: 10,
    backgroundColor: 'white',
    borderRadius: 10,
  },
  goodContainer: {
    width: '100%',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgray',
    flexDirection: 'row',
  },
  goodContainerInfo: {
    flexDirection: 'column',
    flex: 1,
  },
  formContainer: {
    flex: 1,
    padding: 10,
  },
  buttonContainer: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    width: '35%',
    flexDirection: 'row',
    padding: 10,
    borderRadius: 30,
    backgroundColor: getAppStyle(process.env.EXPO_APP_TYPE)?.mainColor,
    justifyContent: 'center',
  },
  textInput: {
    borderWidth: 1,
    borderColor: 'lightgray',
    padding: 5,
    borderRadius: 10,
    height: 100,
  },
  starContainer: {
    padding: 10,
    paddingLeft: 0,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default SubmitReviewScreen;
