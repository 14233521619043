import React, { forwardRef } from 'react';
import { SearchBar as SearchBarElement } from 'react-native-elements';

import { View, StyleSheet, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

type SearchBarProp = {
  searchText: string;
  onSubmit: Function;
  onChange: Function;
  showLoading?: boolean;
};

const SearchBar = forwardRef(
  (
    { searchText, onSubmit, onChange, showLoading = false }: SearchBarProp,
    ref
  ) => {
    const navigation = useNavigation();
    const { t } = useTranslation();

    const handleCancel = () => {
      Platform.OS === 'web'
        ? navigation.canGoBack()
          ? navigation.goBack()
          : window.history.back()
        : navigation.goBack();
    };

    return (
      <View style={{ flex: 1 }}>
        <SearchBarElement
          ref={ref}
          placeholder={t('searchScreen.searchProduct')}
          platform="ios"
          cancelButtonTitle={t('cancel')}
          onChangeText={(text) => onChange(text)}
          value={searchText}
          onSubmitEditing={() => {
            onSubmit(searchText);
          }}
          onCancel={handleCancel}
          containerStyle={{ backgroundColor: 'white' }}
          inputContainerStyle={styles.inputContainer}
          returnKeyType={'search'}
          showLoading={showLoading}
        />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  inputContainer: {
    borderRadius: 40,
    alignItems: 'center',
    height: 20,
  },
});

export default SearchBar;
