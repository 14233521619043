import React, { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import StyledText from '../../common/StyledText';
import { GREY3 } from '../../../constants/Colors';
import { AntDesign } from '@expo/vector-icons';
import getAppStyle from '../../../utils/getAppStyle';

type AmountCounterProps = {
  amount: number;
  setAmount?: React.Dispatch<React.SetStateAction<number>>;
  maxQuantity: number;
  minQuantity?: number;
  invalid?: boolean;
  sign?: string;
  updateAmount?: (action: string) => void; // For Choose Offer Button
};

const AmountCounter = ({
  amount,
  setAmount,
  maxQuantity,
  minQuantity = 1,
  sign,
  invalid = false,
  updateAmount,
}: AmountCounterProps) => {
  const [leftButtonDisabled, setLeftButtonDisabled] = useState(false);
  const [rightButtonDisabled, setRightButtonDisabled] = useState(false);

  useEffect(() => {
    !updateAmount && (amount < 2 || amount === minQuantity)
      ? setLeftButtonDisabled(true)
      : setLeftButtonDisabled(false);
    amount <= maxQuantity - (sign === 'x' ? minQuantity : 1)
      ? setRightButtonDisabled(false)
      : setRightButtonDisabled(true);
  }, [amount, maxQuantity, minQuantity]);

  return (
    <View style={styles.container}>
      <TouchableWithoutFeedback
        disabled={invalid ? invalid : leftButtonDisabled}
        onPress={() =>
          setAmount
            ? setAmount((prev) => prev - (sign === 'x' ? minQuantity : 1))
            : updateAmount
            ? updateAmount('remove')
            : undefined
        }
      >
        <View style={styles.button}>
          <AntDesign
            name="minuscircle"
            size={26}
            color={
              invalid ? 'lightgray' : leftButtonDisabled ? 'lightgray' : GREY3
            }
          />
        </View>
      </TouchableWithoutFeedback>
      <StyledText size={20} style={styles.amount}>
        {amount}
      </StyledText>
      <TouchableWithoutFeedback
        disabled={invalid ? invalid : rightButtonDisabled}
        onPress={() =>
          setAmount
            ? setAmount((prev) => prev + (sign === 'x' ? minQuantity : 1))
            : updateAmount
            ? updateAmount('add')
            : undefined
        }
      >
        <View style={styles.button}>
          <AntDesign
            name="pluscircle"
            size={26}
            color={
              invalid
                ? 'lightgray'
                : rightButtonDisabled
                ? 'lightgray'
                : getAppStyle(process.env.EXPO_APP_TYPE)?.secondaryColor
            }
          />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {},
  amount: {
    marginHorizontal: 16,
  },
});

export default AmountCounter;
