import { useTranslation } from 'react-i18next';
import { Claim } from '../../../../types';
import StyledText from '../../../common/StyledText';
import WhiteCard from '../../../common/WhiteCard';
import { GREY2 } from '../../../../constants/Colors';
import { StyleSheet, View } from 'react-native';

type RefundMethodProps = {
  claim: Claim;
};

const RefundMethod = ({ claim }: RefundMethodProps) => {
  const { t } = useTranslation();

  return (
    <WhiteCard title={t('order.claim.claimInfo')}>
      <View style={styles.itemContainer}>
        {claim.refund_method && (
          <View style={styles.textContainer}>
            <StyledText color={GREY2}>
              {t('order.claim.refundMethod')}:{' '}
            </StyledText>
            <StyledText>{claim.refund_method.desc}</StyledText>
          </View>
        )}
        {claim.formated_final_amount !== '' && (
          <View style={styles.textContainer}>
            <StyledText color={GREY2}>
              {t('order.claim.refundAmount')}:{' '}
            </StyledText>
            <StyledText>{claim.formated_final_amount}</StyledText>
          </View>
        )}
      </View>
    </WhiteCard>
  );
};

export default RefundMethod;

const styles = StyleSheet.create({
  itemContainer: {
    padding: 5,
  },
  textContainer: {
    flexDirection: 'row',
  },
});
