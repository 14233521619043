import React, { useState } from 'react';
import WhiteCard from '../../common/WhiteCard';
import StyledText from '../../common/StyledText';
import { subTextColor, backGroundLight } from '../../../constants/Colors';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import BottomAutoHeightModal from '../../common/BottomAutoHeightModal';
import ClearButton from '../../common/iconButtons/ClearButton';
import { useTranslation } from 'react-i18next';
import { AntDesign } from '@expo/vector-icons';
import { PaymentMethod, SelectedPaymentType } from '../../../types';
import ResponsiveImg from '../../common/ResponsiveImg';
type PaymentInfoProps = {
  paymentMethods: Array<PaymentMethod>;
  disabledEdit?: boolean;
  children?: React.ReactNode;
  setSelectedPayment: React.Dispatch<React.SetStateAction<SelectedPaymentType>>;
  selectedPayment: SelectedPaymentType;
};

const PaymentInfo = ({
  paymentMethods = [],
  disabledEdit = false,
  children,
  setSelectedPayment,
  selectedPayment,
}: PaymentInfoProps) => {
  const { t } = useTranslation();

  const [displayPaymentModal, setDisplayPaymentModal] = useState(false);

  return (
    <WhiteCard title={t('order.paymentMethod')}>
      <TouchableOpacity
        disabled={disabledEdit}
        onPress={() => setDisplayPaymentModal(true)}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <View style={styles.methodContainer}>
          {selectedPayment.id !== -1 ? (
            <>
              <StyledText>{selectedPayment.name}</StyledText>
              {selectedPayment.description !== '' && (
                <StyledText color={subTextColor}>
                  {selectedPayment.description}
                </StyledText>
              )}
            </>
          ) : (
            <StyledText color={subTextColor}>
              {t('order.selectPaymentMethod')}
            </StyledText>
          )}
        </View>
        {!disabledEdit && (
          <AntDesign name="rightcircleo" size={20} color="lightgray" />
        )}
      </TouchableOpacity>
      {children}
      <BottomAutoHeightModal
        visible={displayPaymentModal}
        hideModal={() => setDisplayPaymentModal(false)}
      >
        <PaymentModal
          setDisplayPaymentModal={setDisplayPaymentModal}
          data={paymentMethods}
          setSelectedPayment={setSelectedPayment}
        />
      </BottomAutoHeightModal>
    </WhiteCard>
  );
};

type PaymentModalProps = {
  setDisplayPaymentModal: React.Dispatch<React.SetStateAction<boolean>>;
  data: Array<PaymentMethod>;
  setSelectedPayment: React.Dispatch<React.SetStateAction<SelectedPaymentType>>;
};

const PaymentModal = ({
  setDisplayPaymentModal,
  data,
  setSelectedPayment,
}: PaymentModalProps) => {
  const { t } = useTranslation();
  const updatePaymentMethod = (
    id: number,
    name: string,
    description: string,
    code: string
  ) => {
    setSelectedPayment({
      id: id,
      name: name,
      description: description,
      code: code,
    });
    setDisplayPaymentModal(false);
  };

  const renderPaymentMethods = data.map((item) => {
    return (
      <TouchableOpacity
        key={item.pay_id}
        onPress={() =>
          updatePaymentMethod(
            item.pay_id,
            item.pay_name,
            item.pay_desc,
            item.pay_code
          )
        }
      >
        <View style={styles.paymentItem}>
          {item.icon_img ? (
            <ResponsiveImg uri={item.icon_img} width={30} />
          ) : (
            <View style={{ width: 30 }}></View>
          )}
          <View style={{ flex: 1, marginLeft: 10 }}>
            <View style={styles.paymentNameContainer}>
              <StyledText>{item.pay_name}</StyledText>
              {item.pay_fee !== '0' && (
                <StyledText align="right">
                  [{t('order.fee')}] {item.pay_fee}
                </StyledText>
              )}
            </View>
            {item.pay_desc !== '' && (
              <StyledText color="red">{item.pay_desc}</StyledText>
            )}
          </View>
        </View>
      </TouchableOpacity>
    );
  });

  return (
    <View>
      <View style={styles.header}>
        <StyledText bold size={16}>
          {t('order.paymentMethod')}
        </StyledText>
        <ClearButton onPress={() => setDisplayPaymentModal(false)} size={26} />
      </View>
      <View style={styles.body}>{renderPaymentMethods}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10,
  },
  body: {},
  methodContainer: {
    paddingVertical: 10,
    flex: 10,
  },
  paymentItem: {
    paddingVertical: 10,
    borderTopWidth: 1,
    borderTopColor: backGroundLight,
    flexDirection: 'row',
    alignItems: 'center',
  },
  paymentNameContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

export default PaymentInfo;
