import React, { useState, useEffect } from 'react';
import WhiteCard from '../../common/WhiteCard';
import { View, StyleSheet, TextInput, Pressable, Platform } from 'react-native';
import StyledText from '../../common/StyledText';
import { backGroundLight, accentColor } from '../../../constants/Colors';
import { useTranslation } from 'react-i18next';
import { OrderFormInfo, OrderFormInfoLight } from '../../../types';
import ImagePickerModal from '../../common/ImagePickerModal';
import { ImageInfo } from 'expo-image-picker';
import ResponsiveImg from '../../common/ResponsiveImg';
import { AntDesign } from '@expo/vector-icons';
import StyledButton from '../../common/StyledButton';

type OrderPreviewFormProps = {
  formFields: Array<OrderFormInfo>;
  formValue: { [key: string]: OrderFormInfoLight };
  setFormValue: React.Dispatch<
    React.SetStateAction<{
      [key: string]: OrderFormInfoLight;
    }>
  >;
};

const OrderPreviewForm = ({
  formFields,
  formValue,
  setFormValue,
}: OrderPreviewFormProps) => {
  const [height, setHeight] = useState<{ [key: string]: number }>({});
  const { t } = useTranslation();
  const [imageModalVisible, setImageModalVisible] = useState<boolean>(false);
  const [imageValue, setImageValue] = useState<{
    [key: string]: string;
  }>({});
  const [currentFieldValue, setCurrentFieldValue] = useState<{
    parent_id: number;
    field_code: string;
  }>();

  useEffect(() => {
    let groupValueStart: { [key: string]: OrderFormInfoLight } = {};
    formFields.map((group: OrderFormInfo) => {
      groupValueStart[group.field_id] = {
        field_id: group.field_id,
        field_code: group.field_code,
        field_name: group.field_name,
        child: {},
      };
      const groupFieldNames = group.child?.map((x) => {
        return { field_code: x.field_code, field_name: x.field_name };
      });
      let fieldGroupObj: {
        [key: string]: { field_name: string; value: string };
      } = {};
      groupFieldNames?.map((names) => {
        fieldGroupObj[names.field_code] = {
          field_name: names.field_name,
          value: '',
        };
      });
      groupValueStart[group.field_id].child = fieldGroupObj;
    });
    setFormValue(groupValueStart);
  }, []);

  const updateImages = (img: ImageInfo) => {
    if (currentFieldValue) {
      setImageValue((prev) => ({
        ...prev,
        [`${currentFieldValue.field_code}@${currentFieldValue.parent_id}`]:
          img.uri,
      }));
      handleFieldChange(
        Platform.OS === 'web' ? img.uri : `data:image/png;base64,${img.base64}`,
        currentFieldValue.parent_id,
        currentFieldValue.field_code,
        false
      );
    }
  };

  const handleShowImgPicker = (parent_id: number, field_code: string) => {
    setCurrentFieldValue({ parent_id: parent_id, field_code: field_code });
    setImageModalVisible(true);
  };

  const handleFieldChange = (
    text: string,
    field_id: number,
    field_code: string,
    isNumeric: boolean
  ) => {
    const fieldGroup = formValue[`${field_id}`];

    if (isNumeric) {
      text = text.replace(/[^0-9]/g, '');
    }

    setFormValue({
      ...formValue,
      [`${field_id}`]: {
        ...fieldGroup,
        child: {
          ...fieldGroup.child,
          [field_code]: {
            ...fieldGroup.child[field_code],
            value: text,
          },
        },
      },
    });
  };

  const renderField = (field: OrderFormInfo) => {
    switch (field.field_type) {
      case 'group':
        return (
          <View key={field.field_id}>
            <StyledText style={{ marginTop: 5 }}>{field.field_name}</StyledText>
            {field.child &&
              Object.values(field.child).map((value) => renderField(value))}
          </View>
        );
      case 'p_file':
      case 'file':
        return (
          <View key={field.field_id} style={{ paddingVertical: 5 }}>
            <StyledText>{field.field_name}</StyledText>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'flex-start',
                paddingTop: 5,
              }}
            >
              <View style={{ marginRight: 30, width: 100 }}>
                {imageValue[`${field.field_code}@${field.parent_id}`] ? (
                  <View>
                    <ResponsiveImg
                      uri={
                        imageValue[`${field.field_code}@${field.parent_id}`] ??
                        ''
                      }
                      width={100}
                    />
                    <Pressable
                      style={{ position: 'absolute', right: -12, top: -12 }}
                      onPress={() => {
                        setImageValue((prev) => ({
                          ...prev,
                          [`${field.field_code}@${field.parent_id}`]: '',
                        }));
                        handleFieldChange(
                          '',
                          field.parent_id ?? 0,
                          field.field_code,
                          false
                        );
                      }}
                    >
                      <AntDesign name="closecircle" size={20} color="red" />
                    </Pressable>
                  </View>
                ) : (
                  <AntDesign
                    name="picture"
                    size={100}
                    color="lightgray"
                    style={{ marginTop: -12, marginLeft: -5 }}
                  />
                )}
              </View>
              <StyledButton
                buttonSize={14}
                label={t('idVerification.select')}
                onPress={() =>
                  handleShowImgPicker(field.parent_id ?? 0, field.field_code)
                }
                outline={
                  field.is_required === 0 ||
                  (formValue[`${field.parent_id}`] &&
                    formValue[`${field.parent_id}`].child &&
                    formValue[`${field.parent_id}`].child[field.field_code] &&
                    formValue[`${field.parent_id}`].child[field.field_code]
                      .value)
                    ? true
                    : false
                }
              />
            </View>
            {field.is_required === 1 && (
              <StyledText size={11} color={accentColor}>
                *{t('validation.required')}
              </StyledText>
            )}
          </View>
        );
      case 'text':
      case 'string':
      case 'textarea':
      case 'number':
        return (
          <View key={field.field_id}>
            <TextInput
              style={[
                styles.formInput,
                {
                  height: Math.max(28, height[field.field_code] ?? 0),
                  marginBottom: field.is_required === 1 ? 0 : 6,
                  fontSize: 16,
                },
              ]}
              placeholder={field.field_name}
              onChangeText={(text) =>
                handleFieldChange(
                  text,
                  field.parent_id ?? 0,
                  field.field_code,
                  field.field_type === 'number'
                )
              }
              keyboardType={
                field.field_type === 'number' ? 'numeric' : 'default'
              }
              multiline={field.field_type === 'textarea'}
              maxLength={field.field_type === 'string' ? 50 : undefined}
              onContentSizeChange={(event) => {
                setHeight({
                  ...height,
                  [field.field_code]: event.nativeEvent.contentSize.height,
                });
              }}
            />
            {field.is_required === 1 && (
              <StyledText size={11} color={accentColor}>
                *{t('validation.required')}
              </StyledText>
            )}
          </View>
        );
      default:
        return <></>;
    }
  };

  return (
    <WhiteCard title={t('order.form')}>
      <View style={{ padding: 5 }}>
        {formFields.map((fieldGroup: OrderFormInfo) => renderField(fieldGroup))}
      </View>
      <ImagePickerModal
        modalVisible={imageModalVisible}
        setModalVisible={setImageModalVisible}
        setImages={setFormValue}
        customSetImages={(img: ImageInfo) => updateImages(img)}
      />
    </WhiteCard>
  );
};

const styles = StyleSheet.create({
  formInput: {
    marginTop: 6,
    paddingLeft: 4,
    borderWidth: 1,
    borderColor: backGroundLight,
  },
  idPhotoPlaceHolder: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
});

export default OrderPreviewForm;
