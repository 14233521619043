import { ScrollView, StyleSheet, View } from 'react-native';
import FullWidthView from '../../../components/common/FullWidthView';
import { useCallback, useEffect, useState } from 'react';
import { getDataWithAuthToken } from '../../../utils/service';
import { Claim, ClaimParamList } from '../../../types';
import alert from '../../../utils/alert';
import { RouteProp, useRoute } from '@react-navigation/native';
import StyledText from '../../../components/common/StyledText';
import Loading from '../../../components/common/Loading';
import { MaterialIcons } from '@expo/vector-icons';
import { WHITE5 } from '../../../constants/Colors';
import BasicInfo from '../../../components/User/Claim/sections/BasicInfo';
import ClaimInfo from '../../../components/User/Claim/sections/ClaimInfo';
import ReviewInfo from '../../../components/User/Claim/sections/ReviewInfo';
import ClaimGoodsInfo from '../../../components/User/Claim/sections/ClaimGoodsList';

const ClaimDetailScreen = () => {
  const route = useRoute<RouteProp<ClaimParamList, 'ClaimDetail'>>();
  const { claimId } = route.params;
  /** @ts-ignore */
  const [claim, setClaim] = useState<Claim>({});
  const [error, setError] = useState('');
  const [isLoadingCompleted, setIsLoadingCompleted] = useState<boolean>(false);

  const getClaimDetail = useCallback(() => {
    setIsLoadingCompleted(false);
    getDataWithAuthToken('order/claim/detail', {
      params: { claim_id: claimId },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setClaim(response.data);
        } else {
          alert(`Error:${response?.return_code}`, response?.data || '');
          setError(response?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoadingCompleted(true);
      });
  }, [claimId]);

  useEffect(() => {
    let isSubscribed = true;
    isSubscribed && getClaimDetail();

    return () => {
      isSubscribed = false;
    };
  }, [getClaimDetail]);

  return (
    <FullWidthView style={styles.rootContainer}>
      {isLoadingCompleted ? (
        error !== '' && JSON.stringify(claim) !== '{}' ? (
          <View style={styles.loadingContainer}>
            <MaterialIcons name="error" size={24} color="lightgray" />
            <StyledText color={'lightgray'}>{error}</StyledText>
          </View>
        ) : (
          <ScrollView>
            <BasicInfo claim={claim} />
            <ReviewInfo claim={claim} />
            {claim.claim_goods && claim.claim_goods.length > 0 && (
              <ClaimGoodsInfo data={claim.claim_goods} />
            )}
            <ClaimInfo claim={claim} />
          </ScrollView>
        )
      ) : (
        <View style={styles.loadingContainer}>
          <Loading />
        </View>
      )}
    </FullWidthView>
  );
};

export default ClaimDetailScreen;

const styles = StyleSheet.create({
  rootContainer: {
    backgroundColor: WHITE5,
    padding: 10,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
