import { useUpdateEffect } from 'ahooks';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, TouchableOpacity, FlatList } from 'react-native';
import {
  backGroundLight,
  normalTextColor,
  tintColorLight,
} from '../../constants/Colors';
import { BundleDate } from '../../types';
import StyledText from '../common/StyledText';

type DateSelectorProps = {
  data: Array<BundleDate>;
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
};

const DateSelector = ({
  data,
  activeIndex,
  setActiveIndex,
}: DateSelectorProps) => {
  const { t } = useTranslation();
  const listRef = useRef<FlatList>(null);

  const handleDateChange = (selectedIndex: number) => {
    setActiveIndex(selectedIndex);
  };

  useUpdateEffect(() => {
    if (listRef && listRef.current) {
      listRef.current.scrollToIndex({
        index: activeIndex,
      });
    }
  }, [activeIndex]);

  const renderItem = ({ item, index }: { item: BundleDate; index: number }) => {
    return (
      <TouchableOpacity onPress={() => handleDateChange(index)}>
        <View style={styles.dateItem}>
          <StyledText
            align="center"
            color={activeIndex === index ? tintColorLight : normalTextColor}
          >
            {item.best_time}
          </StyledText>
          <StyledText
            align="center"
            color={activeIndex === index ? tintColorLight : normalTextColor}
            size={12}
          >
            {t('bundleScreen.bundleList.options', {
              amount: item.package_number,
            })}
          </StyledText>
          <View
            style={[
              styles.activeBottomBar,
              {
                backgroundColor:
                  activeIndex === index ? tintColorLight : 'white',
              },
            ]}
          ></View>
        </View>
      </TouchableOpacity>
    );
  };

  const renderSeparator = () => {
    return <View style={styles.separator} />;
  };

  return (
    <View style={styles.container}>
      <FlatList
        ref={listRef}
        data={data}
        renderItem={renderItem}
        keyExtractor={(item) => item.shipping_time.toString()}
        ItemSeparatorComponent={renderSeparator}
        horizontal
        showsHorizontalScrollIndicator={false}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    borderBottomColor: backGroundLight,
    borderBottomWidth: 1,
  },
  dateItem: {
    padding: 10,
    position: 'relative',
    overflow: 'hidden',
  },
  separator: {
    width: 1,
    backgroundColor: backGroundLight,
    marginVertical: 10,
  },
  activeBottomBar: {
    width: '100%',
    height: 3,
    position: 'absolute',
    alignSelf: 'center',
    bottom: 0,
  },
});

export default DateSelector;
