import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import {
  accentColor,
  backGroundLight,
  normalTextColor,
  tintColorLight,
  warningRed,
} from '../../constants/Colors';
import { CartActivity } from '../../types';
import StyledText from '../common/StyledText';

type ActivityHeaderProps = {
  activityList: {
    current?: CartActivity;
    next_activity?: CartActivity;
  };
};

const ActivityHeader = ({ activityList }: ActivityHeaderProps) => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  const handleGoSpecialOffer = (
    activityId: number,
    type: 'current' | 'next'
  ) => {
    navigation.navigate('SpecialOffer', { id: activityId, type: type });
  };

  const renderHeader = (type: 'current' | 'next') => {
    if (type === 'current' && activityList.current) {
      const act = activityList.current;
      return (
        <TouchableOpacity
          style={styles.activityHeader}
          onPress={() => handleGoSpecialOffer(act.act_id, 'current')}
        >
          <View style={styles.activityLabel}>
            <StyledText color={warningRed}>{act.act_type_name}</StyledText>
          </View>
          <View style={styles.activityContent}>
            <View>
              <StyledText bold>{act.act_name}</StyledText>
              <StyledText>
                {t('specialOfferScreen.fulfillCondition')}
              </StyledText>
            </View>

            <AntDesign name="rightcircleo" size={16} color={normalTextColor} />
          </View>
        </TouchableOpacity>
      );
    }

    if (type === 'next' && activityList.next_activity) {
      const act = activityList.next_activity;
      return (
        <TouchableOpacity
          style={styles.activityHeader}
          onPress={() => handleGoSpecialOffer(act.act_id, 'next')}
        >
          <View style={styles.activityLabel}>
            <StyledText color={warningRed}>{act.act_type_name}</StyledText>
          </View>
          <View style={styles.activityContent}>
            <View>
              <StyledText bold>{act.act_name}</StyledText>
              <StyledText>
                {t('specialOfferScreen.missingAmount')}
                <StyledText color={accentColor}>
                  {act.formated_amount_diff}
                </StyledText>
                {t('specialOfferScreen.unlockSpecialOffer')}
              </StyledText>
            </View>

            <AntDesign name="rightcircleo" size={16} color={normalTextColor} />
          </View>
        </TouchableOpacity>
      );
    }

    return <></>;
  };

  return (
    <>
      {renderHeader('current')}
      {renderHeader('next')}
    </>
  );
};

const styles = StyleSheet.create({
  activityHeader: {
    width: '100%',
    marginTop: 5,
    flexDirection: 'row',
    alignItems: 'center',
    borderTopColor: backGroundLight,
    paddingTop: 5,
    borderTopWidth: 1,
  },
  activityLabel: {
    borderWidth: 1,
    borderColor: warningRed,
    padding: 2,
    borderRadius: 5,
    marginRight: 3,
  },
  activityContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },
});

export default ActivityHeader;
