import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as Localization from 'expo-localization';

//Languages translation resource
import translationEn from './locales/en/translation.json';
import translationZhCN from './locales/zh/translation.json';
import { setAsyncStorageData, getAsyncStorageData } from './utils/asyncStorage';

let initialLanguage = '';

(async () => {
  const languagePreference = await getAsyncStorageData(
    '@storage_LangrageReference'
  );
  //Set Language, Default would be zh-CN
  if (languagePreference) {
    initialLanguage = languagePreference;
  } else {
    initialLanguage = Localization.locales[0].toLowerCase().includes('zh') ? 'zh-CN' : 'en'
    setAsyncStorageData('@storage_LangrageReference', initialLanguage);
  }
})();

// the translations
const resources = {
  en: {
    translation: translationEn,
  },
  'en-CA': { translation: translationEn },
  zh: {
    translation: translationZhCN,
  },
  'zh-CN': {
    translation: translationZhCN,
  },
  'zh-Hans-CA': {
    translation: translationZhCN,
  },
  'zh-Hans-CN': {
    translation: translationZhCN,
  },
  'zh-Hant-CN': {
    translation: translationZhCN,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
    },
    lng: Localization.locales[0],
    fallbackLng: 'zh-CN',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
