import { AntDesign } from '@expo/vector-icons';
import { useHeaderHeight } from '@react-navigation/stack';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, ScrollView, Platform } from 'react-native';
import FullWidthView from '../../components/common/FullWidthView';
import ImagePickerModal from '../../components/common/ImagePickerModal';
import ResponsiveImg from '../../components/common/ResponsiveImg';
import StyledButton from '../../components/common/StyledButton';
import StyledText from '../../components/common/StyledText';
import { postWithAuthToken } from '../../utils/service';
//@ts-ignore
import Toast from 'react-native-tiny-toast';
import alert from '../../utils/alert';
import { useNavigation } from '@react-navigation/native';

const IdVerificationScreen = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const headerHeight = useHeaderHeight();

  const [images, setImages] = useState<ImageInfo[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState(false);

  const handleShowImgPicker = () => {
    setImages([]);
    setModalVisible(true);
  };

  const handleUploadImage = () => {
    (async () => {
      const imgBase64 =
        Platform.OS === 'web'
          ? images[0].uri
          : `data:image/png;base64,${images[0].base64}`;

      if (imgBase64) {
        setIsUploading(true);
        const result = await uploadImage(imgBase64);
        if (result && result.goodStatus) {
          Toast.showSuccess(t('idVerification.responses.success'));
          //@ts-ignore
          navigation.popToTop();
        } else if (result && typeof result.data === 'string') {
          Toast.show(result.data, { position: 0, duration: 2000 });
          alert(result.data);
        } else {
          Toast.show(t('idVerification.responses.fail'));
        }
        setIsUploading(false);
      }
    })();
  };

  return (
    <FullWidthView>
      <View style={styles.root}>
        <ScrollView
          contentContainerStyle={{
            paddingBottom: Platform.OS === 'web' ? headerHeight : 0,
          }}
        >
          <View style={{ margin: 8 }}>
            {images.length > 0 ? (
              <View style={styles.idPhotoPlaceHolder}>
                <ResponsiveImg uri={images[0].uri} />
              </View>
            ) : (
              <View style={styles.idPhotoPlaceHolder}>
                <AntDesign name="idcard" size={100} color="lightgray" />
                <StyledText align="center">
                  {t('idVerification.description')}
                </StyledText>
              </View>
            )}
          </View>

          <StyledButton
            label={t('idVerification.select')}
            onPress={handleShowImgPicker}
            outline={images.length > 0}
            disabled={isUploading}
          />

          {images.length === 1 && (
            <View style={{ marginVertical: 10 }}>
              <StyledButton
                label={t('idVerification.upload')}
                onPress={handleUploadImage}
                accent
                disabled={isUploading}
              />
            </View>
          )}
        </ScrollView>
      </View>
      <ImagePickerModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setImages={setImages}
      />
    </FullWidthView>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'space-evenly',
  },
  idPhotoPlaceHolder: {
    alignItems: 'center',
    padding: 10,
    marginBottom: 10,
  },
  imgContainer: { alignItems: 'center' },
});

const uploadImage = async (base64: string) => {
  const result = await postWithAuthToken('user/verification', {
    image: base64,
  });

  return result;
};

export default IdVerificationScreen;
