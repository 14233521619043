import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import WhiteCard from '../../../common/WhiteCard';
import StyledText from '../../../common/StyledText';
import { subTextColor, warningRed } from '../../../../constants/Colors';
import Collapsible from 'react-native-collapsible';
import { AntDesign } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { ClaimGood } from '../../../../types';
import NavigateButton from '../../../common/NavigateButton';

type ClaimGoodsInfoProps = {
  data: Array<ClaimGood>;
};

const ClaimGoodsInfo = ({ data }: ClaimGoodsInfoProps) => {
  const { t } = useTranslation();

  const [isCollapsed, setIsCollapsed] = useState(true);

  const itemListSmall =
    data.length <= 5
      ? data.map((item, index) => <Item key={index} item={item} />)
      : data.slice(0, 5).map((item, index) => <Item key={index} item={item} />);

  const itemListRemain =
    data.length > 5 &&
    data
      .slice(5, data.length)
      .map((item, index) => <Item key={index} item={item} />);

  return (
    <WhiteCard title={t('order.productInfo')}>
      <View>{itemListSmall}</View>

      {data.length > 5 && (
        <>
          <Collapsible collapsed={isCollapsed}>{itemListRemain}</Collapsible>
          <TouchableOpacity
            onPress={() => {
              setIsCollapsed((prev) => !prev);
            }}
            style={styles.iconButton}
          >
            {isCollapsed ? (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <StyledText align="center">{t('seeMore') + ' '}</StyledText>
                <AntDesign name="downcircleo" size={12} color="lightgray" />
              </View>
            ) : (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <StyledText align="center">
                  {t('closeSeeMore') + ' '}
                </StyledText>
                <AntDesign name="upcircleo" size={12} color="lightgray" />
              </View>
            )}
          </TouchableOpacity>
        </>
      )}
    </WhiteCard>
  );
};

const Item = ({ item }: { item: ClaimGood }) => {
  return (
    <NavigateButton
      route="ProductNavigator"
      options={{
        params: { productId: item.order_goods.goods_id },
        screen: 'ProductDetailScreen',
      }}
    >
      <View style={styles.itemContainer}>
        <View style={styles.leftContainer}>
          <StyledText numberOfLines={3}>
            {item.order_goods.goods_name}
          </StyledText>
          {item.order_goods.goods_attr && (
            <StyledText color={subTextColor} size={12}>
              {item.order_goods.goods_attr}
            </StyledText>
          )}
        </View>
        <View style={styles.rightContainer}>
          <StyledText>x{item.claim_number}</StyledText>
          <View>
            <StyledText>{item.formated_claim_amount}</StyledText>
          </View>
        </View>
      </View>
    </NavigateButton>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 2.5,
  },
  leftContainer: {
    justifyContent: 'center',
    flex: 2,
    width: 0,
    flexGrow: 2,
  },
  rightContainer: {
    flexDirection: 'row',
    flex: 0.65,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  tag: {
    borderWidth: 1,
    borderColor: warningRed,
    padding: 2,
    borderRadius: 5,
    marginRight: 3,
    alignSelf: 'flex-start',
  },
});

export default ClaimGoodsInfo;
