import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { OrderParamList } from '../../types';
import { useTranslation } from 'react-i18next';
import OrderListScreen from '../../screens/User/Order/OrderListScreen';
import BackButton from '../../components/common/iconButtons/BackButton';
import OrderDetailScreen from '../../screens/User/Order/OrderDetailScreen';
import OrderSubmitScreen from '../../screens/User/Order/OrderSubmitScreen';
import OrderResultScreen from '../../screens/User/Order/OrderResultScreen';
import NewCreditCardScreen from '../../screens/User/CreditCard/NewCreditCardScreen';
import NavigateButton from '../../components/common/NavigateButton';
import OrderCheckScreen from '../../screens/User/Order/OrderCheckScreen';
import WebPaymentScreen from '../../screens/User/Order/WebPaymentScreen';
import OrderListBackBtn from '../../components/Order/OrderListBackBtn';
import OrderTrackingScreen from '../../screens/User/Order/OrderTrackingScreen';
import UploadFileScreen from '../../screens/Cart/UploadFileScreen';
import ClaimListScreen from '../../screens/User/Claim/ClaimListScreen';
import ClaimDetailScreen from '../../screens/User/Claim/ClaimDetailScreen';
import TaskGoodsListScreen from '../../screens/User/Order/TaskGoodsListScreen';
import getAppStyle from '../../utils/getAppStyle';

const OrderStack = createStackNavigator<OrderParamList>();

const OrderNavigator = () => {
  const { t } = useTranslation();

  return (
    <OrderStack.Navigator
      initialRouteName="OrderList"
      screenOptions={{
        headerBackTitleVisible: false,
        headerLeft: () => (
          <NavigateButton goBack>
            <BackButton />
          </NavigateButton>
        ),
        headerTitleAlign: 'center',
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <OrderStack.Screen
        name="OrderList"
        component={OrderListScreen}
        options={{
          headerTitleAlign: 'center',
          headerLeft: () => <OrderListBackBtn />,
        }}
      />
      <OrderStack.Screen
        name="OrderDetail"
        component={OrderDetailScreen}
        options={{
          headerTitleAlign: 'center',
          headerTitle: t('order.orderDetail'),
        }}
      />
      <OrderStack.Screen
        name="OrderSubmit"
        component={OrderSubmitScreen}
        options={{
          headerTitle: t('order.orderProcessing'),
          headerLeft: () => <></>,
          gestureEnabled: false,
        }}
      />
      <OrderStack.Screen
        name="OrderCheck"
        component={OrderCheckScreen}
        options={{
          headerTitle: t('order.orderProcessing'),
          headerLeft: () => <></>,
          gestureEnabled: false,
        }}
      />
      <OrderStack.Screen
        name="OrderResult"
        component={OrderResultScreen}
        options={{
          headerTitle: t('order.orderProcessResult'),
          headerLeft: () => <></>,
          gestureEnabled: false,
        }}
      />
      <OrderStack.Screen
        name="NewCreditCardScreen"
        component={NewCreditCardScreen}
        options={{ title: t('creditCard.newCreditCard') }}
      />
      <OrderStack.Screen
        name="WebPaymentScreen"
        component={WebPaymentScreen}
        options={{
          headerShown: false,
        }}
      />
      <OrderStack.Screen
        name="OrderTracking"
        component={OrderTrackingScreen}
        options={{ title: t('order.orderTracking.title') }}
      />
      <OrderStack.Screen
        name="UploadFileScreen"
        component={UploadFileScreen}
        options={{
          headerTitle: t('uploadFileScreen.title'),
        }}
      />
      <OrderStack.Screen
        name="OrderClaimList"
        component={ClaimListScreen}
        options={{
          headerTitleAlign: 'center',
          headerTitle: t('userProfileScreen.myOrders.options.return'),
        }}
      />
      <OrderStack.Screen
        name="OrderClaimDetail"
        component={ClaimDetailScreen}
        options={{
          headerTitleAlign: 'center',
          headerTitle: t('order.claim.claimDetail'),
        }}
      />
      <OrderStack.Screen
        name="TaskGoodsList"
        component={TaskGoodsListScreen}
        options={{
          headerTitleAlign: 'center',
          headerTitle: t('order.pickingDetail'),
        }}
      />
    </OrderStack.Navigator>
  );
};

export default OrderNavigator;
