import * as Yup from 'yup';
import { removeAsyncStorageData } from './asyncStorage';
import valid from 'card-validator';
import i18n from 'i18next';
import { store } from '../App';
import * as RootNavigation from './navigator';
import jwtDecode from 'jwt-decode';
//@ts-ignore
import Toast from 'react-native-tiny-toast';
import { TokenPayload } from '../types';
import moment from 'moment';

/**
 * Axios response validation
 * @param response Axios response
 */
export function validateResponse(response: {
  return_code?: number;
  data: any;
}) {
  if (response['return_code']) {
    switch (response['return_code']) {
      case 200:
        return {
          goodStatus: true,
          return_code: response.return_code,
          data: response.data,
        };
      //user has no location, send to User Location modal
      case 405:
        // RootNavigation.reset();
        RootNavigation.navigate('Modal', { screen: 'UserLocationModal' });
        removeAsyncStorageData('@storage_location');
        return {
          goodStatus: false,
          return_code: response.return_code,
          data: i18n.t('regionScreen.notFound'),
        };

      //Redirect to waiting page, the user needs to await for registration approval
      case 406:
        removeAsyncStorageData('@storage_AuthToken');
        RootNavigation.navigate('Modal', {
          screen: 'AuthNavigator',
          params: { screen: 'RegistrationApprovalScreen' },
        });
        return {
          goodStatus: false,
          return_code: response.return_code,
          data: response.data,
        };

      //Unauthorized user, remove token in async storage
      case 401:
        if (response.data === 'Expired token') {
          Toast.show(i18n.t('AuthModal.loginExpire'), {
            position: Toast.position.CENTER,
          });
          store.dispatch({ type: 'HIDE_PRODUCT_OPTION_WINDOW' });
          // RootNavigation.reset();
          RootNavigation.navigate('Modal', { screen: 'AuthModal' });
        }
        removeAsyncStorageData('@storage_AuthToken');
        store.dispatch({
          type: 'CHANGE_USER_STATUS',
          payload: {
            userInfo: {
              name: '',
              phoneNumber: '',
              userId: -1,
            },
            isLoggedIn: false,
          },
        });
      default:
        return {
          goodStatus: false,
          return_code: response.return_code,
          data: response.data,
        };
    }
  } else {
    return {
      goodStatus: false,
      return_code: response.return_code,
      data: response.data,
    };
  }
}

/**
 * Login Validation Scheme
 */
export const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, i18n.t('validation.tooShort'))
    .required(i18n.t('validation.required')),
});

/**
 * Register Validation Scheme for Normal and Shop Reg Company
 */
export const RegisterSchemaNormal = Yup.object().shape({
  password: Yup.string()
    .min(6, i18n.t('validation.tooShort'))
    .required(i18n.t('validation.required')),
  confirmPassword: Yup.string()
    .test(
      'passwords-match',
      i18n.t('validation.passwordDoNotMatch'),
      function (value) {
        return this.parent.password === value;
      }
    )
    .required(i18n.t('validation.required')),
  code: Yup.string()
    .length(6, i18n.t('validation.codeHint'))
    .required(i18n.t('validation.required')),
});

export const RegisterSchemaShopReg = Yup.object().shape(
  {
    companyName: Yup.string().required(i18n.t('validation.required')),
    companyAddress: Yup.string().required(i18n.t('validation.required')),
    businessLicense: Yup.string().required(i18n.t('validation.required')),
    password: Yup.string()
      .min(6, i18n.t('validation.tooShort'))
      .required(i18n.t('validation.required')),
    confirmPassword: Yup.string()
      .test(
        'passwords-match',
        i18n.t('validation.passwordDoNotMatch'),
        function (value) {
          return this.parent.password === value;
        }
      )
      .required(i18n.t('validation.required')),
    code: Yup.string()
      .length(6, i18n.t('validation.codeHint'))
      .required(i18n.t('validation.required')),
  }
  // : {
  //     password: Yup.string()
  //       .min(6, i18n.t('validation.tooShort'))
  //       .required(i18n.t('validation.required')),
  //     confirmPassword: Yup.string()
  //       .test(
  //         'passwords-match',
  //         i18n.t('validation.passwordDoNotMatch'),
  //         function (value) {
  //           return this.parent.password === value;
  //         }
  //       )
  //       .required(i18n.t('validation.required')),
  //     code: Yup.string()
  //       .length(6, i18n.t('validation.codeHint'))
  //       .required(i18n.t('validation.required')),
  //   }}
);

/**
 * North America phone number or China mainland phone number schema
 */
export const VerifyPhoneSchema = Yup.object().shape({
  code: Yup.string()
    .length(6, i18n.t('validation.codeHint'))
    .required(i18n.t('validation.required')),
});

/**
 * Reset Password Validation Scheme
 */
export const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, i18n.t('validation.tooShort'))
    .required(i18n.t('validation.required')),
  confirmPassword: Yup.string()
    .test(
      'passwords-match',
      i18n.t('validation.passwordDoNotMatch'),
      function (value) {
        return this.parent.newPassword === value;
      }
    )
    .required(i18n.t('validation.required')),
});

export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(6, i18n.t('validation.tooShort'))
    .required(i18n.t('validation.required')),
  newPassword: Yup.string()
    .min(6, i18n.t('validation.tooShort'))
    .required(i18n.t('validation.required')),
  confirmNewPassword: Yup.string()
    .test(
      'passwords-match',
      i18n.t('validation.passwordDoNotMatch'),
      function (value) {
        return this.parent.newPassword === value;
      }
    )
    .required(i18n.t('validation.required')),
});

export const ChangePhoneSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, i18n.t('validation.tooShort'))
    .required(i18n.t('validation.required')),
  code: Yup.string()
    .length(6, i18n.t('validation.codeHint'))
    .required(i18n.t('validation.required')),
});

export const ChangeEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('validation.invalidEmail'))
    .required(i18n.t('validation.required')),
  code: Yup.string()
    .length(6, i18n.t('validation.codeHint'))
    .required(i18n.t('validation.required')),
  subscribe: Yup.boolean().required(i18n.t('validation.required')),
});

export const AddressSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, i18n.t('validation.tooShort'))
    .required(i18n.t('validation.required')),
  postal: Yup.string()
    .matches(
      /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d[ -]?$/,
      i18n.t('validation.invalidFormat')
    )
    .required(i18n.t('validation.required')),
  unit: Yup.string().notRequired(),
  buzz: Yup.string().notRequired(),
});

/**
 * Credit card validation scheme
 */
export const CreditCardSchema = Yup.object().shape({
  cardNumber: Yup.string().required(i18n.t('validation.required')),
  expiry: Yup.string().test(
    'test-expiry',
    i18n.t('validation.invalidDate'),
    (value) => valid.expirationDate(value).isValid
  ),
  cvc: Yup.string()
    .test(
      'test-cvc',
      i18n.t('validation.invalidCVC'),
      (value) => valid.cvv(value, 4).isPotentiallyValid
    )
    .required(i18n.t('validation.required')),
  postalCode: Yup.string().matches(
    /^[a-zA-Z0-9\s]+$/,
    i18n.t('validation.invalidFormat')
  ),
  // .required(i18n.t('validation.required')),
});

/**
 * Check if JWT expire time is before current or not.
 * @param token JWT token
 */
export const validateJWT = (token: string) => {
  const tokenInfo: TokenPayload = jwtDecode(token);
  if (tokenInfo) {
    return moment(tokenInfo.exp * 1000).isAfter(moment())
      ? { id: tokenInfo.user_id, exp: tokenInfo.exp }
      : false;
  }
  return false;
};
