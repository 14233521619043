import React, { useState, useEffect } from 'react';
import {
  View,
  StyleSheet,
  FlatList,
  Image,
  TouchableOpacity,
  Platform,
  GestureResponderEvent,
  Dimensions,
} from 'react-native';
import FullWidthView from '../../components/common/FullWidthView';
import ListFooterText from '../../components/common/list/ListFooterText';
import { getDataWithAuthToken, postWithAuthToken } from '../../utils/service';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import { AntDesign } from '@expo/vector-icons';
import StyledText from '../../components/common/StyledText';
import { accentColor } from '../../constants/Colors';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/common/Loading';
import alert from '../../utils/alert';
import { useHeaderHeight } from '@react-navigation/stack';
import { ScrollView } from 'react-native-gesture-handler';

type FavoriteObject = {
  goods_id: number;
  goods_name: string;
  shop_price: string;
  goods_thumb: string;
  formated_shop_price: string;
};

const UserFavoriteScreen = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const navigation = useNavigation();

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      fetchData();
    });
    return unsubscribe;
  }, []);

  const fetchData = () => {
    setIsLoading(true);
    getDataWithAuthToken('collection')
      .then((response) => {
        setIsLoading(false);
        setIsLoadingComplete(true);
        if (response) {
          if (response.goodStatus) {
            setFavorites(response.data);
          } else {
            throw response.data;
          }
        } else {
          throw 'no token found';
        }
      })
      .catch((err) => {
        setIsLoadingComplete(true);
        setIsLoading(false);
        Toast.show(err, {
          position: 0,
          duration: 2000,
        });
      });
  };

  const handlePressItem = (item: FavoriteObject) => {
    if (!isLoading) {
      navigation.navigate('ProductNavigator', {
        screen: 'ProductDetailScreen',
        params: { productId: item.goods_id },
      });
    }
  };

  const handlePressDelete = (item: FavoriteObject) => {
    if (!isLoading) {
      alert(
        t('cartScreen.deletePrompt'),
        '',
        [
          {
            text: t('cancel'),
            style: 'cancel',
          },
          {
            text: t('confirm'),
            onPress: () => {
              postWithAuthToken('collection/delete', {
                goods_id: item.goods_id,
              })
                .then((result) => {
                  if (result && result.goodStatus) {
                    setIsLoadingComplete(false);
                    fetchData();
                  } else {
                    console.log('error: ', result);
                  }
                })
                .catch((err) => {
                  Toast.show(err, {
                    position: 0,
                    duration: 2000,
                  });
                });
            },
          },
        ],
        { cancelable: false }
      );
    }
  };

  const _renderItem = ({ item }: { item: FavoriteObject }) => {
    return (
      <View style={styles.favoriteContainer}>
        <TouchableOpacity
          onPress={() => handlePressItem(item)}
          style={{ flex: 1, flexDirection: 'row' }}
        >
          <Image
            source={{ uri: item.goods_thumb }}
            style={{ width: 100, height: 100, marginRight: 10 }}
          />
          <View style={styles.favoriteContainerInfo}>
            <StyledText>{item.goods_name}</StyledText>
            <StyledText
              color={accentColor}
            >{`${item.formated_shop_price}`}</StyledText>
          </View>
        </TouchableOpacity>
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            onPress={(event: GestureResponderEvent) => {
              event.preventDefault();
              handlePressDelete(item);
            }}
          >
            <AntDesign name="delete" size={22} color="lightgray" />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <FullWidthView edges={['bottom', 'left', 'right']}>
      {isLoadingComplete ? (
        favorites.length > 0 ? (
          <ScrollView style={{ flex: 1 }}>
            <FlatList
              data={favorites}
              renderItem={_renderItem}
              keyExtractor={(item: FavoriteObject, index) =>
                item.goods_id.toString() + '@' + index.toString()
              }
              ListFooterComponent={ListFooterText}
            />
          </ScrollView>
        ) : (
          <EmptyFavoritesBlock />
        )
      ) : (
        <Loading />
      )}
    </FullWidthView>
  );
};

const EmptyFavoritesBlock = () => {
  const { t } = useTranslation();

  return (
    <View style={styles.emptyCartBlockContainer}>
      <AntDesign name="hearto" size={80} color="lightgray" />
      <StyledText size={18} color="gray" style={{ marginBottom: 5 }}>
        {t('favorites.noFavorites')}
      </StyledText>
      <StyledText
        size={16}
        color="lightgray"
        style={{ textAlign: 'center', width: 250 }}
      >
        {t('favorites.emptyFavoritesTip')}
      </StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  favoriteContainer: {
    width: '100%',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgray',
    flexDirection: 'row',
  },
  favoriteContainerInfo: {
    flexDirection: 'column',
    flex: 1,
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyCartBlockContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default UserFavoriteScreen;
