import React, { useEffect, useState } from 'react';
import FullWidthView from '../../components/common/FullWidthView';
import { AntDesign } from '@expo/vector-icons';
import StyledText from '../../components/common/StyledText';
import { useTranslation } from 'react-i18next';
import {
  getAsyncStorageData,
  removeAsyncStorageData,
} from '../../utils/asyncStorage';
import {
  SectionList,
  Text,
  View,
  StyleSheet,
  Platform,
  TouchableOpacity,
  GestureResponderEvent,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import ListOption from '../../components/common/list/ListOption';
import NavigateButton from '../../components/common/NavigateButton';
import { GREEN1, tintColorLight, RED3 } from '../../constants/Colors';
import ProfilePictureHeader from '../../components/User/ProfilePictureHeader';
// import * as WeChat from 'react-native-wechat-lib';
//@ts-ignore
import Toast from 'react-native-tiny-toast';
import {
  getDataWithAuthToken,
  postWithAuthToken,
  registerForPushNotificationsAsync,
  savePushToken,
  unlinkWeChat,
} from '../../utils/service';
import alert from '../../utils/alert';
import { RootState, UserParamList } from '../../types';
import UnlinkWeChatModal from '../../components/User/WeChat/UnlinkWeChatModal';
import StyledButton from '../../components/common/StyledButton';
import * as Linking from 'expo-linking';
import getAppStyle from '../../utils/getAppStyle';

let WeChat: any;
try {
  WeChat = require('react-native-wechat-lib');
} catch {}

const UserInfoScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const route = useRoute<RouteProp<UserParamList, 'UserInfo'>>();

  //Get user information from previous screen and display
  const [userInfo, setUserInfo] = useState(route?.params?.data);

  //Values after finish updated
  const newPicture = route?.params?.newPicture;
  const newName = route?.params?.newName;
  const newGender = route?.params?.newGender;
  const newBirthday = route?.params?.newBirthday;
  const newPhone = route?.params?.newPhone;
  const newEmail = route?.params?.newEmail;
  const weChatCode = route?.params?.code;
  const [isWeChatLinked, setIsWeChatLinked] = useState(userInfo.oauth_wechat);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showUnlinkWeChatModal, setShowUnlinkWeChatModal] = useState(false);

  const navigation = useNavigation();

  const appLoginAppId = useSelector<RootState, string>(
    (state) => state.user.appLoginAppId
  );
  const webLoginAppId = useSelector<RootState, string>(
    (state) => state.user.webLoginAppId
  );
  const universalLink = useSelector<RootState, string>(
    (state) => state.user.universalLink
  );

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', async () => {
      if (!userInfo || (userInfo && typeof userInfo === 'string')) {
        if (weChatCode) {
          await linkWeChatAccount(weChatCode);
          setIsWeChatLinked(true);
        }
        getUserInfo();
      }
    });
    return unsubscribe;

    async function getUserInfo() {
      const authToken = await getAsyncStorageData('@storage_AuthToken');
      if (authToken) {
        const result = await getDataWithAuthToken('user');
        if (result && result.goodStatus) {
          dispatch({ type: 'CHANGE_LOGIN_STATUS', payload: true });
          setUserInfo(result.data);
        } else {
          console.log('err, user is not logged in yet', result);
          setUserInfo({});
        }
      } else {
        console.log('err, user is not logged in yet');
        setUserInfo({});
      }
    }
  }, []);

  const handleLinkWeChatPress = async () => {
    if (Platform.OS === 'web' && (!isSubmitting || !isWeChatLinked)) {
      Linking.openURL(
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${webLoginAppId}&redirect_uri=${
          getAppStyle(process.env.EXPO_APP_TYPE)?.appWebsite
        }/user/info?data=${userInfo}&response_type=code&scope=snsapi_userinfo`
      );
    } else if (!isSubmitting || !isWeChatLinked) {
      setIsSubmitting(true);
      WeChat.registerApp(appLoginAppId, universalLink)
        .then(() => {
          WeChat.isWXAppInstalled()
            .then((isInstalled: any) => {
              if (isInstalled) {
                //Send Auth Request
                WeChat.sendAuthRequest('snsapi_userinfo')
                  .then((response: any) => {
                    if (response.errCode === 0) {
                      if (response.code) {
                        linkWeChatAccount(response.code)
                          .then((result) => {
                            setIsSubmitting(false);
                            if (result) {
                              if (result.goodStatus) {
                                setIsWeChatLinked(true);
                                Toast.showSuccess(
                                  t(
                                    'userInfoScreen.listOptions.successfullyLinked'
                                  )
                                );
                              } else {
                                alert(result?.data);
                              }
                            } else {
                              console.log('no token found');
                            }
                          })
                          .catch((err) => {
                            setIsSubmitting(false);
                            throw err;
                          });
                      }
                    }
                  })
                  .catch((err: any) => {
                    setIsSubmitting(false);
                    throw err;
                  });
              } else {
                setIsSubmitting(false);
                throw '请先安装微信';
              }
            })
            .catch((err: any) => {
              setIsSubmitting(false);
              console.log(err);
              Toast.show(t('userInfoScreen.listOptions.wechatRequired'), {
                position: 0,
                duration: 2000,
              });
            });
        })
        .catch((error: any) => console.log('ERROR', error));
    }
  };

  const handleUnlinkWeChatPress = () => {
    setShowUnlinkWeChatModal(true);
  };

  const handleUnlinkWeChat = (password: string) => {
    setIsSubmitting(true);
    unlinkWeChat(password)
      .then((response) => {
        setIsSubmitting(false);
        if (response) {
          if (response.goodStatus) {
            setIsWeChatLinked(false);
            Toast.showSuccess(
              t('userInfoScreen.listOptions.successfullyUnlinked'),
              { position: 0 }
            );
          } else {
            Toast.show(response.data || t('userInfoScreen.failUnlink'), {
              position: 0,
            });
          }
        } else {
          console.log('no response');
        }
      })
      .catch((err: any) => {
        setIsSubmitting(false);
        setShowUnlinkWeChatModal(false);
        console.log(err);
      });
  };

  const options = [
    {
      title: 'Basic Information',
      data: [
        {
          label: t('userInfoScreen.listOptions.username'),
          value: userInfo.user_name,
          type: 'username',
          route: null,
          editable: false,
          button: false,
          buttonDisabled: false,
          onPress: () => {},
          buttonColor: '',
        },
        {
          label: t('userInfoScreen.listOptions.nickname'),
          value: newName ? newName : userInfo.nick_name,
          type: 'nickname',
          route: 'UserInfoEdit',
          editable: true,
          button: false,
          buttonDisabled: false,
          onPress: () => {},
          buttonColor: '',
        },
        {
          label: t('userInfoScreen.listOptions.gender'),
          value: newGender
            ? newGender
            : userInfo.sex === 0
            ? t('userInfoScreen.listOptions.genderType.unknown')
            : newGender
            ? newGender
            : userInfo.sex === 1
            ? t('userInfoScreen.listOptions.genderType.male')
            : t('userInfoScreen.listOptions.genderType.female'),
          type: 'gender',
          route: 'UserInfoEdit',
          editable: true,
          button: false,
          buttonDisabled: false,
          onPress: () => {},
          buttonColor: '',
        },
        {
          label: t('userInfoScreen.listOptions.birthday'),
          value: newBirthday ? newBirthday : userInfo.birthday,
          type: 'birthday',
          route: 'UserInfoEdit',
          editable: true,
          button: false,
          buttonDisabled: false,
          onPress: () => {},
          buttonColor: '',
        },
      ],
    },
    {
      title: 'Contact Information',
      data: [
        {
          label: t('userInfoScreen.listOptions.phone'),
          value: newPhone ? newPhone : userInfo.mobile_phone,
          type: 'phone',
          route: 'UserInfoEdit',
          editable: true,
          button: false,
          buttonDisabled: false,
          onPress: () => {},
          buttonColor: '',
        },
        {
          label: t('userInfoScreen.listOptions.email'),
          value: newEmail
            ? newEmail
            : userInfo.is_validated
            ? userInfo.email
            : '',
          type: 'email',
          route: 'UserInfoEdit',
          editable: true,
          button: false,
          buttonDisabled: false,
          onPress: () => {},
          buttonColor: '',
        },
        {
          label: t('userInfoScreen.listOptions.password'),
          value: '',
          type: 'password',
          route: 'UserInfoEdit',
          editable: true,
          button: false,
          buttonDisabled: false,
          onPress: () => {},
          buttonColor: '',
        },
        {
          label: t('userInfoScreen.listOptions.address'),
          value: '',
          type: 'address',
          route: 'Address',
          editable: true,
          button: false,
          buttonDisabled: false,
          onPress: () => {},
          buttonColor: '',
        },
      ],
    },
    {
      title: 'Linked Accounts',
      data: [
        {
          label: t('idVerification.title'),
          value: !userInfo.has_valid_ids
            ? t('idVerification.notVerified')
            : t('idVerification.verified'),
          type: '',
          button: !userInfo.has_valid_ids,
          buttonDisabled: !userInfo.has_valid_ids,
          route: 'IdVerification',
          editable: !userInfo.has_valid_ids,
          onPress: () => {},
          buttonColor: '',
        },
        {
          label: t('userInfoScreen.listOptions.weChat'),
          value: isWeChatLinked
            ? t('userInfoScreen.listOptions.unlinkWeChat')
            : t('userInfoScreen.listOptions.linkWeChat'),
          type: 'wechat',
          button: true,
          buttonDisabled: isSubmitting,
          route: null,
          editable: false,
          onPress: isWeChatLinked
            ? handleUnlinkWeChatPress
            : handleLinkWeChatPress,
          buttonColor: isWeChatLinked ? RED3 : GREEN1,
        },
        {
          label: t('userInfoScreen.deleteAccountOption.deleteAccount'),
          value: '',
          type: 'deleteAccount',
          button: false,
          buttonDisabled: false,
          route: 'UserInfoEdit',
          editable: true,
          onPress: () => {},
          buttonColor: '',
        },
      ],
    },
  ];

  //Confirm logout, clean up user information
  const emptyToken = () => {
    //Remove device expo push token when logout
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      registerForPushNotificationsAsync().then(async (token) => {
        if (token) {
          savePushToken(token, {
            id: 0,
            exp: 0,
          });
        }
      });
    }

    removeAsyncStorageData('@storage_AuthToken');
    dispatch({
      type: 'CHANGE_USER_STATUS',
      payload: {
        userInfo: {
          name: '',
          phoneNumber: '',
          userId: -1,
        },
        isLoggedIn: false,
      },
    });

    if (getAppStyle(process.env.EXPO_APP_TYPE)?.loginRequiredAccessAllPages) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'Modal', params: { screen: 'AuthModal' } }],
      });
    } else {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: 'Root',
            params: { screen: 'User', params: { screen: 'UserProfile' } },
          },
        ],
      });
    }
  };

  const handleLogoutPress = (event: GestureResponderEvent) => {
    event.preventDefault();
    alert(
      t('userInfoScreen.logoutOption.alertTitle'),
      '',
      [
        {
          text: t('userInfoScreen.logoutOption.cancel'),
          style: 'cancel',
        },
        {
          text: t('userInfoScreen.logoutOption.confirm'),
          onPress: () => emptyToken(),
        },
      ],
      { cancelable: false }
    );
  };

  return (
    <FullWidthView edges={['bottom', 'left', 'right']}>
      <SectionList
        style={{ flex: 1 }}
        sections={
          getAppStyle(process.env.EXPO_APP_TYPE)?.toHideFeature
            ? options
                .filter((element) =>
                  element.data.some(
                    (subElement) =>
                      subElement.label !==
                      t('userInfoScreen.listOptions.weChat')
                  )
                )
                .map((element) => {
                  let n = Object.assign({}, element, {
                    data: element.data.filter(
                      (data) =>
                        data.label !== t('userInfoScreen.listOptions.weChat')
                    ),
                  });
                  return n;
                })
            : options
        }
        keyExtractor={(item, index) => item.type + index}
        renderItem={({ item }) => (
          <MenuItem
            title={item.label}
            value={item.value}
            route={item.route}
            type={item.type}
            editable={item.editable}
            button={item.button}
            onPress={item.onPress}
            buttonDisabled={item.buttonDisabled}
            buttonColor={item.buttonColor}
          />
        )}
        renderSectionHeader={({ section: { title } }) => (
          <Text style={{ opacity: 0 }}>{title}</Text>
        )}
        ListHeaderComponent={
          <ProfilePictureHeader
            thumbnailUri={newPicture ?? userInfo.user_picture}
          />
        }
        ListFooterComponent={
          <TouchableOpacity
            onPress={handleLogoutPress}
            style={{
              backgroundColor: 'white',
              paddingVertical: 10,
              marginVertical: 15,
            }}
          >
            <StyledText align="center">
              {t('userInfoScreen.logoutOption.logout')}
            </StyledText>
          </TouchableOpacity>
        }
      />
      <UnlinkWeChatModal
        visible={showUnlinkWeChatModal}
        setVisible={setShowUnlinkWeChatModal}
        onConfirm={(password) => handleUnlinkWeChat(password)}
      />
    </FullWidthView>
  );
};

type MenuItemProp = {
  title: string;
  route: string | null;
  value: string;
  type: string;
  editable: boolean;
  button: boolean;
  onPress: Function;
  buttonDisabled: boolean;
  buttonColor: string;
};

const MenuItem = ({
  title,
  route = '',
  value,
  type,
  editable = false,
  button = false,
  onPress = () => {},
  buttonDisabled = false,
  buttonColor,
}: MenuItemProp) => (
  <ListOption>
    {editable && route !== null ? (
      <NavigateButton
        route={route}
        options={{ type: type, title: title, value: value }}
      >
        <View style={styles.menuItemContainer}>
          <StyledText>{title}</StyledText>
          <View style={styles.valueContainer}>
            <StyledText>{value}</StyledText>
            <AntDesign
              name="rightcircleo"
              size={16}
              color="lightgray"
              style={{ marginLeft: 5 }}
            />
          </View>
        </View>
      </NavigateButton>
    ) : button ? (
      <View style={styles.menuItemContainer}>
        <StyledText>{title}</StyledText>
        <StyledButton
          label={value}
          buttonSize="small"
          outline
          disabled={buttonDisabled}
          onPress={() => onPress()}
          color={buttonColor}
        />
      </View>
    ) : (
      <View style={styles.menuItemContainer}>
        <StyledText>{title}</StyledText>
        <StyledText color="lightgray">{value}</StyledText>
      </View>
    )}
  </ListOption>
);

const linkWeChatAccount = async (weChatCode: string) => {
  let body = {
    code: weChatCode,
    oauth_type: 'wechat',
  };
  const result = postWithAuthToken('user/connect_account', body);
  return result;
};

const styles = StyleSheet.create({
  editThumbnailBtn: {
    backgroundColor: tintColorLight,
    width: 25,
    height: 25,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
  },
  menuItemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  valueContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default UserInfoScreen;
