import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { accentColor } from '../../../constants/Colors';
import StyledText from '../../common/StyledText';
import WhiteCard from '../../common/WhiteCard';

type ReferralStatusProps = {
  status: {
    number: number;
    amount: string;
  };
};

const ReferralStatus = ({
  status = { number: 0, amount: '$0.00' },
}: ReferralStatusProps) => {
  const { t } = useTranslation();

  return (
    <WhiteCard title={t('inviteFriendScreen.referralStatus.title')} centerTitle>
      <View style={styles.root}>
        <View>
          <StyledText color={accentColor} size={18} align="center">
            {status.amount}
          </StyledText>
          <StyledText align="center">
            {t('inviteFriendScreen.referralStatus.earned')}
          </StyledText>
        </View>
        <View>
          <StyledText color={accentColor} size={18} align="center">
            {status.number}
          </StyledText>
          <StyledText align="center">
            {t('inviteFriendScreen.referralStatus.invitations')}
          </StyledText>
        </View>
      </View>
    </WhiteCard>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  statusContainer: {},
});

export default ReferralStatus;
