import React, { useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import PostScreen from '../screens/Post/PostScreen';
import PostDetailScreen from '../screens/Post/PostDetailScreen';
import { PostParamList } from '../types';
import { useTranslation } from 'react-i18next';
import BackButton from '../components/common/iconButtons/BackButton';
import NavigateButton from '../components/common/NavigateButton';
import ReviewNavigator from './User/ReviewNavigator';

import {
  useRoute,
  useNavigation,
  getFocusedRouteNameFromRoute,
} from '@react-navigation/native';
import getAppStyle from '../utils/getAppStyle';

const PostStack = createStackNavigator<PostParamList>();

const PostNavigator = () => {
  const { t } = useTranslation();
  const route = useRoute();
  const navigation = useNavigation();

  //Hide Tab bar when route is not Cart home
  useEffect(() => {
    const activeRouteName = getFocusedRouteNameFromRoute(route);

    if (activeRouteName !== 'Post' && activeRouteName !== undefined) {
      navigation.setOptions({
        tabBarVisible: false,
      });
    }

    return () =>
      navigation.setOptions({
        tabBarVisible: true,
      });
  }, [route]);

  return (
    <PostStack.Navigator
      headerMode="screen"
      initialRouteName="Post"
      screenOptions={{
        headerBackTitleVisible: false,
        headerBackImage: BackButton,
        headerTitleAlign: 'center',
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <PostStack.Screen
        name="Post"
        component={PostScreen}
        options={{
          headerTitle: t('postScreen.title'),
        }}
      />
      <PostStack.Screen
        name="PostDetail"
        component={PostDetailScreen}
        options={{
          headerTitle: t('postScreen.postDetail.title'),
          headerLeft: () => (
            <NavigateButton route="Post">
              <BackButton />
            </NavigateButton>
          ),
        }}
      />
      <PostStack.Screen
        name="SubmitPost"
        component={ReviewNavigator}
        options={{ headerShown: false }}
      />
    </PostStack.Navigator>
  );
};

export default PostNavigator;
