import React, { useState, useEffect, useRef } from 'react';
import FullWidthView from '../../components/common/FullWidthView';
import Loading from '../../components/common/Loading';
import { getData } from '../../utils/service';
import { resetWechatSharePage } from '../../utils/wechatWebMethods';
import { RootState, SubCategoryData } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import Toast from 'react-native-tiny-toast';
import { StyleSheet, View, Dimensions, Image, Platform } from 'react-native';
import SubCategoriesList from '../../components/Category/container/SubCategoriesList';
import LevelThreeCategoryWithSwipe from '../../components/Category/container/level3/LevelThreeCategoryWithSwipe';
import ProductInCategory from '../../components/Category/container/ProductInCategory';
import { backGroundLight } from '../../constants/Colors';
import { useHeaderHeight } from '@react-navigation/stack';
import CachedImage from '../../components/common/cache-img/CachedImage';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useUpdateEffect } from 'ahooks';
import { screenHit } from '../../utils/analytic';
import GoodsActionToolbar from '../../components/Category/GoodsActionToolbar';

const { width } = Dimensions.get('window');

const HomeProductListScreen = ({ route }: any) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const isFocused = useIsFocused();

  const [catId] = useState(route.params.catId);
  const [data, setData] = useState<Array<SubCategoryData>>([]);
  const [levels, setLevels] = useState(0);
  const [loading, setLoading] = useState(false);
  const [homeProductListCatInfo, setHomeProductListCatInfo] = useState({
    name: '',
    icon: '',
  });
  const [categoryChangeState, setCategoryChangeState] =
    useState<boolean>(false);
  const [selectedSorter, setSelectedSorter] = useState<{
    sort_key?: string;
    sort_value?: string;
  }>({});
  const [horizontalIndex, setHorizontalIndex] = useState(0);
  const levelThreeCategoryRef = useRef<any>(null);
  const headerHeight = useHeaderHeight();

  const selectedMainCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedMainCategoryIndex
  );
  const selectedSubCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedSubCategoryIndex
  );
  const selectedHomeCategoryName = useSelector<RootState, string>(
    (state) => state.goods.selectedHomeCategoryName
  );

  const resetHorizontalIndex = () => {
    setHorizontalIndex(0);
    if (levelThreeCategoryRef.current) {
      levelThreeCategoryRef.current.resetHorizontalPage();
    }
  };

  useEffect(() => {
    screenHit(`category/${catId}`);
    dispatch({ type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX', payload: 0 });
    setLoading(true);
    //Fetch the list directly if goods type not equal to all
    if (
      (route.params.goodsType && route.params.goodsType !== 'all') ||
      (route.params.brandId && route.params.brandId !== 0)
    ) {
      setLevels(0);
      setLoading(false);
    } else {
      getData(`category/detail?id=${catId}`)
        .then((result) => {
          if (result) {
            if (result.goodStatus) {
              setData(result.data);
              dispatch({
                type: 'UPDATE_SUBCATEGORY_LENGTH',
                payload: result.data.length,
              });
              const categoryLevel = calculateLevels(result.data);
              setLevels(categoryLevel);
              //Set header title for level 2 category
              if (categoryLevel === 2) {
                if (
                  !selectedHomeCategoryName ||
                  selectedHomeCategoryName === ''
                ) {
                  navigation.setOptions({
                    headerTitle: result.data[0].name,
                  });
                }
              }
              setLoading(false);
            } else {
              throw result.data;
            }
          } else {
            console.log('error');
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          Toast.show('err happen in home product list screen' + err, {
            position: 0,
            duration: 2000,
          });
        });
    }

    return () => {
      dispatch({ type: 'UPDATE_SUBCATEGORY_LENGTH', payload: 0 });
      dispatch({ type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX', payload: 0 });
      dispatch({
        type: 'UPDATE_SELECTED_HOME_CATEGORY_NAME',
        payload: '',
      });
    };
  }, []);

  useUpdateEffect(() => {
    //Change header title if there is no title value
    if (!selectedHomeCategoryName || selectedHomeCategoryName === '') {
      if (
        data[selectedSubCategoryIndex] &&
        data[selectedSubCategoryIndex].name
      ) {
        navigation.setOptions({
          headerTitle: data[selectedSubCategoryIndex].name,
        });
      }
    }
  }, [data, selectedSubCategoryIndex]);

  const calculateLevels = (data: Array<any>) => {
    if (data.length > 0) {
      let atleastOneHasChildren = false;
      data.forEach((item: SubCategoryData) => {
        if (item.haschild === 1) {
          atleastOneHasChildren = true;
        }
      });
      return atleastOneHasChildren ? 3 : 2;
    } else {
      return 1;
    }
  };

  // Setting Wechat share link
  React.useLayoutEffect(() => {
    let shareLinkTitle: string;
    const level1Title = homeProductListCatInfo?.name ?? '';
    const level2Title = data[selectedSubCategoryIndex]?.name ?? '';
    const level3Title =
      data[selectedSubCategoryIndex]?.cat_id?.[horizontalIndex]?.name;
    // If level3 exists, use level2 and level3 for title. (The last two.)
    if (!level3Title) {
      // If 'selectedHomeCategoryName' exist use it with level2, otherwise use level1 + level2
      // This is for the page that has no top horizontal scroll menu.
      if (!selectedHomeCategoryName || selectedHomeCategoryName === '') {
        // level1Title could be the same as level2Title. In that case, just use level1Title.
        shareLinkTitle =
          level1Title === level2Title
            ? `${level1Title}`
            : `${level1Title} ${level2Title}`;
      } else {
        shareLinkTitle = `${selectedHomeCategoryName} | ${level2Title}`;
      }
    } else {
      shareLinkTitle = `${level2Title} | ${level3Title}`;
    }

    const imgUrl = homeProductListCatInfo?.icon ?? undefined;

    const link =
      Platform.OS === 'web'
        ? `${
            window.location.href.split('?')[0]
          }?subIndex=${selectedSubCategoryIndex}`
        : '';

    /*Because we are subscripting states from redux. We need to check if screen is focused.
      Otherwise this will be called even we are not in this screen  */
    if (Platform.OS === 'web' && isFocused)
      resetWechatSharePage(shareLinkTitle, undefined, imgUrl, link);
    // Set up Wechat share information "again" when user come back from the other page.
    const unsubscribe = navigation.addListener('focus', () => {
      if (Platform.OS === 'web')
        resetWechatSharePage(shareLinkTitle, undefined, imgUrl, link);
    });
    return unsubscribe;
  }, [
    data,
    levels,
    selectedMainCategoryIndex,
    selectedSubCategoryIndex,
    horizontalIndex,
    homeProductListCatInfo,
    navigation,
  ]);

  const updateSubCategoryChange = () => {
    categoryChangeState
      ? setCategoryChangeState(false)
      : setCategoryChangeState(true);
  };

  const renderContent = () => {
    //Has sub category show left-right layout
    if (levels === 3) {
      dispatch({ type: 'UPDATE_SUBCATEGORY_LENGTH', payload: data.length });
      return (
        <FullWidthView style={{ flexDirection: 'row' }}>
          <View style={styles.leftContainer}>
            <SubCategoriesList
              resetHorizontalIndex={resetHorizontalIndex}
              data={data}
              updateSubCategoryChange={updateSubCategoryChange}
            />
          </View>
          <View style={styles.rightContainer}>
            {/* Check Level three Category if exist */}
            {data[selectedSubCategoryIndex].haschild === 1 &&
            data[selectedSubCategoryIndex].cat_id &&
            Array.isArray(data[selectedSubCategoryIndex].cat_id) ? (
              <LevelThreeCategoryWithSwipe
                updateSubCategoryChange={updateSubCategoryChange}
                categoryChangeState={categoryChangeState}
                ref={levelThreeCategoryRef}
                horizontalIndex={horizontalIndex}
                setHorizontalIndex={setHorizontalIndex}
                width={width * 0.78 - 10}
                setHomeProductListCatInfo={setHomeProductListCatInfo}
                levelThreeCategories={
                  data[selectedSubCategoryIndex].cat_id || []
                }
                listHeader={
                  data[selectedSubCategoryIndex].banner.length > 0 ? (
                    <View style={{ marginVertical: 5 }}>
                      <Image
                        style={{
                          width: width * 0.78 - 10,
                          height: (width * 0.78 - 10) / 3,
                          borderRadius: 10,
                        }}
                        source={{
                          uri: data[selectedSubCategoryIndex].banner[0]
                            .cat_banner,
                        }}
                      />
                    </View>
                  ) : (
                    <></>
                  )
                }
                nextCategoryName={
                  data[selectedSubCategoryIndex + 1]
                    ? data[selectedSubCategoryIndex + 1].name
                    : data[0].name
                }
              />
            ) : (
              <>
                <View style={styles.toolbarContainer}>
                  <GoodsActionToolbar
                    leftSideMenuChangeState={categoryChangeState}
                    setSelectedSorter={setSelectedSorter}
                  />
                </View>
                <ProductInCategory
                  selectedSorter={selectedSorter}
                  catId={data[selectedSubCategoryIndex].id}
                  goodsType={
                    data[selectedSubCategoryIndex].goods_type !== undefined
                      ? data[selectedSubCategoryIndex].goods_type
                      : 'all'
                  }
                  listHeader={
                    data[selectedSubCategoryIndex].banner.length > 0 ? (
                      <View style={{ marginTop: 5 }}>
                        <Image
                          style={{
                            width: width * 0.78 - 10,
                            height: (width * 0.78 - 10) / 3,
                            borderRadius: 10,
                          }}
                          source={{
                            uri: data[selectedSubCategoryIndex].banner[0]
                              .cat_banner,
                          }}
                        />
                      </View>
                    ) : (
                      <></>
                    )
                  }
                  setHomeProductListCatInfo={setHomeProductListCatInfo}
                  nextCategoryName={
                    data[selectedSubCategoryIndex + 1]
                      ? data[selectedSubCategoryIndex + 1].name
                      : data[0].name
                  }
                />
              </>
            )}
          </View>
        </FullWidthView>
      );
    }
    //No sub category, show full width layout
    else if (levels === 2) {
      dispatch({ type: 'UPDATE_SUBCATEGORY_LENGTH', payload: data.length });

      if (selectedSubCategoryIndex <= data.length - 1)
        return (
          <FullWidthView style={{ flexDirection: 'row' }}>
            <View style={styles.leftContainer}>
              <SubCategoriesList
                resetHorizontalIndex={resetHorizontalIndex}
                data={data}
                updateSubCategoryChange={updateSubCategoryChange}
              />
            </View>

            <View style={styles.rightContainer}>
              <View style={styles.toolbarContainer}>
                <GoodsActionToolbar
                  leftSideMenuChangeState={categoryChangeState}
                  setSelectedSorter={setSelectedSorter}
                />
              </View>
              <ProductInCategory
                selectedSorter={selectedSorter}
                catId={
                  selectedSubCategoryIndex <= data.length - 1
                    ? data[selectedSubCategoryIndex].id
                    : data[0].id
                }
                goodsType={
                  data[selectedSubCategoryIndex].goods_type !== undefined
                    ? data[selectedSubCategoryIndex].goods_type
                    : 'all'
                }
                listHeader={
                  data[selectedSubCategoryIndex].banner.length > 0 ? (
                    <View style={{ marginTop: 5 }}>
                      <CachedImage
                        style={{
                          width: width * 0.78 - 10,
                          height: (width * 0.78 - 10) / 3,
                          borderRadius: 10,
                        }}
                        source={{
                          uri: data[selectedSubCategoryIndex].banner[0]
                            .cat_banner,
                        }}
                      />
                    </View>
                  ) : (
                    <></>
                  )
                }
                setHomeProductListCatInfo={setHomeProductListCatInfo}
                nextCategoryName={
                  data[selectedSubCategoryIndex + 1]
                    ? data[selectedSubCategoryIndex + 1].name
                    : data[0].name
                }
              />
            </View>
          </FullWidthView>
        );
      //Invalid index, reset to 0 index
      else {
        dispatch({ type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX', payload: 0 });
      }
    }
    //Empty data show plain Product List
    else {
      return (
        <FullWidthView edges={['bottom', 'left', 'right']}>
          <View style={styles.toolbarContainer}>
            <GoodsActionToolbar
              leftSideMenuChangeState={categoryChangeState}
              setSelectedSorter={setSelectedSorter}
            />
          </View>
          <ProductInCategory
            catId={catId}
            isHomeProductList={true}
            setHomeProductListCatInfo={setHomeProductListCatInfo}
            goodsType={route.params.goodsType}
            brandId={route.params.brandId}
            selectedSorter={selectedSorter}
          />
        </FullWidthView>
      );
    }
  };

  return (
    <FullWidthView style={styles.rootContainer}>
      {loading ? <Loading /> : renderContent()}
    </FullWidthView>
  );
};

export default HomeProductListScreen;

const styles = StyleSheet.create({
  rootContainer: {
    flexDirection: 'row',
  },
  leftContainer: {
    backgroundColor: backGroundLight,
    width: width * 0.22,
  },
  rightContainer: {
    width: width * 0.78 - 10,
    marginHorizontal: 5,
  },
  toolbarContainer: {
    paddingBottom: 3,
  },
});
