import React, { useEffect, useState, useRef } from 'react';
import { useUpdateEffect } from 'ahooks';
import {
  getGoodsListWithId,
  getGoodsUnderBrand,
  getGroupSaleGoodsListWithId,
} from '../../../utils/service';
import {
  ProductInformation,
  RootState,
  LocationInformation,
} from '../../../types';
import ProductList from './ProductList';
import { useSelector } from 'react-redux';
import ProductListSkeletonLoad from '../../common/ProductListSkeletonLoad';
import { useNavigation } from '@react-navigation/native';
import EmptyDisplayBlock from '../../common/EmptyDisplayBlock';

type ProductInCategoryProps = {
  catId: number;
  listHeader?: JSX.Element;
  isHomeProductList?: boolean;
  setHomeProductListCatInfo?: Function;
  brandId?: number;
  goodsType?: string;
  nextCategoryName?: string;
  productType?: 'regular' | 'groupsale';
  selectedSorter: {
    sort_key?: string | undefined;
    sort_value?: string | undefined;
  };
};

const ProductInCategory = ({
  catId,
  listHeader,
  isHomeProductList = false,
  setHomeProductListCatInfo,
  goodsType = 'all',
  nextCategoryName = '',
  brandId,
  productType = 'regular',
  selectedSorter,
}: ProductInCategoryProps) => {
  const navigation = useNavigation();

  const location = useSelector<RootState, LocationInformation>(
    (state) => state.goods.location
  );

  const selectedHomeCategoryName = useSelector<RootState, string>(
    (state) => state.goods.selectedHomeCategoryName
  );

  const [goodsArray, setGoodsArray] = useState<Array<ProductInformation>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstLoadComplete, setFirstLoadComplete] = useState(false);

  //Indicator for reach bottom scroll update
  const [productAllLoaded, setProductAllLoaded] = useState(false);

  // Check if ProductInCategory is still mounted
  const isSubscribed = useRef(true);

  const [refreshing, setRefreshing] = useState(false);

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  //Change Sub Category, fetch product at page 1
  useEffect(() => {
    if (JSON.stringify(selectedSorter) === '{}') return;
    loadFirstPage();
  }, [catId, goodsType, selectedSorter]);

  const loadFirstPage = () => {
    setRefreshing(true);
    setCurrentPage(1);
    setProductAllLoaded(false);
    setFirstLoadComplete(false);
    setGoodsArray([]);
    setIsFetching(true);

    //Show products under brand
    if (brandId) {
      getGoodsUnderBrand(
        catId,
        1,
        location.countryId,
        location.provinceId,
        location.cityId,
        10,
        goodsType,
        brandId
      )
        .then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) {
              setFirstLoadComplete(true);
              setGoodsArray(response.data.goods);
              if (
                isHomeProductList &&
                response.data.category &&
                response.data.category.name &&
                (!selectedHomeCategoryName || selectedHomeCategoryName === '')
              ) {
                navigation.setOptions({
                  headerTitle: response.data.category.name,
                });
              }
              //Product list less than 10, do not require bottom reach fetch
              response.data.goods.length < 10 && setProductAllLoaded(true);
            }
          } else {
            console.error(response);
          }
        })
        .finally(() => {
          setRefreshing(false);
          setIsFetching(false);
        });
    }
    //show normal product list
    else {
      (async () => {
        const response =
          productType === 'regular'
            ? await getGoodsListWithId(
                catId,
                1,
                location.countryId,
                location.provinceId,
                location.cityId,
                10,
                selectedSorter && selectedSorter.sort_key
                  ? selectedSorter.sort_key
                  : undefined,
                selectedSorter && selectedSorter.sort_value
                  ? selectedSorter.sort_value
                  : undefined,
                goodsType
              )
            : await getGroupSaleGoodsListWithId(catId, 1, 10, goodsType);
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setFirstLoadComplete(true);
            setGoodsArray(response.data.goods);
            // set HomeProductListScreen's category information. Will be used by Wechat share.
            if (setHomeProductListCatInfo && response.data.category) {
              setHomeProductListCatInfo({
                name: response.data.category.name,
                icon: response.data.category.icon,
              });
            }

            if (
              isHomeProductList &&
              response.data.category &&
              response.data.category.name &&
              (!selectedHomeCategoryName || selectedHomeCategoryName === '')
            ) {
              navigation.setOptions({
                headerTitle: response.data.category.name,
              });
            }

            //Product list less than 10, do not require bottom reach fetch
            response.data.goods.length < 10 && setProductAllLoaded(true);
          }
        } else {
          console.error(response);
        }
        setRefreshing(false);
        setIsFetching(false);
      })();
    }
  };

  //Scroll reach bottom, keep fetching different page products
  useUpdateEffect(() => {
    if (currentPage !== 1) {
      if (!productAllLoaded) {
        setIsFetching(true);
        //Show products under brand
        if (brandId) {
          getGoodsUnderBrand(
            catId,
            currentPage,
            location.countryId,
            location.provinceId,
            location.cityId,
            10,
            goodsType,
            brandId
          )
            .then((response) => {
              if (response && response.goodStatus) {
                if (isSubscribed.current) {
                  //Add new products into current goods array
                  setGoodsArray([...goodsArray, ...response.data.goods]);
                  //Product list less than 10, do not require bottom reach fetch
                  response.data.goods.length < 10 && setProductAllLoaded(true);
                }
              } else {
                console.error(response);
              }
            })
            .finally(() => {
              setIsFetching(false);
            });
        } else {
          (async () => {
            const response =
              productType === 'regular'
                ? await getGoodsListWithId(
                    catId,
                    currentPage,
                    location.countryId,
                    location.provinceId,
                    location.cityId,
                    10,
                    selectedSorter && selectedSorter.sort_key
                      ? selectedSorter.sort_key
                      : undefined,
                    selectedSorter && selectedSorter.sort_value
                      ? selectedSorter.sort_value
                      : undefined,
                    goodsType
                  )
                : await getGroupSaleGoodsListWithId(
                    catId,
                    currentPage,
                    10,
                    goodsType
                  );

            if (response && response.goodStatus) {
              if (isSubscribed.current) {
                //Add new products into current goods array
                setGoodsArray([...goodsArray, ...response.data.goods]);
                //Product list less than 10, do not require bottom reach fetch
                response.data.goods.length < 10 && setProductAllLoaded(true);
              }
            } else {
              console.error(response);
            }
            setIsFetching(false);
          })();
        }
      } else {
        console.log('Error cat id');
      }
    }
  }, [currentPage]);

  //When Scroll to Bottom, set page +1 to trigger useEffect to fetch new page product
  const handleReachBottomUpdate = () => {
    if (isFetching) return;
    !productAllLoaded && setCurrentPage(currentPage + 1);
  };

  return firstLoadComplete ? (
    goodsArray.length > 0 ? (
      <ProductList
        productAllLoaded={productAllLoaded}
        updatePage={handleReachBottomUpdate}
        data={goodsArray}
        listHeader={listHeader}
        isHomeProductList={isHomeProductList}
        nextCategoryName={nextCategoryName}
        productType={productType}
        onRefresh={loadFirstPage}
        refreshing={refreshing}
      />
    ) : (
      <EmptyDisplayBlock />
    )
  ) : (
    <ProductListSkeletonLoad />
  );
};

export default ProductInCategory;
