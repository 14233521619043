import React, { useState } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Platform,
  TextInput,
  Modal,
} from 'react-native';
import {
  Octicons,
  MaterialCommunityIcons,
  AntDesign,
} from '@expo/vector-icons';
import StyledText from '../../common/StyledText';
import {
  accentColor,
  backGroundLight,
  subTextColor,
} from '../../../constants/Colors';
import NavigateButton from '../../common/NavigateButton';
import { CreditCardInList } from '../../../types';
import ClearButton from '../../common/iconButtons/ClearButton';
import { useTranslation } from 'react-i18next';
import BottomAutoHeightModal from '../../common/BottomAutoHeightModal';
import { useNavigation } from '@react-navigation/native';
import { Tooltip } from 'react-native-elements';
import alert from '../../../utils/alert';

type CreditCardSelectorProps = {
  cardList: any;
  cardInfo: any;
  setCreditCard: Function;
  ruID: number;
  CVC: string | undefined;
  setCVC: Function;
};

const CreditCardSelector = ({
  cardList,
  cardInfo,
  setCreditCard,
  ruID,
  CVC,
  setCVC,
}: CreditCardSelectorProps) => {
  const [displayModal, setDisplayModal] = useState(false);
  const { t } = useTranslation();

  const _renderNumber = (cardNumber: string) => (
    <View style={styles.numberContainer}>
      {Array.from(Array(3)).map((_, index) => (
        <View key={index} style={styles.numberDots}>
          {Array.from(Array(4)).map((_, index) => (
            <Octicons
              key={index}
              name="dot-fill"
              style={{ paddingHorizontal: 2 }}
              size={10}
              color="black"
            />
          ))}
        </View>
      ))}
      <StyledText bold color={'black'}>
        {cardNumber.substring(cardNumber.length - 4, cardNumber.length)}
      </StyledText>
    </View>
  );

  return cardInfo && cardList ? (
    <View>
      {cardList.length === 0 ||
      (Array.isArray(cardInfo) && cardInfo.length === 0) ? (
        <View>
          <NavigateButton
            loginRequired
            route="NewCreditCardScreen"
            options={{ ruID: ruID }}
            style={styles.addCardButton}
          >
            <MaterialCommunityIcons
              name="credit-card-plus-outline"
              size={20}
              color="white"
              style={{ marginLeft: 2, marginRight: 6 }}
            />
            <StyledText color="white">
              {t('creditCard.addCreditCard')}
            </StyledText>
          </NavigateButton>
        </View>
      ) : (
        <>
          <View style={styles.selectedCard}>
            <View style={styles.cardInfo}>
              {_renderNumber(cardInfo.credit_card)}
            </View>
            <TouchableOpacity onPress={() => setDisplayModal(true)}>
              <AntDesign
                name="edit"
                size={20}
                color="lightgray"
                style={{ marginTop: 5 }}
              />
            </TouchableOpacity>
            <BottomAutoHeightModal
              visible={displayModal}
              hideModal={() => setDisplayModal(false)}
            >
              <CreditCardModal
                setDisplayModal={setDisplayModal}
                data={cardList}
                setCreditCard={setCreditCard}
                ruID={ruID}
              />
            </BottomAutoHeightModal>
          </View>
          <View style={styles.CVCField}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <StyledText color={subTextColor} allowFontScaling={false}>
                {t('validation.CVCHint') + ' '}
              </StyledText>
              {Platform.OS === 'web' ? (
                <TouchableOpacity
                  onPress={() => alert(t('validation.CVCTooltip'))}
                >
                  <AntDesign
                    name="questioncircleo"
                    size={16}
                    color="lightgray"
                  />
                </TouchableOpacity>
              ) : (
                <Tooltip
                  ModalComponent={Modal}
                  popover={<TooltipInfo />}
                  backgroundColor="black"
                  width={240}
                  height={100}
                >
                  <AntDesign
                    name="questioncircleo"
                    size={16}
                    color="lightgray"
                  />
                </Tooltip>
              )}
            </View>

            <TextInput
              placeholderTextColor={subTextColor}
              placeholder={'XXX'}
              maxLength={4}
              value={CVC}
              onChangeText={(text) => setCVC(text)}
              keyboardType={'number-pad'}
              returnKeyType={'done'}
              style={{
                marginHorizontal: 8,
                backgroundColor: backGroundLight,
                paddingVertical: 5,
                paddingHorizontal: 10,
                width: 50,
              }}
            />
          </View>
        </>
      )}
    </View>
  ) : (
    <></>
  );
};

const TooltipInfo = () => {
  const { t } = useTranslation();

  return (
    <View>
      <StyledText color="white" allowFontScaling={false}>
        {t('validation.CVCTooltip')}
      </StyledText>
    </View>
  );
};

type CreditCardModalProps = {
  setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
  data: Array<CreditCardInList>;
  setCreditCard: Function;
  ruID: number;
};

const CreditCardModal = ({
  setDisplayModal,
  data,
  setCreditCard,
  ruID,
}: CreditCardModalProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const _renderNumber = (cardNumber: string) => (
    <View style={styles.numberContainer}>
      {Array.from(Array(3)).map((_, index) => (
        <View key={index} style={styles.numberDots}>
          {Array.from(Array(4)).map((_, index) => (
            <Octicons
              key={index}
              name="dot-fill"
              style={{ paddingHorizontal: 2 }}
              size={10}
              color="white"
            />
          ))}
        </View>
      ))}
      <StyledText bold color={'white'}>
        {cardNumber.substring(cardNumber.length - 4, cardNumber.length)}
      </StyledText>
    </View>
  );

  const renderCards = data.map((item) => {
    return (
      <TouchableOpacity
        key={item.id}
        onPress={() => {
          setCreditCard(item);
          setDisplayModal(false);
        }}
      >
        <View style={styles.cardInList}>
          {_renderNumber(item.credit_card)}
          <View style={styles.expiryContainer}>
            <StyledText color={'white'}>
              {item.expiry_month}/{item.expiry_year}
            </StyledText>
          </View>
        </View>
      </TouchableOpacity>
    );
  });

  const handleAddCardPress = () => {
    setDisplayModal(false);
    navigation.navigate('NewCreditCardScreen', { ruID: ruID });
  };

  return (
    <View>
      <View style={styles.header}>
        <StyledText bold size={16}>
          {t('order.creditCard')}
        </StyledText>
        <ClearButton onPress={() => setDisplayModal(false)} size={26} />
      </View>
      <View>
        {renderCards}
        <View style={{ alignItems: 'center', marginTop: 10 }}>
          <TouchableWithoutFeedback onPress={() => handleAddCardPress()}>
            <AntDesign
              name="pluscircle"
              size={20}
              color="lightgray"
              style={{ marginLeft: 2, marginRight: 6 }}
            />
          </TouchableWithoutFeedback>
        </View>
      </View>
    </View>
  );
};

export default CreditCardSelector;

const styles = StyleSheet.create({
  numberContainer: {
    marginVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  numberDots: {
    flexDirection: 'row',
    marginRight: 20,
  },
  selectedCard: {
    flexDirection: 'row',
    flex: 1,
  },
  cardInfo: {
    flexDirection: 'column',
    marginRight: 10,
  },
  expiryContainer: {
    marginVertical: 5,
    flexDirection: 'row',
  },
  addCardButton: {
    backgroundColor: accentColor,
    alignSelf: 'flex-start',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: backGroundLight,
    borderBottomWidth: 1,
  },
  cardInList: {
    flexDirection: 'row',
    paddingHorizontal: 25,
    paddingVertical: 10,
    borderRadius: 10,
    marginHorizontal: 10,
    marginTop: 10,
    backgroundColor: accentColor,
    justifyContent: 'space-between',
  },
  CVCField: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    justifyContent: 'space-between',
  },
});
