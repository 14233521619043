import React, { useState } from 'react';
import { View, TextInput, StyleSheet } from 'react-native';
import WhiteCard from '../../common/WhiteCard';
import { subTextColor } from '../../../constants/Colors';
import { useTranslation } from 'react-i18next';
import { Text } from '../../Themed';

type RemarkInfoProps = {
  remark: string;
  setRemark?: React.Dispatch<React.SetStateAction<string>>;
};

const RemarkInfo = ({ remark, setRemark }: RemarkInfoProps) => {
  const { t } = useTranslation();
  const [height, setHeight] = useState(0);

  return (
    <WhiteCard title={t('order.remark')}>
      <View>
        <View style={styles.remarkContainer}>
          {setRemark ? 
          (<TextInput
            style={{height: Math.max(20, height)}}
            placeholderTextColor={subTextColor}
            placeholder={t('order.remarkPlaceholder')}
            onChangeText={(text) => setRemark(text)}
            value={remark}
            multiline={true}
            clearButtonMode='while-editing'
            onContentSizeChange={(event) => setHeight(event.nativeEvent.contentSize.height)}
          />) : (<Text>{remark}</Text>)
          }
        </View>
      </View>
    </WhiteCard>
  );
};

const styles = StyleSheet.create({
  remarkContainer: {
    marginVertical: 10,
  },
});

export default RemarkInfo;
