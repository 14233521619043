import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Platform, Dimensions } from 'react-native';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import InputField from './InputField';
import { Feather, AntDesign, Entypo, MaterialIcons } from '@expo/vector-icons';
import {
  GREY1,
  GREY2,
  GREY3,
  linkColor,
  normalTextColor,
} from '../../constants/Colors';
import {
  RegisterSchemaNormal,
  RegisterSchemaShopReg,
} from '../../utils/validation';
import ErrorMessage from './ErrorMessage';
import SendCodeButton from './SendCodeButton';
import { setAsyncStorageData } from '../../utils/asyncStorage';
import { useNavigation } from '@react-navigation/native';
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, TokenPayload } from '../../types';
import {
  registerForPushNotificationsAsync,
  registerUser,
  savePushToken,
} from '../../utils/service';
import Toast from 'react-native-tiny-toast';
import StyledButton from '../common/StyledButton';
import StyledText from '../common/StyledText';
import { ImageInfo } from 'expo-image-picker';
import MapBoxGeocoder from '../common/map/MapBoxGeocoder';
import ImagePickerModal from '../common/ImagePickerModal';
import ResponsiveImg from '../common/ResponsiveImg';
import * as Linking from 'expo-linking';

type RegisterFormProps = {
  selectedImage: ImageInfo[];
  setSelectedImage: React.Dispatch<React.SetStateAction<ImageInfo[]>>;
};

const RegisterForm = ({
  selectedImage,
  setSelectedImage,
}: RegisterFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState<boolean>(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [newAddressPayload, setNewAddressPayload] = useState<{
    name: string;
    location: { longitude: number; latitude: number };
  }>();
  const shopStatus = useSelector<
    RootState,
    { code: number; message: string; shopRegCompany: boolean }
  >((state) => state.goods.shopStatus);

  const [imageModalVisible, setImageModalVisible] = useState<boolean>(false);

  const handleRegister = async (
    phoneNumber: string,
    password: string,
    confirmPassword: string,
    code: string,
    redeem: string,
    companyName?: string,
    companyAddress?: string,
    businessLicense?: string
  ) => {
    setLoading(true);
    const loading = Toast.showLoading('', { mask: true });
    let referer: any = '';
    let redirectUrl: any = '';
    const url = await Linking.getInitialURL();
    if (url) {
      const { queryParams } = Linking.parse(url);
      if (queryParams) {
        if (queryParams.referer) {
          referer = queryParams.referer;
        }
        if (queryParams.redirect) {
          redirectUrl = queryParams.redirect;
        }
      }
    }

    registerUser(
      phoneNumber,
      password,
      confirmPassword,
      code,
      redeem,
      companyName,
      companyAddress,
      businessLicense,
      referer && { headers: { 'Referer-Platform': referer } }
    )
      .then((result) => {
        Toast.hide(loading);
        if (result && result.goodStatus) {
          //register success
          if (redirectUrl) {
            let newUrl = redirectUrl;
            if (
              result.data.third_party &&
              typeof result.data.third_party === 'object'
            ) {
              Object.keys(result.data.third_party).forEach((key, i) => {
                if (i === 0) {
                  newUrl += `?${key}=${result.data.third_party[key]}`;
                } else {
                  newUrl += `&${key}=${result.data.third_party[key]}`;
                }
              });
            }
            Linking.canOpenURL(newUrl)
              .then((supported) => {
                supported && Linking.openURL(newUrl);
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            setSelectedImage([]);
            setAsyncStorageData('@storage_AuthToken', result.data.token);
            const tokenInfo: TokenPayload = jwtDecode(result.data.token);
            dispatch({
              type: 'CHANGE_USER_STATUS',
              payload: {
                userInfo: {
                  name: tokenInfo.user_name,
                  phoneNumber: tokenInfo.mobile_phone,
                  userId: tokenInfo.user_id,
                },
                isLoggedIn: true,
              },
            });
            Platform.OS === 'web'
              ? navigation.canGoBack()
                ? navigation.goBack()
                : window.history.back()
              : navigation.goBack();

            //Save device expo push token if user grant
            if (Platform.OS === 'ios' || Platform.OS === 'android') {
              registerForPushNotificationsAsync().then(async (token) => {
                if (token && tokenInfo) {
                  savePushToken(token, {
                    id: tokenInfo.user_id,
                    exp: tokenInfo.exp,
                  });
                }
              });
            }
          }
        } else {
          throw result?.data;
        }
      })
      .catch((err) => {
        Toast.show(err, {
          position: 0,
          duration: 2000,
        });
      })
      .finally(() => {
        setLoading(false);

        if (loading) Toast.hide(loading);
      });
  };
  const { width } = Dimensions.get('window');

  useEffect(() => {
    if (selectedImage.length === 2) {
      let newImage: ImageInfo[] = selectedImage;
      setSelectedImage([newImage[1]]);
    }
  }, [selectedImage]);

  return (
    <View style={styles.registerForm}>
      <StyledText bold size={24} style={{ marginBottom: 10 }}>
        {t('AuthModal.createAccount')}
      </StyledText>
      <StyledText color={GREY2} size={15} style={{ marginBottom: 20 }}>
        {t('AuthModal.userInfo')}
      </StyledText>
      <Formik
        initialValues={
          shopStatus.shopRegCompany
            ? {
                phone: '',
                password: '',
                confirmPassword: '',
                code: '',
                redeem: '',
                companyName: '',
                companyAddress: '',
                businessLicense: '',
              }
            : {
                phone: '',
                password: '',
                confirmPassword: '',
                code: '',
                redeem: '',
              }
        }
        validationSchema={
          shopStatus.shopRegCompany
            ? RegisterSchemaShopReg
            : RegisterSchemaNormal
        }
        onSubmit={(values) => {
          // if (
          //   (shopStatus.shopRegCompany && stage === 2) ||
          //   (!shopStatus.shopRegCompany && stage === 1)
          // ) {
          handleRegister(
            values.phone,
            values.password,
            values.confirmPassword,
            values.code,
            values.redeem,
            values.companyName,
            values.companyAddress,
            values.businessLicense
          );
          // }
        }}
      >
        {({ handleChange, handleSubmit, values, errors, touched }) => (
          <View
            style={{
              alignItems: 'center',
            }}
          >
            <View /**User Info */
              style={{
                alignItems: 'center',
              }}
            >
              <InputField
                iconComponent={
                  <Feather
                    name="smartphone"
                    size={24}
                    color={normalTextColor}
                  />
                }
                onChangeText={handleChange('phone')}
                placeholder={t('AuthModal.enterPhoneNumber')}
                keyboardType="phone-pad"
                value={values.phone}
              />
              <View style={styles.errorMsgContainer}>
                {errors.phone && touched.phone && (
                  <ErrorMessage label={errors.phone} />
                )}
              </View>
              <InputField
                iconComponent={
                  <AntDesign name="lock" size={24} color={normalTextColor} />
                }
                buttonComponent={
                  <Entypo
                    name={hidePassword ? 'eye' : 'eye-with-line'}
                    size={24}
                    color="black"
                    onPress={() => setHidePassword((prev) => !prev)}
                  />
                }
                passwordField={hidePassword}
                onChangeText={handleChange('password')}
                placeholder={t('AuthModal.enterPassword')}
                value={values.password}
              />
              <View style={styles.errorMsgContainer}>
                {errors.password && touched.password && (
                  <ErrorMessage label={errors.password} />
                )}
              </View>
              <InputField
                iconComponent={
                  <AntDesign name="lock" size={24} color={normalTextColor} />
                }
                buttonComponent={
                  <Entypo
                    name={hideConfirmPassword ? 'eye' : 'eye-with-line'}
                    size={24}
                    color="black"
                    onPress={() => setHideConfirmPassword((prev) => !prev)}
                  />
                }
                passwordField={hideConfirmPassword}
                onChangeText={handleChange('confirmPassword')}
                placeholder={t('AuthModal.confirmPassword')}
                value={values.confirmPassword}
              />
              <View style={styles.errorMsgContainer}>
                {errors.confirmPassword && touched.confirmPassword && (
                  <ErrorMessage label={errors.confirmPassword} />
                )}
              </View>
              <View>
                <InputField
                  iconComponent={
                    <Entypo
                      name="key"
                      size={20}
                      style={{ marginRight: 3 }}
                      color={normalTextColor}
                    />
                  }
                  buttonComponent={
                    <SendCodeButton value={values.phone} type={'phone'} />
                  }
                  onChangeText={handleChange('code')}
                  placeholder={t('AuthModal.enterCode')}
                  keyboardType="number-pad"
                  value={values.code}
                  maxLength={6}
                />
              </View>
              <View style={styles.errorMsgContainer}>
                {errors.code && touched.code && (
                  <ErrorMessage label={errors.code} />
                )}
              </View>
              <View>
                <InputField
                  iconComponent={
                    <MaterialIcons
                      name="redeem"
                      size={24}
                      color={normalTextColor}
                    />
                  }
                  onChangeText={handleChange('redeem')}
                  placeholder={`${t('AuthModal.enterRedeemCode')} (${t(
                    'AuthModal.optional'
                  )})`}
                  value={values.redeem}
                />
              </View>
              <View style={styles.errorMsgContainer}>
                {errors.redeem && touched.redeem && (
                  <ErrorMessage label={errors.redeem} />
                )}
              </View>
            </View>
            {shopStatus.shopRegCompany /**Only For Grand Intl, company info */ && (
              <View>
                <View style={{ alignItems: 'center' }}>
                  <StyledText
                    color={GREY2}
                    size={15}
                    style={{ marginBottom: 10 }}
                  >
                    {t('AuthModal.companyInfo')}
                  </StyledText>
                  <InputField
                    onChangeText={handleChange('companyName')}
                    placeholder={t('AuthModal.companyName')}
                    keyboardType="default"
                    value={values.companyName}
                  />
                  <View style={styles.errorMsgContainer}>
                    {errors.companyName && touched.companyName && (
                      <ErrorMessage label={errors.companyName} />
                    )}
                  </View>
                  <View
                    style={{
                      borderWidth: 1,
                      borderColor: GREY1,
                      backgroundColor: 'white',
                      zIndex: 100,
                      width: width * 0.75,
                      borderRadius: 25,
                      alignSelf: 'center',
                    }}
                  >
                    <MapBoxGeocoder
                      value={
                        newAddressPayload ? newAddressPayload.name : undefined
                      }
                      setNewAddressPayload={setNewAddressPayload}
                    />
                  </View>
                  <View style={styles.errorMsgContainer}>
                    {errors.companyAddress && touched.companyAddress && (
                      <ErrorMessage label={errors.companyAddress} />
                    )}
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  <StyledText color={GREY3} align="left">
                    {`${t('AuthModal.companyLicense')}: `}
                  </StyledText>
                  <StyledText
                    color={linkColor}
                    onPress={() => setImageModalVisible(true)}
                  >
                    {t('AuthModal.upload')}
                  </StyledText>
                </View>
                <View style={{ paddingTop: 10, alignItems: 'center' }}>
                  {selectedImage.length > 0 && (
                    <View>
                      {selectedImage.map((image, index) => {
                        return (
                          <ResponsiveImg
                            key={index}
                            uri={image.uri}
                            width={100}
                          />
                        );
                      })}
                    </View>
                  )}
                  <ImagePickerModal
                    modalVisible={imageModalVisible}
                    setModalVisible={setImageModalVisible}
                    setImages={setSelectedImage}
                  />
                  <View style={styles.errorMsgContainer}>
                    {errors.businessLicense && touched.businessLicense && (
                      <ErrorMessage label={errors.businessLicense} />
                    )}
                  </View>
                </View>
              </View>
            )}
            <View /**Register Buttons */>
              <StyledButton
                disabled={loading}
                label={t('AuthModal.register')}
                onPress={() => {
                  if (
                    shopStatus.shopRegCompany &&
                    selectedImage &&
                    selectedImage.length === 1
                  ) {
                    values.businessLicense = selectedImage[0].base64;
                  }
                  if (shopStatus.shopRegCompany && newAddressPayload) {
                    values.companyAddress = newAddressPayload.name;
                  }
                  handleSubmit();
                }}
              />
            </View>
          </View>
        )}
      </Formik>
    </View>
  );
};

export default RegisterForm;

const styles = StyleSheet.create({
  thumbnailContainer: {
    borderRadius: 100,
    width: 30,
    height: 30,
    backgroundColor: 'lightgray',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginVertical: 10,
  },
  idPhotoPlaceHolder: {
    paddingVertical: 5,
    alignItems: 'center',
  },
  registerForm: {
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  errorMsgContainer: {
    height: 24,
  },
});
