import { AntDesign } from '@expo/vector-icons';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useMount } from 'ahooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import FullWidthView from '../../../components/common/FullWidthView';
import StyledText from '../../../components/common/StyledText';
import { backGroundLight } from '../../../constants/Colors';
import { OrderParamList } from '../../../types';
import { getDataWithAuthToken } from '../../../utils/service';

const OrderCheckScreen = () => {
  const navigation = useNavigation();
  const route = useRoute<RouteProp<OrderParamList, 'OrderCheck'>>();
  const { t } = useTranslation();

  const { id } = route.params;

  useMount(() => {
    if (id) {
      checkPaymentResult(id).then((result) => {
        if (result && result.goodStatus) {
          //Pay Successfully!
          if (result.data.pay_status) {
            navigation.navigate('OrderResult', {
              orderId: result.data.order_id,
              status: 'PAY_SUCCESS',
              redPacket: result.data.share_red_packet,
            });
          } else {
            navigation.navigate('OrderResult', {
              orderId: result.data.order_id,
              status: 'APP_PAY_FAIL',
            });
          }
        } else {
          navigation.navigate('Home');
        }
      });
    } else {
      navigation.navigate('Home');
    }
  });

  return (
    <FullWidthView>
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <AntDesign name="clockcircle" size={80} color={backGroundLight} />
        <StyledText align="center" size={16}>
          {t('order.orderProcessing')}
        </StyledText>
      </View>
    </FullWidthView>
  );
};

const checkPaymentResult = async (partnerOrderId: string) => {
  const result = await getDataWithAuthToken(
    `order/check?order_id=${partnerOrderId}`
  );
  return result;
};

export default OrderCheckScreen;
