import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useState } from 'react';
import {
  ActivityIndicator,
  Image,
  StyleSheet,
  TouchableOpacity,
  Modal,
  View,
} from 'react-native';
import alert from '../../../utils/alert';
import StyledText from '../../common/StyledText';
import * as ExpoImagePicker from 'expo-image-picker';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import ImageViewer from 'react-native-image-zoom-viewer';
import { useTranslation } from 'react-i18next';

type ImagePickerProps = {
  images: ImageInfo[];
  setImages: (value: ImageInfo[]) => void;
  maxImages?: number;
};

/**
 * Getting picture from device , using in order review section
 */
const ImagePicker = ({
  images,
  setImages,
  maxImages = 9,
}: ImagePickerProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [modalImages, setModalImages] = useState<any>([]);
  const { t } = useTranslation();

  const handlePressAddImageBtn = () => {
    alert(
      t('review.addAPhoto'),
      '',
      [
        {
          text: t('review.takeAPhoto'),
          onPress: () => takePhoto(),
        },
        {
          text: t('review.chooseFromAlbum'),
          onPress: () => pickImage(),
        },
      ],
      { cancelable: false }
    );
  };

  const takePhoto = async () => {
    const { granted } = await ExpoImagePicker.requestCameraPermissionsAsync();
    if (!granted) {
      alert(t('review.enableCameraPermissions'));
    } else {
      let result = await ExpoImagePicker.launchCameraAsync({
        mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
        quality: 0.5,
        allowsMultipleSelection: false,
        base64: true,
      });

      if (!result.cancelled) {
        const newImage = result;
        setImages([...images, newImage]);
      }
    }
  };

  const pickImage = async () => {
    const { granted } =
      await ExpoImagePicker.requestMediaLibraryPermissionsAsync();
    if (!granted) {
      alert(t('review.enableCameraPermissions'));
    } else {
      let result = await ExpoImagePicker.launchImageLibraryAsync({
        mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
        quality: 0.5,
        allowsMultipleSelection: false,
        base64: true,
      });

      if (!result.cancelled) {
        const newImage = result;
        setImages([...images, newImage]);
      }
    }
  };

  const handleImages = (images: Array<ImageInfo>) => {
    let imageUrls: Array<any> = [];
    images.forEach((element: ImageInfo) => {
      imageUrls.push({
        url: element.uri,
        props: {
          source: {
            uri: element.uri,
          },
        },
      });
    });
    return imageUrls;
  };

  const deleteImage = (deleteIndex: number) => {
    const newImages = images.filter((image, index) => index !== deleteIndex);
    setImages(newImages);
    setModalImages(newImages);
  };

  const handleDeleteImage = (currentIndex: number) => {
    alert(
      t('cartScreen.deletePrompt'),
      '',
      [
        {
          text: t('cancel'),
          style: 'cancel',
        },
        {
          text: t('confirm'),
          onPress: () => deleteImage(currentIndex),
        },
      ],
      { cancelable: false }
    );
  };

  return (
    <View style={styles.container}>
      <StyledText>{t('review.maxPhotos', { maxNumber: maxImages })}</StyledText>
      <View style={styles.imageList}>
        {images.length > 0 &&
          images.map((item: any, index) => {
            return (
              <View
                key={index}
                style={[styles.imageInList, { position: 'relative' }]}
              >
                <TouchableOpacity
                  onPress={() => {
                    setModalImages(handleImages(images));
                    setImageIndex(index);
                    setModalVisible(true);
                  }}
                >
                  <Image
                    source={{ uri: item.uri }}
                    style={styles.imageInList}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    position: 'absolute',
                    top: -5,
                    left: -5,
                    zIndex: 10,
                    width: 24,
                    height: 24,
                  }}
                  onPress={() => handleDeleteImage(index)}
                >
                  <AntDesign name="minuscircle" size={24} color="red" />
                </TouchableOpacity>
              </View>
            );
          })}
        {images.length < maxImages && (
          <TouchableOpacity onPress={handlePressAddImageBtn}>
            <View
              style={[
                styles.imageInList,
                {
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#f4f4f4',
                },
              ]}
            >
              <MaterialCommunityIcons
                name="camera-plus-outline"
                size={24}
                color="gray"
              />
            </View>
          </TouchableOpacity>
        )}
      </View>
      {modalVisible && (
        <View>
          <Modal visible={modalVisible} transparent={true}>
            <ImageViewer
              index={imageIndex}
              loadingRender={() => <ActivityIndicator />}
              swipeDownThreshold={0.2}
              onCancel={() => setModalVisible(false)}
              enableSwipeDown={true}
              imageUrls={modalImages}
              renderHeader={(currentIndex: any) => {
                return (
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      backgroundColor: 'black',
                      padding: 10,
                      marginTop: 15,
                      alignItems: 'flex-end',
                    }}
                  >
                    <TouchableOpacity onPress={() => setModalVisible(false)}>
                      <StyledText color={'white'}>{t('goBack')}</StyledText>
                    </TouchableOpacity>
                    <></>
                  </View>
                );
              }}
              renderIndicator={(currentIndex, allSize) => {
                return (
                  <View style={styles.imageIndexCounter}>
                    <StyledText color={'white'}>
                      {`${currentIndex}/${allSize}`}
                    </StyledText>
                  </View>
                );
              }}
            />
          </Modal>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  imageList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginTop: 10,
  },
  imageInList: {
    width: 95,
    height: 95,
    marginRight: 10,
    marginBottom: 10,
  },
  imageIndexCounter: {
    position: 'absolute',
    top: 30,
    alignSelf: 'center',
    backgroundColor: 'black',
    width: 30,
  },
});

export default ImagePicker;
