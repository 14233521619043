import React, { useEffect, useState } from 'react';
import {
  StyleSheet,
  View,
  TextInput,
  Platform,
  FlatList,
  Image,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import StepIndicator from 'react-native-step-indicator';
import FullWidthView from '../common/FullWidthView';
import StyledButton from '../common/StyledButton';
import ImagePicker from '../User/SelfService/SelfServiceImagePicker';
import {
  ProductParamList,
  RootState,
  SelfServiceStepSection,
  SelfServiceTicket,
  UserParamList,
} from '../../types';
import {
  createTicket,
  getTicketSteps,
  uploadTicketFiles,
} from '../../utils/service';
import StyledText from '../common/StyledText';
import { linkColor, subTextColor, warningRed } from '../../constants/Colors';
import { useTranslation } from 'react-i18next';
import RNPickerSelect from 'react-native-picker-select';
import alert from '../../utils/alert';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
// @ts-ignore
import Toast from 'react-native-tiny-toast';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import { navigateBasedOnURL } from '../../utils/linkingRoute';
import NavigateButton from '../common/NavigateButton';
import { AntDesign } from '@expo/vector-icons';
import LiveChat from './CustomerServiceModal/LiveChat';
import BackButton from '../common/iconButtons/BackButton';
import Loading from '../common/Loading';
import { useSelector } from 'react-redux';

const CustomerServiceModal = () => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const [ticket, setTicket] = useState<SelfServiceTicket>();
  const [tickets, setTickets] = useState<SelfServiceTicket[]>([]);
  const [stepLabels, setStepLabels] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState<any>({});
  const [info, setInfo] = useState<any>({});
  const { t } = useTranslation();
  const navigation = useNavigation();
  const route =
    useRoute<
      RouteProp<ProductParamList | UserParamList, 'CustomerServiceModal'>
    >();
  let requiredList: string[] = [];
  const [showLiveChat, setShowLiveChat] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.user.isLoggedIn
  );
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) setIsLoading(true);
    getTicketSteps()
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (Array.isArray(response.data) && response.data.length) {
              if (isSubscribed) {
                setTickets(response.data);
                setShowLiveChat(false);
              }
            } else {
              if (isSubscribed) setShowLiveChat(true);
            }
            if (isSubscribed) setIsLoading(false);
          } else {
            console.log(response.data);
          }
        } else {
          console.log('no response');
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      isSubscribed = false;
    };
  }, [isLoggedIn]);

  const renderTicketItem = ({ item }: { item: SelfServiceTicket }) => {
    return (
      <View style={styles.ticketItemContainer}>
        <StyledButton
          label={item.name}
          labelColor={item.style.textColor}
          color={item.style.backgroundColor}
          onPress={() => {
            if (!item.step) {
              setShowLiveChat(true);
            } else {
              setTicket(item);
              let labels = [];
              if (item.step_progress_bar.length) {
                for (let label of item.step_progress_bar) {
                  labels.push(label.name);
                }
                setStepLabels([
                  ...labels,
                  t('selfServiceScreen.requestReceived'),
                ]);
              }
              setCurrentStep(item.step);
            }
          }}
        />
        <StyledText size={14} color={subTextColor} align="center">
          {item.desc}
        </StyledText>
      </View>
    );
  };

  const renderTickets = (items: SelfServiceTicket[]) => {
    return (
      <FullWidthView style={styles.ticketsContainer}>
        <FlatList
          data={items}
          showsVerticalScrollIndicator={false}
          keyExtractor={(item) => item.ticket_type.toString()}
          renderItem={renderTicketItem}
        />
      </FullWidthView>
    );
  };

  const renderStep = (items: SelfServiceStepSection[]) => {
    let children: JSX.Element[] = [];
    for (let e of items) {
      switch (e.type) {
        case 'options':
          let options = [];
          if (e.range && e.range.length) {
            for (let i = 0; i < e.range.length; i++) {
              options.push({ key: i, value: i, label: e.range[i] });
            }
          }
          if (e.is_required) requiredList.push(e.code);
          children.push(
            <View key={e.code}>
              <View style={styles.contentItemTitle}>
                <StyledText size={18} bold>
                  {e.name}
                </StyledText>
                {e.is_required ? (
                  <StyledText color={warningRed} size={18}>
                    *
                  </StyledText>
                ) : (
                  <StyledText size={18}>
                    {` (${t('AuthModal.optional')})`}
                  </StyledText>
                )}
              </View>
              <View>
                <RNPickerSelect
                  placeholder={{
                    key: 'pleaseSelect',
                    value: '',
                    label: t('selfServiceScreen.pleaseSelect'),
                  }}
                  value={info[e.code] ? info[e.code].value : ''}
                  items={options}
                  onValueChange={(value, index) =>
                    setInfo((prev: any) => ({
                      ...prev,
                      [e.code]:
                        value !== ''
                          ? { label: e.range[index], value: parseInt(value) }
                          : undefined,
                    }))
                  }
                  useNativeAndroidPickerStyle={false}
                  style={{
                    inputWeb: {
                      color: 'black',
                      borderWidth: 1,
                      borderRadius: 8,
                      borderColor: 'lightgray',
                      padding: 10,
                    },
                    inputAndroid: {
                      color: 'black',
                      borderWidth: 1,
                      borderRadius: 8,
                      borderColor: 'lightgray',
                      padding: 10,
                    },
                    inputIOS: {
                      color: 'black',
                      borderWidth: 1,
                      borderRadius: 8,
                      borderColor: 'lightgray',
                      padding: 10,
                    },
                  }}
                />
              </View>
            </View>
          );
          break;
        case 'textarea':
          if (e.is_required) requiredList.push(e.code);
          children.push(
            <View key={e.code}>
              <View style={styles.contentItemTitle}>
                <StyledText size={18} bold>
                  {e.name}
                </StyledText>
                {e.is_required ? (
                  <StyledText color={warningRed} size={18}>
                    *
                  </StyledText>
                ) : (
                  <StyledText size={18}>
                    {` (${t('AuthModal.optional')})`}
                  </StyledText>
                )}
              </View>
              <TextInput
                placeholder={e.desc}
                value={info[e.code] || ''}
                onChangeText={(value) =>
                  setInfo((prev: any) => ({ ...prev, [e.code]: value }))
                }
                multiline
                style={[
                  styles.input,
                  { minHeight: 100, textAlignVertical: 'top' },
                ]}
              />
            </View>
          );
          break;
        case 'text':
          if (e.is_required) requiredList.push(e.code);
          children.push(
            <View key={e.code}>
              <View style={styles.contentItemTitle}>
                <StyledText size={18} bold>
                  {e.name}
                </StyledText>
                {e.is_required ? (
                  <StyledText color={warningRed} size={18}>
                    *
                  </StyledText>
                ) : (
                  <StyledText size={18}>
                    {` (${t('AuthModal.optional')})`}
                  </StyledText>
                )}
              </View>
              <TextInput
                placeholder={e.desc}
                value={info[e.code] || ''}
                onChangeText={(value) =>
                  setInfo((prev: any) => ({ ...prev, [e.code]: value }))
                }
                style={styles.input}
              />
            </View>
          );
          break;
        case 'file':
          if (e.is_required) requiredList.push(e.code);
          children.push(
            <View key={e.code}>
              <View style={styles.contentItemTitle}>
                <StyledText size={18} bold>
                  {e.name}
                </StyledText>
                {e.is_required ? (
                  <StyledText color={warningRed} size={18}>
                    *
                  </StyledText>
                ) : (
                  <StyledText size={18}>
                    {` (${t('AuthModal.optional')})`}
                  </StyledText>
                )}
              </View>
              <StyledText size={14} color={subTextColor} align="center">
                {e.desc}
              </StyledText>
              <View style={{ paddingLeft: 10 }}>
                <ImagePicker
                  images={
                    info.files && info.files[e.code] ? info.files[e.code] : []
                  }
                  setImages={(value) =>
                    setInfo((prev: any) => ({
                      ...prev,
                      files: { ...prev.files, [e.code]: value },
                    }))
                  }
                  maxImages={e.max_num}
                />
              </View>
            </View>
          );
          break;
        case 'button':
          children.push(
            <View key={e.code} style={styles.ticketItemContainer}>
              <StyledButton
                label={e.name}
                labelColor={e.style.textColor}
                color={e.style.backgroundColor}
                onPress={() =>
                  navigateBasedOnURL({
                    navigation: navigation,
                    type: e.url.type,
                    id: e.url.id,
                    url_type: e.url.url_type,
                    url: e.url.url,
                    appId: e.url.appid,
                  })
                }
              />
              <StyledText size={14} color={subTextColor} align="center">
                {e.desc}
              </StyledText>
            </View>
          );
          break;
        case 'link':
          children.push(
            <View key={e.code} style={{ paddingVertical: 10 }}>
              <StyledText
                size={14}
                color={linkColor}
                align="center"
                onPress={() =>
                  navigateBasedOnURL({
                    navigation: navigation,
                    type: e.url.type,
                    id: e.url.id,
                    url_type: e.url.url_type,
                    url: e.url.url,
                    appId: e.url.appid,
                  })
                }
              >
                {e.name}
              </StyledText>
            </View>
          );
          break;
      }
    }
    return (
      <ScrollView keyboardDismissMode="on-drag" style={styles.contentContainer}>
        {children}
      </ScrollView>
    );
  };

  const renderConfirmation = (item: SelfServiceTicket) => {
    return (
      <ScrollView style={styles.contentContainer}>
        <View style={{ paddingVertical: 10 }}>
          <StyledText size={18} bold>
            {t('selfServiceScreen.service')}
          </StyledText>
          <StyledText>{item.name}</StyledText>
        </View>
        {!!Object.keys(info).length &&
          Object.keys(info).map(
            (code) =>
              item.step[code] && (
                <View key={code} style={{ paddingVertical: 10 }}>
                  <StyledText size={18} bold>
                    {item.step[code].name}
                  </StyledText>
                  {typeof info[code] === 'string' && (
                    <StyledText>{info[code]}</StyledText>
                  )}
                </View>
              )
          )}
        {info.files && !!Object.values(info.files).length && (
          <View style={{ paddingVertical: 10 }}>
            <StyledText size={18} bold>
              {t('selfServiceScreen.images')}
            </StyledText>
            <View style={styles.imageList}>
              {Object.values(info.files).map((images: any, i: number) => {
                return images.map((image: any, j: number) => (
                  <View
                    style={{ marginBottom: 10, marginRight: 10 }}
                    key={`${i}-${j}`}
                  >
                    <Image
                      source={{ uri: image.uri }}
                      style={{ width: 95, height: 95 }}
                    />
                  </View>
                ));
              })}
            </View>
          </View>
        )}
      </ScrollView>
    );
  };

  const handleDisable = () => {
    if (requiredList.length) {
      for (let code of requiredList) {
        if (info[code] === undefined || info[code] === '') {
          if (info.files) {
            if (info.files[code]) {
              if (Array.isArray(info.files[code]) && !info.files[code].length) {
                return true;
              }
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      }
    }
    return false;
  };

  const handleGoBack = () => {
    setTicket(undefined);
    setStepLabels([]);
    setInfo({});
    setCurrentStep({});
    setCurrentPosition(0);
  };

  const handleOnNext = (ticket: SelfServiceTicket, currentStep: any) => {
    if (
      Object.keys(currentStep).includes('photo') ||
      (currentStep.step && Object.keys(currentStep.step).includes('photo'))
    ) {
      alert(
        Platform.OS === 'android' ? '' : t('selfServiceScreen.nextWarning'),
        Platform.OS === 'android' ? t('selfServiceScreen.nextWarning') : '',
        [
          { text: t('cancel'), style: 'cancel' },
          {
            text: t('confirm'),
            onPress: () => onConfirm(),
          },
        ]
      );
    }

    const onConfirm = () => {
      const loading = Toast.showLoading('');
      if (currentStep.step && Object.keys(currentStep.step).includes('photo')) {
        createTicket({
          ticket_type: ticket.ticket_type,
          ...info,
          return_reason: info.return_reason
            ? info.return_reason.value
            : undefined,
        })
          .then((response) => {
            if (response && response.goodStatus) {
              Toast.hide(loading);
              setInfo((prev: any) => ({
                ...prev,
                ticket_id: response.data.ticket_id,
                return_reason: prev.return_reason
                  ? prev.return_reason.label
                  : undefined,
              }));
              if (currentStep.step) setCurrentStep(currentStep.step);
              setCurrentPosition((prev) => prev + 1);
            } else {
              Toast.hide(loading);
              Toast.show(response?.data || 'no response', { position: 0 });
            }
          })
          .catch((err) => {
            Toast.hide(loading);
            console.log(err);
          });
      } else if (Object.keys(currentStep).includes('photo') && info.files) {
        if (!info.ticket_id) {
          Toast.hide(loading);
          Toast.show(t('selfServiceScreen.noTicketId'), { position: 0 });
          return;
        }

        let photos: string[] = [];
        let files: ImageInfo[][] = Object.values(info.files);
        if (files.length)
          for (let images of files) {
            if (images.length) {
              for (let image of images) {
                if (Platform.OS === 'web') {
                  photos.push(image.uri);
                } else {
                  photos.push('data:image/jpeg;base64,' + image.base64);
                }
              }
            }
          }
        if (photos.length) {
          uploadTicketFiles({ ticket_id: info.ticket_id, image: photos })
            .then((response) => {
              if (response && response.goodStatus) {
                Toast.hide(loading);
                if (currentStep.step) setCurrentStep(currentStep.step);
                setCurrentPosition((prev) => prev + 1);
              } else {
                Toast.hide(loading);
                Toast.show(response?.data || 'no response', { position: 0 });
              }
            })
            .catch((err) => {
              Toast.hide(loading);
              console.log(err);
            });
        } else {
          Toast.hide(loading);
          if (currentStep.step) setCurrentStep(currentStep.step);
          setCurrentPosition((prev) => prev + 1);
        }
      } else {
        Toast.hide(loading);
        if (currentStep.step) setCurrentStep(currentStep.step);
        setCurrentPosition((prev) => prev + 1);
      }
    };
  };

  const handleOnFinish = () => {
    navigation.reset({
      index: 0,
      routes: [
        {
          name: 'Root',
          params: {
            screen: 'User',
            params: { screen: 'UserProfile' },
          },
        },
      ],
    });
  };

  const handleGoBackPress = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      if (Platform.OS === 'web') {
        //@ts-ignore
        window.history.back();
        navigation.reset({
          index: 0,
          routes: [{ name: 'Root' }],
        });
      } else {
        navigation.reset({
          index: 0,
          routes: [{ name: 'Root' }],
        });
      }
    }
  };

  return showLiveChat ? (
    <FullWidthView edges={['bottom', 'left', 'right', 'top']}>
      <View style={styles.header}>
        {tickets.length > 0 && (
          <TouchableOpacity
            onPress={() => {
              handleGoBack();
              setShowLiveChat(false);
            }}
            style={{ position: 'absolute', left: 8 }}
          >
            <BackButton />
          </TouchableOpacity>
        )}
        <StyledText size={18} align="center">
          {t('userProfileScreen.otherMenu.customerService')}
        </StyledText>
        <NavigateButton goBack style={{ position: 'absolute', right: 8 }}>
          <AntDesign name="close" size={30} />
        </NavigateButton>
      </View>
      {isLoading ? (
        <Loading />
      ) : (
        <View style={[styles.ticketsContainer]}>
          <LiveChat ruId={route.params ? route.params.ruId : undefined} />
        </View>
      )}
    </FullWidthView>
  ) : (
    <FullWidthView edges={['bottom', 'left', 'right', 'top']}>
      <View style={styles.header}>
        {ticket ? (
          <TouchableOpacity
            style={{ position: 'absolute', left: 8 }}
            onPress={handleGoBack}
          >
            <BackButton />
          </TouchableOpacity>
        ) : (
          <NavigateButton goBack style={{ position: 'absolute', left: 8 }}>
            <AntDesign name="close" size={30} />
          </NavigateButton>
        )}
        <StyledText size={18} align="center">
          {ticket
            ? ticket.name
            : t('userProfileScreen.otherMenu.customerService')}
        </StyledText>
        {ticket && (
          <NavigateButton style={{ position: 'absolute', right: 8 }}>
            <AntDesign
              name="close"
              size={30}
              onPress={() => {
                {
                  if (currentPosition === 1 || currentPosition === 2) {
                    alert(
                      Platform.OS === 'android'
                        ? ''
                        : t('selfServiceScreen.closeWarning'),
                      Platform.OS === 'android'
                        ? t('selfServiceScreen.closeWarning')
                        : '',
                      [
                        { text: t('cancel'), style: 'cancel' },
                        {
                          text: t('confirm'),
                          onPress: () => handleGoBackPress(),
                        },
                      ]
                    );
                  } else {
                    handleGoBackPress();
                  }

                  // }
                }
              }}
            />
          </NavigateButton>
        )}
      </View>
      {isLoading ? (
        <Loading />
      ) : (
        <View style={{ flex: 1, overflow: 'scroll' }}>
          {ticket === undefined && renderTickets(tickets)}
          {ticket && (
            <View style={{ flex: 1, paddingHorizontal: 20, paddingBottom: 20 }}>
              {!!ticket.step_progress_bar.length && (
                <StepIndicator
                  labels={stepLabels}
                  stepCount={stepLabels.length}
                  currentPosition={currentPosition}
                />
              )}
              {currentPosition === stepLabels.length - 1
                ? renderConfirmation(ticket)
                : currentStep && renderStep(Object.values(currentStep))}
              <View
                style={[
                  styles.buttonGroup,
                  {
                    justifyContent:
                      currentPosition === 0
                        ? 'space-between'
                        : currentPosition < stepLabels.length - 1
                        ? 'flex-end'
                        : 'center',
                  },
                ]}
              >
                {!info.ticket_id && !!ticket.step_progress_bar.length && (
                  <StyledButton
                    buttonSize={18}
                    label={t('selfServiceScreen.previousStep')}
                    onPress={() => {
                      currentPosition === 0
                        ? handleGoBack()
                        : setCurrentPosition((prev) => prev - 1);
                    }}
                  />
                )}
                {currentPosition < stepLabels.length - 1 && (
                  <StyledButton
                    disabled={handleDisable()}
                    buttonSize={18}
                    label={t('AuthModal.nextStep')}
                    onPress={() => handleOnNext(ticket, currentStep)}
                  />
                )}
                {currentPosition === stepLabels.length - 1 && (
                  <StyledButton label={t('confirm')} onPress={handleOnFinish} />
                )}
              </View>
            </View>
          )}
        </View>
      )}
    </FullWidthView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#FFFF',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    marginTop: Platform.OS === 'web' ? 10 : 0,
    marginBottom: Platform.OS === 'web' ? 10 : 15,
  },
  ticketItemContainer: {
    flex: 1,
    paddingVertical: 5,
  },
  ticketsContainer: {
    flex: 1,
    alignItems: 'center',
    width: '100%',
  },
  actionContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
  },
  contentItemTitle: {
    flexDirection: 'row',
    paddingVertical: 10,
  },
  input: {
    alignSelf: 'stretch',
    borderColor: 'lightgray',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  form: {
    alignItems: 'center',
    paddingTop: 20,
    flex: 1,
  },
  buttonGroup: {
    flexDirection: 'row',
  },
  imageList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

export default CustomerServiceModal;
