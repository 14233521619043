import React from 'react';
import WhiteCard from '../../common/WhiteCard';

import { View, StyleSheet } from 'react-native';
import StyledText from '../../common/StyledText';
import {
  accentColor,
  backGroundLight,
  tintColorLight,
} from '../../../constants/Colors';
import { useTranslation } from 'react-i18next';
import { OrderTotalType } from '../../../types';

type TotalInfoProps = {
  total: OrderTotalType;
};

const TotalInfo = ({ total }: TotalInfoProps) => {
  const { t } = useTranslation();

  const renderPriceDetail = (): JSX.Element => {
    let output: JSX.Element[] = [];
    if (total.unpaid_amount && total.unpaid_amount !== 0) {
      output.push(
        <View key={15} style={styles.detailRow}>
          <StyledText>{t('order.billingDetailFee.unpaidAmount')}</StyledText>
          <StyledText>{total.formated_unpaid_amount}</StyledText>
        </View>
      );
    }

    if (total.goods_amount && total.goods_amount !== 0) {
      output.push(
        <View key={1} style={styles.detailRow}>
          <StyledText>
            {t('order.billingDetailFee.productTotalAmount')}
          </StyledText>
          <StyledText>{total.formated_goods_amount}</StyledText>
        </View>
      );
    }

    if (total.tax_fee && total.tax_fee !== 0) {
      output.push(
        <View key={5} style={styles.detailRow}>
          <StyledText>{t('order.billingDetailFee.tax')}</StyledText>
          <StyledText>{total.formated_tax_fee}</StyledText>
        </View>
      );
    }

    if (total.deposit_fee && total.deposit_fee !== 0) {
      output.push(
        <View key={6} style={styles.detailRow}>
          <StyledText>{t('order.billingDetailFee.deposit')}</StyledText>
          <StyledText>{total.formated_deposit_fee}</StyledText>
        </View>
      );
    }

    if (total.recycle_fee && total.recycle_fee !== 0) {
      output.push(
        <View key={14} style={styles.detailRow}>
          <StyledText>{t('order.billingDetailFee.recycleFee')}</StyledText>
          <StyledText>{total.formated_recycle_fee}</StyledText>
        </View>
      );
    }

    if (total.discount && total.discount !== 0) {
      output.push(
        <View key={9} style={styles.detailRow}>
          <StyledText>{t('order.billingDetailFee.discount')}</StyledText>
          <StyledText>-{total.formated_discount}</StyledText>
        </View>
      );
    }

    if (total.coupons && total.coupons !== 0) {
      output.push(
        <View key={8} style={styles.detailRow}>
          <StyledText>{t('order.billingDetailFee.coupon')}</StyledText>
          <StyledText>-{total.formated_coupons}</StyledText>
        </View>
      );
    }

    if (total.integral_money && total.integral_money !== 0) {
      output.push(
        <View key={13} style={styles.detailRow}>
          <StyledText>{t('order.billingDetailFee.point')}</StyledText>
          <StyledText>-{total.formated_integral_money}</StyledText>
        </View>
      );
    }

    if (total.bonus && total.bonus !== 0) {
      output.push(
        <View key={10} style={styles.detailRow}>
          <StyledText>{t('order.billingDetailFee.bonus')}</StyledText>
          <StyledText>-{total.formated_bonus}</StyledText>
        </View>
      );
    }

    if (total.shipping_fee && total.shipping_fee !== 0) {
      output.push(
        <View key={2} style={styles.detailRow}>
          <StyledText>{t('order.billingDetailFee.deliveryFee')}</StyledText>
          <StyledText>{total.formated_shipping_fee}</StyledText>
        </View>
      );
    }
    if (total.insure_fee && total.insure_fee !== 0) {
      output.push(
        <View key={3} style={styles.detailRow}>
          <StyledText>{t('order.billingDetailFee.insurance')}</StyledText>
          <StyledText>{total.formated_insure_fee}</StyledText>
        </View>
      );
    }

    if (total.pack_fee && total.pack_fee !== 0) {
      output.push(
        <View key={4} style={styles.detailRow}>
          <StyledText>{t('order.billingDetailFee.packFee')}</StyledText>
          <StyledText>{total.formated_pack_fee}</StyledText>
        </View>
      );
    }

    if (total.pay_fee && total.pay_fee !== 0) {
      output.push(
        <View key={7} style={styles.detailRow}>
          <StyledText>{t('order.billingDetailFee.fee')}</StyledText>
          <StyledText>{total.formated_pay_fee}</StyledText>
        </View>
      );
    }

    if (total.surplus && total.surplus !== 0) {
      output.push(
        <View key={11} style={styles.detailRow}>
          <StyledText>{t('order.accountBalance')}</StyledText>
          <StyledText>-{total.formated_surplus}</StyledText>
        </View>
      );
    }

    if (total.money_paid && total.money_paid !== 0) {
      output.push(
        <View key={12} style={styles.detailRow}>
          <StyledText>{t('order.billingDetailFee.moneyPaid')}</StyledText>
          <StyledText>-{total.formated_money_paid}</StyledText>
        </View>
      );
    }

    return <>{output}</>;
  };

  return (
    <WhiteCard title={t('order.billingDetailFee.billingDetail')}>
      <View style={styles.detailContainer}>{renderPriceDetail()}</View>
      <View style={styles.footer}>
        <View style={styles.footerItem}>
          <StyledText>{t('order.totalShouldPay')}</StyledText>
          <StyledText color={accentColor} size={16} bold>
            {total.formated_order_amount}
          </StyledText>
        </View>
        {total.return_amount > 0 && (
          <View style={styles.footerItem}>
            <StyledText>{t('order.returnAmount')}</StyledText>
            <StyledText color={tintColorLight} size={16} bold>
              {total.formated_return_amount}
            </StyledText>
          </View>
        )}
      </View>
    </WhiteCard>
  );
};

const styles = StyleSheet.create({
  detailContainer: {
    paddingVertical: 5,
  },
  detailRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footer: {
    // flexDirection: 'row',
    // justifyContent: 'flex-end',
    alignItems: 'flex-end',
    borderTopColor: backGroundLight,
    borderTopWidth: 1,
    paddingTop: 5,
  },
  footerItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default TotalInfo;
