import React from 'react';
import { View, StyleSheet } from 'react-native';
import StyledText from '../StyledText';
import { useTranslation } from 'react-i18next';

const ListLoadingText = () => {
  const { t } = useTranslation();

  return (
    <View style={styles.listFooter}>
      <StyledText align='center' size={10}>
        {t('listContentLoading')}
      </StyledText>
    </View>
  );
};

export default ListLoadingText;

const styles = StyleSheet.create({
  listFooter: {
    padding: 8,
  },
});
