import React, { useState } from 'react';
import ListOption from '../common/list/ListOption';
import StyledText from '../common/StyledText';
import { View, StyleSheet } from 'react-native';
import {
  subTextColor,
  backGroundLight,
  accentColor,
  warningRed,
  linkColor,
} from '../../constants/Colors';
import ResponsiveImg from '../common/ResponsiveImg';
import NavigateButton from '../common/NavigateButton';
import StyledButton from '../common/StyledButton';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { postWithAuthToken } from '../../utils/service';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import alert from '../../utils/alert';
import { OrderDetailType, ReorderItem } from '../../types';
import { useNavigation } from '@react-navigation/native';

type OrderItemProps = {
  item: OrderDetailType;
  setShowReorderModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setReorderErrors?: React.Dispatch<React.SetStateAction<ReorderItem[]>>;
  type?: string;
};

const OrderItem = ({
  item,
  setShowReorderModal,
  setReorderErrors,
  type,
}: OrderItemProps) => {
  const { t } = useTranslation();

  const [displayItem, setDisplayItem] = useState(true);

  const renderFooter = () => {
    let buttons: JSX.Element[] = [];

    item.cancel_button &&
      buttons.push(
        <ActionButton
          type={'cancel'}
          key={1}
          orderId={item.order_id}
          options={{ hideItem: () => setDisplayItem(false) }}
        />
      );

    item.delete_button &&
      buttons.push(
        <ActionButton
          type={'delete'}
          key={2}
          orderId={item.order_id}
          options={{ hideItem: () => setDisplayItem(false) }}
        />
      );

    item.receive_button &&
      buttons.push(
        <ActionButton type={'receive'} key={3} orderId={item.order_id} />
      );

    (item.delivery_status === 1 || item.delivery_status === 2) &&
      buttons.push(
        <ActionButton
          type={'track'}
          key={4}
          orderId={item.order_id}
          orderSn={item.order_sn}
          deliveryStatus={item.delivery_status}
        />
      );

    item.reorder_button &&
      buttons.push(
        <ActionButton
          type={'reorder'}
          key={5}
          orderId={item.order_id}
          setShowReorderModal={setShowReorderModal}
          setReorderErrors={setReorderErrors}
        />
      );

    return (
      buttons.length > 0 && (
        <View style={styles.orderActionFooter}>{buttons}</View>
      )
    );
  };

  return displayItem ? (
    <View style={styles.orderContainer}>
      <ListOption>
        <NavigateButton
          route="OrderDetail"
          options={{ orderId: item.order_id, type: type }}
        >
          <View style={styles.header}>
            <View>
              <View style={styles.headerRow1}>
                <StyledText>
                  <StyledText color={subTextColor}>
                    {t('order.orderNumber') + ': '}
                  </StyledText>
                  {item.order_sn}
                </StyledText>
                <StyledText color={accentColor}>
                  {item.formated_order_status}
                </StyledText>
              </View>
              <StyledText color={subTextColor} size={12}>
                {item.add_time}
              </StyledText>
            </View>
          </View>
          <View style={styles.orderBody}>
            {item.goods_list && (
              <>
                <GoodsThumbs goodsList={item.goods_list} />
                <View>
                  <StyledText align="right" color={subTextColor}>
                    {t('order.itemCounter', { amount: item.total_item })}
                    <StyledText>{t('order.total')}</StyledText>
                    <StyledText color={accentColor} bold size={16}>
                      {item.total.formated_total_amount}
                    </StyledText>
                  </StyledText>
                </View>
              </>
            )}
          </View>
          <View style={styles.orderFooter}>
            <View style={styles.orderWarningFooter}>
              {item.pay_button && (
                <StyledText color={warningRed}>
                  {t('order.unpaidOrderClickToPay')}
                </StyledText>
              )}
              {item.show_payment_list && !item.pay_button && (
                <StyledText color={warningRed}>
                  {t('order.unpaidSwitchPayment')}
                </StyledText>
              )}
            </View>
            {renderFooter()}
          </View>
        </NavigateButton>
      </ListOption>
    </View>
  ) : (
    <></>
  );
};

const GoodsThumbs = ({ goodsList }: { goodsList: Array<any> }) => {
  const renderThumbs = goodsList.map((item, index) => {
    return (
      <View key={index} style={{ marginRight: 5 }}>
        <ResponsiveImg uri={item.goods_thumb} width={60} />
      </View>
    );
  });

  return <View style={styles.productThumbsContainer}>{renderThumbs}</View>;
};

const ActionButton = ({
  type,
  orderId,
  options,
  orderSn,
  deliveryStatus = 0,
  setShowReorderModal,
  setReorderErrors,
}: {
  type: 'delete' | 'cancel' | 'pay' | 'receive' | 'track' | 'reorder';
  orderId: number;
  options?: any;
  orderSn?: string;
  deliveryStatus?: 0 | 1 | 2;
  setShowReorderModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setReorderErrors?: React.Dispatch<React.SetStateAction<ReorderItem[]>>;
}) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const [displayButton, setDisplayButton] = useState(true);

  const handleButtonClick = () => {
    switch (type) {
      default:
      //Cancel order
      case 'cancel':
        alert(
          i18n.t('order.prompt.cancelAlert'),
          '',
          [
            {
              text: i18n.t('cancel'),
              style: 'cancel',
            },
            {
              text: i18n.t('confirm'),
              onPress: () => {
                let loading = Toast.showLoading('');
                postWithAuthToken('order/cancel', { order_id: orderId })
                  .then((result) => {
                    Toast.hide(loading);
                    if (result && result.goodStatus) {
                      Toast.showSuccess(i18n.t('order.prompt.cancelSuccess'));
                      options?.hideItem();
                    } else if (result && result.data) {
                      Toast.show(result.return_code + ' ' + result.data);
                    } else {
                      console.log(result);
                    }
                  })
                  .catch((err) => {
                    if (loading) Toast.hide(loading);
                    console.log(err);
                  });
              },
            },
          ],
          { cancelable: false }
        );
        break;
      case 'delete':
        alert(
          t('order.prompt.deleteAlert'),
          '',
          [
            {
              text: t('cancel'),
              style: 'cancel',
            },
            {
              text: t('confirm'),
              onPress: () => {
                let loading = Toast.showLoading('');
                postWithAuthToken('order/delete', { order_id: orderId })
                  .then((result) => {
                    Toast.hide(loading);
                    if (result && result.goodStatus) {
                      Toast.showSuccess(t('order.prompt.deleteSuccess'));
                      options?.hideItem();
                    } else if (result && result.data) {
                      Toast.show(result.return_code + ' ' + result.data);
                    } else {
                      console.log(result);
                    }
                  })
                  .catch((err) => {
                    if (loading) Toast.hide(loading);
                    console.log(err);
                  });
              },
            },
          ],
          { cancelable: false }
        );
        break;

      case 'receive':
        alert(
          t('order.prompt.receiveAlert'),
          '',
          [
            {
              text: t('cancel'),
              style: 'cancel',
            },
            {
              text: t('confirm'),
              onPress: () => {
                let loading = Toast.showLoading('');
                postWithAuthToken('order/confirm_receive', {
                  order_id: orderId,
                })
                  .then((result) => {
                    Toast.hide(loading);
                    if (result && result.goodStatus) {
                      Toast.showSuccess(t('order.prompt.receiveSuccess'));
                      setDisplayButton(false);
                    } else if (result && result.data) {
                      Toast.show(result.return_code + ' ' + result.data);
                    } else {
                      console.log(result);
                    }
                  })
                  .catch((err) => {
                    if (loading) Toast.hide(loading);
                    console.log(err);
                  });
              },
            },
          ],
          { cancelable: false }
        );
        break;

      case 'track':
        navigation.navigate('OrderTracking', { orderSn: orderSn });
        break;

      case 'reorder':
        alert(
          t('order.prompt.reorderAlert'),
          '',
          [
            {
              text: t('cancel'),
              style: 'cancel',
            },
            {
              text: t('confirm'),
              onPress: () => {
                let loading = Toast.showLoading('');
                postWithAuthToken('order/reorder', { order_id: orderId })
                  .then((result) => {
                    Toast.hide(loading);
                    if (result && result.goodStatus && result.data) {
                      if (result.data.error && result.data.error.length) {
                        if (setReorderErrors)
                          setReorderErrors(result.data.error);
                        if (setShowReorderModal) setShowReorderModal(true);
                      } else {
                        Toast.showSuccess(t('order.prompt.reorderSuccess'));
                        navigation.reset({
                          index: 0,
                          routes: [
                            { name: 'Root', params: { screen: 'Home' } },
                          ],
                        });
                        setTimeout(() => {
                          navigation.navigate('Root', { screen: 'Cart' });
                        }, 100);
                      }
                    } else if (result && result.data) {
                      Toast.show(result.data);
                    } else {
                      console.log(result);
                    }
                  })
                  .catch((err) => {
                    if (loading) Toast.hide(loading);
                    console.log(err);
                  });
              },
            },
          ],
          { cancelable: false }
        );
        break;
    }
  };

  switch (type) {
    default:
      return <></>;
    case 'pay':
      return (
        <View style={styles.buttonContainer}>
          <StyledButton
            buttonSize="small"
            accent
            label={t('order.pay')}
            onPress={handleButtonClick}
          />
        </View>
      );

    case 'delete':
      return (
        <View style={styles.buttonContainer}>
          <StyledButton
            outline
            buttonSize="small"
            label={t('order.delete')}
            onPress={handleButtonClick}
          />
        </View>
      );

    case 'cancel':
      return (
        <View style={styles.buttonContainer}>
          <StyledButton
            outline
            buttonSize="small"
            label={t('order.cancel')}
            onPress={handleButtonClick}
          />
        </View>
      );

    case 'receive':
      return displayButton ? (
        <View style={styles.buttonContainer}>
          <StyledButton
            accent
            buttonSize="small"
            label={t('order.receive')}
            onPress={handleButtonClick}
          />
        </View>
      ) : (
        <></>
      );

    case 'track':
      return (
        <View style={styles.buttonContainer}>
          <StyledButton
            buttonSize="small"
            color={linkColor}
            label={
              deliveryStatus === 1
                ? t('order.orderTracking.trackOrder')
                : t('order.orderTracking.checkOrderLog')
            }
            onPress={handleButtonClick}
          />
        </View>
      );

    case 'reorder':
      return (
        <View style={styles.buttonContainer}>
          <StyledButton
            buttonSize="small"
            label={t('order.reorder')}
            onPress={handleButtonClick}
          />
        </View>
      );
  }
};
const styles = StyleSheet.create({
  orderContainer: {
    borderBottomColor: backGroundLight,
    borderBottomWidth: 10,
  },
  header: {
    borderBottomColor: backGroundLight,
    borderBottomWidth: 1,
    paddingBottom: 10,
  },
  headerRow1: {},
  orderBody: {
    paddingBottom: 10,
  },
  productThumbsContainer: {
    flexDirection: 'row',
    paddingVertical: 10,
    flexWrap: 'wrap',
  },
  orderActionFooter: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1,
    flexWrap: 'wrap',
  },
  orderWarningFooter: {
    flex: 1,
    marginTop: 5,
  },
  orderFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTopColor: backGroundLight,
    borderTopWidth: 1,
    paddingTop: 5,
  },
  buttonContainer: {
    marginLeft: 5,
    marginTop: 5,
  },
});

export default OrderItem;
