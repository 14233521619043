import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import StyledText from '../../common/StyledText';
import { useTranslation } from 'react-i18next';
import WhiteCard from '../../common/WhiteCard';
import { accentColor } from '../../../constants/Colors';
import NavigateButton from '../../common/NavigateButton';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';

type ProfileWalletMenuProps = {
  balance: number;
  points: number;
  coupons: number;
};

const ProfileWalletMenu = ({
  balance = 0.0,
  points = 0,
  coupons = 0,
}: ProfileWalletMenuProps) => {
  const { t } = useTranslation();

  const navigation = useNavigation();

  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.user.isLoggedIn
  );

  return (
    <WhiteCard
      title={t('userProfileScreen.myWallet.title')}
      action={{
        label: t('userProfileScreen.myWallet.options.manage'),
        handleActionPress: () => {
          isLoggedIn
            ? navigation.navigate('Wallet')
            : navigation.navigate('Modal', { screen: 'Auth' });
        },
      }}
    >
      <View style={styles.menuContainer}>
        <NavigateButton route="Wallet" loginRequired>
          <View style={styles.menuItem}>
            <StyledText size={18} color={accentColor} style={[styles.amount]}>
              {balance}
            </StyledText>
            <StyledText>
              {t('userProfileScreen.myWallet.options.balance')}
            </StyledText>
          </View>
        </NavigateButton>
        <NavigateButton
          route="Wallet"
          options={{ screen: 'PointScreen' }}
          loginRequired
        >
          <View style={styles.menuItem}>
            <StyledText size={18} style={styles.amount}>
              {points}
            </StyledText>
            <StyledText>
              {t('userProfileScreen.myWallet.options.point')}
            </StyledText>
          </View>
        </NavigateButton>
        <NavigateButton
          route="Wallet"
          options={{ screen: 'CouponList' }}
          loginRequired
        >
          <View style={styles.menuItem}>
            <StyledText size={18} style={styles.amount}>
              {coupons}
            </StyledText>
            <StyledText>
              {t('userProfileScreen.myWallet.options.coupon')}
            </StyledText>
          </View>
        </NavigateButton>
      </View>
    </WhiteCard>
  );
};

export default ProfileWalletMenu;

const styles = StyleSheet.create({
  menuContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  menuItem: {
    paddingTop: 10,
    alignItems: 'center',
  },
  amount: {
    marginBottom: 10,
  },
});
