import React from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import FullWidthView from '../../components/common/FullWidthView';
import { backGroundLight, subTextColor } from '../../constants/Colors';
import { useCallback, useEffect, useRef, useState } from 'react';
import AddressInfo from '../../components/Cart/orderPreviewSections/AddressInfo';
import StyledText from '../../components/common/StyledText';
import { AntDesign } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { CartParamList, CreditCardInList, RootState } from '../../types';
import { payOrder, postWithAuthToken } from '../../utils/service';
import alert from '../../utils/alert';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import GoodsInfo from '../../components/Cart/orderPreviewSections/GoodsInfo';
import BalanceInfo from '../../components/Cart/orderPreviewSections/BalanceInfo';
import TotalInfo from '../../components/Cart/orderPreviewSections/TotalInfo';
import SubmitOrderWidget from '../../components/Cart/orderPreviewSections/SubmitOrderWidget';
import PaymentInfo from '../../components/Cart/orderPreviewSections/PaymentInfo';
import CreditCardSelector from '../../components/Cart/orderPreviewSections/CreditCardSelector';
import { useUpdateEffect } from 'ahooks';
import valid from 'card-validator';
import { useSelector } from 'react-redux';
import SubmitOrderModal from '../../components/Cart/orderPreviewSections/SubmitOrderModal';
import { applePay } from '../../components/Order/payment/payment';

const AddOrderGoodsScreen = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<any>();
  const route = useRoute<RouteProp<CartParamList, 'AddOrderGoodsScreen'>>();
  const { ruId, orderType, orderId, tId } = route.params;
  const shopStatus = useSelector<RootState, { code: number; message: string }>(
    (state) => state.goods.shopStatus
  );
  const scrollViewRef = useRef<ScrollView>(null);
  const [orderData, setOrderData] = useState<any>({});
  const [paymentInfoOffsetY, setPaymentInfoOffsetY] = useState<number>(0);
  // Use user balance
  const [useBalance, setUseBalance] = useState<boolean>(true);
  //Payment
  const [selectedPayment, setSelectedPayment] = useState({
    name: '',
    id: -1,
    description: '',
    code: '',
  });
  //Credit Card
  const [creditCard, setCreditCard] = useState<CreditCardInList>();
  const [CVC, setCVC] = useState<string>('');
  //Submit Order Modal
  const [submitOrderModalVisible, setSubmitOrderModalVisible] = useState(false);

  const handleScrollToEnd = () => {
    scrollViewRef.current !== null &&
      scrollViewRef.current.scrollToEnd({
        animated: true,
      });
  };

  const scrollInvalidSubmit = (type: string) => {
    switch (type) {
      case 'payment':
        scrollViewRef.current !== null &&
          scrollViewRef.current.scrollTo({
            x: 0,
            y: paymentInfoOffsetY,
            animated: true,
          });

        break;
      default:
    }
  };

  const submitValidation = () => {
    //Check payment selected or not
    if (selectedPayment.id === -1) {
      return {
        status: false,
        msg: t('order.selectPaymentMethod'),
        type: 'payment',
      };
    }

    //Check if credit card selected if payment method is Moneris
    if (selectedPayment.code === 'moneriscredit') {
      if (
        !orderData.payment.card_info ||
        !orderData.payment.card_list ||
        Array.isArray(orderData.payment.card_info) ||
        orderData.payment.card_list.length === 0
      ) {
        return {
          status: false,
          msg: t('order.selectCreditCard'),
          type: 'payment',
        };
      } else {
        //if credit card is selected check if CVC is entered
        if (CVC === undefined || CVC === '') {
          return {
            status: false,
            msg: t('validation.CVCHint'),
            type: 'payment',
          };
        } else {
          //if CVC is entered check if it is a valid CVC
          if (!valid.cvv(CVC, 4).isPotentiallyValid || CVC.length < 3) {
            return {
              status: false,
              msg: t('validation.invalidCVC'),
              type: 'payment',
            };
          }
        }
      }
    }

    // Check if Apple Pay is available on non-ios platform
    if (selectedPayment.code === 'applepay' && Platform.OS !== 'ios') {
      return {
        status: false,
        msg: t('order.applePayWarning'),
        type: 'payment',
      };
    }
    return { status: true };
  };

  const handleSubmitButtonPress = () => {
    //Only shopStatus equal to 0 can submit order
    if (shopStatus.code === 0) {
      const isValidSubmit = submitValidation();
      if (isValidSubmit.status) {
        if (
          orderData.other?.submit_alert &&
          orderData.other.submit_alert.desc.length > 0
        ) {
          setSubmitOrderModalVisible(true);
        } else {
          handleAddOrderGoods();
        }
      } else {
        if (isValidSubmit.type) {
          scrollInvalidSubmit(isValidSubmit.type);
        }
        isValidSubmit.msg !== undefined && alert(isValidSubmit.msg);
      }
    } else {
      alert(shopStatus.message);
    }
  };

  const updateOrder = useCallback(() => {
    //build request body
    const data: any = {
      ru_id: Number(ruId),
      order_type: Number(orderType),
      tid: Number(tId),
      order_id: Number(orderId),
      is_create: 0,
      use_surplus: useBalance ? 1 : 0,
      pay_id: selectedPayment.id,
    };

    //add credit card
    if (creditCard) {
      data.card_id = creditCard.id;
      data.cvd = CVC;
    }

    const loading = Toast.showLoading('');
    postWithAuthToken('order/add_order_goods', data)
      .then((response) => {
        Toast.hide(loading);
        if (response && response.goodStatus) {
          setOrderData(response.data);
        } else {
          console.log('Wrong', response);
          alert(response?.data);
        }
      })
      .catch((err) => {
        console.log(err);
        Toast.hide(loading);
      });
  }, [useBalance, selectedPayment, creditCard]);

  const handleAddOrderGoods = () => {
    //build request body
    const data: any = {
      ru_id: Number(ruId),
      order_type: Number(orderType),
      tid: Number(tId),
      order_id: Number(orderId),
      is_create: 1,
      use_surplus: useBalance ? 1 : 0,
      pay_id: selectedPayment.id,
    };

    //add credit card
    if (
      selectedPayment.code === 'moneriscredit' &&
      orderData.payment.card_info &&
      orderData.payment.card_list &&
      !Array.isArray(orderData.payment.card_info)
    ) {
      data.card_id = orderData.payment.card_info.id;
      data.cvd = CVC;
    }

    //check if credit card need to be verified
    if (
      orderData.credit_form &&
      typeof orderData.credit_form === 'object' &&
      orderData.credit_form.form &&
      orderData.credit_form.form.length > 0
    ) {
      navigation.navigate('UploadFileScreen', {
        creditFormInfo: orderData.credit_form,
        data: data,
        from: 'AddOrderGoodsScreen',
      });
      return;
    }

    const loading = Toast.showLoading('');
    postWithAuthToken('order/add_order_goods', data)
      .then((response) => {
        if (response && response.goodStatus && response.data) {
          if (response.data.process_order_pay) {
            if (selectedPayment.code === 'applepay') {
              Toast.hide(loading);
              applePay(
                data.order_id,
                data.pay_id,
                response.data.order_amount,
                response.data.applepay_config
              );
            } else {
              payOrder({
                orderId: data.order_id,
                payId: data.pay_id,
                cardId: data.card_id,
                cvd: data.cvd,
              })
                .then((result) => {
                  Toast.hide(loading);
                  if (result && result.goodStatus) {
                    navigation.navigate('User', {
                      screen: 'Order',
                      params: {
                        screen: 'OrderSubmit',
                        params: { data: result.data },
                      },
                    });
                  } //Jump to payment
                  else if (result && result.return_code === 303) {
                    navigation.navigate('User', {
                      screen: 'Order',
                      params: {
                        screen: 'OrderSubmit',
                        params: { data: result.data },
                      },
                    });
                  } else {
                    alert(
                      result?.data,
                      '',
                      [
                        {
                          text: t('confirm'),
                          onPress: () =>
                            navigation.navigate('User', {
                              screen: 'Order',
                              params: {
                                screen: 'OrderResult',
                                params: {
                                  orderId: data.order_id,
                                  status: 'APP_PAY_FAIL',
                                },
                              },
                            }),
                        },
                      ],
                      { cancelable: false }
                    );
                  }
                })
                .catch((err) => {
                  console.log(err);
                  Toast.hide(loading);
                });
            }
          } else {
            Toast.hide(loading);
            navigation.navigate('User', {
              screen: 'Order',
              params: {
                screen: 'OrderResult',
                params: {
                  orderId: data.order_id,
                  status: 'PAY_SUCCESS',
                },
              },
            });
          }
        } else {
          Toast.hide(loading);
          console.log('Wrong', response);
          alert(response?.data);
        }
      })
      .catch((err) => {
        console.log(err);
        Toast.hide(loading);
      });
  };

  useEffect(() => {
    updateOrder();
  }, [updateOrder]);

  //reset CVC if payment method/credit card changes
  useUpdateEffect(() => {
    setCVC('');
  }, [selectedPayment, creditCard]);

  return (
    <FullWidthView style={{ backgroundColor: backGroundLight }}>
      <ScrollView
        contentContainerStyle={styles.orderDetailContainer}
        ref={scrollViewRef}
      >
        {/* Shipping Option */}
        {orderData.shipping?.shipping_type === 1 && (
          <AddressInfo
            address={orderData.address}
            shippingTimeComponent={
              <View>
                <StyledText bold>
                  <AntDesign
                    name="clockcircleo"
                    size={20}
                    color={subTextColor}
                  />{' '}
                  {t('order.deliveryTime')}
                </StyledText>
                <View style={styles.timeContainer}>
                  <StyledText color={subTextColor}>
                    {orderData.address && orderData.address.best_time}
                  </StyledText>
                </View>
              </View>
            }
            disableAddressChange={true}
          />
        )}

        {/* Goods Info */}
        {orderData.cart_goods_list &&
          orderData.cart_goods_list.length > 0 &&
          orderData.goods_amount && (
            <GoodsInfo
              data={orderData.cart_goods_list}
              orderType={orderType}
              number={orderData.goods_amount.goods_number}
              pageType="preview"
            />
          )}

        {/* Balance Info */}
        {orderData.user && (
          <BalanceInfo
            balance={orderData.user.surplus}
            useBalance={useBalance}
            triggerBalanceUsage={() => {
              setUseBalance((prev) => !prev);
            }}
          />
        )}

        {/* Payment Info */}
        {orderData.payment && (
          <View
            onLayout={(event) => {
              const { y } = event.nativeEvent.layout;
              setPaymentInfoOffsetY(y);
            }}
          >
            <PaymentInfo
              selectedPayment={selectedPayment}
              setSelectedPayment={setSelectedPayment}
              paymentMethods={orderData.payment.list}
            >
              <CreditCardSelector
                setCreditCard={setCreditCard}
                cardList={orderData.payment.card_list || null}
                cardInfo={orderData.payment.card_info || null}
                ruID={ruId}
                CVC={CVC}
                setCVC={setCVC}
              />
            </PaymentInfo>
          </View>
        )}

        {/* Total Info */}
        {orderData.total && <TotalInfo total={orderData.total} />}
        {orderData.other?.submit_alert?.desc?.length > 0 && (
          <SubmitOrderModal
            modalVisible={submitOrderModalVisible}
            setModalVisible={setSubmitOrderModalVisible}
            submitAlert={orderData.other.submit_alert}
            handleOrderSubmit={handleAddOrderGoods}
          />
        )}
        {Platform.OS === 'web' && <View style={{ height: 20 }}></View>}
      </ScrollView>
      {orderData.total && (
        <SubmitOrderWidget
          totalPrice={orderData.total.formated_order_amount}
          handleSubmitButtonPress={handleSubmitButtonPress}
          handleScrollToEnd={handleScrollToEnd}
          freeShippingAmount={0}
          fastDeliveryNotice={''}
          buttonLabel={t('order.addOrderGoods')}
        />
      )}
    </FullWidthView>
  );
};

const styles = StyleSheet.create({
  orderDetailContainer: {
    padding: 10,
    paddingBottom: Platform.OS === 'web' ? 30 : 50,
  },
  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeContainer: {
    paddingVertical: 10,
  },
});

export default AddOrderGoodsScreen;
