import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { StyleProp, TextStyle, TouchableOpacity, Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';

type NavigateButtonProp = {
  children?: React.ReactNode;
  route?: string;
  options?: {};
  goBack?: boolean;
  doubleBack?: boolean;
  style?: StyleProp<TextStyle>;
  loginRequired?: boolean;
  disabled?: boolean;
  isAuthModal?: boolean;
};

/**
 * No style component use for navigating to different screen with options
 */
const NavigateButton = ({
  children,
  route = '',
  options = {},
  goBack = false,
  doubleBack = false,
  loginRequired = false,
  disabled = false,
  isAuthModal = false,
  ...props
}: NavigateButtonProp) => {
  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.user.isLoggedIn
  );

  // For Web, if the user is coming directly from the copied URL, set close button to double go back.
  const isDangerousUrl = useSelector<RootState, boolean>(
    (state) => state.url.isDangerousUrl
  );

  const navigation = useNavigation();

  const handleGoToPress = () => {
    //If the route require login, check user login or not. If not, redirect to Login Screen
    if (loginRequired) {
      isLoggedIn
        ? navigation.navigate(route, options)
        : navigation.navigate('Modal', { screen: 'AuthModal' });
    } else {
      navigation.navigate(route, options);
    }
  };

  const handleGoBackPress = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      if (Platform.OS === 'web') {
        //@ts-ignore
        window.history.back();
        navigation.reset({
          index: 0,
          routes: [{ name: 'Root' }],
        });
      } else {
        navigation.reset({
          index: 0,
          routes: [{ name: 'Root' }],
        });
      }
    }
  };

  const handleDoubleBackPress = () => {
    if (Platform.OS === 'web') {
      //@ts-ignore
      window.history.go(-2);
    } else {
      navigation.goBack();
      navigation.goBack();
    }
  };

  const handleDangerousUrlBack = () => {
    if (Platform.OS === 'web') {
      //@ts-ignore
      navigation.navigate('User', {
        screen: 'UserProfile',
      });
    } else {
      navigation.goBack();
      navigation.goBack();
    }
  };

  // This check has to go first before 'goBack' and 'doubleBack'
  if (isDangerousUrl && isAuthModal) {
    return (
      <TouchableOpacity
        disabled={disabled}
        onPress={handleDangerousUrlBack}
        {...props}
      >
        {children}
      </TouchableOpacity>
    );
  }

  //Go back to previous screen
  if (goBack) {
    return (
      <TouchableOpacity
        disabled={disabled}
        onPress={handleGoBackPress}
        {...props}
      >
        {children}
      </TouchableOpacity>
    );
  }

  //
  if (doubleBack) {
    return (
      <TouchableOpacity
        disabled={disabled}
        onPress={handleDoubleBackPress}
        {...props}
      >
        {children}
      </TouchableOpacity>
    );
  }

  return (
    <TouchableOpacity disabled={disabled} onPress={handleGoToPress} {...props}>
      {children}
    </TouchableOpacity>
  );
};

export default NavigateButton;
