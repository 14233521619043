import React, { useState, useEffect } from 'react';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  FlatList,
  Image,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { backGroundLight, normalTextColor } from '../../../constants/Colors';
import { AdItem } from '../../../types';
import StyledText from '../../common/StyledText';
import { getData } from '../../../utils/service';
import ResponsiveImg from '../../common/ResponsiveImg';
import SkeletonContent from 'react-native-skeleton-content';

const { width } = Dimensions.get('window');

interface BrandItem extends AdItem {
  recommend_brand: 0 | 1;
}

type HorizontalBrandListProps = {
  data: BrandItem;
  marginBottom?: number;
  marginHorizontal?: number;
  backgroundColor?: string;
};

const HorizontalBrandList = ({
  data,
  marginBottom,
  marginHorizontal,
  backgroundColor,
}: HorizontalBrandListProps) => {
  const [brandListData, setBrandListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();

  useEffect(() => {
    setLoading(true);
    let isSubscribe = true;
    getBrandListData(
      data.brand_cat_id,
      data.brand_item_number,
      data.recommend_brand
    )
      .then((result) => {
        if (isSubscribe) {
          if (result && result.goodStatus) {
            setBrandListData(result.data.brand);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    return () => {
      isSubscribe = false;
    };
  }, [data]);

  const handleBrandPress = (brandId: any) => {
    navigation.navigate('Brand', {
      screen: 'BrandDetail',
      params: { brandId },
    });
  };

  const _renderItem = ({ item, index }: any) => {
    return (
      <TouchableOpacity onPress={() => handleBrandPress(item.brand_id)}>
        <View
          style={[styles.brandContainer, index === 0 ? { marginLeft: 10 } : {}]}
        >
          <ResponsiveImg width={width * 0.15} uri={item.brand_logo} />
        </View>
      </TouchableOpacity>
    );
  };

  return loading ? (
    <SkeletonContent
      containerStyle={{
        flexDirection: 'row',
        width: width,
        backgroundColor,
        marginBottom,
      }}
      layout={[
        {
          width: width * 0.2,
          height: width * 0.2,
          marginRight: 5,
          borderRadius: 10,
          marginLeft: 10,
        },
        {
          width: width * 0.2,
          height: width * 0.2,
          marginRight: 5,
          borderRadius: 10,
        },
        {
          width: width * 0.2,
          height: width * 0.2,
          marginRight: 5,
          borderRadius: 10,
        },
        {
          width: width * 0.2,
          height: width * 0.2,
          marginRight: 5,
          borderRadius: 10,
        },
        {
          width: width * 0.2,
          height: width * 0.2,
          marginRight: 5,
          borderRadius: 10,
        },
      ]}
      isLoading={true}
    />
  ) : (
    <View style={{ width, backgroundColor, marginBottom }}>
      <FlatList
        data={brandListData}
        renderItem={_renderItem}
        keyExtractor={(item: any) => item.brand_id.toString()}
        horizontal
        showsHorizontalScrollIndicator={false}
        ListFooterComponent={ListFooter}
      />
    </View>
  );
};

const ListFooter = () => {
  const { t } = useTranslation();

  const navigation = useNavigation();

  const handleSeeMorePress = () => {
    navigation.navigate('Brand', { screen: 'BrandList' });
  };

  return (
    <View style={styles.seeMoreBtnContainer}>
      <View style={styles.seeMoreBtn}>
        <TouchableWithoutFeedback onPress={handleSeeMorePress}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <StyledText>{t('seeMore')}</StyledText>
            <AntDesign
              name="rightcircleo"
              size={18}
              color={normalTextColor}
              style={{ marginLeft: 5 }}
            />
          </View>
        </TouchableWithoutFeedback>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  brandContainer: {
    borderColor: 'lightgray',
    width: width * 0.2,
    height: width * 0.2,
    marginRight: 5,
    borderRadius: 10,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  seeMoreBtnContainer: {
    flex: 1,
    justifyContent: 'center',
    marginRight: 10,
  },
  seeMoreBtn: {
    borderColor: backGroundLight,
    padding: 10,
    borderRadius: 10,
    borderWidth: 1,
    backgroundColor: 'white',
  },
});

const getBrandListData = async (
  brandCatId: number,
  size = 10,
  isRecommend = 0
) => {
  const result = await getData(
    `brand/list?cat_id=${brandCatId}&size=${size}&is_recommend=${isRecommend}`
  );
  return result;
};

export default HorizontalBrandList;
