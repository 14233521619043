import React, { useRef } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';

//Multi Languages Setting
import './i18n';

//Redux Persist Config
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const persistConfig = {
  key: 'root',
  storage,
};

//Redux Config
import { Provider as ReduxProvider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './store/rootReducer';

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer);
const persistor = persistStore(store);

import { navigationRef } from './utils/navigator';
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import { NavigationContainer } from '@react-navigation/native';
import LinkingConfiguration from './navigation/LinkingConfiguration';
import Loading from './components/common/Loading';
import { useTranslation } from 'react-i18next';
import CustomerServiceButton from './components/common/CustomerServiceButton';
import { getAsyncStorageData } from './utils/asyncStorage';
import getAppStyle from './utils/getAppStyle';

/**
 * App Component specific version for web, with PersistGate to store redux state in local storage
 */
export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const { t } = useTranslation();
  const routeNameRef = useRef();
  const shopStatus = store.getState().goods.shopStatus;

  const handleOnReady = async () => {
    const displayFloatCS = await getAsyncStorageData('@storage_displayFloatCS');
    const currentRouteName = navigationRef?.current?.getCurrentRoute()?.name;
    if (
      currentRouteName === 'CustomerServiceModal' ||
      currentRouteName === 'UserProfile' ||
      currentRouteName === 'ProductDetailScreen' ||
      currentRouteName === 'AuthModal' ||
      currentRouteName === 'UserLocationModal' ||
      currentRouteName === 'OrderList' ||
      currentRouteName === 'OrderDetail' ||
      currentRouteName === 'OrderPreview' ||
      currentRouteName === 'OrderResult' ||
      currentRouteName === 'OrderCheck' ||
      currentRouteName === 'OrderSubmit' ||
      currentRouteName === 'UploadFileScreen' ||
      currentRouteName === 'HomeWebviewScreen'
    )
      store.dispatch({
        type: 'DISPLAY_FLOAT_CS',
        payload: false,
      });
    else
      store.dispatch({
        type: 'DISPLAY_FLOAT_CS',
        payload: displayFloatCS === 'true',
      });
    routeNameRef.current = currentRouteName;
  };

  const handleOnStateChange = async () => {
    const displayFloatCS = await getAsyncStorageData('@storage_displayFloatCS');
    const previousRouteName = routeNameRef.current;
    const currentRouteName = navigationRef?.current?.getCurrentRoute()?.name;
    if (previousRouteName !== currentRouteName) {
      // Save the current route name for later comparison
      routeNameRef.current = currentRouteName;
    }
    if (
      currentRouteName === 'CustomerServiceModal' ||
      currentRouteName === 'UserProfile' ||
      currentRouteName === 'ProductDetailScreen' ||
      currentRouteName === 'AuthModal' ||
      currentRouteName === 'UserLocationModal' ||
      currentRouteName === 'OrderList' ||
      currentRouteName === 'OrderDetail' ||
      currentRouteName === 'OrderPreview' ||
      currentRouteName === 'OrderResult' ||
      currentRouteName === 'OrderCheck' ||
      currentRouteName === 'OrderSubmit' ||
      currentRouteName === 'UploadFileScreen' ||
      currentRouteName === 'HomeWebviewScreen'
    )
      store.dispatch({
        type: 'DISPLAY_FLOAT_CS',
        payload: false,
      });
    else
      store.dispatch({
        type: 'DISPLAY_FLOAT_CS',
        payload: displayFloatCS === 'true',
      });
  };

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <ReduxProvider store={store}>
          <PersistGate loading={<Loading />} persistor={persistor}>
            <NavigationContainer
              documentTitle={{
                enabled: true,
                formatter: (options, route) => {
                  return route?.name === 'ProductDetailScreen'
                    ? options?.title ?? ''
                    : shopStatus
                    ? shopStatus.shopTitle
                    : t(getAppStyle(process.env.EXPO_APP_TYPE)?.title);
                },
              }}
              linking={LinkingConfiguration}
              ref={navigationRef}
              onReady={handleOnReady}
              onStateChange={handleOnStateChange}
            >
              <>
                <CustomerServiceButton />
                <Navigation colorScheme={colorScheme} />
              </>
            </NavigationContainer>
          </PersistGate>
        </ReduxProvider>
      </SafeAreaProvider>
    );
  }
}
