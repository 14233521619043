import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Modal, Platform, TouchableOpacity, View } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
import ActivityIndicator from './ActivityIndicator';
import StyledText from './StyledText';

type ImageViewerModalProps = {
  modalVisible: boolean;
  imageIndex: number;
  setModalVisible: Function;
  imgUrls: { url: string }[];
};

/**
 * Full screen Image Viewer Wrapper
 */
const ImageViewerModal = ({
  modalVisible,
  imageIndex,
  setModalVisible,
  imgUrls,
}: ImageViewerModalProps) => {
  const { t } = useTranslation();

  return (
    <View>
      <Modal visible={modalVisible} transparent={true}>
        <ImageViewer
          index={imageIndex}
          loadingRender={() => <ActivityIndicator />}
          swipeDownThreshold={0.2}
          onCancel={() => setModalVisible(false)}
          enableSwipeDown={true}
          imageUrls={imgUrls}
          renderHeader={() => {
            return (
              <View
                style={{
                  position: 'absolute',
                  top: Platform.OS === 'web' ? 10 : 70,
                  left:20,
                  zIndex:999999,
                  alignSelf: 'center',
                  backgroundColor: 'black',
                  width: 100,
                }}
              >
                <TouchableOpacity onPress={() => setModalVisible(false)}>
                  <StyledText size={15} color={'white'}>
                    {t('goBack')}
                  </StyledText>
                </TouchableOpacity>
              </View>
            );
          }}
          renderIndicator={(currentIndex, allSize) => {
            return (
              <View style={{
                position: 'absolute',
                top: Platform.OS === 'web' ? 10 : 70,
                alignSelf: 'center',
                backgroundColor: 'black',
                width: 30,
              }}>
                <StyledText color={'white'}>
                  {`${currentIndex}/${allSize}`}
                </StyledText>
              </View>
            );
          }}
        />
      </Modal>
    </View>
  );
};

export default ImageViewerModal;
