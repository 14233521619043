import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import BackButton from '../common/iconButtons/BackButton';

const OrderListBackBtn = () => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      onPress={() => {
        Platform.OS === 'web'
          ? navigation.reset({
              index: 0,
              routes: [{ name: 'Root', params: { screen: 'User' } }],
            })
          : // @ts-ignore
            navigation.replace('UserProfile');
      }}
    >
      <BackButton />
    </TouchableOpacity>
  );
};

export default OrderListBackBtn;
