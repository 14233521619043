import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, ScrollView, Platform } from 'react-native';

import CachedImage from '../../components/common/cache-img/CachedImage';
import FullWidthView from '../../components/common/FullWidthView';
import StyledButton from '../../components/common/StyledButton';
import StyledText from '../../components/common/StyledText';
import ReferralStatus from '../../components/User/Friends/ReferralStatus';
import { subTextColor } from '../../constants/Colors';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import QrCodeModal from '../../components/User/Friends/QrCodeModal';
import { getDataWithAuthToken } from '../../utils/service';
import alert from '../../utils/alert';
import { useNavigation } from '@react-navigation/native';
import InviteModal from '../../components/User/Friends/InviteModal/InviteModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';

const InviteFriendsScreen = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const preferredLanguage = useSelector<RootState, string | null | undefined>(
    (state) => state.user.preferredLanguage
  );

  const [visible, setVisible] = useState(false);
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const [inviteUrl, setInviteUrl] = useState('');
  const [status, setStatus] = useState({ number: 0, amount: '$0.00' });

  useEffect(() => {
    (async () => {
      const result = await getInviteStatus();
      if (result && result.goodStatus) {
        setStatus({
          number: result.data.invite_num,
          amount: result.data.coupon_amount,
        });
        setInviteUrl(
          `https://tools.luniumall.com/register?code=${encodeURIComponent(
            result.data.code
          )}&lng=${preferredLanguage}`
        );
      } else {
        alert(
          result && typeof result.data === 'string' ? result.data : 'Error',
          '',
          [
            {
              text: t('goBack'),
              onPress: () => {
                if (navigation.canGoBack()) {
                  navigation.goBack();
                } else {
                  if (Platform.OS === 'web') {
                    /** @ts-ignore */
                    window.history.back();
                    navigation.reset({
                      index: 0,
                      routes: [{ name: 'Root' }],
                    });
                  } else {
                    navigation.reset({
                      index: 0,
                      routes: [{ name: 'Root' }],
                    });
                  }
                }
              },
            },
          ]
        );
      }
    })();
  }, []);

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleShowQrModal = () => {
    setQrModalVisible(true);
  };

  return (
    <FullWidthView edges={['bottom', 'left', 'right']}>
      <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={true}>
        <View style={styles.contentContainer}>
          <StyledText align="center" size={20}>
            {t('inviteFriendScreen.inviteFriends')}
          </StyledText>
          <StyledText align="center" color={subTextColor}>
            {t('inviteFriendScreen.noLimit')}
          </StyledText>
          <View style={styles.imgContainer}>
            <CachedImage
              source={require('../../assets/images/user/invite-friend.png')}
              style={{ width: 150, height: 150 }}
            />
          </View>
          <StyledText align="center">
            {t('inviteFriendScreen.getRewards')}
          </StyledText>
          <StyledButton
            icon={
              <Ionicons
                name="ios-send"
                size={24}
                color="white"
                style={{ marginRight: 5 }}
              />
            }
            label={t('inviteFriendScreen.buttons.sendInvitation')}
            onPress={handleOpenModal}
          />
          <View style={{ height: 10 }}></View>
          <StyledButton
            icon={
              <AntDesign
                name="qrcode"
                size={24}
                color="white"
                style={{ marginRight: 5 }}
              />
            }
            label={t('inviteFriendScreen.buttons.sendQRcode')}
            onPress={handleShowQrModal}
          />
          <ReferralStatus status={status} />
          <EventDetail />
        </View>
      </ScrollView>
      <InviteModal
        visible={visible}
        setVisible={setVisible}
        inviteUrl={inviteUrl}
      />
      <QrCodeModal
        visible={qrModalVisible}
        setVisible={setQrModalVisible}
        inviteUrl={inviteUrl}
      />
    </FullWidthView>
  );
};

const EventDetail = () => {
  const { t } = useTranslation();

  return (
    <View style={{ paddingHorizontal: 10 }}>
      <StyledText bold align="center" size={16}>
        {t('inviteFriendScreen.terms.title')}
      </StyledText>
      <StyledText>{t('inviteFriendScreen.terms.contents.1')}</StyledText>
      <StyledText>{t('inviteFriendScreen.terms.contents.2')}</StyledText>
      <StyledText>{t('inviteFriendScreen.terms.contents.3')}</StyledText>
      <StyledText>{t('inviteFriendScreen.terms.contents.4')}</StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    marginVertical: 10,
    flex: 1,
  },
  imgContainer: {
    alignItems: 'center',
    marginTop: 10,
  },
});

const getInviteStatus = async () => {
  const result = await getDataWithAuthToken('user/invite');
  return result;
};

export default InviteFriendsScreen;
