import React, { useState } from 'react';

import ReviewListHeader from '../../../components/User/Review/ReviewListHeader';
import UncommentedReviewListScreen from './UncommentedReviewListScreen';
import CommentedReviewListScreen from './CommentedReviewListScreen';
import FullWidthView from '../../../components/common/FullWidthView';

const ReviewListScreen = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <FullWidthView edges={['bottom', 'left', 'right']}>
      <ReviewListHeader
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
      {currentIndex == 0 ? (
        <UncommentedReviewListScreen currentIndex={currentIndex} />
      ) : (
        <CommentedReviewListScreen currentIndex={currentIndex} />
      )}
    </FullWidthView>
  );
};

export default ReviewListScreen;
