import React from 'react';
import { FlatList, View } from 'react-native';
import CreditCard from './CreditCard';
import { CreditCardInList } from '../../../types';

type CreditCardListProps = {
  creditCards: Array<CreditCardInList>;
  setDefault: Function;
  deleteCard: Function;
};

const CreditCardList = ({
  creditCards,
  setDefault,
  deleteCard,
}: CreditCardListProps) => {
  const _renderItem = ({ item }: { item: CreditCardInList }) => {
    return (
      <CreditCard card={item} deleteCard={deleteCard} setDefault={setDefault} />
    );
  };

  return (
    <FlatList
      style={{ flex: 1 }}
      contentContainerStyle={{ padding: 10 }}
      data={creditCards}
      keyExtractor={(item: CreditCardInList) => item.id.toString()}
      renderItem={_renderItem}
      ItemSeparatorComponent={() => <View style={{ height: 10 }} />}
    />
  );
};

export default CreditCardList;
