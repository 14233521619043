import React, { useState } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
  KeyboardAvoidingView,
} from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import NavigateButton from '../common/NavigateButton';
import FullWidthView from '../common/FullWidthView';
import StyledText from '../common/StyledText';
import LoginForm from '../Auth/LoginForm';
import RegisterForm from '../Auth/RegisterForm';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../types';
import { setAsyncStorageData } from '../../utils/asyncStorage';
import WechatLogin from '../Auth/WechatLogin';
import * as WebBrowser from 'expo-web-browser';
import { BLUE3 } from '../../constants/Colors';
import getAppStyle from '../../utils/getAppStyle';
import { ImageInfo } from 'expo-image-picker';
import { ScrollView } from 'react-native-gesture-handler';

const AuthModal = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [displayRegister, setDisplayRegister] = useState(false);
  const shopStatus = useSelector<
    RootState,
    { code: number; message: string; shopRegCompany: boolean }
  >((state) => state.goods.shopStatus);
  const preferredLanguage = useSelector<RootState, string | null | undefined>(
    (state) => state.user.preferredLanguage
  );
  const [selectedImage, setSelectedImage] = useState<ImageInfo[]>([]);

  /* Reset isDangerousUrl to false. */
  React.useEffect(() => {
    return () => {
      if (Platform.OS === 'web') {
        dispatch({
          type: 'RESET_URL_IS_DANGEROUS',
        });
      }
    };
  }, []);

  const handleLanguagePreferenceChange = () => {
    if (typeof preferredLanguage === 'string') {
      //Change to English
      if (preferredLanguage === 'zh-CN') {
        setAsyncStorageData('@storage_LangrageReference', 'en');
        dispatch({
          type: 'CHANGE_LANGUAGE_PREFERENCE',
          payload: 'en',
        });
        i18n.changeLanguage('en');
      } else {
        setAsyncStorageData('@storage_LangrageReference', 'zh-CN');
        dispatch({
          type: 'CHANGE_LANGUAGE_PREFERENCE',
          payload: 'zh-CN',
        });
        i18n.changeLanguage('zh-CN');
      }
    }
  };

  const renderLogin = (
    <View>
      <LoginForm />
      <View style={styles.optionsContainer}>
        <TouchableOpacity
          onPress={() => {
            setDisplayRegister(true);
          }}
        >
          <StyledText>{t('AuthModal.signUp')}</StyledText>
        </TouchableOpacity>
        <NavigateButton route={'ForgotPasswordScreen'}>
          <StyledText>{t('AuthModal.forgotPassword')}</StyledText>
        </NavigateButton>
      </View>
      {!getAppStyle(process.env.EXPO_APP_TYPE)?.toHideFeature && (
        <WechatLogin />
      )}
    </View>
  );

  const renderRegister = (
    <View>
      <RegisterForm
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
      />
      <View style={styles.optionsContainer}>
        <TouchableOpacity
          onPress={() => {
            setSelectedImage([]);
            setDisplayRegister(false);
          }}
        >
          <StyledText>{t('AuthModal.login')}</StyledText>
        </TouchableOpacity>
      </View>
    </View>
  );

  return (
    <FullWidthView edges={['top', 'left', 'right']}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={{ flex: 1 }}
      >
        <TouchableWithoutFeedback
          onPress={Platform.OS === 'web' ? undefined : () => Keyboard.dismiss()}
          accessible={false}
        >
          <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
            {/* <View
              style={{
                flex: 1,
              }}
            > */}
            <View style={styles.header}>
              {!shopStatus.shopRegCompany ? (
                <NavigateButton goBack isAuthModal={true}>
                  <AntDesign name="close" size={30} />
                </NavigateButton>
              ) : (
                <View style={{ width: 30 }} />
              )}
              <View>
                <TouchableOpacity onPress={handleLanguagePreferenceChange}>
                  <StyledText size={18}>中文/EN</StyledText>
                </TouchableOpacity>
              </View>
            </View>
            {displayRegister ? renderRegister : renderLogin}
            <View style={styles.privacyAgreeContainer}>
              <StyledText align="center">
                {t('AuthModal.agreePrivacyPolicy')}
              </StyledText>
              <TouchableWithoutFeedback
                onPress={() => {
                  WebBrowser.openBrowserAsync(
                    getAppStyle(process.env.EXPO_APP_TYPE)?.aboutURL
                  );
                }}
              >
                <StyledText color={BLUE3}>
                  {t('AuthModal.luniuPrivacyPolicy')}
                </StyledText>
              </TouchableWithoutFeedback>
            </View>
            {/* </View> */}
          </ScrollView>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </FullWidthView>
  );
};

export default AuthModal;

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 8,
  },
  optionsContainer: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '75%',
    alignSelf: 'center',
  },
  privacyAgreeContainer: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // width: '75%',
    marginBottom: 10,
  },
});
