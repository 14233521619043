import { StyleSheet, View } from 'react-native';
import { Claim } from '../../../types';
import NavigateButton from '../../common/NavigateButton';
import StyledText from '../../common/StyledText';
import { useTranslation } from 'react-i18next';
import { AntDesign } from '@expo/vector-icons';
import { GREY2 } from '../../../constants/Colors';
import { useRoute } from '@react-navigation/native';

type ClaimListItemProps = {
  item: Claim;
};

const ClaimListItem = ({ item }: ClaimListItemProps) => {
  const { t } = useTranslation();
  const route = useRoute();

  return (
    <NavigateButton
      route={route && route.name === 'OrderClaimList' ? 'Order' : 'Claim'}
      options={{
        screen:
          route && route.name === 'OrderClaimList'
            ? 'OrderClaimDetail'
            : 'ClaimDetail',
        params: { claimId: item.id },
      }}
    >
      <View style={styles.claimContainer}>
        <View>
          {item.claim_sn && (
            <View style={styles.claimTextContainer}>
              <StyledText color={GREY2}>
                {t('order.claim.claimSn')}:{' '}
              </StyledText>
              <StyledText>{item.claim_sn}</StyledText>
            </View>
          )}
          {item.add_time && (
            <View style={styles.claimTextContainer}>
              <StyledText color={GREY2}>
                {t('order.claim.addTime')}:{' '}
              </StyledText>
              <StyledText>{item.add_time}</StyledText>
            </View>
          )}
          {item.claim_type && (
            <View style={styles.claimTextContainer}>
              <StyledText color={GREY2}>
                {t('order.claim.claimType')}:{' '}
              </StyledText>
              <StyledText>{item.claim_type.desc}</StyledText>
            </View>
          )}
          {item.review_status && (
            <StyledText color={item.review_status.color}>
              {item.review_status.desc}
            </StyledText>
          )}
        </View>
        <AntDesign name="rightcircleo" size={18} color={GREY2} />
      </View>
    </NavigateButton>
  );
};

export default ClaimListItem;

const styles = StyleSheet.create({
  claimContainer: {
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  claimItemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  claimTextContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});
