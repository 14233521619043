import React, { useState } from 'react';
import { Dimensions } from 'react-native';
import { WebView } from 'react-native-webview';

type WebviewAutoHeightProps = {
  source: {
    uri: string;
  };
};

const { height } = Dimensions.get('window');

const WebviewAutoHeight = ({ source }: WebviewAutoHeightProps) => {
  const [webViewHeight, setWebviewHeight] = useState(height);

  const getHeight = `window.ReactNativeWebView.postMessage(document.documentElement.scrollHeight)`;

  const onWebViewMessage = (event: any) => {
    setWebviewHeight(Number(event.nativeEvent.data));
  };

  return (
    <WebView
      source={source}
      injectedJavaScript={getHeight}
      onMessage={onWebViewMessage}
      style={{ height: webViewHeight }}
    />
  );
};
export default WebviewAutoHeight;
