import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { WHITE5, GREY2 } from '../../../constants/Colors';
import getAppStyle from '../../../utils/getAppStyle';
import StyledText from '../../common/StyledText';

type MessageListItemProps = {
  item: {
    id: number;
    user_id: number;
    title: string;
    content: string;
    data: {
      type: string;
      id: string;
    };
    msg_type: string;
    send_time: string;
    read_time: string;
  };
};

const MessageListItem = ({ item }: MessageListItemProps) => {
  return (
    <View style={styles.container}>
      <View style={styles.leftContainer}>
        <Image
          source={getAppStyle(process.env.EXPO_APP_TYPE)?.icon}
          style={{ width: 40, height: 40, borderRadius: 50 }}
        />
      </View>
      <View style={styles.rightContainer}>
        <View style={styles.header}>
          <StyledText
            style={{ flexShrink: 1 }}
            bold
            size={15}
            numberOfLines={2}
          >
            {item.title}
          </StyledText>
          <StyledText color={GREY2} size={13}>
            {item.send_time.split(' ')[0]}
          </StyledText>
        </View>
        <View style={styles.contentContainer}>
          <StyledText style={{ flexShrink: 1 }}>{item.content}</StyledText>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderBottomColor: WHITE5,
    borderBottomWidth: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  leftContainer: {
    width: '15%',
    paddingTop: 10,
    display: 'flex',
    alignItems: 'center',
  },
  rightContainer: {
    width: '85%',
    padding: 10,
    paddingLeft: 0,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  contentContainer: {
    paddingVertical: 5,
  },
});

export default MessageListItem;
