import React, { useEffect, useState } from 'react';
import { View, StyleSheet, FlatList, Dimensions } from 'react-native';
import FullWidthView from '../../../components/common/FullWidthView';
import StyledText from '../../../components/common/StyledText';
import Loading from '../../../components/common/Loading';
import { getDataWithAuthToken } from '../../../utils/service';
import { useNavigation } from '@react-navigation/native';
import {
  tintColorLight,
  backGroundLight,
  normalTextColor,
  accentColor,
} from '../../../constants/Colors';

import ActivityIndicator from '../../../components/common/ActivityIndicator';
import ListFooterText from '../../../components/common/list/ListFooterText';
import { AntDesign } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { useHeaderHeight } from '@react-navigation/stack';
import getAppStyle from '../../../utils/getAppStyle';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';

type LogDataType = {
  log_id: number;
  user_id: number;
  user_money: string;
  deposit_fee: string;
  frozen_money: string;
  rank_points: number;
  pay_points: number;
  change_time: string;
  change_desc: string;
  change_type: number;
  type: string;
  formated_user_money: string;
};

//For render LogItem
type LogItemProps = {
  item: LogDataType;
};

const AccountScreen = () => {
  const navigation = useNavigation();
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [currentBalance, setCurrentBalance] = useState('0.00');
  const [logList, setLogList] = useState<Array<LogDataType>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);

  //Get page 1 of log when page focus
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setLogList([]);
      setIsLoadingComplete(false);
      getAccountLog(1);
    });

    return unsubscribe;
  }, []);

  //When page +1, fetch new data
  useEffect(() => {
    if (currentPage !== 1) {
      getAccountLog(currentPage);
    }
  }, [currentPage]);

  const renderItem = ({ item }: LogItemProps) => <LogItem item={item} />;

  const loadExtraData = () => {
    if (isFetching) return;
    if (currentPage < totalPage) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  async function getAccountLog(page: number) {
    setIsFetching(true);
    const result = await getDataWithAuthToken(
      `/user/account?page=${page}&size=10`
    );
    if (result && result.goodStatus) {
      setCurrentBalance(result.data.surplus);
      setLogList((prevList) => [...prevList, ...result.data.logs]);
      setTotalPage(result.data.total_page);
    } else {
      console.log('err', result);
      setLogList([]);
      setCurrentBalance('Error');
      setTotalPage(0);
    }

    setIsLoadingComplete(true);
    setIsFetching(false);
  }

  return (
    <FullWidthView edges={['bottom', 'left', 'right']}>
      {!isLoadingComplete ? (
        <Loading />
      ) : (
        <FlatList
          style={{ flex: 1 }}
          contentContainerStyle={{ backgroundColor: '#FFFF' }}
          data={logList}
          renderItem={renderItem}
          keyExtractor={(item) => item.log_id.toString()}
          ListHeaderComponent={<BalanceView balance={currentBalance} />}
          onEndReached={() => loadExtraData()}
          onEndReachedThreshold={0.01}
          ListFooterComponent={
            currentPage < totalPage && logList.length > 0 ? (
              ActivityIndicator
            ) : logList.length > 0 ? (
              ListFooterText
            ) : (
              <></>
            )
          }
          ListEmptyComponent={EmptyLogComponent}
        />
      )}
    </FullWidthView>
  );
};

type BalanceViewProps = {
  balance: string;
};

const BalanceView = ({ balance = '0.00' }: BalanceViewProps) => {
  const { t } = useTranslation();
  const appMainColor = useSelector<RootState, string>(
    (state) => state.user.appMainColor
  );

  return (
    <>
      <View
        style={[styles.balanceViewContainer, { backgroundColor: appMainColor }]}
      >
        <StyledText color="white" size={18}>
          {t('userProfileScreen.myWallet.availableBalance')}
        </StyledText>
        <StyledText color="white" size={40}>
          {balance}
        </StyledText>
        {/* <NavigateButton route='AddFundsScreen' style={styles.depositBtn}>
          <StyledText align='center' color='white'>
            充值
          </StyledText>
        </NavigateButton> */}
      </View>

      <StyledText color={appMainColor} style={styles.logSectionTitle} size={18}>
        {t('userProfileScreen.myWallet.transactions')}
      </StyledText>
    </>
  );
};

const LogItem = ({ item }: LogItemProps) => {
  const amountColor = item.type === '+' ? accentColor : normalTextColor;
  return (
    <View style={styles.logItemContainer}>
      <View style={styles.logItemLeft}>
        <StyledText>{item.change_desc}</StyledText>
        <StyledText color="lightgray">{item.change_time}</StyledText>
      </View>
      <View style={styles.logItemRight}>
        <StyledText size={18} color={amountColor}>
          {item.formated_user_money}
        </StyledText>
      </View>
    </View>
  );
};

const EmptyLogComponent = () => {
  const { t } = useTranslation();
  return (
    <View style={styles.emptyLogComponent}>
      <View>
        <AntDesign name="redenvelopes" size={60} color="lightgray" />
      </View>

      <StyledText align="center">
        {t('userProfileScreen.myWallet.noTransactions')}
      </StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  balanceViewContainer: {
    alignItems: 'center',
    paddingVertical: 15,
  },
  logItemContainer: {
    borderTopColor: backGroundLight,
    borderTopWidth: 1,
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  logItemLeft: {
    flex: 3,
  },
  logItemRight: {
    flex: 1,
    alignItems: 'flex-end',
  },
  depositBtn: {
    borderColor: 'white',
    borderWidth: 1,
    borderRadius: 5,
    paddingVertical: 5,
    paddingHorizontal: 15,
    marginTop: 8,
  },
  logSectionTitle: {
    backgroundColor: 'white',
    textAlign: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingHorizontal: 10,
  },
  emptyLogComponent: {
    alignItems: 'center',
    paddingVertical: 30,
  },
});

export default AccountScreen;
