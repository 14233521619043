import React, { useState } from 'react';
import { View, TextInput, StyleSheet } from 'react-native';
import StyledText from '../../common/StyledText';
import ListOption from '../../common/list/ListOption';
import { normalTextColor } from '../../../constants/Colors';
import StyledButton from '../../common/StyledButton';
import { useTranslation } from 'react-i18next';

import Toast from 'react-native-tiny-toast';
import ClearButton from '../../common/iconButtons/ClearButton';
import { postWithAuthToken } from '../../../utils/service';
import { useNavigation } from '@react-navigation/native';

type NicknameEditFormProps = {
  title: string;
  value: string;
};

const NicknameEditForm = ({ title, value }: NicknameEditFormProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  //TODO - Bug with input chinese character will be fixed on React Native 0.63
  const [nicknameValue, setNicknameValue] = useState(value);

  const handleClearText = () => {
    setNicknameValue('');
  };

  const handleOnSubmit = () => {
    const inputValue = nicknameValue.trim();

    if (inputValue && inputValue.length >= 3 && inputValue.length <= 20) {
      /* The commented out code here is used for checking if there's emoji in the input */
      //Validate input text without special symbol
      // const checkInputRegexObj = /^[\u4E00-\u9FA5A-Za-z0-9_@. ]+$/;
      // if (checkInputRegexObj.test(inputValue)) {
      //   postWithAuthToken('user/nickname/edit', { nick_name: inputValue }).then(
      //     (result) => {
      //       if (result && result.goodStatus) {
      //         //Successfully update, go back to info screen
      //         Toast.showSuccess(t('userInfoEditScreen.updateSuccessfully'));
      //         // navigation.goBack();
      //         navigation.navigate('UserInfo', { newName: inputValue });
      //       } else {
      //         Toast.show(result?.data ? result.data : 'Error');
      //       }
      //     }
      //   );
      // } else {
      //   Toast.show(
      //     t('userInfoEditScreen.invalidSymbol', { fieldName: title }),
      //     {
      //       position: Toast.position.CENTER,
      //     }
      //   );
      // }

      postWithAuthToken('user/nickname/edit', { nick_name: inputValue }).then(
        (result) => {
          if (result && result.goodStatus) {
            //Successfully update, go back to info screen
            Toast.showSuccess(t('userInfoEditScreen.updateSuccessfully'));
            navigation.navigate('UserInfo', { newName: inputValue });
          } else {
            Toast.show(result?.data ? result.data : 'Error');
          }
        }
      );
    } else if (inputValue.length > 20) {
      Toast.show(
        t('userInfoEditScreen.cannotBeTooLong', { fieldName: title, num: 20 }),
        {
          position: Toast.position.CENTER,
        }
      );
    } else {
      Toast.show(t('userInfoEditScreen.cannotBeNull', { fieldName: title }), {
        position: Toast.position.CENTER,
      });
    }
  };

  return (
    <View style={styles.rootContainer}>
      <ListOption>
        <View style={styles.formContainer}>
          <StyledText style={styles.fieldTitle}>{title}</StyledText>
          <View style={styles.inputContainer}>
            <TextInput
              autoFocus
              style={[styles.inputField, { outline: 'none' }]}
              value={nicknameValue}
              onChangeText={(text) => setNicknameValue(text)}
            />
            <ClearButton onPress={handleClearText} />
          </View>
        </View>
      </ListOption>
      <View style={styles.buttonContainer}>
        <StyledButton label={t('confirm')} onPress={handleOnSubmit} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  formContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  fieldTitle: {
    flex: 1,
  },
  inputContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 4,
  },
  inputField: {
    color: normalTextColor,
    flex: 1,
    fontSize: 14,
    paddingVertical: 0,
  },
  buttonContainer: {
    marginBottom: 30,
  },
});

export default NicknameEditForm;
