import { Ionicons, AntDesign } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as React from 'react';
import { StyleSheet, Platform } from 'react-native';
import useColorScheme from '../hooks/useColorScheme';

import { useTranslation } from 'react-i18next';

//Home Navigator
import HomeNavigator from '../navigation/HomeNavigator';
//Category Navigator
import CategoryNavigator from '../navigation/CategoryNavigator';
//Post Navigator
import PostNavigator from '../navigation/PostNavigator';
//User Navigator
import UserNavigator from './User/UserNavigator';
import CartNavigator from './CartNavigator';
import ProductOptionSelector from '../components/Product/ProductOptionSelector';
import CartTabItem from '../components/Cart/CartTabItem';
import useNotification from '../hooks/useNotification';
import { useNavigation } from '@react-navigation/native';
import useIsWeChat from '../hooks/useIsWeChat';
import {
  defaultWeChatShareConfig,
  setWechatSharePage,
} from '../utils/wechatWebMethods';
import AlertModal from '../components/common/AlertModal/AlertModal';
import ShopCloseModal from '../components/Modal/ShopCloseModal';
import { RootState } from '../types';
import { useSelector } from 'react-redux';
import getAppStyle from '../utils/getAppStyle';

const BottomTab = createBottomTabNavigator();

/**
 * Main Tab navigator
 */
export default function BottomTabNavigator() {
  const colorScheme = useColorScheme();
  const navigation = useNavigation();
  const { t } = useTranslation();
  const isWeChatBrowser = useIsWeChat();

  useNotification();

  const shopStatus = useSelector<RootState, { code: number; message: string }>(
    (state) => state.goods.shopStatus
  );

  const appMainColor = useSelector<RootState, string>(
    (state) => state.user.appMainColor
  );

  // This will set Wechat share information to default whenever navigation is used.
  //---------------------------------------------------
  React.useEffect(() => {
    const unsubscribe = navigation.addListener('state', () => {
      if (isWeChatBrowser) {
        setTimeout(() => {
          setWechatSharePage(
            defaultWeChatShareConfig.title,
            defaultWeChatShareConfig.desc,
            defaultWeChatShareConfig.logo
          );
        }, 1);
      }
    });

    return unsubscribe;
  }, [navigation]);

  return (
    <>
      <BottomTab.Navigator
        initialRouteName="Home"
        tabBarOptions={{
          activeTintColor: appMainColor,
          labelStyle: { fontFamily: 'noto-sans' },
        }}
      >
        <BottomTab.Screen
          name="Home"
          component={HomeNavigator}
          options={{
            tabBarIcon: ({ color }) => (
              <TabBarIcon type="antdesign" name="home" color={color} />
            ),
            tabBarLabel: t('tabsLabel.tabHome'),
          }}
        />
        <BottomTab.Screen
          name="Category"
          component={CategoryNavigator}
          options={{
            tabBarIcon: ({ color }) => (
              <TabBarIcon type="antdesign" name="appstore-o" color={color} />
            ),
            tabBarLabel: t('tabsLabel.tabCategory'),
          }}
        />
        {!getAppStyle(process.env.EXPO_APP_TYPE)?.toHideFeature && (
          <BottomTab.Screen
            name="Post"
            component={PostNavigator}
            options={{
              tabBarIcon: ({ color }) => (
                <TabBarIcon
                  type="ionicons"
                  name="ios-camera-outline"
                  color={color}
                />
              ),
              tabBarLabel: t('tabsLabel.tabPost'),
            }}
          />
        )}
        <BottomTab.Screen
          name="Cart"
          component={CartNavigator}
          options={{
            tabBarIcon: ({ color }) => <CartTabItem color={color} />,
            tabBarLabel: t('tabsLabel.tabCart'),
          }}
        />
        <BottomTab.Screen
          name="User"
          component={UserNavigator}
          options={{
            tabBarIcon: ({ color }) => (
              <TabBarIcon type="antdesign" name="user" color={color} />
            ),
            tabBarLabel: t('tabsLabel.tabUser'),
          }}
        />
      </BottomTab.Navigator>
      <ProductOptionSelector />
      <AlertModal />
      {shopStatus && shopStatus.code === 1 && (
        <ShopCloseModal message={shopStatus.message} />
      )}
    </>
  );
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: { name: string; color: string; type?: string }) {
  switch (props.type) {
    case 'antdesign':
      //@ts-ignore
      return <AntDesign style={styles.tabIconStyle} size={24} {...props} />;

    default:
      //@ts-ignore
      return <Ionicons size={24} style={styles.tabIconStyle} {...props} />;
  }
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab

const styles = StyleSheet.create({
  tabIconStyle: {
    marginBottom: -3,
    marginTop: Platform.OS === 'android' ? 10 : 0,
  },
});
