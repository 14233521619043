import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { subTextColor } from '../../../constants/Colors';
import { RootState } from '../../../types';
import NavigateButton from '../../common/NavigateButton';
import StyledText from '../../common/StyledText';

const FriendsBanner = () => {
  const { t } = useTranslation();

  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.user.isLoggedIn
  );

  if (!isLoggedIn) return <></>;

  return (
    <NavigateButton route="InviteFriends" loginRequired={true}>
      <View style={styles.container}>
        <Image
          style={styles.img}
          source={require('../../../assets/images/user/invite-friend.png')}
        />
        <View style={styles.content}>
          <StyledText color={subTextColor}>
            {t('inviteFriendScreen.noLimit')}
          </StyledText>
          <StyledText size={18} bold>
            {t('inviteFriendScreen.inviteFriends')}
          </StyledText>
        </View>
      </View>
    </NavigateButton>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    overflow: 'hidden',
    marginBottom: 10,
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 5,
  },
  img: {
    width: 70,
    height: 70,
    marginLeft: 10,
  },
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default FriendsBanner;
