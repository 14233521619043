import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import WhiteCard from '../../common/WhiteCard';
import ResponsiveImg from '../../common/ResponsiveImg';
import StyledText from '../../common/StyledText';
import {
  accentColor,
  subBlueColor,
  subTextColor,
  warningRed,
} from '../../../constants/Colors';
import Collapsible from 'react-native-collapsible';
import { AntDesign } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { CartGoodsType } from '../../../types';
import BundleItemList from '../../ProductBundle/BundleItemList';
import NavigateButton from '../../common/NavigateButton';
import { useNavigation, useRoute } from '@react-navigation/native';

type ItemDataType = {
  rec_id: number;
  ru_id: number;
  tid: number;
  shipping_fee: string;
  user_id: number;
  goods_id: number;
  goods_name: string;
  goods_price: string;
  main_goods_price: string;
  goods_number: number;
  goods_attr: string;
  is_real: number;
  is_gift: number;
  extension_code: string;
  is_invalid: false;
  goods_thumb: string;
  stock: number;
  limit_number: number;
  subtotal: number;
  main_subtotal: number;
  formated_goods_price: string;
  has_tax: boolean;
  rec_type: CartGoodsType;
  package_goods?: {
    goods: PackageGoods[];
    item_number: number;
    total_amount: number;
    formated_total_amount: string;
  };
  tag?: string;
  weight_tag?: string;
};

type PackageGoods = {
  package_id: number;
  goods_id: number;
  product_id: number;
  goods_number: number;
  goods_name: string;
  shop_price: number;
  goods_brief: string;
  goods_thumb: string;
  stock: number;
  goods_attr_id: string;
  goods_attr: string;
  invalid_stock: false;
  formated_shop_price: string;
};

type GoodsInfoProps = {
  data: Array<ItemDataType>;
  number: number;
  orderType: CartGoodsType;
  pageType?: 'preview' | 'detail';
  orderId?: number;
  taskGoodsButtonEnabled?: boolean;
};

const GoodsInfo = ({
  data,
  number,
  orderType = 0,
  pageType = 'detail',
  orderId,
  taskGoodsButtonEnabled,
}: GoodsInfoProps) => {
  const { t } = useTranslation();
  const route = useRoute();
  const navigation = useNavigation();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const itemListSmall =
    data.length <= 5
      ? data.map((item, index) => (
          <Item key={index} item={item} pageType={pageType} />
        ))
      : data
          .slice(0, 5)
          .map((item, index) => (
            <Item key={index} item={item} pageType={pageType} />
          ));

  const itemListRemain =
    data.length > 5 &&
    data
      .slice(5, data.length)
      .map((item, index) => (
        <Item key={index} item={item} pageType={pageType} />
      ));

  return (
    <WhiteCard
      title={t('order.productInfo')}
      action={
        taskGoodsButtonEnabled
          ? {
              label: t('order.pickingDetail'),
              handleActionPress: () => {
                navigation.navigate('Order', {
                  screen: 'TaskGoodsList',
                  params: { orderId: orderId },
                });
              },
            }
          : undefined
      }
    >
      <View>{itemListSmall}</View>

      {data.length > 5 && (
        <>
          <Collapsible collapsed={isCollapsed}>{itemListRemain}</Collapsible>
          <TouchableOpacity
            onPress={() => {
              setIsCollapsed((prev) => !prev);
            }}
            style={styles.iconButton}
          >
            {isCollapsed ? (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <StyledText align="center">{t('seeMore') + ' '}</StyledText>
                <AntDesign name="downcircleo" size={12} color="lightgray" />
              </View>
            ) : (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <StyledText align="center">
                  {t('closeSeeMore') + ' '}
                </StyledText>
                <AntDesign name="upcircleo" size={12} color="lightgray" />
              </View>
            )}
          </TouchableOpacity>
        </>
      )}

      <View
        style={{
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <StyledText>
          {orderType !== CartGoodsType.CART_PACKAGE_GOODS
            ? t('order.itemCounter', { amount: number })
            : t('order.bundleCounter', { amount: number })}
        </StyledText>
        {route?.name === 'OrderDetail' && pageType === 'detail' && (
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('Order', {
                screen: 'OrderClaimList',
                params: { orderId: orderId },
              });
            }}
          >
            <StyledText size={12} color={accentColor}>
              {t('userProfileScreen.myOrders.options.return')}
            </StyledText>
          </TouchableOpacity>
        )}
      </View>
    </WhiteCard>
  );
};

const Item = ({
  item,
  pageType,
}: {
  item: ItemDataType;
  pageType: 'preview' | 'detail';
}) => {
  //Render bundle package
  if (
    item.rec_type === CartGoodsType.CART_PACKAGE_GOODS &&
    item.package_goods !== undefined
  ) {
    return <BundleItemList bundle={item} pageType={pageType} />;
  }

  //Render default product item
  return (
    <NavigateButton
      route="ProductNavigator"
      options={{
        params: { productId: item.goods_id },
        screen: 'ProductDetailScreen',
      }}
      disabled={pageType === 'preview'}
    >
      <View style={styles.itemContainer}>
        <View style={styles.leftContainer}>
          <ResponsiveImg width={50} uri={item.goods_thumb} />
          <View style={styles.productTextInfo}>
            {typeof item.tag === 'string' && item.tag !== '' && (
              <View style={styles.tag}>
                <StyledText size={10} color={warningRed}>
                  {item.tag}
                </StyledText>
              </View>
            )}
            {typeof item.weight_tag === 'string' && item.weight_tag !== '' && (
              <View style={[styles.tag, { borderColor: subBlueColor }]}>
                <StyledText size={10} color={subBlueColor}>
                  {item.weight_tag}
                </StyledText>
              </View>
            )}
            <StyledText numberOfLines={3}>{item.goods_name}</StyledText>

            <StyledText color={subTextColor} size={12}>
              {item.goods_attr}
            </StyledText>
          </View>
        </View>
        <View style={styles.rightContainer}>
          <StyledText>x{item.goods_number}</StyledText>
          <View>
            <StyledText>{item.formated_goods_price}</StyledText>
            {item.has_tax && (
              <StyledText align="center" color={subTextColor} size={10}>
                + Tax
              </StyledText>
            )}
          </View>
        </View>
      </View>
    </NavigateButton>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 2.5,
  },
  leftContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 2,
    width: 0,
    flexGrow: 2,
  },
  rightContainer: {
    flexDirection: 'row',
    flex: 0.65,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  productTextInfo: {
    flex: 1,
    marginLeft: 10,
  },
  iconButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  tag: {
    borderWidth: 1,
    borderColor: warningRed,
    padding: 2,
    borderRadius: 5,
    marginRight: 3,
    alignSelf: 'flex-start',
  },
});

export default GoodsInfo;
