import React from 'react';
import EmptyDisplayBlock from '../common/EmptyDisplayBlock';
import GeneralModal from '../common/GeneralModal';

/**
 * For some reason when the server is not available, show this modal
 */
const ShopCloseModal = ({ message }: { message: string }) => {
  return (
    <GeneralModal visible>
      <EmptyDisplayBlock description={message} />
    </GeneralModal>
  );
};

export default ShopCloseModal;
