import { Entypo } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import axios, { CancelTokenSource } from 'axios';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, FlatList, RefreshControl, View } from 'react-native';
import SkeletonContent from 'react-native-skeleton-content';
import { useSelector } from 'react-redux';
import { tintColorLight } from '../../constants/Colors';
import { CouponType, RootState } from '../../types';
import { getDataWithAuthToken } from '../../utils/service';
import ActivityIndicator from '../common/ActivityIndicator';
import EmptyDisplayBlock from '../common/EmptyDisplayBlock';
import ListFooterText from '../common/list/ListFooterText';
import NavigateButton from '../common/NavigateButton';
import CouponItem from './CouponItem';

type CouponListType = {
  couponType: 'unused' | 'used' | 'expired';
  listHeader?: JSX.Element;
  onSelectCouponRoute?: string;
  ruId?: number;
};

const { width } = Dimensions.get('window');

const CouponList = forwardRef(({ couponType = 'unused', listHeader = <>

    </>, onSelectCouponRoute, ruId }: CouponListType, ref) => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  const [coupons, setCoupons] = useState<CouponType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [firstLoadComplete, setFirstLoadComplete] = useState(false);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useImperativeHandle(ref, () => ({
    refreshList: refreshList,
  }));

  //For web to change different list
  useEffect(() => {
    setFirstLoadComplete(false);
    refreshList();
  }, [couponType]);

  useEffect(() => {
    let isSubscribed = true;
    let cancelToken: CancelTokenSource;
    cancelToken = axios.CancelToken.source();
    if (currentPage > 1) {
      (async () => {
        setIsFetching(true);
        const result = await getCoupons(couponType, currentPage, ruId);
        if (result && result.goodStatus) {
          if (isSubscribed) {
            if (currentPage === 1) {
              setCoupons(result.data.coupon);
              setTotalPage(result.data.total_page);
              setFirstLoadComplete(true);
            } else {
              setCoupons((prev) => [...prev, ...result.data.coupon]);
            }

            if (currentPage >= result.data.total_page) {
              setAllDataLoaded(true);
            }
          }
        } else {
          console.log('err', result);
        }
        setIsFetching(false);
      })();
    }

    return () => {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('operation canceled due to new request');
      }
      isSubscribed = false;
    };
  }, [currentPage]);

  const refreshList = () => {
    setRefreshing(true);
    setCurrentPage(1);
    setAllDataLoaded(false);
    setTotalPage(1);
    setIsFetching(true);
    (async () => {
      const result = await getCoupons(couponType, 1, ruId);
      if (result && result.goodStatus) {
        setCoupons(result.data.coupon);
        setTotalPage(result.data.total_page);
        setFirstLoadComplete(true);
        if (currentPage >= result.data.total_page) {
          setAllDataLoaded(true);
        }
      } else {
        console.log('err', result);
      }

      setRefreshing(false);
      setIsFetching(false);
    })();
  };

  //Refresh when pull down
  const onRefresh = useCallback(refreshList, []);

  const renderItem = ({ item }: { item: CouponType }) => {
    return (
      <NavigateButton
        disabled={
          item.is_invalid === 1 || onSelectCouponRoute === undefined
            ? true
            : false
        }
        route={onSelectCouponRoute || undefined}
        options={{ selectedCoupon: item.uc_id }}
      >
        <CouponItem item={item} />
      </NavigateButton>
    );
  };

  const loadMore = () => {
    if (isFetching) return;
    if (currentPage < totalPage) {
      setCurrentPage((oldValue) => oldValue + 1);
    }
  };

  return !firstLoadComplete ? (
    <SkeletonContent
      containerStyle={{ width: width }}
      layout={[
        {
          width: width - 20,
          height: 100,
          borderRadius: 8,
          marginTop: 10,
          marginHorizontal: 10,
        },
        {
          width: width - 20,
          height: 100,
          borderRadius: 8,
          marginTop: 10,
          marginHorizontal: 10,
        },
        {
          width: width - 20,
          height: 100,
          borderRadius: 8,
          marginTop: 10,
          marginHorizontal: 10,
        },
      ]}
      isLoading={true}
    />
  ) : (
    <FlatList
      style={{ flex: 1 }}
      contentContainerStyle={{ width: width }}
      data={coupons}
      renderItem={renderItem}
      keyExtractor={(item) => item.uc_id.toString()}
      onEndReached={loadMore}
      onEndReachedThreshold={0.01}
      showsVerticalScrollIndicator={false}
      ListFooterComponent={
        coupons.length > 0 ? (
          allDataLoaded ? (
            ListFooterText
          ) : (
            ActivityIndicator
          )
        ) : (
          <></>
        )
      }
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={[tintColorLight]}
          tintColor={tintColorLight}
        />
      }
      ListHeaderComponent={listHeader}
      ListEmptyComponent={
        <View style={{ height: 500 }}>
          <EmptyDisplayBlock
            icon={<Entypo name="ticket" size={80} color="lightgray" />}
            description={t('coupons.list.emptyMsg', {
              type: t('coupons.list.' + couponType),
            })}
          />
        </View>
      }
    />
  );
});

export default CouponList;

const getCoupons = async (
  type: 'unused' | 'used' | 'expired',
  page: number,
  ruId?: number
) => {
  let status = 0;
  switch (type) {
    default:
    case 'unused':
      status = 0;
      break;
    case 'used':
      status = 1;
      break;
    case 'expired':
      status = 2;
      break;
  }
  let endpoint = '';
  if (ruId !== undefined) {
    endpoint = `user/coupon?status=${status}&page=${page}&size=10&ru_id=${ruId}`;
  } else {
    endpoint = `user/coupon?status=${status}&page=${page}&size=10`;
  }

  const result = await getDataWithAuthToken(endpoint);
  return result;
};
