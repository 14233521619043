import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
} from 'react-native';
import NavigateButton from '../../components/common/NavigateButton';
import FullWidthView from '../../components/common/FullWidthView';
import StyledText from '../../components/common/StyledText';
import { setAsyncStorageData } from '../../utils/asyncStorage';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../types';
import { Ionicons } from '@expo/vector-icons';
import ForgotPasswordNavigator from '../../navigation/ForgotPasswordNavigator';
import getAppStyle from '../../utils/getAppStyle';

const ForgotPasswordScreen = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const preferredLanguage = useSelector<RootState, string | null | undefined>(
    (state) => state.user.preferredLanguage
  );

  const handleLanguagePreferenceChange = () => {
    if (typeof preferredLanguage === 'string') {
      //Change to English
      if (preferredLanguage === 'zh-CN') {
        setAsyncStorageData('@storage_LangrageReference', 'en');
        dispatch({
          type: 'CHANGE_LANGUAGE_PREFERENCE',
          payload: 'en',
        });
        i18n.changeLanguage('en');
      } else {
        setAsyncStorageData('@storage_LangrageReference', 'zh-CN');
        dispatch({
          type: 'CHANGE_LANGUAGE_PREFERENCE',
          payload: 'zh-CN',
        });
        i18n.changeLanguage('zh-CN');
      }
    }
  };

  return (
    <TouchableWithoutFeedback
      onPress={Platform.OS === 'web' ? undefined : () => Keyboard.dismiss()}
      accessible={false}
    >
      <View style={{ flex: 1 }}>
        <FullWidthView>
          <View style={styles.header}>
            <NavigateButton goBack>
              <Ionicons
                name="ios-arrow-back"
                size={24}
                color="black"
                style={{ paddingLeft: 10 }}
              />
            </NavigateButton>
            <View>
              <TouchableOpacity onPress={handleLanguagePreferenceChange}>
                <StyledText size={18}>中文/EN</StyledText>
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.brandContainer}>
            <Image
              source={getAppStyle(process.env.EXPO_APP_TYPE)?.loginLogo}
              style={{ width: 200, height: 200 }}
              resizeMode="cover"
            />
          </View>
          <ForgotPasswordNavigator />
        </FullWidthView>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 75,
    alignItems: 'center',
    paddingHorizontal: 8,
  },
  brandContainer: {
    height: '30%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ForgotPasswordScreen;
