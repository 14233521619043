import React from 'react';
import {
  View,
  TouchableWithoutFeedback,
  FlatList,
  StyleSheet,
  Dimensions,
} from 'react-native';
import StyledText from '../../common/StyledText';
import { tintColorLight, backGroundLight } from '../../../constants/Colors';

type VerticalGoodsListMenuProps = {
  catNames: Array<string>;
  verticalListCategory: number;
  setVerticalListCategory: Function;
};

const { width } = Dimensions.get('window');

const VerticalGoodsListMenu = ({
  catNames,
  verticalListCategory,
  setVerticalListCategory
}: VerticalGoodsListMenuProps) => {

  const handleMenuPress = (index: number) => {
    setVerticalListCategory(index)
  };

  const renderItem = ({ item, index }: any) => (
    <TouchableWithoutFeedback
      key={index}
      onPress={() => handleMenuPress(index)}
    >
      {verticalListCategory === index ? (
        <View style={[styles.menuButton, styles.menuButtonActive]}>
          <StyledText size={18} style={{ color: tintColorLight }}>
            {item}
          </StyledText>
        </View>
      ) : (
        <View style={styles.menuButton}>
          <StyledText size={18}>{item}</StyledText>
        </View>
      )}
    </TouchableWithoutFeedback>
  );

  return (
    <View style={styles.menuContainer}>
      <FlatList
        horizontal
        showsHorizontalScrollIndicator={false}
        data={catNames}
        renderItem={renderItem}
        keyExtractor={(item) => item}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  menuContainer: {
    width: width,
    paddingVertical: 5,
    backgroundColor: backGroundLight,
  },
  menuButton: {
    margin: 10,
  },
  menuButtonActive: {
    borderBottomColor: tintColorLight,
    borderBottomWidth: 2,
  },
});

export default VerticalGoodsListMenu;
