import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import StyledText from '../common/StyledText';
import { Comment as CommentType, CommentImage } from '../../types';
import { useTranslation } from 'react-i18next';
import ImageViewerModal from './ImageViewerModal';
import { backGroundLight } from '../../constants/Colors';
import NavigateButton from './NavigateButton';
import UserAndStars from './comment/UserAndStars';
import ImageList from './comment/ImageList';
import AdminReply from './comment/AdminReply';

type CommentProps = CommentType & {
  productId?: number;
  showFullContent?: boolean;
};
const Comment = ({
  nick_name,
  comment_rank,
  content,
  user_picture,
  add_time,
  images,
  admin_reply,
  productId,
  showFullContent = true,
}: CommentProps) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const renderNickname = (nickName: string) => {
    const length = nickName.length;
    //Dog -> D*g
    if (length > 2)
      return (
        nickName[0] +
        new Array(nickName.length - 2).join('*') +
        nickName[nickName.length - 1]
      );

    //Hi => H*
    if (length === 2)
      return nickName[0] + new Array(nickName.length - 1).join('*');

    //for nickname less than 2 just return nickname
    return nickName;
  };

  const handleImages = (images: Array<CommentImage>) => {
    let imageUrls: Array<{ url: string }> = [];
    images.forEach((element: any) => {
      imageUrls.push({
        url: element.comment_img,
      });
    });
    return imageUrls;
  };

  const renderTextContent = () => {
    if (content.length <= 60 || showFullContent) {
      return <StyledText>{content}</StyledText>;
    } else {
      return (
        <View>
          <StyledText>{`${content.slice(0, 60)}......`}</StyledText>
          <View style={styles.showMoreBtnContainer}>
            <NavigateButton
              route="CommentScreen"
              options={{ productId: productId }}
            >
              <View style={styles.showMoreBtn}>
                <StyledText>
                  {t('productDetailScreen.commentSection.show')}
                </StyledText>
              </View>
            </NavigateButton>
          </View>
        </View>
      );
    }
  };

  return (
    <View>
      <UserAndStars
        nick_name={renderNickname(nick_name)}
        comment_rank={comment_rank}
        user_picture={user_picture}
        add_time={add_time}
      />
      {renderTextContent()}

      <ImageList
        images={productId ? images.slice(0, 2) : images}
        setModalVisible={setModalVisible}
        setImageIndex={setImageIndex}
      />
      <AdminReply
        admin_reply={admin_reply}
        showFullContent={showFullContent}
        productId={productId}
      />
      <View>
        <ImageViewerModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          imageIndex={imageIndex}
          imgUrls={handleImages(images)}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  showMoreBtnContainer: {
    alignItems: 'flex-end',
  },
  showMoreBtn: {
    backgroundColor: backGroundLight,
    padding: 3,
  },
});

export default Comment;
