import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, View } from 'react-native';
import FullWidthView from '../../../components/common/FullWidthView';
import NavigateButton from '../../../components/common/NavigateButton';
import ResponsiveImg from '../../../components/common/ResponsiveImg';
import StyledButton from '../../../components/common/StyledButton';
import StyledText from '../../../components/common/StyledText';
import { normalTextColor } from '../../../constants/Colors';
import useIsWeChat from '../../../hooks/useIsWeChat';

const { width } = Dimensions.get('window');

const WebPaymentScreen = ({ route }: any) => {
  const { pay_url, id } = route.params;
  const navigation = useNavigation();
  const isWeChat = useIsWeChat();
  const { t } = useTranslation();

  if (isWeChat) {
    return (
      <FullWidthView
        style={{
          backgroundColor: normalTextColor,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <View>
          <ResponsiveImg
            width={width}
            uri={require('../../../assets/images/open-safari-instructions.png')}
          />
          <View style={{ alignSelf: 'center' }}>
            <View style={{ flexDirection: 'row', marginBottom: 10 }}>
              <MaterialCommunityIcons
                style={{ marginRight: 5 }}
                name="numeric-1-circle"
                size={24}
                color="white"
              />
              <StyledText color={'white'}>
                {t('order.webPaymentInstructions.step1')}
              </StyledText>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 10 }}>
              <MaterialCommunityIcons
                style={{ marginRight: 5 }}
                name="numeric-2-circle"
                size={24}
                color="white"
              />
              <StyledText color={'white'}>
                {t('order.webPaymentInstructions.step2')}
              </StyledText>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <MaterialCommunityIcons
                style={{ marginRight: 5 }}
                name="numeric-3-circle"
                size={24}
                color="white"
              />
              <StyledText color={'white'}>
                {t('order.webPaymentInstructions.step3')}
              </StyledText>
            </View>
          </View>
        </View>
        <View style={{ marginBottom: 20, alignItems: 'center' }}>
          <StyledButton
            label={t('done')}
            onPress={() => navigation.navigate('OrderCheck', { id: id })}
          />
          <NavigateButton route={'OrderCheck'} options={{ id: id }}>
            <StyledText
              color={'lightgray'}
              style={{ marginTop: 30, textDecorationLine: 'underline' }}
            >
              {t('order.abandonPayment')}
            </StyledText>
          </NavigateButton>
        </View>
      </FullWidthView>
    );
  } else {
    return <></>;
  }
};

export default WebPaymentScreen;
