import React, { useState, useEffect, useRef } from 'react';
import { FlatList, View, ViewToken } from 'react-native';
import {
  CategoriesItem,
  RootState,
  LocationInformation,
} from '../../../../types';
import { getGoodsListWithId } from '../../../../utils/service';
import { useDispatch, useSelector } from 'react-redux';
import ProductItemInList from '../../../Product/ProductItemInList';
import { ProductInformation } from '../../../../types';
import ActivityIndicator from '../../../common/ActivityIndicator';
import axios, { CancelTokenSource } from 'axios';
import ProductListSkeletonLoad from '../../../common/ProductListSkeletonLoad';
import { useUpdateEffect } from 'ahooks';
import EmptyDisplayBlock from '../../../common/EmptyDisplayBlock';
import PressNextTip from '../../PressNextTip';
import StyledText from '../../../common/StyledText';
import { backGroundLight, subTextColor } from '../../../../constants/Colors';

type LevelThreeCategoryListProps = {
  category: CategoriesItem;
  listHeader?: JSX.Element;
  width: number;
  setHomeProductListCatInfo?: Function;
  scrollToNextCategory?: Function;
  nextCategoryName: string;
  selectedSorter: {
    sort_key?: string | undefined;
    sort_value?: string | undefined;
  };
  levelThreeCategories: Array<CategoriesItem>;
  horizontalIndex: number;
  setHorizontalIndex: Function;
};

const LevelThreeCategoryList = ({
  category,
  listHeader,
  width,
  setHomeProductListCatInfo,
  scrollToNextCategory,
  nextCategoryName,
  selectedSorter,
  levelThreeCategories,
  horizontalIndex,
  setHorizontalIndex,
}: LevelThreeCategoryListProps) => {
  const dispatch = useDispatch();
  const isResetGoodsList = useSelector<RootState, boolean>(
    (state) => state.goods.isResetGoodsList
  );
  const location = useSelector<RootState, LocationInformation>(
    (state) => state.goods.location
  );
  const isFetching = useSelector<RootState, boolean>(
    (state) => state.goods.isFetchingGoods
  );

  const [goodsList, setGoodsList] = useState<Array<ProductInformation>>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  useEffect(() => {
    if (JSON.stringify(selectedSorter) === '{}') return;
    if (!isResetGoodsList) {
      const foundIndex = goodsList.length
        ? goodsList.findIndex(
            (good) =>
              typeof good.goods_id === 'string' &&
              good.goods_id ===
                `${category.name}_${horizontalIndex}_${category.id}`
          )
        : -1;
      if (foundIndex !== -1) return;
    }
    isResetGoodsList && setLoading(true);
    let isSubscribed = true;
    let cancelToken: CancelTokenSource;
    cancelToken = axios.CancelToken.source();
    setPage(1);
    setAllDataLoaded(false);
    isResetGoodsList && setGoodsList([]);
    dispatch({ type: 'SET_RESET_GOODS_LIST', payload: false });
    dispatch({ type: 'SET_FETCHING_GOODS', payload: true });
    getProducts(
      category.id,
      1,
      location.countryId,
      location.provinceId,
      location.cityId,
      selectedSorter,
      category.goods_type ? category.goods_type : 'all',
      { cancelToken: cancelToken.token }
    )
      .then((result) => {
        if (result && result.goodStatus) {
          // set HomeProductListScreen's category information. Will be used by Wechat share.
          if (setHomeProductListCatInfo) {
            setHomeProductListCatInfo({
              name: result.data.category.name,
              icon: result.data.category.icon,
            });
          }

          if (isSubscribed) {
            setGoodsList((prev) => [
              ...prev,
              {
                goods_id: `${category.name}_${horizontalIndex}_${category.id}`,
              },
              ...result.data.goods,
            ]);
            //Product list less than 10, do not require bottom reach fetch
            result.data.goods.length < 10 && setAllDataLoaded(true);
          }
        }
      })
      .catch((err) => {
        console.log('error: ', err);
      })
      .finally(() => {
        setLoading(false);
        dispatch({ type: 'SET_FETCHING_GOODS', payload: false });
      });

    return () => {
      if (cancelToken) {
        cancelToken.cancel('operation canceled due to new request');
      }
      isSubscribed = false;
    };
  }, [category, location, selectedSorter]);

  useUpdateEffect(() => {
    let isSubscribed = true;
    if (!allDataLoaded && page > 1) {
      dispatch({ type: 'SET_FETCHING_GOODS', payload: true });
      getProducts(
        category.id,
        page,
        location.countryId,
        location.provinceId,
        location.cityId,
        selectedSorter,
        category.goods_type ? category.goods_type : 'all',
        {}
      )
        .then((result) => {
          if (result && result.goodStatus) {
            if (isSubscribed) {
              setGoodsList((oldValues) => [...oldValues, ...result.data.goods]);
              //Product list less than 10, do not require bottom reach fetch
              result.data.goods.length < 10 && setAllDataLoaded(true);
            }
          }
        })
        .catch((err) => {
          console.log('error: ', err);
        })
        .finally(() => {
          dispatch({ type: 'SET_FETCHING_GOODS', payload: false });
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [page]);

  const _renderItem = ({ item, index }: any) => {
    return (
      <View style={{ width: width }}>
        {item && item.goods_id && typeof item.goods_id === 'string' ? (
          <View
            style={{
              backgroundColor: backGroundLight,
              paddingHorizontal: 6,
              borderBottomColor: '#d3d3d3',
              borderBottomWidth: 1,
              borderTopColor: index === 0 ? '#d3d3d3' : undefined,
              borderTopWidth: index === 0 ? 1 : undefined,
            }}
          >
            <StyledText color={subTextColor}>
              {item.goods_id.split('_')[0]}
            </StyledText>
          </View>
        ) : (
          <ProductItemInList data={item} />
        )}
      </View>
    );
  };

  const loadMore = () => {
    if (isFetching) return;
    if (!allDataLoaded) {
      setPage((oldValue) => oldValue + 1);
    } else {
      if (horizontalIndex < levelThreeCategories.length - 1) {
        scrollToNextCategory && scrollToNextCategory();
      }
    }
  };

  const onViewableItemsChanged = useRef(
    ({
      viewableItems,
      changed,
    }: {
      viewableItems: ViewToken[];
      changed: ViewToken[];
    }) => {
      if (viewableItems && changed && viewableItems.length !== changed.length) {
        const viewableSeparatorIndex = viewableItems
          .reverse()
          .findIndex((item) => typeof item.item.goods_id === 'string');

        if (viewableSeparatorIndex !== -1) {
          const newHorizontalIndex = Number(
            viewableItems[viewableSeparatorIndex].item.goods_id.split('_')[1]
          );
          setHorizontalIndex && setHorizontalIndex(newHorizontalIndex);
        }
      }
    }
  );

  return !loading ? (
    goodsList.length > 0 ? (
      <FlatList
        contentContainerStyle={[{ width: width }]}
        style={{ width: width, flex: 1 }}
        data={goodsList}
        renderItem={_renderItem}
        keyExtractor={(item, index) =>
          item.goods_id.toString() + '@' + index.toString()
        }
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={listHeader}
        onEndReached={loadMore}
        onEndReachedThreshold={0.01}
        onViewableItemsChanged={onViewableItemsChanged.current}
        ListFooterComponent={
          allDataLoaded ? (
            <PressNextTip
              nextCategoryName={nextCategoryName}
              onPress={scrollToNextCategory}
            />
          ) : (
            <ActivityIndicator />
          )
        }
      />
    ) : (
      <EmptyDisplayBlock withoutFlex={true} />
    )
  ) : (
    <ProductListSkeletonLoad
      containerStyle={{ width: width, alignItems: 'center' }}
    />
  );
};

export default LevelThreeCategoryList;

async function getProducts(
  id: number,
  page: number,
  countryId: number,
  provinceId: number,
  cityId: number,
  selectedSorter: {
    sort_key?: string | undefined;
    sort_value?: string | undefined;
  },
  goodsType = 'all',
  options: any
) {
  const result = await getGoodsListWithId(
    id,
    page,
    countryId,
    provinceId,
    cityId,
    10,
    selectedSorter && selectedSorter.sort_key
      ? selectedSorter.sort_key
      : undefined,
    selectedSorter && selectedSorter.sort_value
      ? selectedSorter.sort_value
      : undefined,

    goodsType,
    options
  );
  return result;
}
