import React, { useState } from 'react';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import {
  View,
  StyleSheet,
  TouchableWithoutFeedback,
  Platform,
} from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { useNavigation } from '@react-navigation/native';
import { useUpdateEffect } from 'ahooks';
import { useTranslation } from 'react-i18next';
import { postWithAuthToken } from '../../utils/service';
import { tintColorLight } from '../../constants/Colors';

import Toast from 'react-native-tiny-toast';
import alert from '../../utils/alert';
import ResponsiveImg from '../../components/common/ResponsiveImg';
import StyledText from '../../components/common/StyledText';

type ProfilePictureHeaderProps = {
  thumbnailUri: string;
};

const ProfilePictureHeader = ({ thumbnailUri }: ProfilePictureHeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigation();

  const [image, setImage] = useState<ImageInfo>();

  const handlePressPicture = () => {
    const buttonList: {
      text: string;
      onPress?: () => Promise<void>;
    }[] = [
      {
        text: t('userInfoScreen.changeProfilePhotoOption.takeAPhoto'),
        onPress: () => takePhoto(),
      },
      {
        text: t('userInfoScreen.changeProfilePhotoOption.chooseFromAlbum'),
        onPress: () => pickImage(),
      },
    ];
    if (Platform.OS === 'ios')
      buttonList.push({
        text: t('userInfoScreen.changeProfilePhotoOption.cancel'),
      });

    alert(
      t('userInfoScreen.changeProfilePhotoOption.alertTitle'),
      '',
      buttonList,
      { cancelable: true }
    );
  };

  const takePhoto = async () => {
    const { granted } = await ImagePicker.requestCameraPermissionsAsync();
    if (!granted) {
      alert(
        t('userInfoScreen.changeProfilePhotoOption.enableCameraPermissions')
      );
    } else {
      let result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 0.5,
        allowsMultipleSelection: false,
        base64: true,
      });

      if (!result.cancelled) {
        const newImage = result;
        handleSubmit(newImage);
      }
    }
  };

  const pickImage = async () => {
    const { granted } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (!granted) {
      alert(
        t('userInfoScreen.changeProfilePhotoOption.enableCameraPermissions')
      );
    } else {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 0.5,
        allowsMultipleSelection: false,
        base64: true,
      });

      if (!result.cancelled) {
        const newImage = result;
        handleSubmit(newImage);
      }
    }
  };

  const handleSubmit = (newImage: ImageInfo) => {
    if (newImage) {
      const toastLoading = Toast.showLoading('');
      let formData = new FormData();
      if (Platform.OS === 'web') {
        formData.append('image', newImage.uri);
      } else {
        formData.append('image', 'data:image/jpeg;base64,' + newImage.base64);
      }
      postWithAuthToken('user/picture/edit', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          Toast.hide(toastLoading);
          if (response && response.goodStatus) {
            // Set local image to new image, and update the params.
            setImage(newImage);

            Toast.showSuccess(t('userInfoEditScreen.updateSuccessfully'));
          } else {
            if (response) {
              throw response.data;
            } else {
              throw 'no token found';
            }
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.hide(toastLoading);
          Toast.show(err, {
            position: 0,
            duration: 2000,
          });
        });
    }
  };

  useUpdateEffect(() => {
    navigate.setParams({
      newPicture: image?.uri,
    });
  }, [image]);

  return (
    <>
      <TouchableWithoutFeedback onPress={handlePressPicture}>
        <View style={styles.thumbnailContainer}>
          {thumbnailUri !== '' && (
            <ResponsiveImg uri={thumbnailUri} width={100} />
          )}
        </View>
      </TouchableWithoutFeedback>
      <StyledText align="center" onPress={handlePressPicture}>
        {t('userInfoScreen.changeProfilePhotoOption.title')}
      </StyledText>
    </>
  );
};

export default ProfilePictureHeader;

const styles = StyleSheet.create({
  thumbnailContainer: {
    width: 100,
    height: 100,
    backgroundColor: 'lightgray',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginVertical: 10,
  },
  editThumbnailBtn: {
    backgroundColor: tintColorLight,
    width: 25,
    height: 25,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
  },
  menuItemContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  valueContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
