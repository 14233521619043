import { RouteProp, useRoute } from '@react-navigation/native';
import { OrderParamList, TaskGood } from '../../../types';
import { useCallback, useEffect, useState } from 'react';
import { getDataWithAuthToken } from '../../../utils/service';
import alert from '../../../utils/alert';
import FullWidthView from '../../../components/common/FullWidthView';
import { FlatList } from 'react-native';
import ListSeparator from '../../../components/common/list/ListSeparator';
import Loading from '../../../components/common/Loading';
import TaskGoodsListItem from '../../../components/Order/TaskGoodsListItem';
import EmptyDisplayBlock from '../../../components/common/EmptyDisplayBlock';

const TaskGoodsListScreen = () => {
  const route = useRoute<RouteProp<OrderParamList, 'TaskGoodsList'>>();
  const { orderId } = route.params;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taskGoods, setTaskGoods] = useState<TaskGood[]>([]);

  const getTaskGoods = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('order/task/goods', { params: { order_id: orderId } })
      .then((response) => {
        if (response && response.goodStatus) {
          setTaskGoods(response.data.task_goods);
        } else {
          alert(`Error:${response?.return_code}`, response?.data || '');
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [orderId]);

  useEffect(() => {
    let isSubscribed = true;
    isSubscribed && getTaskGoods();

    return () => {
      isSubscribed = false;
    };
  }, [getTaskGoods]);

  return (
    <FullWidthView edges={['bottom', 'left', 'right']}>
      {isLoading ? (
        <Loading />
      ) : taskGoods && taskGoods.length > 0 ? (
        <FlatList
          style={{ flex: 1 }}
          data={taskGoods}
          renderItem={({ item }) => <TaskGoodsListItem item={item} />}
          keyExtractor={(item: TaskGood) => item.tg_id.toString()}
          ItemSeparatorComponent={() => <ListSeparator />}
        />
      ) : (
        <EmptyDisplayBlock />
      )}
    </FullWidthView>
  );
};

export default TaskGoodsListScreen;
