import React from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import HomeLinkNavigator from '../HomeLinkNavigator';
import ResponsiveImg from '../../common/ResponsiveImg';
import { AdItem } from '../../../types';

type AdLayoutProps = {
  data: Array<AdItem>;
  marginBottom?: number;
  marginHorizontal?: number;
  backgroundColor?: string;
  tcType: string;
  method?: string;
};

const { width } = Dimensions.get('window');

const AdLayout = ({
  data,
  marginBottom = 10,
  marginHorizontal = 10,
  backgroundColor = 'white',
  tcType,
  method,
}: AdLayoutProps) => {
  const adjustedWidth = width - marginHorizontal * 2;
  const isValid = data && data.length > 0;
  //limit the data array to 3 elements
  const adArray = data;
  adArray.splice(3);

  return isValid ? (
    <View
      style={[
        styles.container,
        {
          width: width,
          marginBottom,
          backgroundColor,
        },
      ]}
    >
      {tcType === 'three_ads' && adArray.length === 3 && method ? (
        <ThreeAds
          adArray={adArray}
          adjustedWidth={adjustedWidth}
          method={method}
        />
      ) : (
        <>
          {adArray.map((item: AdItem, index) => (
            <AdsItem
              key={index}
              data={item}
              width={adjustedWidth / adArray.length}
            />
          ))}
        </>
      )}
    </View>
  ) : (
    <></>
  );
};

const ThreeAds = ({
  adArray,
  adjustedWidth,
  method,
}: {
  adArray: Array<AdItem>;
  adjustedWidth: number;
  method: string;
}) => {
  switch (method) {
    case '1-1-1':
      return (
        <>
          {adArray.map((item: AdItem, index) => (
            <AdsItem
              key={index}
              data={item}
              width={adjustedWidth / adArray.length}
            />
          ))}
        </>
      );
    case '2-1':
      return (
        <ThreeAdsStack
          adArray={adArray}
          adjustedWidth={adjustedWidth}
          order={'row'}
        />
      );
    case '1-2':
      return (
        <ThreeAdsStack
          adArray={adArray}
          adjustedWidth={adjustedWidth}
          order={'row-reverse'}
        />
      );
    default:
      return (
        <>
          {adArray.map((item: AdItem, index) => (
            <AdsItem
              key={index}
              data={item}
              width={adjustedWidth / adArray.length}
            />
          ))}
        </>
      );
  }
};

const ThreeAdsStack = ({
  adArray,
  adjustedWidth,
  order,
}: {
  adArray: Array<AdItem>;
  adjustedWidth: number;
  order: 'row' | 'row-reverse';
}) => {
  return (
    <View style={[styles.row, { width: adjustedWidth, flexDirection: order }]}>
      <View style={styles.column}>
        <AdsItem data={adArray[0]} width={adjustedWidth / 2 - 5} />
        <View style={{ height: 8 }}></View>
        <AdsItem data={adArray[1]} width={adjustedWidth / 2 - 5} />
      </View>
      <View style={styles.column}>
        <AdsItem data={adArray[2]} width={adjustedWidth / 2 - 5} />
      </View>
    </View>
  );
};

const AdsItem = ({ data, width }: { data: AdItem; width: number }) => {
  return (
    <View>
      <HomeLinkNavigator data={data}>
        <ResponsiveImg uri={data.img} width={width} />
      </HomeLinkNavigator>
    </View>
  );
};

export default AdLayout;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  column: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
