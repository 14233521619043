import React, { forwardRef } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import PostMenuItem from './PostMenuItem';

type PostMenuProps = {
  categoryNames: Array<{ cat_id: number; cat_name: string }>;
  selectedCatId: number;
  horizontalIndex: number;
  handleCategoryPress: Function;
};

const PostMenu = (
  {
    categoryNames,
    selectedCatId,
    horizontalIndex,
    handleCategoryPress,
  }: PostMenuProps,
  ref: any
) => {
  const _renderItem = ({ item, index }: any) => {
    return (
      <PostMenuItem
        index={index}
        selectedCatId={selectedCatId}
        cat_id={item.cat_id}
        cat_name={item.cat_name}
        horizontalIndex={horizontalIndex}
        handleCategoryPress={handleCategoryPress}
      />
    );
  };
  return (
    <View style={styles.container}>
      <FlatList
        ref={ref}
        removeClippedSubviews={true}
        showsHorizontalScrollIndicator={false}
        data={categoryNames}
        extraData={horizontalIndex}
        renderItem={_renderItem}
        horizontal
        keyExtractor={(item, index) =>
          item.cat_name.toString() + '@' + index.toString()
        }
      />
    </View>
  );
};

export default forwardRef(PostMenu);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 6,
    backgroundColor: 'white'
  },
});
