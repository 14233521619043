import React, { useState, useEffect } from 'react';
import { View, SectionList, StyleSheet } from 'react-native';
import { CartShop, CartItem } from '../../types';
import ShopHeader from './ShopHeader';
import ShopFooter from './ShopFooter';
import ProductItem from './ProductItem';
import { postWithAuthToken, getCartInformation } from '../../utils/service';
import Toast from 'react-native-tiny-toast';
import { useDispatch } from 'react-redux';

type CartItemListProps = {
  list: Array<CartShop>;
};

const CartItemList = ({ list }: CartItemListProps) => {
  const [showAllItems, setShowAllItems] = useState<Array<boolean>>([]);
  const [isCalculating, setIsCalculating] = useState(false);

  // Object where key = store id, value = all items in cart selected
  const [allSelected, setAllSelected] = useState<{ [key: number]: boolean }>(
    {}
  );

  // Object where key = store id, value = all items in cart unSelected
  const [allDeselected, setAllDeselected] = useState<{
    [key: number]: boolean;
  }>({});

  const dispatch = useDispatch();

  useEffect(() => {
    //Init showAllItems by setting default as collapse all
    const showAllItemsStatus = list.map((_) => false);
    if (showAllItemsStatus.length !== showAllItems.length) {
      setShowAllItems(showAllItemsStatus);
    }

    // Setting up allSelected and allDeselected Variable
    let allSelectedStart: { [key: number]: boolean } = {};
    let allDeselectedStart: { [key: number]: boolean } = {};
    list.map((store: CartShop) => {
      allSelectedStart[store.ru_id] =
        store.data.filter((x) => x.is_checked === 0).length === 0;

      allDeselectedStart[store.ru_id] =
        store.data.filter((x) => x.is_checked === 1).length === 0;
    });
    setAllSelected(allSelectedStart);
    setAllDeselected(allDeselectedStart);
  }, [list]);

  const deselectAllHandler = (ru_id: number, value: boolean) => {
    setAllDeselected({
      ...allDeselected,
      [ru_id]: value,
    });
  };

  // Makes all items in store selected
  const selectOrDeselectAllItems = (ru_id: number, select: 0 | 1) => {
    setAllSelected({
      ...allSelected,
      [ru_id]: select === 1,
    });

    setAllDeselected({
      ...allDeselected,
      [ru_id]: select === 0,
    });

    // This sends a request to select all unselected items in the shop
    // Once the last request gets a response then the app will refresh the cart information
    const shop = list.filter((x) => x.ru_id === ru_id)[0];
    const unselected = shop.data.filter((x) => x.is_checked !== select);
    let numOfResponse = 0;
    setIsCalculating(true);
    unselected.map((item) => {
      toggleItemSelect(item, select).then(() => {
        numOfResponse++;
        if (numOfResponse === unselected.length) {
          getCartInformation()
            .then((result) => {
              if (result && result.goodStatus) {
                dispatch({
                  type: 'UPDATE_CART_LIST',
                  payload: result.data.cart_list,
                });
                dispatch({
                  type: 'UPDATE_CART_ITEM_COUNTER',
                  payload: result.data.cart_number,
                });
              } else {
                console.log(result);
              }
              setIsCalculating(false);
            })
            .catch((err) => {
              setIsCalculating(false);
              throw err;
            });
        }
      });
    });
  };

  // Toggles item from select to unselected
  function toggleItemSelect(item: CartItem, isChecked: 0 | 1) {
    // if the item is being unchecked then allSelected will be turned to false
    if (isChecked === 0) {
      setAllSelected({
        ...allSelected,
        [item.ru_id]: false,
      });
    }

    return new Promise((resolve, reject) => {
      postWithAuthToken('cart/select', {
        id: item.rec_id,
        is_checked: isChecked,
      })
        .then((result) => {
          if (result && result.goodStatus) {
            //Successfully update
            resolve(result);
          } else {
            Toast.show(result ? result.data : 'Error');
            reject(result);
          }
        })
        .catch((err) => reject(err));
    });
  }

  return (
    <View style={styles.container}>
      <SectionList
        contentContainerStyle={{ padding: 10 }}
        sections={list}
        keyExtractor={(item) => item.rec_id.toString()}
        stickySectionHeadersEnabled={false}
        renderItem={({ item, index, section }) => {
          const sectionIndex = list.indexOf(section);
          return (
            <ProductItem
              item={item}
              index={index}
              showAllItems={showAllItems[sectionIndex]}
              isCalculating={isCalculating}
              setIsCalculating={setIsCalculating}
              toggleItemSelect={toggleItemSelect}
              allSelected={allSelected[section.ru_id]}
              allDeselected={allDeselected[section.ru_id]}
              deselectAllHandler={deselectAllHandler}
            />
          );
        }}
        renderSectionHeader={({ section }) => {
          const sectionIndex = list.indexOf(section);
          return (
            <ShopHeader
              logo={section.shop_logo}
              shopName={section.shop_name}
              totalNumber={section.total.total_goods_number}
              shippingName={section.shipping_name}
              triggerCollapseView={setShowAllItems}
              showAllItems={showAllItems[sectionIndex]}
              sectionIndex={sectionIndex}
              itemCounter={section.data.length}
              bestTime={section.best_time ? section.best_time : null}
              activityList={
                section.activity_list ? section.activity_list : null
              }
            />
          );
        }}
        renderSectionFooter={({ section }) => {
          const sectionIndex = list.indexOf(section);
          return (
            <ShopFooter
              total={section.total}
              triggerCollapseView={setShowAllItems}
              showAllItems={showAllItems[sectionIndex]}
              sectionIndex={sectionIndex}
              itemCounter={section.data.length}
              isCalculating={isCalculating}
              tId={section.tid}
              ruId={section.ru_id}
              orderType={section.rec_type}
              bestTime={
                section.best_shipping_time ? section.best_shipping_time : null
              }
              allSelected={allSelected[section.ru_id]}
              selectOrDeselectAllItems={selectOrDeselectAllItems}
            />
          );
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default CartItemList;
