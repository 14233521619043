import React from 'react';
import { StyleSheet, View, StyleProp, TextStyle } from 'react-native';

type HeaderViewProp = {
  children?: React.ReactNode;
  style?: StyleProp<TextStyle>;
};

const HeaderView = ({ children, style }: HeaderViewProp) => {
  return <View style={[styles.header, style]}>{children}</View>;
};

export default HeaderView;

const styles = StyleSheet.create({
  header: {},
});
