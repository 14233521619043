import React from 'react';
import { View, StyleSheet } from 'react-native';
import StyledText from '../common/StyledText';
import { accentColor, subBlueColor, warningRed } from '../../constants/Colors';
import { GoodsInfo } from '../../types';
import { useTranslation } from 'react-i18next';

const ProductInfo = ({
  goods_name: name,
  goods_brief: brief,
  formated_final_goods_price: finalPrice,
  formated_shop_price: shopPrice,
  formated_estimate_shop_price: estimatePrice,
  limit_number: limitNumber,
  goods_unit: unit,
  tag,
  text_tag,
  weight_tag,
}: Pick<
  GoodsInfo,
  | 'goods_name'
  | 'goods_brief'
  | 'formated_final_goods_price'
  | 'formated_shop_price'
  | 'formated_estimate_shop_price'
  | 'limit_number'
  | 'goods_unit'
  | 'tag'
  | 'text_tag'
  | 'weight_tag'
>) => {
  const { t } = useTranslation();

  const renderPriceText = () => {
    if (finalPrice !== shopPrice) {
      return (
        <View>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <StyledText bold size={16} color={accentColor}>
              {finalPrice}
              <StyledText size={12}>{' /' + unit} </StyledText>
            </StyledText>
            {text_tag && (
              <View
                style={[styles.textTag, { backgroundColor: text_tag.color }]}
              >
                <StyledText size={11} color={'white'}>
                  {text_tag.text}
                </StyledText>
              </View>
            )}
            {typeof weight_tag === 'string' && weight_tag !== '' && (
              <View style={[styles.textTag, { backgroundColor: subBlueColor }]}>
                <StyledText size={11} color={'white'}>
                  {weight_tag}
                </StyledText>
              </View>
            )}
          </View>
          <StyledText bold size={12} style={styles.originalPrice}>
            {shopPrice}
          </StyledText>
          {typeof weight_tag === 'string' &&
            weight_tag !== '' &&
            estimatePrice && (
              <StyledText size={12}>
                <StyledText size={12}>
                  {t('productInformation.estimatePrice')}:{' '}
                </StyledText>
                {estimatePrice}
              </StyledText>
            )}
        </View>
      );
    } else {
      return (
        <View>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <StyledText bold size={16} color={accentColor}>
              {shopPrice}
              <StyledText size={12}>{' /' + unit} </StyledText>
            </StyledText>
            {!!weight_tag && (
              <View style={[styles.textTag, { backgroundColor: subBlueColor }]}>
                <StyledText size={11} color={'white'}>
                  {weight_tag}
                </StyledText>
              </View>
            )}
          </View>
          {estimatePrice && (
            <StyledText size={12}>
              <StyledText size={12}>
                {t('productInformation.estimatePrice')}:{' '}
              </StyledText>
              {estimatePrice}
            </StyledText>
          )}
        </View>
      );
    }
  };

  const renderLimitSection = () => {
    if (limitNumber > 0) {
      return (
        <View style={styles.limitSection}>
          <StyledText color={warningRed} size={12}>
            {t('productDetailScreen.limitedPurchase', { num: limitNumber })}
          </StyledText>
        </View>
      );
    } else {
      return <></>;
    }
  };

  return (
    <View>
      <View>
        <View>
          {typeof tag === 'string' && tag !== '' && (
            <View style={styles.tag}>
              <StyledText color={warningRed}>{tag}</StyledText>
            </View>
          )}
          <StyledText bold={true} size={16}>
            {name}
          </StyledText>
        </View>
        {brief ? (
          <StyledText color="gray" size={14}>
            {brief}
          </StyledText>
        ) : (
          <></>
        )}
        {renderPriceText()}
        {renderLimitSection()}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  originalPrice: {
    color: 'lightgray',
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  limitSection: {
    borderTopColor: 'lightgray',
    borderTopWidth: 1,
    marginTop: 10,
    paddingTop: 10,
  },
  tag: {
    borderWidth: 1,
    borderColor: warningRed,
    padding: 2,
    borderRadius: 5,
    marginRight: 3,
    alignSelf: 'flex-start',
  },
  textTag: {
    marginLeft: 2,
    paddingHorizontal: 10,
    borderRadius: 8,
    paddingVertical: 4,
  },
});

export default ProductInfo;
