import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import BrandListScreen from '../screens/Brand/BrandListScreen';
import BrandDetailScreen from '../screens/Brand/BrandDetailScreen';
import { BrandParamList } from '../types';
import NavigateButton from '../components/common/NavigateButton';
import BackButton from '../components/common/iconButtons/BackButton';
import { useTranslation } from 'react-i18next';
import getAppStyle from '../utils/getAppStyle';

const BrandStack = createStackNavigator<BrandParamList>();

const BrandNavigator = () => {
  const { t } = useTranslation();

  return (
    <BrandStack.Navigator
      initialRouteName="BrandList"
      screenOptions={{
        headerBackTitleVisible: false,
        headerTitleAlign: 'center',
        headerLeft: () => (
          <NavigateButton goBack>
            <BackButton />
          </NavigateButton>
        ),
        headerStyle: getAppStyle(process.env.EXPO_APP_TYPE)?.screenHeaderStyle,
      }}
    >
      <BrandStack.Screen
        name="BrandList"
        component={BrandListScreen}
        options={{
          headerTitle: t('brand.brandList'),
        }}
      />
      <BrandStack.Screen
        name="BrandDetail"
        component={BrandDetailScreen}
        options={{
          headerTitle: t('brand.brandDetail'),
        }}
      />
    </BrandStack.Navigator>
  );
};

export default BrandNavigator;
