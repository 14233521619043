import React, { useEffect, useState, useRef } from 'react';
import {
  StyleSheet,
  FlatList,
  Platform,
  View,
  TouchableOpacity,
  Dimensions,
  ScrollView,
} from 'react-native';
import Constants from 'expo-constants';
import FullWidthView from '../../components/common/FullWidthView';
import { backGroundLight } from '../../constants/Colors';
import { useTranslation } from 'react-i18next';
import StyledText from '../../components/common/StyledText';
import { getData } from '../../utils/service';
import { resetWechatSharePage } from '../../utils/wechatWebMethods';

/*Import: Components */
import PostCard from '../../components/Post/card/PostCard';
import EmptyDisplayBlock from '../../components/common/EmptyDisplayBlock';
import ProductListSkeletonLoad from '../../components/common/ProductListSkeletonLoad';
import ListFooterText from '../../components/common/list/ListFooterText';
import ActivityIndicator from '../../components/common/ActivityIndicator';

/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import { useHeaderHeight } from '@react-navigation/stack';
import getAppStyle from '../../utils/getAppStyle';

// WechatShareBtn should only be shown on standalone app
let WechatShareBtn: Function;
if (Constants.appOwnership === 'expo' || Platform.OS === 'web') {
  WechatShareBtn = () => <View></View>;
} else {
  WechatShareBtn =
    require('../../components/common/WechatShare/WechatShareBtn').WechatShareBtn;
}

const PostDetailScreen = ({ navigation, route }: any) => {
  const [highlightPost, setHighlightPost] = useState<any>({});
  const [userPosts, setUserPosts] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [firstLoadComplete, setFirstLoadComplete] = useState(false);
  const [postAllLoaded, setPostAllLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);

  const { t } = useTranslation();

  const isSubscribed = useRef(true);
  const flatListRef = useRef<FlatList>(null);

  const getFirstPage = () => {
    setIsFetching(true);
    getData(`post/user_posts?user_id=${route.params.user_id}&page=1&size=10`)
      .then((result) => {
        if (result && result.goodStatus) {
          if (isSubscribed.current) {
            setUserPosts(result.data.posts);
            setTotalPage(result.data.total_page);
            result.data.posts.length < 10 && setPostAllLoaded(true);
          } else {
            throw result.data;
          }
        }
      })
      .catch((err) => {
        Toast.show('err', {
          position: 0,
          duration: 2000,
        });
      })
      .finally(() => {
        setFirstLoadComplete(true);
        setIsFetching(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getFirstPage();
    getData(`post/detail?post_id=${route.params.postId}`)
      .then((result) => {
        if (result && result.goodStatus) {
          if (isSubscribed.current) {
            if (result.goodStatus) {
              setHighlightPost(result.data);
              setLoading(false);
            } else {
              throw result.data;
            }
          }
        }
      })
      .catch((err) => {
        Toast.show('err', {
          position: 0,
          duration: 2000,
        });
      });

    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (currentPage !== 1) {
      if (!postAllLoaded) {
        setIsFetching(true);
        getData(
          `post/user_posts?user_id=${route.params.user_id}&page=${currentPage}&size=10`
        )
          .then((result) => {
            if (result) {
              if (result.goodStatus) {
                if (isSubscribed.current) {
                  setUserPosts([...userPosts, ...result.data.posts]);
                  currentPage == totalPage && setPostAllLoaded(true);
                }
              }
            } else {
              console.log('error');
            }
          })
          .catch((err) => {
            Toast.show('err', {
              position: 0,
              duration: 2000,
            });
          })
          .finally(() => {
            setIsFetching(false);
          });
      }
    }
  }, [currentPage]);

  const onPress = (item: any) => {
    setHighlightPost(item);
    flatListRef.current?.scrollToOffset({ animated: true, offset: 0 });
  };

  /* Wechat section -------- Start */

  // Setting up Wechat share information
  useEffect(() => {
    const title = highlightPost.goods
      ? `${t('postScreen.title')} | ${highlightPost.goods?.goods_name}`
      : `${t('postScreen.title')}`;

    const link =
      Platform.OS === 'web'
        ? window.location.href
        : `${getAppStyle(process.env.EXPO_APP_TYPE)?.appWebsite}/post/detail/${
            route.params.postId
          }?user_id=${route.params.user_id}`;

    const imgUrl =
      highlightPost.goods?.goods_thumb ??
      'https://image.luniumall.com/default/postIcon.png';

    if (Platform.OS === 'web')
      resetWechatSharePage(title, undefined, imgUrl, link);

    // Also set the shareBtn for Mobile platform.
    navigation.setOptions({
      headerRight: () => (
        <WechatShareBtn title={title} url={link} imageUrl={imgUrl} />
      ),
    });

    // Set up Wechat share information "again" when user come back from the other page.
    const unsubscribe = navigation.addListener('focus', () => {
      if (Platform.OS === 'web')
        resetWechatSharePage(title, undefined, imgUrl, link);
    });
    return unsubscribe;
  }, [highlightPost]);

  /* Wechat section -------- End */

  const _renderItem = ({ item }: { item: any; index: number }) => (
    <PostCard
      comment_id={item.comment_id}
      nick_name={item.nick_name}
      user_picture={item.user_picture}
      add_time={item.add_time}
      tag={item.tag}
      content={item.content}
      images={item.images}
      goods={item.goods}
      user_id={item.userId}
      navigation={navigation}
      comment_rank={item.comment_rank}
      admin_reply={null}
      detailFunction={() => onPress(item)}
      isDetailScreen={false}
      adminReply={item.admin_reply}
    />
  );

  const handleReachBottomUpdate = () => {
    if (isFetching) return;
    !postAllLoaded &&
      currentPage < totalPage &&
      setCurrentPage(currentPage + 1);
  };

  const onRefresh = () => {
    setIsFetching(true);
    getFirstPage();
  };

  return (
    <FullWidthView
      edges={['bottom', 'left', 'right']}
      style={{ backgroundColor: backGroundLight }}
    >
      {firstLoadComplete ? (
        userPosts.length > 0 ? (
          <FlatList
            style={{ flex: 1 }}
            ref={flatListRef}
            removeClippedSubviews={true}
            onRefresh={onRefresh}
            refreshing={isFetching}
            data={userPosts}
            keyExtractor={(item, index) =>
              item.comment_id.toString() + '@' + index.toString()
            }
            renderItem={_renderItem}
            ListHeaderComponent={
              <>
                {!loading && (
                  <PostCard
                    comment_id={highlightPost.comment_id}
                    nick_name={highlightPost.nick_name}
                    user_picture={highlightPost.user_picture}
                    add_time={highlightPost.add_time}
                    tag={highlightPost.tag}
                    content={highlightPost.content}
                    images={highlightPost.images}
                    goods={highlightPost.goods}
                    user_id={highlightPost.userId}
                    navigation={navigation}
                    comment_rank={highlightPost.comment_rank}
                    admin_reply={null}
                    disabled
                    highlightPostId={highlightPost.comment_id}
                    isDetailScreen={true}
                    adminReply={highlightPost.admin_reply}
                  />
                )}
                <StyledText bold size={16} style={{ marginTop: 10 }}>
                  {t('postScreen.postDetail.viewMore')}
                </StyledText>
              </>
            }
            onEndReachedThreshold={0.01}
            onEndReached={() => handleReachBottomUpdate()}
            ListFooterComponent={
              postAllLoaded ? ListFooterText : ActivityIndicator
            }
            /* bump these up so when user does fast scrolling, items can render properly. */
            maxToRenderPerBatch={200}
            windowSize={41}
          />
        ) : (
          <EmptyDisplayBlock />
        )
      ) : (
        <ProductListSkeletonLoad />
      )}
    </FullWidthView>
  );
};

export default PostDetailScreen;

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    backgroundColor: backGroundLight,
    flexDirection: 'column',
    position: 'relative',
    paddingHorizontal: 10,
  },
});
