import React from 'react';
import { Pressable, Image, Linking } from 'react-native';
import { RedPacket } from '../../../types';

type RedPacketBtnProps = {
  redPacket: RedPacket;
};

const RedPacketBtn = ({ redPacket }: RedPacketBtnProps) => {

  const handleWebShare = () => {
    let shareCode = redPacket.link.split('?');
    if(shareCode.length === 2)
    Linking.openURL(redPacket.link);
  }

  return (
    <>
      {redPacket && redPacket.link.length > 0 && (
        <Pressable onPress={() => handleWebShare()} style={{marginRight: 5}}>
          <Image
            source={require('../../../assets/images/icons/red_packet.png')}
            style={{ width: 26, height: 26 }}
          />
        </Pressable>
      )}
    </>
  );
};

export default RedPacketBtn;
