import React from 'react';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import NavigateButton from '../common/NavigateButton';
import { AntDesign } from '@expo/vector-icons';
import StyledText from '../common/StyledText';
import { useTranslation } from 'react-i18next';
import AddToCartBtn from '../Cart/AddToCartBtn';
import { RED3 } from '../../constants/Colors';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import getAppStyle from '../../utils/getAppStyle';

type ProductNavbarProps = {
  goodsId: number;
  goodsData: any;
  isCollect: any;
  onPressFavorite: Function;
  addToCartDisabled: boolean;
  isPresaleProduct: boolean;
};

const ProductNavbar = ({
  isCollect,
  onPressFavorite,
  goodsId,
  goodsData,
  addToCartDisabled,
  isPresaleProduct,
}: ProductNavbarProps) => {
  const { t } = useTranslation();
  const linkTo = useLinkTo();

  const navbarItems: Array<NavbarItemType> = [
    {
      label: t('productNavbar.support'),
      iconName: 'customerservice',
      route: 'Modal',
      loginRequired: true,
      navigate: true,
      options: {
        screen: 'CustomerServiceModal',
        params: { ruId: goodsData.shop.ru_id },
      },
    },
    {
      label: t('productNavbar.favorite'),
      iconName: isCollect ? 'heart' : 'hearto',
      iconColor: RED3,
      loginRequired: true,
      navigate: false,
      onPress: onPressFavorite,
    },
    {
      label: t('productNavbar.cart'),
      iconName: 'shoppingcart',
      route: 'Cart',
      loginRequired: false,
      navigate: true,
    },
    ...(Platform.OS === 'web'
      ? [
          {
            label: t('tabsLabel.tabHome'),
            iconName: 'home',
            navigate: false,
            onPress: () => {
              linkTo('/home');
            },
          },
        ]
      : []),
  ];

  const _renderItems = navbarItems.map((item, index) => {
    if (item.iconName === 'customerservice') {
      return <View key={index} />;
    } else {
      return <NavbarItem item={item} key={index} />;
    }
  });

  return (
    <View style={styles.navbar}>
      {_renderItems}
      <View
        style={[
          styles.addToCartButton,
          {
            backgroundColor: addToCartDisabled
              ? 'lightgray'
              : getAppStyle(process.env.EXPO_APP_TYPE)?.secondaryColor,
          },
        ]}
      >
        <AddToCartBtn
          disabled={addToCartDisabled}
          goodsId={goodsId}
          goodsData={goodsData}
          isPresale={isPresaleProduct}
        >
          <StyledText color={'white'}>
            {isPresaleProduct
              ? t('productInformation.preOrderButton')
              : t('productNavbar.addToCart')}
          </StyledText>
        </AddToCartBtn>
      </View>
    </View>
  );
};

type NavbarItemType = {
  label: string;
  iconName: string;
  iconColor?: string;
  route?: string;
  loginRequired?: boolean;
  navigate: boolean;
  onPress?: any;
  options?: object;
};

type NavbarItemProps = {
  item: NavbarItemType;
};

const NavbarItem = ({ item }: NavbarItemProps) => {
  const navigation = useNavigation();

  return (
    <View style={styles.tab}>
      {item.navigate ? (
        item.route !== 'Cart' ? (
          <NavigateButton
            route={item.route}
            loginRequired={item.loginRequired ? true : false}
            options={item.options ? item.options : undefined}
          >
            <View style={styles.navbarItem}>
              <AntDesign name={item.iconName} size={20} color={'gray'} />
              <StyledText size={12} align="center">
                {item.label}
              </StyledText>
            </View>
          </NavigateButton>
        ) : (
          <TouchableOpacity
            onPress={() => {
              navigation.reset({
                index: 0,
                routes: [
                  {
                    name: 'Root',
                    params: {
                      screen: 'Cart',
                    },
                  },
                ],
              });
            }}
          >
            <View style={styles.navbarItem}>
              <AntDesign name={item.iconName} size={20} color={'gray'} />
              <StyledText size={12} align="center">
                {item.label}
              </StyledText>
            </View>
          </TouchableOpacity>
        )
      ) : (
        <TouchableOpacity onPress={item.onPress}>
          <View style={styles.navbarItem}>
            <AntDesign
              name={item.iconName}
              size={20}
              color={item.iconName === 'heart' ? item.iconColor : 'gray'}
            />
            <StyledText size={12} align="center">
              {item.label}
            </StyledText>
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  navbar: {
    flexDirection: 'row',
    borderTopColor: 'lightgray',
    borderTopWidth: 1,
    backgroundColor: 'white',
    paddingVertical: 5, //Controls the height of the navbar
  },
  tab: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  navbarItem: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addToCartButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    marginRight: 10,
  },
});

export default ProductNavbar;
