import React from 'react';
import { StyleSheet, View } from 'react-native';
import { orderStatusToString, OrderStatusType } from '../../../utils/toString';
import StyledText from '../../common/StyledText';
import moment from 'moment';
import { backGroundLight, subTextColor } from '../../../constants/Colors';
import { useTranslation } from 'react-i18next';

type LogType = {
  id: number;
  status: OrderStatusType;
  created_at: string;
};

type LogListProps = {
  logs: LogType[];
};

const LogList = ({ logs }: LogListProps) => {
  const { t } = useTranslation();

  return (
    <View style={styles.root}>
      <StyledText align="center" bold size={16}>
        {t('order.orderTracking.log.title')}
      </StyledText>
      {logs.length > 0 ? (
        logs.map((log) => {
          return <LogItem key={log.id} log={log} />;
        })
      ) : (
        <StyledText color={subTextColor} align="center">
          {t('order.orderTracking.log.noLog')}
        </StyledText>
      )}
    </View>
  );
};

type LogItemProps = {
  log: LogType;
};

const LogItem = ({ log }: LogItemProps) => {
  const { t } = useTranslation();

  return (
    <View style={styles.itemContainer}>
      <StyledText>
        {t('order.orderTracking.log.statusChange', {
          status: orderStatusToString(log.status),
        })}
      </StyledText>
      <StyledText color={subTextColor} align="right">
        {moment(log.created_at).format('YYYY-MM-DD k:mm')}
      </StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: backGroundLight,
    padding: 10,
  },
  itemContainer: {
    paddingVertical: 10,
    borderBottomColor: backGroundLight,
    borderBottomWidth: 1,
  },
});

export default LogList;
