import React, { ReactNode } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import StyledText from './StyledText';
import { backGroundLight, normalTextColor } from '../../constants/Colors';

type WhiteCardProps = {
  children?: ReactNode;
  title?: string;
  icon?: {
    iconComponent: JSX.Element;
    handleActionPress?: () => void;
  };
  action?: {
    label: string;
    handleActionPress: () => void;
  };
  titleColor?: string;
  description?: {
    label: string;
    color: string;
  };
  centerTitle?: boolean;
};

/**
 * White background Card styled wrapper
 */
const WhiteCard = ({
  children,
  title,
  icon,
  action,
  titleColor = normalTextColor,
  description,
  centerTitle = false,
}: WhiteCardProps) => {
  const handleActionPress = () => {};

  if (title) {
    return (
      <View style={styles.whiteCard}>
        <View style={styles.cardHeader}>
          <View
            style={[
              styles.titleContainer,
              centerTitle ? { justifyContent: 'center' } : {},
            ]}
          >
            <StyledText
              bold
              size={16}
              style={styles.titleText}
              color={titleColor}
            >
              {title}
            </StyledText>
            {icon && (
              <TouchableOpacity
                onPress={
                  icon.handleActionPress
                    ? icon.handleActionPress
                    : handleActionPress
                }
              >
                {icon.iconComponent}
              </TouchableOpacity>
            )}
          </View>
          {action && (
            <TouchableOpacity
              onPress={
                action.handleActionPress
                  ? action.handleActionPress
                  : handleActionPress
              }
            >
              <StyledText>{action.label + ' >'}</StyledText>
            </TouchableOpacity>
          )}
          {description && (
            <View style={styles.description}>
              <StyledText
                color={description.color ? description.color : normalTextColor}
                align="right"
              >
                {description.label}
              </StyledText>
            </View>
          )}
        </View>
        {/* Card Content */}
        <View style={styles.cardContent}>{children}</View>
      </View>
    );
  }

  //Without Header
  return <View style={styles.whiteCard}>{children}</View>;
};

export default WhiteCard;

const styles = StyleSheet.create({
  whiteCard: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
  },
  cardHeader: {
    paddingBottom: 10,
    borderBottomColor: backGroundLight,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContent: {
    // padding: 10,
  },
  titleText: {
    marginRight: 5,
  },
  description: {
    flex: 2,

    alignItems: 'flex-end',
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
});
