import React from 'react';
import FullWidthView from '../../../components/common/FullWidthView';
import { backGroundLight } from '../../../constants/Colors';
import * as Application from 'expo-application';
import ListOption from '../../../components/common/list/ListOption';
import { View, StyleSheet, Image, Platform } from 'react-native';
import StyledText from '../../../components/common/StyledText';

import { AntDesign } from '@expo/vector-icons';
import NavigateButton from '../../../components/common/NavigateButton';
import { useTranslation } from 'react-i18next';
import { webCurrentVersion } from '../../../constants/WebConstants';
import getAppStyle from '../../../utils/getAppStyle';

const AboutLuniuScreen = () => {
  const { t } = useTranslation();

  return (
    <FullWidthView style={{ backgroundColor: backGroundLight }}>
      <View style={styles.logoContainer}>
        <Image
          source={getAppStyle(process.env.EXPO_APP_TYPE)?.icon}
          style={{ width: 100, height: 100, borderRadius: 10 }}
        />
      </View>

      <ListOption>
        <View style={styles.listItem}>
          <StyledText>{t(`userSettingScreen.aboutLuniu.version`)}</StyledText>
          <StyledText>
            {Platform.OS === 'web'
              ? webCurrentVersion
              : Application.nativeApplicationVersion}
          </StyledText>
        </View>
      </ListOption>
      <ListOption>
        <NavigateButton
          route="SettingWebView"
          options={{
            url: getAppStyle(process.env.EXPO_APP_TYPE)?.aboutURL,
            title: t('userSettingScreen.aboutLuniu.privacyPolicy'),
          }}
        >
          <View style={styles.listItem}>
            <StyledText>
              {t('userSettingScreen.aboutLuniu.privacyPolicy')}
            </StyledText>
            <AntDesign
              name="rightcircleo"
              size={16}
              color="lightgray"
              style={{ marginLeft: 5 }}
            />
          </View>
        </NavigateButton>
      </ListOption>
    </FullWidthView>
  );
};

const styles = StyleSheet.create({
  logoContainer: {
    alignItems: 'center',
    marginVertical: 30,
  },
  listItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default AboutLuniuScreen;
