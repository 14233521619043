import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, View, StyleSheet, TouchableOpacity } from 'react-native';
import { ReorderItem } from '../../types';
import FullWidthView from '../common/FullWidthView';
import GeneralModal from '../common/GeneralModal';
import ListSeparator from '../common/list/ListSeparator';
import ResponsiveImg from '../common/ResponsiveImg';
import StyledButton from '../common/StyledButton';
import StyledText from '../common/StyledText';

type ReorderModalProps = {
  title: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setReorderErrors: React.Dispatch<React.SetStateAction<ReorderItem[]>>;
  items: ReorderItem[];
};

const ReorderModal = ({
  title,
  visible,
  setVisible,
  setReorderErrors,
  items,
}: ReorderModalProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const renderItem = ({ item }: { item: ReorderItem }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          setVisible(false);
          navigation.navigate('ProductNavigator', {
            screen: 'ProductDetailScreen',
            params: { productId: item.goods_id },
          });
        }}
      >
        <View style={styles.item}>
          <ResponsiveImg uri={item.goods_thumb} width={60} />
          <StyledText style={{ marginLeft: 20 }}>{item.goods_name}</StyledText>
        </View>
      </TouchableOpacity>
    );
  };

  const onClose = () => {
    setReorderErrors([]);
    setVisible(false);
  };

  return (
    <GeneralModal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={onClose}
    >
      <FullWidthView style={styles.container}>
        <View style={styles.modal}>
          <StyledText size={16} bold>
            {title}
          </StyledText>
          <FlatList
            data={items}
            renderItem={renderItem}
            keyExtractor={(item) => item.goods_id.toString()}
            ItemSeparatorComponent={() => <ListSeparator />}
            style={{ width: '100%' }}
          />
          <StyledButton
            label={t('confirm')}
            onPress={() => {
              onClose();
              navigation.reset({
                index: 0,
                routes: [{ name: 'Root', params: { screen: 'Home' } }],
              });
              setTimeout(
                () => navigation.navigate('Root', { screen: 'Cart' }),
                100
              );
            }}
          />
        </View>
      </FullWidthView>
    </GeneralModal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  modal: {
    flex: 1,
    alignItems: 'center',
    width: '90%',
    paddingVertical: 30,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
  },
});

export default ReorderModal;
