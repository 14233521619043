import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import StyledText from '../../components/common/StyledText';
import ManuallyChooseRegionButton from '../../components/common/ManuallyChooseRegionButton';
import { useNavigation } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';
import { tintColorLight } from '../../constants/Colors';
import { useTranslation } from 'react-i18next';

type NoLocationProps = {
  type: 'granted' | 'range' | 'pending';
};

const NoLocation = ({ type = 'pending' }: NoLocationProps) => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  const handleNavigateRegionScreen = () => {
    navigation.navigate('UserLocation');
  };

  const renderContent = () => {
    if (type === 'range')
      return (
        <View style={styles.textContainer}>
          <AntDesign name="closecircle" size={80} color="lightgray" />
          <StyledText align="center" size={20}>
            {t('regionScreen.notFoundTitle')}
          </StyledText>
          <StyledText align="center">{t('regionScreen.notFound')}</StyledText>
        </View>
      );

    if (type === 'granted')
      return (
        <View style={styles.textContainer}>
          <AntDesign name="closecircle" size={80} color="lightgray" />
          <StyledText align="center" size={20}>
            {t('regionScreen.requireLocationPermissionFailed')}
          </StyledText>
          <StyledText align="center">
            {Platform.OS === 'ios' &&
              t('regionScreen.setLocationPermissionTipIOS')}

            {Platform.OS === 'android' &&
              t('regionScreen.setLocationPermissionTipAndroid')}
          </StyledText>
        </View>
      );

    return (
      <View style={styles.textContainer}>
        <AntDesign name="enviromento" size={80} color={tintColorLight} />
        <StyledText align="center" size={20}>
          {t('regionScreen.findingPosition')}
        </StyledText>
      </View>
    );
  };

  return (
    <View style={styles.root}>
      {renderContent()}
      <ManuallyChooseRegionButton />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  textContainer: {
    alignItems: 'center',
  },
  buttonContainer: {
    marginTop: 10,
  },
});

export default NoLocation;
