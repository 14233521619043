import React from 'react';
import {
  StyleProp,
  Image,
  ImageSourcePropType,
  ImageStyle,
} from 'react-native';

type CachedImageType = {
  source: ImageSourcePropType;
  style?: StyleProp<ImageStyle>;
};

const CachedImage = ({ source, style, ...props }: CachedImageType) => {
  return <Image source={source} style={style} {...props} />;
};

export default CachedImage;
