import React from 'react';
import FullWidthView from '../../components/common/FullWidthView';
import StyledText from '../../components/common/StyledText';
import { useNavigation, useRoute } from '@react-navigation/native';
import NicknameEditForm from '../../components/User/EditInfoSection/NicknameEditForm';
import { useTranslation } from 'react-i18next';
import GenderEditForm from '../../components/User/EditInfoSection/GenderEditForm';
import BirthdayEditForm from '../../components/User/EditInfoSection/BirthdayEditForm';
import PasswordEditForm from '../../components/User/EditInfoSection/PasswordEditForm';
import PhoneEditForm from '../../components/User/EditInfoSection/PhoneEditForm';
import EmailEditForm from '../../components/User/EditInfoSection/EmailEditForm';
import {
  View,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
} from 'react-native';
import { useHeaderHeight } from '@react-navigation/stack';
import AccountDeleteForm from '../../components/User/EditInfoSection/AccountDeleteForm';

const UserInfoEditScreen = () => {
  const navigation = useNavigation();

  const { t } = useTranslation();

  const route = useRoute();
  const { type, title, value } = route.params;

  const headerHeight = useHeaderHeight();
  navigation.setOptions({
    headerTitle:
      type === 'deleteAccount'
        ? title
        : t('userInfoEditScreen.title', { title: title }),
  });

  const renderForm = () => {
    switch (type) {
      case 'nickname':
        return <NicknameEditForm title={title} value={value} />;
      case 'gender':
        return <GenderEditForm title={title} value={value} />;
      case 'birthday':
        return <BirthdayEditForm title={title} value={value} />;
      case 'phone':
        return <PhoneEditForm value={value} />;
      case 'email':
        return <EmailEditForm value={value} />;
      case 'password':
        return <PasswordEditForm />;
      case 'deleteAccount':
        return <AccountDeleteForm />;
      default:
        return <StyledText align="center">Error Type</StyledText>;
    }
  };

  return (
    <TouchableWithoutFeedback
      onPress={Platform.OS === 'web' ? undefined : () => Keyboard.dismiss()}
      accessible={false}
    >
      <View style={{ flex: 1 }}>
        <FullWidthView
          style={{ paddingBottom: Platform.OS === 'web' ? headerHeight : 0 }}
        >
          {renderForm()}
        </FullWidthView>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default UserInfoEditScreen;
