import { useUpdateEffect } from 'ahooks';
import axios, { CancelTokenSource } from 'axios';
import React, { useEffect, useState } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { useSelector } from 'react-redux';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import FullWidthView from '../../components/common/FullWidthView';
import Loading from '../../components/common/Loading';
import BundleList from '../../components/ProductBundle/BundleList';
import DateSelector from '../../components/ProductBundle/DateSelector';
import { backGroundLight } from '../../constants/Colors';
import {
  BundleDate,
  BundleObject,
  LocationInformation,
  RootState,
} from '../../types';
import { getAds, getData } from '../../utils/service';
import BundleCarousel from '../../components/ProductBundle/BundleCarousel';
import EmptyDisplayBlock from '../../components/common/EmptyDisplayBlock';
import {
  defaultWeChatShareConfig,
  setWechatSharePage,
} from '../../utils/wechatWebMethods';

const BundleHomeScreen = () => {
  const location = useSelector<RootState, LocationInformation>(
    (state) => state.goods.location
  );

  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [isLoadingBundleList, setIsLoadingBundleList] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [bundleDates, setBundleDates] = useState<BundleDate[]>([]);
  const [bundleListData, setBundleListData] = useState<BundleObject[]>([]);
  const [bundleAds, setBundleAds] = useState([]);

  const [bundleNotice, setBundleNotice] = useState({ title: '', content: '' });
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    let isSubscribe = true;

    (async () => {
      const result = await getBundleDates(
        location.countryId,
        location.provinceId,
        location.cityId
      );
      if (isSubscribe) {
        if (result && result.goodStatus) {
          setBundleDates(result.data.day_list);
          setIsLoadingComplete(true);

          //Wechat share settings
          if (Platform.OS === 'web') {
            setTimeout(() => {
              setWechatSharePage(
                '超值大礼包',
                defaultWeChatShareConfig.desc,
                defaultWeChatShareConfig.logo
              );
            }, 1000);
          }
        } else {
          console.log('err for getting bundle dates', result);
        }
      }

      const adsResult = await getAds('package_list');
      if (isSubscribe) {
        if (adsResult && adsResult.goodStatus) {
          setBundleAds(adsResult.data);
        } else {
          console.log('err for getting bundle ads', adsResult);
        }
      }
    })();

    return () => {
      isSubscribe = false;
    };
  }, []);

  //Get bundle list page 1 when user change date
  useUpdateEffect(() => {
    let isSubscribe = true;
    let cancelToken: CancelTokenSource;
    cancelToken = axios.CancelToken.source();

    if (bundleDates.length > 0) {
      setBundleListData([]);
      setTotalPage(1);
      setCurrentPage(1);
      setAllDataLoaded(false);
      setIsLoadingBundleList(true);
      setIsFetching(true);
      (async () => {
        const result = await getBundleList(
          0,
          bundleDates[activeIndex].shipping_time,
          1,
          { cancelToken: cancelToken.token }
        );
        if (isSubscribe) {
          if (result && result.goodStatus) {
            setTotalPage(result.data.total_page);
            setBundleListData(result.data.package);
            setBundleNotice(result.data.notice);
            setAllDataLoaded(true);
          } else {
            console.log('err for getting bundle list', result);
          }
          setIsLoadingBundleList(false);
          setIsFetching(false);
        }
      })();
    }

    return () => {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('operation canceled due to new request');
      }
      isSubscribe = false;
    };
  }, [bundleDates, activeIndex]);

  return (
    <FullWidthView style={{ backgroundColor: backGroundLight }}>
      {isLoadingComplete ? (
        bundleDates.length > 0 ? (
          // <>
          //   <BundleCarousel data={bundleAds} />
          //   <DateSelector
          //     data={bundleDates}
          //     activeIndex={activeIndex}
          //     setActiveIndex={setActiveIndex}
          //   />
          //   <BundleList
          //     data={bundleListData}
          //     allDataLoaded={allDataLoaded}
          //     currentPage={currentPage}
          //     totalPage={totalPage}
          //     setCurrentPage={setCurrentPage}
          //     bundleNotice={bundleNotice}
          //   />
          // </>
          <ScrollView
            showsVerticalScrollIndicator={false}
            stickyHeaderIndices={[1]}
          >
            <BundleCarousel data={bundleAds} />
            <DateSelector
              data={bundleDates}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
            />
            <BundleList
              data={bundleListData}
              allDataLoaded={allDataLoaded}
              currentPage={currentPage}
              totalPage={totalPage}
              setCurrentPage={setCurrentPage}
              bundleNotice={bundleNotice}
              isFetching={isFetching}
            />
          </ScrollView>
        ) : (
          <EmptyDisplayBlock />
        )
      ) : (
        <View
          style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <Loading />
        </View>
      )}
    </FullWidthView>
  );
};

const getBundleDates = async (
  countryId: number,
  provinceId: number,
  cityId: number
) => {
  const result = await getData(
    `package/day?country_id=${countryId}&province_id=${provinceId}&city_id=${cityId}`
  );
  return result;
};

const getBundleList = async (
  ruId: number,
  time: number,
  page = 1,
  options?: object
) => {
  const result = await getData(
    `package?ru_id=${ruId}&shipping_time=${time}&page=${page}&size=99`,
    options
  );
  return result;
};

export default BundleHomeScreen;
