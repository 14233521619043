import React, { useEffect, useState } from 'react';
import FullWidthView from '../../components/common/FullWidthView';
import ThemeHomeScreenPaginator from '../../components/ThemeHomeScreen/ThemeHomeScreenPaginator';
import { Dimensions, View } from 'react-native';
import { getData } from '../../utils/service';
/** @ts-ignore */
import Toast from 'react-native-tiny-toast';
import Loading from '../../components/common/Loading';
import { LocationInformation, RootState, TopicPage } from '../../types';
import { MaterialIcons } from '@expo/vector-icons';
import StyledText from '../../components/common/StyledText';
import { useTranslation } from 'react-i18next';
import ThemeHomeScreenPage from '../../components/ThemeHomeScreen/ThemeHomeScreenPage';
import { screenHit } from '../../utils/analytic';
import { useSelector } from 'react-redux';

const { width } = Dimensions.get('window');

type ThemeHomeScreenProps = {
  route: any;
};

const ThemeHomeScreen = ({ route }: ThemeHomeScreenProps) => {
  const { topicId } = route.params ?? { topicId: -1 };
  const [topicData, setTopicData] = useState<Array<TopicPage>>([]);
  const [singleTab, setSingleTab] = useState<{
    isSingle: boolean;
    topicId: number;
  }>({ isSingle: false, topicId: -1 });
  const [horizontalIndex, setHorizontalIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();

  const location = useSelector<RootState, LocationInformation>(
    (state) => state.goods.location
  );

  useEffect(() => {
    screenHit(`topic-home/${topicId}`);
    let isSubscribe = true;
    setIsError(false);
    if (topicId >= 0) {
      setIsLoading(true);
      getTopicData(parseInt(topicId))
        .then((result) => {
          if (isSubscribe) {
            if (result && result.goodStatus) {
              //Has single tabs
              if (result.data.length == 0) {
                setSingleTab({ isSingle: true, topicId: topicId });
              }
              //Has multiple tabs
              else {
                setTopicData(result.data);
              }
            } else {
              throw result.data;
            }
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          Toast.show(err);
          console.log(err);
        });
    } else {
      setIsError(true);
    }
    return () => {
      isSubscribe = false;
    };
  }, [location]);

  return (
    <FullWidthView>
      {isError ? (
        <View
          style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <MaterialIcons name="error" size={24} color="lightgray" />
          <StyledText color={'lightgray'}>
            {t('failedToRetrieveItem')}
          </StyledText>
        </View>
      ) : isLoading ? (
        <View
          style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <Loading />
        </View>
      ) : singleTab.isSingle && singleTab.topicId !== -1 ? (
        <ThemeHomeScreenPage pageId={singleTab.topicId} />
      ) : (
        <ThemeHomeScreenPaginator
          data={topicData}
          horizontalIndex={horizontalIndex}
          setHorizontalIndex={setHorizontalIndex}
          width={width}
        />
      )}
    </FullWidthView>
  );
};

const getTopicData = async (topicId: number) => {
  const result = await getData(`topic?topic_id=${topicId}`);
  return result;
};

export default ThemeHomeScreen;
