import React, { useRef, useState } from 'react';

import { CategoriesItem, ProductInformation, RootState } from '../../../types';
import ProductItemInList from '../../Product/ProductItemInList';

import ListFooterText from '../../common/list/ListFooterText';
import { Dimensions, FlatList, Platform, RefreshControl } from 'react-native';
import ActivityIndicator from '../../common/ActivityIndicator';
import { useDispatch, useSelector } from 'react-redux';
import KeepScrollTip from '../KeepScrollTip';
import { useNavigation } from '@react-navigation/native';
import PressNextTip from '../PressNextTip';
import { tintColorLight } from '../../../constants/Colors';

type ProductListProp = {
  data: Array<ProductInformation>;
  updatePage: Function;
  productAllLoaded: boolean;
  listHeader?: JSX.Element;
  isHomeProductList?: boolean;
  nextCategoryName?: string;
  productType?: 'regular' | 'groupsale';
  onRefresh?: () => void;
  refreshing?: boolean;
};

const ProductList = ({
  data,
  updatePage,
  productAllLoaded,
  listHeader,
  isHomeProductList,
  nextCategoryName = '',
  productType = 'regular',
  onRefresh,
  refreshing,
}: ProductListProp) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const selectedSubCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedSubCategoryIndex
  );

  const subCategoryLength = useSelector<RootState, number>(
    (state) => state.goods.subCategoryLength
  );

  const categoriesArray = useSelector<RootState, Array<CategoriesItem>>(
    (state) => state.goods.categoriesArray
  );
  const selectedMainCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedMainCategoryIndex
  );

  const groupSaleCategoriesArray = useSelector<
    RootState,
    Array<CategoriesItem>
  >((state) => state.goods.groupSaleCategoriesArray);

  const selectedGroupSaleCategoryIndex = useSelector<RootState, number>(
    (state) => state.goods.selectedGroupSaleCategoryIndex
  );

  const [shouldRelease, setShouldRelease] = useState(false);

  const listRef = useRef<FlatList>(null);

  //Render Item in List
  const renderItem = ({ item }: any) => <ProductItemInList data={item} />;

  const _onScrollEndDragFun = () => {
    if (!isHomeProductList) {
      let scrollMetrics =
        (listRef.current &&
          listRef.current._listRef &&
          listRef.current._listRef._scrollMetrics) ||
        null;

      let { contentLength = 0, offset = 0, visibleLength = 0 } = scrollMetrics;

      const targetArray =
        productType === 'regular' ? categoriesArray : groupSaleCategoriesArray;
      const targetIndex =
        productType === 'regular'
          ? selectedMainCategoryIndex
          : selectedGroupSaleCategoryIndex;

      if (Platform.OS === 'ios') {
        //IOS 系统存在弹性上拉
        if (offset + visibleLength > contentLength + 100 && productAllLoaded) {
          //Switch level 2 category
          if (selectedSubCategoryIndex + 1 <= subCategoryLength - 1) {
            dispatch({
              type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX',
              payload: selectedSubCategoryIndex + 1,
            });
          }
          //Switch level 1 category
          else {
            if (targetArray.length > 0) {
              if (targetIndex !== -1 && targetIndex < targetArray.length - 1) {
                navigation.setParams({
                  catId: targetArray[targetIndex + 1].id,
                });
                dispatch({
                  type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX',
                  payload: 0,
                });
                if (productType === 'regular') {
                  dispatch({
                    type: 'UPDATE_SELECTED_MAIN_CATEGORY_NAME',
                    payload:
                      categoriesArray[selectedMainCategoryIndex + 1].name,
                  });
                  dispatch({
                    type: 'UPDATE_SELECTED_MAIN_CATEGORY_INDEX',
                    payload: selectedMainCategoryIndex + 1,
                  });
                } else if (productType === 'groupsale') {
                  dispatch({
                    type: 'UPDATE_SELECTED_GROUPSALE_MAIN_CATEGORY_INDEX',
                    payload: selectedGroupSaleCategoryIndex + 1,
                  });
                }
              } else {
                navigation.setParams({
                  catId: targetArray[0].id,
                });
                dispatch({
                  type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX',
                  payload: 0,
                });
                if (productType === 'regular') {
                  dispatch({
                    type: 'UPDATE_SELECTED_MAIN_CATEGORY_NAME',
                    payload: categoriesArray[0].name,
                  });
                  dispatch({
                    type: 'UPDATE_SELECTED_MAIN_CATEGORY_INDEX',
                    payload: 0,
                  });
                } else if (productType === 'groupsale') {
                  dispatch({
                    type: 'UPDATE_SELECTED_GROUPSALE_MAIN_CATEGORY_INDEX',
                    payload: 0,
                  });
                }
              }
            } else {
              dispatch({
                type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX',
                payload: 0,
              });
            }
          }
        }
      } else {
        //android 无弹性滑动，滑动到底时，offset + visibleLength = contentLength。IOS有弹性滑动，需要改变判断条件  lbs 2019-03-10
        if (productAllLoaded) {
          if (selectedSubCategoryIndex + 1 <= subCategoryLength - 1) {
            if (productType === 'regular') {
              dispatch({
                type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX',
                payload: selectedSubCategoryIndex + 1,
              });
            } else if (productType === 'groupsale') {
              dispatch({
                type: 'UPDATE_SELECTED_GROUPSALE_MAIN_CATEGORY_INDEX',
                payload: selectedGroupSaleCategoryIndex + 1,
              });
            }
          } else {
            if (categoriesArray.length > 0) {
              if (targetIndex !== -1 && targetIndex < targetArray.length - 1) {
                navigation.setParams({
                  catId: targetArray[targetIndex + 1].id,
                });
                dispatch({
                  type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX',
                  payload: 0,
                });
                if (productType === 'regular') {
                  dispatch({
                    type: 'UPDATE_SELECTED_MAIN_CATEGORY_NAME',
                    payload:
                      categoriesArray[selectedMainCategoryIndex + 1].name,
                  });
                  dispatch({
                    type: 'UPDATE_SELECTED_MAIN_CATEGORY_INDEX',
                    payload: selectedMainCategoryIndex + 1,
                  });
                } else if (productType === 'groupsale') {
                  dispatch({
                    type: 'UPDATE_SELECTED_GROUPSALE_MAIN_CATEGORY_INDEX',
                    payload: selectedGroupSaleCategoryIndex + 1,
                  });
                }
              } else {
                navigation.setParams({
                  catId: categoriesArray[0].id,
                });
                dispatch({
                  type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX',
                  payload: 0,
                });
                if (productType === 'regular') {
                  dispatch({
                    type: 'UPDATE_SELECTED_MAIN_CATEGORY_NAME',
                    payload: categoriesArray[0].name,
                  });
                  dispatch({
                    type: 'UPDATE_SELECTED_MAIN_CATEGORY_INDEX',
                    payload: 0,
                  });
                } else if (productType === 'groupsale') {
                  dispatch({
                    type: 'UPDATE_SELECTED_GROUPSALE_MAIN_CATEGORY_INDEX',
                    payload: 0,
                  });
                }
              }
            } else {
              dispatch({
                type: 'UPDATE_SELECTED_SUBCATEGORY_INDEX',
                payload: 0,
              });
            }
          }
        }
      }
    }
  };

  const onScrollCheck = () => {
    if (Platform.OS === 'ios' && productAllLoaded && !isHomeProductList) {
      let scrollMetrics =
        (listRef.current &&
          listRef.current._listRef &&
          listRef.current._listRef._scrollMetrics) ||
        null;

      let { contentLength = 0, offset = 0, visibleLength = 0 } = scrollMetrics;
      if (offset + visibleLength > contentLength + 100) {
        setShouldRelease(true);
      } else {
        setShouldRelease(false);
      }
    }
  };

  return (
    <FlatList
      contentContainerStyle={{ width: '100%' }}
      ref={listRef}
      data={data}
      renderItem={renderItem}
      keyExtractor={(item, index) =>
        item.goods_id.toString() + '@' + index.toString()
      }
      onEndReachedThreshold={0.01}
      onEndReached={() => updatePage()}
      ListFooterComponent={
        productAllLoaded ? (
          isHomeProductList ? (
            ListFooterText
          ) : Platform.OS === 'ios' ? (
            <KeepScrollTip
              nextCategoryName={nextCategoryName}
              shouldRelease={shouldRelease}
            />
          ) : (
            <PressNextTip
              nextCategoryName={nextCategoryName}
              onPress={_onScrollEndDragFun}
            />
          )
        ) : (
          ActivityIndicator
        )
      }
      ListHeaderComponent={listHeader}
      onScrollEndDrag={() => {
        if (Platform.OS === 'ios') {
          _onScrollEndDragFun();
        }
      }}
      onScroll={onScrollCheck}
      refreshControl={
        refreshing !== undefined && onRefresh ? (
          <RefreshControl
            colors={[tintColorLight]}
            tintColor={tintColorLight}
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
        ) : undefined
      }
    />
  );
};

export default ProductList;
