import React, { useEffect, useRef } from 'react';
import { FlatList, StyleSheet, View, TouchableOpacity } from 'react-native';
import {
  backGroundLight,
  normalTextColor,
  tintColorLight,
} from '../../constants/Colors';
import ListSeparator from '../common/list/ListSeparator';
import StyledText from '../common/StyledText';

type CategoryType = {
  cat_id: number;
  cat_name: string;
  goods_count?: number;
};

type CategoryListProps = {
  menus: Array<CategoryType>;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  selectedIndex: number;
};

const BrandCategoryList = ({
  menus,
  selectedIndex,
  setSelectedIndex,
}: CategoryListProps) => {
  const menuRef = useRef<FlatList>(null);

  const renderItem = ({
    item,
    index,
  }: {
    item: CategoryType;
    index: number;
  }) => {
    return (
      <TouchableOpacity onPress={() => setSelectedIndex(index)}>
        <View style={styles.menuItem}>
          <StyledText
            color={index === selectedIndex ? tintColorLight : normalTextColor}
            bold={index === selectedIndex}
          >
            {item.cat_name}
            {/* {item.goods_count !== undefined && `(${item.goods_count})`} */}
          </StyledText>
        </View>
      </TouchableOpacity>
    );
  };

  useEffect(() => {
    if (selectedIndex > 0) {
      menuRef.current?.scrollToIndex({
        index: selectedIndex,
        viewPosition: 0.5,
      });
    }

    return () => {};
  }, [selectedIndex]);

  return (
    <View
      style={{
        backgroundColor: 'white',
        borderBottomColor: backGroundLight,
        borderBottomWidth: 1,
      }}
    >
      <FlatList
        ref={menuRef}
        data={menus}
        renderItem={renderItem}
        keyExtractor={(item) => item.cat_id.toString()}
        horizontal
        contentContainerStyle={styles.menuContainer}
        ItemSeparatorComponent={() => <ListSeparator direction="vertical" />}
        onScrollToIndexFailed={() =>
          console.log('failed to scroll to index from brand menu')
        }
        showsHorizontalScrollIndicator={false}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  menuContainer: {
    paddingVertical: 10,
  },
  menuItem: {
    paddingHorizontal: 6,
  },
});

export default BrandCategoryList;
